import React from 'react';
import { Layout } from 'antd';

type ContainerProps = {
  children: JSX.Element|JSX.Element[],
  style?: React.CSSProperties,
};

export default function Container({ children, style }: ContainerProps) {
  return (
    <Layout.Content
      style={{
        padding: 24,
        background: '#fff',
        borderRadius: '.3rem',
        ...style,
      }}
    >
      {children}
    </Layout.Content>
  );
}
