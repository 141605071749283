import api from '.';

export const regraComissaoCorretoraByFiltros = data =>
  api().get('api/producao/regra-comissao-corretora-by-filtros', data);

export const regraComissaoProdutorByFiltros = data =>
  api().get('api/producao/regra-comissao-produtor-by-filtros', data);

export const equipeByProdutor = produtorId =>
  api().get(`api/producao/equipes/${produtorId}`);

export const produtoByEquipe = equipeId =>
  api().get(`api/producao/consultor-by-equipe/${equipeId}`);

export const agenciasByEquipe = equipeId =>
  api().get(`api/producao/agencias/equipe/${equipeId}`);
