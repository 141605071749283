import React, { useState } from "react";
import { Button, Col, Divider, Form, Row, Input as InputAnt, Spin, Table, message } from 'antd';
import { Container, ContentHeader, Select, Input, DatePicker } from '~/components';
import { useDispatch, useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as AcumuloRiscoActions from '~/store/ducks/acumulo-risco'
import history from '~/services/history';
import 'antd/dist/antd.css';
import { Functions } from '~/utils';

const { Column } = Table;


export default function AcumuloRisco () {

    const { loading, retorno, acumuloRiscoValor, clienteNome } = useSelector(({ acumuloRisco }) => acumuloRisco);

    const dispatch = useDispatch();

    const [validateStatus, setValidateStatus] = useState('');

    const [erros, setErros] = useState([]);

    const [acumuloRisco, setAcumuloRisco] = useState({});

    const [cpfCnpj, setCpfCnpj] = useState();

    function onChangeAcumuloRisco(value, name) {
        if (name === 'cpfCnpj') {
            setCpfCnpj(value);
            value = value.replace(/\D/g, '');
            acumuloRisco.cpfCnpj = value;
        }
        setAcumuloRisco({...acumuloRisco, [name]: value});
    }

    function validaAcumuloRisco(acumuloRisco) {
        setErros([]);

        if (!acumuloRisco.cpfCnpj || acumuloRisco.cpfCnpj.length < 11) {
            message.error('Por favor, preencha o campo "CPF/CNPJ"');
            return false;
        }
        return true;
    }

    function handleSubmit(e) {
        if (e) e.preventDefault();
        if (validaAcumuloRisco(acumuloRisco)) {
            dispatch(
                AcumuloRiscoActions.getAcumuloRiscoByCpf(acumuloRisco)
            );
        }
    }

    function onNovaCotacao() {
        dispatch(ClienteActions.initCliente());
        dispatch(ProducaoActions.initProducao());
        dispatch(PropostaActions.initProposta());
        history.push('/proposta/new');
    }

    return (
        <>
        <ContentHeader
        title="ACUMULO DE RISCO"
        hideClass="btn-Hide"
        icon="home"
        showButton
        textButton="Nova Cotação"
        onClick={onNovaCotacao}
      />
        <Container style={{ margin: '0 16px' }}>
        <Row>
          <Col xs={{ span: 24 }}>
            <h6>Acúmulo de Risco</h6>
            <Form
                layout="vertical"
                className="ant-advanced-search-form"
                onSubmit={handleSubmit}
                >
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Divider orientation="center" style={{ color: '#333' }} />
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Row gutter={[16, 0]}>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="CPF/CNPJ" required>
                                    <Input
                                    value={cpfCnpj}
                                    name="cpfCnpj"
                                    mask="cpfCnpj"
                                    onChange={onChangeAcumuloRisco}
                                    maxLength={18}
                                    validateStatus={validateStatus}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Valor de Risco Acumulado">
                                    <Input
                                    value={Functions.formatarMoeda(acumuloRiscoValor)}
                                    name="acumuloRiscoValor"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label=" " style={{width: 100}}>
                                    <Button
                                    type="primary"
                                    className="btn-block"
                                    htmlType="submit"
                                    > Pesquisar
                                    </Button>
                                </Form.Item>
                            </Col>
                    </Row>
                </Row>
            </Form>
          </Col>
        </Row>
        <Row>
            <Col>
              <Divider
                orientation="center"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                <span><strong>{clienteNome}</strong></span>
              </Divider>
              <Table
                rowKey={(r, i) => i.toString()}
                dataSource={retorno}
                loading={!!(loading || []).length}
              >
                <Column
                  align="center"
                  title="Nº Contrato"
                  width={250}
                  dataIndex="contratoPrestamistaNumeroControle"
                />
                <Column
                  align="center"
                  title="Valor prestamista"
                  width={150}
                  dataIndex="mtrContratoPrestamistaValor"
                  render={(text, record) => (
                    <span>
                      { Functions.formatarMoeda(record.mtrContratoPrestamistaValor) }
                    </span>
                  )}
                />
                <Column
                  width={250}
                  align="center"
                  title="Produto"
                  dataIndex="produtoDescricao"
                />
              </Table>
            </Col>
        </Row>
        </Container>
        </>
    )

}