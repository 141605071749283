import history from '~/services/history';
import { store } from '~/store';
import * as AuthAction from '~/store/ducks/auth';

export function getResponse(response) {
  if (response.ok) {
    const { data, message, success, messages } = response.data;
    return { ok: success, data, message, messages };
  }

  if (process.env.NODE_ENV === 'development')
    console.tron.log('Response', response);
  else console.error('Erro no Request', response);

  return getGeneralApiProblem(response);
}

export function getResponseFile(response, fileName) {
  if (!response.ok) {
    response.data.message =
      'Ocorreu um erro ao realizar o download do arquivo!';
    return getGeneralApiProblem(response);
  }

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE variant
    window.navigator.msSaveOrOpenBlob(
      new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      fileName
    );
  } else {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  return response;
}

export function getResponseFilePdfStringBase64(response, fileName) {
  
  if (!response.ok) {
    response.data.message = 'Ocorreu um erro ao realizar o download do arquivo!';
    return getGeneralApiProblem(response);
  }

  donwloadPdfFromBase64String(response.data.data, fileName);
  
  return response;
}

export function getResponseFileStringBase64(response, fileName, dataType,fileExtension) {
  if (!response.ok) {
    response.data.message = 'Ocorreu um erro ao realizar o download do arquivo!';
    return getGeneralApiProblem(response);
  }

  downloadFileBase64String(response.data.data, dataType, fileName, fileExtension);

  return response;
}

function downloadFileBase64String(stringBase64, stringDataType, fileName, fileExtension) {
  const linkSource = `data:${stringDataType};base64,${stringBase64}`;
  const downloadLink = document.createElement("a");
  fileName = `${(fileName ?? 'download')}.${fileExtension}`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

function donwloadPdfFromBase64String(stringBase64, fileName){
  downloadFileBase64String(stringBase64, 'application/pdf', fileName, 'pdf');
}

function getGeneralApiProblem(response) {
  const { status, data } = response;

  if (status === 401) {
    store.dispatch(AuthAction.logout());
    history.push('/');
    return { ok: false, message: 'Sua sessão expirou.' };
  }

  if (status === 404 && data !== null) {
    const { success, message } = data;
    return { ok: success, message };
  }

  if (status == null || data == null) {
    return { ok: false, message: 'Não pôde se comunicar com o servidor' };
  }

  if (data) {
    const { success, message, messages } = data;
    return { ok: success, message, messages };
  }

  return { ok: false, message: 'Unable to connect to the server' };
}
