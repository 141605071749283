/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import './styles.css';

type RadioCustomProps = {
  label: String,
  icon: String,
  id: Number,
  name: String,
  smallText: String,
  onClick: Function,
};

export default function RadioCustom({
  label,
  icon,
  id,
  name,
  smallText,
  onClick,
}: RadioCustomProps) {
  const [className] = useState(
    id === 11409 ? 'radio-wrapper destaque' : 'radio-wrapper'
  );

  return (
    <div className={className} onClick={onClick}>
      <input type="radio" id={id} name={name} />
      <label htmlFor={id}>
        <i className={icon} />
        <span>
          {label}
          <small>{smallText}</small>
        </span>
      </label>
    </div>
  );
}
