import React, { useEffect } from 'react';

import { Row, Col, Spin, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Block, BlockTable } from './styles';

import * as DominiosActions from '~/store/ducks/dominio';

import imgEmpresaPremiado from '~/assets/img/brb_empresa_premiado.png';
import imgMitsui from '~/assets/img/mitsui.jpg';
import { Functions, Mask } from '~/utils';

type FieldProps = {
  span: Number,
  title: String,
  value: String,
};

export default function Impressao() {
  const {
    generos,
    estadosCivis,
    naturalidade,
    consultores,
    angariadores,
    agencias,
    loading,
    profissoes,
    cargos,
    faixaRenda,
  } = useSelector(({ dominio }) => dominio);
  const { cliente, endereco } = useSelector(state => state.cliente);
  const {
    contratoId,
    propostaId,
    dreMitsuiEmp,
    dreMitsuiEmp: { bemEndereco, planoSelected, pagamento },
    producao,
  } = useSelector(({ proposta }) => proposta);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DominiosActions.listAll(print));
  }, []);

  function print() {
    setTimeout(function() {
      window.focus();
      window.print();
    }, 2000); // wait for images to load inside iframe
  }

  function Field({ span, title, value }: FieldProps) {
    if (!value) return <></>;
    return (
      <Col span={span}>
        <b>{title}:</b> {value}
      </Col>
    );
  }

  function getDescricaoDominio(dominio, textValue, textDescription, value) {
    if (!value) return 'Não Informado';
    return dominio.find(d => d[textValue] === value)?.[textDescription];
  }

  function getFormaPagamento() {
    const parcela = Functions.formatarMoeda(pagamento.mtrContratoDRE1Parcela);
    if (pagamento.formaPagamento === 1) return `À Vista ${parcela}`;

    if (pagamento.formaPagamento === 2)
      return ` 1 + ${pagamento.contratoDREQtdParcela} Parcela(s) SEM JUROS de ${parcela}`;

    return '';
  }

  function getContatoFone(type) {
    const listFones = cliente.clienteContatoFones;
    // 1 - Residencial     2 - Comercial     4 - Celular     6 - Recado
    const filtered = listFones.filter(
      f => f.clienteContatoFoneTipoId === type
    )[0];
    if (filtered) return filtered.clienteContatoFoneNumero;

    return '';
  }

  function getProfissao() {
    const profissaoFiltered = profissoes.filter(
      p => p.clienteProfissaoId === cliente.clienteProfissaoId
    )[0];
    if (profissaoFiltered) return profissaoFiltered.clienteProfissaoDescricao;

    return '';
  }

  function getCargo() {
    const cargoFiltered = cargos.filter(
      c => c.clienteCargoId === cliente.clienteCargoId
    )[0];
    if (cargoFiltered) return cargoFiltered.clienteCargoDescricao;

    return '';
  }

  function getRendaMensal() {
    const rendaFiltered = faixaRenda.filter(
      r => r.clienteFaixaRendaId === cliente.clienteFaixaRendaId
    )[0];
    if (rendaFiltered) {
      const inicio = Mask.maskMoney(rendaFiltered.clienteFaixaRendaInicio);
      const fim = Mask.maskMoney(rendaFiltered.clienteFaixaRendaFim);
      return `${inicio} à ${fim}`;
    }
    return '';
  }

  function getPremioLiquido() {
    const premioTotal = Functions.getMoney(
      dreMitsuiEmp.planoSelected.mtrPropostaEletronicaPlanoPremioTotal
    );
    const perIof = 7.38 / 100 + 1;
    const iof = parseFloat(premioTotal - premioTotal / perIof);
    const premioLiquido = parseFloat(premioTotal - iof);

    return Mask.maskMoney(premioLiquido);
  }

  function getPremioIOF() {
    const premioTotal = Functions.getMoney(
      dreMitsuiEmp.planoSelected.mtrPropostaEletronicaPlanoPremioTotal
    );
    const perIof = 7.38 / 100 + 1;
    const iof = parseFloat(premioTotal - premioTotal / perIof);

    return Mask.maskMoney(iof);
  }

  function getAgenciaProdutora() {
    const agenciaFiltered = agencias.filter(
      a => a.agenciaId === producao.agenciaId
    )[0];
    if (agenciaFiltered)
      return `${agenciaFiltered.agenciaCodigo} - ${agenciaFiltered.agenciaDescricao}`;

    return '';
  }

  return (
    <>
      <Spin
        spinning={loading}
        style={{ textAlign: 'center' }}
        size="large"
        tip="Aguarde carregando"
      >
        {loading && <Skeleton active />}
      </Spin>
      {generos && angariadores && consultores && (
        <Container>
          {/* Dados Proposta */}
          <Block>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col span={10} style={{ paddingLeft: 4 }}>
                <img src={imgEmpresaPremiado} alt="BRB" />
              </Col>
              <Col span={6} style={{ alignItems: 'center' }}>
                <b>{contratoId ? 'Proposta de Seguro' : 'Cotação de Seguro'}</b>
                <br />
                {contratoId
                  ? `Número da Proposta: ${contratoId}`
                  : `Número da Cotação: ${propostaId}`}
              </Col>
              <Col
                span={8}
                style={{
                  textAlign: 'right',
                  paddingRight: 10,
                }}
              >
                <img src={imgMitsui} alt="Logo" width="70" />
              </Col>
            </Row>
          </Block>
          {/* Tipo Seguro */}
          <Block>
            <Row>
              <b>Tipo de Seguro: </b> Seguro Novo
            </Row>
            <Row>
              <b>Vigência do Seguro: </b> A partir das 24h de{' '}
              {Functions.formatarData(dreMitsuiEmp.contratoDREDataInicio)} até
              as 24h de{' '}
              {Functions.formatarData(dreMitsuiEmp.contratoDREDataFim)}
            </Row>
          </Block>
          {/* Dados do Cliente */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PROPONENTE</b>
            </Row>
            <Row>
              <Field
                span={10}
                title="Razão Social"
                value={dreMitsuiEmp.propRazaoSocial}
              />
              <Field span={8} title="CNPJ" value={dreMitsuiEmp.propCNPJ} />
            </Row>
            <Row>
              <Field
                span={10}
                title="Nome do Responsável"
                value={cliente.clienteNome}
              />
              <Field span={8} title="CNPJ" value={cliente.clienteCpfCnpj} />
              <Field
                span={6}
                title="Data de Nascimento"
                value={Functions.formatarData(cliente.clienteDataNascimento)}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Endereço"
                value={endereco.clienteEndereco1}
              />
              <Field
                span={8}
                title="Bairro"
                value={endereco.clienteEnderecoBairro}
              />
              <Field span={6} title="CEP" value={endereco.clienteEnderecoCep} />
            </Row>
            <Row>
              <Field
                span={10}
                title="Cidade"
                value={endereco.clienteEnderecoCidade}
              />
              <Field span={8} title="UF" value={endereco.clienteEnderecoUf} />
            </Row>
            <Row>
              <Field
                span={10}
                title="Telefone Residencial"
                value={getContatoFone(1)}
              />
              <Field
                span={8}
                title="Telefone Comercial"
                value={getContatoFone(2)}
              />
              <Field
                span={6}
                title="Telefone Celular"
                value={getContatoFone(4)}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Telefone Recado"
                value={getContatoFone(6)}
              />
              <Field
                span={8}
                title="E-mail"
                value={
                  cliente.clienteContatoMails[0]?.clienteContatoMailDescricao
                }
              />
            </Row>
            <Row>
              <Field span={10} title="Profissão" value={getProfissao()} />
              <Field
                span={8}
                title="Ramo de Atividade"
                value={cliente.clienteRamoAtividade}
              />
            </Row>
            <Row>
              <Field span={10} title="Cargo" value={getCargo()} />
              <Field span={8} title="Renda Mensal" value={getRendaMensal()} />
            </Row>
            <Row>
              <Field
                span={10}
                title="Sexo"
                value={getDescricaoDominio(
                  generos,
                  'sexoId',
                  'sexoDescricao',
                  cliente.sexoId
                )}
              />
              <Field
                span={8}
                title="Estado Civil"
                value={getDescricaoDominio(
                  estadosCivis,
                  'estadoCivilId',
                  'estadoCivilDescricao',
                  cliente.estadoCivilId
                )}
              />
              <Field
                span={6}
                title="Nacionalidade"
                value={getDescricaoDominio(
                  naturalidade,
                  'naturalidadeId',
                  'naturalidadeDescricao',
                  cliente.naturalidadeId
                )}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Nº da Identidade"
                value={cliente.clienteRg}
              />
              <Field
                span={8}
                title="Data de emissão"
                value={Functions.formatarData(cliente.clienteDataExpedicaoRg)}
              />
              <Field
                span={6}
                title="Orgão Emissor"
                value={cliente.clienteRgSsp}
              />
            </Row>
            <Row>
              <Field
                span={24}
                title="Identificação, se for o caso, de seu enquadramento como pessoa politicamente exposta"
                value={dreMitsuiEmp === 'S' ? 'SIM' : 'NÃO'}
              />
            </Row>
            <Row>
              <Field
                span={24}
                title="Há Outros Seguros Cobrindo o Mesmo Bem"
                value={dreMitsuiEmp === 1 ? 'SIM' : 'NÃO'}
              />
            </Row>
          </Block>
          {/* Local de Risco */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>LOCAL DE RISCO</b>
            </Row>
            <Row>
              <Field
                span={10}
                title="Endereço de Risco"
                value={bemEndereco.clienteEndereco1}
              />
              <Field
                span={8}
                title="Bairro"
                value={bemEndereco.clienteEnderecoBairro}
              />
              <Field
                span={6}
                title="CEP"
                value={bemEndereco.clienteEnderecoCep}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Número"
                value={bemEndereco.clienteEnderecoNumero}
              />
              <Field
                span={8}
                title="Complemento"
                value={bemEndereco.clienteEnderecoComplemento}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Cidade"
                value={bemEndereco.clienteEnderecoCidade}
              />
              <Field
                span={8}
                title="UF"
                value={bemEndereco.clienteEnderecoUf}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Cláusula beneficiária a favor de"
                value={dreMitsuiEmp.propClausulaBeneficiaria}
              />
              <Field
                span={8}
                title="CPF/CNPJ"
                value={dreMitsuiEmp.propClausulaBeneficiariaCpfCnpj}
              />
            </Row>
          </Block>
          {/* Plano Selecionado */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PLANO</b>
            </Row>
            <Row type="flex" align="middle">
              <BlockTable minHeight bold>
                Plano
              </BlockTable>
              <BlockTable minHeight bold>
                Incêndio/ Queda de Raio/ Explosão
              </BlockTable>
              <BlockTable minHeight bold>
                Danos Elétricos
              </BlockTable>
              <BlockTable minHeight bold>
                RC Operações
              </BlockTable>
              <BlockTable minHeight bold>
                Roubo de Bens
              </BlockTable>
              <BlockTable minHeight bold>
                Perda de Aluguel
              </BlockTable>
              <BlockTable minHeight bold>
                Quebra de Vidros
              </BlockTable>
              <BlockTable minHeight bold>
                Vendaval até Fumaça
              </BlockTable>
              <BlockTable minHeight bold>
                Rec de Doctos
              </BlockTable>
              <BlockTable minHeight bold>
                Prêmio Líquido
              </BlockTable>
              <BlockTable minHeight bold>
                IOF
              </BlockTable>
              <BlockTable minHeight bold>
                Prêmio Total
              </BlockTable>
            </Row>
            <Row type="flex" align="middle">
              <BlockTable>
                {planoSelected.propostaEletronicaPlanoSigla}
              </BlockTable>
              <BlockTable>
                {planoSelected.mtrIncendioQuedeDeRaioExplosao}
              </BlockTable>
              <BlockTable>{planoSelected.mtrDanosEletricos}</BlockTable>
              <BlockTable>{planoSelected.mtrRCOperacoes}</BlockTable>
              <BlockTable>{planoSelected.mtrRouboDeBens}</BlockTable>
              <BlockTable>{planoSelected.mtrPerdaDeAluguel}</BlockTable>
              <BlockTable>{planoSelected.mtrQuebraDeVidros}</BlockTable>
              <BlockTable>{planoSelected.mtrVendavalFumaca}</BlockTable>
              <BlockTable>{planoSelected.mtrRecDoctos}</BlockTable>
              <BlockTable>{getPremioLiquido()}</BlockTable>
              <BlockTable>{getPremioIOF()}</BlockTable>
              <BlockTable>
                {planoSelected.mtrPropostaEletronicaPlanoPremioTotal}
              </BlockTable>
            </Row>
            <Row>
              <span>
                <i>Remuneração pela intermediação de 40% sobre o prêmio</i>
              </span>
            </Row>
          </Block>
          {/* Forma de Pagamento */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>FORMA DE PAGAMENTO</b>
            </Row>
            <Row>
              <Col span={6}>{getFormaPagamento()}</Col>
              <Field
                title="Nosso Número"
                value={pagamento.propostaEletronicaNossoNumero}
              />
            </Row>
          </Block>
          {/* Dados Débito Conta */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS PARA DÉBITO EM CONTA CORRENTE</b>
            </Row>
            <Row>
              <Field
                span={6}
                title="Banco"
                value={`${pagamento.agencia.bancoId} - ${pagamento.agencia.banco.bancoDescricao}`}
              />
              <Field
                span={6}
                title="Agência"
                value={pagamento.agencia.agenciaCodigo}
              />
              <Field
                span={6}
                title="Conta Corrente Nº"
                value={pagamento.debitoContaNumeroConta}
              />
              {pagamento.contratoDREDiaVencimentoDemais > 0 && (
                <Field
                  span={6}
                  title="Dia Preferencial para Pagamento"
                  value={pagamento.contratoDREDiaVencimentoDemais}
                />
              )}
            </Row>
          </Block>
          {/* Termo e Assinatura */}
          <Block>
            <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
              Concordo com a forma de pagamento especificada autorizando o
              débito, na referida conta-corrente, do valor relativo à(s)
              parcela(s) do prêmio devido pela contratação deste seguro. Estou
              ciente de que a inadimplência de quaisquer das parcelas do prêmio
              no(s) seu(s) vencimento(s), decorrente de insuficiência de saldo,
              implicará na suspensão imediata das coberturas contratadas,
              observados os termos da Cláusula de Pagamento de Prêmio das
              Condições Gerais do Seguro. Declaro que as informações constantes
              nesta proposta forma por mim prestadas e, em sendo apurada
              qualquer divergência naquelas que serviram de base para a taxação
              do risco, por omissão ou incorreção desses dados, estou ciente que
              poderei perder o direito a uma eventual indenização, nos termos
              previstos no Código Civil Brasileiro e na legislação pertinente,
              devendo ainda efetuar o pagamento do prêmio vencido. Declaro ainda
              que li e concordo com as Condições Gerais do Seguro em questão,
              que me foram apresentadas previamente à assinatura da presente
              proposta.
            </Row>
            <Row>
              <Field
                span={14}
                title="Local e data"
                value={`Brasília, ${Functions.getDateNow()}`}
              />
              <Col span={6} style={{ textAlign: 'center' }}>
                ________________________________________________
                <br />
                <b>Assinatura do Proponente</b>
              </Col>
            </Row>
          </Block>
          {/* Dados Produção */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DA PRODUÇÃO</b>
            </Row>
            <Row>
              <Field
                span={6}
                title="Mat. Consultor"
                value={getDescricaoDominio(
                  consultores,
                  'produtorId',
                  'produtorCodigo',
                  producao.consultorId
                )}
              />
              <Field
                span={6}
                title="Mat. Angariador"
                value={getDescricaoDominio(
                  angariadores,
                  'produtorId',
                  'produtorCodigo',
                  producao.angariadorId
                )}
              />
              <Field
                span={6}
                title="Agência Produtora"
                value={getAgenciaProdutora()}
              />
              <Field span={6} title="Equipe" value={producao.equipeId} />
            </Row>
          </Block>
        </Container>
      )}
    </>
  );
}
