import { Col, Divider, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';

const { Column } = Table;

type PrevidenciaTabProp = {
  data?: Object
}

function ordenarPorPropriedade(lista, posicaoOrdenar = null) {
  return lista.sort((a, b) => a[posicaoOrdenar] - b[posicaoOrdenar]);
}

function possuiAlgumaMovimentacaoTipoResgate(contrato) {
  return contrato && contrato.movimentacoes
    && contrato.movimentacoes.some(x => (x.movimentacaoTipoDescricao || '').toLowerCase() === 'resgate' );
}

export default function PrevidenciaDetalhe(param: PrevidenciaTabProp) {

  const contrato = param?.data || {};

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        Dados do Contrato
      </Divider>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Proposta">Proposta</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Proposta">{contrato?.proposta}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Certificado">Certificado</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Certificado">{contrato?.certificado}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Data venda">Data venda</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Data venda">{contrato?.contratoPrevidenciaDataVenda ? moment(contrato.contratoPrevidenciaDataVenda).format('DD/MM/YYYY') : ''}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Situação">Situação</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Situação">{contrato?.contratoStatusDescricao}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Produto">Produto</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Produto">{contrato?.produtoDescricao}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Seguradora">Seguradora</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Seguradora">{contrato?.seguradoraDescricao}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        {
          contrato?.movimentacoes && !!contrato.movimentacoes.length &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Movimentações
              </Divider>
              <Table
                rowKey="movimentacaId"
                dataSource={ordenarPorPropriedade(contrato?.movimentacoes || [], 'movimentacaoParcela')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="Nº Parcela"
                  align="center"
                  dataIndex="movimentacaoParcela"
                  width={6}
                />
                <Column
                  title="Tipo"
                  align="center"
                  dataIndex="movimentacaoTipoDescricao"
                  width={6}
                />
                <Column
                  title="Data"
                  width={10}
                  align="center"
                  dataIndex="movimentacaoDataPagamentoParcela"
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />
                <Column
                  title="Valor Pago"
                  width={12}
                  align="center"
                  dataIndex="movimentacaoValorPagamento"
                  render={(text, record) => (
                    text
                      ? <span>{util.Functions.formatarMoeda(text)}</span>
                      : ''
                  )}
                />

              </Table>
              {
                possuiAlgumaMovimentacaoTipoResgate(contrato) && 
                <p><em>Para movimentações de resgate 
                  o valor trata-se de valor aproximado de resgate. Somente a cia. possui o valor atualizado.*</em></p>
              }
            </Col>
          </>
        }

      </Row>

    </>
  );
};