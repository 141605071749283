import React from 'react';
import { Alert } from 'antd';

type ErrorProps = {
  erros: String[],
};

export default function Error({ erros }: ErrorProps) {
  function Erros() {
    return (
      <ul>
        {erros.map((e,index) => (
          <li key={index}>{e}</li>
        ))}
      </ul>
    );
  }

  if (erros && erros.length > 0) {
    return (
      <Alert
        message="Atenção, verifique os campos abaixo:"
        description={<Erros />}
        type="error"
        showIcon
        style={{ marginBottom: 12 }}
      />
    );
  }

  return null;
}
