import styled from 'styled-components';

export const Content = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }
  .ant-breadcrumb {
    line-height: 0 !important;
  }
`;
