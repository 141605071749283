import { createActions, handleActions } from 'redux-actions';

export const types = {
  INIT_PRODUCAO: 'INIT_PRODUCAO',
  GET_REGRA_COMISSAO_CORRETORA: 'GET_REGRA_COMISSAO_CORRETORA',
  GET_REGRA_COMISSAO_PRODUTOR: 'GET_REGRA_COMISSAO_PRODUTOR',
  GET_EQUIPES_BY_PRODUTOR: 'GET_EQUIPES_BY_PRODUTOR',
  GET_PRODUTORES_BY_EQUIPE: 'GET_PRODUTORES_BY_EQUIPE',
  SAVE_PRODUCAO: 'SAVE_PRODUCAO',
  FAILED_PRODUCAO: 'FAILED_PRODUCAO',
};

export const {
  initProducao,
  getRegraComissaoCorretora,
  getRegraComissaoProdutor,
  getEquipesByProdutor,
  getProdutoresByEquipe,
  saveProducao,
  failedProducao,
} = createActions(
  types.INIT_PRODUCAO,
  types.GET_REGRA_COMISSAO_CORRETORA,
  types.GET_REGRA_COMISSAO_PRODUTOR,
  types.GET_EQUIPES_BY_PRODUTOR,
  types.GET_PRODUTORES_BY_EQUIPE,
  types.SAVE_PRODUCAO,
  types.FAILED_PRODUCAO
);

const defaultState = {
  regraComissaoCorretora: {},
  regraComissaoProdutor: {},
  equipesProdutor: [],
  produtoresEquipe: [],
  agenciasEquipe: [],
  loadingEquipes: false,
};

export default handleActions(
  {
    [initProducao]: () => defaultState,
    [getEquipesByProdutor]: state => ({
      ...state,
      equipesProdutor: [],
      loadingEquipes: true,
    }),
    [getProdutoresByEquipe]: state => ({
      ...state,
      produtoresEquipe: [],
      loadingProdutores: true,
    }),
    [saveProducao]: (state, action) => ({
      ...state,
      loadingEquipes: false,
      loadingProdutores: false,
      [action.payload.dominio]: action.payload.data,
    }),
  },
  defaultState
);
