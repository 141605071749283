/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Radio, Checkbox } from 'antd';
import { Select, TitleSection, Input, Disable } from '~/components';
import proposta, * as PropostaActions from '~/store/ducks/proposta';
import * as ProducaoActions from '~/store/ducks/producao';

import { Container } from './style';
import { Constants } from '~/utils';

type FieldsProducaoProps = {
  produto: String,
  showIsProdutor?: Boolean,
  showIsVendaRelacionada?: Boolean,
};

export default function FieldsProducao({
  produto,
  showIsProdutor,
  showIsVendaRelacionada = false,
}: FieldsProducaoProps) {
  const dispatch = useDispatch();

  const {
    consultores,
    angariadores,
    angariadoresCnps,
    agenciasBrb,
    agenciasCnps,
    tiposContratos,
    seguradoras,
    equipes,
  } = useSelector(({ dominio }) => dominio);

  const {
    loadingEquipes,
    produtoresEquipe,
    loadingProdutores,
    regraComissaoProdutor,
  } = useSelector(({ producao }) => producao);

  // eslint-disable-next-line no-unused-vars
  const { producao, contratoId, produtoId } = useSelector(
    state => state.proposta
  );
  const { usuario } = useSelector(state => state.auth);

  const [showEquipe, setShowEquipe] = useState(true);
  const [isAngariador, setIsAngariador] = useState(false);
  const [isCnp, setIsCnp] = useState(false);
  const [isAgenciaDigital, setIsAgenciaDigital] = useState(false);

  useEffect(() => {
    if (usuario.descTipoProdutor === 'CONSULTOR') {
      onChangeConsultor(usuario.produtorId);
      changeField('isProdutor', 2);
    } else if (usuario.descTipoProdutor === 'OPERADOR') {
      onChangeConsultor(usuario.produtorId);
      changeField('isProdutor', 2);
    } else if (usuario.descTipoProdutor === 'ANGARIADOR') {
      setShowEquipe(false);
      changeField('angariadorId', usuario.produtorId);
      changeField('isProdutor', 1);
      changeField('equipeId', '009999');
      setIsAngariador(true);
    } else if (usuario.descTipoProdutor === 'CNP') {
      changeField('isProdutor', 2);
      changeField('equipeId', '009999');
      changeField('consultorId', 9480);
      changeField('angariadorId', 9480);
      setIsCnp(true);
      setShowEquipe(false);
      if (usuario.agenciaId) changeField('agenciaId', usuario.agenciaId);
    } else if (usuario.descTipoProdutor === 'AGENCIA_DIGITAL') {
      changeField('isProdutor', 2);
      changeField('equipeId', '009999');
      changeField('consultorId', 9480);
      setShowEquipe(false);
      setIsAgenciaDigital(true);
      changeField('angariadorId', usuario.produtorId);
      changeField('agenciaId', 27);
    } else {
      changeField('isProdutor', 2);
    }
  }, []);

  useEffect(() => {
    if (produtoresEquipe) {
      const c = produtoresEquipe.find(pr => pr.produtorId !== 9480);
      if (c) {
        changeField('consultorId', c.produtorId);
      }
    }
  }, [produtoresEquipe]);

  function isCap() {
    return produto === 'capitalizacao-icatu';
  }

  function isResidencialPersonalizavelLiberty(){
    return produto === 'residencial-personalizavel-liberty'
  }

  function getTiposConstratos() {
    if (!tiposContratos) return [];
    return tiposContratos.filter(t => {
      return produto === 'vpp' ||
        produto === 'ap-liberty' ||
        produto === 'residencial-mitsui' ||
        produto === 'empresarial-liberty' ||
        produto === 'empresarial-zurich' ||
        produto === 'empresarial-mitsui' ||
        produto === 'brb-master'
        ? t.contratoTipoId === 1
        : produto === 'residencial-zurich'
        ? t.contratoTipoId === 1 ||
          t.contratoTipoId === 3 ||
          t.contratoTipoId === 4
        : true;
    });
  }

  function changeField(field, value) {
    dispatch(PropostaActions.changeProducao({ field, value }));
  }

  function changeIsVendaRelacionada(field, value) {
    changeField(field, value);
    if (value) {
      changeField('equipeId', '009999');
      changeField('consultorId', 9480); // SEM PRODUCAO
      changeField('isProdutor', 1); // ANGARIADOR
    } else {
      changeField('isProdutor', null);
    }
  }

  function onChangeConsultor(value) {
    changeField('consultorId', value);
    getRegraComissaoProdutor(value);
  }

  function onChangeEquipe(value) {
    changeField('equipeId', value);
    getRegraComissaoProdutor(null, value);
  }

  function getRegraComissaoProdutor(consultorId, equipeId) {
    if (!producao.consultorId && !consultorId) return;

    const consultor = consultores.find(
      c => c.produtorId === consultorId || c.produtorId === producao.consultorId
    );

    let _produtoId;

    if (produto === 'vpp') {
      _produtoId = Constants.Produtos.VidaPremiadoPlus;
    } else if (produto === 'residencial-mitsui') {
      _produtoId = Constants.Produtos.DreMitsui;
    } else if (produto === 'empresarial-liberty') {
      _produtoId = Constants.Produtos.DreLibertyCeS;
    } else if (produto === 'residencial-zurich') {
      _produtoId = Constants.Produtos.DreZurichRes;
    } else if (produto === 'empresarial-zurich') {
      _produtoId = Constants.Produtos.DreZurichEmp;
    } else if (produto === 'empresarial-mitsui') {
      _produtoId = Constants.Produtos.DreMitsuiEmp;
    } else if (produto === 'ap-liberty') {
      _produtoId = Constants.Produtos.AcidentesPessoaisLiberty;
    } else if (isCap()) {
      _produtoId = produtoId;
    } else if (produto === 'brb-master') {
      _produtoId = Constants.Produtos.BrbMasterPremiado;
    }

    const param = {
      produtoId: _produtoId,
      contratoTipoId: !isCap() ? producao?.contratoTipoId : -2,
      produtorTipoId: consultor?.produtorTipoId,
      equipeId: !isCap() ? equipeId : null,
    };

    dispatch(ProducaoActions.getRegraComissaoProdutor(param));
  }

  useEffect(() => {
    if (regraComissaoProdutor == undefined || regraComissaoProdutor == null) {
      if (
        producao.equipeId !== null &&
        producao.contratoTipoId !== null &&
        producao.consultorId
      ) {
        getRegraComissaoProdutor(producao.consultorId, producao.equipeId);
      }
    }
  }, []);

  function onChangeAgencia(value) {
    changeField('agenciaId', value);

    if (usuario.descTipoProdutor === 'CNP') return;

    const { equipeId } = agenciasBrb.find(a => a.agenciaId === value);

    if (equipeId) changeField('equipeId', equipeId);
    else changeField('equipeId', '009999');

    if (usuario.descTipoProdutor === 'ANGARIADOR') {
      changeField('consultorId', null);
      dispatch(ProducaoActions.getProdutoresByEquipe(equipeId));
    }
  }

  return (
    <>
      {isCap() && <TitleSection title="ANGARIADOR" />}
      {!isCap() && <TitleSection title="Dados da Produção" />}

      <Container>
        {!isCap() && (
          <Row gutter={[16, 16]}>
            {produto === 'residencial-zurich' && (
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Tipo de Seguro</label>
                  <Select
                    defaultValue={1}
                    itens={getTiposConstratos()}
                    itemValue="contratoTipoId"
                    itemText="contratoTipoDescricao"
                    renderText={item => item.contratoTipoDescricao}
                    value={producao.contratoTipoId}
                    onChange={value => changeField('contratoTipoId', value)}
                    disabled={proposta.produtoId === 138104}
                  />
                </div>
              </Col>
            )}
            {showIsProdutor && (
              <Disable
                disable={
                  producao.isVendaRelacionada ||
                  usuario.descTipoProdutor === 'ANGARIADOR'
                }
              >
                <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label>Produtor</label> <br />
                    <Radio.Group
                      onChange={({ target: { value } }) =>
                        changeField('isProdutor', value)
                      }
                      value={producao.isProdutor}
                    >
                      <Radio value={1}>Angariador</Radio>
                      <Radio value={2}>Consultor</Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Disable>
            )}
            {showIsVendaRelacionada && (
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <label> </label> <br />
                <div className="form-group">
                  <Checkbox
                    checked={producao.isVendaRelacionada}
                    onChange={value =>
                      changeIsVendaRelacionada(
                        'isVendaRelacionada',
                        value.target.checked
                      )
                    }
                  >
                    Seguro vinculado ao financiamento imobiliário (seguro
                    habitacional).
                  </Checkbox>
                </div>
              </Col>
            )}
          </Row>
        )}
        {(producao.contratoTipoId === 3 || producao.contratoTipoId === 4) && (
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <div className="form-group">
                <label>Apólice Nº</label>
                <Input
                  placeholder="Apólice Nº"
                  value={producao.apoliceAnterior}
                  onChange={value => changeField('apoliceAnterior', value)}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="form-group">
                <label>Seguradora</label>
                <Select
                  defaultValue="Selecione"
                  itens={seguradoras}
                  itemValue="seguradoraId"
                  itemText="seguradoraDescricao"
                  itemTextSearch="seguradoraDescricao"
                  renderText={item =>
                    `${item.seguradoraId} - ${item.seguradoraDescricao}`
                  }
                  value={producao.seguradoraIdAnterior}
                  onChange={value => changeField('seguradoraIdAnterior', value)}
                  showSearch
                />
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]}>
          {isAngariador && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Angariador</label>
                <Select
                  defaultValue="Selecione"
                  itens={angariadores}
                  itemValue="produtorId"
                  itemText="produtorNome"
                  itemTextSearch="produtorCodigo"
                  renderText={item =>
                    `${item.produtorCodigo} - ${item.produtorNome}`
                  }
                  value={producao.angariadorId}
                  onChange={value => changeField('angariadorId', value)}
                  showSearch
                />
              </div>
            </Col>
          )}

          {!isAngariador && !isCnp && !isAgenciaDigital && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Consultor</label>
                <Select
                  itens={consultores}
                  itemValue="produtorId"
                  itemText="produtorNome"
                  itemTextSearch="produtorCodigo"
                  renderText={item =>
                    `${item.produtorCodigo} - ${item.produtorNome}`
                  }
                  value={producao.consultorId}
                  onChange={onChangeConsultor}
                  showSearch
                  disabled={producao.isVendaRelacionada}
                  loading={loadingProdutores}
                />
              </div>
            </Col>
          )}

          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <div className="form-group">
              <label>Ag. Produtora</label>
              <Select
                defaultValue="Selecione"
                itens={isCnp ? agenciasCnps : agenciasBrb}
                itemValue="agenciaId"
                itemText="agenciaDescricao"
                itemTextSearch="agenciaCodigo"
                renderText={item =>
                  `${item.agenciaCodigo} - ${item.agenciaDescricao}`
                }
                value={producao.agenciaId}
                onChange={onChangeAgencia}
                showSearch
                disabled={isCnp || isAgenciaDigital}
              />
            </div>
          </Col>

          {showEquipe && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Equipe</label>
                <Select
                  itens={equipes}
                  itemValue="equipeId"
                  itemText="equipeDescricao"
                  itemTextSearch="equipeId"
                  renderText={item =>
                    `${item.equipeId} - ${item.equipeDescricao}`
                  }
                  value={producao.equipeId}
                  onChange={onChangeEquipe}
                  showSearch
                  disabled={producao.isVendaRelacionada}
                  loading={loadingEquipes}
                />
              </div>
            </Col>
          )}

          {!isAngariador && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Angariador</label>
                <Select
                  defaultValue="Selecione"
                  itens={angariadores}
                  itemValue="produtorId"
                  itemText="produtorNome"
                  itemTextSearch="produtorCodigo"
                  renderText={item =>
                    `${item.produtorCodigo} - ${item.produtorNome}`
                  }
                  value={usuario.descTipoProdutor === 'CNP' ? 9480 : producao.angariadorId}
                  onChange={value => changeField('angariadorId', value)}
                  showSearch
                  disabled={usuario.descTipoProdutor === 'CNP'}
                />
              </div>
            </Col>
          )}

          {isAngariador && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Consultor</label>
                <Select
                  itens={consultores}
                  itemValue="produtorId"
                  itemText="produtorNome"
                  itemTextSearch="produtorCodigo"
                  renderText={item =>
                    `${item.produtorCodigo} - ${item.produtorNome}`
                  }
                  value={producao.consultorId}
                  onChange={onChangeConsultor}
                  showSearch
                  disabled={producao.isVendaRelacionada}
                  loading={loadingProdutores}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

FieldsProducao.defaultProps = {
  showIsProdutor: false,
  showIsVendaRelacionada: false,
};
