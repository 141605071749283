import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Radio,
  Button,
  Table,
  Spin,
  BackTop,
  Result,
  message,
  Icon,
  Tooltip,
  Alert,
} from 'antd';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as UtilsProposta from './utils';
import Buttons from '../components/Buttons';
import {
  TitleSection,
  FieldsProducao,
  Container,
  DatePicker,
  FieldsEndereco,
  Error,
  Disable,
  Input,
  Select,
} from '~/components';

import Pagamento from './pagamento';

import {
  ProducaoValidation,
  PropostaValidation,
  PagamentoValidation,
} from '~/yup';

import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PlanoActions from '~/store/ducks/plano';

import { Constants } from '~/utils';

type ResidencialMitsuiProps = {
  onReset: Function,
  onPrevius: Function,
};

export default function ResidencialMitsui({
  onReset,
  onPrevius,
}: ResidencialMitsuiProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    dreMitsui,
    dreMitsui: {
      planoSelected,
      bemEndereco,
      pagamento,
      isOutrosSeguros,
      propostaEletronicaPlanoId,
    },
    producao,
    loading: loadingProposta,
    propostaId,
    contratoId,
  } = useSelector(state => state.proposta);
  const { planos, loading } = useSelector(state => state.plano);
  const { cliente, endereco } = useSelector(state => state.cliente);
  const { regraComissaoCorretora, regraComissaoProdutor } = useSelector(
    state => state.producao
  );

  // Component state
  const [planosAgrupado, setPlanosAgrupado] = useState([]);
  const [planoAExibir, setPlanoAExibir] = useState([]);
  const [planoSelectedKey, setPlanoSelectedKey] = useState([]);
  const [erros, setErros] = useState([]);
  const [dataHoraProposta] = useState(new Date());

  useEffect(() => {
    $('html, body').animate({ scrollTop: 0 }, 300);
    getRegraComissaoCorretora();
    if (bemEndereco && bemEndereco.tipoImovel)
      onChangeTipoImovel(bemEndereco.tipoImovel, false);
  }, []);

  useEffect(() => {
    const planosAgrupado1 = UtilsProposta.formataPlanos(planos);
    setPlanosAgrupado(planosAgrupado1);
  }, [planos]);

  useEffect(() => {
    if (planoSelected) {
      setPlanoSelectedKey([planoSelected.propostaEletronicaPlanoId]);
      setPlanoAExibir([planoSelected]);
    } else if (propostaEletronicaPlanoId) {
      const plano = planosAgrupado.find(
        e => e.propostaEletronicaPlanoId === propostaEletronicaPlanoId
      );
      if (plano) {
        setPlanoSelectedKey([propostaEletronicaPlanoId]);
        onSelectedPlano(plano);
        setPlanoAExibir([plano]);
      }
    }
  }, [planosAgrupado]);

  function getRegraComissaoCorretora() {
    const data = {
      produtoId: Constants.Produtos.DreMitsui,
      seguradoraId: Constants.Seguradoras.Mitsui,
    };
    dispatch(ProducaoActions.getRegraComissaoCorretora(data));
  }

  function changeField(field, value) {
    dispatch(PropostaActions.changeDreMitsui({ field, value }));
  }

  function onChangeEnderecoLocalRisco(value) {
    dispatch(PropostaActions.changeDreMitsui({ field: 'bemEndereco', value }));
  }

  function onChangeTipoImovel(tipoImovel, limpaPlanoId) {
    const newEndereco = { ...dreMitsui.bemEndereco, tipoImovel };
    onChangeEnderecoLocalRisco(newEndereco);

    const param = {
      seguradoraId: Constants.Seguradoras.Mitsui,
      produtoId: Constants.Produtos.DreMitsui,
      tipoImovel,
    };

    setPlanoSelectedKey([]);
    onSelectedPlano();
    setPlanoAExibir([]);
    if (limpaPlanoId) changeField('propostaEletronicaPlanoId');

    dispatch(PlanoActions.getPlanos({ param }));
  }

  function showCoberturas(coberturaId) {
    let isShow = false;
    if (planos) {
      planos.forEach(p => {
        if (p.propostaEletronicaPlanoCoberturas) {
          p.propostaEletronicaPlanoCoberturas.forEach(pc => {
            if (pc.coberturaId === coberturaId) {
              isShow = true;
            }
          });
        }
      });
    }

    return isShow;
  }

  function onSelectedPlano(value) {
    dispatch(
      PropostaActions.changeDreMitsui({
        field: 'planoSelected',
        value,
      })
    );
  }

  async function onSalvar() {
    setErros([]);
    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();

      dispatch(PropostaActions.save({ param }));
    } else {
      setErros(isValid);
      $('html, body').animate({ scrollTop: 0 }, 300);
    }
  }

  async function validaProposta() {
    let response = await validaObjeto(
      ProducaoValidation.ProducaoValidationDre,
      producao
    );
    if (!response.ok) return response;

    response = await validaObjeto(PropostaValidation.DreMitsui, dreMitsui);
    if (!response.ok) return response;

    if (!pagamento.agencia || !pagamento.debitoContaNumeroConta) {
      message.error('Por favor, informe a Agência e o Número da Conta');
      return { ok: false };
    }

    // Se boleto não estiver marcado e informar o Nosso Número
    if (!pagamento.isBoleto && pagamento.propostaEletronicaNossoNumero) {
      message.error(
        'Foi informado o Nosso Número porém nenhuma opção de Boleto está marcada, favor verificar.'
      );
      return { ok: false };
    }

    // Se não escolher nenhuma forma de pagamento boleto ou débito automático
    if (
      !pagamento.isBoleto &&
      !pagamento.isDebitoAutomatico &&
      !pagamento.isDebitoBrb &&
      !pagamento.isDebitoOutrosBancos
    ) {
      message.error(
        'Nenhuma forma de pagamento foi selecionada. Gentileza escolher uma das opções de Boleto,  Débito Automático...'
      );
      return { ok: false };
    }

    if (pagamento.formaPagamento === 1) {
      response = await validaObjeto(PagamentoValidation.AVista, pagamento);
    } else if (pagamento.formaPagamento === 2)
      response = await validaObjeto(
        PagamentoValidation.ParceladoCEntrada,
        pagamento
      );
    else
      response = await validaObjeto(
        PagamentoValidation.ParceladoSEntrada,
        pagamento
      );

    if (!response) return ['Dados de pagamento inválido.'];
    if (!response?.ok) return response;

    return { ok: true };
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function getObjetoEnvio() {
    if (!planoSelected) return null;
    if (!endereco.clienteEnderecoId) cliente.clienteEnderecos.push(endereco);

    return UtilsProposta.formataPropostaEnvio(
      planoSelected,
      bemEndereco,
      producao,
      cliente,
      dreMitsui,
      pagamento,
      regraComissaoCorretora,
      regraComissaoProdutor,
      endereco
    );
  }

  async function onEfetivar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }
    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();
      dispatch(
        PropostaActions.efetivar({ param, callback: callbackErrorEfetivar })
      );
    } else {
      setErros(isValid);
      $('html, body').animate({ scrollTop: 0 }, 300);
    }
  }

  function callbackErrorEfetivar(values) {
    setErros(values);
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  const formataDataHora = (date) => `${new Date(date).toLocaleDateString("pt-br")}, às ${new Date(date).toLocaleTimeString("pt-br", { minute: "2-digit", hour: "2-digit" })}`

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }

  function onChangeValorImovel(value) {
    if (value) {
      const plano = planosAgrupado.find(
        e => e.propostaEletronicaPlanoId === value
      );
      setPlanoSelectedKey([plano.propostaEletronicaPlanoId]);
      onSelectedPlano(plano);
      setPlanoAExibir([plano]);
    } else setPlanoAExibir([]);
  }

  return (
    <>
      <BackTop />
      <Spin spinning={loadingProposta} size="small">
        <Buttons
          onPressVoltar={onPrevius}
          showButtonNovaCotacao={!contratoId}
          onPressNovaCotacao={onNovaCotacao}
          showButtonSalvar={!contratoId}
          onPressSalvar={onSalvar}
          showButtonEfetivar={propostaId > 0 && !contratoId}
          onPressEfetivar={onEfetivar}
          showButtonImprimir={propostaId > 0}
          linkImprimir="impressao-dre"
        />
        <Container>
          {contratoId > 0 && (
            <>
            <Result
              status="success"
              title="Parabéns, produto contratado com sucesso!"
              subTitle={`O número do contrato no Multiseguros é: ${contratoId} e você já pode consultá-lo lá.`}
              extra={[
                <Button
                  className="btn btn-xs-block mb-2 mb-sm-0"
                  type="primary"
                  href={`https://multiseguros${
                    process.env.REACT_APP_BASE_URL.indexOf('hmo') > 0
                      ? 'plushmo'
                      : ''
                  }.brbseguros.com.br/#/proposta-dre/${contratoId}/11407`}
                  target="_blank"
                  key="1"
                >
                  Ir ao Multiseguros
                </Button>,
              ]}
            />
          <Row style={{ marginBottom: 12, marginTop: 18 }}>
            <Alert
              showIcon
              description={`Para as propostas cadastradas após às 16h00, o 1º débito poderá ocorrer somente no próximo dia útil. Essa proposta foi efetivada aproximadamente em: ${formataDataHora(dataHoraProposta)}.`}
              message="Atenção:"
              type="warning">
            </Alert>
          </Row>
          </>
        )}
          <Error erros={erros} />

          <Disable disable={!!contratoId}>
            <h4>BRB Residencial</h4>

            <FieldsProducao
              produto="residencial-mitsui"
              showIsVendaRelacionada
            />

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <div className="form-group">
                  <label>Início da Vigência</label> <br />
                  <DatePicker
                    value={dreMitsui.contratoDREDataInicio}
                    placeholder="Selecione"
                    onChange={value => {
                      changeField('contratoDREDataInicio', value);
                      changeField(
                        'contratoDREDataFim',
                        moment(value).add(1, 'y')
                      );
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="form-group">
                  <label>Fim da Vigência</label> <br />
                  <DatePicker
                    value={dreMitsui.contratoDREDataFim}
                    placeholder="Selecione"
                    onChange={value => changeField('contratoDREDataFim', value)}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="form-group">
                  <label>Há outros seguros cobrindo os mesmos bens?</label>{' '}
                  <br />
                  <Radio.Group
                    onChange={({ target: { value } }) =>
                      changeField('isOutrosSeguros', value)
                    }
                    value={isOutrosSeguros}
                  >
                    <Radio value={1}>Sim</Radio>
                    <Radio value={0}>Não</Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>

            <FieldsEndereco
              title="Local de Risco"
              onChangeEndereco={onChangeEnderecoLocalRisco}
              enderecoRedux={dreMitsui.bemEndereco}
              produtoId={Constants.Produtos.DreMitsui}
              showMesmoCorrespondencia
            />

            <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Cláusula Beneficiária</label>
                  <Tooltip
                    placement="right"
                    title="Pessoa física ou jurídica que foi escolhida para receber a indenização do seguro no caso de ocorrência relacionada as coberturas contratadas."
                  >
                    <Icon
                      type="info-circle"
                      theme="twoTone"
                      style={{ marginLeft: 10, fontSize: 18 }}
                    />
                  </Tooltip>

                  <Input
                    value={dreMitsui.propClausulaBeneficiaria}
                    onChange={value =>
                      changeField('propClausulaBeneficiaria', value)
                    }
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>CPF / CNPJ</label>
                  <Input
                    value={dreMitsui.propClausulaBeneficiariaCpfCnpj}
                    onChange={value =>
                      changeField('propClausulaBeneficiariaCpfCnpj', value)
                    }
                    mask="cpfCnpj"
                    maxLength={18}
                  />
                </div>
              </Col>
            </Row>
          </Disable>

          <TitleSection title="Opções de planos" />
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <div className="form-group">
                <label style={{ marginRight: 10 }}>Tipo de Imóvel</label>
                <Radio.Group
                  onChange={({ target: { value } }) =>
                    onChangeTipoImovel(value, true)
                  }
                  value={bemEndereco?.tipoImovel}
                >
                  <Radio value={2}>Casa</Radio>

                  <Radio value={1}>Apartamento</Radio>
                </Radio.Group>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <div className="form-group">
                <label>Valor do Imóvel </label> (Exibidos de acordo com o tipo
                de imóvel)
                <Select
                  placeholder="Selecione"
                  itens={planosAgrupado}
                  itemText="mtrIncendioQuedeDeRaioExplosao"
                  itemTextSearch="mtrPropostaEletronicaPlanoPremioTotal"
                  renderText={item =>
                    item.mtrIncendioQuedeDeRaioExplosao
                      ? `${item.mtrIncendioQuedeDeRaioExplosao} - ${item.mtrPropostaEletronicaPlanoPremioTotal}`
                      : ''
                  }
                  onChange={onChangeValorImovel}
                  itemValue="propostaEletronicaPlanoId"
                  value={
                    planoSelected?.mtrIncendioQuedeDeRaioExplosao &&
                    `${planoSelected?.mtrIncendioQuedeDeRaioExplosao} - ${planoSelected?.mtrPropostaEletronicaPlanoPremioTotal}`
                  }
                  loading={loading}
                  showSearch
                />
              </div>
            </Col>
            {planoAExibir.length === 1 && (
              <Col span={24}>
                <Table
                  scroll={{ x: 1000 }}
                  rowKey="propostaEletronicaPlanoId"
                  dataSource={planoAExibir}
                  pagination={false}
                  loading={loading}
                  rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys, selectedRows) => {
                      onSelectedPlano(selectedRows[0]);
                      setPlanoSelectedKey(selectedRowKeys);
                    },
                    selectedRowKeys: planoSelectedKey,
                    getCheckboxProps: () => ({
                      disabled: !!contratoId,
                    }),
                  }}
                >
                  {planoAExibir.length > 0 && (
                    <Table.Column
                      width={70}
                      title="Plano"
                      dataIndex="propostaEletronicaPlanoSigla"
                    />
                  )}
                  {showCoberturas(202) && (
                    <Table.Column
                      title="Incêndio/ Queda de Raio/ Explosão"
                      dataIndex="mtrIncendioQuedeDeRaioExplosao"
                    />
                  )}
                  {showCoberturas(245) && (
                    <Table.Column
                      title="Perda de Aluguel"
                      dataIndex="mtrPerdaDeAluguel"
                    />
                  )}
                  {showCoberturas(205) && (
                    <Table.Column
                      title="Danos Elétricos"
                      dataIndex="mtrDanosEletricos"
                    />
                  )}
                  {showCoberturas(277) && (
                    <Table.Column
                      title="Roubo de Bens"
                      dataIndex="mtrRouboDeBens"
                    />
                  )}
                  {showCoberturas(220) && (
                    <Table.Column
                      title="RC Familiar"
                      dataIndex="mtrRCFamiliar"
                    />
                  )}
                  {showCoberturas(223) && (
                    <Table.Column
                      title="Quebra de Vidros"
                      dataIndex="mtrQuebraDeVidros"
                    />
                  )}
                  {showCoberturas(229) && (
                    <Table.Column
                      title="Despesas Fixas"
                      dataIndex="mtrDespesasFixas"
                    />
                  )}
                  {showCoberturas(451) && (
                    <Table.Column
                      title="Vendaval Granizo e Impacto de Veículos"
                      dataIndex="mtrVendavalGranizo"
                    />
                  )}
                  {showCoberturas(496) && (
                    <Table.Column
                      title="RC Estab. Comerciais"
                      dataIndex="mtrRcEstabComerciais"
                    />
                  )}
                  {planoAExibir.length > 0 && (
                    <Table.Column
                      title="Prêmio"
                      dataIndex="mtrPropostaEletronicaPlanoPremioTotal"
                    />
                  )}
                </Table>
              </Col>
            )}
          </Row>
          <span>
            <i>Remuneração pela intermediação de 50% sobre o prêmio</i>
          </span>
          <Disable disable={!!contratoId}>
            <Pagamento />
          </Disable>
          {(pagamento.formaPagamento === 2 ||
            pagamento.formaPagamento === 3) && (
            <span>
              <i>Mitsui não possui parcelamento no boleto</i>
            </span>
          )}
        </Container>
        <Buttons
          onPressVoltar={onPrevius}
          showButtonNovaCotacao={!contratoId}
          onPressNovaCotacao={onNovaCotacao}
          showButtonSalvar={!contratoId}
          onPressSalvar={onSalvar}
          showButtonEfetivar={propostaId > 0 && !contratoId}
          onPressEfetivar={onEfetivar}
          showButtonImprimir={propostaId > 0}
          linkImprimir="impressao-dre"
        />
      </Spin>
    </>
  );
}
