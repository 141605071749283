import React from 'react';
import { Link } from 'react-router-dom';
// import { Container } from './styles';

export default function Profile() {
  return (
    <div>
      <h1>Profile 1</h1>
      <Link to="dashboard">dashboard</Link>
    </div>
  );
}
