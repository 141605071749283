import * as yup from 'yup';
import moment from 'moment';
import { Functions } from '~/utils';

const Cliente = yup
  .object()
  .nullable()
  .required('Por favor, informe o cliente para continuar.')
  .shape({
    clienteNome: yup
      .string()
      .required('Por favor, informe o nome do cliente.')
      .nullable(),
    clienteCpfCnpj: yup
      .string()
      .required('Por favor, informe o cpf ou cnpj.')
      .nullable(),
    clienteRg: yup
      .string()
      .when('clienteCpfCnpj', {
        is: val => val && val.length <= 11, // VALOR FOR CPF
        then: yup
          .string()
          .required('Por favor, informe o número de identidade'),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    clienteRgSsp: yup
      .string()
      .when('clienteCpfCnpj', {
        is: val => val && val.length <= 11, // VALOR FOR CPF
        then: yup.string().required('Por favor, informe o órgão emissor'),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    mtrClienteFaixaRendaValor: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .moreThan(0, 'Por favor, informe a renda mensal')
      .required('Por favor, informe a renda mensal')
      .nullable(),
    clienteContatoFones: yup
      .array()
      .min(1, 'Por favor, informe no mínimo um contato.'),
    // .test(
    //   'telefone',
    //   'Deve ter pelo menos um telefone residêncial',
    //   function v(value) {
    //     return value.filter(t => t.clienteContatoFoneTipoId === 1).length > 0;
    //   }
    // ),
  });
const ClienteCap = Cliente.concat(
  yup
    .object()
    .nullable()
    .shape({
      clienteNome: yup
        .string()
        .required('Por favor, informe o nome do cliente.')
        .nullable(),
      clienteCpfCnpj: yup
        .string()
        .required('Por favor, informe o cpf ou cnpj.')
        .nullable(),
      clienteRg: yup
        .string()
        .when('clienteCpfCnpj', {
          is: val => val && val.length <= 11, // VALOR FOR CPF
          then: yup
            .string()
            .required('Por favor, informe o número de identidade'),
          otherwise: yup.string().nullable(),
        })
        .nullable(),
      clienteRgSsp: yup
        .string()
        .when('clienteCpfCnpj', {
          is: val => val && val.length <= 11, // VALOR FOR CPF
          then: yup.string().required('Por favor, informe o órgão emissor'),
          otherwise: yup.string().nullable(),
        })
        .nullable(),
      clienteContatoFones: yup
        .array()
        .min(1, 'Por favor, informe no mínimo um contato.'),
      clienteContatoMails: yup
        .array()
        .min(1, 'Por favor, informe no mínimo um e-mail.'),
      clienteDataNascimento: yup
        .date()
        .when('clienteCpfCnpj', {
          is: val => val && val.length <= 11, // VALOR FOR CPF
          then: yup
            .date()
            .required('Por favor, informe a data de nascimento do cliente'),
          otherwise: yup.date().nullable(),
        })
        .nullable(),
      sexoId: yup
        .number()
        .required('Por favor, informe o sexo do cliente')
        .nullable(),
      estadoCivilId: yup
        .number()
        .nullable()
        .when('clienteCpfCnpj', {
          is: val => val && val.length <= 11, // VALOR FOR CPF
          then: yup
            .number()
            .required('Por favor, informe o estado civil do cliente'),
          otherwise: yup.number().nullable(),
        }),
      clienteDataExpedicaoRg: yup
        .date()
        .nullable()
        .when('clienteCpfCnpj', {
          is: val => val && val.length < 11, // VALOR FOR CNPJ
          then: yup
            .date()
            .required('Por favor, informe a data de emissão do RG'),
          otherwise: yup.date().nullable(),
        })
        .nullable(),
      // clienteProfissaoId: yup
      //   .number()
      //   .nullable()
      //   .required('Por favor, informe a profissão'),
      // clienteCargoId: yup
      //   .number()
      //   .when('clienteCpfCnpj', {
      //     is: val => val && val.length < 11, // VALOR FOR CNPJ
      //     then: yup.number().required('Por favor, informe o cargo do cliente'),
      //     otherwise: yup.number().nullable(),
      //   })
      //   .nullable(),
      clienteFaixaRendaId: yup
        .number()
        .nullable()
        .required('Por favor, informe a renda mensal'),
    })
);

const ClienteAll = Cliente.concat(
  yup
    .object()
    .nullable()
    .shape({
      clienteDataNascimento: yup
        .date()
        .required('Por favor, informe a data de nascimento do cliente')
        .test(
          'clienteDataNascimento',
          'Esse seguro não pode ser contratado por pessoas maiores que 65 anos, 11 meses e 25 dias.',
          function v(value) {
            const idade = Functions.calculaIdade(value);
            const dataAtual = moment.utc(new Date());
            const dataAniversario = moment.utc(value);

            if (idade > 65) return false;
            if (idade < 65) return true;

            if (idade === 65) {
              let ultimoNiver = moment.utc(
                new Date(
                  dataAtual.year(),
                  dataAniversario.month(),
                  dataAniversario.date()
                )
              );

              // Se ainda não tiver completado aniversário esse ano o último níver foi ano passado
              if (ultimoNiver.toDate() > dataAtual.toDate()) {
                ultimoNiver = ultimoNiver.add(-1, 'year');
              }

              // Quantos dias tem que completou aniversário
              const diff = dataAtual.diff(ultimoNiver);
              const diffDias = moment.duration(diff).asDays();

              // Verifica ano bisiesto
              if (dataAtual.month(1).daysInMonth() > 28) {
                if (diffDias >= 361) return false;
              } else if (diffDias >= 360) return false;
            }

            return true;
          }
        )
        .nullable(),
      sexoId: yup
        .number()
        .required('Por favor, informe o sexo do cliente')
        .nullable(),
      estadoCivilId: yup
        .number()
        .required('Por favor, informe o estado civil do cliente')
        .test(
          'estadoCivilId',
          'O Estado Civil do cliente não pode ser OUTROS, favor selecionar outra opção.',
          function v(value) {
            if (value === 6) return false;

            return true;
          }
        )
        .nullable(),
      clienteDataExpedicaoRg: yup
        .date()
        .nullable()
        .test(
          'clienteDataExpedicaoRg',
          'Por favor, informe a data de emissão do RG',
          function v(val) {
            const { clienteCpfCnpj } = this.parent;

            if (clienteCpfCnpj?.length > 11) return true;

            if (val === undefined || val === null) return false;

            return true;
          }
        ),
      naturalidadeId: yup
        .number()
        .nullable()
        .required('Por favor, informe a nacionalidade'),
      clienteProfissaoId: yup
        .number()
        .nullable()
        .required('Por favor, informe a profissão do cliente'),
      clienteCargoId: yup
        .number()
        .test(
          'clienteCargoId',
          'Por favor, informe o cargo do cliente',
          function v(val) {
            const { clienteCpfCnpj } = this.parent;

            if (clienteCpfCnpj?.length > 11) return true;

            if (val === undefined || val === null) return false;

            return true;
          }
        )
        .nullable(),
      mtrClienteFaixaRendaValor: yup
        .number()
        .transform(val => (Number.isNaN(val) ? undefined : val))
        .moreThan(0, 'Por favor, informe a renda mensal')
        .required('Por favor, informe a renda mensal')
        .nullable(),
      clienteRamoAtividade: yup
        .string()
        .required('Por favor, informe o ramo de atividade.')
        .nullable(),
    })
);

const ClienteBrbMaster = Cliente.concat(
  yup.object().nullable().shape({
    clienteDataNascimento: yup
      .date()
      .required('Por favor, informe a data de nascimento do cliente')
      .nullable(),
    sexoId: yup
      .number()
      .required('Por favor, informe o sexo do cliente')
      .nullable(),
    estadoCivilId: yup
      .number()
      .nullable()
      .required('Por favor, informe o estado civil do cliente'),
    clienteDataExpedicaoRg: yup
      .date()
      .nullable()
      .required('Por favor, informe a data de emissão do RG'),
    naturalidadeId: yup
      .number()
      .nullable()
      .required('Por favor, informe a nacionalidade'),
    clienteProfissaoBrbMaster: yup
      .string()
      .nullable()
      .required('Por favor, informe a profissão do cliente'),
    clientePpeId: yup
      .number()
      .nullable()
      .required('Por favor, informe se o cliente é pessoa politicamente exposta'),
    clienteFaixaRendaId: yup
      .number()
      .nullable()
      .required('Por favor, informe a renda mensal'),
    clienteNrFilhos: yup
      .string()
      .required('Por favor, informe o número de filhos')
      .nullable(),
    clienteProfissaoCategoria: yup
      .string()
      .required('Por favor, informe a categoria da profissão do cliente')
      .nullable(),
    clienteContatoMails: yup
        .array()
        .min(1, 'Por favor, informe no mínimo um e-mail.'),
  })
);

const Endereco = yup.object().shape({
  clienteEnderecoCep: yup
    .number('Cep está no formato incorreto.')
    .transform(val => (Number.isNaN(val) ? undefined : val))
    .required('Por favor, informe o cep do endereço.')
    .nullable(),
  clienteEndereco1: yup
    .string()
    .required('Por favor, informe o endereço')
    .nullable(),
  clienteEnderecoUf: yup
    .string()
    .required('UF é obrigatório')
    .nullable(),
  clienteEnderecoNumero: yup
    .number()
    .transform(val => (Number.isNaN(val) ? undefined : val))
    .required('Por favor, informe somente o número do endereço')
    .nullable(),
  clienteEnderecoBairro: yup
    .string()
    .required('Por favor, informe o bairro.')
    .nullable(),
});

const EnderecoPersonalizavelLiberty = yup.object().shape({
  clienteEnderecoBairro: yup
    .string()
    .test('clienteEnderecoBairro', 'O nome do bairro deve ter no máximo 30 caracteres', function v(val) {
      if(!val || !((val ?? '').trim())) return true;
      
      return val.length <= 30;
    }),
    clienteEnderecoComplemento: yup
    .string()
    .test('clienteEnderecoComplemento', 'O complemento do endereço deve ter no máximo 16 caracteres', function v(val) {
      if(!val || !((val ?? '').trim())) return true;
      
      return val.length <= 16;
    })
    .nullable(),
});

const ClienteResidencialPersonalizavelLiberty = yup
  .object()
  .nullable()
  .shape({
    clienteDataNascimento: yup
      .date()
      .test('clienteDataNascimento', 'Por favor, informe a data de nascimento do cliente', function v(val) {
        const { clienteCpfCnpj } = this.parent;
        
        if (clienteCpfCnpj?.length > 11) return true;

        if (val === undefined || val === null) return false;
        
        return true;
      })
      .nullable(),
    clienteRg: yup
      .string()
      .when('clienteCpfCnpj', {
        is: val => val && val.length <= 11, // VALOR FOR CPF
        then: yup
          .string()
          .required('Por favor, informe o número de identidade'),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    clienteRgSsp: yup
      .string()
      .when('clienteCpfCnpj', {
        is: val => val && val.length <= 11, // VALOR FOR CPF
        then: yup.string().required('Por favor, informe o órgão emissor'),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    clienteNome: yup
      .string()
      .required('Por favor, informe o nome do cliente.')
      .nullable(),
    clienteCpfCnpj: yup
      .string()
      .required('Por favor, informe o cpf ou cnpj.')
      .nullable(),
    sexoId: yup
      .number()
      .required('Por favor, informe o sexo do cliente')
      .test('sexoId', 'A opção para sexo deve ser Masculino ou Feminino', function v(val) {
        const { clienteCpfCnpj } = this.parent;
        if (clienteCpfCnpj?.length > 11) return true;

        if(val === 1 || val === 2) return true;
        
        return true;
      })
      .nullable(),
    estadoCivilId: yup
      .number()
      .nullable()
      .test('estadoCivilId', `As opções 'Não identificado' e 'Outros' não são aceitas como estado civil do cliente para esse produto`, function v(val) {
        const { clienteCpfCnpj } = this.parent;
        if (clienteCpfCnpj?.length > 11) return true;

        if(val === -2 || val === 6) return false;
        
        return true;
      })
      .when('clienteCpfCnpj', {
        is: val => val && val.length <= 11, // VALOR FOR CPF
        then: yup.number().required('Por favor, informe o estado civil do cliente'),
        otherwise: yup.number().nullable(),
      }),
    clienteDataExpedicaoRg: yup
      .date()
      .nullable()
      .test(
        'clienteDataExpedicaoRg',
        'Por favor, informe a data de emissão do RG',
        function v(val) {
          const { clienteCpfCnpj } = this.parent;

          if (clienteCpfCnpj?.length > 11) return true;

          if (val === undefined || val === null) return false;

          return true;
        }
      ),
    naturalidadeId: yup
      .number()
      .nullable()
      .test(
        'naturalidadeId',
        'Por favor, informe a nacionalidade',
        function v(val) {
          const { clienteCpfCnpj } = this.parent;

          if (clienteCpfCnpj?.length > 11) return true;

          if (!val) return false;

          return true;
        }
      ),
    clienteProfissaoId: yup
      .number()
      .nullable()
      .test(
        'clienteProfissaoId',
        'Por favor, informe a profissão do cliente',
        function v(val) {
          const { clienteCpfCnpj } = this.parent;

          if (clienteCpfCnpj?.length > 11) return true;

          if (!val) return false;

          return true;
        }
      ),
    clienteCargoId: yup
      .number()
      .test(
        'clienteCargoId',
        'Por favor, informe o cargo do cliente',
        function v(val) {
          const { clienteCpfCnpj } = this.parent;

          if (clienteCpfCnpj?.length > 11) return true;

          if (!val) return false;

          return true;
        }
      )
      .nullable(),
    mtrClienteFaixaRendaValor: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .test(
        'mtrClienteFaixaRendaValor',
        'Por favor, informe a renda mensal',
        function v(val) {
          const { clienteCpfCnpj } = this.parent;

          if (clienteCpfCnpj?.length > 11) return true;

          if (!val) return false;

          return true;
        }
      )
      .nullable(),
    clienteContatoFones: yup
      .array()
      .min(1, 'Por favor, informe no mínimo um contato.'),
    clienteContatoMails: yup
      .array()
      .min(1, 'Por favor, informe no mínimo um email.')
  });

export default {
  Cliente,
  ClienteAll,
  ClienteResidencialPersonalizavelLiberty,
  ClienteBrbMaster,
  Endereco,
  EnderecoPersonalizavelLiberty,
  ClienteCap,
};
