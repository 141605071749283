import { Col, Row, Spin, Radio, Switch } from 'antd';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message as toast } from 'antd';

import {
  Container,
  DatePicker, Disable, FieldsEndereco,
  Select,
  TitleSection
} from '~/components';
import * as LibertyResidencialPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';
import { Constants } from '~/utils';

type CotacaoPrimeiroPassoResidencialPersonalizavelLibertyProps = {
  onReset: Function,
  onPrevius: Function,
  onNext: Function
};

export default function CotacaoPrimeiroPassoResidencialPersonalizavelLiberty({
  onReset,
  onPrevius,
  onNext
}: CotacaoPrimeiroPassoResidencialPersonalizavelLibertyProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    contratoId,
  } = useSelector(state => state.proposta);

  const { libertyResidencialPersonalizavel, libertyResidencialPersonalizavel: {
    cotacao, listaNomesDominios
  } } = useSelector(state => state);


  function changeFieldCotacao(field, value) {
    dispatch(LibertyResidencialPersonalizavelActions.changeCotacaoState({ field, value }));
  }

  function changeFieldLocalRisco(field, value) {
    
    var obj = {
      ...cotacao,
      localRisco: {
        ...cotacao.localRisco,
        [field]: value
      }
    }

    tratarExibirMensagemRiscoNaoCoberto(field, value);
    obj = tratarZerarCampos(field, obj);  

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'cotacao', value: obj }))
  }

  function tratarZerarCampos(field, obj) {
    if(field === 'tipoOcupacaoId')
      obj.localRisco.tipoAtividadeProfissionalExercidaId = null;

    if(field === 'tipoHabitacaoId')
      obj.localRisco.tipoConstrucaoId = null;

    return obj;
  }

  function tratarExibirMensagemRiscoNaoCoberto(field, value) {
    var listaPropriedade = ['imovelDesocupadoEmRetormaOuEmConstrucao', 'imovelEhPatrimonioHistorico'];

    if(!!listaPropriedade.some(x => x === field) && !!value)
      toast.warn(`Risco não coberto pela Seguradora`, 5);

  }

  function onChangeEnderecoLocalRisco(value) {
    dispatch(LibertyResidencialPersonalizavelActions.changeEnderecoCotacao(value));
  }

  function ehUtilizacaoHomeOffice(){
    var retorno = false;

    var tipoOcupacaoId = cotacao?.localRisco?.tipoOcupacaoId ?? '0';

    retorno = !!(retornaListaTipoOcupacao()
      .find(x => x.dominioParceiroCodigo === tipoOcupacaoId && (x.dominioParceiroDescricao ?? '').toUpperCase() === "HOME OFFICE"))

    return retorno;
  }

  function retornaListaDominio(nomeLista) {
    var retorno = [];

    var retorno = ((listaNomesDominios ?? [])
      .find(x => x.dominioParceiroNomeDescricao.includes(nomeLista)) ?? { dominioParceiros: [] }).dominioParceiros;

    return retorno;
  }

  function retornaListaTipoConstrucao() {
    var lista = retornaListaDominio('Tipo Construção');

    if(cotacao?.localRisco?.tipoHabitacaoId === Constants.ResidencialPersonalizavelLiberty.TipoHabitacao.Apartamento)
      lista = (lista ?? []).filter(x => x.dominioParceiroCodigo !== Constants.ResidencialPersonalizavelLiberty.TipoConstrucao.Madeira)
    return lista;
  }

  function retornaListaTipoHabitacao() {
    return retornaListaDominio('Tipo Habitação')
  }

  function retornaListaTipoOcupacao() {
    return retornaListaDominio('Tipo Ocupação')
  }

  function retornaListaTipoAtividadeExercida() {
    return retornaListaDominio('Atividade Profissional Exercida na Residência')
  }

  return (
    <>
      <Spin spinning={!!(libertyResidencialPersonalizavel.loading ?? []).length} size="small">

        <Container>

          <Disable disable={!!contratoId}>

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Início da Vigência</label> <br />
                  <DatePicker
                    value={cotacao.dataInicioVigencia}
                    placeholder="Selecione"
                    onChange={value => {
                      changeFieldCotacao('dataInicioVigencia', value);
                      if(value) changeFieldCotacao('dataFimVigencia', moment(value).add(1, 'y'));
                      else changeFieldCotacao('dataFimVigencia', null)
                    }}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Fim da Vigência</label> <br />
                  <DatePicker
                    value={cotacao.dataFimVigencia}
                    placeholder="Selecione"
                    onChange={value => changeFieldCotacao('dataFimVigencia', value)}
                  />
                </div>
              </Col>
            </Row>

            <FieldsEndereco
              title="Local de Risco"
              onChangeEndereco={onChangeEnderecoLocalRisco}
              enderecoRedux={cotacao.localRisco.endereco}
              produtoId={Constants.Produtos.LibertyResidencialPersonalizavel}
              showMesmoCorrespondencia
            />

            <TitleSection title="Dados do Imóvel" />

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Tipo do Imóvel</label>
                  <Select
                    defaultValue="Selecione"
                    itens={retornaListaTipoHabitacao()}
                    itemValue="dominioParceiroCodigo"
                    itemText="dominioParceiroDescricao"
                    value={cotacao.localRisco.tipoHabitacaoId}
                    onChange={value =>
                      changeFieldLocalRisco('tipoHabitacaoId', value)
                    }
                    showSearch
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Tipo de utilização</label>
                  <Select
                    defaultValue="Selecione"
                    itens={retornaListaTipoOcupacao()}
                    itemValue="dominioParceiroCodigo"
                    itemText="dominioParceiroDescricao"
                    value={cotacao.localRisco.tipoOcupacaoId}
                    onChange={value =>
                      changeFieldLocalRisco('tipoOcupacaoId', value)
                    }
                    showSearch
                  />
                </div>
              </Col>
              {
                ehUtilizacaoHomeOffice() && <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                  <div className="form-group">
                    <label>Tipo de home office</label>
                    <Select
                      defaultValue="Selecione"
                      itens={retornaListaTipoAtividadeExercida()}
                      itemValue="dominioParceiroCodigo"
                      itemText="dominioParceiroDescricao"
                      value={cotacao.localRisco.tipoAtividadeProfissionalExercidaId}
                      onChange={value =>
                        changeFieldLocalRisco('tipoAtividadeProfissionalExercidaId', value)
                      }
                      showSearch
                    />
                  </div>
                </Col>
              }
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Tipo de construção</label>
                  <Select
                    defaultValue="Selecione"
                    itens={retornaListaTipoConstrucao()}
                    itemValue="dominioParceiroCodigo"
                    itemText="dominioParceiroDescricao"
                    value={cotacao.localRisco.tipoConstrucaoId}
                    onChange={value =>
                      changeFieldLocalRisco('tipoConstrucaoId', value)
                    }
                    showSearch
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="form-group">
                  <Row gutter={[10, 10]}>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                      <label>Segurado e o proprietário do imóvel?</label>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                      <Switch checkedChildren="Sim" 
                        unCheckedChildren="Não" 
                        defaultChecked={cotacao.localRisco.seguradoEhProprietarioDoImovel}  
                        onChange={value => changeFieldLocalRisco('seguradoEhProprietarioDoImovel', value)} 
                      />
                    </Col>
                  </Row>
                  
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="form-group">
                  <Row gutter={[10, 10]}>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                    <label>Imóvel tombado pelo patrimônio histórico?</label>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                      <Switch checkedChildren="Sim" 
                        unCheckedChildren="Não" 
                        defaultChecked={cotacao.localRisco.imovelEhPatrimonioHistorico}  
                        onChange={value => changeFieldLocalRisco('imovelEhPatrimonioHistorico', value)} 
                      />
                    </Col>
                  </Row>  
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="form-group">
                  <Row gutter={[10, 10]}>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                    <label>Imóvel desocupado, em reforma ou em construção?</label>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                      <Switch checkedChildren="Sim" 
                        unCheckedChildren="Não" 
                        defaultChecked={cotacao.localRisco.imovelDesocupadoEmRetormaOuEmConstrucao}  
                        onChange={value => changeFieldLocalRisco('imovelDesocupadoEmRetormaOuEmConstrucao', value)} 
                      />
                    </Col>
                  </Row>  
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="form-group">
                  <Row gutter={[10, 10]}>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                    <label>Possui madeira nas paredes externas?</label>
                    </Col>
                    <Col xs={{span: 24}} sm={{span: 10}}>
                      <Switch checkedChildren="Sim" 
                        unCheckedChildren="Não" 
                        defaultChecked={cotacao.localRisco.imovelPossuiMadeiraParedesExternas}  
                        onChange={value => changeFieldLocalRisco('imovelPossuiMadeiraParedesExternas', value)} 
                      />
                    </Col>
                  </Row>  
                </div>
              </Col>
            </Row>
          </Disable>
        </Container>
      </Spin>
    </>
  );
}
