function retornaDescricaoFormaPagamento(coditoTipoPagamento, codigoTipoPlano){
    switch(coditoTipoPagamento){
        case 'FB':
            return codigoTipoPlano === '0050' 
                ? 'Boleto bancário (Santander)' 
                : 'Boleto bancário (BRB)'
        case 'DC':
            return codigoTipoPlano === '0171' 
                ? 'Débito em conta com a primeira no boleto'
                : 'Débito em conta'
        default:
            return ''
    }
}

export default {
    retornaDescricaoFormaPagamento
}