import api from '.';

export const getByCpf = cpf => api().get(`api/clientes/${cpf}`);
export const getById = id => api().get(`api/clientes/id/${id}`);
export const getContas = cpf => api().get(`api/clientes/contas/${cpf}`);
export const getProfissaoLibertyByDescricao = descricao => api().get(`api/clientes/profissaoLiberty/${descricao}`);
export const getContasContratos = cpf => api().get(`api/clientes/contas-contratos/${cpf}`);
export const getSolicitacaoAlteracaoConta = () => api().get(`api/clientes/solicitacao-alteracao-conta`);
export const postContaContrato = contaCliente => api().post('api/clientes/altera-conta-contrato', contaCliente);
export const postAprovaAlteracaoConta = analiseConta => api().post('api/clientes/aprova-alteracao-conta', analiseConta);
