import styled from 'styled-components';

export const Container = styled.div`
  border: 1px;
  border-style: solid;
  border-color: #d3d3d3;
  color: #000;
  font-size: 13px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const Block = styled.div`
  margin: 2px 2px 2px 2px;
  border: 1px;
  border-style: solid;
  border-color: #d3d3d3;
  padding: 3px 3px 3px 3px;
`;

export const BlockTable = styled.div`
  border: 1px;
  border-style: solid;
  border-color: #d3d3d3;
  margin: 2px 2px 2px 2px;
  /* padding: 0px 1px 0px 1px; */
  max-width: 109px;
  min-height: ${({ minHeight }) => (minHeight ? '60px' : '0px')};
  text-align: center;
  flex: 1;
  font-weight: ${({ bold }) => (bold ? 'bold' : '')};
`;
