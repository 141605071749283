import { Button, Col, Input, Radio, Result, Row } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Error, Select, TitleSection } from '~/components';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PropostaActions from '~/store/ducks/proposta';
import { Constants } from '~/utils';
import { PropostaValidation } from '~/yup';
import Buttons from '../../components/Buttons';


type PerguntasResumoRiscoProps = {
    produtoId: Number,
};

export default function PerguntasResumoRisco({ ProdutoId }: PerguntasResumoRiscoProps) {

    const RespostaDefaultRadioOption = [
        { value: 22, label: 'Não' },
        { value: 21, label: 'Sim' }
    ];
    const {
        proposta: {
            apLiberty: proposta,
            contasDebito,
            loading,
            propostaId,
            contratoId,
            perguntasProduto,
            d,
        },
        cliente: { cliente },
    } = useSelector(state => state);

    const [validateStatus, setValidateStatus] = useState('');
    const [erros, setErros] = useState([]);
    const dispatch = useDispatch();

    function modelRespostasDadas(perguntaProdutoId) {
        proposta.respostasDps = proposta.respostasDps && !Array.isArray(proposta.respostasDps)
            ? proposta.respostasDps : { respostasObservacao: null, listaRespostas: [] };

        if (!proposta.respostasDps.listaRespostas.find(x => x.perguntaProdutoId === perguntaProdutoId))
            proposta.respostasDps.listaRespostas
                .push({ perguntaProdutoId: perguntaProdutoId, idRespostaPerguntaProduto: null, idPessoaRespostaPerguntaProduto: 1 })

        return proposta.respostasDps.listaRespostas.find(x => x.perguntaProdutoId === perguntaProdutoId)?.idRespostaPerguntaProduto;
    }


    useEffect(() => {
        if (loading) {
            setValidateStatus('validating');
        } else {
            setValidateStatus('');
        }
    }, [loading]);

    useEffect(() => {
        dispatch(PropostaActions.searchPerguntasProduto({ ProdutoId: ProdutoId }));
        setPessoaRespostaPergunta();
    }, []);



    function setPessoaRespostaPergunta() {
        (perguntasProduto || []).forEach(x => {
            changeResposta(x.perguntaProdutoId, 1, 'idPessoaRespostaPerguntaProduto')
        });
    }

    function callbackErrorEfetivar(values) {
        setErros(values);
    }

    async function validaRespostas() {
        let response = await validaObjeto(PropostaValidation.RespostasDpsAtividades, proposta.respostasDps);
        return !response.ok ? response : { ok: true };
    }

    function validaObjeto(validation, obj) {
        return validation
            .validate(obj, { abortEarly: false })
            .then(() => ({ ok: true }))
            .catch(error => error.errors);
    }

    function onNovaCotacao() {
        dispatch(ClienteActions.initCliente());
        dispatch(ProducaoActions.initProducao());
        dispatch(PropostaActions.initProposta());
    }

    function changeResposta(perguntaProdutoId, valor, propriedade) {

        var respostasDadas = { ...proposta.respostasDps, };
        var pergunta = respostasDadas.listaRespostas.find(x => x.perguntaProdutoId === perguntaProdutoId);
        var index = respostasDadas.listaRespostas.indexOf(pergunta);

        pergunta[propriedade] = valor;

        respostasDadas.listaRespostas.splice(index, 1, pergunta);

        dispatch(PropostaActions.changeApLiberty({ respostasDps: respostasDadas }));
    }

    function onChangeObservacaoRespostas(ev) {

        const valor = ev.target.value
        var objRespostas = { ...proposta.respostasDps };
        objRespostas.respostasObservacao = valor;

    }

    return (
        <>
            {
                (perguntasProduto || []).map((x, i) =>
                    <Row key={x.perguntaProdutoId} style={{ paddingLeft: 15, background: "#f7f7f7", marginBottom: 10 }}>
                        <div className="form-group">
                            <Col xs={{ span: 24 }} sm={{ span: 20 }}>
                                <label> {x.perguntaProdutoDescricao} </label>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 4 }}
                                style={{ paddingRight: 0 }}>

                                <Radio.Group
                                    buttonStyle="solid"
                                    defaultValue={modelRespostasDadas(x.perguntaProdutoId)}
                                    options={RespostaDefaultRadioOption}
                                    onChange={({ target: { value } }) =>
                                        changeResposta(x.perguntaProdutoId, value, 'idRespostaPerguntaProduto')
                                    }

                                />
                            </Col>
                        </div>
                        <div>&nbsp;</div>
                    </Row>
                )
            }
        </>
    );
}


