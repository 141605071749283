import { message as toast } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as LoginSeguradorasApi from '~/api/login-seguradoras.api';
import { getResponseFile } from '~/api/utils';
import * as LoginSeguradorasActions from '~/store/ducks/login-seguradoras';

function* downloadFile() {
  
  // Call api
  const response = getResponseFile(yield call(LoginSeguradorasApi.downloadFile), 'Login Seguradoras.exe');
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }
}

function* fail(message) {
  yield put(LoginSeguradorasActions.failedLoginSeguradoras());
  toast.error(message);
}

export default all([
  takeLatest(LoginSeguradorasActions.types.DOWNLOAD_FILE, downloadFile),
]);
