import React, { useState } from 'react';
import { Row, Col, Table, Card, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import * as ClienteActions from '~/store/ducks/cliente';
import { Input } from '~/components/';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const emailSitesScheme = yup.object().shape({
  clienteContatoMailDescricao: yup
    .string()
    .required('Email/Site é obrigatório'),
});

export default function EmailsSites() {
  const { clienteContatoMails } = useSelector(({ cliente }) => cliente.cliente);

  // Component state
  const [contato, setContato] = useState({});

  const dispatch = useDispatch();

  function onAddEmailSite() {
    emailSitesScheme
      .validate(contato, { abortEarly: false })
      .then(() => {
        if (checkEmailSiteExist() && checkEmailValido()) {
          clienteContatoMails.push(contato);
          updateFonesCliente();
        }
      })
      .catch(onError);
  }

  function onDeleteMailSite(index) {
    clienteContatoMails.splice(index, 1);
    updateFonesCliente();
  }

  function updateFonesCliente() {
    dispatch(
      ClienteActions.changeCliente({
        field: 'clienteContatoMails',
        value: clienteContatoMails,
      })
    );
  }

  function checkEmailSiteExist() {
    if (!clienteContatoMails) return true;
    const emailSite = clienteContatoMails.find(
      t => t.clienteContatoMailDescricao === contato.clienteContatoMailDescricao
    );
    if (emailSite) {
      message.error('O email/site já foi informado.');
      return false;
    }

    return true;
  }

  function checkEmailValido() {
    const email = contato.clienteContatoMailDescricao;
    let isValido = true;

    // Não tem @ e tem gmail, falso
    if (email.indexOf('@') === -1 && email.indexOf('gmail') !== -1) {
      isValido = false;
    }
    // Não tem @, verdadeiro pois será considerado site
    if (email.indexOf('@') === -1) {
      isValido = true;
    }
    // Nao pode ter espaço
    if (email.indexOf(' ') !== -1) {
      isValido = false;
    }
    // Deve ter ponto
    if (email.indexOf('.') === -1) {
      isValido = false;
    }

    if (!isValido) {
      message.error(
        `E-mail "${email}" inválido. (Não pode conter espaços, deve conter @.)`
      );
    }
    return isValido;
  }

  function onError(error) {
    let erros = '';
    error.errors.map(e => (erros += `• ${e} \n`));
    message.error(erros);
  }

  return (
    <Card title="Emails/Site">
      <Row gutter={[16, 40]}>
        <Col xs={{ span: 24 }} sm={{ span: 10 }}>
          <div className="form-group">
            <label>Email/Site</label>
            <Input
              placeholder="Email ou Site"
              value={contato.clienteContatoMailDescricao}
              onChange={clienteContatoMailDescricao =>
                setContato({ ...contato, clienteContatoMailDescricao })
              }
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 10 }}>
          <div className="form-group">
            <label>Observação</label>
            <Input
              placeholder="Observação"
              value={contato.clienteContatoMailObservacao}
              onChange={clienteContatoMailObservacao =>
                setContato({ ...contato, clienteContatoMailObservacao })
              }
            />
          </div>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 2 }} style={{ marginTop: 29 }}>
          <Button type="primary" onClick={onAddEmailSite}>
            <PlusOutlined />  Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} style={{ marginTop: 29 }}>
          <Table
            rowKey="clienteContatoMailDescricao"
            dataSource={clienteContatoMails}
            pagination={false}
            scroll={{ x: 600 }}
          >
            <Table.Column
              title="Email/Site"
              dataIndex="clienteContatoMailDescricao"
            />
            <Table.Column
              title="Observação"
              dataIndex="clienteContatoMailObservacao"
            />

            <Table.Column
              title="Ações"
              render={(text, reco, index) => (
                <Button
                  size="small"
                  type="danger"
                  onClick={() => onDeleteMailSite(index)}
                >
                  <DeleteOutlined />  Excluir
                </Button>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Card>
  );
}
