import { Col, Divider, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';

import TabelaSinistroDetalhes from './tabela-sinistro-detalhes';

const { Column } = Table;

type HabitacionalTabProp = {
  data?: Object
}

function ordenarPorPropriedade(lista, posicaoOrdenar = null) {
  return lista.sort((a, b) => a[posicaoOrdenar] - b[posicaoOrdenar]);
}

export default function HabitacionalDetalhe(param: HabitacionalTabProp) {

  const contrato = param?.data || {};

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        Dados do Contrato
      </Divider>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Número Contrato">Número Contrato</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Número Contrato">{ contrato?.contratoHabitacionalNumeroContrato }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Produto">Produto</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Produto">{ contrato?.produtoDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Seguradora">Seguradora</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Seguradora">{ contrato?.seguradoraDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 4 }}>
          <Row>
            <Col span={24}>
              <strong title="Situação">Situação</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Situação">
                { contrato?.contratoStatusDescricao }                
              </span>
              
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 12 }}>
          <Row>
            <Col span={24}>
              <strong title="Valor financiamento">Valor financiamento</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Valor financiamento">{ util.Functions.formatarMoeda(contrato?.valorFinanciamento) }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 12 }}>
          <Row>
            <Col span={24}>
              <strong title="Prazo">Prazo</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Prazo">{ contrato?.prazo }</span>
            </Col>
          </Row>
        </Col>
        
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        {
          contrato?.pagamentos && !!contrato.pagamentos.length &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Parcelas
              </Divider>
              <Table
                rowKey="pagamentoId"
                dataSource={ordenarPorPropriedade(contrato?.pagamentos || [], 'pagamentoParcelaNumero')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="Nº Parcela"
                  align="center"
                  dataIndex="pagamentoParcelaNumero"
                  width={6}
                />
                <Column
                  title="Data competência"
                  width={10}
                  align="center"
                  dataIndex="pagamentoDataCompetencia"
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />
                <Column
                  title="Prêmio total"
                  width={12}
                  align="center"
                  dataIndex="pagamentoPremioTotal"
                  render={(text, record) => (
                    text
                      ? <span>{util.Functions.formatarMoeda(text)}</span>
                      : ''
                  )}
                />
                <Column
                  title="Situação"
                  align="center"
                  width={8}
                  dataIndex="pagamentoStatusDescricao"
                />

              </Table>
            </Col>
          </>
        }
        {
          contrato?.coberturas && !!contrato.coberturas.length &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Coberturas
              </Divider>
              <Table
                rowKey="coberturaId"
                dataSource={ordenarPorPropriedade(contrato?.coberturas || [], 'coberturaDescricao')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="Garantia"
                  align="center"
                  dataIndex="coberturaDescricao"
                  width={15}
                />
                <Column
                  title="Capital"
                  width={10}
                  align="center"
                  dataIndex="coberturaValor"
                  render={(text, record) => (
                    <span>{util.Functions.formatarMoeda(text || '0')}</span>
                  )}
                />
              </Table>
            </Col>
          </>
        }
        {
          contrato?.sinistros && !!contrato.sinistros.length &&
          <TabelaSinistroDetalhes data={ordenarPorPropriedade(contrato.sinistros, 'sinistroId')}>
          </TabelaSinistroDetalhes>
        }
        
      
      </Row>
      
    </>
  );
};