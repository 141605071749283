import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import cliente from './cliente';
import dominio from './dominio';
import dne from './dne';
import proposta from './proposta';
import plano from './plano';
import producao from './producao';
import jogaJunto from './joga-junto';
import dashboard from './dashboard';
import pesquisaGeral from './pesquisa-geral';
import libertyResidencialPersonalizavel from './liberty-residencial-personalizavel';
import brbMaster from './brb-master';
import loginSeguradoras from './login-seguradoras';
import agendaPositiva from './agenda-positiva';
import acumuloRisco from './acumulo-risco';
import cotagro from './cotagro';

/* Blacklist */
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['dne', 'plano', 'dominio'],
  whitelist: ['auth', 'cliente', 'proposta', 'producao', 'jogaJunto', 'dashboard', 'libertyResidencialPersonalizavel', 'brbMaster', 'loginSeguradoras', 'cotagro'],
};

/* Whitelist */
// const persistConfigAuth = {
//   key: 'auth',
//   storage,
//   whitelist: ['auth', 'cliente', 'proposta', 'producao'],
// };

// const persistConfigApp = {
//   key: 'app',
//   storage,
//   whitelist: ['cliente'],
// };

const reducers = combineReducers({
  auth, // : persistReducer(persistConfigAuth, auth),
  cliente, // : persistReducer(persistConfigApp, cliente),
  dominio,
  dne,
  proposta,
  plano,
  producao,
  jogaJunto,
  dashboard,
  pesquisaGeral,
  libertyResidencialPersonalizavel,
  brbMaster,
  loginSeguradoras,
  agendaPositiva,
  acumuloRisco,
  cotagro
});

export default persistReducer(persistConfig, reducers);
