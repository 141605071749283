import React, { useEffect, useState } from 'react';
import { Button, Table, Divider, Form, Row, Col, Radio, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import add from 'date-fns/add';
import history from '~/services/history';
import { Container, ContentHeader, DatePicker, Input } from '~/components';

import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as DominiosActions from '~/store/ducks/dominio';
import * as ProducaoActions from '~/store/ducks/producao';
import * as LibertyResidencialPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';
import * as PlanoActions from '~/store/ducks/plano';

import 'antd/dist/antd.css';
import { Functions, Constants } from '~/utils';
import { func } from 'prop-types';
import functions from '~/utils/functions';

const { Column } = Table;

export default function PesquisarCotacoesPropostas() {
  const dispatch = useDispatch();

  const { usuario } = useSelector(({ auth }) => auth);
  const { propostas, loading } = useSelector(({ proposta }) => proposta);
  const dominios = useSelector(({ dominio }) => dominio);

  const [cpfCnpj, setCpfCnpj] = useState();

  const [periodo, setPeriodo] = useState([
    moment()
      .add(-5, 'd')
      .toDate(),
    moment().toDate(),
  ]);
  const [filtros, setFiltros] = useState({});
  const [loadingSelected, setLoadingSelected] = useState(false);

  const {
    ApLiberty: { planos },
  } = Constants;

  useEffect(() => {
    if (periodo) {
      setFiltros({
        ...filtros,
        periodoDataInicial: moment(periodo[0]).format('YYYY-MM-DD'),
        periodoDataFinal: moment(periodo[1]).format('YYYY-MM-DD'),
      });
    }
  }, [periodo]);

  useEffect(() => {
    dispatch(PlanoActions.savePlanos([]));

    if (
      !dominios.horaCarregada ||
      moment(Date.now())
        .add(-2, 'hour')
        .isAfter(dominios.horaCarregada)
    )
      dispatch(DominiosActions.listAll(firtSearch));
    else firtSearch();
  }, []);

  function isCap(produtoId) {
    return (
      produtoId === Constants.Produtos.CapIcatuPU ||
      produtoId === Constants.Produtos.CapIcatu24 ||
      produtoId === Constants.Produtos.CapIcatu48 ||
      produtoId === Constants.Produtos.CapIcatu60 ||
      produtoId === Constants.Produtos.CapIcatu84
    );
  }
  function firtSearch() {
    const isNoAdmin =
      usuario.fatoUsuarioPerfis.filter(c =>
        Functions.inStruct(c.perfil.perfilCodigo, ['BCCON', 'BCOPE'])
      ).length > 0;

    const param = {
      periodoDataInicial: periodo[0],
      periodoDataFinal: periodo[1],
      efetivada: '',
    };

    dispatch(
      PropostaActions.search({ ...param, usuarioId: usuario.matricula })
    );

    if (!isNoAdmin) param.usuarioId = '';

    setFiltros(param);
  }

  function onChangeFiltros(value, name) {
    if (name === 'cpfCnpj') {
      setCpfCnpj(value);
      value = value.replace(/\D/g, '');
    }
    setFiltros({ ...filtros, [name]: value });
  }

  function handleSubmit(e) {
    
    e.preventDefault();
    var obj = {...filtros};

    dispatch(PropostaActions.search(obj));
  }

  function onChangeEfetivada({ target: { value } }) {
    onChangeFiltros(value, 'efetivada');
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    history.push('/proposta/new');
  }

  function selectPropostaResidencialPersonalizavelLiberty(proposta, jsonContrato, jsonDemaisDados) {
    
    preencherDadosCliente(proposta, jsonDemaisDados);

    dispatch(PropostaActions.saveSuccess(proposta));
    
    dispatch(
      PropostaActions.efetivarSuccess({
        id: proposta.contratoId,
        numeroControle: proposta.propostaEletronicaNumeroControle,
      })
    );
    
    dispatch(PropostaActions.saveProduto(proposta.produtoId));
    
    preencherDadosCotacao(jsonContrato);
    preencherDadosEfetivacaoResidencialPersonalizavelLiberty(proposta, jsonContrato)
    preencherDadosSegurado(jsonContrato.Segurado);

    saveProducao({
      angariadorId: jsonContrato.AngariadorId,
      consultorId: jsonContrato.ConsultorId,
      equipeId: jsonContrato.EquipeId,
      agenciaId: jsonContrato.AgenciaProducaoId,
      produtoId: proposta.produtoId,}, jsonDemaisDados?.isProdutor);
  }

  function preencherDadosSegurado({EhEstrangeiro, NumeroPassaporte, PassaporteOrigem, TipoAtividadeEmpresa}) {
    var value = { ehEstrangeiro: EhEstrangeiro, numeroPassaporte: NumeroPassaporte, passaporteOrigem: PassaporteOrigem, tipoAtividadeEmpresa: TipoAtividadeEmpresa };
    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'segurado', value}))
  }

  function preencherDadosEfetivacaoResidencialPersonalizavelLiberty(proposta, jsonContrato) {
    
    if(!proposta.propostaEletronicaEfetivada) return;
    jsonContrato = functions.stringToJsonPropertiesCamelCase(JSON.stringify(jsonContrato));
    
    var efetivacao = {
      beneficiarios: jsonContrato.beneficiarios,
      listaContatosInspecao: (jsonContrato.listaContatosInspecao ?? []).map(x => {
        return {
          ...x,
          numeroTelefone: `${(x.dddContato ?? '')}${(x.numeroContato ?? '')}`
        }
      }),
      diaPreferenciaPagamento: (jsonContrato.diaPreferenciaPagamento ?? '').toString(),
      enderecoDeCorrespodenciaIgualAoDoSegurado: true,
      escolhaFormaPagamentoId: jsonContrato.escolhaFormaPagamentoId,
      clienteContaId: jsonContrato.clienteContaId,
      contaDebito: {
        ...(jsonContrato?.contaDebito ?? {}),
        numeroContaCorrente: `${(jsonContrato?.contaDebito?.numeroContaCorrente ?? '')}${(jsonContrato?.contaDebito?.digitoVerificadorContaCorrente ?? '')}`
      }
    };

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: {...efetivacao} }))
  }

  function preencherDadosCotacao(json) {
    
    dispatch(LibertyResidencialPersonalizavelActions.setDefaultState());

    var jsonString = JSON.stringify(json);
    json = functions.stringToJsonPropertiesCamelCase(jsonString);
    var residencia = (json.residencias ?? [])[0] ?? {};

    var obj = {
      dataInicioVigencia: moment(json.dataInicioVigencia),
      dataFimVigencia: moment(json.dataFimVigencia),
      coberturas: (residencia.coberturas ?? []).map(x => {
        return {
          codigoCobertura: x.codigoCobertura,
          codigoVerba: x.codigoVerba,
          valorCobertura: x.valorCobertura
        }
      }),
      porcentagemAgravo: residencia.porcentagemAgravo,
      localRisco: {
        tipoAssistenciaId: !!residencia.tipoAssistenciaId ? `${residencia.tipoAssistenciaId}` : null,
        tipoConstrucaoId: !!residencia.tipoConstrucaoId ? `${residencia.tipoConstrucaoId}` : null,
        tipoHabitacaoId: !!residencia.tipoHabitacaoId ? `${residencia.tipoHabitacaoId}` : null,
        tipoOcupacaoId: !!residencia.tipoOcupacaoId ? `${residencia.tipoOcupacaoId}` : null,
        tipoAtividadeProfissionalExercidaId: !!residencia.tipoAtividadeProfissionalExercidaId ? `${residencia.tipoAtividadeProfissionalExercidaId}`: null,
        seguradoEhProprietarioDoImovel: residencia.seguradoEhProprietarioDoImovel,
        imovelEhPatrimonioHistorico: residencia.imovelEhPatrimonioHistorico,
        imovelDesocupadoEmRetormaOuEmConstrucao: residencia.imovelDesocupadoEmRetormaOuEmConstrucao,
        imovelPossuiMadeiraParedesExternas: residencia.imovelPossuiMadeiraParedesExternas,
        endereco: {
          clienteEndereco1: residencia.localDeRisco.logradouro,
          clienteEnderecoBairro: residencia.localDeRisco.bairro,
          clienteEnderecoCep: residencia.localDeRisco.cep,
          tipoLogradouroLibertyResidencialPersonalizavel: residencia.localDeRisco.tipoRuaEndereco,
          clienteEnderecoNumero: residencia.localDeRisco.numeroEndereco,
          clienteEnderecoComplemento: residencia.localDeRisco.complemento,
          clienteEnderecoCidade: residencia.localDeRisco.cidade,
          clienteEnderecoUf: residencia.localDeRisco.estado,
        },
      },
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'cotacao', value: obj}));
    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'propostaDetalhes', value: json.detalhesCotacao}));
  }

  function onSelected(proposta) {
    setLoadingSelected(true);

    const jsonContrato = JSON.parse(proposta.propostaEletronicaJsonContrato);
    const jsonDemaisDados = JSON.parse(
      proposta.propostaEletronicaJsonDemaisDados || null
    );

    if(proposta.produtoId === Constants.Produtos.LibertyResidencialPersonalizavel){
      dispatch(LibertyResidencialPersonalizavelActions.getListaDominios());
      return selectPropostaResidencialPersonalizavelLiberty(proposta, jsonContrato, jsonDemaisDados);
    }

    dispatch(PropostaActions.saveSuccess(proposta));
    dispatch(
      PropostaActions.efetivarSuccess({
        id: proposta.contratoId,
        numeroControle: proposta.propostaEletronicaNumeroControle,
      })
    );
    dispatch(PropostaActions.saveProduto(proposta.produtoId));
    saveProducao(jsonContrato, jsonDemaisDados?.isProdutor);

    if (proposta.produtoId === Constants.Produtos.DreMitsui) {
      saveDreMitsui(jsonContrato, jsonDemaisDados, proposta);
    } else if (proposta.produtoId === Constants.Produtos.DreLibertyCeS) {
      saveDreLibertyCeS(jsonContrato, jsonDemaisDados, proposta);
    } else if (proposta.produtoId === Constants.Produtos.DreZurichRes) {
      saveDreZurichRes(jsonContrato, jsonDemaisDados, proposta);
    } else if (proposta.produtoId === Constants.Produtos.DreZurichEmp) {
      saveDreZurichEmp(jsonContrato, jsonDemaisDados, proposta);
    } else if (proposta.produtoId === Constants.Produtos.DreMitsuiEmp) {
      saveDreMitsuiEmp(jsonContrato, jsonDemaisDados, proposta);
    } else if (
      proposta.produtoId === Constants.Produtos.AcidentesPessoaisLiberty
    ) {
      saveLibertyAp(jsonContrato, jsonDemaisDados, proposta);
    } else if (isCap(proposta.produtoId)) {
      saveCapIcatu(jsonContrato, jsonDemaisDados, proposta);
    } else if (proposta.produtoId === Constants.Produtos.BrbMasterPremiado) {
      saveBrbMaster(jsonContrato, jsonDemaisDados, proposta);
    } else {
      saveVppAmerican(jsonContrato, jsonDemaisDados, proposta);
    }

    preencherDadosCliente(proposta, jsonDemaisDados);
  }

  function preencherDadosCliente(proposta, jsonDemaisDados) {

    dispatch(
      ClienteActions.getById({
        param: proposta.clienteId,
        callback: cliente => {
          if (jsonDemaisDados.enderecoCorrepondencia) {
            dispatch(
              ClienteActions.saveEndereco(
                jsonDemaisDados.enderecoCorrepondencia
              )
            );
          }

          if (
            jsonDemaisDados.clienteFaixaRendaManual &&
            proposta.produtoId === Constants.Produtos.VidaPremiadoPlus
          ) {
            dispatch(
              ClienteActions.changeCliente({
                field: 'clienteFaixaRendaManual',
                value: jsonDemaisDados.clienteFaixaRendaManual,
              })
            );
          }
          if (
            proposta.produtoId === Constants.Produtos.AcidentesPessoaisLiberty
          ) {
            const { profissoes, profissoesLiberty } = dominios;
            const descricaoClienteProfissao = profissoes.find(
              p => p.clienteProfissaoId === cliente.clienteProfissaoId
            ).clienteProfissaoDescricao;
            const clienteProfissaoId = SelectedProfissaoLiberty(
              profissoesLiberty,
              descricaoClienteProfissao
            );

            if (jsonDemaisDados.clienteProfissaoLibertyCodigo)
              dispatch(
                ClienteActions.changeCliente({
                  field: 'clienteProfissaoLibertyCodigo',
                  value: jsonDemaisDados.clienteProfissaoLibertyCodigo,
                })
              );

            dispatch(
              ClienteActions.changeCliente({
                field: 'clienteProfissaoId',
                value: clienteProfissaoId,
              })
            );
            dispatch(
              ClienteActions.changeCliente({
                field: 'estadoCivilId',
                value: cliente.estadoCivilId,
              })
            );
          }

          if (proposta.produtoId === Constants.Produtos.BrbMasterPremiado) {
            dispatch(
              ClienteActions.changeCliente({
                field: 'clienteProfissaoBrbMaster',
                value: jsonDemaisDados.clienteProfissaoBrbMaster,
              })
            );
            dispatch(
              ClienteActions.changeCliente({
                field: 'clienteProfissaoDescricaoBrbMaster',
                value: jsonDemaisDados.clienteProfissaoDescricaoBrbMaster,
              })
            );
            dispatch(
              ClienteActions.changeCliente({
                field: 'clienteNrFilhos',
                value: jsonDemaisDados.clienteNrFilhos,
              })
            );
            dispatch(
              ClienteActions.changeCliente({
                field: 'clienteProfissaoCategoria',
                value: jsonDemaisDados.clienteProfissaoCategoria,
              })
            );
            dispatch(
              ClienteActions.changeCliente({
                field: 'clientePpeId',
                value: cliente.clientePpeId,
              })
            );
            dispatch(
              ClienteActions.changeCliente({
                field: 'clienteFaixaRendaManual',
                value: cliente.mtrClienteFaixaRendaValor,
              })
            );
          }

          history.push('proposta/edit');
        },
      })
    );
  } 

  function SelectedProfissaoLiberty(profissoesLiberty, descricao) {
    for (let i = 0; i < profissoesLiberty.length; i++) {
      if (
        (profissoesLiberty[i]?.descricao ?? '').toUpperCase() == (descricao ?? '').toUpperCase()
      )
        return profissoesLiberty[i].codigo;
    }
  }

  function SeProdutoApAjustaProfissaoSelected(cliente) {
    return cliente.clienteProfissaoId;
  }

  function saveProducao(
    {
      angariadorId,
      consultorId,
      equipeId,
      agenciaId,
      contratoTipoId,
      apoliceAnterior,
      seguradoraIdAnterior,
      produtoId,
      isVendaRelacionada,
    },
    isProdutor
  ) {
    const producao = {
      angariadorId,
      consultorId,
      equipeId,
      agenciaId,
      contratoTipoId: contratoTipoId || 1,
      isProdutor,
      apoliceAnterior,
      seguradoraIdAnterior,
      isVendaRelacionada,
    };

    dispatch(PropostaActions.saveProducaoProposta(producao));
    if (consultorId)
      dispatch(ProducaoActions.getEquipesByProdutor(consultorId));

    let produtorTipoId = '';

    if (dominios.consultores) {
      const consultor = dominios.consultores.find(
        c => c.produtorId === consultorId
      );

      produtorTipoId = consultor?.produtorTipoId;

      const param = {
        produtoId,
        contratoTipoId: !isCap(produtoId) ? contratoTipoId : -2,
        produtorTipoId,
        equipeId,
      };

      dispatch(ProducaoActions.getRegraComissaoProdutor(param));
    }
  }

  function saveLibertyAp(
    {
      contratoVidaDataContrato,
      contratoVidaDataFinal,
      contratoVidaObservacao,
      contratoVidaVencimento1Parcela,
      mtrContratoVida1Parcela,
      clienteId,
      seguradoraId,
      produtoId,
      contratoFormaPagamentoId,
      tipoPagamentoId,
      agenciaId,
      equipeId,
      angariadorId,
      consultorId,
      contratoVidaQuantidadeParcelas,
      contratoVidaDiaVencimentoDemaisParcelas,
      contaDebitoId,
      contaDebitoNumero,
      agenciaDebitoId,
      bancoId,
    },
    {
      tipoPropostaId,
      tipoConta,
      cpfTerceiro,
      nomeTerceiro,
      tipoPlano,
      respostasDps,
      beneficiarios,
      PlanoSelecionado,
      PessoaPoliticamenteExposta,
      isDebitoBrb,
      isDebitoOutrosBancos,
    },
    { propostaEletronicaNossoNumero, propostaEletronicaPlanoId }
  ) {
    const pessoaPoliticamenteExposta = {
      isPessoaPoliticamenteExposta:
        PessoaPoliticamenteExposta.isPessoaPoliticamenteExposta == 1,
      tipoPessoaPoliticamenteExposta:
        PessoaPoliticamenteExposta?.tipoPessoaPoliticamenteExposta == null
          ? 0
          : PessoaPoliticamenteExposta.tipoPessoaPoliticamenteExposta,
    };

    const apLiberty = {
      contaDebitoId,
      contaDebitoNumero,
      agenciaDebitoId,
      beneficiarios,
      diaDebito: contratoVidaDiaVencimentoDemaisParcelas,
      parcelamento: contratoVidaQuantidadeParcelas,
      planoSelected: planos.find(p => p.id == PlanoSelecionado.id),
      PessoaPoliticamenteExposta: pessoaPoliticamenteExposta,
      respostasDps,
      tipoConta,
      tipoPagamento: tipoPagamentoId,
      tipoPropostaId,
      valorParcela: mtrContratoVida1Parcela.replace('.', ','),
      bancoId,
      cpfTerceiro,
      nomeTerceiro,
      pagamento: { isDebitoBrb, isDebitoOutrosBancos }
    };
    dispatch(PropostaActions.saveApLiberty(apLiberty));
  }

  function saveDreMitsui(
    {
      contratoDREDataInicio,
      contratoDREDataFim,
      contratoDREQtdParcela,
      mtrContratoDRE1Parcela,
      contratoDREDiaVencimentoDemais,
      debitoConta,
      bens,
    },
    {
      formaPagamentoTipo,
      isOutrosSeguros,
      isBoleto,
      isDebitoAutomatico,
      isDebitoBrb,
      isDebitoOutrosBancos,
      propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj,
    },
    { propostaEletronicaNossoNumero, propostaEletronicaPlanoId }
  ) {
    const bemEndereco = bens ? getLocalRisco(bens[0]) : null;

    const dreMitsui = {
      contratoDREDataInicio,
      contratoDREDataFim,
      isOutrosSeguros,
      pagamento: {
        formaPagamento: formaPagamentoTipo,
        contratoDREQtdParcela,
        isBoleto,
        isDebitoAutomatico,
        isDebitoBrb,
        isDebitoOutrosBancos,
        contratoDREDiaVencimentoDemais,
        propostaEletronicaNossoNumero,
        agencia: debitoConta?.agencia,
        debitoContaNumeroConta: debitoConta?.debitoContaNumeroConta,
        mtrContratoDRE1Parcela,
      },
      bemEndereco,
      propostaEletronicaPlanoId,
      propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj,
    };

    dispatch(PropostaActions.saveDreMitsui(dreMitsui));
  }

  function saveDreLibertyCeS(
    {
      contratoDREDataInicio,
      contratoDREDataFim,
      contratoDREQtdParcela,
      mtrContratoDRE1Parcela,
      contratoDREDiaVencimentoDemais,
      debitoConta,
      bens,
    },
    {
      formaPagamentoTipo,
      isOutrosSeguros,
      inspecaoRiscoNome,
      inspecaoRiscoDdd,
      inspecaoRiscoTelefone,
      inspecaoRiscoRamal,
      proponenteNome,
      proponenteCpfCnpj,
      propostaEletronicaAtividadeId,
      propostaEletronicaAtividadePJId,
    },
    { propostaEletronicaNossoNumero, propostaEletronicaPlanoId }
  ) {
    const bemEndereco = bens ? getLocalRisco(bens[0]) : null;

    const dreLibertyCeS = {
      contratoDREDataInicio,
      contratoDREDataFim,
      isOutrosSeguros,
      pagamento: {
        formaPagamento: formaPagamentoTipo,
        contratoDREQtdParcela,
        contratoDREDiaVencimentoDemais,
        propostaEletronicaNossoNumero,
        agencia: debitoConta?.agencia,
        debitoContaNumeroConta: debitoConta?.debitoContaNumeroConta,
        mtrContratoDRE1Parcela,
      },
      inspecaoRiscoNome,
      inspecaoRiscoDdd,
      inspecaoRiscoTelefone,
      inspecaoRiscoRamal,
      proponenteNome,
      proponenteCpfCnpj,
      propostaEletronicaAtividadeId,
      propostaEletronicaAtividadePJId,
      bemEndereco,
      propostaEletronicaPlanoId,
    };

    dispatch(PropostaActions.saveDreLibertyCeS(dreLibertyCeS));
  }

  function saveDreZurichRes(
    {
      contratoDREDataInicio,
      contratoDREDataFim,
      contratoDREQtdParcela,
      mtrContratoDRE1Parcela,
      contratoDREDiaVencimentoDemais,
      debitoConta,
      bens,
    },
    {
      formaPagamentoTipo,
      isOutrosSeguros,
      isBoleto,
      propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj,
    },
    { propostaEletronicaNossoNumero, propostaEletronicaPlanoId }
  ) {
    const bemEndereco = bens ? getLocalRisco(bens[0]) : null;

    const dreZurichRes = {
      contratoDREDataInicio,
      contratoDREDataFim,
      isOutrosSeguros,
      pagamento: {
        formaPagamento: formaPagamentoTipo,
        isBoleto,
        contratoDREQtdParcela,
        contratoDREDiaVencimentoDemais,
        propostaEletronicaNossoNumero,
        agencia: debitoConta?.agencia,
        debitoContaNumeroConta: debitoConta?.debitoContaNumeroConta,
        mtrContratoDRE1Parcela,
      },
      bemEndereco,
      propostaEletronicaPlanoId,
      propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj,
    };

    dispatch(PropostaActions.saveDreZurichRes(dreZurichRes));
  }

  function saveDreZurichEmp(
    {
      contratoDREDataInicio,
      contratoDREDataFim,
      contratoDREQtdParcela,
      mtrContratoDRE1Parcela,
      contratoDREDiaVencimentoDemais,
      debitoConta,
      bens,
    },
    {
      formaPagamentoTipo,
      isOutrosSeguros,
      apoliceOutrosSeg,
      seguradoraOutrosSeg,
      bemAtividade,
      proponente,
    },
    { propostaEletronicaNossoNumero, propostaEletronicaPlanoId }
  ) {
    const bemEndereco = bens ? getLocalRisco(bens[0]) : null;

    const dreZurichEmp = {
      contratoDREDataInicio,
      contratoDREDataFim,
      isOutrosSeguros,
      apoliceOutrosSeg,
      seguradoraOutrosSeg,
      pagamento: {
        formaPagamento: formaPagamentoTipo,
        contratoDREQtdParcela,
        contratoDREDiaVencimentoDemais,
        propostaEletronicaNossoNumero,
        agencia: debitoConta?.agencia,
        debitoContaNumeroConta: debitoConta?.debitoContaNumeroConta,
        mtrContratoDRE1Parcela,
      },
      bemEndereco: {
        ...bemEndereco,
        bemAtividade,
      },
      propostaEletronicaPlanoId,
      propRazaoSocial: proponente.propRazaoSocial,
      propCNPJ: proponente.propCNPJ,
      propAtividade: proponente.propAtividade,
      propPatrimonioLiquido: proponente.propPatrimonioLiquido,
      propPEP: proponente.propPEP,
      propClausulaBeneficiaria: proponente.propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj:
        proponente.propClausulaBeneficiariaCpfCnpj,
      propCobertura: proponente.propCobertura,
      controladores: proponente.controladores,
    };

    dispatch(PropostaActions.saveDreZurichEmp(dreZurichEmp));
  }

  function saveDreMitsuiEmp(
    {
      contratoDREDataInicio,
      contratoDREDataFim,
      contratoDREQtdParcela,
      mtrContratoDRE1Parcela,
      contratoDREDiaVencimentoDemais,
      debitoConta,
      bens,
    },
    {
      formaPagamentoTipo,
      isOutrosSeguros,
      apoliceOutrosSeg,
      isBoleto,
      isDebitoAutomatico,
      seguradoraOutrosSeg,
      proponente,
      propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj,
    },
    { propostaEletronicaNossoNumero, propostaEletronicaPlanoId }
  ) {
    const bemEndereco = bens ? getLocalRisco(bens[0]) : null;

    const dreMitsuiEmp = {
      contratoDREDataInicio,
      contratoDREDataFim,
      isOutrosSeguros,
      isBoleto,
      isDebitoAutomatico,
      apoliceOutrosSeg,
      seguradoraOutrosSeg,
      pagamento: {
        formaPagamento: formaPagamentoTipo,
        contratoDREQtdParcela,
        contratoDREDiaVencimentoDemais,
        propostaEletronicaNossoNumero,
        agencia: debitoConta?.agencia,
        debitoContaNumeroConta: debitoConta?.debitoContaNumeroConta,
        mtrContratoDRE1Parcela,
      },
      bemEndereco,
      propostaEletronicaPlanoId,
      propRazaoSocial: proponente.propRazaoSocial,
      propCNPJ: proponente.propCNPJ,
      propPEP: proponente.propPEP,
      propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj,
    };

    dispatch(PropostaActions.saveDreMitsuiEmp(dreMitsuiEmp));
  }

  function saveCapIcatu(
    {
      contratoCapitalizacaoId,
      produtoId,
      seguradoraId,
      clienteId,
      consultorId,
      angariadorId,
      agenciaId,
      equipeId,
      contratoCapitalizacaoNumeroControle,
      contratoCapitalizacaoDiaDebito,
      contratoCapitalizacaoDataAssinatura,
      contratoCapitalizacaoDataPrimeiroDebito,
      contratoCapitalizacaoDataEnvioSeguradora,
      contratoCapitalizacaoGuid,
      contratoCapitalizacaoDataEntrada,
      titulosCapitalizacao,
      regraComissaoProdutor,
    },
    demaisDados,
    { propostaEletronicaNossoNumero }
  ) {
    const capIcatu = {
      contratoCapitalizacaoId,
      produtoId,
      seguradoraId,
      clienteId,
      consultorId,
      angariadorId,
      agenciaId,
      equipeId,
      contratoCapitalizacaoNumeroControle,
      contratoCapitalizacaoDiaDebito,
      contratoCapitalizacaoDataAssinatura,
      contratoCapitalizacaoDataPrimeiroDebito,
      contratoCapitalizacaoDataEnvioSeguradora,
      contratoCapitalizacaoGuid,
      contratoCapitalizacaoDataEntrada,
      titulosCapitalizacao,
      regraComissaoProdutor,
      demaisDados,
    };

    dispatch(PropostaActions.saveCapIcatu(capIcatu));
  }
  function getLocalRisco(bem) {
    const {
      bemEndereco: {
        bemEnderecoCep,
        bemEnderecoBairro,
        bemEnderecoCidade,
        bemEnderecoEndereco,
        bemEnderecoUf,
        BemEnderecoNumero,
        BemEnderecoComplemento,
      },
      objetoSeguradoId,
    } = bem;

    return {
      clienteEnderecoCep: Functions.getOnlyNum(bemEnderecoCep),
      clienteEnderecoBairro: bemEnderecoBairro,
      clienteEnderecoCidade: bemEnderecoCidade,
      clienteEndereco1: bemEnderecoEndereco,
      clienteEnderecoUf: bemEnderecoUf,
      clienteEnderecoNumero: BemEnderecoNumero,
      clienteEnderecoComplemento: BemEnderecoComplemento,
      tipoImovel: getTipoImovel(objetoSeguradoId),
    };
  }
  function getTipoImovel(objetoSeguradoId) {
    switch (objetoSeguradoId) {
      case 10: // Apartamento
        return 1;
      case 20: // Casa
        return 2;
      case 50: // Loja comercial
        return 3;
      default:
        return 4;
    }
  }

  function saveVppAmerican(jsonContrato, jsonDemaisDados, proposta) {
    const beneficiarios = jsonContrato.fatoBeneficiarioNivelDois.map(f => {
      return {
        nome: f.beneficiario?.beneficiarioNome,
        grauRelacionamento: f.grauRelacionamentoId,
        percentual: f.mtrParticipacao * 100,
      };
    });

    const { debitoConta } = jsonContrato;
    const { tipoPropostaId, tipoPlano, tipoConta } = jsonDemaisDados || {};

    const propostaVida = {
      tipoPropostaId,
      tipoPlano: tipoPlano || 1,
      tipoConta,
      tipoPagamento: jsonContrato.tipoPagamentoId,
      bancoId: '0070',
      beneficiarios,
      titularAltura: jsonContrato.contratoVidaTitularAltura,
      titularPeso: jsonContrato.contratoVidaTitularPeso,
      agenciaDebitoId: debitoConta?.agenciaId,
      contaDebitoNumero: debitoConta?.debitoContaNumeroConta,
      diaDebito: jsonContrato.contratoVidaDiaVencimentoDemaisParcelas,
      respostasDps: jsonDemaisDados.respostasDps,
      planoSelected: {
        planoGarantiaVidaId: jsonContrato.planoGarantiaVidaId,
        mtrPremioTotal: jsonContrato.mtrContratoVidaPremio,
      },
      numeroPropostaManual: jsonContrato.contratoVidaNumeroControle,
      dataPagamentoPropostaManual: add(
        Date.parse(jsonContrato.contratoVidaDataContrato),
        {
          days: -1,
        }
      ),
      numeroControle: proposta.propostaEletronicaNumeroControle,
      propostaEletronicaDataEfetivacao:
        proposta.propostaEletronicaDataEfetivacao,
    };

    if (
      propostaVida.tipoPlano ===
      Constants.VidaPremiadoPlus.tipoPlano.titularConjuge
    ) {
      propostaVida.conjugeCpf = jsonContrato.contratoVidaConjugeCpf;
      propostaVida.conjugeNome = jsonContrato.contratoVidaConjugeNome;
      propostaVida.conjugeDataNascimento =
        jsonContrato.contratoVidaConjugeDataNascimento;
      propostaVida.conjugeAltura = jsonContrato.contratoVidaConjugeAltura;
      propostaVida.conjugePeso = jsonContrato.contratoVidaConjugePeso;
    }

    dispatch(PropostaActions.changeVppAmerican(propostaVida));
  }

  function saveBrbMaster(jsonContrato, jsonDemaisDados, proposta) {
    const beneficiarios = jsonContrato.fatoBeneficiarioNivelDois.map(f => {
      return {
        nome: f.beneficiario?.beneficiarioNome,
        grauRelacionamento: f.grauRelacionamentoId,
        percentual: f.mtrParticipacao * 100,
        dataNascimento: f.beneficiario?.beneficiarioDataNascimento,
      };
    });

    const { debitoConta } = jsonContrato;
    const { tipoPropostaId, tipoPlano, tipoConta } = jsonDemaisDados || {};

    const propostaBrbMaster = {
      tipoPropostaId,
      tipoPlano: tipoPlano || 1,
      tipoConta,
      tipoPagamento: jsonContrato.tipoPagamentoId,
      bancoId: '0070',
      beneficiarios,
      titularAltura: jsonContrato.contratoVidaTitularAltura,
      titularPeso: jsonContrato.contratoVidaTitularPeso,
      agenciaDebitoId: debitoConta?.agenciaId,
      contaDebitoNumero: debitoConta?.debitoContaNumeroConta,
      diaDebito: jsonContrato.contratoVidaDiaVencimentoDemaisParcelas,
      respostasDps: jsonDemaisDados.respostasDps,
      planoSelected: {
        planoGarantiaVidaId: jsonContrato.planoGarantiaVidaId,
        mtrPremioTotal: jsonContrato.mtrContratoVidaPremio,
      },
      numeroPropostaManual: jsonContrato.contratoVidaNumeroControle,
      dataPagamentoPropostaManual: add(
        Date.parse(jsonContrato.contratoVidaDataContrato),
        {
          days: -1,
        }
      ),
      numeroControle: proposta.propostaEletronicaNumeroControle,
      propostaEletronicaDataEfetivacao: proposta.propostaEletronicaDataEfetivacao,
      tokenSigned: false,
      urlArquivo: jsonDemaisDados.urlArquivo,
      telefonesEnvioToken: [],
    };

    dispatch(PropostaActions.changeBrbMaster(propostaBrbMaster));
  }

  return (
    <>
      <ContentHeader
        title="PROPOSTAS E COTAÇÕES"
        hideClass="btn-Hide"
        breadText="Pesquisar"
        icon="home"
        showButton
        textButton="Nova Cotação"
        onClick={onNovaCotacao}
      />

      <Container style={{ margin: '0 16px' }}>
        <Row>
          <Col xs={{ span: 24 }}>
            <h6>Pesquisar Cotações e Propostas</h6>
            <Form
              onSubmit={handleSubmit}
              layout="vertical"
              className="ant-advanced-search-form"
            >
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item>
                    <Radio.Group
                      buttonStyle="solid"
                      name="efetivada"
                      onChange={onChangeEfetivada}
                      value={filtros.efetivada}
                    >
                      <Radio value="">Todos</Radio>
                      <Radio value>Somente Efetivadas</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Divider orientation="center" style={{ color: '#333' }} />
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                  <Form.Item label="Cpf">
                    <Input
                      placeholder="Cpf/Cnpj"
                      value={cpfCnpj}
                      name="cpfCnpj"
                      onChange={onChangeFiltros}
                      mask="cpfCnpj"
                      maxLength={18}
                      id="validating"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                  <Form.Item label="Nome">
                    <Input
                      placeholder="Nome"
                      value={filtros.nome}
                      name="nome"
                      onChange={onChangeFiltros}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                  <Form.Item label="Período">
                    <DatePicker
                      placeholder="Período"
                      value={periodo}
                      range
                      onChange={value => setPeriodo(value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                  <Form.Item label="&nbsp;">
                    <Button
                      type="primary"
                      className="btn-block"
                      htmlType="submit"
                    >
                      <SearchOutlined /> Pesquisar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Divider
              orientation="center"
              style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
            >
              Propostas encontradas
            </Divider>
            <Table
              rowKey="propostaEletronicaId"
              dataSource={propostas}
              loading={loading || loadingSelected || dominios.loading}
              scroll={{ x: 1500 }}
              size="small"
            >
              <Column
                title="Nº Proposta"
                dataIndex="contratoId"
                width={100}
                render={(text, record) => (
                  <span>
                    {record.contratoId == null
                      ? 'NÃO EFETIVADO'
                      : record.contratoId}
                  </span>
                )}
              />
              <Column
                title="Nome"
                width={250}
                dataIndex="cliente.clienteNome"
                render={(text, record) =>
                  moment.utc(record.propostaEletronicaDataCadastro) >
                  moment('2020-10-16') ? (
                    <Button
                      size="small"
                      type="link"
                      onClick={() => onSelected(record)}
                    >
                      {text}
                    </Button>
                  ) : (
                    <span>{text}</span>
                  )
                }
              />
              <Column
                title="CPF"
                width={150}
                dataIndex="cliente.clienteCpfCnpj"
                render={(text, record) =>
                  moment.utc(record.propostaEletronicaDataCadastro) >
                  moment('2020-10-16') ? (
                    <Button
                      size="small"
                      type="link"
                      onClick={() => onSelected(record)}
                    >
                      {text}
                    </Button>
                  ) : (
                    <span>{text}</span>
                  )
                }
              />
              <Column
                title="Produto"
                dataIndex="produto.produtoDescricao"
                width={250}
              />
              <Column
                width={150}
                title="Data Cadastro"
                dataIndex="propostaEletronicaDataCadastro"
                render={(text, record) => (
                  <span>
                    {moment
                      .utc(record.propostaEletronicaDataCadastro)
                      .format('DD/MM/YYYY')}
                  </span>
                )}
              />
              <Column
                width={150}
                title="Data Efetivação"
                dataIndex="propostaEletronicaDataEfetivacao"
                render={(text, record) => (
                  <span>
                    {text == null
                      ? 'NÃO EFETIVADO'
                      : moment.utc(text).format('DD/MM/YYYY')}
                  </span>
                )}
              />
              <Column
                width={150}
                title="Vínculo
                Habitacional?"
                dataIndex="vendaRelacionada"
                render={(text, record) => (
                  <Checkbox checked={record.vendaRelacionada != null} />
                )}
              />
            </Table>
          </Col>
        </Row>
        <span>
          <i>
            * Propostas digitadas no sistema anterior, não estão acessíveis
            nessa versão.
          </i>
        </span>
      </Container>
    </>
  );
}
