import { Col, Divider, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';

type ClienteTabProp = {
  tipo?: String,
  data?: Object
}

function orderPorTamanho(lista, posicaoOrdenar = null) {
  return posicaoOrdenar 
    ? lista.sort((a, b) => a[posicaoOrdenar].toString().length - b[posicaoOrdenar].toString().length)
    : lista.sort((a, b) => a.toString().length - b.toString().length);
}

export default function ClienteTab(param: ClienteTabProp) {

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        PROPONENTE
      </Divider>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={12}>
              <strong title="Cpf">Cpf</strong>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span title="Cpf">{util.Mask.maskCpfCnpj(param?.data?.clienteCpfCnpj)}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 12 }}>
          <Row>
            <Col span={12}>
              <strong title="nome">Nome</strong>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span title="nome">{param.data.clienteNome}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={12}>
              <strong title="DataNascimento">Data de nascimento</strong>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span title="DataNascimento">{moment(param?.data?.clienteDataNascimento).format('DD/MM/YYYY')}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      {
        !!param.data?.clienteEnderecos?.length && <>
          <Divider
            orientation="left"
            style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
          >
            CORRESPONDÊNCIA
          </Divider>
          <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
            {
              param?.data?.clienteEnderecos?.map((x, i) => {
                return <div key={i} style={{padding: '4px'}}>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
                      <Row>
                        <Col span={12}>
                          <strong title="CEP">CEP</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span title="CEP">{util.Mask.maskCep(x.clienteEnderecoCep)}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
                      <Row>
                        <Col span={12}>
                          <strong title="Endereço">Endereço</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span title="Endereço">{x.clienteEndereco1}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
                      <Row>
                        <Col span={12}>
                          <strong title="Complemento">Complemento</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span title="Complemento">{x.clienteEnderecoComplemento}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
                      <Row>
                        <Col span={12}>
                          <strong title="Cidade">Cidade</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span title="Cidade">{x.clienteEnderecoCidade }</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <br/>
                  <Row gutter={[8, 8]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
                      <Row>
                        <Col span={12}>
                          <strong title="Bairro">Bairro</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span title="Bairro">{x.clienteEnderecoBairro}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 4 }}>
                      <Row>
                        <Col span={12}>
                          <strong title="UF">Estado</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span title="UF">{x.clienteEnderecoUf}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
                      <Row>
                        <Col span={12}>
                          <strong title="Número">Número</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span title="Número">{x.clienteEnderecoNumero}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {
                    i < (param.data?.clienteEnderecos?.length - 1) &&
                    <Divider
                      orientation="center"
                      style={{ color: '#333', fontWeight: 'small', margin: 50, marginBottom: 3, marginTop: 0 }}
                    ></Divider>
                  }
                </div>
              })
            }
          </Row>
          <Divider
            orientation="left"
            style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
          >
            CONTATO
          </Divider>
          <Row gutter={[10,10]}>
            <Col sm={{ span: 24}} md={{ span: 12 }} style={{padding: '.9rem'}}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 0 }}
              >
                Telefones
              </Divider>
              {
                !!(param.data?.clienteContatoFones?.length) && orderPorTamanho(param.data.clienteContatoFones, 'clienteContatoFoneNumero').map((x, i) => {
                  return <div key={i}>
                    <Row>
                      <span>{util.Mask.maskPhone(x.clienteContatoFoneNumero)}</span>
                    </Row>
                  </div>
                })
              }
            </Col>
            <Col sm={{ span: 24}} md={{ span: 12 }} style={{padding: '.9rem'}}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 0 }}
              >
                Emails
              </Divider>
              {
                !!(param.data?.clienteContatoMails?.length) && orderPorTamanho(param.data.clienteContatoMails, 'clienteContatoMailDescricao').map((x, i) => {
                  return <div key={i}>
                    <Row>
                      <span>{x.clienteContatoMailDescricao}</span>
                    </Row>
                  </div>
                })
              }
            </Col>
          </Row>
        </>
      }
    </>
  );
};