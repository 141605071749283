import { ColumnConfig } from '@ant-design/charts/es/column';
import { Functions } from 'utils';

function retornaConfig(dataGrafico) {
  var config: ColumnConfig = {
    data: dataGrafico,
    // Agrupa as barras que possuem mesmo nome
    isGroup: true,
    xField: 'mes',
    yField: 'valor',
    seriesField: 'nome',
    // Configuração do texto apresentado durante hover na seção
    tooltip: {
      formatter: (x) => {
        return {
          name: x.nome,
          value: Functions.formatarMoeda(x.valor)
        }
      }
    },
    // Cofiguração da legenda pra cada barra
    label: {
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
      formatter: (x) => x.valor === 0 ? '' : Functions.formatarMoeda(x.valor)
    },
  }
  return config;
}

export default {
  retornaConfig
}