import React, { useEffect, useState } from "react";
import { Popconfirm, Button, Col, Divider, Form, Row, Input as InputAnt, Spin, Table, message } from 'antd';
import { Container, ContentHeader, Select, Input, DatePicker } from '~/components';
import { useDispatch, useSelector } from 'react-redux';
import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as DominiosActions from '~/store/ducks/dominio';
import * as AgendaPositivaActions from '~/store/ducks/agenda-positiva'
import history from '~/services/history';
import 'antd/dist/antd.css';
import moment from 'moment';

export default function AgendaPositiva () {

    const dispatch = useDispatch();

    const [validateStatus, setValidateStatus] = useState('');

    const [erros, setErros] = useState([]);

    const modulos = [{moduloId : 1, moduloDescricao : 'AUTOMOVEL', ramo : 'AUTO'}, 
                    {moduloId : 2, moduloDescricao : 'RISCOS DIVERSOS', ramo : 'RD'}, 
                    {moduloId : 3, moduloDescricao : 'VIDA', ramo : 'VIDA'}];

    const [agenda, setAgenda] = useState({});

    const [cpfCnpj, setCpfCnpj] = useState();

    const [telefoneCelular, setTelefoneCelular] = useState();
    const [telefoneResidencial, setTelefoneResidencial] = useState();
    const [telefoneComercial, setTelefoneComercial] = useState();

    const { producao } = useSelector(
        state => state.proposta
      );

    const dominios = useSelector(({ dominio }) => dominio);

    const {agenciasBrb, consultoresDeSeguros} = useSelector(({dominio}) => dominio);

    const { usuario } = useSelector(({ auth }) => auth);

    function onChangeAgenda(value, name) {
        if (name === 'cpfCnpj') {
            setCpfCnpj(value);
            value = value.replace(/\D/g, '');
            agenda.cpfCnpj = value;
        }
        else if (name === 'telefoneCelular') {
            setTelefoneCelular(value);
            if(value) value = value.replace(/\D/g, '');
            agenda.telefoneCelular = value;
        }
        else if (name === 'telefoneResidencial') {
            setTelefoneResidencial(value);
            if(value) value = value.replace(/\D/g, '');
            agenda.telefoneResidencial = value;
        }
        else if (name === 'telefoneComercial') {
            setTelefoneComercial(value);
            if(value) value = value.replace(/\D/g, '');
            agenda.telefoneComercial = value;
        }
        setAgenda({...agenda, [name]: value});
    }

    function changeField(field, value) {
        dispatch(PropostaActions.changeProducao({ field, value }));
    }

    function onChangeConsultor(value) {
        changeField('consultorCodigo', value);
        agenda.consultorCodigo = value;
    }

    function onChangeAgencia(value) {
        changeField('agenciaOrigem', value);
        agenda.agenciaOrigem = value;
    }

    function onNovaCotacao() {
        dispatch(ClienteActions.initCliente());
        dispatch(ProducaoActions.initProducao());
        dispatch(PropostaActions.initProposta());
        history.push('/proposta/new');
    }

    function validaAgenda(agenda) {
        setErros([]);

        if (!agenda.cpfCnpj || agenda.cpfCnpj.length < 11) {
            message.error('Por favor, preencha o campo "CPF/CNPJ"');
            return false;
        }

        if(!agenda.clienteEmpresa) {
            message.error('Por favor, preencha o campo "Cliente/Empresa"');
            return false;
        }

        if(!agenda.ramo) {
            message.error('Por favor, preencha o campo "Produto"');
            return false;
        }

        if(!agenda.consultorCodigo) {
            message.error('Por favor, preencha o campo "Consultor"');
            return false;
        }

        if(!agenda.agenciaOrigem) {
            message.error('Por favor, preencha o campo "Agência"');
            return false;
        }

        if(!agenda.telefoneCelular || agenda.telefoneCelular.length < 10) {
            message.error('Por favor, preencha o campo "Telefone Celular"');
            return false;
        }

        if(agenda.telefoneResidencial && agenda.telefoneResidencial.length < 10) {
            message.error('Por favor, preencha corretamente o campo "Telefone Residencial"');
            return false;
        }

        if(agenda.telefoneComercial && agenda.telefoneComercial.length < 10) {
            message.error('Por favor, preencha corretamente o campo "Telefone Comercial"');
            return false;
        }

        if(!agenda.dataContato) {
            message.error('Por favor, preencha o campo "Data desejada do contato"');
            return false;
        }

        if(moment(Date.now()) > agenda.dataContato) {
            message.error('A data de contato deve ser posterior à data atual!');
            return false;
        }

        if(!agenda.observacao) {
            message.error('Por favor, preencha o campo "Observação"');
            return false;
        }

        return true;
    }

    function limpaAgenda() {
        setCpfCnpj('');
        setTelefoneCelular('');
        setTelefoneComercial('');
        setTelefoneResidencial('');
        setAgenda({});
    }

    function handleSubmit(e) {
        if (e) e.preventDefault();
        if (validaAgenda(agenda)) {
            agenda.angariadorOrigem = usuario.matricula;
            dispatch(
                AgendaPositivaActions.addAgendaPositiva(agenda)
            );
            // Limpa tela
            limpaAgenda();
        }
    }

    useEffect(() => {
        if (
          !dominios.horaCarregada ||
          moment(Date.now())
            .add(-2, 'hour')
            .isAfter(dominios.horaCarregada)
        )
          dispatch(DominiosActions.listAll());
    }, []);
    
    return (
        <>
        <ContentHeader
        title="AGENDA POSITIVA NOVO"
        hideClass="btn-Hide"
        icon="home"
        showButton
        textButton="Nova Cotação"
        onClick={onNovaCotacao}
      />
        <Container style={{ margin: '0 16px' }}>
        <Row>
          <Col xs={{ span: 24 }}>
            <h6>Agendar Contato</h6>
            <Form
                layout="vertical"
                className="ant-advanced-search-form"
                onSubmit={handleSubmit}
                >
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Divider orientation="center" style={{ color: '#333' }} />
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Row gutter={[16, 0]}>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="CPF/CNPJ" required>
                                    <Input
                                    placeholder="CPF/CNPJ"
                                    value={cpfCnpj}
                                    name="cpfCnpj"
                                    mask="cpfCnpj"
                                    onChange={onChangeAgenda}
                                    maxLength={18}
                                    validateStatus={validateStatus}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Cliente/Empresa" required>
                                    <Input
                                    placeholder="Cliente/Empresa"
                                    value={agenda.clienteEmpresa}
                                    name="clienteEmpresa"
                                    onChange={onChangeAgenda}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Consultor Destino" required>
                                    <Select 
                                    itens={consultoresDeSeguros}
                                    itemValue="produtorCodigo"
                                    itemText="produtorNome"
                                    itemTextSearch="produtorCodigo"
                                    renderText={item =>
                                    `${item.produtorCodigo} - ${item.produtorNome}`}
                                    value={agenda.consultorCodigo}
                                    onChange={onChangeConsultor}
                                    showSearch
                                    placeholder={'Selecione'}
                                    loading={!consultoresDeSeguros}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Produto" required>
                                    <Select 
                                    itens={modulos}
                                    itemValue="ramo"
                                    itemText="ramo"
                                    value={agenda.ramo}
                                    renderText={item => `${item.moduloDescricao}`}
                                    placeholder={'Selecione'}
                                    onChange={value => onChangeAgenda(value, 'ramo')}
                                    > 
                                    </Select>
                                </Form.Item>
                            </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Tel. Celular" required>
                                    <Input
                                    placeholder="(XX) XXXXX-XXXX"
                                    mask="phone"
                                    value={telefoneCelular}
                                    name="telefoneCelular"
                                    onChange={onChangeAgenda}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Tel. Residencial">
                                    <Input
                                    placeholder="(XX) XXXX-XXXX"
                                    value={telefoneResidencial}
                                    mask="phone"
                                    name="telefoneResidencial"
                                    onChange={onChangeAgenda}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Tel. Comercial">
                                    <Input
                                    placeholder="(XX) XXXX-XXXX"
                                    value={telefoneComercial}
                                    mask="phone"
                                    name="telefoneComercial"
                                    onChange={onChangeAgenda}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Data desejada do contato" required>
                                    <DatePicker
                                    placeholder="DD/MM/AAAA"
                                    value={agenda.dataContato}
                                    onChange={value => onChangeAgenda(value, 'dataContato')}
                                    />
                                </Form.Item>
                            </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label="Agência Origem" required>
                                    <Select 
                                    itens={agenciasBrb}
                                    itemValue="agenciaCodigo"
                                    itemText="agenciaDescricao"
                                    itemTextSearch="agenciaCodigo"
                                    renderText={item =>
                                    `${item.agenciaCodigo} - ${item.agenciaDescricao}`}
                                    value={agenda.agenciaOrigem}
                                    onChange={onChangeAgencia}
                                    showSearch
                                    placeholder={'Selecione'}
                                    loading={!agenciasBrb}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 12 }}>
                                <Form.Item label="Observação" required>
                                    <Input
                                    value={agenda.observacao}
                                    name="observacao"
                                    onChange={onChangeAgenda}
                                    maxLength={240}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24 }} sm={{ span: 6 }}>
                                <Form.Item label=" " style={{width: 100}}>
                                    <Popconfirm
                                        placement="right"
                                        title="Confirma agendamento?"
                                        onConfirm={handleSubmit}
                                        okText="Sim"
                                        cancelText="Não"  
                                    >
                                    <Button
                                    type="primary"
                                    className="btn-block"
                                    > Agendar
                                    </Button>
                                </Popconfirm>
                                </Form.Item>
                            </Col>
                    </Row>
                </Row>
            </Form>
          </Col>
        </Row>
        </Container>
        </>
    )
}