import React, { useState } from 'react';
import { Button } from 'antd';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import criptografar from '../../services/criptografia.service'

import * as AuthActions from '~/store/ducks/auth';

import logo from '~/assets/nova-logotipo.png';
import '../_layouts/auth/index.css';

export default function Home() {
  // Dispatch redux
  const dispatch = useDispatch();
  // Redux state
  const { loading } = useSelector(state => state.auth);
  // Component state
  const [matricula, setMatricula] = useState('');
  const [senha, setSenha] = useState('');
  const [validated, setValidated] = useState(false);

  function onPressLogin() {
    // user
    const user = { matricula, senha: criptografar(senha) };
    dispatch(AuthActions.login({ param: user }));
    dispatch(AuthActions.notification({ param: user.matricula }));
  }

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      onPressLogin();
    }

    setValidated(true);
  };

  return (
    <section id="login">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} sm={4}>
            <img src={logo} alt="Seguridade" style={{ maxWidth: '100%' }} />
            <p className="text-center text-white mt-3 mb-5">
              Faça o login para acessar o sistema
            </p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Label style={{ color: '#fff' }}>Matrícula</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="matrícula"
                  size="lg"
                  value={matricula}
                  onChange={({ target: { value } }) => setMatricula(value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor informe sua matrícula.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ color: '#fff' }}>Senha</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="senha"
                  value={senha}
                  size="lg"
                  onChange={({ target: { value } }) => setSenha(value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor informe sua senha.
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                block
                size="large"
                type="primary"
                loading={loading}
                style={{ marginTop: 14 }}
                htmlType="submit"
              >
                Login
              </Button>
            </Form>

            <div className="text-center text-white mt-3">
              <span>
                Copyright <strong>BRB Seguros</strong> © 2019-2022 <br />
              </span>
              <span>
                ST SAUN Quadra 5 Lote C Torre “C” – Asa Norte 
                <br /> Edifício Centro Empresarial CNC  
                <br /> Distrito Federal  |  CEP: 70.040-250
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
