import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Functions } from '~/utils';
import history from '~/services/history';
import { Spin } from 'antd';
import * as CotagroActions from '~/store/ducks/cotagro';

export default function Cotagro() {

    const dispatch = useDispatch();

    const { usuario } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(CotagroActions.gerarLinkLogin({
            param: usuario.email,
            callback: response => {
                var url = Functions.isProducao() 
                    ? "https://smartinsure-wizsolucoes.gclaims.com.br/brb/conta/login" 
                    : "https://cotagrobrb-hml.azureedge.net/login";

                window.open(response ?? url, '_blank');
                history.push('/');
            }
        }));
    }, [usuario.email]);

    return (
        <Spin spinning={true} size="large"></Spin>
    )
}