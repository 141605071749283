import * as yup from 'yup';
import { Validation } from '~/utils';
import moment from 'moment'

import { store } from '~/store';

const ApLibert = yup.object().shape({
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
  beneficiarios: yup
    .array()
    .test('nome', 'Por favor, informe o nome do(s) beneficiários', function v(
      value
    ) {
      return value.filter(b => b.nome === '').length === 0;
    })
    .test(
      'parentesco',
      'Por favor, informe o parentesco do(s) beneficiários.',
      function v(value) {
        return value.filter(b => b.grauRelacionamento === '').length === 0;
      }
    )
    .test(
      'percentual',
      'O percentual dos beneficiários devem totalizar 100%',
      function v(value) {
        let total = 0;
        value.map(
          b => (total += Number.parseFloat(b.percentual.replace(',', '.')))
        );
        return total === 100;
      }
    ),
  // .test(
  // 'cpf', 'Por favor, informe o cpf do beneficiário(a).',
  // function v(value) {
  //   return value.filter(b => b.cpf === '').length === 0;
  // }
  // )
});

const VppAmericanTitularPeso = yup.object().shape({
  titularAltura: yup.string().when('isAgenciaDigital', {
    is: false,
    then: yup.string().required('Por favor, informe a altura do titular.'),
  }),
  titularPeso: yup.string().when('isAgenciaDigital', {
    is: false,
    then: yup.string().required('Por favor, informe o peso do titular.'),
  }),
  conjugeCpf: yup.string().when('tipoPlano', {
    is: 2,
    then: yup.string().required('Por favor, informe o cpf do cônjuge.'),
  }),
  conjugeNome: yup.string().when('tipoPlano', {
    is: 2,
    then: yup.string().required('Por favor, informe o nome do cônjuge.'),
  }),
  conjugeDataNascimento: yup.date().when('tipoPlano', {
    is: 2,
    then: yup
      .date()
      .required('Por favor, informe a data de nascimento do cônjuge.')
      .nullable(),
  }),
  conjugeAltura: yup.string().when(['tipoPlano', 'isAgenciaDigital'], {
    is: (tipoPlano, isAgenciaDigital) => tipoPlano === 2 && !isAgenciaDigital,
    then: yup.string().required('Por favor, informe a altura do cônjuge.'),
  }),
  conjugePeso: yup.string().when(['tipoPlano', 'isAgenciaDigital'], {
    is: (tipoPlano, isAgenciaDigital) => tipoPlano === 2 && !isAgenciaDigital,
    then: yup.string().required('Por favor, informe o peso do cônjuge.'),
  }),
});

const VppAmerican = yup.object().shape({
  numeroPropostaManual: yup.string().when('tipoPropostaId', {
    is: 2,
    then: yup.string().required('Número da proposta manual é obrigatório.'),
  }),
  dataPagamentoPropostaManual: yup.date().when('tipoPropostaId', {
    is: 2,
    then: yup
      .date()
      .required('Data do pagamento da proposta manual é obrigatório.')
      .nullable(),
  }),
  contaDebitoId: yup
    .number()
    .when('tipoConta', {
      is: val => val === 1 || val === 2,
      then: yup
        .number()
        .required('Por favor, informe a conta.')
        .nullable(),
    })
    .nullable(),
  cpfTerceiro: yup.string().when('tipoConta', {
    is: 2,
    then: yup.string().required('Por favor, informe o cpf do terceiro.'),
  }),
  agenciaDebitoId: yup.number().when('tipoConta', {
    is: 3,
    then: yup.number().required('Por favor, informe a agência de débito.'),
  }),
  contaDebitoNumero: yup
    .string()
    .required('Por favor, informe o número da conta de débito.')
    .test('conta-valida', 'O número da conta é inválido.', function v(value) {
      if (!this.parent.agenciaDebitoId) return false;
      const {
        dominio: { agencias },
      } = store.getState();
      const agencia = agencias.find(
        a => a.agenciaId === this.parent.agenciaDebitoId
      );
      if (!agencia) return false;
      return Validation.isContaValid(agencia.agenciaCodigo, value);
    }),
  diaDebito: yup
    .number()
    .required('Por favor, informe o dia para débito das parcelas'),
  beneficiarios: yup
    .array()
    .test('nome', 'Por favor, informe o nome do(s) beneficiários', function v(
      value
    ) {
      return value.filter(b => b.nome === '').length === 0;
    })
    .test(
      'parentesco',
      'Por favor, informe o parentesco do(s) beneficiários.',
      function v(value) {
        return value.filter(b => b.grauRelacionamento === '').length === 0;
      }
    )
    .test(
      'percentual',
      'O percentual dos beneficiários devem totalizar 100%',
      function v(value) {
        let total = 0;
        value.map(b => (total += Number.parseInt(b.percentual, 10)));
        return total === 100;
      }
    ),
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
});

const BrbMaster = yup.object().shape({
  // titularAltura: yup
  //   .string()
  //   .required('Por favor, informe a altura do titular.'),
  // titularPeso: yup.string().required('Por favor, informe o peso do titular.'),
  contaDebitoId: yup.number().when('tipoConta', {
    is: val => val === 1,
    then: yup.number().required('Por favor, informe a conta.').nullable(),
  }).nullable(),
  contaDebitoNumero: yup
    .string()
    .required('Por favor, informe o número da conta de débito.')
    .test('conta-valida', 'O número da conta é inválido.', function v(value) {
      if (!this.parent.agenciaDebitoId) return false;
      const {
        dominio: { agencias },
      } = store.getState();
      const agencia = agencias.find(
        a => a.agenciaId === this.parent.agenciaDebitoId
      );
      if (!agencia) return false;
      return Validation.isContaValid(agencia.agenciaCodigo, value);
    }),
  diaDebito: yup
    .number()
    .required('Por favor, informe o dia para débito das parcelas'),
  beneficiarios: yup
    .array()
    .test('nome', 'Por favor, informe o nome do(s) beneficiários', function v(
      value
    ) {
      return value.filter(b => b.nome === '').length === 0;
    })
    .test(
      'dataNascimento',
      'Por favor, informe o data de nascimento do(s) beneficiários.',
      function v(value) {
        return (
          value.filter(
            b => b.grauRelacionamento !== 12 && b.dataNascimento === ''
          ).length === 0
        );
      }
    )
    .test(
      'parentesco',
      'Por favor, informe o parentesco do(s) beneficiários.',
      function v(value) {
        return value.filter(b => b.grauRelacionamento === '').length === 0;
      }
    )
    .test(
      'percentual',
      'O percentual dos beneficiários devem totalizar 100%',
      function v(value) {
        let total = 0;
        value.map(b => (total += Number.parseInt(b.percentual, 10)));
        return total === 100;
      }
    ),
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
});

const DreMitsui = yup.object().shape({
  isOutrosSeguros: yup
    .number()
    .required('Por favor, informe se há outros seguros no mesmo bem.'),
  contratoDREDataInicio: yup
    .date()
    .required('Por favor, informe o início de vigência.')
    .nullable(),
  contratoDREDataFim: yup
    .date()
    .required('Por favor, informe o fim de vigência.')
    .nullable(),
  bemEndereco: yup.object().shape({
    tipoImovel: yup
      .number()
      .required('Por favor, informe o tipo de imóvel.')
      .nullable(),
    clienteEnderecoCep: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .required('Por favor, informe o cep do local de risco.')
      .nullable(),
    clienteEndereco1: yup.string().required('Por favor, informe o endereço.'),
    clienteEnderecoNumero: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .required('Por favor, informe o número referente ao local de risco.')
      .nullable(),
    clienteEnderecoUf: yup
      .string()
      .required('UF é obrigatório')
      .nullable(),
    clienteEnderecoBairro: yup
      .string()
      .required('Por favor, informe o bairro.')
      .nullable(),
  }),
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
});

const DreLibertyCeS = yup.object().shape({
  isOutrosSeguros: yup
    .number()
    .required('Por favor, informe se há outros seguros no mesmo bem.'),
  contratoDREDataInicio: yup
    .date()
    .required('Por favor, informe o início de vigência.')
    .nullable(),
  contratoDREDataFim: yup
    .date()
    .required('Por favor, informe o fim de vigência.')
    .nullable(),
  bemEndereco: yup.object().shape({
    tipoImovel: yup.number().required('Por favor, informe o tipo de imóvel.'),
    clienteEnderecoCep: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .required('Por favor, informe o cep do local de risco.'),
    clienteEndereco1: yup.string().required('Por favor, informe o endereço'),
    clienteEnderecoNumero: yup
      .number()
      .required('Por favor, informe somente o número do endereço')
      .nullable(),
  }),
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
  inspecaoRiscoNome: yup
    .string()
    .required('Informe os dados(Nome) para impeção de risco')
    .nullable(),
  inspecaoRiscoDdd: yup
    .string()
    .required('Informe os dados(DDD) para impeção de risco')
    .nullable(),
  inspecaoRiscoTelefone: yup
    .string()
    .required('Informe os dados(Telefone) para impeção de risco')
    .nullable(),
  proponenteNome: yup
    .string()
    .required('Informe os dados do proponente(Nome)')
    .nullable(),
  proponenteCpfCnpj: yup
    .string()
    .required('Informe os dados do proponente(Cpf/CNPJ)')
    .nullable(),
});

const DreZurichRes = yup.object().shape({
  isOutrosSeguros: yup
    .number()
    .required('Por favor, informe se há outros seguros no mesmo bem.'),
  contratoDREDataInicio: yup
    .date()
    .required('Por favor, informe o início de vigência.')
    .nullable(),
  contratoDREDataFim: yup
    .date()
    .required('Por favor, informe o fim de vigência.')
    .nullable(),
  bemEndereco: yup.object().shape({
    tipoImovel: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .required('Por favor, informe o tipo de imóvel.')
      .nullable(),
    clienteEnderecoCep: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .required('Por favor, informe o cep do local de risco.')
      .nullable(),
    clienteEndereco1: yup.string().required('Por favor, informe o endereço'),
    clienteEnderecoNumero: yup
      .string()
      .required('Por favor, informe somente o número do endereço')
      .nullable(),
  }),
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
});

const DreZurichEmp = yup.object().shape({
  isOutrosSeguros: yup
    .number()
    .required('Por favor, informe se há outros seguros no mesmo bem.'),
  contratoDREDataInicio: yup
    .date()
    .required('Por favor, informe o início de vigência.')
    .nullable(),
  contratoDREDataFim: yup
    .date()
    .required('Por favor, informe o fim de vigência.')
    .nullable(),
  bemEndereco: yup.object().shape({
    clienteEnderecoCep: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .required('Por favor, informe o cep do local de risco.')
      .nullable(),
    clienteEndereco1: yup.string().required('Por favor, informe o endereço'),
    clienteEnderecoNumero: yup
      .string()
      .required('Por favor, informe somente o número do endereço')
      .nullable(),
    bemAtividade: yup
      .number()
      .required('Por favor, informe a Atividade Desenvolvida no Local'),
  }),
  controladores: yup
    .array()
    .test(
      'controladorNome',
      'Por favor, informe o nome do(s) controladores',
      function v(value) {
        return value.filter(b => b.nome === '').length === 0;
      }
    )
    .test(
      'controladorCpfcnpj',
      'Por favor, informe o CPF/CNPJ do(s) controladores.',
      function v(value) {
        return value.filter(b => b.cpfcnpj === '').length === 0;
      }
    )
    .test(
      'controladorNacionalidade',
      'Por favor, informe a nacionalidade do(s) controladores.',
      function v(value) {
        return value.filter(b => b.nacionalidade === '').length === 0;
      }
    )
    .test(
      'controladorPublico',
      'Por favor, informe se o(s) controladores são agentes públicos.',
      function v(value) {
        return value.filter(b => b.publico === '').length === 0;
      }
    ),
  propRazaoSocial: yup
    .string()
    .required('Por favor, informe a razão social do proponente.'),
  propCNPJ: yup.string().required('Por favor, informe o CNPJ do proponente.'),
  propAtividade: yup
    .number()
    .required('Por favor, informe a Atividade do proponente.'),
  propPatrimonioLiquido: yup
    .string()
    .required('Por favor, informe o Patrimônio Líquido do proponente.'),
  propPEP: yup
    .string()
    .required(
      'Por favor, informe se o proponente é Pessoa Exposta Politicamente.'
    ),
  propClausulaBeneficiaria: yup
    .string()
    .required('Por favor, informe a cláusula beneficiária.'),
  propClausulaBeneficiariaCpfCnpj: yup
    .string()
    .required('Por favor, informe o CPF/CNPJ da cláusula beneficiária.'),
  propCobertura: yup.number().required('Por favor, informe a cobertura.'),
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
});

const DreMitsuiEmp = yup.object().shape({
  isOutrosSeguros: yup
    .number()
    .required('Por favor, informe se há outros seguros no mesmo bem.'),
  contratoDREDataInicio: yup
    .date()
    .required('Por favor, informe o início de vigência.')
    .nullable(),
  contratoDREDataFim: yup
    .date()
    .required('Por favor, informe o fim de vigência.')
    .nullable(),
  bemEndereco: yup.object().shape({
    clienteEnderecoCep: yup
      .number()
      .transform(val => (Number.isNaN(val) ? undefined : val))
      .required('Por favor, informe o cep do local de risco.')
      .nullable(),
    clienteEndereco1: yup.string().required('Por favor, informe o endereço'),
    clienteEnderecoNumero: yup
      .number()
      .required('Por favor, informe somente o número do endereço')
      .nullable(),
  }),
  propRazaoSocial: yup
    .string()
    .required('Por favor, informe a razão social do proponente.'),
  propCNPJ: yup.string().required('Por favor, informe o CNPJ do proponente.'),
  planoSelected: yup.object().required('Por favor, selecione um plano.'),
});

const RespostasDpsAtividades = yup.object().shape({
  listaRespostas: yup
    .array()
    .test(
      'DPS Atividades',
      'Por favor, responda com "Sim" ou "Não" todas as perguntas',
      value =>
        value.every(x =>
          x.idRespostaPerguntaProduto === 0
            ? true
            : !!x.idRespostaPerguntaProduto
        )
    ),
  respostasObservacao: yup
    .string()
    .when('listaRespostas', {
      is: x =>
        x.some(y => y.idRespostaPerguntaProduto === 1 && !y.respostaObservacao),
      then: yup
        .string()
        .required('Por favor, informe os esclareciamentos')
        .nullable(),
    })
    .nullable(),
});

const RespostasDpsAtividadesBrbMaster = yup.object().shape({
  listaRespostas: yup.array()
    .test('DPS Atividades', 'Por favor, responda com "Sim" ou "Não" todas as perguntas',
      value => value.every(x => x.idRespostaPerguntaProduto === 0 ? true : !!x.idRespostaPerguntaProduto)
    ),
  respostasObservacao: yup.string().when('listaRespostas', {
    is: (x) => x.some(y => y.idRespostaPerguntaProduto === 1 && !y.respostaObservacao),
    then: yup.string()
      .required('Por favor, informe os esclareciamentos')
      .nullable(),
  }).nullable(),
});

const PessoaPoliticamenteExposta = yup.object().shape({
  isPessoaPoliticamenteExposta: yup
    .boolean()
    .required('Informe se é pessoa politicamente exposta!'),
  tipoPessoaPoliticamenteExposta: yup
    .number()
    .when('isPessoaPoliticamenteExposta', {
      is: true,
      then: yup
        .number()
        .required('Por favor, selecione pessoa politicamente exposta!'),
    }),
});

const CapIcatu = yup.object().shape({
  debitoConta: yup.object().shape({
    agenciaId: yup
      .number()
      .required(
        'Favor preencher os dados bancários: Agência de débito em branco'
      )
      .nullable(),
    debitoContaNumeroConta: yup
      .string()
      .required(
        'Favor preencher os dados bancários: Número da conta de débito em branco'
      )
      .nullable(),
  }),
  contratoCapitalizacaoDiaDebito: yup.number().when('produtoId', {
    is: value => value !== 161137,
    then: yup
      .number()
      .required('Favor, preencher o melhor dia para débito')
      .nullable(),
  }),
});

const CotacaoLibertyResidencialPersonalizavelPasso2 = yup.object().shape({
  localRisco: yup.object().shape({
    tipoAssistenciaId: yup
      .string()
      .required('Favor preencher o tipo de assistência desejada')
      .nullable()
  }),
  coberturas: yup.array().of(
    yup.object().shape({
      codigoCobertura: yup.string().required('Nome da cobertura deve ser informado'),
      codigoVerba: yup.string().nullable().when('codigoCobertura', {
        is: value => value === '11429',
        then: yup.string().required('Deve-se informar o tipo de seguro para a cobertura principal')
      }),
      valorCobertura: yup.number().nullable().when('codigoCobertura', {
        is: value => value === '11429',
        then: yup.number().required('Deve-se informar o valor para a cobertura principal')
          .test('ValorMinimoPreenchido', 'Deve-se informar o valor para a cobertura principal acima de R$ 0, 00', function v(val) {
            if(!val) return true;
            return val > 0;
          })
      })
    }))
    .test('PeloMenosCoberturaBasicaInformada', 'Deve-se informar o valor e o tipo de seguro para a cobertura principal', (val) => {
      if(!val || !val.length) return false;

      var coberturaPrincipal = val.find(x => x.codigoCobertura === '11429');
      if(!coberturaPrincipal) return false;

      return true;

    })
});

const LocalRiscoLibertyResidencialPersonalizavelCotacao = yup.object().shape({
  tipoHabitacaoId: yup
    .string()
    .required(
      'Favor informar o tipo do imóvel'
    )
    .nullable(),
  tipoConstrucaoId: yup
    .string()
    .required(
      'Favor informar o tipo de construção'
    )
    .nullable(),
  tipoOcupacaoId: yup
    .string()
    .required(
      'Favor informar o tipo de utilização'
    )
    .nullable(),
    tipoAtividadeProfissionalExercidaId: yup.string().nullable().when('tipoOcupacaoId', {
    is: value => value === "3", // home office
    then: yup
      .string()
      .required('Favor informar o tipo de home office')
      .nullable(),
  }),
});

const DatasVigenciaCotacaoLibertyPersonalizavel = yup.object().shape({
  dataInicioVigencia: yup
    .date()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required('Por favor, informe o início de vigência.')
    .nullable()
    .test('dataInicioVigenciaMaximo30Dias', 'A data de início da vigência não deve ser superior à data atual mais 30 dias', function v(val) {
      if(!val) return true;
      var ehDepois = moment().add(31, 'day').isAfter(moment(val), "day")
      return ehDepois;
    })
    .test('dataInicioVigenciaNaoPodeSerAnteriorADataAtual', 'A data de início da vigência deve ser igual ou superior à data atual', function v(val) {
      var pularValidacao = this.parent.naoValidarDataAnteriorAAtual;
      if(pularValidacao) return true.valueOf;
      if(!val) return true;
      var diferenca = moment(val).diff(moment(), "day")
      return diferenca >= 0;
    }),
  dataFimVigencia: yup
    .date()
    .required('Por favor, informe o fim de vigência.')
    .nullable()
    .test('dataFimVigenciaPosteriorAInicial', 'A data de fim da vigência deve ser posterior à de início', function v(val) {
      const dataInicio = this.parent.dataInicioVigencia;
      if(!val || !dataInicio) return true;
      var ehDepois = moment(val).add(1, 'day').isAfter(moment(dataInicio), "day")
      return ehDepois;
    })
    .test('dataFimDeveSerNoMaximo5AnosPosterior', 'O período máximo de vigência deve ser de 36 meses', function v(val) {
      const dataInicio = this.parent.dataInicioVigencia;
      const dataFim = moment(val).add('month')
      
      if(!val || !dataInicio) return true;
      var diferenca = dataFim.diff(moment(dataInicio), "months")
      return diferenca < 36;
    }),
});

const ContaDebitoLibertyPersonalizavel = yup.object().shape({
  codigoBanco: yup
    .string()
    .nullable()
    .required('Deve-se informar o banco da conta débito'),
  agenciaId: yup
    .number()
    .nullable()
    .required('Deve-se informar a agência da conta débito'),
  cpfCnpjTitularConta: yup
    .string()
    .nullable()
    .required('Deve-se informar o CPF / CNPJ do titular da conta débito')
    .test('cpfCnpjValido', 'Deve-se informar um CPF / CNPJ válido para a conta débito', (val) => {
      if(!val) return true;
      return val.length > 11 ? Validation.isCNPJValid(val) : Validation.isCpfCnpjValid(val);
    }),
  nomeTitularConta: yup
    .string()
    .nullable()
    .required('Deve-se informar o nome do titular da conta')
    .test('nomeTitularValido', 'Deve-se informar nome e sobrenome para o titular da conta débito', (val) => {
      if(!val) return true;

      val = val.trim();
      var nomeArray = val.split(' ');
      return nomeArray.length > 1;
    }),
  numeroContaCorrente: yup
    .string()
    .required('Por favor, informe o número da conta de débito.')
    .test('conta-valida', 'O número da conta é inválido.', function v(value) {
      if(!value) return true;
      if (!this.parent.agenciaId) return false;
      const {
        dominio: { agencias },
      } = store.getState();
      const agencia = agencias.find(
        a => a.agenciaId === this.parent.agenciaId
      );
      if (!agencia) return false;
      return Validation.isContaValid(agencia.agenciaCodigo, value);
    }),
})

export default {
  ApLibert,
  VppAmerican,
  VppAmericanTitularPeso,
  DreMitsui,
  DreLibertyCeS,
  DreZurichRes,
  DreZurichEmp,
  DreMitsuiEmp,
  RespostasDpsAtividades,
  PessoaPoliticamenteExposta,
  CapIcatu,
  DatasVigenciaCotacaoLibertyPersonalizavel,
  LocalRiscoLibertyResidencialPersonalizavelCotacao,
  CotacaoLibertyResidencialPersonalizavelPasso2,
  ContaDebitoLibertyPersonalizavel,
  RespostasDpsAtividadesBrbMaster,
  BrbMaster
};