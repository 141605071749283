import React from 'react';
import { Button, Breadcrumb, Icon } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { Content } from './style';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Functions } from '~/utils';
import history from '~/services/history';
import * as AuthActions from '~/store/ducks/auth';

type ContentHeaderProps = {
  title: String,
  icon: String,
  breadText: String,
  showButton: Boolean,
  textButton: String,
};

const matriculasProjetoPiloto = [
  '00273',
  '00275',
  '82206',
  '00431',
];

export default function ContentHeader({
  title,
  icon,
  breadText,
  showButton,
  textButton,
  onClick,
}: ContentHeaderProps) {
  const {
    keySSOWiz,
    usuario,
  } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  function redirect() {    
    if (!matriculasProjetoPiloto.includes(usuario.matricula) && usuario.descTipoProdutor !== 'ANGARIADOR') return;

    const produtoId = undefined;
    const produtoGrupoId = undefined;
    const matricula = usuario.matricula;

    const url = Functions.isProducao() 
    ? `https://brbcompleto.com.br/integration#` 
    : `https://brbcgestorhmlstg.z13.web.core.windows.net/integration#`;

    dispatch(AuthActions.tokenWiz({
        param: { matricula, produtoId, produtoGrupoId },
        callback: response => {
            
            if (!response) history.push('/');

            window.open(url + response, '_blank');
            history.push('/');
        }
    }));
  }
  return (
    <Content>
      <div>
        <h3>{title}</h3>
        <Breadcrumb>
          <Breadcrumb.Item href="">
            <Icon type={icon} />
          </Breadcrumb.Item>
          {breadText && (
            <Breadcrumb.Item>
              <span>{breadText}</span>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      {showButton && (
        <Row gutter={[16, 16]}>
          {(matriculasProjetoPiloto.includes(usuario.matricula) || usuario.descTipoProdutor === 'ANGARIADOR') && (
            <Col span={24}>
              <Button
                title="Clique aqui para ver como está seu desempenho em Seguridade."
                type="primary"
                size="large"
                style={{ width: 162 }}
                onClick={redirect}
              >
                Meus Resultados
              </Button>
            </Col>
          )}
          <Col span={24}>
            <Button
              style={{ width: 162 }}
              type="success"
              size="large"
              onClick={onClick}
            >
              <PlusOutlined /> {textButton}
            </Button>
          </Col>
        </Row>
      )}
    </Content>
  );
}
