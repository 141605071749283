import React, { useEffect } from 'react';

import { Row, Col, Spin, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Block, BlockTable } from './styles';
import residencialLibertyUtil from '../util'

import * as DominiosActions from '~/store/ducks/dominio';

import imgResidenciaPremiado from '~/assets/img/brb_residencia_premiado.jpg';
import imgSeguradora from '~/assets/img/liberty.png';
import { Functions, Mask } from '~/utils';

type FieldProps = {
  span: Number,
  title: String,
  value: String,
};

export default function Impressao() {
  const {
    generos,
    estadosCivis,
    naturalidade,
    consultores,
    angariadores,
    agencias,
    bancos,
    loading,
  } = useSelector(({ dominio }) => dominio);
  const { cliente } = useSelector(state => state.cliente);
  const {
    contratoId,
    propostaId,
    dreMitsui,
    dreMitsui: { bemEndereco, planoSelected, pagamento },
    producao,
  } = useSelector(({ proposta }) => proposta);

  const { 
    libertyResidencialPersonalizavel, 
    libertyResidencialPersonalizavel: {
      cotacao, 
      efetivacao, 
      listaNomesDominios, 
      propostaDetalhes} 
  } = useSelector(state => state);

  const { usuario } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DominiosActions.listAll(print));
  }, []);

  function ehPessoaJuridica() {
    return !!cliente?.clienteCpfCnpj &&
      (cliente?.clienteCpfCnpj ?? '').length === 14
  }

  function print() {
    setTimeout(function() {
      window.focus();
      window.print();
    }, 2000); // wait for images to load inside iframe
  }

  function Field({ span, title, value }: FieldProps) {
    if (!value) return <></>;
    return (
      <Col span={span}>
        <b>{title}:</b> {value}
      </Col>
    );
  }

  function retornaPagamentoEscolhido() {

    var listaPagamentos = libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento ?? [];
    if(!listaPagamentos.length || !efetivacao?.escolhaFormaPagamentoId) return {};

    var escolhido = listaPagamentos.find(x => x.formasPagamento.some(y => y.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId))    
    if(!escolhido) return {};

    escolhido.listaFormaEscolhida = escolhido.formasPagamento.filter(x => x.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId) 
    return escolhido;
  }

  function retornaDescricaoTipoImovel(idTipoHabitacao) {
    if(!idTipoHabitacao) return '';
    var lista = retornaListaDominio('Liberty Residencial Personalizável Tipo Habitação');

    var selecionado = lista.find(x => x.dominioParceiroCodigo === idTipoHabitacao);
    
    return (selecionado ?? {}).dominioParceiroDescricao;
  }

  function retornaListaDominio(nomeLista) {
    var retorno = [];

    var retorno = ((listaNomesDominios ?? [])
      .find(x => x.dominioParceiroNomeDescricao.includes(nomeLista)) ?? { dominioParceiros: [] }).dominioParceiros;

    return retorno;
  }

  function retornaDescricaoBancoSelecionado() {
    var banco = (bancos ?? []).find(x => x.bancoId === efetivacao?.contaDebito?.codigoBanco)
    return `${efetivacao?.contaDebito?.codigoBanco} - ${banco?.bancoDescricao}`
  }

  function getDescricaoDominio(dominio, textValue, textDescription, value) {
    if (!value) return 'Não Informado';
    return dominio.find(d => d[textValue] === value)?.[textDescription];
  }

  function retornaCoberturaDominio(codigoCobertura) {
    var listaCoberturas = [...(retornaListaDominio('Liberty Residencial Personalizável Cobertura') ?? [])];

    var cobertura = listaCoberturas.find(x => x.dominioParceiroCodigo === codigoCobertura);

    return cobertura ?? {};
  }


  function retornaDescricaoCobertura(codigoCobertura) {
    var cobertura = retornaCoberturaDominio(codigoCobertura);

    return cobertura.dominioParceiroDescricao === 'RESPONSABILIDADE CIVIL FAMILIAR'
      ? 'RSP CIVIL FAMILIAR'
      : cobertura.dominioParceiroDescricao
  }

  function getFormaPagamento() {
    const parcela = Functions.formatarMoeda(pagamento.mtrContratoDRE1Parcela);
    if (pagamento.formaPagamento === 1) return `À Vista R$ ${parcela}`;

    if (pagamento.formaPagamento === 2)
      return ` 1 + ${pagamento.contratoDREQtdParcela} Parcela(s) SEM JUROS de R$ ${parcela}`;

    if (pagamento.formaPagamento === 3)
      return ` 0 + ${pagamento.contratoDREQtdParcela +
        1} Parcela(s) SEM JUROS de R$ ${parcela}`;

    return '';
  }

  function getPremioLiquidoFormatado() {
    if(!propostaDetalhes || !propostaDetalhes.valorTotalPremio) return 'R$ 0,00';
    
    return Mask.maskMoney(propostaDetalhes.valorTotalPremio);
  }
  
  function getPremioTotalFormatado() {
    var pagamentoEscolhido = retornaPagamentoEscolhido(); 
    if(!propostaDetalhes || !propostaDetalhes.valorTotalPremio) return 'R$ 0,00';

    var pagamentoFormaPagamentoEscolhida = pagamentoEscolhido?.listaFormaEscolhida[0];
    
    var valor = propostaDetalhes.valorTotalPremio + (pagamentoFormaPagamentoEscolhida?.valorIof ?? 0) 
      + (pagamentoFormaPagamentoEscolhida?.valorJurosAplicado ?? 0);
    
    return Mask.maskMoney(valor);
  }
  
  function getDescricaoPagamentoParcelamento() {
    var pagamentoEscolhido = retornaPagamentoEscolhido(); 
    if(!pagamentoEscolhido || !pagamentoEscolhido.listaFormaEscolhida || !pagamentoEscolhido.listaFormaEscolhida.length)
      return '';
    
    var formaEscolhida = pagamentoEscolhido.listaFormaEscolhida[0];

    var retorno = `1 de ${Mask.maskMoney(formaEscolhida.valorPrimeiraParcela)} `;

    if((formaEscolhida?.quantidadeParcelas ?? 1) > 1)
      retorno += `+ ${((formaEscolhida?.quantidadeParcelas ?? 1) - 1)} de ${Mask.maskMoney(formaEscolhida.valorDemaisParcelas)}`

    return retorno;
  }

  function getDescricaoFormaPagamento() {
    var formasPagamentos = propostaDetalhes.formasPagamento;

    var formaSelecionada = (formasPagamentos ?? [])
      .find(x => x.formasPagamento.some(y => y.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId));

    return residencialLibertyUtil
      .retornaDescricaoFormaPagamento(formaSelecionada?.formaPagamentoTipoCodigo, formaSelecionada?.formaPagamentoPlanoCodigo)
  }

  function retornaValorMonetario(valor) {
    if(!valor) return 'R$ 0,00'; 

    return Mask.maskMoney(valor)
  }

  function getPremioIOFormatado() {
    var pagamentoEscolhido = retornaPagamentoEscolhido(); 
    
    var valor = (pagamentoEscolhido?.listaFormaEscolhida[0]?.valorIof ?? 0);

    return Mask.maskMoney(valor);
  }

  function getValorJurosormatado() {
    var pagamentoEscolhido = retornaPagamentoEscolhido(); 
    
    var valor = (pagamentoEscolhido?.listaFormaEscolhida[0]?.valorJurosAplicado ?? 0);

    return Mask.maskMoney(valor);
  }

  function possuiJurosAplicado() {
    var pagamentoEscolhido = retornaPagamentoEscolhido(); 
    
    var valor = (pagamentoEscolhido?.listaFormaEscolhida[0]?.valorJurosAplicado ?? 0);

    return valor > 0;
  }

  return (
    <>
      <Spin
        spinning={loading}
        style={{ textAlign: 'center' }}
        size="large"
        tip="Aguarde carregando"
      >
        {loading && <Skeleton active />}
      </Spin>
      {generos && angariadores && consultores && (
        <Container>
          {/* Dados Proposta */}
          <Block>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col span={10} style={{ paddingLeft: 4 }}>
                <img src={imgResidenciaPremiado} alt="BRB" />
              </Col>
              <Col span={6} style={{ alignItems: 'center' }}>
                <b>{contratoId ? 'Proposta de Seguro' : 'Cotação de Seguro'}</b>
                <br />
                {contratoId
                  ? `Número da Proposta: ${contratoId}`
                  : `Número da Cotação: ${propostaId}`}
              </Col>
              <Col
                span={8}
                style={{
                  textAlign: 'right',
                  paddingRight: 10,
                }}
              >
                <img src={imgSeguradora} alt="Logo" width="70" />
              </Col>
            </Row>
          </Block>
          {/* Tipo Seguro */}
          <Block>
            <Row>
              <b>Tipo de Seguro: </b> Seguro Novo
            </Row>
            <Row>
              <b>Vigência do Seguro: </b> A partir das 24h de {' '}
              {Functions.formatarData(cotacao.dataInicioVigencia)} { ' ' }
              até as 24h de {' '}
              {Functions.formatarData(cotacao.dataFimVigencia)}
            </Row>
          </Block>
          {/* Dados do Cliente */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PROPONENTE</b>
            </Row>
            <Row>
              <Field span={10} title="Nome" value={cliente.clienteNome} />
              <Field span={8} title={ ehPessoaJuridica() ? 'CNPJ' : 'CPF' } value={cliente.clienteCpfCnpj} />
              {
                !ehPessoaJuridica() &&
                <Field
                  span={6}
                  title="Data de Nascimento"
                  value={Functions.formatarData(cliente.clienteDataNascimento)}
                />
              }
            </Row>
            {
              !ehPessoaJuridica() &&
              <>
                <Row>
                  <Field
                    span={10}
                    title="Sexo"
                    value={getDescricaoDominio(
                      generos,
                      'sexoId',
                      'sexoDescricao',
                      cliente.sexoId
                    )}
                  />
                  <Field
                    span={8}
                    title="Estado Civil"
                    value={getDescricaoDominio(
                      estadosCivis,
                      'estadoCivilId',
                      'estadoCivilDescricao',
                      cliente.estadoCivilId
                    )}
                  />
                  <Field
                    span={6}
                    title="Nacionalidade"
                    value={getDescricaoDominio(
                      naturalidade,
                      'naturalidadeId',
                      'naturalidadeDescricao',
                      cliente.naturalidadeId
                    )}
                  />
                </Row>
                  <Row>
                  <Field
                    span={10}
                    title="Nº da Identidade"
                    value={cliente.clienteRg}
                  />
                  <Field
                    span={8}
                    title="Data de emissão"
                    value={Functions.formatarData(cliente.clienteDataExpedicaoRg)}
                  />
                  <Field
                    span={6}
                    title="Orgão Emissor"
                    value={cliente.clienteRgSsp}
                  />
                </Row>
              </>
            }
            <Row>
              {
                !!cliente.clienteContatoFones && !!cliente.clienteContatoFones.length && !!(cliente.clienteContatoFones[0]) && 
                <>
                  {
                    !!(cliente.clienteContatoFones[0]?.clienteContatoFoneTipoId === 1) &&
                    <Field
                      span={10}
                      title="Telefone Residencial"
                      value={cliente.clienteContatoFones[0]?.clienteContatoFoneNumero}
                    />
                  }

                  {
                    !!(cliente.clienteContatoFones[0]?.clienteContatoFoneTipoId === 4) &&
                    <Field
                      span={10}
                      title="Telefone Celular"
                      value={cliente.clienteContatoFones[0]?.clienteContatoFoneNumero}
                    />
                  }

                  {
                    !!(cliente.clienteContatoFones[0]?.clienteContatoFoneTipoId === 2) &&
                    <Field
                      span={10}
                      title="Telefone Comercial"
                      value={cliente.clienteContatoFones[0]?.clienteContatoFoneNumero}
                    />
                  }

                  
                </>
              }
              <Field
                title="E-mail"
                span={12}
                value={
                  cliente.clienteContatoMails[0]?.clienteContatoMailDescricao
                }
              />
            </Row>
          </Block>
          {/* Local de Risco */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>LOCAL DE RISCO</b>
            </Row>
            <Row>
              <Field
                span={10}
                title="Tipo de Imóvel"
                value={retornaDescricaoTipoImovel(cotacao?.localRisco?.tipoHabitacaoId)}
              />
              <Field
                span={8}
                title="CEP"
                value={cotacao?.localRisco?.endereco?.clienteEnderecoCep}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Endereço de Risco"
                value={cotacao?.localRisco?.endereco?.clienteEndereco1}
              />
              <Field
                span={8}
                title="Número"
                value={cotacao?.localRisco?.endereco?.clienteEnderecoNumero}
              />
              <Field
                span={6}
                title="Complemento"
                value={cotacao?.localRisco?.endereco?.clienteEnderecoComplemento}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Bairro"
                value={cotacao?.localRisco?.endereco?.clienteEnderecoBairro}
              />
              <Field
                span={8}
                title="Cidade"
                value={cotacao?.localRisco?.endereco?.clienteEnderecoCidade}
              />
              <Field
                span={6}
                title="UF"
                value={cotacao?.localRisco?.endereco?.clienteEnderecoUf}
              />
              <Field
                span={6}
                title="Tipo logradouro"
                value={cotacao?.localRisco?.endereco?.tipoLogradouroLibertyResidencialPersonalizavel}
              />
            </Row>
          </Block>
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>BENEFICIÁRIOS</b>
            </Row>
            {
              !!contratoId && !!efetivacao?.beneficiarios && !!efetivacao?.beneficiarios.length &&
              <>
                {
                  efetivacao.beneficiarios.map(x => (
                    x
                  ))
                }
              </>
            }
          </Block>
          {/* Plano Selecionado */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PLANO</b>
            </Row>
            <Row type="flex">
              {
                !!cotacao && !!cotacao?.coberturas && !!cotacao.coberturas.length &&
                cotacao.coberturas.map(x => (
                  !!x.valorCobertura && <>
                    <BlockTable minHeight bold>
                      {retornaDescricaoCobertura(x.codigoCobertura)}
                    </BlockTable>
                  </>
                ))
              }

              <BlockTable minHeight bold>
                Prêmio Líquido
              </BlockTable>
              {
                !!contratoId &&
                <>
                  <BlockTable minHeight bold>
                    IOF
                  </BlockTable>
                  { possuiJurosAplicado() &&
                    <BlockTable minHeight bold>
                      Juros
                    </BlockTable>
                  }
                  <BlockTable minHeight bold>
                    Prêmio Total
                  </BlockTable>
                </>
              }
            </Row>
            <Row type="flex">
              {
                !!cotacao && !!cotacao?.coberturas && !!cotacao.coberturas.length &&
                cotacao.coberturas.map(x => (
                  !!x.valorCobertura && <>
                    <BlockTable minHeight>
                      {retornaValorMonetario(x.valorCobertura)}
                    </BlockTable>
                  </>
                ))
              }

              <BlockTable>{getPremioLiquidoFormatado()}</BlockTable>
              {
                !!contratoId && 
                <>
                  <BlockTable>{getPremioIOFormatado()}</BlockTable>
                  { possuiJurosAplicado() &&
                    <BlockTable>
                      {getValorJurosormatado()}
                    </BlockTable>
                  }
                  <BlockTable>
                    {getPremioTotalFormatado()}
                  </BlockTable>
                </>
              }
            </Row>
            <Row>
              <span>
                <i>Remuneração pela intermediação de 57% sobre o prêmio</i>
              </span>
            </Row>
          </Block>
          {/* Forma de Pagamento */}
          {
            !!contratoId && <Block>
              <Row style={{ marginBottom: 12 }}>
                <b>FORMA DE PAGAMENTO</b>
              </Row>
              <Row>
                {/* <Col span={6}>{getDescricaoFormaPagamento()}</Col> */}
                <Col span={6}>{ getDescricaoFormaPagamento() }</Col>
              </Row>
              <Row>
                <Col span={6}>
                  <strong>
                    { getDescricaoPagamentoParcelamento() }
                  </strong>
                </Col>
              </Row>
              {/* <Row>
                <Col span={6}>{getFormaPagamento()}</Col>
                <Field
                  title="Nosso Número"
                  value={pagamento.propostaEletronicaNossoNumero}
                />
              </Row> */}
            </Block>
          }
          {/* Termo e Assinatura */}
          <Block>
            <Row style={{ paddingBottom: 2, paddingTop: 10 }}>
            Concordo com a forma de pagamento especificada autorizando o débito, 
            na referida conta-corrente, do valor relativo à(s) parcela(s) do 
            prêmio devido pela contratação deste seguro. Estou ciente de que a 
            inadimplência de quaisquer das parcelas do prêmio no(s) seu(s) vencimento(s), 
            decorrente de insuficiência de saldo, implicará na suspensão imediata das 
            coberturas contratadas, observados os termos da Cláusula de Pagamento de 
            Prêmio das Condições Gerais do Seguro. Declaro que as informações constantes 
            nesta proposta forma por mim prestadas e, em sendo apurada qualquer divergência 
            naquelas que serviram de base para a taxação do risco, por omissão ou 
            incorreção desses dados, estou ciente que poderei perder o direito a uma 
            eventual indenização, nos termos previstos no Código Civil Brasileiro e na 
            legislação pertinente, devendo ainda efetuar o pagamento do prêmio vencido. 
            Declaro ainda que li e concordo com as Condições Gerais do Seguro em questão, 
            que me foram apresentadas previamente à assinatura da presente proposta.
            </Row>
            <b>RENOVAÇÃO AUTOMÁTICA</b>
            <Row style={{ paddingTop: 1 }}>
            <b> •	Do Cancelamento da renovação automática: </b>
            A qualquer momento, e até 30 dias antes do fim da vigência 
            desta 1ª apólice, o cliente poderá solicitar o cancelamento 
            da renovação automática, pelos canais de atendimento da BRB 
            Seguros ou do BRB.
            </Row>
            <Row>
            <b> •	Da Forma de pagamento: </b>
            O cliente está ciente de que, na renovação automática, a forma 
            de pagamento será alterada para débito em conta corrente (informada 
            no momento da contratação), em 10x, sem juros.
            </Row>
            <Row style={{ paddingBottom: 10}}>
            <b> •	Da Renovação automática: </b>
             Com a renovação automática, a nova apólice será emitida em 
             até 15 dias antes do término de vigência da apólice atual, 
             e enviada para o e-mail cadastrado pelo cliente. O cliente 
             está ciente de que o pagamento da 1ª parcela configurará o 
             aceite da nova apólice de seguro.
            </Row>
            <Row>
              <Field
                span={14}
                title="Local e data"
                value={`Brasília, ${Functions.getDateNow()}`}
              />
              <Col span={6} style={{ textAlign: 'center' }}>
                ________________________________________________
                <br />
                <b>Assinatura do Proponente</b>
              </Col>
            </Row>
          </Block>
          {/* Dados Débito Conta */}
          {
            !!contratoId && !!efetivacao?.contaDebito &&
            <>
              <Block>
                <Row style={{ marginBottom: 12 }}>
                  <b>DADOS PARA DÉBITO EM CONTA CORRENTE</b>
                </Row>
                <Row>
                  <Field
                    span={6}
                    title="Banco"
                    value={
                      efetivacao?.contaDebito?.codigoBanco != null
                        ? retornaDescricaoBancoSelecionado()
                        : ''
                    }
                  />
                  <Field
                    span={6}
                    title="Agência"
                    value={
                      efetivacao?.contaDebito?.numeroAgencia
                    }
                  />
                  <Field
                    span={6}
                    title="Conta Corrente Nº"
                    value={efetivacao?.contaDebito?.numeroContaCorrente}
                  />

                  {efetivacao?.diaPreferenciaPagamento && (
                    <Field
                      span={6}
                      title="Dia Preferencial para Pagamento"
                      value={efetivacao?.diaPreferenciaPagamento}
                    />
                  )}
                </Row>
              </Block>
            </>
          }
          {/* Dados Produção */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DA PRODUÇÃO</b>
            </Row>
            <Row>
              <Field
                span={6}
                title="Mat. Consultor"
                value={getDescricaoDominio(
                  consultores,
                  'produtorId',
                  'produtorCodigo',
                  producao.consultorId
                )}
              />
              <Field
                span={6}
                title="Mat. Angariador"
                value={getDescricaoDominio(
                  angariadores,
                  'produtorId',
                  'produtorCodigo',
                  producao.angariadorId
                )}
              />
              <Field
                span={6}
                title="Agência Produtora"
                value={getDescricaoDominio(
                  agencias,
                  'agenciaId',
                  'agenciaCodigo',
                  producao.agenciaId
                )}
              />
              <Field span={6} title="Equipe" value={producao.equipeId} />
            </Row>
          </Block>
        </Container>
      )}
    </>
  );
}
