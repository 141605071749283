import React, { useState, useEffect } from 'react';

import { Row, Col, Spin, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Container, Block } from './styles';

import * as DominiosActions from '~/store/ducks/dominio';

import imgVidaLogo from '~/assets/favicon.png';
import imgAmericanLife from '~/assets/img/americanLife.png';
import { Functions, Mask } from '~/utils';
import useIsAgenciaDigital from '~/hooks/useIsAgenciaDigital';

type FieldProps = {
  span: Number,
  title: String,
  value: String,
};

export default function Impressao() {
  const {
    generos,
    estadosCivis,
    naturalidade,
    grausRelacionamento,
    consultores,
    angariadores,
    agenciasBrb,
    loading,
    profissoes,
    cargos,
  } = useSelector(({ dominio }) => dominio);
  const { equipesProdutor } = useSelector(({ producao }) => producao);
  const { cliente, endereco } = useSelector(state => state.cliente);
  const [hasIsencaoDps, setHasIsencaoDps] = useState(false);

  const [isAgenciaDigital] = useIsAgenciaDigital();

  const {
    vppAmerican,
    vppAmerican: { beneficiarios, planoSelected },
    producao,
    perguntasProduto,
    numeroControle,
  } = useSelector(({ proposta }) => proposta);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DominiosActions.listAll(print));
  }, []);

  useEffect(() => {
    if (isAgenciaDigital && planoSelected.mtrGarantiaMorte <= 50000)
      setHasIsencaoDps(true);
  }, [isAgenciaDigital]);

  function print() {
    setTimeout(function v() {
      window.print();
    }, 2000); // ADD ESSA LINHA POIS NÃO ESTAVA CARREGANDO AS IMAGENS NA IMPRESSÃO
  }

  function Field({ span, title, value }: FieldProps) {
    if (!value) return <></>;
    return (
      <Col span={span}>
        <b>{title}:</b> {value}
      </Col>
    );
  }

  function getDescricaoDominio(dominio, textValue, textDescription, value) {
    if (!value) return 'Não Informado';
    return dominio.find(d => d[textValue] == value)?.[textDescription];
  }

  function getMarcacaoDpsPergunta(
    perguntaProdutoId,
    idPessoaRespostaPerguntaProduto
  ) {
    var pergunta = ((vppAmerican.respostasDps || {}).listaRespostas || []).find(
      x => x.perguntaProdutoId === perguntaProdutoId
    );

    // idPessoaRespostaPerguntaProduto 3 significa ambos
    return pergunta &&
      pergunta.idRespostaPerguntaProduto === 1 &&
      (pergunta.idPessoaRespostaPerguntaProduto ===
        idPessoaRespostaPerguntaProduto ||
        pergunta.idPessoaRespostaPerguntaProduto === 3)
      ? 'Sim'
      : 'Não';
  }

  function getRespostaDpsPergunta(perguntaProdutoId) {
    var pergunta = ((vppAmerican.respostasDps || {}).listaRespostas || []).find(
      x => x.perguntaProdutoId === perguntaProdutoId
    );

    return pergunta.respostaObservacao;
  }

  function getProfissao() {
    const profissaoFiltered = profissoes.filter(
      p => p.clienteProfissaoId === cliente.clienteProfissaoId
    )[0];
    if (profissaoFiltered) return profissaoFiltered.clienteProfissaoDescricao;

    return '';
  }

  function getCargo() {
    const cargoFiltered = cargos.filter(
      c => c.clienteCargoId === cliente.clienteCargoId
    )[0];
    if (cargoFiltered) return cargoFiltered.clienteCargoDescricao;

    return '';
  }

  return (
    <>
      <Spin
        spinning={loading}
        style={{ textAlign: 'center' }}
        size="large"
        tip="Aguarde carregando"
      >
        {loading && <Skeleton active />}
      </Spin>
      {generos && angariadores && consultores && (
        <Container>
          {/* Dados Proposta */}
          <Block>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col span={2} style={{ paddingLeft: 4 }}>
                <img
                  src={imgVidaLogo}
                  alt="BRB"
                  style={{ width: 44, height: 36 }}
                />
              </Col>
              <Col span={8}>
                <b style={{ fontSize: 16 }}>BRB VIDA PREMIADO PLUS</b>
              </Col>
              <Col span={6} style={{ alignItems: 'center' }}>
                <b>Proposta de Adesão</b>
                <br />
                {`Número da Proposta: 1038 - ${numeroControle}`}
              </Col>
              <Col
                span={8}
                style={{
                  textAlign: 'right',
                  paddingRight: 10,
                }}
              >
                <img src={imgAmericanLife} alt="Logo" width="70" height="74" />
              </Col>
            </Row>
          </Block>
          <Block>
            <Row type="flex">
              <Field
                span={24}
                title="Estipulante"
                value="AEBRB - Associação dos Empregados do Banco de Brasília - Apólice de Vida em Grupo - Nº 1009300001038"
              />
              <Field
                span={10}
                title="Seguradora"
                value="AMERICAN LIFE COMPANHIA DE SEGUROS"
              />
              <Field span={8} title="Tipo de Seguro" value="Seguro Novo" />
              <Row style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                <b>Vigência do Seguro: </b>O início de vigência do seguro será a
                partir das 24 horas do dia do pagamento inicial, descrito
                abaixo, desde que a proposta tenha sido aceita pela Seguradora,
                podendo ser renovado automaticamente, por uma única vez, ao
                término do período anual de vigência, salvo se houver
                manifestação contrária de uma das partes, com antecedência de 60
                (sessenta) dias do término da vigência.
              </Row>
            </Row>
          </Block>
          {/* Dados do Cliente */}
          <Block>
            <Row style={{ marginBottom: 8 }}>
              <b>DADOS DO PROPONENTE TITULAR</b>
            </Row>
            <Row>
              <Field span={10} title="Nome" value={cliente.clienteNome} />
              <Field span={8} title="CPF" value={cliente.clienteCpfCnpj} />
              <Field
                span={6}
                title="Data de Nascimento"
                value={Functions.formatarData(cliente.clienteDataNascimento)}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Endereço"
                value={`${endereco.clienteEndereco1}, ${endereco.clienteEnderecoNumero} - ${endereco.clienteEnderecoComplemento}`}
              />
              <Field
                span={8}
                title="Bairro"
                value={endereco.clienteEnderecoBairro}
              />
              <Field span={6} title="CEP" value={endereco.clienteEnderecoCep} />
            </Row>
            <Row>
              <Field
                span={10}
                title="Cidade"
                value={endereco.clienteEnderecoCidade}
              />
              <Field span={8} title="UF" value={endereco.clienteEnderecoUf} />
              <Field
                span={6}
                title="Telefone"
                value={cliente.clienteContatoFones[0]?.clienteContatoFoneNumero}
              />
            </Row>
            <Row>
              <Field span={10} title="Profissão" value={getProfissao()} />
              <Field
                span={14}
                title="Ramo de Atividade"
                value={cliente.clienteRamoAtividade}
              />
            </Row>
            <Row>
              <Field span={10} title="Cargo" value={getCargo()} />
              <Field
                span={14}
                title="Renda Mensal"
                value={Mask.maskMoney(cliente.clienteFaixaRendaManual ?? cliente.mtrClienteFaixaRendaValor)}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Sexo"
                value={getDescricaoDominio(
                  generos,
                  'sexoId',
                  'sexoDescricao',
                  cliente.sexoId
                )}
              />
              <Field
                span={8}
                title="Estado Civil"
                value={getDescricaoDominio(
                  estadosCivis,
                  'estadoCivilId',
                  'estadoCivilDescricao',
                  cliente.estadoCivilId
                )}
              />
              <Field
                span={6}
                title="Nacionalidade"
                value={getDescricaoDominio(
                  naturalidade,
                  'naturalidadeId',
                  'naturalidadeDescricao',
                  cliente.naturalidadeId
                )}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Nº da Identidade"
                value={cliente.clienteRg}
              />
              <Field
                span={8}
                title="Data de emissão"
                value={Functions.formatarData(cliente.clienteDataExpedicaoRg)}
              />
              <Field
                span={6}
                title="Orgão Emissor"
                value={cliente.clienteRgSsp}
              />
            </Row>
            <Row>
              <Field
                title="E-mail"
                value={
                  cliente.clienteContatoMails[0]?.clienteContatoMailDescricao
                }
              />
            </Row>
          </Block>
          {/* Opção de Plano */}
          <Block>
            <Row style={{ marginBottom: 8 }}>
              <b>OPÇÕES DE PLANO</b>
            </Row>
            <Row>
              <Field
                xs={{ span: 24 }}
                sm={{ span: 10 }}
                title="Tipo de Plano"
                value={
                  vppAmerican.tipoPlano === 1 ? 'Titular' : 'Titular + Cônjuge'
                }
              />
            </Row>
          </Block>
          {/* Dados do Cônjuge */}
          {vppAmerican.conjugeCpf && (
            <Block>
              <Row style={{ marginBottom: 8 }}>
                <b>DADOS DO CÔNJUGE</b>
              </Row>
              <Row>
                <Field
                  span={10}
                  title="Nome do Cônjuge"
                  value={vppAmerican.conjugeNome}
                />
                <Field
                  span={8}
                  title="Cpf do Cônjuge"
                  value={vppAmerican.conjugeCpf}
                />
                <Field
                  span={6}
                  title="Data de Nascimento"
                  value={Functions.formatarData(
                    vppAmerican.conjugeDataNascimento
                  )}
                />
              </Row>
              <Row>
                <b>OBS: </b>Se o cônjuge já faz parte do Grupo Segurado por esta
                apólice como Proponente Principal, não será possível a inclusão
                como cônjuge nesta proposta de seguro.
              </Row>
            </Block>
          )}
          {planoSelected && (
            <Block>
              <Row style={{ marginBottom: 8 }}>
                <b>CÓDIGO DO PLANO, COBERTURAS E PRÊMIOS</b>
              </Row>
              <Row>
                <Field
                  span={10}
                  title="Cód. Plano"
                  value={planoSelected.planoGarantiaVidaDescricao}
                />
                <Field
                  span={14}
                  title="Custo"
                  value={`${Functions.formatarMoeda(
                    planoSelected.mtrPremioTotal
                  )} (${vppAmerican.tipoPagamento === 1 ? 'Mensal' : 'Anual'})`}
                />
                <Row>
                  <b>CAPITAIS SEGURADOS DO TITULAR</b>
                </Row>
                <Field
                  span={10}
                  title="Morte"
                  value={Functions.formatarMoeda(
                    planoSelected.mtrGarantiaMorte
                  )}
                />
                <Field
                  span={14}
                  title="Morte Acidental (MA)"
                  value={Functions.formatarMoeda(
                    planoSelected.mtrGarantiaMorteAcidental
                  )}
                />
                <Field
                  span={10}
                  title="Invalidez permanente total ou parcial por acidente (IPA) - até"
                  value={Functions.formatarMoeda(planoSelected.mtrGarantiaIPA)}
                />
                <Field
                  span={8}
                  title="Morte - Assistência Funeral Padrão Luxo"
                  value={Functions.formatarMoeda(
                    planoSelected.mtrGarantiaAxilioDescessos
                  )}
                />
              </Row>
              <Row>
                <span>
                  <i>
                    Remuneração pela intermediação de 63,1% e Pró Labore de 0,5%
                    sobre o prêmio
                  </i>
                </span>
              </Row>
              <Row>
                <Field
                  span={24}
                  title="REENQUADRAMENTO AUTOMATICO DE PRÊMIOS"
                  value="O prêmio do seguro será
                  reenquadrado automaticamente, na renovação anual do Certificado do Seguro, sempre
                  que o segurado titular mudar de faixa etária de acordo com as Tabelas de Vendas
                  vigentes no momento da contratação do seguro."
                />
              </Row>
              <Row>
                <b>OBS:</b> O capital segurado e os prêmios serão reajustados
                anualmente pela variação do INPC apurada dois meses antes da
                renovação do seguro. - Em caso de Morte Acidental, as
                indenizações de Morte e Morte Acidental, se acumulam.
              </Row>
              <Row>
                A partir da primeira renovação dos seguros com pagamento ANUAL a
                emissâo será alterada para a forma de pagamento MENSAL.
              </Row>
              <Row>
                <b>GARANTIAS DO CÔNJUGE:</b> Quando contratada a cobertura para
                cônjuge, este participará com 50% (cinquenta por cento) do
                capital do Segurado Titular nas coberturas, Morte, Morte
                Acidental e Invalidez Permanente por Acidente (até)."
              </Row>
            </Block>
          )}
          <Block>
            <Row style={{ marginBottom: 8 }}>
              <b>PAGAMENTO DO PRÊMIO</b>
            </Row>
            <Row>
              <Field
                xs={{ span: 24 }}
                sm={{ span: 14 }}
                title="Dia para débito em Conta Corrente"
                value={vppAmerican.diaDebito}
              />
            </Row>
          </Block>
          <Block>
            <Row style={{ marginBottom: 8 }}>
              <b>BENEFICIÁRIOS</b>
            </Row>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Parentesco</th>
                  <th>Participação</th>
                </tr>
              </thead>
              <tbody>
                {beneficiarios.map(b => (
                  <tr key={b.nome}>
                    <td>{b.nome}</td>
                    <td>
                      {getDescricaoDominio(
                        grausRelacionamento,
                        'grauRelacionamentoId',
                        'grauRelacionamentoDescricao',
                        b.grauRelacionamento
                      )}
                    </td>
                    <td>{b.percentual}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Block>

          {/* <p style={{ pageBreakBefore: "always" }}>&nbsp;</p> */}

          {/* DPS / Atividades */}

          {perguntasProduto &&
            perguntasProduto.length &&
            vppAmerican.respostasDps &&
            !hasIsencaoDps && (
              <Block>
                <Row style={{ marginBottom: 8 }}>
                  <b>DECLARAÇÃO PESSOAL DE SAÚDE / ATIVIDADES</b>
                </Row>
                <Row style={{ padding: 10, paddingBottom: 0, marginBottom: 0 }}>
                  Responda as perguntas abaixo por extenso{' '}
                  <b>
                    <u>SIM</u>
                  </b>{' '}
                  ou{' '}
                  <b>
                    <u>NÃO</u>
                  </b>
                  . Qualquer resposta SIM deve ser complementada no espaço
                  existente após o questionário.
                </Row>
                <table
                  style={{
                    width: '100%',
                    borderCollapse: 'separate',
                    borderSpacing: 2,
                  }}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        style={{
                          padding: 10,
                          border: 'solid',
                          borderWidth: 0.5,
                          margin: 0.3,
                        }}
                      >
                        Titular
                      </th>
                      {vppAmerican.tipoPlano === 2 && (
                        <th
                          style={{
                            padding: 10,
                            border: 'solid',
                            borderWidth: 0.5,
                            margin: 0.3,
                          }}
                        >
                          Cônjuge
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {perguntasProduto.map((p, i) => (
                      <>
                        <tr key={p.perguntaProdutoId}>
                          <td
                            style={{
                              padding: 5,
                              border: 'solid',
                              borderWidth: 0.5,
                              textAlign: 'justify',
                            }}
                          >
                            <b>{i + 1})</b> {p.perguntaProdutoDescricao}
                            {(getMarcacaoDpsPergunta(p.perguntaProdutoId, 1) ==
                              'Sim' ||
                              getMarcacaoDpsPergunta(p.perguntaProdutoId, 2) ==
                                'Sim') && (
                              <>
                                <b>&nbsp;Justificativa: </b>
                                {getRespostaDpsPergunta(p.perguntaProdutoId)}
                              </>
                            )}
                          </td>
                          <td
                            style={{
                              padding: 5,
                              border: 'solid',
                              borderWidth: 0.5,
                              margin: 0.3,
                              textAlign: 'center',
                            }}
                          >
                            <b>
                              {getMarcacaoDpsPergunta(p.perguntaProdutoId, 1)}
                            </b>
                          </td>
                          {vppAmerican.tipoPlano === 2 && (
                            <td
                              style={{
                                padding: 5,
                                border: 'solid',
                                borderWidth: 0.5,
                                margin: 0.3,
                                textAlign: 'center',
                              }}
                            >
                              <b>
                                {getMarcacaoDpsPergunta(p.perguntaProdutoId, 2)}
                              </b>
                            </td>
                          )}
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td
                        style={{
                          padding: 5,
                          border: 'solid',
                          borderWidth: 0.5,
                        }}
                      >
                        <b>11)</b>{' '}
                        {`Altura (Titular): ${vppAmerican.titularAltura}m / Peso (Titular): ${vppAmerican.titularPeso}kg`}
                        <br />
                        {vppAmerican.conjugeCpf &&
                          `Altura (Cônjuge): ${vppAmerican.conjugeAltura}m / Peso (Cônjuge): ${vppAmerican.conjugePeso}kg`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Block>
            )}
          {/* Termo e Assinatura */}
          <Block>
            <b>DECLARAÇÃO E AUTORIZAÇÃO PARA DÉBITO EM CONTA CORRENTE</b>
            <Row
              style={{
                paddingBottom: 10,
                paddingTop: 10,
                textAlign: 'justify',
                textJustify: 'inter-word',
                fontSize: '13px',
              }}
            >
              Pela presente, autorizo a inclusão do meu nome no quadro social do
              AEBRB-Associação dos Empregados do Banco de Brasília, a quem
              concedo o direito de agir em meu nome no cumprimento ou alterações
              de todas as cláusulas das &quot;Condições Gerais e Especiais&quot;
              da apólice, e a quem devo encaminhar todas as comunicações ou
              avisos inerentes ao contrato, que, para tal fim, fica investido
              dos poderes de representação ressaltando-se, entretanto, que os
              poderes de representação ora outorgados não lhe dão o direito de
              cancelar o(s) seguro(s) aqui proposto(s) no decorrer de sua(s)
              vigência(s) e nem reduzir meu(s) capital(ais) segurado(s) sem o
              meu expresso consentimento, enquanto o pagamento do(s) prêmio(s)
              ocorrer(em) sob minha responsabilidade. Declaro que nada omiti em
              relação ao meu estado de saúde e do meu cônjuge, ao fornecer de
              próprio punho informações completas e verdadeiras, ciente de que
              quaisquer omissões de circunstâncias, informações ou falsidades
              nelas contidas, que possam influir na aceitação desta Proposta,
              tornará nula, nos termos do Código Civil Brasileiro. Concordo que
              as declarações que prestei passam a fazer parte integrante do
              contrato celebrado entre o AEBRB-Associação dos Empregados do
              Banco de Brasília com a American Life Companhia de Seguros,
              ficando ela autorizada a utilizá-las, em qualquer época, no amparo
              ou na defesa de seus direitos. Autorizo médicos, hospitais,
              clinicas ou quaisquer entidades públicas ou privadas a prestar à
              American Life Companhia de Seguros informações relacionadas ao meu
              estado de saúde ou a moléstias que eu possa sofrer ou ter sofrido,
              bem como resultados de exames e tratamentos instituídos,
              isentando-os desde já de qualquer responsabilidade que implique em
              ofensa ou sigilo profissional. Autorizo a American Life Companhia
              de Seguros a utilizar e verificar os dados cadastrais/pessoais, de
              maneira que julgar necessária. Por fim. declaro ainda, que recebi,
              conheço e concordo com as Condições Gerais do presente seguro, e
              que a American Life Companhia de Seguros disporá de 15 (quinze)
              dias para analisar esta Proposta, contado do seu protocolo em
              qualquer dos seus escritórios. A inexistência de manifestação
              expressa pela Seguradora, dentro deste prazo implicará na
              aceitação automática desta proposta. Sendo aceita esta proposta,
              autorizo o AEBRB-Associação dos Empregados do Banco de Brasília e
              ao BRB - Banco de Brasília S.A. a debitar o(s) prêmio(s)
              correspondente(s) ao seguro ora proposto no Banco <b>070</b>,
              Agência{' '}
              <b>
                {getDescricaoDominio(
                  agenciasBrb,
                  'agenciaId',
                  'agenciaCodigo',
                  vppAmerican.agenciaDebitoId
                )}
              </b>
              , Conta Corrente nº <b>{vppAmerican.contaDebitoNumero}</b>, na
              data acima assinalada, ciente de que, não existindo saldo
              disponível na data do débito, ele não será efetuado, ocasionando a
              interrupção das coberturas do seguro até a regularização do
              pagamento, nos termos das Condições Gerais e Especiais da Apólice.
              Em caso de recusa, o prêmio pago será devolvido, monetariamente
              atualizado. Este seguro é por prazo determinado tendo a Seguradora
              a faculdade de não renovar a apólice na data de vencimento, sem
              devolução dos premios pagos da apólice. A aceitação do seguro
              estará sujeita a análise do risco. O registro deste plano na SUSEP
              não implica, por parte da Autarquia, incentivo ou recomendação a
              sua comercialização. O segurado poderá consultar a situação
              cadastral de seu corretor de seguros, no site www.susep.gov.br,
              por meio do número de seu registro na SUSEP, nome completo, CNPJ
              ou CPF. As condições contratuais deste produto protocoladas pela
              American Life junto à Susep poderão ser consultadas no endereço
              eletrônico www.susep.gov.br, de acordo com o número de processos
              constante da apólice/proposta. Telefone de atendimento ao público
              SUSEP: 0800-021-8484
            </Row>
            <Row style={{ marginTop: 60 }}>
              <Field
                span={14}
                title="Local e data"
                value={`Brasília, ${
                  vppAmerican.propostaEletronicaDataEfetivacao
                    ? moment
                        .utc(vppAmerican.propostaEletronicaDataEfetivacao)
                        .format('DD/MM/YYYY')
                    : Functions.getDateNow()
                }`}
              />
              <Col span={6} style={{ textAlign: 'center' }}>
                ______________________________________________
                <br />
                <b>Assinatura do Proponente</b>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 8,
                textAlign: 'justify',
                textJustify: 'inter-word',
              }}
            >
              O SEGURO BRB VIDA PREMIADO PLUS É GARANTIDO 100% (CEM POR CENTO)
              PELA AMERICAN LIFE COMPANHIA DE SEGUROS. Avenida Angélica nº 2.626
              Consolaçao, São Paulo/ SP CNPJ -67.865.360/0001-27, CÓDIGO SUSEP:
              0581-9 PROCESSO SUSEP 10.003894/99-11, 15414.003320/2007-10,
              15414.901246/2013-39
            </Row>
            <Row style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
              SAC: 0800-755-5985 SAC Deficiência Auditiva ou de Fala:
              0800-770-9797 Ouvidoria: 0800-770-1102
            </Row>
            <Row style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
              CORRETOR: BRB ADMINISTRADORA E CORRETORA DE SEGUROS S/A - CÓDIGO
              SUSEP Nº 058907.1.006614-1
            </Row>
          </Block>

          {/* <p style={{ pageBreakBefore: "always" }}>&nbsp;</p> */}

          {/* Dados Produção */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DA PRODUÇÃO</b>
            </Row>
            <Row>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Mat. Consultor</th>
                    <th>Mat. Angariador</th>
                    <th>Agência Produtora</th>
                    <th>Equipe</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {getDescricaoDominio(
                        consultores,
                        'produtorId',
                        'produtorCodigo',
                        producao.consultorId
                      )}
                    </td>
                    <td>
                      {getDescricaoDominio(
                        angariadores,
                        'produtorId',
                        'produtorCodigo',
                        producao.angariadorId
                      )}
                    </td>
                    <td>
                      {`${getDescricaoDominio(
                        agenciasBrb,
                        'agenciaId',
                        'agenciaCodigo',
                        producao.agenciaId
                      )} - ${getDescricaoDominio(
                        agenciasBrb,
                        'agenciaId',
                        'agenciaDescricao',
                        producao.agenciaId
                      )}`}
                    </td>
                    <td>
                      {getDescricaoDominio(
                        equipesProdutor,
                        'equipeId',
                        'equipeDescricao',
                        producao.equipeId
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Block>
        </Container>
      )}
    </>
  );
}
