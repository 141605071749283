import { Button, Col, Icon, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container, Disable, Input, Select,
  TitleSection
} from '~/components';
import * as LibertyResidencialPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';
import * as PropostaActions from '~/store/ducks/proposta';
import ContatosInspecao from '../contato-inspecao/index.js';

type EfetivacaoResidencialPersonalizavelLibertyProps = {
  onReset: Function,
  onPrevius: Function,
  onNext: Function
};

export default function EfetivacaoResidencialPersonalizavelLiberty({
  onReset,
  onPrevius,
  onNext
}: EfetivacaoResidencialPersonalizavelLibertyProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    contratoId,
  } = useSelector(state => state.proposta);

  const { libertyResidencialPersonalizavel, cliente, libertyResidencialPersonalizavel: {
    cotacao, efetivacao
  }, dominio: { bancos, agenciasBrb, agencias }, proposta: { contasDebito } } = useSelector(state => state);

  // Component state
  const [contaSelecionada, setContaSelecionada] = useState();
  const [habilitarInserirContaManualmente, setHabilitarInserirContaManualmente] = useState(false);

  useEffect(() => {
    dispatch(PropostaActions.getContas({ param: cliente?.cliente?.clienteCpfCnpj }));
    changeDiaPreferenciaPagamento(efetivacao.diaPreferenciaPagamento);
  }, []);

  useEffect(() => {
    definirNomeCpfTitularBancoNaConta();
    setHabilitarInserirContaManualmente(!(!!(contasDebito?.length)));

  }, [contasDebito])

  useEffect(() => {
    var formaSelecionada = retornaFormaPagamentoSelecionada();

    if(formaSelecionada?.formaPagamentoTipoCodigo !== 'DC')
      limparContaDebito();
    else
      definirNomeCpfTitularBancoNaConta();

  }, [efetivacao?.escolhaFormaPagamentoId])

  function definirNomeCpfTitularBancoNaConta() {
    var obj = {
      ...efetivacao,
      clienteContaId: null,
      contaDebito: {
        ...efetivacao.contaDebito,
        codigoBanco: '0070',
        nomeTitularConta: cliente.cliente.clienteNome,
        cpfCnpjTitularConta: cliente.cliente.clienteCpfCnpj,
      }
    }
    
    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: obj}));
  }

  function retornaFormaPagamentoSelecionada() {
    var formasPagamentos = libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento;

    var formaSelecionada = (formasPagamentos ?? [])
      .find(x => x.formasPagamento.some(y => y.formaPagamentoEscolhaId === efetivacao?.escolhaFormaPagamentoId));

    return formaSelecionada;
  }

  function changeBeneficiario(beneficiario, index){
    var listaBeneficiarios = [...efetivacao.beneficiarios]
    listaBeneficiarios.splice(index, 1, beneficiario);
    var objEfetivacao = {
      ...efetivacao,
      beneficiarios: [...listaBeneficiarios]
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: objEfetivacao}));
  }

  function deleteBeneficiario(index) {
    var listaBeneficiarios = [...efetivacao.beneficiarios]
    listaBeneficiarios.splice(index, 1);
    var objEfetivacao = {
      ...efetivacao,
      beneficiarios: [...listaBeneficiarios]
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: objEfetivacao}));
  }

  function newBeneficiario() {
    var listaBeneficiarios = [...efetivacao.beneficiarios]
    listaBeneficiarios.unshift('');
    var objEfetivacao = {
      ...efetivacao,
      beneficiarios: [...listaBeneficiarios]
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: objEfetivacao}));
  }

  function changeDiaPreferenciaPagamento(valor) {
    
    // Remove tudo o que não é dígito
    valor = !valor ? '' : valor.replace(/\D/g, ''); 

    valor = valor.length > 2 ? valor.substring(0, 2) : valor;

    var valorNumero = Number(valor);
    valorNumero = valorNumero > 31 ? 31 : valorNumero

    var objEfetivacao = {
      ...efetivacao,
      diaPreferenciaPagamento: `${valorNumero == 0 ? ('') : valorNumero}`
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: objEfetivacao}));
  }

  function limparContaDebito() {
    setContaSelecionada(null);
    changeDiaPreferenciaPagamento('')

    dispatch(LibertyResidencialPersonalizavelActions.changeContaDebito({}));
  }

  function changeAgencia(idAgencia) {
    var agenciaSelecionada = agencias.find(x => x.agenciaId === idAgencia) ?? {}

    var obj = {
      ...efetivacao,
      clienteContaId: null,
      contaDebito: {
        ...efetivacao.contaDebito,
        codigoBanco: '0070',
        numeroAgencia: agenciaSelecionada?.agenciaCodigo,
        agenciaId: agenciaSelecionada?.agenciaId
      }
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: obj}));
  }

  function changeContaDebito(value) {
    var listaContas = (contasDebito ?? [])
    var conta = listaContas.find(x => x.id === value);
    
    var obj = {
      ...efetivacao,
      clienteContaId: null,
      contaDebito: {
        ...efetivacao.contaDebito,
        codigoBanco: '0070',
        numeroAgencia: conta?.agenciaCodigo,
        numeroContaCorrente: conta?.contaNumero,
        nomeTitularConta: cliente.cliente.clienteNome,
        cpfCnpjTitularConta: cliente.cliente.clienteCpfCnpj,
        agenciaId: conta?.agenciaId
      }
    }

    setContaSelecionada(value)
    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: obj}));
  }

  function changeFieldContaDebito(field, value) {
    var obj = {
      ...efetivacao,
      contaDebito: {
        ...efetivacao.contaDebito,
        [field]: value
      }
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: obj}));
  }

  function exibirBeneficiarios() { return !(cotacao?.localRisco?.seguradoEhProprietarioDoImovel) };

  // como no seguridade apenas uma residência é enviada, caso exista algum registro na lista de inspenção, 
  // significa que deve informar a lista de contatos para inspeção
  function exibirContatosParaInspecao() { return !!(libertyResidencialPersonalizavel?.propostaDetalhes?.listaIndexResidenciasParaInspecao ?? []).length };

  function exibirBodyEfetivar() {
    return !!exibirBeneficiarios() || !!exibirContatosParaInspecao();
  }

  return (
    <>
      <Spin spinning={!!(libertyResidencialPersonalizavel.loading ?? []).length} size="small">

        <Container>

          <Disable disable={!!contratoId}>
          {
            
            exibirContatosParaInspecao() && (
              <>
                <ContatosInspecao></ContatosInspecao>
              </>
            )
            
          }

          {
            <>
              <TitleSection title="Conta para débito" />

              <Row gutter={[16, 16]}>
                {
                  !contratoId && !habilitarInserirContaManualmente &&
                  <>
                    <Col xs={{ span: 24 }} sm={{ span: 8 }} style={{ paddingTop: '15px' }}>
                      <div className="form-group">
                        <label>
                            Contas do cliente
                        </label>
                        <Select
                            defaultValue="Selecione"
                            itens={(contasDebito ?? [])}
                            itemValue="id"
                            renderText={conta =>
                                `${conta?.agenciaCodigo} - ${conta?.contaNumero}`
                            }
                            value={contaSelecionada}
                            onChange={value => changeContaDebito(value)}
                            loading={false}
                        />
                      </div>
                    </Col>
                  </>
                }
                <Col xs={{ span: 24 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                  <div className="form-group">
                    <label className="w-100">Banco</label>
                    <Select
                        defaultValue="Selecione"
                        itens={bancos?.filter(b => b.bancoId === '0070')}
                        itemValue="bancoId"
                        itemText="bancoDescricao"
                        value={efetivacao.contaDebito.codigoBanco}
                        onChange={value => changeFieldContaDebito('codigoBanco', value)}
                        disabled
                    />
                  </div>
                </Col>
              </Row>

              <Row>

                <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                    <div className="form-group">
                        <label>Agência</label>
                        <Select
                            defaultValue="Selecione"
                            itens={agenciasBrb}
                            itemValue="agenciaId"
                            value={efetivacao.contaDebito.numeroAgencia}
                            itemText="agenciaCodigo"
                            itemTextSearch="agenciaDescricao"
                            renderText={ag =>
                                `${ag.agenciaCodigo} - ${ag.agenciaDescricao}`
                            }
                            onChange={value => changeAgencia(value)}
                            disabled={!habilitarInserirContaManualmente}
                            showSearch
                        />
                    </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                    <div className="form-group">
                        <label>Conta</label>
                        <Input
                            placeholder="Conta Corrente"
                            value={efetivacao.contaDebito.numeroContaCorrente}
                            onChange={value => changeFieldContaDebito('numeroContaCorrente', value)}
                            disabled={!habilitarInserirContaManualmente}
                            maxLength={7}
                            accept="onlyNumbers"
                        />
                    </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingTop: '15px' }}>
                  <div className="form-group">
                    <label>Dia de preferência do pagamento</label>
                    <Input
                      placeholder="Dia de preferência"
                      maxLength={31}
                      minLength={1}
                      value={efetivacao.diaPreferenciaPagamento}
                      onChange={changeDiaPreferenciaPagamento}
                    />
                  </div>
                </Col>
              </Row>
            </>
          }
          
          {
            exibirBeneficiarios() && (
              <>
                <TitleSection title="Beneficiários" />

                  {
                    (!efetivacao?.beneficiarios || !efetivacao?.beneficiarios.length || efetivacao.beneficiarios.length < 3) &&
                    <Row>
                      <Col>
                        <Button
                          className="btn btn-xs-block"
                          type="primary"
                          onClick={newBeneficiario}
                          style={{ marginRight: 20, float: "right" }}
                        >
                          <Icon type="plus" />
                          Incluir Beneficiário
                        </Button>
                      </Col>
                    </Row>
                  }

                  {
                    (efetivacao.beneficiarios ?? []).map((beneficiario, index) => {
                      return (
                        <Row gutter={[16, 16]} key={index.toString()}>
                          <Col xs={{ span: 22 }} sm={{ span: 18 }}>
                            <Input
                                placeholder="Nome do Beneficiário(a)"
                                value={ beneficiario }
                                onChange={value =>
                                  changeBeneficiario(value, index)
                                }
                            />
                          </Col>
                          <Col xs={{ span: 2 }} sm={{ span: 2 }}>
                            <Button
                                className="btn  btn-xs-block mt-2 mt-sm-0"
                                type="danger"
                                onClick={() => deleteBeneficiario(index)}

                              >
                                <Icon type="delete" />
                            </Button>
                          </Col>
                        </Row>
                      );
                    })
                  }
              </>
            )
          }          
          </Disable>
        </Container>
      </Spin>
    </>
  );
}
