import * as yup from 'yup';

const FormaPagamentoDefault = yup.object().shape({
  agencia: yup.string().when('isBoleto', {
    is: false,
    then: yup
      .string()
      .required('Por favor, informe a agência.')
      .nullable(),
  }),
  // debitoContaNumeroConta: ,
  debitoContaNumeroConta: yup.string().when('isBoleto', {
    is: false,
    then: yup
      .string()
      .required('Por favor, informe o número da conta')
      .nullable(),
  }),
});

const AVista = FormaPagamentoDefault.concat(
  yup.object().shape({
    propostaEletronicaNossoNumero: yup
      .string()
      .nullable()
      .when('isBoleto', {
        is: true,
        then: yup
          .string()
          .test('len', 'Nosso número inválido!', function(val) {
            // SE O CAMPO TIVER 12 DÍGITOS, VALIDA SE O BOLETO É DO BRB
            if (val?.toString().length === 12)
              return val?.toString().substring(7, 10) === '070';
            // VALIDA SE O CAMPO TEM 8 OU MAIS CARACTERES
            return val?.toString().length >= 8;
          })
          .required('Por favor, informe o nosso número.')
          .nullable()
          .typeError('Por favor, informe o nosso número.'),
      }),
  })
);

const ParceladoSEntrada = FormaPagamentoDefault.concat(
  yup.object().shape({
    contratoDREQtdParcela: yup
      .string()
      .required('Por favor, informe a quantidade de parcelas.')
      .nullable(),
    contratoDREDiaVencimentoDemais: yup
      .string()
      .required('Por favor, informe o melhor dia para débito.')
      .nullable(),
  })
);

const ParceladoCEntrada = FormaPagamentoDefault.concat(
  AVista.concat(ParceladoSEntrada)
);

export default {
  AVista,
  ParceladoSEntrada,
  ParceladoCEntrada,
};
