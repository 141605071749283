import React from 'react';

import { AutoComplete as AC } from 'antd';

type AutoCompleteProps = {
  value: String,
  placeholder: String,
  onChange: Function,
  onSelect: Function,
  itens: Array<Object>,
  itemText: String,
  itemValue: String,
};

export default function AutoComplete({
  value,
  placeholder,
  onChange,
  onSelect,
  itens,
  itemText,
  itemValue,
}: AutoCompleteProps) {
  return (
    <AC
      placeholder={placeholder}
      value={value}
      style={{ width: '100%' }}
      onChange={onChange}
      onSelect={onSelect}
    >
      {itens &&
        itens.map(item => (
          <AC.Option
            key={item[itemText]}
            value={itemValue ? item[itemValue] : JSON.stringify(item)}
          >
            {item[itemText]}
          </AC.Option>
        ))}
    </AC>
  );
}
