import { Column, Line, Pie } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/charts/es/column';
import { LineConfig } from '@ant-design/charts/es/line';
import { PieConfig } from '@ant-design/charts/es/pie';
import { Button, Col, DatePicker as DatePickerAntd, Form, Layout, Row, Spin } from 'antd';
import 'antd/dist/antd.css';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Constants, Functions } from 'utils';
import { CardProgress, Container, ContentHeader, Select } from '~/components';
import * as DashboardActions from '~/store/ducks/dashboard';
import constants from '~/utils/constants';
import configuracaoGraficoBarras from './configuracoes-graficos/configuracao-grafico-barras';
import configuracaoGraficoLinha from './configuracoes-graficos/configuracao-grafico-linha.js';
import configGraficoPizza from './configuracoes-graficos/configuracao-grafico-pizza.js';
import BlocoSeguridade from './partials/bloco-seguridade';
import BlocoRealizadoCarteriaOperador from './partials/realizado-carteira-operador.js';
import './styles.css';

const { MonthPicker } = DatePickerAntd;

export default function Dashboard() {
  const dispatch = useDispatch();
  const { dashboard,
    auth: { usuario }, auth: { usuario: { listaUsuarioAgenciaProdutor } },
    dashboard: { orcadoAgenciaPeriodo, realizadoAgenciaPeriodo, orcadoOperadorPeriodo, realizadoOperadorPeriodo }
  } = useSelector((state: RootStateOrAny) => state);
  const [dadosMetaProdutor, setDadosMetaProdutor] = useState({});
  const [dadosMetaAgenciaOperador, setDadosMetaAgenciaOperador] = useState({});
  const [dataGraficoPie, setDataGraficoPie] = useState([]);
  const [dataGraficoLinha, setDataGraficoLinha] = useState([]);
  const [dataGraficoBarra, setDataGraficoBarra] = useState([]);
  const [exibirDadosAgenciaOperadorDetalhadoPorProduto, setExibirDadosAgenciaOperadorDetalhadoPorProduto] = useState(false);
  const [usuarioPossuiVariasAgencias, setUsuarioPossuiVariasAgencias] = useState(false);
  const [agenciaSelecionada, setAgenciaSelecionada] = useState();
  const [usuarioEhConsultor, setUsuarioEhConsultor] = useState(usuario.descTipoProdutor === 'CONSULTOR');
  const [exibirMetaIdealDia, setExibirMetaIdealDia] = useState(false);
  const [metaIdealDia, setMetaIdealDia] = useState(0);
  const [dataFiltro, setDataFiltro] = useState(moment());
  const [listaBlocoSeguridades, setListaBlocoSeguridades] = useState([]);
  const [listaRealizadoCarteiraOperador, setListaRealizadoCarteiraOperador] = useState([]);
  const [semestreAnoAgencia, setSemestreAnoAgencia] = useState(JSON.stringify(
    {
      ano: moment().year(), semestre: moment().month() > 5 ? 2 : 1,
      codigoAgencia: retornaStringCodigosAgencias(),
    })
  );

  const [filtroOrcado, setFiltroOrcado] = useState({
    codigoAgencia: retornaStringCodigosAgencias(),
    dataFiltro: moment().format('YYYY-MM-DD'),
    produtorId: usuario.produtorId
  });

  const [filtroRealizado, setFiltroRealizado] = useState({
    agenciaId: retornaStringIdsAgencias(),
    dataFiltro: moment().format('YYYY-MM-DD'),
    produtorId: usuario.produtorId
  });

  const {
    tiposProdutosConsultorAgencia,
    tiposProdutos: configCards
  } = Constants.Dashboard;

  const constantes = constants.Dashboard;
  const encarteiramentos = constantes.encarteiramentos;
  //#region Configuração gráficos
  let configGraficoLinha: LineConfig = configuracaoGraficoLinha.retornaConfig(dataGraficoLinha);

  let configPie: PieConfig = configGraficoPizza
    .retornaConfig(dataGraficoPie, retornaMetaTotalAgenciaOperador('realizado'),
      retornaValorFaltanteParaGrafico())

  let configGraficoBarra: ColumnConfig = configuracaoGraficoBarras.retornaConfig(dataGraficoBarra);
  //#endregion

  //#region  EFEITOS
  useEffect(() => {
    if (usuario && usuario.exibirDashboardPpe) {
      dispatch(DashboardActions.setStateDefault())
      setAgenciaSelecionada((listaUsuarioAgenciaProdutor || []).map(x => x.agenciaId))
      setUsuarioPossuiVariasAgencias((listaUsuarioAgenciaProdutor || []).length > 1)
    }
  }, []);

  useEffect(() => {
    if (usuario && usuario.exibirDashboardPpe) {

      setSemestreAnoAgencia(JSON.stringify({
        ano: moment(filtroOrcado.dataFiltro).year(),
        semestre: moment(filtroOrcado.dataFiltro).month() > 5 ? 2 : 1,
        codigoAgencia: filtroOrcado.codigoAgencia
      }));
      
      filtrarOrcadosRealizadosMes();
    }
  }, [filtroOrcado]);

  useEffect(() => {
    
    filtrarOrcadosRealizadosMes();
  }, [orcadoAgenciaPeriodo, realizadoAgenciaPeriodo, orcadoOperadorPeriodo, realizadoOperadorPeriodo]);

  useEffect(() => {
    const dadosBusca = JSON.parse(semestreAnoAgencia);
    const dataInicio = moment(`${dadosBusca.ano}-${(dadosBusca.semestre === 1 ? '01' : '07')}-01`);
    const dataFim = moment(`${dadosBusca.ano}-${(dadosBusca.semestre === 1 ? '06' : '12')}-${(dadosBusca.semestre === 1 ? '30' : '31')}`);
    const realizadoFiltro = {
      dataInicio: dataInicio.format('YYYY-MM-DD'),
      dataFim: dataFim.format('YYYY-MM-DD'),
      agenciaId: filtroRealizado.agenciaId,
      produtorId: usuario.produtorId
    };

    const orcadoFiltro = {
      dataInicio: dataInicio.format('YYYY-MM-DD'),
      dataFim: dataFim.format('YYYY-MM-DD'),
      codigoAgencia: filtroOrcado.codigoAgencia,
      produtorId: usuario.produtorId
    };

    switch (usuario.descTipoProdutor) {
      case 'CONSULTOR':
        {
          dispatch(DashboardActions.getRealizadoAgenciaPeriodo(realizadoFiltro))
          dispatch(DashboardActions.getOrcadoAgenciaPeriodo(orcadoFiltro))
          return;
        }
      case 'OPERADOR':
        {
          dispatch(DashboardActions.getRealizadoOperadorPeriodo(realizadoFiltro))
          dispatch(DashboardActions.getOrcadoOperadorPeriodo(orcadoFiltro))
        }
    }
  }, [semestreAnoAgencia]);

  useEffect(() => {
    if (usuario && usuario.exibirDashboardPpe) {
      tratarDadosApresentarDash();
      metaIdealParaDiaAtual();
    }
  }, [dashboard]);

  useEffect(() => {
    
    if (!dadosMetaAgenciaOperador || !Object.keys(dadosMetaAgenciaOperador).length) return;

    setDataGraficoPie(
      [
        {
          descricao: 'Realizado',
          valor: retornaMetaTotalAgenciaOperador('realizado'),
          cor: '#1890ff'
        },
        {
          descricao: 'GAP',
          valor: retornaValorFaltanteParaGrafico(),
          cor: '#ec2540'
        },
      ]
    )

    if (usuarioEhConsultor) tratarDadosBlocoSeguridade();
    else tratarDadosRealizadoCarteiraProdutor()
  }, [dadosMetaAgenciaOperador]);

  useEffect(() => {
    
    const meses = retornaMesesSemestre();
    let data = [];

    meses.forEach(x => {
      let obj = { mes: x.descricao, valor: 0 };
      const filtroMes = y => moment(y.dataReferencia).month() + 1 === x.id
      let valorOrcado = calcularOrcadoTotal((orcadoAgenciaPeriodo || []).filter(filtroMes)).valor;
      let valorRealizado = calcularRealizadoTotal((realizadoAgenciaPeriodo || []).filter(filtroMes)).valor;
      const mesPorVir = retornaEhMesPorVir(x.id);

      obj.valor = mesPorVir ? null : (valorRealizado / valorOrcado) * 100;
      data.push(obj);
    })

    setDataGraficoLinha(data);
  }, [orcadoAgenciaPeriodo, realizadoAgenciaPeriodo]);

  useEffect(() => {
    
    const meses = retornaMesesSemestre();
    let data = [];

    meses.forEach(x => {
      const filtroMes = y  => moment(y.dataReferencia).month() + 1 === x.id
      const listaOrcado = (orcadoOperadorPeriodo || []).filter(filtroMes);
      const listaRealizado = (realizadoOperadorPeriodo || []).filter(filtroMes);
      data = data.concat(retornaListaGrupoGraficoBarra(x, x.descricao, listaOrcado, listaRealizado));      
    });

    data = data
      .concat(retornaListaGrupoGraficoBarra(0, 'Total Geral', orcadoOperadorPeriodo, realizadoOperadorPeriodo, true));

    setDataGraficoBarra(data);
  }, [orcadoOperadorPeriodo, realizadoOperadorPeriodo]);
  //#endregion

  function filtrarOrcadosRealizadosMes(){
    switch (usuario.descTipoProdutor) {
      case 'CONSULTOR':
        {
          filtrarOrcadosRealizadosMesAgencia();
          return;
        }
      case 'OPERADOR':{
        filtrarOrcadosRealizadosMesOperador();
      }
    }
  }

  function metaIdealParaDiaAtual() {
    const dataAtual = moment();
    const dataSelecionada = moment(filtroOrcado.dataFiltro)

    if(dataAtual.year() !== dataSelecionada.year() || dataAtual.month() !== dataSelecionada.month()){
      setExibirMetaIdealDia(false);
      return;
    }

    const orcado = retornaMetaTotalAgenciaOperador('orcado');

    if(!orcado){
      setExibirMetaIdealDia(false);
      return;
    }

    setMetaIdealDia((orcado / dataAtual.daysInMonth()) * dataAtual.get('D'));
    setExibirMetaIdealDia(true);
  }

  function filtrarOrcadosRealizadosMesAgencia() {
    const filtroMes = x => moment(x.dataReferencia).month() === moment(filtroOrcado.dataFiltro).month();
    dispatch(DashboardActions.saveRealizadoAgenciaMes((realizadoAgenciaPeriodo || []).filter(filtroMes)))
    dispatch(DashboardActions.saveOrcadoAgenciaMes((orcadoAgenciaPeriodo || []).filter(filtroMes)));
  }

  function filtrarOrcadosRealizadosMesOperador() {
    const filtroMes = x => moment(x.dataReferencia).month() === moment(filtroOrcado.dataFiltro).month();
    dispatch(DashboardActions.saveRealizadoOperadorMes((realizadoOperadorPeriodo || []).filter(filtroMes)))
    dispatch(DashboardActions.saveOrcadoOperadorMes((orcadoOperadorPeriodo || []).filter(filtroMes)));
  }

  function retornaListaGrupoGraficoBarra(mes: number, descricaoGrupo: string, listaCalculoOrcado: Array<any>, 
    listaCalculoRealizado: Array<any>, ignorarMesPorVir = false) {
    let obj = { mes: descricaoGrupo, valor: null, nome: 'Orçado' };
    let obj2 = { mes: descricaoGrupo, valor: null, nome: 'Realizado' };

    const mesPorVir = ignorarMesPorVir ? false : retornaEhMesPorVir(mes);

    if (!mesPorVir) {
      obj.valor = calcularOrcadoTotal(listaCalculoOrcado).valor;
      obj2.valor = calcularRealizadoTotal(listaCalculoRealizado).valor;
    }

    return [{ ...obj }, { ...obj2 }];
  }

  function retornaMesesSemestre() {
    const semestre = moment(filtroOrcado.dataFiltro).month() > 5 ? 2 : 1;

    return semestre === 1 
      ? constants.Meses.filter(x => x.id < 7) : constants.Meses.filter(x => x.id > 6);
  }

  function retornaEhMesPorVir(mes){
    return moment(filtroOrcado.dataFiltro).year() > moment().year()
    || (moment(filtroOrcado.dataFiltro).year() === moment().year() && mes > (moment().month() + 1))
  }

  function retornaCodigoPrimeiraAgencia() {
    return usuario && usuario.exibirDashboardPpe && usuarioEhConsultor
      ? (listaUsuarioAgenciaProdutor[0] || { agenciaCodigo: null }).agenciaCodigo
      : null;
  }

  function retornaStringCodigosAgencias() {
    return usuario && usuario.exibirDashboardPpe && usuarioEhConsultor
      ? listaUsuarioAgenciaProdutor.map(x => x.agenciaCodigo).join(',')
      : null;
  }

  function retornaStringIdsAgencias() {
    return usuario && usuario.exibirDashboardPpe && usuarioEhConsultor
      ? listaUsuarioAgenciaProdutor.map(x => x.agenciaId).join(',')
      : null;
  }

  function retornaIdPrimeiraAgencia() {
    return usuario && usuario.exibirDashboardPpe && usuarioEhConsultor
      ? (listaUsuarioAgenciaProdutor[0] || { agenciaId: null }).agenciaId
      : null;
  }

  function tratarDadosRealizadoCarteiraProdutor() {
    const filtroMes = x => moment(x.dataReferencia).month() === moment(filtroOrcado.dataFiltro).month();
    const orcadosMes = (orcadoOperadorPeriodo || []).filter(filtroMes);
    const realizadosMes = (realizadoOperadorPeriodo || []).filter(filtroMes);

    let lista = [];
    let objLinhaOrcado = { descLinha: 'Orçado' }
    let objLinhaRealizado = { descLinha: 'Realizado' }
    let objLinhaRealizadoPorcentagem = { descLinha: 'Realizado %' }

    constantes.tiposProdutos.sort((x, y) => x.desc - y.desc).forEach(x => {
      const valorOrcado = calcularOrcadoTotal(orcadosMes.filter(y => y.ramoId === x.id)).valor;
      const valorRealizado = calcularRealizadoTotal(realizadosMes.filter(y => y.ramoId === x.id)).valor;
      if (valorRealizado === 0 && valorOrcado === 0) return;
      objLinhaRealizado[x.desc] = valorRealizado;
      objLinhaOrcado[x.desc] = valorOrcado;
      objLinhaRealizadoPorcentagem[x.desc] = (valorRealizado / valorOrcado) * 100;
    });

    lista = [{ ...objLinhaOrcado }, { ...objLinhaRealizado }, { ...objLinhaRealizadoPorcentagem }];
    setListaRealizadoCarteiraOperador(lista);
  }

  function tratarDadosBlocoSeguridade() {
    const filtroMes = x => moment(x.dataReferencia).month() === moment(filtroOrcado.dataFiltro).month();
    const orcadosMes = (dashboard.orcadoAgenciaPeriodo || []).filter(filtroMes);
    const realizadosMes = (dashboard.realizadoAgenciaPeriodo || []).filter(filtroMes);
    
    const ehConfiguracaoAntiga = moment(filtroOrcado.dataFiltro).year() < 2021 
      || (moment(filtroOrcado.dataFiltro).year() === 2021 && moment(filtroOrcado.dataFiltro).month() < 6);

    const listaBloco = encarteiramentos.find(x => x.id === (ehConfiguracaoAntiga ? 1 : 2)).grupos;

    listaBloco.forEach(x => {
      const orcado = calcularOrcadoTotal(orcadosMes.filter(y => x.idsGrupo.some(z=> z === y.ramoId))).valor;
      const realizado = calcularRealizadoTotal(realizadosMes.filter(y => x.idsGrupo.some(z=> z === y.ramoId))).valor;
      const porcentagemRealizada = ((realizado || 0) / (orcado || 0)) * 100

      x['orcado'] = orcado;
      x['realizado'] = realizado;
      x['porcentagemRealizada'] = porcentagemRealizada;
    });

    const totalOrcado = calcularOrcadoTotal(orcadosMes).valor;
    const totalRealizado = calcularRealizadoTotal(realizadosMes).valor;

    listaBloco.push({
      desc: 'Total geral',
      idsGrupo: [],
      orcado: totalOrcado,
      realizado: totalRealizado,
      porcentagemRealizada: ((totalRealizado || 0) / (totalOrcado || 0)) * 100
    });

    setListaBlocoSeguridades(listaBloco);
  }

  function tratarDadosApresentarDash() {

    if (usuarioEhConsultor) {
      const filtroPorProdutor = x => x.produtorId === listaUsuarioAgenciaProdutor[0].produtorId;

      const orcadoConsultor = calcularOrcado(dashboard.orcadoAgenciaMes.filter(filtroPorProdutor));
      const realizadoConsultor = calcularRealizado(dashboard.realizadoAgenciaMes.filter(filtroPorProdutor));
      setDadosMetaProdutor({ orcado: orcadoConsultor, realizado: realizadoConsultor });
    }

    const orcadoAgenciaOperador = calcularOrcado(dashboard[usuarioEhConsultor ? 'orcadoAgenciaMes' : 'orcadoOperadorMes']);
    const realizadoAgenciaOperador = calcularRealizado(dashboard[usuarioEhConsultor ? 'realizadoAgenciaMes' : 'realizadoOperadorMes']);

    setDadosMetaAgenciaOperador({ orcado: orcadoAgenciaOperador, realizado: realizadoAgenciaOperador });
  }

  function calcularOrcado(metaOrcada): any {
    let orcado = {};

    (metaOrcada || []).forEach(x => {
      orcado[x.ramoId] = orcado[x.ramoId] || { quantidade: 0, valor: 0 }
      orcado[x.ramoId].quantidade += (x.quantidadeItensOrcados || 0);
      orcado[x.ramoId].valor += x.valorMonetarioOrcado;
    });

    return orcado;
  }

  function calcularOrcadoTotal(metaOrcada: Array<any>): any {
    let orcado = { quantidade: 0, valor: 0 };

    (metaOrcada || []).forEach(x => {
      orcado['quantidade'] += (x.quantidadeItensOrcados || 0);
      orcado['valor'] += (x.valorMonetarioOrcado || 0);
    });

    return orcado;
  }

  function calcularRealizadoTotal(metaOrcada: Array<any>): any {
    let orcado = { quantidade: 0, valor: 0 };

    (metaOrcada || []).forEach(x => {
      orcado['quantidade'] += (x.quantidadeRealizado || 0);
      orcado['valor'] += (x.valorRealizado || 0);
    });

    return orcado;
  }

  function calcularRealizado(metaRealizada) {
    let realizado = {};

    (metaRealizada || []).forEach(x => {
      realizado[x.ramoId] = realizado[x.ramoId] || { quantidade: 0, valor: 0 }
      realizado[x.ramoId].quantidade += x.quantidadeRealizado;
      realizado[x.ramoId].valor += x.valorRealizado;
    });

    return realizado;
  }

  function retornaQuantidadeProdutorOrcadoRamo(idRamo: number, prop = 'quantidade') {
    return ((dadosMetaProdutor['orcado'] || {})[idRamo] || {})[prop] || 0
  }

  function retornaQuantidadeProdutorRealizadoRamo(idRamo: number, prop = 'quantidade') {
    return ((dadosMetaProdutor['realizado'] || {})[idRamo] || {})[prop] || 0
  }

  function retornaQuantidadeAgenciaOrcadoRamo(idRamo: number, prop = 'quantidade') {
    return ((dadosMetaAgenciaOperador['orcado'] || {})[idRamo] || {})[prop] || 0
  }

  function retornaQuantidadeAgenciaRealizadoRamo(idRamo: number, prop = 'quantidade') {
    return ((dadosMetaAgenciaOperador['realizado'] || {})[idRamo] || {})[prop] || 0
  }

  function retornaMetaTotalProdutor(tipoMeta: string) {
    let valorRetorno = 0;
    const meta = (dadosMetaProdutor[tipoMeta] || {});

    (Object.keys(meta) || []).forEach(x => {
      valorRetorno += (meta[x].quantidade || 0)
    })

    return valorRetorno;
  }

  function retornaMetaTotalAgenciaOperador(tipoMeta: string) {
    let valorRetorno = 0;
    const meta = (dadosMetaAgenciaOperador[tipoMeta] || {});

    (Object.keys(meta) || []).forEach(x => {
      valorRetorno += (meta[x].valor || 0)
    })

    return valorRetorno;
  }

  function retornaValorFaltanteParaGrafico() {
    let valorOrcado = retornaMetaTotalAgenciaOperador('orcado');
    let valorRealizado = retornaMetaTotalAgenciaOperador('realizado');
    let diferenca = valorOrcado - valorRealizado;

    return diferenca < 0 ? 0 : diferenca;
  }

  function changeAgenciaSelecionada(valor) {
    setAgenciaSelecionada(valor);
    onChangeFiltroRealizado('agenciaId', (valor || []).join(','));

    const codigoAgencia = (listaUsuarioAgenciaProdutor
      .filter((x => (valor || []).some(y => y === x.agenciaId))) || { agenciaCodigo: '' }).map(x => x.agenciaCodigo).join(',');
      
    onChangeFiltroOrcado('codigoAgencia', codigoAgencia);
  }

  function onChangeFiltroRealizado(nome, valor) {
    setFiltroRealizado({ ...filtroRealizado, [nome]: valor });
  }

  function onChangeFiltroOrcado(nome, valor) {
    setFiltroOrcado({ ...filtroOrcado, [nome]: valor });
  }

  function onChangeDataFiltros(valor) {
    if (!valor) return;
    const datastring = moment(valor.set("date", 1)).format('YYYY-MM-DD');
    onChangeFiltroOrcado('dataFiltro', datastring);
    onChangeFiltroRealizado('dataFiltro', datastring);
    setDataFiltro(valor);
  }

  function retornaListaCardsDetalhes() {
    let retorno = [];

    configCards.forEach(x => {
      const valorOrcado = retornaQuantidadeAgenciaOrcadoRamo(x.id, 'valor');
      const valorRealizado = retornaQuantidadeAgenciaRealizadoRamo(x.id, 'valor');

      const porcentagem = (valorRealizado / valorOrcado) * 100;

      retorno.push({
        valorOrcado, valorRealizado,
        id: x.id,
        desc: x.desc,
        porcentagem: porcentagem > 100 ? 100 : porcentagem
      });
    });

    // Ordena por maior porcentagem e depois por maior valor realizado
    return retorno.sort((x, y) => (y.porcentagem - x.porcentagem) || (y.valorRealizado - x.valorRealizado));
  }

  return (
    <>
      <ContentHeader
        title="Dashboard"
        icon="dashboard"
        breadText=""
        showButton={false}
        textButton="Nova Cotação"
      />
      <Layout.Content>
        <Spin
          spinning={!!(dashboard.loading || []).length}
          size="small"
          tip="Aguarde carregando..."
        >

          <Layout.Content style={{ padding: 10, borderRadius: '.3rem', }}>
            <Row>
              <Col style={{ marginTop: '-40px', marginBottom: '-25px' }} xs={{ span: 24 }} sm={{ span: 7 }}>
                <Form.Item label="Mês da busca">
                  <MonthPicker
                    format="MM/YYYY"
                    locale={locale}
                    value={dataFiltro}
                    placeholder='Mês da busca'
                    onChange={onChangeDataFiltros}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={{ span: 24 }} sm={{ span: usuarioEhConsultor ? 9 : 24 }}>

                <Container style={{ padding: 15 }}>
                  <Row>
                    <strong style={{ color: '#538ac3' }}>{`${usuario.descTipoProdutor}(A): ${usuario.nome}`}</strong>
                  </Row>
                  <Row>
                    {/* Visão consultor */}
                    {
                      usuarioEhConsultor &&
                      <>
                        {
                          !usuarioPossuiVariasAgencias &&
                          <label style={{ color: '#908e8e' }}>Agência: {retornaCodigoPrimeiraAgencia()}</label>
                        }
                        {
                          usuarioPossuiVariasAgencias &&
                          <>
                            <br />
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                              <div className="form-group">
                                <label>
                                  Agência(s):
                                </label>
                                <Select
                                  defaultValue="Selecione"
                                  itens={listaUsuarioAgenciaProdutor}
                                  multiple={true}
                                  itemValue="agenciaId"
                                  itemText="agenciaCodigo"
                                  value={agenciaSelecionada}
                                  onChange={value => changeAgenciaSelecionada(value)}
                                />
                              </div>
                            </Col>
                          </>
                        }
                      </>
                    }

                  </Row>
                  <br />
                  {
                    !exibirDadosAgenciaOperadorDetalhadoPorProduto &&
                    <Row>
                      <Col sm={{ span: usuarioEhConsultor ? 24 : 12 }} xs={{ span: 24 }}>
                        <Row>
                          <h6 style={{ textAlign: 'center', color: '#908e8e' }}>
                            {`Meta Mensal ${usuarioEhConsultor ? 'Agência' : 'Operador(a)'} (R$)`}
                            <br />
                            {Functions.formatarMoeda(retornaMetaTotalAgenciaOperador('orcado'))}
                          </h6>
                        </Row>
                        {
                          !usuarioEhConsultor && exibirMetaIdealDia &&
                          <Row style={{ marginTop: 8 }}>
                            <h6 style={{ textAlign: 'center', color: '#538ac3' }}>
                              {`Ideal Para o Dia (R$): ${Functions.formatarMoeda(metaIdealDia)}`}
                            </h6>
                          </Row>
                        }
                        <Row>
                          <Pie {...configPie} />
                        </Row>
                      </Col>
                      <br />
                      <Col sm={{ span: usuarioEhConsultor ? 24 : 12 }} xs={{ span: 24 }}>
                        <CardProgress
                          titulo={`Meta Mensal ${usuarioEhConsultor ? 'Agência' : 'Operador(a)'}`}
                          valorTotal={retornaMetaTotalAgenciaOperador('orcado')}
                          valorRealizado={retornaMetaTotalAgenciaOperador('realizado')}
                          mostrarLegendaSuperior={true}
                          descLegendaSuperiorInicio='Realizado (R$)'
                          descLegendaSuperiorFim='Orçado (R$)'
                          mostrarIconeProgresso={true}
                          apresentarValoresEmReais={true}
                          apresentarBotaoCard={true}
                          configBotaoCard={{
                            descricao: 'Detalhar',
                            icon: 'eye',
                            onClickFunction: () => setExibirDadosAgenciaOperadorDetalhadoPorProduto(true)
                          }
                          }
                          style={{ background: '#eaeaea' }}></CardProgress>
                      </Col>
                    </Row>
                  }
                  {
                    exibirDadosAgenciaOperadorDetalhadoPorProduto &&
                    <>
                      <div>
                        <Row>
                          <h6 style={{ textAlign: 'center', color: '#908e8e' }}>
                            {`Meta ${(usuarioEhConsultor ? 'da Agência' : 'do(a) Operador(a)')} Detalhada por Produto:`}
                          </h6>
                        </Row>

                        <Row gutter={[8, 8]}>

                          {
                            retornaListaCardsDetalhes().map(x => {
                              return (x.valorOrcado !== 0 || x.valorRealizado !== 0)
                                && (
                                  <Col key={x.id} span={usuarioEhConsultor ? 24 : 12}>
                                    <CardProgress
                                      titulo={`Meta Mensal ${usuarioEhConsultor ? 'Agência' : 'Operador'} (${x.desc})`}
                                      valorTotal={x.valorOrcado}
                                      valorRealizado={x.valorRealizado}
                                      mostrarLegendaSuperior={true}
                                      descLegendaSuperiorInicio='Realizado (R$)'
                                      descLegendaSuperiorFim='Orçado (R$)'
                                      mostrarIconeProgresso={true}
                                      apresentarValoresEmReais={true}
                                      style={{ background: '#eaeaea' }}></CardProgress>
                                  </Col>
                                )

                            })
                          }

                        </Row>

                        <Row>
                          <div style={{ textAlign: 'right' }}>
                            <Button
                              style={{ background: 'inherit', border: 'none', boxShadow: 'none' }}
                              title="Retornar para visão geral"
                              icon="rollback"
                              type="default" shape="round" size="small" onClick={() => setExibirDadosAgenciaOperadorDetalhadoPorProduto(false)}
                            >
                              Retornar para visão geral
                            </Button>
                          </div>
                        </Row>
                      </div>
                    </>
                  }
                </Container>

              </Col>
              {usuarioEhConsultor &&
                <Col xs={{ span: 24 }} sm={{ span: 15 }}>
                  <Row gutter={[8, 8]}>
                    {
                      configCards.filter(x => tiposProdutosConsultorAgencia.some(y => y === x.id))
                        .map(x => {
                          return (
                            <Col key={x.id} xs={{ span: 24 }} sm={{ span: 12 }}>
                              <CardProgress
                                titulo={x.desc}
                                valorTotal={retornaQuantidadeProdutorOrcadoRamo(x.id)}
                                valorRealizado={retornaQuantidadeProdutorRealizadoRamo(x.id)}
                                mostrarIconeProgresso={true}
                                mostrarLegendaSuperior={true}
                                descLegendaSuperiorInicio='Quantidade Realizado'
                                descLegendaSuperiorFim='Quantidade Orçado'
                              ></CardProgress>
                            </Col>
                          )
                        })}
                  </Row>
                  <Row>
                    <Container style={null}>
                      <Row style={{ marginBottom: 14 }}>
                        <strong style={{ color: '#538ac3' }}>Evolução</strong>
                      </Row>
                      <Line {...configGraficoLinha} />
                    </Container>
                  </Row>
                  {
                    exibirDadosAgenciaOperadorDetalhadoPorProduto &&
                    <Row>
                      <Container style={{ marginTop: 8 }}>
                        <Row style={{ marginBottom: 14 }}>
                          <strong style={{ color: '#538ac3' }}>Blocos de Seguridade</strong>
                        </Row>
                        <BlocoSeguridade listaBlocoSeguridades={listaBlocoSeguridades}></BlocoSeguridade>
                      </Container>
                    </Row>
                  }
                </Col>
              }
            </Row>
            {
              usuarioEhConsultor && !exibirDadosAgenciaOperadorDetalhadoPorProduto &&
              <Container style={{ marginTop: 8 }}>
                <Row style={{ marginBottom: 14 }}>
                  <strong style={{ color: '#538ac3' }}>Blocos de Seguridade</strong>
                </Row>
                <BlocoSeguridade listaBlocoSeguridades={listaBlocoSeguridades}></BlocoSeguridade>
              </Container>
            }
            {
              !usuarioEhConsultor &&
              <>
                <Container style={{ marginTop: 8 }}>
                  <BlocoRealizadoCarteriaOperador
                    listaRealizadoCarteiraOperador={listaRealizadoCarteiraOperador}
                  ></BlocoRealizadoCarteriaOperador>
                </Container>
                <Container style={{ marginTop: 8 }}>
                  <Row style={{ marginBottom: 14 }}>
                    <strong style={{ color: '#538ac3' }}>Mês a Mês Orçado/Realizado Semestre</strong>
                  </Row>
                  <Column {...configGraficoBarra} />
                </Container>
              </>
            }

          </Layout.Content>
        </Spin>

      </Layout.Content>
    </>
  );
}
