import { call, all, put, takeLatest } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse } from '~/api/utils';
import * as ClienteApi from '~/api/cliente.api';
import * as ClienteActions from '~/store/ducks/cliente';
import { Functions } from '~/utils';

function* getByCpf(action) {
  const { param } = action.payload;
  const clienteCpfCnpj = param.replace(/\D/g, '');
  // Call api
  const response = getResponse(yield call(ClienteApi.getByCpf, clienteCpfCnpj));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  yield put(
    ClienteActions.saveCliente(
      Functions.trimObjectProperties(data) || {
        clienteCpfCnpj,
        clienteContatoFones: [],
        clienteContatoMails: [],
        clienteEnderecos: [],
        clienteTipoId: clienteCpfCnpj.length > 11 ? 2 : 1,
        sexoId: clienteCpfCnpj.length > 11 ? 3 : null,
        estadoCivilId: clienteCpfCnpj.length > 11 ? -2 : null,
      }
    )
  );

  yield call(success, message);
}

function* getProfissaoLibertyByDescricao(action) {
  const { param, callback } = action.payload;
  // Call api
  const response = getResponse(
    yield call(ClienteApi.getProfissaoLibertyByDescricao, param)
  );
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }
  if (callback) callback(data);
}

function* getContasContratos(action) {
  const { param, callback} = action.payload;
  const clienteCpfCnpj = param.replace(/\D/g, '');

  // Chamada da API
  const response = yield call(ClienteApi.getContasContratos, clienteCpfCnpj);
  const { ok, data, message, messages } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  const processedData = data.data.map(item => Functions.trimObjectProperties(item) || {
    apolice: item.apolice,
    clienteCpfCnpj: item.clienteCpfCnpj,
    clienteNome: item.clienteNome,
    conta: item.conta,
    contrato: item.contrato,
    dataFim: item.dataFim,
    dataInicio: item.dataInicio,
    modalidade: item.modalidade,
    objeto: item.objeto,
    podeAlterarConta: item.podeAlterarConta,
    produtoId: item.produtoId,
    ramo: item.ramo,
    seguradora: item.seguradora,
    seguradoraId: item.seguradoraId,
  });

  yield put(ClienteActions.saveContasContrato(processedData));

  if (!ok) {
    if (callback) callback({ ok, message: messages });
    yield call(fail, message);
    return;
  }

  if (callback) callback({ ok, message });

  yield call(success, message || "Pesquisa Realizada!");
}


function* postContaContrato(action) {
  const { param, callback } = action.payload;
  const response = getResponse(
    yield call(ClienteApi.postContaContrato, param)
  );

  const { ok, data, message, messages } = response;

  if (!ok) {
    if (callback) callback({ ok, message: messages });
    yield call(fail, message);
    return;
  }

  if (callback) callback({ ok, message });
  yield call(success, message);
}

function* getSolicitacaoAlteracaoConta(action) {
  const { callback} = action.payload;

  // Chamada da API
  const response = yield call(ClienteApi.getSolicitacaoAlteracaoConta);
  const { ok, data, message, messages } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  const processedData = data.data.map(item => Functions.trimObjectProperties(item) || {
    alteracaoContaCorrenteId: item.alteracaoContaCorrenteId,
    moduloId: item.moduloId,
    produtoId: item.produtoId,
    StatusAlteracaoContaCorrenteId: item.StatusAlteracaoContaCorrenteId,
    NumeroApolice: item.NumeroApolice,
    NumeroContrato: item.NumeroContrato,
    ContaAntiga: item.ContaAntiga,
    ContaNova: item.ContaNova,
    NomeSolicitante: item.NomeSolicitante,
    dataSolicitacao: item.dataSolicitacao,
    dataAlteracao: item.dataAlteracao,
  });

  yield put(ClienteActions.saveSolicitacaoAlteracaoConta(processedData));

  if (!ok) {
    if (callback) callback({ ok, message: messages });
    yield call(fail, message);
    return;
  }

  if (callback) callback({ ok, message });

  yield call(success, message || "Pesquisa Realizada!");
}


function* postAprovaAlteracaoConta(action) {
  const { param, callback } = action.payload;
  const response = getResponse(
    yield call(ClienteApi.postAprovaAlteracaoConta, param)
  );

  const { ok, data, message, messages } = response;

  if (!ok) {
    if (callback) callback({ ok, message: messages });
    yield call(fail, message);
    return;
  }

  if (callback) callback({ ok, message });

  yield call(success, message);
}


function* getById(action) {
  const { param, callback } = action.payload;
  // Call api
  const response = getResponse(yield call(ClienteApi.getById, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  yield put(ClienteActions.saveCliente(data));
  yield put(ClienteActions.saveEndereco(data.clienteEnderecos[0]));

  if (callback) callback(data);
}


function* success(message){
  yield put(ClienteActions.successCliente());
  toast.success(message);
}

function* fail(message) {
  yield put(ClienteActions.failedCliente());
  toast.error(message);
}

export default all([
  takeLatest(ClienteActions.types.GET_BY_CPF, getByCpf),
  takeLatest(ClienteActions.types.GET_BY_ID, getById),
  takeLatest(ClienteActions.types.GET_CONTAS_CONTRATO, getContasContratos),
  takeLatest(ClienteActions.types.POST_CONTA_CONTRATO, postContaContrato),
  takeLatest(ClienteActions.types.GET_SOLICITACAO_ALTERACAO_CONTA, getSolicitacaoAlteracaoConta),
  takeLatest(ClienteActions.types.POST_APROVA_ALTERACAO_CONTA, postAprovaAlteracaoConta),
]);
