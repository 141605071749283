import { Col, Icon, Radio, Row, Spin, Table, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import Button from 'antd-button-color';
import {
  Container,
  DatePicker,
  Error,
  FieldsProducao,
  Input,
  Select,
  TitleSection,
  Disable,
} from '~/components';
import * as PlanoActions from '~/store/ducks/plano';
import * as PropostaActions from '~/store/ducks/proposta';
import { Constants, Functions, Validation } from '~/utils';
import { ProducaoValidation, PropostaValidation } from '~/yup';
import useIsAgenciaDigital from '~/hooks/useIsAgenciaDigital';

type VppAmericanProps = {
  onPrevius: Function,
  onNext: Function,
};

export default function VppAmerican({ onPrevius, onNext }: VppAmericanProps) {
  const {
    listTipoProposta,
    listTipoPagamento,
    listTipoConta,
    listDiaDebito,
    colunasTabelaPlanos: columns,
  } = Constants.VidaPremiadoPlus;

  const {
    proposta: {
      vppAmerican: proposta,
      contasDebito,
      loading,
      contratoId,
      producao,
    },
    dominio: { bancos, agenciasBrb, grausRelacionamento },
    cliente: { cliente },
    plano: { planos, loading: loadingPlanos },
  } = useSelector(state => {
    const { contasDebito: contaD, vppAmerican } = state.proposta;

    vppAmerican.contaDebitoId = vppAmerican
      ? (
          contaD.find(
            x =>
              x.agenciaId === vppAmerican.agenciaDebitoId &&
              x.contaNumero === vppAmerican.contaDebitoNumero
          ) || { id: null }
        ).id
      : 0;

    return state;
  });

  const [erros, setErros] = useState([]);
  const [planoSelectedKey, setPlanoSelectedKey] = useState([]);
  const [planoAExibir, setPlanoAExibir] = useState([]);
  const [disableBeneficiario, setDisableBeneficiario] = useState(false);
  const [showErrorBeneficiario, setShowErrorBeneficiario] = useState(false);

  const [isAgenciaDigital] = useIsAgenciaDigital();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PropostaActions.getContas({ param: cliente?.clienteCpfCnpj }));
    if (proposta.beneficiarios.length === 1) {
      const beneficiario = proposta.beneficiarios[0];
      if (beneficiario.nome.indexOf('HERDEIRO') !== -1)
        setDisableBeneficiario(true);
    }
  }, []);

  useEffect(() => {
    if (proposta.planoSelected && planos.length > 0) {
      const planoSelected = planos.find(
        p =>
          p.planoGarantiaVidaId === proposta.planoSelected?.planoGarantiaVidaId
      );
      changeFields({ planoSelected });
      setPlanoSelectedKey([proposta.planoSelected?.planoGarantiaVidaId]);
      setPlanoAExibir([planoSelected]);
    } else setPlanoAExibir([]);
  }, [planos]);

  useEffect(() => {
    const { tipoPlano, tipoPagamento } = proposta;
    const idade = Functions.calculaIdade(cliente?.clienteDataNascimento);
    dispatch(
      PlanoActions.getPlanosVpp({
        param: { idade, tipoPlano, tipoPagamento },
      })
    );
  }, [proposta.tipoPlano, proposta.tipoPagamento]);

  function changeFields(obj) {
    dispatch(PropostaActions.changeVppAmerican(obj));
  }

  function changeTipoProposta(value) {
    if (value === 2) {
      changeFields({ tipoPropostaId: value });
      return;
    }
    changeFields({
      tipoPropostaId: value,
      dataPagamentoPropostaManual: undefined,
      numeroPropostaManual: undefined,
    });
  }

  function changeContaDebito(contaId) {
    const conta = contasDebito.find(x => x.id === contaId);

    changeFields({
      contaDebitoId: conta.id,
      agenciaDebitoId: conta.agenciaId,
      contaDebitoNumero: conta.contaNumero,
    });
  }

  function onChangeCpfTerceiro(value) {
    dispatch(
      PropostaActions.changeVppAmerican({
        cpfTerceiro: value,
        contaDebitoId: undefined,
        agenciaDebitoId: undefined,
      })
    );

    dispatch(PropostaActions.changeContasDebito([]));

    if (value && value.length === 14) {
      if (!Validation.isCpfCnpjValid(value))
        dispatch(
          PropostaActions.changeVppAmerican({ cpfTerceiroIsValid: false })
        );
      else {
        dispatch(
          PropostaActions.changeVppAmerican({
            cpfTerceiroIsValid: true,
            loadingContas: true,
          })
        );

        dispatch(PropostaActions.getContas({ param: value }));
      }
    }
  }

  function changeOrigemConta(event) {
    const {
      target: { value },
    } = event;

    changeFields({
      tipoConta: value,
      contaDebitoId: undefined,
      agenciaDebitoId: undefined,
      contaDebitoNumero: undefined,
    });

    if (value !== 2) {
      changeFields({ cpfTerceiro: undefined });
      if (value === 1)
        dispatch(PropostaActions.getContas({ param: cliente.clienteCpfCnpj }));
      else dispatch(PropostaActions.changeContasDebito([]));
    } else if (value === 2) dispatch(PropostaActions.changeContasDebito([]));
  }

  function changeBeneficiario(field, value, index) {
    const newArray = proposta.beneficiarios.map((beneficiario, i) => {
      if (i === index) {
        beneficiario[field] = value;
      }

      return beneficiario;
    });

    changeFields({ beneficiarios: newArray });
  }

  function newBeneficiario() {
    setShowErrorBeneficiario(false);
    const { beneficiarios } = proposta;
    let total = 0;
    beneficiarios.forEach(b => (total += parseInt(b.percentual, 10)));

    if (total >= 100) {
      setShowErrorBeneficiario(true);
      return;
    }

    changeFields({
      beneficiarios: [
        ...proposta.beneficiarios,
        ...[{ nome: '', grauRelacionamento: '', percentual: '' }],
      ],
    });
  }

  async function validaProposta() {
    let response = await validaObjeto(PropostaValidation.VppAmerican, proposta);
    if (!response.ok) return response;

    response = await validaObjeto(
      ProducaoValidation.ProducaoValidationDefault,
      producao
    );

    if (!response.ok) return response;

    return { ok: true };
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function deleteBeneficiario() {
    setShowErrorBeneficiario(false);
    const ben = proposta.beneficiarios;
    ben.pop();
    changeFields({
      beneficiarios: ben,
    });
  }

  async function _onNext() {
    setErros([]);
    // valid data
    const response = await validaProposta();
    // Check is valid
    if (response.ok) onNext();
    else setErros(response);
  }

  function onChangeValorCobertura(value) {
    if (value) {
      const plano = planos.find(e => e.planoGarantiaVidaId === value);
      setPlanoSelectedKey([plano.planoGarantiaVidaId]);
      setPlanoAExibir([plano]);
      changeFields({ planoSelected: plano });
    } else setPlanoAExibir([]);
  }

  function onChangeGrauParentesco(value, index) {
    const grauRelacionamento = grausRelacionamento.find(
      g => g.grauRelacionamentoId === value
    );
    if (
      grauRelacionamento.grauRelacionamentoDescricao.indexOf('HERDEIRO') !== -1
    ) {
      changeBeneficiario('nome', 'HERDEIROS LEGAIS', index);
      changeBeneficiario('percentual', '100', index);
      setDisableBeneficiario(true);
    } else setDisableBeneficiario(false);
    changeBeneficiario('grauRelacionamento', value, index);
  }

  return (
    <>
      <Spin spinning={loading} size="large">
        <div className="actions-tabs" style={{ paddingBottom: 10 }}>
          <Button size="sm" onClick={onPrevius} with="link" type="primary">
            <ArrowLeftOutlined /> Voltar
          </Button>
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            onClick={_onNext}
          >
            Próximo <ArrowRightOutlined />
          </Button>
        </div>
        <Container>
          <Error erros={erros} />

          <Disable disable={!!contratoId}>
            <h4>Vida</h4>

            <TitleSection title="Tipo de proposta" />

            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col span={24}>
                <Radio.Group
                  buttonStyle="solid"
                  defaultValue={proposta.tipoPropostaId}
                  options={listTipoProposta}
                  onChange={({ target: { value } }) =>
                    changeTipoProposta(value)
                  }
                />
              </Col>
            </Row>
            {proposta.tipoPropostaId === 2 && (
              <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
                <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                  <div className="form-group">
                    <label>Número da proposta manual</label>
                    <Input
                      placeholder="1038-"
                      value={proposta.numeroPropostaManual}
                      onChange={value =>
                        changeFields({ numeroPropostaManual: value })
                      }
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                  <div className="form-group">
                    <label>Data do pagamento</label>
                    <div>
                      <DatePicker
                        value={proposta.dataPagamentoPropostaManual}
                        placeholder="Selecione"
                        onChange={value =>
                          changeFields({ dataPagamentoPropostaManual: value })
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            <FieldsProducao produto="vpp" />

            <TitleSection title="Pagamentos" />

            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label className="w-100">Pagamento do Prêmio</label>
                  <Radio.Group
                    buttonStyle="solid"
                    defaultValue={proposta.tipoPagamento}
                    options={listTipoPagamento}
                    onChange={({ target: { value } }) =>
                      changeFields({ tipoPagamento: value })
                    }
                  />
                </div>
              </Col>

              <Col xs={{ span: 12 }} sm={{ span: 7 }}>
                <div className="form-group">
                  <label className="w-100">Origem da conta</label>
                  <Radio.Group
                    buttonStyle="solid"
                    defaultValue={proposta.tipoConta}
                    options={listTipoConta}
                    onChange={changeOrigemConta}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              {proposta.tipoConta === 2 && (
                <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                  <div className="form-group">
                    <label>CPF do terceiro</label>
                    <Input
                      placeholder="Cpf/Cnpj"
                      value={proposta.cpfTerceiro}
                      onChange={onChangeCpfTerceiro}
                      mask="cpfCnpj"
                      maxLength={18}
                    />
                    <Spin
                      style={{ marginLeft: -30, marginTop: 1 }}
                      spinning={proposta.loadingContas}
                    />
                  </div>
                </Col>
              )}
              {proposta.tipoConta !== 3 && (
                <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                  <div className="form-group">
                    <label>
                      Contas do{' '}
                      {proposta.tipoConta === 1 ? 'Titular' : 'Terceiro'}
                    </label>
                    <Select
                      defaultValue="Selecione"
                      itens={contasDebito}
                      itemValue="id"
                      renderText={conta =>
                        `${conta.agenciaCodigo} - ${conta.contaNumero}`
                      }
                      value={proposta.contaDebitoId}
                      onChange={value => changeContaDebito(value)}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label className="w-100">Banco</label>
                  <Select
                    defaultValue="Selecione"
                    itens={bancos.filter(b => b.bancoId === '0070')}
                    itemValue="bancoId"
                    itemText="bancoDescricao"
                    value={proposta.bancoId}
                    onChange={value => changeFields({ bancoId: value })}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label>Agência</label>
                  <Select
                    defaultValue="Selecione"
                    itens={agenciasBrb}
                    itemValue="agenciaId"
                    value={proposta.agenciaDebitoId}
                    itemText="agenciaCodigo"
                    itemTextSearch="agenciaDescricao"
                    renderText={ag =>
                      `${ag.agenciaCodigo} - ${ag.agenciaDescricao}`
                    }
                    onChange={value => changeFields({ agenciaDebitoId: value })}
                    disabled={proposta.tipoConta !== 3}
                    showSearch
                  />
                </div>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label>Conta</label>
                  <Input
                    placeholder="Conta Corrente"
                    value={proposta.contaDebitoNumero}
                    onChange={value =>
                      changeFields({ contaDebitoNumero: value })
                    }
                    disabled={proposta.tipoConta !== 3}
                    accept="onlyNumbers"
                  />
                </div>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                <div className="form-group">
                  <label className="w-100">Dia para débito das parcelas</label>
                  <Select
                    defaultValue="Selecione"
                    style={{ width: '100%' }}
                    itens={listDiaDebito}
                    value={proposta.diaDebito}
                    renderText={item => item}
                    onChange={value => changeFields({ diaDebito: value })}
                  />
                </div>
              </Col>
            </Row>
          </Disable>

          <TitleSection title="Opções de Planos" />

          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <div className="form-group">
                <label>Selecione o valor da cobertura </label>
                <Select
                  placeholder="Selecione"
                  itens={planos}
                  itemText="mtrGarantiaMorte"
                  itemTextSearch="mtrPremioTotal"
                  renderText={item =>
                    item.mtrGarantiaMorte
                      ? `${Functions.formatarMoeda(
                          item.mtrGarantiaMorte
                        )} - ${Functions.formatarMoeda(item.mtrPremioTotal)}`
                      : ''
                  }
                  onChange={onChangeValorCobertura}
                  itemValue="planoGarantiaVidaId"
                  value={
                    proposta.planoSelected?.mtrGarantiaMorte &&
                    `${Functions.formatarMoeda(
                      proposta.planoSelected?.mtrGarantiaMorte
                    )} - ${Functions.formatarMoeda(
                      proposta.planoSelected?.mtrPremioTotal
                    )}`
                  }
                  loading={loading}
                  showSearch
                  remove={item =>
                    isAgenciaDigital && item.mtrGarantiaMorte > 50000
                  }
                />
              </div>
            </Col>

            {planoAExibir.length === 1 && (
              <Col span={24}>
                <Table
                  rowKey="planoGarantiaVidaId"
                  dataSource={planoAExibir}
                  columns={columns}
                  pagination={false}
                  loading={loadingPlanos}
                  size="small"
                  rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys, selectedRows) => {
                      changeFields({ planoSelected: selectedRows[0] });
                      setPlanoSelectedKey(selectedRowKeys);
                    },
                    selectedRowKeys: planoSelectedKey,
                    getCheckboxProps: () => ({
                      disabled: !!contratoId,
                    }),
                  }}
                  scroll={{ x: 1000 }}
                />
              </Col>
            )}
          </Row>
          <span>
            <i>
              Remuneração pela intermediação de 63,1% e Pró Labore de 0,5% sobre
              o prêmio
            </i>
          </span>

          <Disable disable={!!contratoId}>
            <TitleSection title="Benficiários" />

            <p>A soma das participações dos beneficiários deve ser 100%.</p>

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                Grau de parentesco
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 14 }}>
                Nome(s) do(s) Beneficiário(s)
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                Participação
              </Col>
            </Row>
            {proposta.beneficiarios.map((beneficiario, index) => {
              return (
                <Row gutter={[16, 16]} key={index.toString()}>
                  <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                    <div className="form-group">
                      <Select
                        showSearch
                        defaultValue="Selecione"
                        itens={grausRelacionamento}
                        itemValue="grauRelacionamentoId"
                        itemText="grauRelacionamentoDescricao"
                        value={beneficiario.grauRelacionamento}
                        onChange={value => onChangeGrauParentesco(value, index)}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 14 }}>
                    <Input
                      placeholder="Nome dos Beneficiários"
                      value={beneficiario.nome}
                      onChange={value =>
                        changeBeneficiario('nome', value, index)
                      }
                      disabled={disableBeneficiario}
                    />
                  </Col>

                  <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                    <Input
                      disabled={disableBeneficiario}
                      placeholder="Em porcentagem"
                      value={beneficiario.percentual}
                      onChange={value =>
                        changeBeneficiario('percentual', value, index)
                      }
                      accept="onlyNumbers"
                      suffix="%"
                      maxLength={3}
                    />
                  </Col>
                </Row>
              );
            })}
            {showErrorBeneficiario && (
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <Alert
                    message="O percentual máximo de 100% já foi atingido."
                    type="error"
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Button
                  className="btn btn-xs-block"
                  type="primary"
                  onClick={newBeneficiario}
                  style={{ marginRight: 20 }}
                >
                  <Icon type="plus" />
                  Incluir Beneficiário
                </Button>

                <Button
                  className="btn  btn-xs-block mt-2 mt-sm-0"
                  type="danger"
                  onClick={deleteBeneficiario}
                  disabled={proposta.beneficiarios.length === 1}
                >
                  <Icon type="delete" />
                  Excluir último Beneficiário
                </Button>
              </Col>
            </Row>
          </Disable>
        </Container>

        <div className="actions-tabs">
          <Button size="sm" onClick={onPrevius} with="link" type="primary">
            <ArrowLeftOutlined /> Voltar
          </Button>
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            onClick={_onNext}
          >
            Próximo <ArrowRightOutlined />
          </Button>
        </div>
      </Spin>
    </>
  );
}
