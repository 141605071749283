import { createActions, handleActions } from 'redux-actions';

import moment from 'moment';

export const types = {
  GET_LISTA_DOMINIOS: 'GET_LISTA_DOMINIOS',
  SAVE_LISTA_DOMINIOS: 'SAVE_LISTA_DOMINIOS',
  GET_DOMINIO_BY_ID: 'GET_DOMINIO_BY_ID',
  SAVE_DOMINIO: 'SAVE_DOMINIO',
  COTAR: 'COTAR',
  SAVE_COTAR: 'SAVE_COTAR',
  EFETIVA_PROPOSTA: 'EFETIVA_PROPOSTA',
  SUCCESS_RETORNO_EFETIVAR: 'SUCCESS_RETORNO_EFETIVAR',
  SAVE_SEGURADO_REDUX: 'SAVE_SEGURADO_REDUX',
  CHANGE_FIELD_STATE: 'CHANGE_FIELD_STATE',
  CHANGE_COTACAO_STATE: 'CHANGE_COTACAO_STATE',
  CHANGE_ENDERECO_COTACAO: 'CHANGE_ENDERECO_COTACAO',
  REINICIAR_PAGAMENTO_ESCOLHIDO: 'REINICIAR_PAGAMENTO_ESCOLHIDO',
  IMPRESSAO_BOLETO_RESIDENCIAL_LIBERTY: 'IMPRESSAO_BOLETO_RESIDENCIAL_LIBERTY',
  SET_DEFAULT_STATE: 'SET_DEFAULT_STATE',
  LIMPAR_CARREGANDO: 'LIMPAR_CARREGANDO',
  CHANGE_CONTA_DEBITO: 'CHANGE_CONTA_DEBITO',
  FALHA: 'FALHA',
};

export const {
  getListaDominios,
  saveListaDominios,
  getDominioById,
  saveDominio,
  cotar,
  saveCotar,
  efetivaProposta,
  successRetornoEfetivar,
  saveSeguradoRedux,
  changeFieldState,
  changeCotacaoState,
  changeEnderecoCotacao,
  reiniciarPagamentoEscolhido,
  impressaoBoletoResidencialLiberty,
  setDefaultState,
  limparCarregando,
  changeContaDebito,
  falha
} = createActions(
  types.GET_LISTA_DOMINIOS,
  types.SAVE_LISTA_DOMINIOS,
  types.GET_DOMINIO_BY_ID,
  types.SAVE_DOMINIO,
  types.COTAR,
  types.SAVE_COTAR,
  types.EFETIVA_PROPOSTA,
  types.SUCCESS_RETORNO_EFETIVAR,
  types.SAVE_SEGURADO_REDUX,
  types.CHANGE_FIELD_STATE,
  types.CHANGE_COTACAO_STATE,
  types.CHANGE_ENDERECO_COTACAO,
  types.REINICIAR_PAGAMENTO_ESCOLHIDO,
  types.IMPRESSAO_BOLETO_RESIDENCIAL_LIBERTY,
  types.SET_DEFAULT_STATE,
  types.LIMPAR_CARREGANDO,
  types.CHANGE_CONTA_DEBITO,
  types.FALHA,
);

const defaultState = {
  loading: [],
  cotacao: {
    dataInicioVigencia: moment.utc(Date.now()),
    dataFimVigencia: moment(Date.now()).add(1, 'y'),
    localRisco: {
      tipoAssistenciaId: null,
      tipoConstrucaoId: null,
      tipoHabitacaoId: null,
      tipoOcupacaoId: null,
      tipoAtividadeProfissionalExercidaId: null,
      seguradoEhProprietarioDoImovel: false,
      imovelEhPatrimonioHistorico: false,
      imovelDesocupadoEmRetormaOuEmConstrucao: false,
      imovelPossuiMadeiraParedesExternas: false,
      endereco: {},
    },
    porcentagemAgravo: null,
    coberturas: []
  },
  propostaDetalhes: {},
  efetivacao: {
    beneficiarios: [],
    listaContatosInspecao: [],
    diaPreferenciaPagamento: null,
    enderecoDeCorrespodenciaIgualAoDoSegurado: true,
    escolhaFormaPagamentoId: 0,
    clienteContaId: null,
    contaDebito: {}
  },
  listaNomesDominios: [],
  segurado: {},
  messagesInfo: []
};

export default handleActions(
  {
    [impressaoBoletoResidencialLiberty]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      
      return {
        ...state
      }
    },
    [getListaDominios]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      
      return {
        ...state
      }
    },
    [getDominioById]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [changeFieldState]: (state, action) => {
      return action.payload.field 
        ? {
          ...state,
          [action.payload.field]: action.payload.value
        }
        : {
          ...action.payload
        }
    },
    [changeCotacaoState]: (state, action) => {
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          [action.payload.field]: action.payload.value
        }
      }
    },
    [changeEnderecoCotacao]: (state, action) => {
      return {
        ...state,
        cotacao: {
          ...state.cotacao,
          localRisco: {
            ...state.cotacao.localRisco,
            endereco: action.payload
          }
        }
      }
    },
    [cotar]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [efetivaProposta]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [saveListaDominios]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      
      return ({
        ...state,
        listaNomesDominios: [...action.payload]
      })
    },
    [saveDominio]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        cliente: {...action.payload}
      })
    },
    [saveCotar]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        propostaDetalhes: { ...action.payload.resultadoCotacao }
      })
    },
    [successRetornoEfetivar]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state
      })
    },
    [saveSeguradoRedux]: (state, action) => {
      return {
        ...state,
        segurado: {...action.payload }
      }
    },
    [reiniciarPagamentoEscolhido]: (state) => {
      return {
        ...state,
        efetivacao: {
          ...state.efetivacao,
          escolhaFormaPagamentoId: 0,
        }
      }
    },
    [setDefaultState]: (state) => {
      return ({
        ...defaultState,
        listaNomesDominios: [...(state?.listaNomesDominios ?? [])],
        cotacao: {
          ...defaultState.cotacao,
          coberturas: []
        },
        propostaDetalhes: {},
        messagesInfo: [],
        loading: [],
        efetivacao: {
          beneficiarios: [],
          listaContatosInspecao: [],
          diaPreferenciaPagamento: null,
          enderecoDeCorrespodenciaIgualAoDoSegurado: true,
          escolhaFormaPagamentoId: 0,
          clienteContaId: null,
          contaDebito: {}
        }
      })
    },
    [changeContaDebito]: (state, action) => 
    (
      {
        ...state,
        efetivacao: {
          ...state.efetivacao,
          contaDebito: {
            ...action.payload
          }
        }
      }
    ),   
    [falha]: state => ({ ...state, loading: [] }),
    [limparCarregando]: state => ({ ...state, loading: [] }),
  },
  defaultState
);
