import api from '.';

export const get = param => api().get(`api/pesquisa-geral`, param);

export const getDetalhesCliente = 
    param => api().get(`api/pesquisa-geral/detalhe-cliente`, param);

export const getDetalhesPrestamista = 
    param => api().get(`api/pesquisa-geral/detalhe-prestamista`, param);

export const getDetalhesHabitacional = 
    param => api().get(`api/pesquisa-geral/detalhe-habitacional`, param);

export const getDetalhesConsorcio = 
    param => api().get(`api/pesquisa-geral/detalhe-consorcio`, param);

export const getDetalhesPrevidencia = 
    param => api().get(`api/pesquisa-geral/detalhe-previdencia`, param);

export const getDetalhesSaude = 
    param => api().get(`api/pesquisa-geral/detalhe-saude`, param);

export const getDetalhesFerias = 
    param => api().get(`api/pesquisa-geral/detalhe-ferias`, param);

export const getDetalhesVida = 
    param => api().get(`api/pesquisa-geral/detalhe-vida`, param);

export const getDetalhesCapitalizacao = 
    param => api().get(`api/pesquisa-geral/detalhe-capitalizacao`, param);

export const getDetalhesAutomovel = 
    param => api().get(`api/pesquisa-geral/detalhe-automovel`, param);

export const getDetalhesDre = 
    param => api().get(`api/pesquisa-geral/detalhe-dre`, param);
