import { createActions, handleActions } from 'redux-actions';
import moment from 'moment';

export const types = {
  LIST_DOMINIO: 'LIST_DOMINIO',
  LIST_ALL: 'LIST_ALL',
  SAVE_DOMINIO: 'SAVE_DOMINIO',
  SAVE_MANY: 'SAVE_MANY',
  FAILED_DOMINIO: 'FAILED_DOMINIO',
};

export const {
  listDominio,
  listAll,
  saveDominio,
  saveMany,
  failedDominio,
} = createActions(
  types.LIST_DOMINIO,
  types.LIST_ALL,
  types.SAVE_DOMINIO,
  types.SAVE_MANY,
  types.FAILED_DOMINIO
);

const defaultState = {
  horaCarregada: null,
  loading: false,
};

export default handleActions(
  {
    [listAll]: state => ({ ...state, loading: true }),
    [saveDominio]: (state, action) => ({
      ...state,
      horaCarregada: moment(Date.now()),
      [action.payload.dominio]: action.payload.data,
      loading: false,
    }),
    [saveMany]: (state, action) => Object.assign(state, action.payload),
  },
  defaultState
);
