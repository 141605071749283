import { Col, Divider, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';

import TabelaSinistroDetalhes from './tabela-sinistro-detalhes';

const { Column } = Table;

type AutomovelTabProp = {
  data?: Object
}

function ordenarPorPropriedade(lista, posicaoOrdenar = null) {
  return lista.sort((a, b) => a[posicaoOrdenar] - b[posicaoOrdenar]);
}

function ocultarListaParcelas(contrato) {
  var listaSeguradoraIds = 
  [713, 1490, 2054, 2895, 3182, 4952, 5321, 5355, 5495, 5886, 6203, 6467, 6513, 6572, 6751, 6955, 28878, 500561];

  return listaSeguradoraIds.some(x => x === contrato?.seguradoraId)
}

export default function AutomovelDetalhe(param: AutomovelTabProp) {

  const contrato = param?.data || {};

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        Dados do Contrato
      </Divider>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Contrato">Contrato</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Contrato">{contrato?.contratoAutomovelNumeroControle}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Apólice">Apólice</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Apólice">{contrato?.contratoAutomovelApolice}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Vigência">Vigência</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Vigência">
                {
                  `DE ${moment(contrato?.contratoAutomovelDataInicio).format('DD/MM/YYYY')}
                  A ${moment(contrato?.contratoAutomovelDataFim).format('DD/MM/YYYY')}`

                }
              </span>

            </Col>
          </Row>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Situação">Situação</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Situação">
                {contrato?.contratoStatusDescricao}
              </span>

            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Produto">Produto</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Produto">{contrato?.produtoDescricao}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Seguradora">Seguradora</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Seguradora">{contrato?.seguradoraDescricao}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Tipo contrato">Tipo contrato</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Tipo contrato">{contrato?.contratoTipoDescricao}</span>
            </Col>
          </Row>
        </Col>
        {
          contrato?.contratoAutomovelEndosso &&
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="Endosso">Endosso</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="Endosso">{contrato?.contratoAutomovelEndosso}</span>
              </Col>
            </Row>
          </Col>
        }

      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Item / Modelo">Item / Modelo</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Item / Modelo">{contrato?.itemModelo}</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Placa">Placa</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Placa">{contrato?.placa}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        {
          contrato?.pagamentos && !!contrato.pagamentos.length &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Parcelas
              </Divider>
              {
                ocultarListaParcelas(contrato) &&
                <span>A cia. em referência ainda não possui rotina de automação de parcelas</span>
              }
              {
                !ocultarListaParcelas(contrato) && 
                <>
                  <Table
                    rowKey="pagamentoId"
                    dataSource={ordenarPorPropriedade(contrato?.pagamentos || [], 'pagamentoParcelaNumero')}
                    scroll={{ x: 800 }}
                  >
                    <Column
                      title="Nº Parcela"
                      align="center"
                      dataIndex="pagamentoParcelaNumero"
                      width={6}
                    />
                    <Column
                      title="Data pagamento"
                      width={10}
                      align="center"
                      dataIndex="pagamentoDataPagamento"
                      render={(text, record) => (
                        text
                          ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                          : 'NÃO ESTÁ PAGA'
                      )}
                    />
                    <Column
                      title="Data vencimento"
                      width={10}
                      align="center"
                      dataIndex="pagamentoDataVencimento"
                      render={(text, record) => (
                        text
                          ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                          : 'SEM DATA DE VENCIMENTO'
                      )}
                    />
                    <Column
                      title="Valor pago"
                      width={12}
                      align="center"
                      dataIndex="pagamentoValor"
                      render={(text, record) => (
                        text
                          ? <span>{util.Functions.formatarMoeda(text)}</span>
                          : 'NÃO ESTÁ PAGA'
                      )}
                    />
                    <Column
                      title="Situação"
                      align="center"
                      width={8}
                      dataIndex="pagamentoStatusDescricao"
                    />

                  </Table>
                </>
              }
              
            </Col>
          </>
        }
        {
          contrato?.bens && !!contrato.bens.length &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Bens
              </Divider>
              <Table
                rowKey="bemId"
                dataSource={ordenarPorPropriedade(contrato?.bens || [], 'bemId')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="Bem"
                  align="center"
                  dataIndex="bem"
                  width={15}
                />
                <Column
                  title="Bem marca"
                  align="center"
                  dataIndex="bemMarca"
                  width={15}
                />
                <Column
                  title="Bem modelo"
                  align="center"
                  dataIndex="bemModelo"
                  width={15}
                />
                <Column
                  title="Endereço"
                  width={10}
                  align="center"
                  dataIndex="bemEnderecoDescricao"
                />
                <Column
                  title="Endereço complemento"
                  width={10}
                  align="center"
                  dataIndex="bemEnderecoComplemento"
                />
                <Column
                  title="Endereço bairro"
                  width={10}
                  align="center"
                  dataIndex="bemEnderecoBairro"
                />
                <Column
                  title="Número"
                  width={10}
                  align="center"
                  dataIndex="bemEnderecoNumero"
                />
              </Table>
            </Col>
          </>
        }
        {
          contrato?.sinistros && !!contrato.sinistros.length &&
          <TabelaSinistroDetalhes data={ordenarPorPropriedade(contrato.sinistros, 'sinistroId')}>
          </TabelaSinistroDetalhes>
        }


      </Row>

    </>
  );
};