import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import Constants from '../../../../utils/constants';
import { store } from '~/store';
import { Functions } from '~/utils';

function formatarContrato(proposta, cliente, usuario, producao, nrControle) {
  let dadosCondicionais = {};
  if (
    proposta.tipoPropostaId === Constants.VidaPremiadoPlus.tipoProposta.manual
  ) {
    dadosCondicionais = {
      contratoVidaDataContrato: add(
        Date.parse(proposta.dataPagamentoPropostaManual),
        {
          days: 1,
        }
      ),
      contratoVidaDataFinal: add(
        Date.parse(proposta.dataPagamentoPropostaManual),
        {
          years: 1,
          days: 1,
        }
      ),
      contratoVidaObservacao: `PROPOSTA MANUAL DIGITADA ATRAVES DO PORTAL SEGUROS PELO USUARIO ${usuario.matricula}`,
      contratoVidaVencimento1Parcela: proposta.dataPagamentoPropostaManual ,
      mtrContratoVida1Parcela: proposta.planoSelected.mtrPremioTotal,
    };
  } else {
    dadosCondicionais = {
      contratoVidaDataContrato: formatISO(add(new Date(), { days: 1 })),
      contratoVidaDataFinal: formatISO(add(new Date(), { years: 1, days: 1 })),
      contratoVidaObservacao: 'PROPOSTA ELETRONICA',
      contratoVidaVencimento1Parcela: formatISO(new Date()),
      mtrContratoVida1Parcela: proposta.planoSelected.mtrPremioTotal,
    };   
  }

  if (
    proposta.tipoPlano === Constants.VidaPremiadoPlus.tipoPlano.titularConjuge
  ) {
    dadosCondicionais = {
      ...dadosCondicionais,
      contratoVidaConjugeCpf: proposta.conjugeCpf,
      contratoVidaConjugeNome: proposta.conjugeNome,
      contratoVidaConjugeDataNascimento: proposta.conjugeDataNascimento,
      contratoVidaConjugeAltura: proposta.conjugeAltura,
      contratoVidaConjugePeso: proposta.conjugePeso,
    };    
  }

  const benficiarios = proposta.beneficiarios.map(b => {
    const result = {
      moduloId: 3, // VIDA
      beneficiarioId: 0,
      clienteId: cliente.clienteId,
      mtrParticipacao: b.percentual * 0.01,
      fatoBeneficiarioNivelDoisDataCadastro: formatISO(new Date()),
      grauRelacionamentoId: b.grauRelacionamento,
      beneficiario: { beneficiarioNome: b.nome },
    };

    return result;
  });

  const contrato = {
    contratoVidaNumeroControle: nrControle,
    clienteId: cliente.clienteId,
    contratoVidaStatusId: 1, // ativo
    seguradoraId: Constants.Seguradoras.AmericanLife, // american life
    produtoId: Constants.Produtos.VidaPremiadoPlus, // vida premiado plus
    contratoVidaDataCadastro: formatISO(new Date()),
    contratoVidaDataCancelamento: null,
    contratoFormaPagamentoId: proposta.tipoPagamento == 1 ? 5 : 1, // fracionado/debito no mensal avisa débito no anual
    planoGarantiaVidaId: proposta.planoSelected.planoGarantiaVidaId,
    contratoVidaApolice: '1/0093/00001038',
    contratoVidaDataEmissaoApolice: formatISO(new Date()),
    correcaoCapitalVidaId: 2, // anual
    tipoPagamentoId: proposta.tipoPagamento,
    mtrContratoVidaPremio: proposta.planoSelected.mtrPremioTotal,
    mtrContratoVidaPremioLiquido:
      proposta.planoSelected.mtrPremioTotal / 1.0038,
    mtrContratoVidaComissaoCorretagem: 0.631, 
    mtrContratoVidaIof: proposta.planoSelected.mtrPremioTotal * 0.0038,
    agenciaId: producao.agenciaId,
    equipeId: producao.equipeId,
    angariadorId: producao.angariadorId,
    consultorId: producao.consultorId,
    contratoVidaTitularAltura: proposta.titularAltura,
    contratoVidaTitularPeso: proposta.titularPeso,
    contratoVidaDiaVencimentoDemaisParcelas: proposta.diaDebito,
    debitoConta: {
      produtoId: Constants.Produtos.VidaPremiadoPlus,
      agenciaId: proposta.agenciaDebitoId,
      debitoContaNumeroConta: proposta.contaDebitoNumero,
    },
    ...dadosCondicionais,
    fatoBeneficiarioNivelDois: benficiarios,
  };

  return contrato;
}

function formatarCliente(cliente, endereco) {
  return {
    ...cliente,
    clienteTipoId: cliente.clienteTipoId || 1, // Pessoa Física
    clienteContatoFones: cliente.clienteContatoFones.map(cf => {
      cf.clienteContatoFoneTipo = null; // Limpa o objeto do tipo de telefone
      return cf;
    }),
    clienteEnderecos: endereco.clienteEnderecoId
      ? cliente.clienteEnderecos.map(e => {
          if (e.clienteEnderecoId === endereco.clienteEnderecoId) {
            e = { ...e, ...endereco };
          }

          return e;
        })
      : [...cliente.clienteEnderecos, endereco],
  };
}

function formataJsonDemaisDados(proposta, cliente) {
  const { tipoPropostaId, tipoConta, tipoPlano, respostasDps } = proposta;
  const { clienteFaixaRendaManual } = cliente;
  return JSON.stringify({ tipoPropostaId, tipoConta, tipoPlano, respostasDps, clienteFaixaRendaManual });
}

function formatarProposta(efetivar) {
  const {
    auth: { usuario },
    cliente: { cliente, endereco },
    proposta: { propostaId, vppAmerican: proposta, producao, numeroControle },
  } = store.getState();

  const clienteFormatado = formatarCliente(cliente, endereco);

  const propostaFormatada = {
    clienteId: cliente.clienteId,
    produtoId: Constants.Produtos.VidaPremiadoPlus,
    moduloId: Constants.Modulos.VIDA,
    usuarioId: usuario.matricula,
    planoGarantiaVidaId: proposta.planoSelected?.planoGarantiaVidaId,
    cliente: clienteFormatado,
    propostaEletronicaId: propostaId,
    propostaEletronicaEfetivada: efetivar,
    propostaEletronicaDataCadastro: formatISO(new Date()),
    propostaEletronicaDataEfetivacao: efetivar ? formatISO(new Date()) : null,
    propostaEletronicaJsonContrato: JSON.stringify(
      formatarContrato(proposta, clienteFormatado, usuario, producao, numeroControle)
    ),
    propostaEletronicaJsonDemaisDados: formataJsonDemaisDados(proposta, cliente),
    propostaEletronicaNumeroControle: proposta.tipoPropostaId === 2 ? proposta.numeroPropostaManual : proposta.numeroControle,
  };

  return propostaFormatada;
}

export default formatarProposta;
