import 'antd/dist/antd.css';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Row, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Functions as util, Mask, Validation } from 'utils';
import { Container, ContentHeader, DatePicker, Input, Select } from '~/components';
import * as DominiosActions from '~/store/ducks/dominio';
import * as PlanoActions from '~/store/ducks/plano';
import * as PropostaActions from '~/store/ducks/proposta';
import { Constants, Functions } from '~/utils';




const { Column } = Table;

export default function PesquisarPropostasCanceladas() {
  const dispatch = useDispatch();

  const { usuario } = useSelector(({ auth }) => auth);
  const { propostaCanceladas, loading } = useSelector(({ proposta }) => proposta);
  const dominios = useSelector(({ dominio }) => dominio);

  const [periodo, setPeriodo] = useState([
    moment()
      .add(-5, 'd')
      .toDate(),
    moment().toDate(),
  ]);
  const [filtros, setFiltros] = useState({});
  const [loadingSelected, setLoadingSelected] = useState(false);

  const {
    ApLiberty: { planos },
  } = Constants;

  const listaModulos = [
    { value: -1, desc: 'TODOS' },
    { value: 10, desc: 'RESIDÊNCIA' },
    { value: 20, desc: 'AUTOMÓVEL' },
    { value: 30, desc: 'RISCOS DIVERSOS' },
    { value: 40, desc: 'VIDA' },
    { value: 50, desc: 'CAPITALIZAÇÃO' },
    { value: 60, desc: 'PREVIDÊNCIA' },
    { value: 70, desc: 'DEMAIS RE' },
    { value: 90, desc: 'RISCOS PESSOAIS' },
    { value: 91, desc: 'VIDA PREMIADO' },
    { value: 92, desc: 'CONSÓRCIO' },
    { value: 93, desc: 'PRESTAMISTA' },
    { value: 94, desc: 'SAÚDE' },
    { value: 95, desc: 'VIAGEM E TURISMO' },
  ]

  useEffect(() => {
    if (periodo) {
      setFiltros({
        ...filtros,
        periodoDataInicial: moment(periodo[0]).format('YYYY-MM-DD'),
        periodoDataFinal: moment(periodo[1]).format('YYYY-MM-DD'),
      });
    }
  }, [periodo]);

  useEffect(() => {
    dispatch(PlanoActions.savePlanos([]));

    if (
      !dominios.horaCarregada ||
      moment(Date.now())
        .add(-2, 'hour')
        .isAfter(dominios.horaCarregada)
    )
    dispatch(DominiosActions.listAll(firtSearch));
    else firtSearch();
  }, []);

  function firtSearch() {
    const isNoAdmin =
      usuario.fatoUsuarioPerfis.filter(c =>
        Functions.inStruct(c.perfil.perfilCodigo, ['BCCON', 'BCOPE'])
      ).length > 0;

    const param = {
      periodoDataInicial: periodo[0],
      periodoDataFinal: periodo[1],
      moduloId: -1,
      agenciaId: -1,
    };

    dispatch(
      PropostaActions.searchPropostasCanceladas({ ...param, usuarioId: usuario.matricula })
    );

    if (!isNoAdmin) param.usuarioId = '';

    setFiltros(param);
  }

  function onChangeFiltros(value, name) {
    setFiltros({ ...filtros, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    var _filtros = {
      ...filtros,
      cpfCnpj: filtros.cpfCnpj ? util.stringOnlyNumber(filtros.cpfCnpj) : null
    }

    dispatch(PropostaActions.searchPropostasCanceladas(_filtros));
  }

  function retornaAgenciasSelect() {
    return dominios.agenciasBrb 
      ? [{ agenciaId: -1, agenciaDescricao: 'TODAS'}].concat(dominios.agencias) 
      : [];
  }

  function formataCpfCnpj(valor) {
    if(!valor) return '';
    valor = Validation.isCNPJValid(valor) ? valor : valor.substring(3);
    return Mask.maskCpfCnpj(valor);
  }

  return (
    <>
      <ContentHeader
        title="PROPOSTAS CANCELADAS"
        hideClass="btn-Hide"
        breadText="Pesquisar"
        icon="home"
      />

      <Container style={{ margin: '0 16px' }}>
        <Row>
          <Col xs={{ span: 24 }}>
            <h6>Pesquisar Propostas Canceladas</h6>
            <Form
              onSubmit={handleSubmit}
              layout="vertical"
              className="ant-advanced-search-form"
            >
              <br />
              <br />
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }}>
                  <Form.Item label="Cpf">
                    <Input
                      placeholder="Cpf/Cnpj"
                      value={filtros.cpfCnpj}
                      name="cpfCnpj"
                      onChange={(v) => onChangeFiltros(v, 'cpfCnpj')}
                      mask="cpfCnpj"
                      autoComplete="off"
                      maxLength={18}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label className="w-100">Ramo</label>
                    <Select
                      itens={listaModulos}
                      itemValue="value"
                      itemText="desc"
                      value={filtros.moduloId}
                      onChange={(v) => onChangeFiltros(v, 'moduloId')}
                      showSearch
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                  <div className="form-group">
                    <label className="w-100">Agência</label>
                    <Select
                      defaultValue="Selecione"
                      itens={retornaAgenciasSelect()}
                      itemValue="agenciaId"
                      itemText="agenciaDescricao"
                      itemTextSearch="agenciaCodigo"
                      renderText={item =>
                        item.agenciaId === -1 
                          ? `${item.agenciaDescricao}`
                          : `${item.agenciaCodigo} - ${item.agenciaDescricao}`
                      }
                      value={filtros.agenciaId}
                      onChange={(v) => onChangeFiltros(v, 'agenciaId')}
                      showSearch
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                  <Form.Item label="Período">
                    <DatePicker
                      placeholder="Período"
                      value={periodo}
                      range
                      onChange={value => setPeriodo(value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                  <Form.Item label="&nbsp;">
                    <Button
                      type="primary"
                      className="btn-block"
                      htmlType="submit"
                    >
                      <SearchOutlined /> Pesquisar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Divider
              orientation="center"
              style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
            >
              Propostas encontradas
            </Divider>
            <Table
              rowKey="propostaEletronicaId"
              dataSource={propostaCanceladas}
              loading={loading || loadingSelected || dominios.loading}
              scroll={{ x: 1500 }}
              size="small"
            >
              <Column
                title="Ramo"
                width={130}
                dataIndex="ramoDescricao"
              />
              <Column
                title="CPF / CNPJ"
                width={120}
                dataIndex="cpfCnpj"
                render={(text, record) => formataCpfCnpj(record.cpfCnpj)}
              />
              <Column
                title="Proposta"
                width={150}
                dataIndex="proposta"
              />
              <Column
                title="Código Agência"
                dataIndex="agenciaCodigo"
                width={100}
              />
              <Column
                width={100}
                title="Agência"
                dataIndex="agenciaDescricao"
              />
              <Column
                width={150}
                title="Status"
                dataIndex="statusDescricao"
              />
              <Column
                width={80}
                title="Data Cancelamento"
                dataIndex="dataCancelamento"
                render={(text, record) => (
                  <span>
                    {moment
                      .utc(record.dataCancelamento)
                      .format('DD/MM/YYYY')}
                  </span>
                )}
              />
              <Column
                width={150}
                title="Valor"
                dataIndex="valor"
                render={(text, record) => (
                  <span>
                    { Functions.formatarMoeda(record.valor) }
                  </span>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
