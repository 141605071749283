import moment from 'moment';
import { Mask, Constants, Functions } from '~/utils';

export function formataPlanos(planos) {
  const planosAgrupado1 = [];
  if (planos && planos.length > 0) {
    // Unifica retorno duplicados devido a coberturas
    planos.forEach(p => {
      const p2 = {};
      p2.propostaEletronicaPlanoId = p.propostaEletronicaPlanoId;
      p2.propostaEletronicaPlanoSigla = p.propostaEletronicaPlanoSigla;
      p2.propostaEletronicaPlanoTipo = p.propostaEletronicaPlanoTipo;
      p2.propostaEletronicaPlanoCodParceiro =
        p.propostaEletronicaPlanoCodParceiro;
      p2.mtrPropostaEletronicaPlanoPremioTotal = Mask.maskMoney(
        p.mtrPropostaEletronicaPlanoPremioTotal
      );

      if (
        planosAgrupado1.filter(
          pl =>
            pl.propostaEletronicaPlanoSigla ===
              p.propostaEletronicaPlanoSigla &&
            pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
        ).length === 0
      ) {
        planosAgrupado1.push(p2);
      }
    });

    // Agrupa por coberturas de acordo com a nomenclatura do antigo PPE
    planos.forEach(p => {
      if (p.propostaEletronicaPlanoCoberturas) {
        p.propostaEletronicaPlanoCoberturas.forEach(pc => {
          planosAgrupado1.forEach(pl => {
            if (
              pl.propostaEletronicaPlanoSigla ===
                p.propostaEletronicaPlanoSigla &&
              pl.propostaEletronicaPlanoTipo ===
                p.propostaEletronicaPlanoTipo &&
              pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
            ) {
              pl.mtrIncendioQuedeDeRaioExplosao = Mask.maskMoney(
                pc.coberturaId === 202
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrIncendioQuedeDeRaioExplosao
              );
              pl.mtrPerdaDeAluguel = Mask.maskMoney(
                pc.coberturaId === 245 || pc.coberturaId === 571
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrPerdaDeAluguel
              );
              pl.mtrDanosEletricos = Mask.maskMoney(
                pc.coberturaId === 205
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrDanosEletricos
              );
              pl.mtrRouboDeBens = Mask.maskMoney(
                pc.coberturaId === 277
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRouboDeBens
              );
              pl.mtrRCFamiliar = Mask.maskMoney(
                pc.coberturaId === 220
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRCFamiliar
              );
              pl.mtrQuebraDeVidros = Mask.maskMoney(
                pc.coberturaId === 223
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrQuebraDeVidros
              );
              pl.mtrDespesasFixas = Mask.maskMoney(
                pc.coberturaId === 229
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrDespesasFixas
              );
              pl.mtrVendavalGranizo = Mask.maskMoney(
                pc.coberturaId === 451
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrVendavalGranizo
              );
              pl.mtrRcEstabComerciais = Mask.maskMoney(
                pc.coberturaId === 496
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRcEstabComerciais
              );
            }
          });
        });
      }
    });
  }

  return planosAgrupado1.sort((a, b) => {
    return a.propostaEletronicaPlanoSigla > b.propostaEletronicaPlanoSigla
      ? 1
      : -1;
  });
}

export function formataPropostaEnvio(
  planoSelected,
  bemEndereco,
  producao,
  cliente,
  dreMitsui,
  pagamento,
  regraComissaoCorretora,
  regraComissaoProdutor,
  endereco
) {
  const propostaEletronicaJsonContrato = formataPropostaJsonContrato(
    planoSelected,
    bemEndereco,
    producao,
    cliente,
    dreMitsui,
    pagamento,
    regraComissaoCorretora,
    regraComissaoProdutor
  );

  const propostaEletronicaJsonDemaisDados = {
    isProdutor: getIsProdutor(producao.isProdutor),
    isVendaRelacionada: producao.isVendaRelacionada,
    isOutrosSeguros: dreMitsui.isOutrosSeguros,
    tipoImovel: bemEndereco.tipoImovel,
    propostaEletronicaPlanoSigla: planoSelected.propostaEletronicaPlanoSigla,
    formaPagamentoTipo: pagamento.formaPagamento,
    isDebitoAutomatico: pagamento.isDebitoAutomatico,
    isBoleto: pagamento.isBoleto,
    isDebitoBrb: pagamento.isDebitoBrb,
    isDebitoOutrosBancos: pagamento.isDebitoOutrosBancos,
    formaPagamentoTipoCodParceiro: getFormaPagamentoTipoCodParceiro(pagamento),
    propostaEletronicaPlanoCodParceiro:
      planoSelected.propostaEletronicaPlanoCodParceiro,
    enderecoCorrepondencia: endereco,
    propClausulaBeneficiaria: dreMitsui.propClausulaBeneficiaria,
    propClausulaBeneficiariaCpfCnpj: dreMitsui.propClausulaBeneficiariaCpfCnpj,
  };

  return {
    clienteId: cliente.clienteId,
    cliente,
    produtoId: Constants.Produtos.DreMitsui,
    moduloId: Constants.Modulos.RISCO_DIVERSOS,
    propostaEletronicaPlanoId: planoSelected.propostaEletronicaPlanoId,
    propostaEletronicaJsonContrato: JSON.stringify(
      propostaEletronicaJsonContrato
    ),
    propostaEletronicaJsonDemaisDados: JSON.stringify(
      propostaEletronicaJsonDemaisDados
    ),
    propostaEletronicaDataCadastro: moment().format(),
    propostaEletronicaNossoNumero: pagamento.propostaEletronicaNossoNumero,
  };
}

function getIsProdutor(isProdutor) {
  if (isProdutor) 
    return isProdutor;
  else 
    return 2; // CONSULTOR
}

function getBem(planoSelected, bemEndereco) {
  const bemCoberturas = getBemCoberturas(planoSelected);

  return {
    bemCoberturas,
    bemEndereco: getBemEndereco(bemEndereco),
    mtrBemDREImportanciaSegurada: Functions.getMoney(
      planoSelected.mtrIncendioQuedeDeRaioExplosao
    ),
    objetoSeguradoId: bemEndereco.tipoImovel === 1 ? 10 : 20,
  };
}

function getBemCoberturas(planoSelected) {
  const bemCoberturas = [];
  if (planoSelected) {
    bemCoberturas.push({
      coberturaId: 202,
      mtrBemCoberturaValor: Functions.getMoney(
        planoSelected.mtrIncendioQuedeDeRaioExplosao
      ),
    });
    bemCoberturas.push({
      coberturaId: 245,
      mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrPerdaDeAluguel),
    });
    bemCoberturas.push({
      coberturaId: 205,
      mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrDanosEletricos),
    });
    bemCoberturas.push({
      coberturaId: 277,
      mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRouboDeBens),
    });
    bemCoberturas.push({
      coberturaId: 220,
      mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRCFamiliar),
    });
    bemCoberturas.push({
      coberturaId: 223,
      mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrQuebraDeVidros),
    });
  }
  return bemCoberturas;
}

function getBemEndereco(bemEndereco) {
  if (!bemEndereco) return null;
  return {
    bemEnderecoCep: Functions.getOnlyNum(bemEndereco.clienteEnderecoCep),
    bemEnderecoBairro: bemEndereco.clienteEnderecoBairro,
    bemEnderecoCidade: bemEndereco.clienteEnderecoCidade,
    bemEnderecoEndereco: bemEndereco.clienteEndereco1,
    bemEnderecoUf: bemEndereco.clienteEnderecoUf,
    BemEnderecoNumero: bemEndereco.clienteEnderecoNumero,
    BemEnderecoComplemento: bemEndereco.clienteEnderecoComplemento,
  };
}

function formataPropostaJsonContrato(
  planoSelected,
  bemEndereco,
  producao,
  cliente,
  dreMitsui,
  pagamento,
  regraComissaoCorretora,
  regraComissaoProdutor
) {
  const bem = getBem(planoSelected, bemEndereco);
  const premioTotal = Functions.getMoney(
    planoSelected.mtrPropostaEletronicaPlanoPremioTotal
  );

  const perIof = 7.38 / 100 + 1;
  const iof = parseFloat(premioTotal - premioTotal / perIof);
  const premioLiquido = parseFloat(premioTotal - iof);

  return {
    bens: [bem],
    contratoTipoId: producao.contratoTipoId,
    clienteId: cliente.clienteId,
    contratoDREDataInicio: dreMitsui.contratoDREDataInicio,
    contratoDREDataFim: dreMitsui.contratoDREDataFim,
    seguradoraId: 6602,
    contratoFormaPagamentoId: getFormaPagamento(pagamento),
    mtrContratoDRE1Parcela:
      pagamento.formaPagamento === 1
        ? premioTotal
        : pagamento.mtrContratoDRE1Parcela,
    mtrContratoDREPremioTotal: premioTotal,
    mtrContratoDREIof: iof,
    mtrContratoDREFracionado: 0,
    mtrContratoDREComissao:
      premioLiquido *
      regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
    contratoDREQtdParcela:
      pagamento.formaPagamento === 1 // À vista
        ? 0
        : pagamento.contratoDREQtdParcela,
    mtrContratoDREDemaisParcelas:
      pagamento.formaPagamento === 1 // À vista
        ? null
        : pagamento.mtrContratoDRE1Parcela,
    contratoDREVencimento1Parcela: moment().format(),
    contratoDREDiaVencimentoDemais:
      pagamento.formaPagamento === 1
        ? 0
        : pagamento.contratoDREDiaVencimentoDemais,
    debitoConta: {
      agencia: pagamento.agencia,
      agenciaId: pagamento.agencia?.agenciaId,
      produtoId: Constants.Produtos.DreMitsui,
      debitoContaNumeroConta: pagamento.debitoContaNumeroConta,
    },
    mtrContratoDREPremioLiquido: premioLiquido,
    mtrContratoDREComissaoPercent:
      regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
    contratoDREObservacao: 'PROPOSTA ELETRÔNICA NOVO PPE',
    ...producao,
    contratoDREDataCadastro: moment().format(),
    produtoId: Constants.Produtos.DreMitsui,
    tipoInclusaoId: 2,
    contratoStatusId: 1,
    contratoGrupoTipoId: 1,
    regraComissaoProdutor: regraComissaoProdutor.regraComissaoProdutor,
  };
}

function getFormaPagamentoTipoCodParceiro(pagamento) {
  const isDebito =
    pagamento.isDebitoAutomatico ||
    pagamento.isDebitoBrb ||
    pagamento.isDebitoOutrosBancos;
  if (pagamento.formaPagamento === 1) return isDebito ? 37221 : 37276;
  if (pagamento.formaPagamento === 2) {
    if (pagamento.contratoDREQtdParcela === 1) return isDebito ? 37220 : 37211;
    if (pagamento.contratoDREQtdParcela === 2) return isDebito ? 37222 : 37212;
    if (pagamento.contratoDREQtdParcela === 3) return isDebito ? 37223 : 37213;
    if (pagamento.contratoDREQtdParcela === 4) return isDebito ? 37224 : 37214;
    if (pagamento.contratoDREQtdParcela === 5) return isDebito ? 39322 : 39312;
    if (pagamento.contratoDREQtdParcela === 6) return isDebito ? 39323 : 39313;
    if (pagamento.contratoDREQtdParcela === 7) return isDebito ? 39324 : 39314;
    if (pagamento.contratoDREQtdParcela === 8) return isDebito ? 39325 : 39315;
    if (pagamento.contratoDREQtdParcela === 9) return isDebito ? 39326 : 39316;
  }
  if (pagamento.formaPagamento === 3) {
    if (pagamento.contratoDREQtdParcela === 1) return 37216;
    if (pagamento.contratoDREQtdParcela === 2) return 37217;
    if (pagamento.contratoDREQtdParcela === 3) return 37218;
    if (pagamento.contratoDREQtdParcela === 4) return 37219;
    if (pagamento.contratoDREQtdParcela === 5) return 39317;
    if (pagamento.contratoDREQtdParcela === 6) return 39318;
    if (pagamento.contratoDREQtdParcela === 7) return 39319;
    if (pagamento.contratoDREQtdParcela === 8) return 39320;
    if (pagamento.contratoDREQtdParcela === 9) return 39321;
  }

  return null;
}

function getFormaPagamento(pagamento) {
  if (pagamento.isDebitoBrb || pagamento.isDebitoOutrosBancos) {
    if (pagamento.formaPagamento === 1) return pagamento.isDebitoBrb ? 1 : 14;
    if (pagamento.formaPagamento === 2) return pagamento.isDebitoBrb ? 21 : 22;
    return pagamento.isDebitoBrb ? 5 : 13;
  }

  if (pagamento.formaPagamento === 1 && !pagamento.isBoleto) return 1; // A VISTA\DEBITO
  if (pagamento.formaPagamento === 1 && pagamento.isBoleto) return 6; // A VISTA\CREDITO
  if (pagamento.formaPagamento === 2 && pagamento.isBoleto) return 15; // FCA + DÉBITO
  if (pagamento.formaPagamento === 2 && pagamento.isDebitoAutomatico) return 21; // A VISTA\ DÉBITO + FRACION DÉBITO
  if (pagamento.formaPagamento === 3 && pagamento.isBoleto) return 5; //	FRACION\DEBITO

  return 5;
}
