import { createActions, handleActions } from 'redux-actions';

export const types = {
  GET_PLANOS: 'GET_PLANOS',
  GET_PLANOS_VPP: 'GET_PLANOS_VPP',
  GET_PLANOS_BRB_MASTER: 'GET_PLANOS_BRB_MASTER',
  SAVE_PLANOS: 'SAVE_PLANOS',
  FAILED_PLANOS: 'FAILED_PLANOS',
};

export const {
  getPlanos,
  getPlanosVpp,
  getPlanosBrbMaster,
  savePlanos,
  failedPlanos,
} = createActions(
  types.GET_PLANOS,
  types.GET_PLANOS_VPP,
  types.GET_PLANOS_BRB_MASTER,
  types.SAVE_PLANOS,
  types.FAILED_PLANOS
);

const defaultState = {
  loading: false,
  planos: [],
};

export default handleActions(
  {
    [getPlanos]: () => ({ loading: true, planos: [] }),
    [getPlanosVpp]: () => ({ loading: true, planos: [] }),
    [getPlanosBrbMaster]: () => ({ loading: true, planos: [] }),
    [savePlanos]: (state, action) => ({
      loading: false,
      planos: action.payload,
    }),
  },
  defaultState
);
