/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { Row, Col, Radio } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { TitleSection, Select, Input } from '~/components';

import { Mask, Functions } from '~/utils';

import * as PropostaActions from '~/store/ducks/proposta';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const formasPagamento = [
  { text: 'À vista', value: 1 },
  { text: 'Parcelado com entrada', value: 2 },
];

const qtdParcelas = [
  { value: 1, parcela: 2 },
  { value: 2, parcela: 3 },
  { value: 3, parcela: 4 },
  { value: 4, parcela: 5 },
  { value: 5, parcela: 6 },
  { value: 6, parcela: 7 },
  { value: 7, parcela: 8 },
  { value: 8, parcela: 9 },
  { value: 9, parcela: 10 },
];

export default function Pagamento() {
  // Component state
  const [label, setLabel] = useState('');
  const [propostaEletronicaNossoNumero, setNossoNumero] = useState();
  const [debitoContaNumeroConta, setNumeroConta] = useState();

  // Redux state
  const {
    planoSelected,
    pagamento,
    pagamento: {
      formaPagamento,
      contratoDREQtdParcela,
      contratoDREDiaVencimentoDemais,
      agencia,
    },
  } = useSelector(({ proposta }) => proposta.dreLibertyCeS);

  const { agenciasBrb } = useSelector(({ dominio }) => dominio);

  const dispatch = useDispatch();

  useEffect(() => {
    if (planoSelected) {
      if (formaPagamento === 1) {
        const valorVista = Functions.getMoney(
          planoSelected.mtrPropostaEletronicaPlanoPremioTotal);

        setLabel(
          `À Vista ${planoSelected.mtrPropostaEletronicaPlanoPremioTotal}`
        );

        onChangePagamento('mtrContratoDRE1Parcela', valorVista);
      } else if (formaPagamento === 2 || formaPagamento === 3) {
        const demaisParcelas =
          Functions.getMoney(
            planoSelected.mtrPropostaEletronicaPlanoPremioTotal
          ) /
          (contratoDREQtdParcela + 1);

        const num = formaPagamento === 2 ? 1 : 0;

        setLabel(
          `${num} + ${contratoDREQtdParcela +
          (num === 0 ? 1 : 0)} Parcela(s) SEM JUROS de ${Mask.maskMoney(
            demaisParcelas
          )}`
        );

        onChangePagamento('mtrContratoDRE1Parcela', demaisParcelas);
      }
    }
    setNossoNumero(pagamento.propostaEletronicaNossoNumero);
    setNumeroConta(pagamento.debitoContaNumeroConta);
  }, [planoSelected, formaPagamento, contratoDREQtdParcela]);

  function onChangeFormaPagamento({ target: { value } }) {
    onChangePagamento('formaPagamento', value);
  }

  function getMelhorDiaPagamento() {
    const list = [];
    for (let i = 1; i <= 31; i++) {
      list.push({ text: i, value: i });
    }

    return list;
  }

  function onChangePagamento(field, value) {
    // Valor default
    let newValue = { ...pagamento, [field]: value };
    // Se alterou a forma de pagamento
    if (field === 'formaPagamento') {
      // Se for a vista limpa a parcelas e demais dias
      if (value === 1) {
        newValue = {
          ...pagamento,
          formaPagamento: value,
          contratoDREQtdParcela: 1,
          contratoDREDiaVencimentoDemais: null,
        };
        // Se for parcelado sem entrada limpa o nosso numero
      } else if (value === 3) {
        newValue = {
          ...pagamento,
          formaPagamento: value,
          propostaEletronicaNossoNumero: null,
        };
      }
    }

    // Altera o pagamento no redux
    dispatch(
      PropostaActions.changeDreLibertyCeS({
        field: 'pagamento',
        value: newValue,
      })
    );
  }

  return (
    <>
      <TitleSection title="Forma de pagamento" />

      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={6}>
          <div className="form-group">
            <Radio.Group
              onChange={onChangeFormaPagamento}
              value={formaPagamento}
            >
              {formasPagamento.map(f => (
                <Radio key={f.value} style={radioStyle} value={f.value}>
                  {f.text}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <label style={{ fontSize: 12 }}>{label}</label>
        </Col>
        {formaPagamento !== 1 && (
          <>
            <Col span={6}>
              <div className="form-group">
                <label>Quantidade de Parcelas</label>
                <Select
                  itens={qtdParcelas}
                  itemText="parcela"
                  itemValue="value"
                  value={contratoDREQtdParcela}
                  onChange={value =>
                    onChangePagamento('contratoDREQtdParcela', value)
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div className="form-group">
                <label>Melhor dia para débito</label>
                <Select
                  itens={getMelhorDiaPagamento()}
                  itemText="text"
                  itemValue="value"
                  value={contratoDREDiaVencimentoDemais}
                  onChange={value =>
                    onChangePagamento('contratoDREDiaVencimentoDemais', value)
                  }
                />
              </div>
            </Col>
          </>
        )}
        {formaPagamento !== 3 && (
          <Col span={6}>
            <div className="form-group">
              <label>Proposta Nº</label>
              <Input
                placeholder="Proposta Nº"
                value={propostaEletronicaNossoNumero}
                onChange={value => setNossoNumero(value)}
                onBlur={value =>
                  onChangePagamento('propostaEletronicaNossoNumero', value)
                }
              />
            </div>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <div className="form-group">
            <label>Agência</label>
            <Select
              itens={agenciasBrb}
              itemValue="agenciaId"
              itemText="agenciaDescricao"
              itemTextSearch="agenciaCodigo"
              renderText={item =>
                `${item.agenciaCodigo} - ${item.agenciaDescricao}`
              }
              showSearch
              value={agencia?.agenciaId}
              onChange={value =>
                onChangePagamento(
                  'agencia',
                  agenciasBrb.find(a => a.agenciaId === value)
                )
              }
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="form-group">
            <label>Número Conta</label>
            <Input
              placeholder="Número Conta"
              value={debitoContaNumeroConta}
              onChange={value => setNumeroConta(value)}
              onBlur={value =>
                onChangePagamento('debitoContaNumeroConta', value)
              }
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
