import { Col, Row, Table } from 'antd';
import React from 'react';
import { Functions } from 'utils/index';

type BlocoSeguridadeProps = {
  listaBlocoSeguridades: Array<any>
};

export default function BlocoSeguridade({
  listaBlocoSeguridades
}: BlocoSeguridadeProps) {
  return (
    <Row>
      <Col xs={{ span: 24 }} style={{ marginTop: 8 }}>
        <Table
          dataSource={listaBlocoSeguridades}
          pagination={false}
          rowKey={'desc'}
          rowClassName={(r) => r.porcentagemRealizada < 100 ? 'letra-vermelha' : 'letra-azul'}
          scroll={{ x: 800 }}
        >
          <Table.Column
            title=""
            dataIndex="desc"
            align="center"
            fixed="left"
            width="130px"
            render={(x) => {

              return {
                props: {
                  style: { 'font-weight': 'bold' }
                },
                children: x
              }
            }}
          />
          <Table.Column
            title="Orçado"
            align="center"
            render={(x) => Functions.formatarMoeda(x.orcado)}
          />
          <Table.Column
            title="Realizado"
            align="center"
            render={(x) => Functions.formatarMoeda(x.realizado)}
          />
          <Table.Column
            title="Realizado %"
            align="center"
            render={(x) => Functions.formatarPorcentagem(x.porcentagemRealizada)}
          />
        </Table>
      </Col>
    </Row>
  );
}
