import { message as toast } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as DashboardApi from '~/api/dashboard.api';
import { getResponse } from '~/api/utils';
import * as DashboardActions from '~/store/ducks/dashboard';


function* getOrcadoAgenciaMes(action) {;

  const response = getResponse(
    yield call(DashboardApi.getListaOrcadoAgenciaMes, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveOrcadoAgenciaMes(data || []));
}

function* getRealizadoAgenciaMes(action) {

  const response = getResponse(
    yield call(DashboardApi.getListaRealizadoAgenciaMes, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveRealizadoAgenciaMes(data || []));
}

function* getOrcadoAgenciaPeriodo(action) {;

  const response = getResponse(
    yield call(DashboardApi.getListaOrcadoAgenciaPeriodo, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveOrcadoAgenciaPeriodo(data || []));
}

function* getRealizadoAgenciaPeriodo(action) {

  const response = getResponse(
    yield call(DashboardApi.getListaRealizadoAgenciaPeriodo, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveRealizadoAgenciaPeriodo(data || []));
}

function* getOrcadoOperadorMes(action) {;

  const response = getResponse(
    yield call(DashboardApi.getListaOrcadoOperadorMes, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveOrcadoOperadorMes(data || []));
}

function* getRealizadoOperadorMes(action) {

  const response = getResponse(
    yield call(DashboardApi.getListaRealizadoOperadorMes, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveRealizadoOperadorMes(data || []));
}

function* getOrcadoOperadorPeriodo(action) {;

  const response = getResponse(
    yield call(DashboardApi.getListaOrcadoOperadorPeriodo, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveOrcadoOperadorPeriodo(data || []));
}

function* getRealizadoOperadorPeriodo(action) {

  const response = getResponse(
    yield call(DashboardApi.getListaRealizadoOperadorPeriodo, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) yield call(fail, message);

  yield put(DashboardActions.saveRealizadoOperadorPeriodo(data || []));
}

function* fail(message) {
  yield put(DashboardActions.falha());
  toast.error(message);
}

export default all([
  takeLatest(DashboardActions.types.GET_ORCADO_AGENCIA_MES, getOrcadoAgenciaMes),
  takeLatest(DashboardActions.types.GET_REALIZADO_AGENCIA_MES, getRealizadoAgenciaMes),
  takeLatest(DashboardActions.types.GET_ORCADO_AGENCIA_PERIODO, getOrcadoAgenciaPeriodo),
  takeLatest(DashboardActions.types.GET_REALIZADO_AGENCIA_PERIODO, getRealizadoAgenciaPeriodo),
  takeLatest(DashboardActions.types.GET_ORCADO_OPERADOR_MES, getOrcadoOperadorMes),
  takeLatest(DashboardActions.types.GET_REALIZADO_OPERADOR_MES, getRealizadoOperadorMes),
  takeLatest(DashboardActions.types.GET_ORCADO_OPERADOR_PERIODO, getOrcadoOperadorPeriodo),
  takeLatest(DashboardActions.types.GET_REALIZADO_OPERADOR_PERIODO, getRealizadoOperadorPeriodo),
]);
