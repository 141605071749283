import api from '.';

export const getRegulamentoCampanha = () =>
  api().get(
    `api/joga-junto/regulamento-campanha`, null, { responseType: 'blob' }
  );

export const getSituacaoAgencia = () =>
  api().get(
    `api/joga-junto/situacao-agencia`, null, { responseType: 'blob' }
  );
