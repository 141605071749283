import { message as toast } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '~/api/residencial-liberty-personalizavel.api';
import { getResponse, getResponseFilePdfStringBase64 } from '~/api/utils';
import { store } from '~/store';
import * as ClienteActions from '~/store/ducks/cliente';
import libertyResidencialPersonalizavel, * as LibertyPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';
import * as PropostaActions from '~/store/ducks/proposta';

const CANAL_VENDA = {
  CNP: 13,
  AGENCIA_DIGITAL: 14,
};

function* imprimirBoleto(param) {
  
  const { payload } = param;

  // Call api
  const response = getResponseFilePdfStringBase64(yield call(Api.imprimirBoleto, payload), `Boleto_Proposta_${payload}`);
  const { ok, messages } = response;
  let { message } = response;

  if (!ok) {
    message = retornaMensagemErroBoleto(messages, message);
    yield call(fail, message);
    return;
  }

  // tirar loading da tela
  yield put(LibertyPersonalizavelActions.limparCarregando());
}

function retornaMensagemErroBoleto(messages, message) {
  if(!messages || !messages.length) return message;

  const firstMessage = messages[0];

  return (firstMessage ?? '').includes('Boleto não disponivel para impressão.')
    ? firstMessage
    : message;
}

function* getListaDominios(param) {
  
  const response = getResponse(
    yield call(Api.getNomesDominios, param)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(LibertyPersonalizavelActions.saveListaDominios(data));
}

function* getDominioById(action) {;

  const response = getResponse(
    yield call(Api.getDominioByDominioParceiroNomeId, { dominioParceiroNomeId: action.payload })
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(LibertyPersonalizavelActions.saveDominio(data || []));
}

function* cotar(action) {
  // Parâmetro
  const { param, callbackErro } = action.payload;
  // Id da proposta
  const { propostaId } = store.getState().proposta;
  
  // Chama a api
  const response = getResponse(yield call(Api.cotar, param));

  // Recebe o retorno
  const { ok, data, message, messages } = response;

  // Verifica se houve erro
  if (!ok) {
    if (callbackErro) callbackErro(messages);
    yield call(fail, message);
    return;
  }

  // Exibe mensagem ao usuário
  toast.success(message);

  if (data) {
    yield put(LibertyPersonalizavelActions.saveCotar(data));
    yield put(PropostaActions.saveSuccess(data));
    yield put(LibertyPersonalizavelActions.changeFieldState({ field: 'messagesInfo', value: messages }));
    yield put(LibertyPersonalizavelActions.reiniciarPagamentoEscolhido());
    yield put(ClienteActions.changeCliente({
      field: 'clienteId', value: data.clienteId
    }));
  }
}

function* efetivar(action) {
  // Parâmetro
  const { param, callback, callbackErro } = action.payload;
 
  const { usuario } = store.getState().auth;

  // Adiciona o canal de venda para todos os contratos efetivados
  const { propostaEletronicaJsonContrato } = param;
  const jsonContratoObj = JSON.parse(propostaEletronicaJsonContrato);
  jsonContratoObj.CanalVendaId = CANAL_VENDA[usuario.descTipoProdutor] || 10; // Canal de Venda do Seguridade
  param.propostaEletronicaJsonContrato = JSON.stringify(jsonContratoObj);

  // Chama a api para efetivar
  const response = getResponse(yield call(Api.efetivar, param));
  // Recebe o retorno
  const { ok, data, message, messages } = response;
  // Verifica se houve erro
  if (!ok) {
    if (callbackErro) callbackErro(messages);
    yield call(fail, message);
    return;
  }
  // Exibe mensagem ao usuário
  // toast.success(message);

  // Salva o retorno
  yield put(
    LibertyPersonalizavelActions.successRetornoEfetivar({})
  );

  yield put(
    PropostaActions.efetivarSuccess({
      id: data.contratoId,
      numeroControle: data.numeroControle,
    })
  );

  if(callback) callback();
}

function* fail(message) {
  yield put(LibertyPersonalizavelActions.falha());
  toast.error(message);
}

export default all([
  takeLatest(LibertyPersonalizavelActions.types.GET_LISTA_DOMINIOS, getListaDominios),
  takeLatest(LibertyPersonalizavelActions.types.GET_DOMINIO_BY_ID, getDominioById),
  takeLatest(LibertyPersonalizavelActions.types.EFETIVA_PROPOSTA, efetivar),
  takeLatest(LibertyPersonalizavelActions.types.COTAR, cotar),
  takeLatest(LibertyPersonalizavelActions.types.IMPRESSAO_BOLETO_RESIDENCIAL_LIBERTY, imprimirBoleto)
]);
