import { call, all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse } from '~/api/utils';
import * as DominiosApi from '~/api/dominio.api';
import * as DominiosActions from '~/store/ducks/dominio';

import { store } from '~/store';

function* listDominio(action) {
  const { param: dominio } = action.payload;
  // Call api
  const response = getResponse(yield call(DominiosApi[dominio]));
  const { ok, data } = response;
  if (ok) yield put(DominiosActions.saveDominio({ dominio, data }));
}

function* listAllDominios(action) {
  const callback = action.payload;

  const many = getResponse(yield call(DominiosApi.allDominios));

  if (many.ok) yield put(DominiosActions.saveMany(many.data));
  else if (many.message === 'Sua sessão expirou.') toast.error(many.message);
  else
    toast.error(
      'Tivemos uma falha ao conectar com o servidor, por favor atualize a página.'
    );

  const { usuario } = store.getState().auth;

  const [consultores, angariadores, equipes, angariadoresCnps] = yield all([
    call(DominiosApi.consultores),
    usuario?.descTipoProdutor === 'AGENCIA_DIGITAL'
      ? call(DominiosApi.produtorByTipos, 'tipos=28')
      : call(DominiosApi.angariadores),
    call(DominiosApi.equipes),
    call(DominiosApi.angariadoresCnps, usuario?.agenciaId),
  ]);

  if (consultores.ok)
    yield put(
      DominiosActions.saveDominio({
        dominio: 'consultores',
        data: consultores.data.data,
      })
    );
  if (angariadores.ok)
    yield put(
      DominiosActions.saveDominio({
        dominio: 'angariadores',
        data: angariadores.data.data,
      })
    );
  if (equipes.ok)
    yield put(
      DominiosActions.saveDominio({
        dominio: 'equipes',
        data: equipes.data.data,
      })
    );
  if (angariadoresCnps.ok)
    yield put(
      DominiosActions.saveDominio({
        dominio: 'angariadoresCnps',
        data: angariadoresCnps.data.data,
      })
    );

    const [consultoresDeSeguros] = yield all([
      call(DominiosApi.produtorByTipos, 'tipos=16&tipos=29&tipos=44'),
    ]);
    if (consultoresDeSeguros.ok)
    yield put(
      DominiosActions.saveDominio({
        dominio: 'consultoresDeSeguros',
        data: consultoresDeSeguros.data.data,
      })
    );

  if (callback) callback();
}

export default all([
  takeEvery(DominiosActions.types.LIST_DOMINIO, listDominio),
  takeLatest(DominiosActions.types.LIST_ALL, listAllDominios),
]);
