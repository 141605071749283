import React, { useEffect, useState } from 'react';
import { PlusOutlined, PrinterOutlined, ArrowLeftOutlined, SaveOutlined, CheckOutlined } from '@ant-design/icons';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import Button from "antd-button-color";
import {
  Row,
  Col,
  Radio,
  Table,
  Spin,
  BackTop,
  Result,
  Popconfirm,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  TitleSection,
  FieldsProducao,
  Container,
  DatePicker,
  FieldsEndereco,
  Select,
  Input,
  Error,
  Disable,
} from '~/components';

import Pagamento from './pagamento';

import {
  ProducaoValidation,
  PropostaValidation,
  PagamentoValidation,
} from '~/yup';

import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PlanoActions from '~/store/ducks/plano';

import { Constants, Mask, Functions } from '~/utils';

type EmpresarialLibertyProps = {
  onReset: Function,
  onPrevius: Function,
};

export default function EmpresarialLiberty({
  onReset,
  onPrevius,
}: EmpresarialLibertyProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    dreLibertyCeS,
    dreLibertyCeS: {
      planoSelected,
      bemEndereco,
      pagamento,
      isOutrosSeguros,
      propostaEletronicaPlanoId,
    },
    producao,
    loading: loadingProposta,
    propostaId,
    contratoId,
  } = useSelector(state => state.proposta);
  const { planos, loading } = useSelector(state => state.plano);
  const { cliente, endereco } = useSelector(state => state.cliente);
  const { regraComissaoCorretora, regraComissaoProdutor } = useSelector(
    state => state.producao
  );

  const {
    propostaEletronicaAtividades,
    propostaEletronicaAtividadesPJ,
  } = useSelector(({ dominio }) => dominio);

  // Component state
  const [planosAgrupado, setPlanosAgrupado] = useState([]);
  const [planoSelectedKey, setPlanoSelectedKey] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [atividadesPj, setAtividadesPj] = useState(
    propostaEletronicaAtividadesPJ
  );
  const [erros, setErros] = useState([]);

  useEffect(() => {
    getRegraComissaoCorretora();
    if (bemEndereco && bemEndereco.tipoImovel)
      onChangeTipoImovel(bemEndereco.tipoImovel);
  }, []);

  useEffect(() => {
    if (propostaEletronicaAtividades) {
      const a = propostaEletronicaAtividades.filter(
        c =>
          c.propostaEletronicaPlanoTipoId === bemEndereco?.tipoImovel &&
          c.seguradoraId === 5185
      );

      //  Se não encontrou a atividade na lista filtrada, limpa da tela
      if (
        a.find(
          c =>
            c.propostaEletronicaAtividadeId ===
            dreLibertyCeS.propostaEletronicaAtividadeId &&
            c.seguradoraId === 5185
        ) === undefined
      ) {
        dreLibertyCeS.propostaEletronicaAtividadeId = undefined;
      }

      setAtividades(a);
    }
  }, [bemEndereco?.tipoImovel]);

  useEffect(() => {
    const planosAgrupado1 = [];

    if (planos && planos.length > 0) {
      // Unifica retorno duplicados devido a coberturas
      planos.forEach(p => {
        const p2 = {};
        p2.propostaEletronicaPlanoId = p.propostaEletronicaPlanoId;
        p2.propostaEletronicaPlanoSigla = p.propostaEletronicaPlanoSigla;
        p2.propostaEletronicaPlanoTipo = p.propostaEletronicaPlanoTipo;
        p2.propostaEletronicaPlanoCodParceiro =
          p.propostaEletronicaPlanoCodParceiro;
        p2.mtrPropostaEletronicaPlanoPremioTotal = Mask.maskMoney(
          p.mtrPropostaEletronicaPlanoPremioTotal
        );

        if (
          planosAgrupado1.filter(
            pl =>
              pl.propostaEletronicaPlanoSigla ===
                p.propostaEletronicaPlanoSigla &&
              pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
          ).length === 0
        ) {
          planosAgrupado1.push(p2);
        }
      });

      planos.forEach(p => {
        p.propostaEletronicaPlanoCoberturas.forEach(pc => {
          planosAgrupado1.forEach(pl => {
            if (
              pl.propostaEletronicaPlanoSigla ===
                p.propostaEletronicaPlanoSigla &&
              pl.propostaEletronicaPlanoTipo ===
                p.propostaEletronicaPlanoTipo &&
              pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
            ) {
              pl.mtrIncendioQuedeDeRaioExplosao = Mask.maskMoney(
                pc.coberturaId === 202
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrIncendioQuedeDeRaioExplosao
              );
              pl.mtrPerdaDeAluguel = Mask.maskMoney(
                pc.coberturaId === 245 || pc.coberturaId === 571
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrPerdaDeAluguel
              );
              pl.mtrDanosEletricos = Mask.maskMoney(
                pc.coberturaId === 205
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrDanosEletricos
              );
              pl.mtrRouboDeBens = Mask.maskMoney(
                pc.coberturaId === 277
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRouboDeBens
              );
              pl.mtrRCFamiliar = Mask.maskMoney(
                pc.coberturaId === 220
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRCFamiliar
              );
              pl.mtrQuebraDeVidros = Mask.maskMoney(
                pc.coberturaId === 223
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrQuebraDeVidros
              );
              pl.mtrDespesasFixas = Mask.maskMoney(
                pc.coberturaId === 229
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrDespesasFixas
              );
              pl.mtrVendavalGranizo = Mask.maskMoney(
                pc.coberturaId === 451
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrVendavalGranizo
              );
              pl.mtrRcEstabComerciais = Mask.maskMoney(
                pc.coberturaId === 496
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRcEstabComerciais
              );
            }
          });
        });
      });

      setPlanosAgrupado(planosAgrupado1);
    }
  }, [planos]);

  useEffect(() => {
    if (planoSelected) {
      const index = planosAgrupado
        .map(e => e.propostaEletronicaPlanoId)
        .indexOf(planoSelected.propostaEletronicaPlanoId);
      setPlanoSelectedKey([index]);
    } else if (propostaEletronicaPlanoId) {
      const index = planosAgrupado
        .map(e => e.propostaEletronicaPlanoId)
        .indexOf(propostaEletronicaPlanoId);
      setPlanoSelectedKey([index]);
      onSelectedPlano(planosAgrupado[index]);
    }
  }, [planosAgrupado]);

  function getRegraComissaoCorretora() {
    const data = {
      produtoId: Constants.Produtos.DreLibertyCeS,
      seguradoraId: Constants.Seguradoras.Liberty,
    };
    dispatch(ProducaoActions.getRegraComissaoCorretora(data));
  }

  function changeField(field, value) {
    dispatch(PropostaActions.changeDreLibertyCeS({ field, value }));
  }

  function onChangeEnderecoLocalRisco(value) {
    dispatch(
      PropostaActions.changeDreLibertyCeS({ field: 'bemEndereco', value })
    );
  }

  function onChangeTipoImovel(tipoImovel) {
    const param = {
      seguradoraId: Constants.Seguradoras.Liberty,
      produtoId: Constants.Produtos.DreLibertyCeS,
      tipoImovel,
    };

    dispatch(PlanoActions.getPlanos({ param }));
  }

  function showCoberturas(coberturaId) {
    let isShow = false;
    if (planos) {
      planos.forEach(p => {
        p.propostaEletronicaPlanoCoberturas.forEach(pc => {
          if (pc.coberturaId === coberturaId) {
            isShow = true;
          }
        });
      });
    }

    return isShow;
  }

  function onSelectedPlano(value) {
    dispatch(
      PropostaActions.changeDreLibertyCeS({
        field: 'planoSelected',
        value,
      })
    );
  }

  async function onSalvar() {
    setErros([]);
    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();

      dispatch(PropostaActions.save({ param }));
    } else setErros(isValid);
  }

  async function validaProposta() {
    let response = await validaObjeto(
      ProducaoValidation.ProducaoValidationDre,
      producao
    );
    if (!response.ok) return response;

    response = await validaObjeto(
      PropostaValidation.DreLibertyCeS,
      dreLibertyCeS
    );
    if (!response.ok) return response;

    if (pagamento.formaPagamento === 1)
      response = await validaObjeto(PagamentoValidation.AVista, pagamento);
    else if (pagamento.formaPagamento === 2)
      response = await validaObjeto(
        PagamentoValidation.ParceladoCEntrada,
        pagamento
      );
    else
      response = await validaObjeto(
        PagamentoValidation.ParceladoSEntrada,
        pagamento
      );

    if (!response.ok) return response;

    return { ok: true };
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }
  function getObjetoEnvio() {
    if (!planoSelected) return null;
    if (!endereco.clienteEnderecoId) cliente.clienteEnderecos.push(endereco);

    const bemCoberturas = [];
    if (planoSelected) {
      bemCoberturas.push({
        coberturaId: 202,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrIncendioQuedeDeRaioExplosao
        ),
      });
      bemCoberturas.push({
        coberturaId: 245,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrPerdaDeAluguel
        ),
      });
      bemCoberturas.push({
        coberturaId: 205,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrDanosEletricos
        ),
      });
      bemCoberturas.push({
        coberturaId: 277,
        mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRouboDeBens),
      });
      bemCoberturas.push({
        coberturaId: 220,
        mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRCFamiliar),
      });
      bemCoberturas.push({
        coberturaId: 223,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrQuebraDeVidros
        ),
      });
    }

    const bem = {
      bemCoberturas,
      bemEndereco: getBemEndereco(),
      mtrBemDREImportanciaSegurada: Functions.getMoney(
        planoSelected.mtrIncendioQuedeDeRaioExplosao
      ),
      objetoSeguradoId: bemEndereco.tipoImovel === 3 ? 50 : 30,
    };

    const premioTotal = Functions.getMoney(
      planoSelected.mtrPropostaEletronicaPlanoPremioTotal
    );

    const perIof = 7.38 / 100 + 1;
    const iof = parseFloat(premioTotal - premioTotal / perIof);
    const premioLiquido = parseFloat(premioTotal - iof);

    const propostaEletronicaJsonContrato = {
      bens: [bem],
      contratoTipoId: producao.contratoTipoId,
      clienteId: cliente.clienteId,
      contratoDREDataInicio: dreLibertyCeS.contratoDREDataInicio,
      contratoDREDataFim: dreLibertyCeS.contratoDREDataFim,
      seguradoraId: Constants.Seguradoras.Liberty,
      contratoFormaPagamentoId: getFormaPagamento(),
      mtrContratoDRE1Parcela:
        pagamento.formaPagamento === 1
          ? premioTotal
          : pagamento.mtrContratoDRE1Parcela,
      mtrContratoDREPremioTotal: premioTotal,
      mtrContratoDREIof: iof,
      mtrContratoDREFracionado: 0,
      mtrContratoDREComissao:
        premioLiquido *
        regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
      contratoDREQtdParcela:
        pagamento.formaPagamento === 1 // À vista
          ? 0
          : pagamento.contratoDREQtdParcela,
      mtrContratoDREDemaisParcelas:
        pagamento.formaPagamento === 1 // À vista
          ? null
          : pagamento.mtrContratoDRE1Parcela,
      contratoDREVencimento1Parcela: moment()
          .add(1, 'M')
          .format(),
      contratoDREDiaVencimentoDemais:
        pagamento.formaPagamento === 1
          ? 0
          : pagamento.contratoDREDiaVencimentoDemais,
      debitoConta: {
        agencia: pagamento.agencia,
        agenciaId: pagamento.agencia?.agenciaId,
        produtoId: Constants.Produtos.DreLibertyCeS,
        debitoContaNumeroConta: pagamento.debitoContaNumeroConta,
      },
      mtrContratoDREPremioLiquido: premioLiquido,
      mtrContratoDREComissaoPercent:
        regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
      contratoDREObservacao: 'PROPOSTA ELETRÔNICA NOVO PPE',
      ...producao,
      contratoDREDataCadastro: moment().format(),
      produtoId: Constants.Produtos.DreLibertyCeS,
      tipoInclusaoId: 2,
      contratoStatusId: 1,
      contratoGrupoTipoId: 1,
      regraComissaoProdutor: regraComissaoProdutor.regraComissaoProdutor,
    };

    const propostaEletronicaJsonDemaisDados = {
      isProdutor: producao.isProdutor,
      isOutrosSeguros: dreLibertyCeS.isOutrosSeguros,
      tipoImovel: bemEndereco.tipoImovel,
      propostaEletronicaPlanoSigla: planoSelected.propostaEletronicaPlanoSigla,
      formaPagamentoTipo: pagamento.formaPagamento,
      formaPagamentoTipoCodParceiro: getFormaPagamentoTipoCodParceiro(),
      propostaEletronicaPlanoCodParceiro:
        planoSelected.propostaEletronicaPlanoCodParceiro,
      inspecaoRiscoNome: dreLibertyCeS.inspecaoRiscoNome,
      inspecaoRiscoDdd: dreLibertyCeS.inspecaoRiscoDdd,
      inspecaoRiscoTelefone: dreLibertyCeS.inspecaoRiscoTelefone,
      inspecaoRiscoRamal: dreLibertyCeS.inspecaoRiscoRamal,
      proponenteNome: dreLibertyCeS.proponenteNome,
      proponenteCpfCnpj: dreLibertyCeS.proponenteCpfCnpj,
      propostaEletronicaAtividadeId:
        dreLibertyCeS.propostaEletronicaAtividadeId,
      propostaEletronicaAtividadePJId:
          dreLibertyCeS.propostaEletronicaAtividadePJId,
      enderecoCorrepondencia: endereco,
    };

    const objetoEnvio = {
      clienteId: cliente.clienteId,
      cliente,
      produtoId: Constants.Produtos.DreLibertyCeS,
      moduloId: Constants.Modulos.RISCO_DIVERSOS,
      propostaEletronicaPlanoId: planoSelected.propostaEletronicaPlanoId,
      propostaEletronicaJsonContrato: JSON.stringify(
        propostaEletronicaJsonContrato
      ),
      propostaEletronicaJsonDemaisDados: JSON.stringify(
        propostaEletronicaJsonDemaisDados
      ),
      propostaEletronicaDataCadastro: moment().format(),
      propostaEletronicaNossoNumero: pagamento.propostaEletronicaNossoNumero,
    };

    return objetoEnvio;
  }
  function getFormaPagamentoTipoCodParceiro() {
    if (pagamento.formaPagamento === 1) return 37276;
    if (pagamento.formaPagamento === 2) {
      if (pagamento.contratoDREQtdParcela === 1) return 37211;
      if (pagamento.contratoDREQtdParcela === 2) return 37212;
      if (pagamento.contratoDREQtdParcela === 3) return 37213;
      if (pagamento.contratoDREQtdParcela === 4) return 37214;
    }
    if (pagamento.formaPagamento === 3) {
      if (pagamento.contratoDREQtdParcela === 1) return 37216;
      if (pagamento.contratoDREQtdParcela === 2) return 37217;
      if (pagamento.contratoDREQtdParcela === 3) return 37218;
      if (pagamento.contratoDREQtdParcela === 4) return 37219;
    }

    return null;
  }

  function getBemEndereco() {
    if (!bemEndereco) return null;
    return {
      bemEnderecoCep: Functions.getOnlyNum(bemEndereco.clienteEnderecoCep),
      bemEnderecoBairro: bemEndereco.clienteEnderecoBairro,
      bemEnderecoCidade: bemEndereco.clienteEnderecoCidade,
      bemEnderecoEndereco: bemEndereco.clienteEndereco1,
      bemEnderecoUf: bemEndereco.clienteEnderecoUf,
      BemEnderecoNumero: bemEndereco.clienteEnderecoNumero,
      BemEnderecoComplemento: bemEndereco.clienteEnderecoComplemento,
    };
  }
  function getFormaPagamento() {
    if (pagamento.formaPagamento === 1) return 6;
    if (pagamento.formaPagamento === 2) return 15;
    return 5;
  }

  async function onEfetivar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }

    if (
      dreLibertyCeS.propostaEletronicaAtividadeId === undefined ||
      dreLibertyCeS.propostaEletronicaAtividadeId === null
    ) {
      message.error('É obrigatório informar a Atividade.');
      return;
    }

    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();

      dispatch(
        PropostaActions.efetivar({ param, callback: callbackErrorEfetivar })
      );
    } else setErros(isValid);
  }

  function callbackErrorEfetivar(values) {
    setErros(values);
  }
  function Botoes() {
    return (
      <div className="actions-tabs" style={{ paddingBottom: 10 }}>
        <Button size="sm" onClick={onPrevius} with="link" type="primary">
        <ArrowLeftOutlined/>  Voltar
        </Button>
        
        {!contratoId && (
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            onClick={onSalvar}
          >
            <SaveOutlined />  Salvar
          </Button>
        )}
        {propostaId > 0 && !contratoId && (
          <Popconfirm
            placement="topLeft"
            title="Deseja realmente efetivar esse contrato?"
            onConfirm={onEfetivar}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              size="sm"
              type="info"
              style={{
                marginLeft: '.5rem',
                
              }}
            >
              <CheckOutlined />  Efetivar
            </Button>
          </Popconfirm>
        )}
        {propostaId > 0 && (
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            // onClick={imprimir}
            target="_blank"
            href="/impressao-dre-ces"
          >
            <PrinterOutlined /> Imprimir
          </Button>
        )}
      </div>
    );
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }
 
  return (
    <>
      <BackTop />
      <Spin spinning={loadingProposta} size="large">
        <Botoes />
        <Container>
          {contratoId !== null && contratoId > 0 && (
            <Result
              status="success"
              title="Parabéns, produto contratado com sucesso!"
              subTitle={`O número do contrato no Multiseguros é: ${contratoId} e você já pode consultá-lo lá.`}
              extra={[
                <Button
                  className="btn btn-xs-block mb-2 mb-sm-0"
                  type="primary"
                  href={`https://multiseguros${
                    Functions.isProducao() ? '' : 'hmo'
                  }.brbseguros.com.br/#/proposta-dre/${contratoId}/11804`}
                  target="_blank"
                >
                  Ir ao Multiseguros
                </Button>,
              ]}
            />
          )}

          <Error erros={erros} />

          <Disable disable={!!contratoId}>
            <h4>Empresarial Comércio e Serviços Liberty</h4>

            <FieldsProducao produto="empresarial-liberty" showIsProdutor />

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <div className="form-group">
                  <label>Início da Vigência</label> <br />
                  <DatePicker
                    value={dreLibertyCeS.contratoDREDataInicio}
                    placeholder="Selecione"
                    onChange={value => {
                      changeField('contratoDREDataInicio', value);
                      changeField(
                        'contratoDREDataFim',
                        moment(value).add(1, 'y')
                      );
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="form-group">
                  <label>Fim da Vigência</label> <br />
                  <DatePicker
                    value={dreLibertyCeS.contratoDREDataFim}
                    placeholder="Selecione"
                    onChange={value => changeField('contratoDREDataFim', value)}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="form-group">
                  <label>Há outros seguros cobrindo os mesmos bens?</label>{' '}
                  <br />
                  <Radio.Group
                    onChange={({ target: { value } }) =>
                      changeField('isOutrosSeguros', value)
                    }
                    value={isOutrosSeguros}
                  >
                    <Radio value={1}>Sim</Radio>
                    <Radio value={0}>Não</Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>

            <FieldsEndereco
              title="Local de Risco"
              onChangeEndereco={onChangeEnderecoLocalRisco}
              enderecoRedux={dreLibertyCeS.bemEndereco}
              showTipoImovel
              onChangeTipoImovel={onChangeTipoImovel}
              produtoId={Constants.Produtos.DreLibertyCeS}
            />
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <div className="form-group">
                  <label>Atividades</label>
                  <Select
                    defaultValue="Selecione"
                    itens={atividades}
                    itemValue="propostaEletronicaAtividadeId"
                    itemText="propostaEletronicaAtividadeDescricao"
                    itemTextSearch="propostaEletronicaAtividadeDescricao"
                    value={dreLibertyCeS.propostaEletronicaAtividadeId}
                    onChange={value =>
                      changeField('propostaEletronicaAtividadeId', value)
                    }
                    showSearch
                  />
                </div>
              </Col> 
              <Col span={6}>
                <div className="form-group">
                  <label>Atividade PJ</label>
                  <Select
                    defaultValue="Selecione"
                    itens={atividadesPj}
                    itemValue="propostaEletronicaAtividadePJId"
                    itemText="propostaEletronicaAtividadePJDescricao"
                    itemTextSearch="propostaEletronicaAtividadePJDescricao"
                    value={dreLibertyCeS.propostaEletronicaAtividadePJId}
                    onChange={value =>
                      changeField('propostaEletronicaAtividadePJId', value)
                    }
                    showSearch
                  />
                </div>
              </Col>
            </Row>
            <TitleSection title="Dados Inspeção de Risco" />
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-group">
                  <label>Nome</label>
                  <Input
                    placeholder="Nome"
                    value={dreLibertyCeS.inspecaoRiscoNome}
                    onChange={value => {
                      changeField('inspecaoRiscoNome', value);
                    }}
                  />
                </div>
              </Col>
              <Col span={2}>
                <div className="form-group">
                  <label>DDD</label>
                  <Input
                    placeholder="DDD"
                    value={dreLibertyCeS.inspecaoRiscoDdd}
                    onChange={value => {
                      changeField('inspecaoRiscoDdd', value);
                    }}
                    accept="onlyNumbers"
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="form-group">
                  <label>Telefone</label>
                  <Input
                    placeholder="Telefone"
                    value={dreLibertyCeS.inspecaoRiscoTelefone}
                    onChange={value => {
                      changeField('inspecaoRiscoTelefone', value);
                    }}
                    accept="onlyNumbers"
                  />
                </div>
              </Col>
              <Col span={2}>
                <div className="form-group">
                  <label>Ramal</label>
                  <Input
                    placeholder="Ramal"
                    value={dreLibertyCeS.inspecaoRiscoRamal}
                    onChange={value => {
                      changeField('inspecaoRiscoRamal', value);
                    }}
                    accept="onlyNumbers"
                  />
                </div>
              </Col>
            </Row>
            <TitleSection title="Dados Proponente" />
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-group">
                  <label>Cláusula beneficiária a favor de</label>
                  <Input
                    placeholder="Cláusula beneficiária a favor de.."
                    value={dreLibertyCeS.proponenteNome}
                    onChange={value => {
                      changeField('proponenteNome', value);
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="form-group">
                  <label>CPF/CNPJ</label>
                  <Input
                    placeholder="CPF/CNPJ"
                    value={dreLibertyCeS.proponenteCpfCnpj}
                    mask="cpfCnpj"
                    onChange={value => {
                      changeField('proponenteCpfCnpj', value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Disable>
          <TitleSection title="Opções de planos" />
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col span={24}>
              <Table
                scroll={{ x: 1000 }}
                dataSource={planosAgrupado}
                pagination={false}
                loading={loading}
                size="small"
                rowSelection={{
                  type: 'radio',
                  onChange: (selectedRowKeys, selectedRows) => {
                    onSelectedPlano(selectedRows[0]);
                    setPlanoSelectedKey(selectedRowKeys);
                  },
                  selectedRowKeys: planoSelectedKey,
                  getCheckboxProps: () => ({
                    disabled: !!contratoId,
                  }),
                }}
                title={() => (
                  <span>
                    Os planos serão exibidos de acordo com tipo de imóvel
                    informado
                  </span>
                )}
              >
                {planosAgrupado.length > 0 && (
                  <Table.Column
                    title="Plano"
                    dataIndex="propostaEletronicaPlanoSigla"
                  />
                )}
                {showCoberturas(202) && (
                  <Table.Column
                    title="Incêndio/ Queda de Raio/ Explosão"
                    dataIndex="mtrIncendioQuedeDeRaioExplosao"
                  />
                )}
                {showCoberturas(245) && (
                  <Table.Column
                    title="Perda de Aluguel"
                    dataIndex="mtrPerdaDeAluguel"
                  />
                )}
                {showCoberturas(205) && (
                  <Table.Column
                    title="Danos Elétricos"
                    dataIndex="mtrDanosEletricos"
                  />
                )}
                {showCoberturas(277) && (
                  <Table.Column
                    title="Roubo de Bens"
                    dataIndex="mtrRouboDeBens"
                  />
                )}
                {showCoberturas(220) && (
                  <Table.Column title="RC Familiar" dataIndex="mtrRCFamiliar" />
                )}
                {showCoberturas(223) && (
                  <Table.Column
                    title="Quebra de Vidros"
                    dataIndex="mtrQuebraDeVidros"
                  />
                )}
                {showCoberturas(229) && (
                  <Table.Column
                    title="Despesas Fixas"
                    dataIndex="mtrDespesasFixas"
                  />
                )}
                {showCoberturas(451) && (
                  <Table.Column
                    title="Vendaval Granizo e Impacto de Veículos"
                    dataIndex="mtrVendavalGranizo"
                  />
                )}
                {showCoberturas(496) && (
                  <Table.Column
                    title="RC Estab. Comerciais"
                    dataIndex="mtrRcEstabComerciais"
                  />
                )}
                {planosAgrupado.length > 0 && (
                  <Table.Column
                    title="Prêmio"
                    dataIndex="mtrPropostaEletronicaPlanoPremioTotal"
                  />
                )}
              </Table>
            </Col>
          </Row>
          <span><i>Remuneração pela intermediação de 50% sobre o prêmio</i></span>
          <Disable disable={!!contratoId}>
            <Pagamento />
          </Disable>
        </Container>
        <Botoes />
      </Spin>
    </>
  );
}
