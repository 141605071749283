import { createActions, handleActions } from 'redux-actions';
import { callback } from './auth';

export const types = {
  INIT_CLIENTE: 'INIT_CLIENTE',
  GET_BY_CPF: 'GET_BY_CPF',
  GET_BY_ID: 'GET_BY_ID',
  SAVE_CLIENTE: 'SAVE_CLIENTE',
  SAVE_ENDERECO: 'SAVE_ENDERECO',
  CHANGE_CLIENTE: 'CHANGE_CLIENTE',
  CHANGE_ENDERECO: 'CHANGE_ENDERECO',
  GET_PROFISSAO_LIBERTY_BY_DESCRICAO: 'GET_PROFISSAO_LIBERTY_BY_DESCRICAO',
  GET_CONTAS_CONTRATO: 'GET_CONTAS_CONTRATO',
  SAVE_CONTAS_CONTRATO: 'SAVE_CONTAS_CONTRATO',
  GET_SOLICITACAO_ALTERACAO_CONTA: 'GET_SOLICITACAO_ALTERACAO_CONTA',
  SAVE_SOLICITACAO_ALTERACAO_CONTA: 'SAVE_SOLICITACAO_ALTERACAO_CONTA',
  POST_CONTA_CONTRATO: 'POST_CONTA_CONTRATO',
  UPDATE_CLIENT_AFTER_SAVE: 'UPDATE_CLIENT_AFTER_SAVE',
  POST_APROVA_ALTERACAO_CONTA: 'POST_APROVA_ALTERACAO_CONTA',
  FAILED_CLIENTE: 'FAILED_CLIENTE',
  SUCCESS_CLIENTE: 'SUCCESS_CLIENTE',
};

export const {
  initCliente,
  getByCpf,
  getById,
  saveCliente,
  saveEndereco,
  changeCliente,
  changeEndereco,
  getProfissaoLibertyByDescricao,
  getContasContrato,
  saveContasContrato,
  getSolicitacaoAlteracaoConta,
  saveSolicitacaoAlteracaoConta,
  postContaContrato,
  postAprovaAlteracaoConta,
  updateClientAfterSave,
  failedCliente,
  successCliente,
} = createActions(
  types.INIT_CLIENTE,
  types.GET_BY_CPF,
  types.GET_BY_ID,
  types.SAVE_CLIENTE,
  types.SAVE_ENDERECO,
  types.CHANGE_CLIENTE,
  types.CHANGE_ENDERECO,
  types.GET_PROFISSAO_LIBERTY_BY_DESCRICAO,
  types.GET_CONTAS_CONTRATO,
  types.SAVE_CONTAS_CONTRATO,
  types.GET_SOLICITACAO_ALTERACAO_CONTA,
  types.SAVE_SOLICITACAO_ALTERACAO_CONTA,
  types.POST_CONTA_CONTRATO,
  types.POST_APROVA_ALTERACAO_CONTA,
  types.UPDATE_CLIENT_AFTER_SAVE,
  types.FAILED_CLIENTE,
  types.SUCCESS_CLIENTE
);

const defaultState = {
  cliente: null,
  endereco: {},
  contasContrato: [],
  solicitacaoAlteracaoConta: [],
  loading: false,
};

export default handleActions(
  {
    [initCliente]: () => defaultState,
    [getByCpf]: state => ({ ...state, loading: true }),
    [saveCliente]: (state, action) => ({
      ...state,
      loading: false,
      cliente: action.payload,
      endereco: {},
    }),
    [saveEndereco]: (state, action) => ({
      ...state,
      endereco: action.payload,
    }),
    [changeCliente]: (state, action) => ({
      ...state,
      cliente: {
        ...state.cliente,
        [action.payload.field]: action.payload.value,
      },
    }),
    [getProfissaoLibertyByDescricao]: state => ({ ...state, loading: true }),
    [getContasContrato]: state => ({ ...state, loading: true }),
    [saveContasContrato]: (state, action) => {
      
      return {
        ...state,
        loading: false,
        contasContrato: [...action.payload]
      }
    },
    [getSolicitacaoAlteracaoConta]: (callback) => ({ callback, loading: true }),
    [saveSolicitacaoAlteracaoConta]: (state, action) => {
      
      return {
        ...state,
        loading: false,
        solicitacaoAlteracaoConta: [...action.payload]
      }
    },

    [postContaContrato]: (param, callback) => ({ param, callback, loading: true }),
    [postAprovaAlteracaoConta]: (param, callback) => ({ param, callback, loading: true }),

    [changeEndereco]: (state, action) => ({
      ...state,
      endereco: {
        ...state.endereco,
        [action.payload.field]: action.payload.value,
      },
    }),
    [updateClientAfterSave]: (state, action) => ({
      ...state,
      cliente: {
        ...state.cliente,
        ...action.payload.cliente,
      },
    }),
    [successCliente]: state => ({ ...state, loading: false }),
    [failedCliente]: state => ({ ...state, loading: false }),
  },
  defaultState
);
