import { createActions, handleActions } from 'redux-actions';

export const types = {
  GERAR_LINK_LOGIN: 'GERAR_LINK_LOGIN',
  FAILED_COTAGRO: 'FAILED_COTAGRO',
};

export const {
  gerarLinkLogin,
  failedCotagro,
} = createActions(
  types.GERAR_LINK_LOGIN,
  types.FAILED_COTAGRO,
);

const defaultState = {
  loading: false,
};

export default handleActions(
  {
    [failedCotagro]: state => ({ ...state, loading: false }),
    [gerarLinkLogin]: state => ({
      ...state,
      loading: true
    }),
  },
  defaultState
);
