import * as yup from 'yup';

const ProducaoValidationDefault = yup.object().shape({
  contratoTipoId: yup.number().required('Por favor, informe o tipo de seguro.'),
  angariadorId: yup.number().required('Por favor, informe o angariador.'),
  consultorId: yup.number().required('Por favor, informe o consultor.'),
  equipeId: yup
    .string()
    .required('Por favor, informe a equipe.')
    .nullable(),
  agenciaId: yup.number().required('Por favor, informe a agência.'),
});

const ProducaoValidationDre = ProducaoValidationDefault.concat(
  yup.object().shape({
    isProdutor: yup.string().required('Por favor, informe o produtor'),
  })
);

export default {
  ProducaoValidationDre,
  ProducaoValidationDefault,
};
