import { Modal, Tabs } from 'antd';
import Switch from 'components/switch';
import React from 'react';

import AutomovelDetalhe from './automovel-detalhe';
import CapitalizacaoDetalhe from './capitalizacao-detalhe';
import ClienteTab from './cliente-tab';
import ConsorcioDetalhe from './consorcio-detalhe';
import DreDetalhe from './dre-detalhe';
import FeriasDetalhe from './ferias-detalhe';
import HabitacionalDetalhe from './habitacional-detalhe';
import PrestamistaDetalhe from './prestamista-detalhe';
import PrevidenciaDetalhe from './previdencia-detalhe';
import SaudeDetalhe from './saude-detalhe';
import VidaDetalhe from './vida-detalhe';

const { TabPane } = Tabs;

type ModalDetalhesProp = {
  visible?: Boolean,
  width?: String | Number,
  onOk?: Function,
  onCancel?: Functionu,
  data?: DataModal
}

type DataModal = {
  cliente?: Object,
  tipo?: String,
  contrato?: Object
}

export default function ModalDetalhes(param: ModalDetalhesProp) {

  return (
    <>
      <Modal
        centered
        visible={param.visible}
        onOk={param.onOk}
        onCancel={param.onOk}
        cancelButtonProps={{ hidden: true }}
        width={param.width}
        style={{padding: '15px'}}
      >

        <Tabs defaultActiveKey="1">
          <TabPane tab="Cliente" key="1">
            <ClienteTab data={param.data.cliente}></ClienteTab>
          </TabPane>
          <TabPane tab="Detalhes Contrato" key="2">
            <Switch valorTeste={ param?.data?.tipo }>
              <VidaDetalhe valueSwitch="VIDA" data={param?.data?.contrato}></VidaDetalhe>
              <CapitalizacaoDetalhe valueSwitch="CAPITALIZAÇÃO" data={param?.data?.contrato}></CapitalizacaoDetalhe>
              <FeriasDetalhe valueSwitch="FÉRIAS" data={param?.data?.contrato}></FeriasDetalhe>
              <SaudeDetalhe valueSwitch="SAÚDE" data={param?.data?.contrato}></SaudeDetalhe>
              <PrevidenciaDetalhe valueSwitch="PREVIDÊNCIA" data={param?.data?.contrato}></PrevidenciaDetalhe>
              <ConsorcioDetalhe valueSwitch="CONSÓRCIO" data={param?.data?.contrato}></ConsorcioDetalhe>
              <HabitacionalDetalhe valueSwitch="HABITACIONAL" data={param?.data?.contrato}></HabitacionalDetalhe>
              <PrestamistaDetalhe valueSwitch="PRESTAMISTA" data={param?.data?.contrato}></PrestamistaDetalhe>
              <DreDetalhe valueSwitch="DRE" data={param?.data?.contrato}></DreDetalhe>
              <AutomovelDetalhe valueSwitch="AUTOMÓVEL" data={param?.data?.contrato}></AutomovelDetalhe>
            </Switch>
          </TabPane>
        </Tabs>

      </Modal>
    </>
  );
};