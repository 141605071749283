import { PieConfig } from '@ant-design/charts/es/pie';
import { Functions } from 'utils';

function retornaConfig(dataGrafico, valorGraficoRealizado, valorGraficoFaltante) {
  var config: PieConfig = {
    appendPadding: 0,
    data: dataGrafico,
    // Texto apresentado após o nome da seção quando passa o mouse sobre a seção
    meta: {
      valor: {
        formatter: function formatter(valor) {
          return Functions.formatarMoeda(valor || 0);
        },
      },
    },
    // Configuração de coloração por setor
    color: (config) => {
      return (dataGrafico.find(x => x.descricao === config.descricao) || { cor: '' }).cor
    },
    angleField: 'valor',
    colorField: 'descricao',
    radius: 1,
    innerRadius: 0.9,
    // Configuração da legenda do gráfico
    legend: {
      layout: 'horizontal',
      position: 'bottom',
    },
    // Configuração de texto por setor
    label: {
      type: 'inner',
      offset: '-280%',
      content: (x) => {
        return x.valor ? Functions.formatarMoeda(x.valor) : '';
      },
      autoRotate: false,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'black',
        fontWeight: 600,
        fontFamily: 'Times New Roman'
      },
    },
    // Configuração de texto no centro do gráfico
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '1rem',
          fontFamily: 'Times New Roman'
        },
        customHtml: function formatter() {
          return retornaValorTotalRealizadoAgencia(valorGraficoRealizado, valorGraficoFaltante);
        },
      },
    },
  };

  return config;
}

function retornaValorTotalRealizadoAgencia(valor, valorFaltante) {
  return `<div><div>
    <span>Realizado</span>
    <span>${Functions.formatarMoeda(valor)}</span>
  </div>
  <div style='color: #ec2540; margin-top: -50px'>
    <span>GAP </span>
    <span>${Functions.formatarMoeda(valorFaltante)}</span>
  </div></div>`;
}

export default {
  retornaConfig
}