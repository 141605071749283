import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Row, Spin, Table } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, ContentHeader, Error, Input } from '~/components';
import * as Actions from '~/store/ducks/cliente';
import * as utils from '~/utils';
import ContaDetalhe from './components/conta-detalhe';
import './styles.css';

const { Column } = Table;

export default function AlteraConta() {
  const dispatch = useDispatch();

  const { contasContrato, loading } = useSelector(({ cliente }) => cliente);
  const [contrato, setContrato] = useState({});
  const [filtros, setFiltros] = useState({ quantidadePorPagina: 10, paginaAtual: 1, ativo: true });
  const [modalVisible, setModalVisible] = useState(false);
  const [erros, setErros] = useState([]);

  function onChangeFiltros(value, name) {
    setFiltros({ ...filtros, [name]: value });
  }

  function handleSubmit(e) {
    if (e) e.preventDefault();
    if (preencheuCpf()) {
      dispatch(Actions.getContasContrato({ param: filtros.cpfCnpj }));
    }
  }

  function preencheuCpf() {
    setErros([]);
    const ok = (filtros.cpfCnpj && filtros.cpfCnpj.trim());

    if (!ok) {
      setErros([
        'Preencha o CPF/CNPJ do Cliente!'
      ]);

      return false;
    }

    return true;
  }

  function tratarChangeTable(paginacao) {
    if (preencheuCpf()) dispatch(Actions.getContasContrato({ ...filtros, paginaAtual: paginacao.current }));
  }

  function handleRowClick(record) {
    setContrato(record);
    setModalVisible(true);
  }

  return (
    <>
      <ContentHeader
        title="ALTERAR CONTA"
        hideClass="btn-Hide"
        breadText="Pesquisar"
        icon="search"
      />

      <Spin
        spinning={loading}
        size="small"
        tip="Aguarde carregando..."
      >

        <Container style={{ margin: '0 16px' }}>
          <Error erros={erros} />

          <ContaDetalhe
            width="90%"
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            visible={modalVisible}
            data={{ contrato: contrato }}
          />

          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <h6>Pesquisa Contratos Cliente</h6>
              <Form
                onSubmit={handleSubmit}
                layout="vertical"
                className="ant-advanced-search-form"
              >
                <Divider orientation="center" style={{ color: '#333' }} />
                <Row gutter={[16, 0]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }}>
                    <Form.Item label="Cpf">
                      <Input
                        placeholder="Cpf/Cnpj"
                        value={filtros.cpfCnpj}
                        name="cpfCnpj"
                        onChange={onChangeFiltros}
                        mask="cpfCnpj"
                        maxLength={18}
                        id="validating"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }}>
                    <Form.Item label="&nbsp;">
                      <Button
                        type="primary"
                        className="btn-block"
                        htmlType="submit"
                      >
                        <SearchOutlined /> Pesquisar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col>
              <Divider
                orientation="center"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Listagem de Contratos do Cliente
              </Divider>
              <Table
                rowKey={(r, i) => i.toString()}
                dataSource={contasContrato}
                onChange={tratarChangeTable}
                loading={!!(loading || []).length}
                scroll={{ x: 1500 }}
              >

                <Column
                  align="center"
                  title="Nome"
                  width={150}
                  dataIndex="clienteNome"
                  render={(text, record) => (
                    <span className="spn-link" onClick={() => handleRowClick(record)}>
                      {text}
                    </span>
                  )}
                />

                <Column
                  align="center"
                  title="CPF / CNPJ"
                  width={100}
                  dataIndex="clienteCpfCnpj"
                  render={(text, record) => (
                    <Button
                      size="small"
                      type="link"
                      onClick={() => handleRowClick(record)}
                    >
                      {utils.Mask.maskCpfCnpj(text)}
                    </Button>
                  )}
                />

                <Column
                  align="center"
                  title="Carteira"
                  dataIndex="ramo"
                  width={80}
                />

                <Column
                  align="center"
                  title="Apolice"
                  dataIndex="apolice"
                  width={100}
                />

                <Column
                  align="center"
                  title="Conta"
                  dataIndex="conta"
                  width={80}
                />

                <Column
                  width={100}
                  align="center"
                  title="Seguradora"
                  dataIndex="seguradora"
                />
              </Table>
            </Col>
          </Row>
        </Container>
      </Spin>
    </>
  );
}
