import { all } from 'redux-saga/effects';

import AuthSagas from './auth';
import ClienteSagas from './cliente';
import DominiosSagas from './dominio';
import DneSagas from './dne';
import PropostaSagas from './proposta';
import PlanosSagas from './plano';
import ProducaoSagas from './producao';
import JogaJuntoSagas from './joga-junto';
import DashboardSagas from './dashboard';
import PesquisaGeral from './pesquisa-geral';
import ResidencialLibertyPersonalizavel from './liberty-residencial-personalizavel';
import BrbMasterSagas from './brb-master';
import LoginSeguradorasSagas from './login-seguradoras';
import AgendaPositivaSagas from './agenda-positiva';
import AcumuloRiscoSagas from './acumulo-risco';
import CotagroSagas from './cotagro';

export default function* rootSaga() {
  yield all([
    AuthSagas,
    ClienteSagas,
    DominiosSagas,
    DneSagas,
    PropostaSagas,
    PlanosSagas,
    ProducaoSagas,
    JogaJuntoSagas,
    DashboardSagas,
    PesquisaGeral,
    ResidencialLibertyPersonalizavel,
    BrbMasterSagas,
    LoginSeguradorasSagas,
    AgendaPositivaSagas,
    AcumuloRiscoSagas,
    CotagroSagas
  ]);
}
