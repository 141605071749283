import { createActions, handleActions } from 'redux-actions';

export const types = {
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  FAILED_LOGIN_SEGURADORAS: 'FAILED_LOGIN_SEGURADORAS',
};

export const {
  downloadFile,
  failedLoginSeguradoras,
} = createActions(
  types.DOWNLOAD_FILE,
  types.FAILED_LOGIN_SEGURADORAS,
);

const defaultState = {
  loading: false,
};

export default handleActions(
  {
    [failedLoginSeguradoras]: state => ({ ...state, loading: false }),
  },
  defaultState
);
