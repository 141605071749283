import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { DeleteOutlined, PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import * as ClienteActions from '~/store/ducks/cliente';
import { Select, Input } from '~/components/';

const telefoneScheme = yup.object().shape({
  clienteContatoFoneNumero: yup
    .string()
    .required('Número de telefone obrigatório')
    .test('clienteContatoFoneNumero', 'Telefone não é válido', function v(
      value
    ) {
      if (!value) return false;
      value = value.replace(/\D/g, '');

      if (value.length < 3) return false;

      value = value.substring(2, value.length);

      if (value.startsWith('9') && value.length === 9) return true;
      if (!value.startsWith('9') && value.length === 8) return true;

      return false;
    })
    .nullable(),
  clienteContatoFoneTipoId: yup
    .string()
    .required('Tipo de telefone obrigatório'),
});

type SelectTelefoneConfigProps = {
  showOptionToSelectTelefones: Boolean,
  isMultiSelectTelefones: Boolean,
  textToolTipSelectTelefone: string,
  textHeaderSelectTelefone: string,
  onSelectTelefone: Function,
  selectedTelefonesList: Array<String>
}

export default function Telefone({
  showOptionToSelectTelefones,
  isMultiSelectTelefones,
  textToolTipSelectTelefone,
  textHeaderSelectTelefone,
  onSelectTelefone,
  selectedTelefonesList
}: SelectTelefoneConfigProps) {
  // Redux state
  const { contatoFoneTipos } = useSelector(({ dominio }) => dominio);

  const { clienteContatoFones } = useSelector(({ cliente }) => cliente.cliente);

  // Component state
  const [contato, setContato] = useState({});
  const [selectedTelefones, setSelectedTelefones] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedTelefones(selectedTelefonesList ?? [])
  }, []);

  function onAddTelefone() {
    telefoneScheme
      .validate(contato, { abortEarly: false })
      .then(() => {
        if (checkNumeroExist()) {
          contato.clienteContatoFoneNumero = contato.clienteContatoFoneNumero.replace(
            /\D/g,
            ''
          );
          clienteContatoFones.push(contato);
          updateTelefonesCliente();
          setContato({});
        }
      })
      .catch(onError);
  }

  function onDeleteTelefone(index) {
    clienteContatoFones.splice(index, 1);
    updateTelefonesCliente();
  }

  function updateTelefonesCliente() {
    dispatch(
      ClienteActions.changeCliente({
        field: 'clienteContatoFones',
        value: clienteContatoFones,
      })
    );
  }

  function checkNumeroExist() {
    const tel = clienteContatoFones.find(
      t =>
        t.clienteContatoFoneNumero.toString() ===
        contato.clienteContatoFoneNumero
    );
    if (tel) {
      message.error('O número informado já está cadastrado.');
      return false;
    }

    return true;
  }

  function onError(error) {
    let erros = '';
    error.errors.map(e => (erros += `• ${e} \n`));
    message.error(erros);
  }

  function _onSelectTelefone(telefone) {

    let telefonesList = [...(selectedTelefones ?? [])];

    if(!isMultiSelectTelefones)
      telefonesList = [telefone]
    else
      telefonesList.push(telefone)
    
    setSelectedTelefones(telefonesList);
    if(onSelectTelefone) onSelectTelefone(telefonesList);
  }

  function removeSelectedTelefone(telefone) {

    let telefonesList = [...(selectedTelefones ?? [])];

    var indexRemove = telefonesList.indexOf(telefone);

    if(indexRemove > -1)
      telefonesList.splice(indexRemove, 1);
    
    setSelectedTelefones(telefonesList);
    if(onSelectTelefone) onSelectTelefone(telefonesList);
  }  


  function telefoneEstaSelecionado(telefone) {
    return (selectedTelefones ?? []).some(x => x === telefone);
  }

  return (
    <Card title="Telefones">
      <Row gutter={[16, 40]}>
        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <div className="form-group">
            <label>Número</label>
            <Input
              placeholder="Número de telefone com DDD"
              value={contato.clienteContatoFoneNumero}
              onChange={clienteContatoFoneNumero =>
                setContato({ ...contato, clienteContatoFoneNumero })
              }
              mask="phone"
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 4 }}>
          <div className="form-group">
            <label>Ramal</label>
            <Input
              placeholder="Ramal"
              value={contato.clienteContatoFoneRamal}
              onChange={clienteContatoFoneRamal =>
                setContato({ ...contato, clienteContatoFoneRamal })
              }
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 9 }}>
          <div className="form-group">
            <label>Tipo</label>
            <Select
              placeholder="Selecione"
              itens={contatoFoneTipos}
              itemText="clienteContatoFoneTipoDescricao"
              itemValue="clienteContatoFoneTipoId"
              showSearch
              value={contato.clienteContatoFoneTipoId}
              onChange={clienteContatoFoneTipoId => {
                const t = contatoFoneTipos.find(
                  cft =>
                    cft.clienteContatoFoneTipoId === clienteContatoFoneTipoId
                );
                setContato({
                  ...contato,
                  clienteContatoFoneTipoId,
                  clienteContatoFoneTipo: t,
                });
              }}
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 2 }} style={{ marginTop: 29 }}>
          <Button type="primary" onClick={onAddTelefone}>
            <PlusOutlined /> Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} style={{ marginTop: 29 }}>
          <Table
            rowKey={(el, index) => index}
            dataSource={clienteContatoFones}
            pagination={false}
            scroll={{ x: 500 }}
          >
            <Table.Column title="Número" dataIndex="clienteContatoFoneNumero" />
            <Table.Column title="Ramal" dataIndex="clienteContatoFoneRamal" />
            <Table.Column
              title="Tipo"
              dataIndex="clienteContatoFoneTipo.clienteContatoFoneTipoDescricao"
              key="custo"
            />
            <Table.Column
              title="Ações"
              align="center"
              render={(text, reco, index) => (
                <Button
                  size="small"
                  type="danger"
                  onClick={() => onDeleteTelefone(index)}
                >
                  <DeleteOutlined /> Excluir
                </Button>
              )}
            />
            {
              !!showOptionToSelectTelefones &&
              <Table.Column
                title={ textHeaderSelectTelefone ?? 'Selecionar' }
                align={ 'center' }
                render={(row) => (
                 <>
                    {
                      !telefoneEstaSelecionado(row.clienteContatoFoneNumero)
                      ? <CheckCircleOutlined  
                        title={textToolTipSelectTelefone ?? 'Selecionar'} 
                        style={{fontSize: '20px'}} 
                        onClick={() => _onSelectTelefone(row.clienteContatoFoneNumero)} 
                      />
                      : 
                      <>
                        <CheckCircleOutlined style={{color: 'green', fontSize: '20px', margin: '5px' }} />
                        <CloseCircleOutlined style={{color: '#ff4d4f', fontSize: '20px', margin: '5px'}} onClick={() => removeSelectedTelefone(row.clienteContatoFoneNumero)} />
                      </>

                    }
                  </>
                )}
            />
            }
          </Table>
        </Col>
      </Row>
    </Card>
  );
}
