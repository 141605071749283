import { message as toast } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as CotagroApi from '~/api/cotagro.api';
import { getResponse } from '~/api/utils';
import * as CotagroActions from '~/store/ducks/cotagro';

function* gerarLinkLogin(action) {
  const { param, callback } = action.payload;

  // Call api
  const response = getResponse(yield call(CotagroApi.gerarLinkLogin, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    callback(data);
    return;
  }

  if (callback) callback(data);
}

function* fail(message) {
  yield put(CotagroActions.failedCotagro());
  toast.error(message);
}

export default all([
  takeLatest(CotagroActions.types.GERAR_LINK_LOGIN, gerarLinkLogin),
]);
