import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Table } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
    TitleSection
} from '~/components';
import { Input } from '~/components/';
import * as Actions from '~/store/ducks/liberty-residencial-personalizavel';


const telefoneScheme = yup.object().shape({
  numeroTelefone: yup
    .string()
    .required('Número de telefone obrigatório')
    .test('numeroTelefone', 'Telefone não é válido', function v(
      value
    ) {
      if (!value) return false;
      value = value.replace(/\D/g, '');

      if (value.length < 3) return false;

      value = value.substring(2, value.length);

      if (value.startsWith('9') && value.length === 9) return true;
      if (!value.startsWith('9') && value.length === 8) return true;

      return false;
    })
    .nullable(),
  nomeContato: yup
    .string()
    .required('Nome do contato obrigatório'),
});

export default function ContatosInspecao() {
  
  const { libertyResidencialPersonalizavel: {
    efetivacao
  } } = useSelector(state => state);

  // Component state
  const [contato, setContato] = useState({});

  const dispatch = useDispatch();

  function onAddTelefone() {
    telefoneScheme
      .validate(contato, { abortEarly: false })
      .then(() => {
        if (checkNumeroExist()) {
          contato.numeroTelefone = contato.numeroTelefone.replace(
            /\D/g,
            ''
          );
          var listaContatos = [...efetivacao.listaContatosInspecao]
          listaContatos.push(contato);
          updateTelefonesCliente(listaContatos);
          setContato({});
        }
      })
      .catch(onError);
  }

  function onDeleteTelefone(index) {
    var listaContatos = [...efetivacao.listaContatosInspecao]
    listaContatos.splice(index, 1);
    updateTelefonesCliente(listaContatos);
  }

  function updateTelefonesCliente(lista) {
    var obj = {
        ...efetivacao,
        listaContatosInspecao: [...lista]
    }

    dispatch(
        Actions.changeFieldState({
        field: 'efetivacao',
        value: obj,
      })
    );
  }

  function checkNumeroExist() {
    const tel = (efetivacao.listaContatosInspecao ?? []).find(
      t =>
        t.numeroTelefone.toString() ===
        contato.numeroTelefone
    );
    if (tel) {
      message.error('O número informado já está cadastrado.');
      return false;
    }

    return true;
  }

  function onError(error) {
    let erros = '';
    (error.errors ?? []).map(e => (erros += `• ${e} \n`));
    message.error(erros);
  }

  return (
    <>
        <TitleSection title="Contatos para inspeção" />
        <Row gutter={[16, 40]}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <div className="form-group">
                <label>Número</label>
                <Input
                placeholder="Número de telefone com DDD"
                value={contato.numeroTelefone}
                onChange={numeroTelefone =>
                    setContato({ ...contato, numeroTelefone })
                }
                mask="phone"
                />
            </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 10 }}>
            <div className="form-group">
                <label>Nome Contato</label>
                <Input
                placeholder="Nome contato"
                value={contato.nomeContato}
                onChange={nomeContato =>
                    setContato({ ...contato, nomeContato })
                }
                />
            </div>
            </Col>
            {
                (efetivacao.listaContatosInspecao ?? []).length < 3 && (
                    <>
                        <Col xs={{ span: 24 }} sm={{ span: 2 }} style={{ marginTop: 29 }}>
                            <Button type="primary" onClick={onAddTelefone}>
                                <PlusOutlined /> Add
                            </Button>
                        </Col>
                    </>
                )
            }
        </Row>
        {
          !!(efetivacao.listaContatosInspecao ?? []).length && (
              <>
                <Row>
                    <Col xs={{ span: 24 }} style={{ marginTop: 29 }}>
                        <Table
                            rowKey="numeroTelefone"
                            dataSource={efetivacao.listaContatosInspecao}
                            pagination={false}
                            scroll={{ x: 500 }}
                        >
                            <Table.Column title="Número" dataIndex="numeroTelefone" />
                            <Table.Column title="Nome do contato" dataIndex="nomeContato" />
                            <Table.Column
                            title="Ações"
                            align="center"
                            render={(text, reco, index) => (
                                <Button
                                size="small"
                                type="danger"
                                onClick={() => onDeleteTelefone(index)}
                                >
                                <DeleteOutlined /> Excluir
                                </Button>
                            )}
                            />
                        </Table>
                    </Col>
                </Row>
              </>
          )
      }
    </>
  );
}
