import React, { useState, useEffect } from 'react';
import { Steps, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import history from '~/services/history';
import { ContentHeader } from '~/components';

import * as PlanoActions from '~/store/ducks/plano';
import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as DominiosActions from '~/store/ducks/dominio';
import * as LibertyPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';

import Produtos from './produtos';
import DadosCliente from './dados-cliente';
import Planos from './acidentes-pessoais-liberty/planos';

import EmpresarialLiberty from './empresarial-liberty';
import ResidencialMitsui from './residencial-mitsui';
import ResidencialPersonalizavelLiberty from './residencial-personalizavel-liberty';
import VppAmerican from './vpp-american';
import ResidencialZurich from './residencial-zurich';
import DpsAtividades from './vpp-american/DpsAtividades';
import EmpresarialZurich from './empresarial-zurich';
import EmpresarialMitsui from './empresarial-mitsui';
import Beneficiario from './acidentes-pessoais-liberty/beneficiario';
import Pagamento from './acidentes-pessoais-liberty/pagamento';
import DadosProducao from './acidentes-pessoais-liberty/producao';
import CapitalizacaoIcatu from './capitalizacao-icatu';
import { Constants } from '~/utils';
import BrbMasterPremiado from './brb-master-premiado';

import { Content } from './styles';
import 'antd/dist/antd.css';
import './styles.css';

const steps = [
  {
    title: 'Produto',
    produtosVisiveis: [11402, 11407, 11802, 11804, 11806, 99310, 138104, 11409, 139113],
  },
  {
    title: 'Cliente',
    produtosVisiveis: [11402, 11407, 11802, 11804, 11806, 99310, 138104, 11409, 139113],
  },
  {
    title: 'Dados Producao',
    produtosVisiveis: [138104],
  },
  {
    title: 'Imóvel Cotação',
    produtosVisiveis: [11409]
  },
  {
    title: 'Coberturas',
    produtosVisiveis: [11409]
  },
  {
    title: 'Efetivação',
    produtosVisiveis: [11409]
  },
  {
    title: 'Escolha do plano',
    produtosVisiveis: [138104],
  },
  {
    title: 'Beneficiários',
    produtosVisiveis: [138104],
  },
  {
    title: 'Pagamentos',
    produtosVisiveis: [138104],
  },
  {
    title: 'Dados complementares',
    produtosVisiveis: [11402, 11407, 11802, 11804, 11806, 99310, 139113],
  },
  {
    title: 'DPS / ATIVIDADES',
    produtosVisiveis: [99310],
  },
];

const produtos = {
  99310: VppAmerican,
  11407: ResidencialMitsui,
  11804: EmpresarialLiberty,
  11402: ResidencialZurich,
  11802: EmpresarialZurich,
  11806: EmpresarialMitsui,
  161139: CapitalizacaoIcatu, // 24
  161144: CapitalizacaoIcatu, // 48
  161142: CapitalizacaoIcatu, // 60
  // 161143: CapitalizacaoIcatu,// 84
  161137: CapitalizacaoIcatu, // PU
  138104: Pagamento, // Utilizado pois
  11409: ResidencialPersonalizavelLiberty,
  139113: BrbMasterPremiado
};

export default function Proposta() {
  // Redux State
  const produtoId = useSelector(({ proposta }) => proposta.produtoId);
  const dominios = useSelector(({ dominio }) => dominio);
  // Component State
  const [current, setCurrent] = useState(0);
  const [produto, setProduto] = useState(99310);

  const { type } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !dominios.horaCarregada ||
      moment(Date.now())
        .add(-2, 'hour')
        .isAfter(dominios.horaCarregada)
    )
      dispatch(DominiosActions.listAll(loadInicial));
    else loadInicial();
  }, []);

  function loadInicial() {
    if (type === 'edit') {
      if (produtoId) {
        setCurrent(produtoId === 99310 ? 3 : produtoId === 138104 ? 5 : 2);
        setProduto(produtoId);
      } else history.push('new');
    } else initNovaCotacao();
  }

  function initNovaCotacao() {
    setCurrent(0);
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
  }

  function next() {
    setCurrent(current + 1);
  }

  function prev() {
    setCurrent(current - 1);
  }

  function reset() {
    setCurrent(0);
    message.success('Nova cotação inicializada.');
  }

  function CompProduto(props) {
    const P = produtos[produto];
    return <P {...props} />;
  }

  function onChangeProduto(value) {
    dispatch(PlanoActions.savePlanos([]));
    setProduto(value);
    setCurrent(1);
    if(Constants.Produtos.LibertyResidencialPersonalizavel === value) {
      dispatch(LibertyPersonalizavelActions.setDefaultState());
      dispatch(LibertyPersonalizavelActions.getListaDominios({}));
    }
  }

  return (
    <>
      <ContentHeader
        title="Propostas eletrônicas"
        textButton="Nova Proposta"
        icon="home"
        breadText="Nova proposta"
        showButton
        onClick={initNovaCotacao}
      />
      <Content>
        <Spin
          spinning={dominios.loading}
          size="small"
          tip="Aguarde carregando..."
        >
          <Steps current={current}>
            {steps.map(item => {
              return item.produtosVisiveis.indexOf(produto) > -1 ? (
                <Steps.Step key={item.title} title={item.title} />
              ) : (
                ''
              );
            })}
          </Steps>

          <div className="steps-content">
            {current === 0 && (
              <Produtos
                onNext={next}
                onChangeProduto={onChangeProduto}
                produto={produto}
              />
            )}
            {current === 1 && (
              <DadosCliente
                onNext={next}
                onPrevius={prev}
                produtoId={produto}
              />
            )}
            {current === 2 && produto === 138104 && (
              <>
                <DadosProducao
                  onNext={next}
                  onPrevius={prev}
                  produtoId={produto}
                />
              </>
            )}
            {current === 3 && produto === 138104 && (
              <>
                <Planos onNext={next} onPrevius={prev} produtoId={produto} />
              </>
            )}
            {current === 2 && produto !== 138104 && (
              <>
                <CompProduto passoAtual={1} onReset={reset} onPrevius={prev} onNext={next} />
              </>
            )}
            {current === 3 && produto === Constants.Produtos.LibertyResidencialPersonalizavel && (
              <>
                <CompProduto passoAtual={2} onReset={reset} onPrevius={prev} onNext={next} />
              </>
            )}
            {current === 4 && produto === Constants.Produtos.LibertyResidencialPersonalizavel && (
              <>
                <CompProduto passoAtual={3} onReset={reset} onPrevius={prev} onNext={next} />
              </>
            )}
            {current === 3 && produto !== 138104 && produto !== Constants.Produtos.LibertyResidencialPersonalizavel && (
              <DpsAtividades
                onNext={next}
                onPrevius={prev}
                produtoId={produto}
              />
            )}
            {current === 4 && produto === 138104 && (
              <Beneficiario
                onNext={next}
                onPrevius={prev}
                produtoId={produto}
              />
            )}
            {current === 5 && produto === 138104 && (
              <Pagamento onNext={next} onPrevius={prev} produtoId={produto} />
            )}
            {current === 5 && produto !== 138104 && (
              <CompProduto onReset={reset} onPrevius={prev} onNext={next} />
            )}
            {current === 6 && produto === 99310 && produto !== 138104 && (
              <DpsAtividades onReset={reset} onPrevius={prev} />
            )}
          </div>
        </Spin>
      </Content>
    </>
  );
}
