import { Col, Divider, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';
import '../styles.css';
import TabelaSinistroDetalhes from './tabela-sinistro-detalhes';

const { Column } = Table;

type CapitalizacaoTabProp = {
  data?: Object
}

function ordenarPorPropriedade(lista, posicaoOrdenar = null) {
  return lista.sort((a, b) => a[posicaoOrdenar] - b[posicaoOrdenar]);
}

function expandedRow (contrato) {
  
  return contrato?.pagamentos && !!contrato?.pagamentos.length &&
    <>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
        <Divider
          orientation="left"
          style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
        >
          Parcelas
        </Divider>
        <Table
          rowKey="pagamentoId"
          dataSource={ordenarPorPropriedade(contrato?.pagamentos || [], 'pagamentoParcelaNumero')}
          scroll={{ x: 800 }}
        >
          <Column
            title="Nº Parcela"
            align="center"
            dataIndex="pagamentoParcelaNumero"
            width={6}
          />
          <Column
            title="Data pagamento"
            width={10}
            align="center"
            dataIndex="pagamentoDataPagamento"
            render={(text, record) => (
              text
                ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                : 'NÃO ESTÁ PAGA'
            )}
          />
          <Column
            title="Valor Bruto"
            width={12}
            align="center"
            dataIndex="pagamentoValor"
            render={(text, record) => (
              text
                ? <span>{util.Functions.formatarMoeda(text)}</span>
                : 'NÃO ESTÁ PAGA'
            )}
          />
          <Column
            title="Valor Resgate"
            width={10}
            align="center"
            dataIndex="pagamentoValorResgate"
            render={(text, record) => (
              text
                ? <span>{util.Functions.formatarMoeda(text)}</span>
                : 'NÃO ESTÁ PAGA'
            )}
          />

        </Table>
        <p><em>Trata-se de valor aproximado de resgate. Somente a cia. possui o valor atualizado.*</em></p>
      </Col>
    </>
}

export default function CapitalizacaoDetalhe(param: CapitalizacaoTabProp) {

  const contrato = param?.data || {};

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        Dados do Contrato
      </Divider>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Número Contrato">Número Contrato</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Número Contrato">{ contrato?.contratoCapitalizacaoNumeroControle }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Produto">Produto</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Produto">{ contrato?.produtoDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Seguradora">Seguradora</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Seguradora">{ contrato?.seguradoraDescricao }</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        {
          contrato?.titulos && !!contrato.titulos.length &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Títulos
              </Divider>
              <Table
                rowKey="tituloId"
                dataSource={ordenarPorPropriedade(contrato?.titulos || [], 'tituloId')}
                scroll={{ x: 800 }}
                expandedRowRender={expandedRow}
              >
                <Column
                  title="N° Título"
                  align="center"
                  dataIndex="tituloNumero"
                  
                />
                <Column
                  title="Valor mensalidade"
                  align="center"
                  dataIndex="tituloValor"
                  render={(text, record) => (
                    <span>{util.Functions.formatarMoeda(text || '0')}</span>
                    )}
                />
                <Column
                  title="Situação"
                  align="center"
                  dataIndex="tituloStatusDescricao"
                />

              </Table>
              <p><em>Trata-se de valor aproximado de resgate. Somente a cia. possui o valor atualizado.*</em></p>
            </Col>
          </>
        }
      
      </Row>
      
    </>
  );
};