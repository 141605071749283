import { createActions, handleActions } from 'redux-actions';

export const types = {
  GET_CEP_GERAL: 'GET_CEP_GERAL',
  SAVE_CEP_GERAL: 'SAVE_CEP_GERAL',
  FAILED_CEP: 'FAILED_CEP',
};

export const { getCepGeral, saveCepGeral, failedCep } = createActions(
  types.GET_CEP_GERAL,
  types.SAVE_CEP_GERAL,
  types.FAILED_CEP
);

const defaultState = {
  loading: null,
  enderecos: [],
};

export default handleActions(
  {
    [getCepGeral]: () => ({ loading: true }),
    [saveCepGeral]: (state, action) => ({
      loading: false,
      enderecos: action.payload,
    }),
    [failedCep]: () => ({ loading: false }),
  },
  defaultState
);
