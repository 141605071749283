import React from 'react';

type DisableProps = {
  children: React.Component,
  disable: Boolean,
};

export default function Disable({ children, disable }: DisableProps) {
  return (
    <div style={disable ? { pointerEvents: 'none', opacity: '0.8' } : {}}>
      {children}
    </div>
  );
}
