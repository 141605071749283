import { createActions, handleActions } from 'redux-actions';

export const types = {
  GET: 'GET',
  SAVE_RESULTADO_BUSCA: 'SAVE_RESULTADO_BUSCA',
  GET_CLIENTE: 'GET_CLIENTE',
  SAVE_CLIENTE: 'SAVE_CLIENTE',
  SAVE_PROPOSTA_DETALHES: 'SAVE_PROPOSTA_DETALHES',
  GET_PREVIDENCIA_DETALHES: 'GET_PREVIDENCIA_DETALHES',
  GET_AUTOMOVEL_DETALHES: 'GET_AUTOMOVEL_DETALHES',
  GET_DRE_DETALHES: 'GET_DRE_DETALHES',
  GET_PRESTAMISTA_DETALHES: 'GET_PRESTAMISTA_DETALHES',
  GET_HABITACIONAL_DETALHES: 'GET_HABITACIONAL_DETALHES',
  GET_CONSORCIO_DETALHES: 'GET_CONSORCIO_DETALHES',
  GET_SAUDE_DETALHES: 'GET_SAUDE_DETALHES',
  GET_FERIAS_DETALHES: 'GET_FERIAS_DETALHES',
  GET_VIDA_DETALHES: 'GET_VIDA_DETALHES',
  GET_CAPITALIZACAO_DETALHES: 'GET_CAPITALIZACAO_DETALHES',
  SET_DEFAULT_STATE: 'SET_DEFAULT_STATE',
  FALHA: 'FALHA',
};

export const {
  get,
  saveResultadoBusca,
  getCliente,
  saveCliente,
  getPrevidenciaDetalhes,
  getAutomovelDetalhes,
  getDreDetalhes,
  getPrestamistaDetalhes,
  getHabitacionalDetalhes,
  getConsorcioDetalhes,
  getSaudeDetalhes,
  getFeriasDetalhes,
  getVidaDetalhes,
  getCapitalizacaoDetalhes,
  savePropostaDetalhes,
  setDefaultState,
  falha
} = createActions(
  types.GET,
  types.SAVE_RESULTADO_BUSCA,
  types.GET_CLIENTE,
  types.SAVE_CLIENTE,
  types.GET_PREVIDENCIA_DETALHES,
  types.GET_AUTOMOVEL_DETALHES,
  types.GET_DRE_DETALHES,
  types.GET_PRESTAMISTA_DETALHES,
  types.GET_HABITACIONAL_DETALHES,
  types.GET_CONSORCIO_DETALHES,
  types.GET_SAUDE_DETALHES,
  types.GET_FERIAS_DETALHES,
  types.GET_VIDA_DETALHES,
  types.GET_CAPITALIZACAO_DETALHES,
  types.SAVE_PROPOSTA_DETALHES,
  types.SET_DEFAULT_STATE,
  types.FALHA,
);

const defaultState = {
  loading: [],
  isSearch: false,
  cliente: null,
  paginacao: {},
  resultadoPesquisa: []
};

export default handleActions(
  {
    [get]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      
      return {
        ...state,
        isSearch: true
      }
    },
    [getCliente]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getPrevidenciaDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getAutomovelDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getDreDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getPrestamistaDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getHabitacionalDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getConsorcioDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getSaudeDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getFeriasDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getVidaDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getCapitalizacaoDetalhes]: (state) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [saveResultadoBusca]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();

      state.paginacao.total = action.payload.quantidade;
      state.paginacao.current = action.payload.paginaAtual;
      
      return ({
        ...state,
        isSearch: false,
        paginacao: state.paginacao,
        resultadoPesquisa: [...action.payload.resultado]
      })
    },
    [saveCliente]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        cliente: {...action.payload}
      })
    },
    [savePropostaDetalhes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        propostaDetalhes: {...action.payload}
      })
    },
    [setDefaultState]: (state) => {
      return ({
        ...defaultState,
        loading: [],
        paginacao: { total: 0}
      })
    },
    [falha]: state => ({ ...state, loading: [], isSearch: false }),
  },
  defaultState
);
