import styled from 'styled-components';

export const Title = styled.h3`
    font-size: 1.2rem;
    position: relative;
    font-weight: 300;
    margin-top: 1rem;
    text-transform: uppercase;
    :before{
        content: "";
        position: absolute;
        height: 3px;
        background: #f3f3f3;
        bottom: 10px;
        right: 0;
        left: 25%;
    }
`;
