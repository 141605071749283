import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Validation, Constants } from '~/utils';
import * as ClienteActions from '~/store/ducks/cliente';
import * as PlanoActions from '~/store/ducks/plano';
import * as PropostaActions from '~/store/ducks/proposta';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import CardPlano from '../components/card-plano'
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import Button from "antd-button-color";

import { Container, Error } from '~/components';

import 'antd/dist/antd.css';

type PlanosProp = {
    onNext: Function,
    onPrevius: Function,
    produtoId: Number,
};

export default function Planos({
    onNext,
    onPrevius,
    produtoId,
}: PlanosProp) {
    const [validateStatus, setValidateStatus] = useState('');
    const [erros, setErros] = useState([]);
    const [planosFiltered, setPlanosFiltered] = useState([]);
    const { loading, cliente } = useSelector(state => state.cliente);
    const { usuario } = useSelector(({ auth }) => auth);

    const proposta = useSelector(state => state.proposta);

    const [planoSelected, setPlanoSelecionado] = useState();

    const isCNP = usuario?.descTipoProdutor === 'CNP';

    const { ApLiberty: { planos } } = Constants;

    const dispatch = useDispatch();

    useEffect(() => {
        if (loading) {
            setValidateStatus('validating');
        } else {
            setValidateStatus('');
        }
    }, [loading]);

    useEffect(() => {
        if (planos) {
            let isCNP = usuario.descTipoProdutor === 'CNP';
            let planosFiltered = isCNP ? planos.filter(p => p.id === 32989) : planos.filter(p => p.id !== 32989);
            setPlanosFiltered(planosFiltered);
        }
    }, [planos]);

    async function _onNext() {
        setErros([]);
        // Valid data
        const response = await validPlano();
        // Check is valid
        if (response.ok) onNext();
        else setErros(response);
    }

    async function validPlano() {
        return { ok: true };
    }



    function onChangePlano(planoSelected) {
        setPlanoSelecionado(planoSelected);
        dispatch(PropostaActions.changeApLiberty({ planoSelected }))
    }


    return (
        <>
            <div className="actions-tabs" style={{ paddingBottom: 10 }}>
                <Button size="sm" onClick={onPrevius} with="link" type="primary">
                    <ArrowLeftOutlined />  Voltar
                </Button>
                <Button
                    type="primary"
                    size="sm"
                    style={{ marginLeft: '.5rem' }}
                    onClick={_onNext}
                >
                    Próximo <ArrowRightOutlined />
                </Button>
            </div>
            <Container>
                {cliente && (
                    <div className='box-plain'>
                        {planosFiltered.map(p =>
                            <CardPlano
                                key={`${p.id}`}
                                plano={p}
                                onChangePlano={onChangePlano}
                                isCNP={isCNP}
                                isSelected={((p.id === planoSelected?.id) || (p.id === proposta.apLiberty.planoSelected?.id))} />)}
                    </div>
                )}
            </Container>
            {
                cliente && (
                    <div className="actions-tabs">
                        <Button
                            type="primary"
                            size="sm"
                            style={{ marginLeft: '.5rem' }}
                            onClick={_onNext}
                        >
                            Próximo <ArrowRightOutlined />
                        </Button>
                    </div>
                )
            }
        </>
    );
}
