import { createActions, handleActions } from 'redux-actions';

export const types = {
  SEND_TOKEN: 'SEND_TOKEN',
  FAILED_BRB_MASTER: 'FAILED_BRB_MASTER',
  SIGN_TOKEN: 'SIGN_TOKEN',
  DOWNLOAD_PDF: 'DOWNLOAD_PDF',
};

export const {
  sendToken,
  failedBrbMaster,
  signToken,
  downloadPdf,
} = createActions(
  types.SEND_TOKEN,
  types.FAILED_BRB_MASTER,
  types.SIGN_TOKEN,
  types.DOWNLOAD_PDF,
);

const defaultState = {
  loading: false,
};

export default handleActions(
  {
    [failedBrbMaster]: state => ({ ...state, loading: false }),
    [sendToken]: state => ({
      ...state,
      loading: true
    }),
    [signToken]: state => ({
      ...state,
      loading: true
    }),
    [downloadPdf]: state => ({
      ...state,
      loading: true
    }),
  },
  defaultState
);
