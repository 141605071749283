import { Col, Divider, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';

const { Column } = Table;

type ConsorcioTabProp = {
  data?: Object
}

function ordenarPorPropriedade(lista, posicaoOrdenar = null) {
  return lista.sort((a, b) => a[posicaoOrdenar] - b[posicaoOrdenar]);
}

function retornaListaContemplacoes(lista) {
  if(!lista) return [];

  var listaRetorno = [];

  lista.forEach(x => {
    if(x.contemplacaoDataContemplacao || x.contemplacaoDataLance || x.contemplacaoDataPagamentoLance || x.contemplacaoDataSorteio)
    listaRetorno.push(x);
  });

  return listaRetorno;

}

function exibirListaContemplacoes(lista){
  return lista && !!lista.length;
}

export default function ConsorcioDetalhe(param: ConsorcioTabProp) {

  const contrato = param?.data || {};

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        Dados do Contrato
      </Divider>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Contrato">Contrato</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Contrato">{ contrato?.contratoNumeroControle }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Produto">Produto</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Produto">{ contrato?.produtoDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Seguradora">Seguradora</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Seguradora">{ contrato?.seguradoraDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Situação">Situação</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Situação">{ contrato?.contratoStatusDescricao }</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Prazo do grupo">Prazo do grupo</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Prazo do grupo">{ contrato?.contratoConsorcioDuracaoGrupo }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Prazo do consorciado">Prazo do consorciado</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Prazo do consorciado">{ contrato?.contratoConsorcioDuracaoGrupoConsorciado }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Valor crédito">Valor crédito</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Valor crédito">{ util.Functions.formatarMoeda(contrato?.mtrContratoConsorcioValorCredito) }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Data da venda">Data da venda</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Data da venda">{ moment(contrato?.contratoConsorcioDataVenda).format('DD/MM/YYYY') }</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        {
          contrato?.movimentacoes && !!contrato.movimentacoes.length &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Parcelas
              </Divider>
              <Table
                rowKey="pagamentoId"
                dataSource={ordenarPorPropriedade(contrato?.movimentacoes || [], 'movimentacaoParcela')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="Nº Parcela"
                  align="center"
                  dataIndex="movimentacaoParcela"
                  width={6}
                />
                <Column
                  title="Data vencimento"
                  width={10}
                  align="center"
                  dataIndex="movimentacaoVencimentoParcela"
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />
                <Column
                  title="Data pagamento"
                  width={10}
                  align="center"
                  dataIndex="movimentacaoDataPagamentoParcela"
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : 'NÃO ESTÁ PAGA'
                  )}
                />
                <Column
                  title="Valor Pago"
                  width={12}
                  align="center"
                  dataIndex="movimentacaoValorPagamento"
                  render={(text, record) => (
                    text
                      ? <span>{util.Functions.formatarMoeda(text)}</span>
                      : 'NÃO ESTÁ PAGA'
                  )}
                />

              </Table>
            </Col>
          </>
        }
        {
          exibirListaContemplacoes(retornaListaContemplacoes(contrato?.contemplacoes)) &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Contemplações
              </Divider>
              <Table
                rowKey="tituloId"
                dataSource={ordenarPorPropriedade(retornaListaContemplacoes(contrato?.contemplacoes), 'contemplacaoId')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="Valor lance"
                  align="center"
                  dataIndex="contemplacaoDataPagamentoLance"
                  width={15}
                  render={(text, record) => (
                    text
                      ? <span>{util.Functions.formatarMoeda(text)}</span>
                      : ''
                  )}
                />
                <Column
                  title="Data lance"
                  align="center"
                  dataIndex="contemplacaoDataLance"
                  width={15}
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />
                <Column
                  title="Data pagamento lance"
                  align="center"
                  dataIndex="contemplacaoDataPagamentoLance"
                  width={15}
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />
                <Column
                  title="Data sorteio"
                  align="center"
                  dataIndex="contemplacaoDataSorteio"
                  width={15}
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />
                <Column
                  title="Data contemplação"
                  align="center"
                  dataIndex="contemplacaoDataContemplacao"
                  width={15}
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />

              </Table>
            </Col>
          </>
        }
      
      </Row>
      
    </>
  );
};