import { message as toast } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as JogaJuntoApi from '~/api/joga-junto.api';
import { getResponseFile } from '~/api/utils';
import * as JogaJuntoActions from '~/store/ducks/joga-junto';

function* getRegulamento() {
  
  // Call api
  const response = getResponseFile(yield call(JogaJuntoApi.getRegulamentoCampanha), 'Regulamento.pdf');
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }
}

function* getSituacaoAgencia() {
  
  // Call api
  const response = getResponseFile(yield call(JogaJuntoApi.getSituacaoAgencia), 'Acompanhamento/Simulador.xlsb');
  
  const { ok, message, data } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

}

function* fail(message) {
  yield put(JogaJuntoActions.failedJogaJunto());
  toast.error(message);
}

export default all([
  takeLatest(JogaJuntoActions.types.GET_REGULAMENTO, getRegulamento),
  takeLatest(JogaJuntoActions.types.GET_SITUACAO_AGENCIA, getSituacaoAgencia),
]);
