import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Row, Spin, Table } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, ContentHeader, Error, Input, Select } from '~/components';
import * as Actions from '~/store/ducks/pesquisa-geral';
import * as utils from '~/utils';
import ModalDetalhes from './components/modal-detalhes';
import './styles.css';

const { Column } = Table;

export default function PesquisaGeral() {
  const dispatch = useDispatch();

  const { loading, resultadoPesquisa, isSearch, cliente, paginacao, propostaDetalhes } = useSelector(({ pesquisaGeral }) => pesquisaGeral);
  const [filtros, setFiltros] = useState({ quantidadePorPagina: 10, paginaAtual: 1, ativo: true });
  const [modalVisible, setModalVisible] = useState(false);
  const [tipoModal, setTipoModal] = useState('');
  const [filtroSituacao, setFiltroSituacao] = useState('ATIVO');
  const [erros, setErros] = useState([]);

  const listaSituacoes = [{ value: 'ativo', desc: 'ATIVO'}, { value: 'todos', desc: 'TODOS'}];

  useEffect(() => {
    dispatch(Actions.setDefaultState());
  }, []);

  useEffect(() => {
    if(cliente) setModalVisible(true);
  }, [cliente]);

  function onChangeFiltros(value, name) {
    
    if(name === 'situacao') {
      setFiltroSituacao(value);
      setFiltros({ ...filtros, ativo: value === 'ativo' });
      return;
    }

    setFiltros({ ...filtros, [name]: value });
  }

  function retornaFiltroPesquisa(){

    const contaCorrente = (filtros.contaCorrente || '').replace(/\D/g, '');

    const _filtros = {
      ...filtros,
      contaCorrente: contaCorrente.substring(4, 11),
      codigoAgencia: contaCorrente.substring(0, 4)
    }
    console.log(_filtros);
    return _filtros;
  }

  function handleSubmit(e) {

    if(e) e.preventDefault();
    if(preencheuAoMenosUmFiltro()) dispatch(Actions.get(retornaFiltroPesquisa()));
  }

  function preencheuAoMenosUmFiltro(){
    setErros([]);

    const ok = (filtros.cpfCnpj && filtros.cpfCnpj.trim())
    || (filtros.nome && filtros.nome.trim())
    || (filtros.contaCorrente && filtros.contaCorrente.trim())

    
    if(!ok){
      setErros([
        'Ao menos uns dos seguintes filtros devem ser informados: CPF ou Número conta'
      ]);

      return false;
    } 

    if(filtros.contaCorrente && filtros.contaCorrente.length !== 13) {
      setErros([
        'A conta informada está incompleta, deve-se informar agência e conta com dígito verificador.'
      ]);
      
      return false;
    }

    if(!verificaPossuiNomeSobreNome(filtros.nome)) {
      setErros([
        'Informe ao menos um sobrenome para realizar a busca'
      ]);
      
      return false;
    }

    return true;
  }

  function verificaPossuiNomeSobreNome(nomeCompleto: stirng) {
    if(!nomeCompleto || !nomeCompleto.trim()) return true;

    const nomeArray = nomeCompleto.split(' ');
    let qtdPalavras = 0
    
    nomeArray.forEach(x => {

      if(x && x.trim())
      qtdPalavras += 1;
    });

    return qtdPalavras > 1;
  }

  function onSelected(selecionado) {
    buscarDetalhamento(selecionado);
    setTipoModal(selecionado.carteira);
    dispatch(Actions.getCliente(selecionado.clienteCpfCnpj));
  }

  function tratarChangeTable(paginacao){
    if(preencheuAoMenosUmFiltro()) dispatch(Actions.get({...filtros, paginaAtual: paginacao.current} ));
  }

  function buscarDetalhamento(obj){
    obj.carteira = obj.carteira ? obj.carteira.toUpperCase().trim() : '';

    switch (obj.carteira) {
      case 'AUTOMÓVEL':
        dispatch(Actions.getAutomovelDetalhes({contratoId: obj.contratoId}));
        break;
      case 'DRE':
        dispatch(Actions.getDreDetalhes({contratoId: obj.contratoId}));
        break;
      case 'PRESTAMISTA':
        dispatch(Actions.getPrestamistaDetalhes({contratoId: obj.contratoId}));
        break;
      case 'HABITACIONAL':
        dispatch(Actions.getHabitacionalDetalhes({contratoId: obj.contratoId}));
        break;
      case 'CONSÓRCIO':
        dispatch(Actions.getConsorcioDetalhes({contratoId: obj.contratoId}));
        break;
      case 'PREVIDÊNCIA':
        dispatch(Actions.getPrevidenciaDetalhes({contratoId: obj.contratoId}));
        break;
      case 'SAÚDE':
        dispatch(Actions.getSaudeDetalhes({contratoId: obj.contratoId}));
        break;
      case 'FÉRIAS':
        dispatch(Actions.getFeriasDetalhes({contratoId: obj.contratoId}));
        break;
      case 'VIDA':
        dispatch(Actions.getVidaDetalhes({contratoId: obj.contratoId}));
        break;
      case 'CAPITALIZAÇÃO':
        dispatch(Actions.getCapitalizacaoDetalhes({contratoId: obj.contratoId}));
        break;
      default:
        break;
    }
  }

  return (
    <>
      <ContentHeader
        title="PESQUISA GERAL"
        hideClass="btn-Hide"
        breadText="Pesquisar"
        icon="search"
      />

      <Spin
        spinning={!!(loading || []).length && !isSearch}
        size="small"
        tip="Aguarde carregando..."
      >

        <Container style={{ margin: '0 16px' }}>
          <Error erros={erros} />

          <ModalDetalhes 
            width="90%"
            onOk={() => setModalVisible(false)}
            visible={modalVisible} 
            data={{cliente: cliente, tipo: tipoModal, contrato: propostaDetalhes}}>

          </ModalDetalhes>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <h6>Pesquisa Geral Produtos Cliente</h6>
              <Form
                onSubmit={handleSubmit}
                layout="vertical"
                className="ant-advanced-search-form"
              >
                <Divider orientation="center" style={{ color: '#333' }} />
                <Row gutter={[16, 0]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }}>
                    <Form.Item label="Cpf">
                      <Input
                        placeholder="Cpf/Cnpj"
                        value={filtros.cpfCnpj}
                        name="cpfCnpj"
                        onChange={onChangeFiltros}
                        mask="cpfCnpj"
                        // autoComplete="off"
                        maxLength={18}
                        id="validating"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
                    <Form.Item label="Nome e Sobrenome">
                      <Input
                        placeholder="Nome e Sobrenome"
                        value={filtros.nome}
                        name="nome"
                        onChange={onChangeFiltros}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                    <Form.Item label="Agência e conta">
                      <Input
                        placeholder="Número de agência e conta"
                        name="contaCorrente"
                        mask="maskContaBrb"
                        value={filtros.contaCorrente}
                        onChange={onChangeFiltros}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }}>
                    <div className="form-group">
                      <label className="w-100">Situação</label>
                      <Select
                        // defaultValue="Selecione"
                        itens={listaSituacoes}
                        itemValue="value"
                        itemText="desc"
                        value={filtroSituacao}
                        onChange={value => onChangeFiltros(value, 'situacao')}
                        showSearch
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }}>
                    <Form.Item label="&nbsp;">
                      <Button
                        type="primary"
                        className="btn-block"
                        htmlType="submit"

                      >
                        <SearchOutlined />  Pesquisar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Divider
                orientation="center"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Listagem de produtos
              </Divider>
              <Table
                rowKey={(r, i) => i.toString()}
                dataSource={resultadoPesquisa}
                onChange={tratarChangeTable}
                loading={!!(loading || []).length && isSearch}
                pagination={paginacao}
                scroll={{ x: 1500 }}
              >

                <Column
                  align="center"
                  title="Nome"
                  width={250}
                  dataIndex="clienteNome"
                  render={(text, record) => (

                    <span className="spn-link"
                      onClick={() => onSelected(record)}
                    >
                      {text}
                    </span>
                  )}
                />

                <Column
                  align="center"
                  title="CPF / CNPJ"
                  width={150}
                  dataIndex="clienteCpfCnpj"
                  render={(text, record) => (
                    <Button
                      size="small"
                      type="link"
                      onClick={() => onSelected(record)}
                    >
                      {utils.Mask.maskCpfCnpj(text)}
                    </Button>
                  )}
                />

                <Column
                  align="center"
                  title="Carteira"
                  dataIndex="carteira"
                  width={100}
                />
                <Column
                  width={250}
                  align="center"
                  title="Produto"
                  dataIndex="produtoDescricao"
                />
                <Column
                  width={150}
                  align="center"
                  title="Bem"
                  dataIndex="bem"
                />
                <Column
                  width={100}
                  align="center"
                  title="Status"
                  dataIndex="contratoStatusDescricao"
                />
              </Table>
            </Col>
          </Row>

        </Container>

      </Spin>


    </>
  );
}
