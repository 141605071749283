import { Col, Divider, Table } from 'antd';
import moment from 'moment';
import React from 'react';

const { Column } = Table;

type TabelaSinistroDetalhesProp = {
  data?: Array<any>
}

export default function TabelaSinistroDetalhes(param: TabelaSinistroDetalhesProp) {

  return (
    <>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
        <Divider
          orientation="left"
          style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
        >
          Sinistros
        </Divider>
        <Table
          rowKey="sinistroId"
          dataSource={param?.data || []}
          scroll={{ x: 800 }}
        >
          <Column
            title="N° Sinistro"
            align="center"
            dataIndex="sinistroNumero"
            width={10}
          />
          <Column
            title="Motivo"
            width={10}
            align="center"
            dataIndex="sinistroMotivoDescricao"
          />
          <Column
            title="Situação"
            width={10}
            align="center"
            dataIndex="sinistroSituacaoDescricao"
          />
          <Column
            title="Data aviso"
            width={10}
            align="center"
            dataIndex="sinistroDataAviso"
            render={(text, record) => (
              text
                ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                : 'SEM DATA DE AVISO'
            )}
          />
          <Column
            title="Data sinistro"
            width={10}
            align="center"
            dataIndex="sinistroDataSinistro"
            render={(text, record) => (
              text
                ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                : 'SINISTRO SEM DATA'
            )}
          />

        </Table>
      </Col>
    </>
  );
};