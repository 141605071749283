import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Validation, Constants } from '~/utils';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Button from "antd-button-color";
import 'antd-button-color/dist/css/style.css';
import { Container, Error, FieldsProducao, Disable } from '~/components';

type DadosProducaoProp = {
    onNext: Function,
    onPrevius: Function,
    produtoId: Number,
};

export default function DadosProducao({
    onNext,
    onPrevius,
    produtoId,
}: PlanosProp) {

    const [validateStatus, setValidateStatus] = useState('');
    const [erros, setErros] = useState([]);
    const { loading, cliente } = useSelector(state => state.cliente);

    const { 
        proposta: { contratoId } 
    } = useSelector(state => state);

    const dispatch = useDispatch();

    useEffect(() => {
        if (loading) {
            setValidateStatus('validating');
        } else {
            setValidateStatus('');
        }
    }, [loading]);

    async function _onNext() {
        setErros([]);
        // Valid data
        const response = await validProducao();
        // Check is valid
        if (response.ok) onNext();
        else setErros(response);
    }

    async function validProducao() {
        return { ok: true };
    }

    return (
        <>
            <div className="actions-tabs" style={{ paddingBottom: 10 }}>
                <Button size="sm" onClick={onPrevius} with="link" type="primary">
                    <ArrowLeftOutlined />  Voltar
                </Button>
                <Button
                    type="primary"
                    size="sm"
                    style={{ marginLeft: '.5rem' }}
                    onClick={_onNext}
                >
                    Próximo <ArrowRightOutlined />
                </Button>
            </div>
            <Container>
                <Disable disable={!!contratoId}>
                    <FieldsProducao produto="ap-liberty" />
                </Disable>
            </Container>
            {
                cliente && (
                    <div className="actions-tabs">
                        <Button
                            type="primary"
                            size="sm"
                            style={{ marginLeft: '.5rem' }}
                            onClick={_onNext}
                        >
                            Próximo <ArrowRightOutlined />
                        </Button>
                    </div>
                )
            }
        </>
    )
}