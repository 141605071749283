import moment from 'moment';
import { Constants } from '~/utils';

/* eslint-disable no-restricted-syntax */
function calculaIdade(dataNascimento) {
  const dataAtual = new Date();
  const dataAniversario = new Date(dataNascimento);
  let idade = dataAtual.getFullYear() - dataAniversario.getFullYear();
  const m = dataAtual.getMonth() - dataAniversario.getMonth();

  if (m < 0 || (m === 0 && dataAtual.getDate() < dataAniversario.getDate())) {
    idade -= 1;
  }

  return idade;
}

function isCap(produtoId) {
  return (
    produtoId === Constants.Produtos.CapIcatuPU ||
    produtoId === Constants.Produtos.CapIcatu24 ||
    produtoId === Constants.Produtos.CapIcatu48 ||
    produtoId === Constants.Produtos.CapIcatu60 ||
    produtoId === Constants.Produtos.CapIcatu84
  );
}
function formatarMoeda(value) {
  return !value || value === '0'
    ? 'R$ 0,00'
    : value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      });
}

function inStruct(val, structure) {
  for (const a in structure) {
    if (
      structure[a] === val &&
      Object.prototype.hasOwnProperty.call(structure, a)
    ) {
      return true;
    }
  }
  return false;
}

function trimObjectProperties(objectToTrim) {
  for (const key in objectToTrim) {
    if (
      objectToTrim[key] &&
      objectToTrim[key].constructor &&
      objectToTrim[key].constructor === Object
    )
      trimObjectProperties(objectToTrim[key]);
    else if (objectToTrim[key] && typeof objectToTrim[key] === 'string')
      objectToTrim[key] = objectToTrim[key].trim();
  }

  return objectToTrim;
}

function formatarData(value) {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY');
}

function getDateNow(format) {
  return moment().format(format || 'DD/MM/YYYY');
}

function getMoney(value) {
  if (!value) return '';
  return parseFloat(
    value
      .toString()
      .replace('R$ ', '')
      .replace(/[.]+/g, '')
      .replace(',', '.')
  );
}

function getFaixaRendaId(value) {
  if (!value) return '1';

  value = value.toString();

  if (value > 0 && value <= 1000) return 1;
  if (value >= 1001 && value <= 2000) return 2;
  if (value >= 2001 && value <= 3000) return 3;
  if (value >= 3001 && value <= 4000) return 4;
  if (value >= 4001 && value <= 5000) return 5;
  if (value >= 5001 && value <= 6000) return 6;
  if (value >= 6001 && value <= 7000) return 7;
  if (value >= 7001 && value <= 8000) return 8;
  if (value >= 8001 && value <= 9000) return 9;
  if (value >= 9001 && value <= 10000) return 10;
  if (value >= 10001 && value <= 15000) return 11;
  if (value >= 15001 && value <= 20000) return 12;
  if (value >= 20001 && value <= 30000) return 13;
  if (value >= 30000 && value <= 99999) return 14;
  if (value >= 100000 && value <= 999999) return 15;
  if (value >= 1000000 && value <= 9999999) return 16;
  if (value >= 10000000 && value <= 99999999) return 17;
}

// Retorna somente numero(float também) de uma string, ou retorna 0(zero) se não houver nenhum número
function getOnlyNum(num) {
  if (num === '' || num == null || num === undefined) {
    return 0;
  }

  if (!isNumber(num)) {
    num = num.match(/\d/g);
    num = num.join('');
  }

  return parseFloat(num);
}

function formatarPorcentagem(
  valor,
  qtdCasasDecimais = 2,
  separadorDecimal = ',',
  separadorMilhar = '.'
) {
  valor = !valor || isNaN(valor) || !isFinite(valor) ? 0 : valor;
  return `${formatarDecimal(
    valor,
    qtdCasasDecimais,
    separadorDecimal,
    separadorMilhar
  )}%`;
}

function formatarDecimal(
  valor,
  qtdCasasDecimais = 2,
  separadorDecimal = ',',
  separadorMilhar = '.'
) {
  try {
    qtdCasasDecimais = Math.abs(qtdCasasDecimais);
    qtdCasasDecimais = isNaN(qtdCasasDecimais) ? 2 : qtdCasasDecimais;

    const negativeSign = valor < 0 ? '-' : '';

    let i = parseInt(
      (valor = Math.abs(Number(valor) || 0).toFixed(qtdCasasDecimais))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + separadorMilhar : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + separadorMilhar) +
      (qtdCasasDecimais
        ? separadorDecimal +
          Math.abs(valor - i)
            .toFixed(qtdCasasDecimais)
            .slice(2)
        : '')
    );
  } catch (e) {
    return '';
  }
}

function left(str, n) {
  if (n <= 0) return '';
  if (n > String(str).length) return str;
  return String(str).substring(0, n);
}
function right(str, n) {
  if (n <= 0) return '';
  if (n > String(str).length) return str;

  const iLen = String(str).length;
  return String(str).substring(iLen, iLen - n);
}
function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function isProducao(){
  
  const href = window.location.href;  
  
  return href.indexOf("seguridade.segurosbrb.com.br") !== -1 || href.indexOf("seguridade.brbseguros.com.br") !== -1 || href.indexOf("seguridade.brb.com.br") !== -1 ;

}

function stringOnlyNumber(x) {
  if(!x) return null;

  return x.replace(/\D/g,'');
}

function stringToJsonPropertiesCamelCase(objString) {
  var parsed = JSON.parse(objString, toCamelCase);
  return parsed;
}

function toCamelCase(key, value) {
  if (value && typeof value === 'object'){
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}


export default {
  calculaIdade,
  formatarMoeda,
  inStruct,
  trimObjectProperties,
  formatarData,
  getDateNow,
  getMoney,
  getFaixaRendaId,
  formatarDecimal,
  formatarPorcentagem,
  getOnlyNum,
  isProducao,
  isCap,
  stringOnlyNumber,
  stringToJsonPropertiesCamelCase,
  left,
  right,
};
