import { call, all, put, takeLatest } from 'redux-saga/effects';
import { getResponse } from '~/api/utils';
import * as ProducaoApi from '~/api/producao.api';
import * as ProducaoActions from '~/store/ducks/producao';

function* getRegraComissaoCorretora(action) {
  const response = getResponse(
    yield call(ProducaoApi.regraComissaoCorretoraByFiltros, action.payload)
  );
  const { ok, data } = response;
  if (ok)
    yield put(
      ProducaoActions.saveProducao({
        dominio: 'regraComissaoCorretora',
        data: data[0],
      })
    );
}

function* getRegraComissaoProdutor(action) {
  const response = getResponse(
    yield call(ProducaoApi.regraComissaoProdutorByFiltros, action.payload)
  );
  const { ok, data } = response;
  if (ok)
    yield put(
      ProducaoActions.saveProducao({ dominio: 'regraComissaoProdutor', data })
    );
}

function* getEquipesByProdutor(action) {
  const response = getResponse(
    yield call(ProducaoApi.equipeByProdutor, action.payload)
  );
  const { ok, data } = response;
  if (ok)
    yield put(
      ProducaoActions.saveProducao({ dominio: 'equipesProdutor', data })
    );
}

function* getProdutoresByEquipe(action) {
  const response = getResponse(
    yield call(ProducaoApi.produtoByEquipe, action.payload)
  );
  const { ok, data } = response;
  if (ok)
    yield put(
      ProducaoActions.saveProducao({ dominio: 'produtoresEquipe', data })
    );
}

// function* getAgenciasByEquipe(action) {
//   const response = getResponse(
//     yield call(ProducaoApi.agenciasByEquipe, action.payload)
//   );
//   const { ok, data } = response;
//   if (ok)
//     yield put(
//       ProducaoActions.saveProducao({ dominio: 'agenciasEquipe', data })
//     );
// }

export default all([
  takeLatest(
    ProducaoActions.types.GET_REGRA_COMISSAO_CORRETORA,
    getRegraComissaoCorretora
  ),
  takeLatest(
    ProducaoActions.types.GET_REGRA_COMISSAO_PRODUTOR,
    getRegraComissaoProdutor
  ),
  takeLatest(
    ProducaoActions.types.GET_EQUIPES_BY_PRODUTOR,
    getEquipesByProdutor
  ),
  takeLatest(
    ProducaoActions.types.GET_PRODUTORES_BY_EQUIPE,
    getProdutoresByEquipe
  ),
]);
