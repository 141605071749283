import { Col, Row, Table } from 'antd';
import React from 'react';
import { Functions } from 'utils/index';

type BlocoRealizadoCarteriaOperadorProps = {
  listaRealizadoCarteiraOperador: Array<any>
};

export default function BlocoRealizadoCarteriaOperador({
  listaRealizadoCarteiraOperador
}: BlocoRealizadoCarteriaOperadorProps) {
  return (
    <>
      <Row style={{ marginBottom: 14 }}>
        <strong style={{ color: '#538ac3' }}>Realizado por Carteira</strong>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} style={{ marginTop: 8 }}>
          <Table
            dataSource={listaRealizadoCarteiraOperador.filter(x => x.descLinha !== 'Realizado %')}
            pagination={false}
            rowKey={'descLinha'}
            scroll={{ x: 800 }}
          >
            <Table.Column
              title=""
              dataIndex="descLinha"
              align="center"
              fixed="left"
            />
            {
              Object.keys(((listaRealizadoCarteiraOperador || [])[0] || {})).map(x => {
                return x === 'descLinha' ? '' : <Table.Column
                  title={x}
                  key={x}
                  align="center"
                  className={(((listaRealizadoCarteiraOperador || [])[2] || {})[x] || 0) < 100 ? 'letra-vermelha' : 'letra-azul'}
                  render={(y) => Functions.formatarMoeda(y[x])}
                />
              })
            }
          </Table>
        </Col>
      </Row>
    </>
  );
}
