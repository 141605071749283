import { LinkOutlined } from '@ant-design/icons';
import { Avatar, Col, List, Modal, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, RadioCustom } from '~/components/';
import { Constants, Functions } from '~/utils';
import history from '~/services/history';

import * as PropostaActions from '~/store/ducks/proposta';

import './style.css';

type ProdutosProp = {
  // onNext: Function,
  onChangeProduto: Function,
  // produto: Number,
};
const listProdutosCap = [
  {
    value: 161139,
    label: 'CAP SONHO 24',
    codeSeg: 'PM541T',
  },
  {
    value: 161144,
    label: 'CAP SONHO 48',
    codeSeg: 'PM552T',
  },
  {
    value: 161142,
    label: 'CAP SONHO 60',
    codeSeg: 'PM553T',
  },
  // {
  //   value: 161143,
  //   label: 'CAP SONHO 84',
  //   codeSeg: 'PM544T',
  // },
  {
    value: 161137,
    label: 'CAP SONHO PU',
    codeSeg: 'PU556T',
  },
];

let listProdutos = [
  // {
  //   value: 99312,
  //   label: 'BRB Vida Premiado Plus',
  //   smallText: 'American Life',
  //   icon: 'fa fa-heartbeat',
  // },
  // {
  //   value: 11410,
  //   label: 'BRB Residencial',
  //   smallText: 'Mitsui',
  //   icon: 'fal fa-home',
  // },
  {
    value: 96053,
    label: 'BRB Odonto',
    smallText: 'BRB Completo',
    icon: 'fal fa-user-md',
  },
  {
    value: 11410,
    label: 'BRB Residencial Personalizável',
    smallText: 'BRB Completo',
    icon: 'fal fa-home',
  },
  {
    value: 99312,
    label: 'Vida Super Premiada',
    smallText: 'BRB Completo',
    icon: 'fa fa-heartbeat',
  },
  {
    value: 11804,
    label: 'Comércio e Serviços',
    smallText: 'Liberty',
    icon: 'fa fa-handshake',
  },
  {
    value: 11806,
    label: 'Pacote Empresarial',
    smallText: 'Mitsui Sumitomo',
    icon: 'fa fa-building',
  },
  {
    value: 0,
    label: 'BRB Cap',
    smallText: 'ICATU',
    icon: 'fal fa-hand-holding-usd',
  },
  {
    value: 98208,
    label: 'Acidentes Pessoais',
    smallText: 'BRB Completo',
    icon: 'fas fa-user-injured',
  },
  {
    value: 138104,
    label: 'Acidentes Pessoais',
    smallText: 'Liberty',
    icon: 'fas fa-user-injured',
  },
  // {
  //   value: 11409,
  //   label: 'Residencial Personalizável - Liberty',
  //   smallText: '',
  //   icon: 'fal fa-home',
  // },
  {
    value: 139113,
    label: 'BRB Vida Sênior',
    smallText: 'MAG',
    icon: 'fa fa-heartbeat',
  },
  {
    value: 1,
    label: 'Cotagro',
    smallText: '',
    icon: 'fa fa-tractor',
  },
];

const listProdutosLink = [
  { value: 'http://www.brbferias.com.br/', label: 'BRB  FÉRIAS' },
  {
    value: 'https://newcon.bancorbras.com.br/intranet/',
    label: 'BRB CONSÓRCIO',
  },
  {
    value: 'http://portaldenegocios.brb.com.br/brb-intranet-portal/',
    label: 'BRB CAP',
  },
  { value: 'https://plataformadoparceiro.com.br/login', label: 'BRB  PREV' },
  {
    value: 'http://segurosbrb.clubeflytour.com.br',
    label: 'BRB CLUBE VIAGENS',
  },
  {
    value:
      'https://portal.sulamericaseguros.com.br/seguroviagem.htm?ref=eyJkYWRvc1Byb2R1Y2FvIjp7IkFBIjoiMzY3MDciLCJBViI6IjAiLCJFQSI6Ijc4MDg3IiwiRVYiOiIzMDg0ODQxIiwidW9wRW1pc3NhbyI6Ijk5MCIsInVvcE5lZ29jaW8iOiI1NzgxIn0sImNvcnJldG9yTm9tZSI6IkJSQiBDT1JSRVRPUkEgREUgU0VHVVJPUyAiLCJpZENvcnJldG9yIjpudWxsLCJwZXJjZW50dWFsQ29ycmV0YWdlbSI6W3sicGVyY2VudHVhbENvcnJldGFnZW0iOiI0MC4wMCJ9LHsicGVyY2VudHVhbEFnZW5jaWFtZW50byI6IjAuMDAifSx7InBlcmNlbnR1YWxQcmVzdGFjYW9TZXJ2aWNvIjoiMC4wMCJ9LHsiaW5kZXhPcGNhbyI6IjcifV0sIm5vbWVQcm9tb3RvciI6IiIsImltZ0NvcnJldG9yIjoiaHR0cHM6Ly9jb3JyZXRvci5zdWxhbWVyaWNhc2VndXJvcy5jb20uYnIvZGF0YS9maWxlcy9ENS9GNi80Qy9EQi8yNkZGMjgxMDc4QkU2RDI4MDgwODA4MDgvV2hhdHNBcHAlMjBJbWFnZSUyMDIwMjItMDgtMzElMjBhdCUyMDE0LjA0LjM5JTIwKDIpLmpwZWcifQ==',
    label: 'SEGURO VIAGEM',
  },
  // { value: 4, label: 'BRB Empresa Premiado' },
];

const radioStyleModal = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

// Para essas matróculas Nari só mostram os 3 primeiros produtos
const matriculasNari = [
  "848333",
  "841890",
  "851238",
  "63925",
];

export default function Produtos({ onChangeProduto }: ProdutosProp) {
  const { usuario, tokenSSOWiz } = useSelector(({ auth }) => auth);

  const [visibleModal, setVisibleModal] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [links, setLinks] = useState(listProdutosLink);
  const [showLinks, setShowLinks] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {

    if (matriculasNari.includes(usuario.matricula)) {
      listProdutos = listProdutos.filter( p => p.value === 11410 || p.value === 2);
    }

    // Remove CAP se data inferior a 2021-11-18 20:12:41.405  e matriculas  diferente 86504 e 89446
    if (
      Date.now() < 1637266361405 &&
      usuario.matricula !== '86504' &&
      usuario.matricula !== '89446'
    ) {
      listProdutos = listProdutos.filter(l => l.value !== 0);
    }

    setProdutos(listProdutos);
    if (usuario) {
      if (
        usuario.fatoUsuarioPerfis.filter(c =>
          Functions.inStruct(c.perfil.perfilCodigo, ['BCOPE', 'SUPGECAL'])
        ).length === 0
      ) {
        // produtos.pop();
      }

      if (usuario.descTipoProdutor === 'ANGARIADOR') {
        // Angariador exibe Residencial e CAP
        const produto = listProdutos.filter(
          l => {
            var listaDisponiveis = [
              11410, 
              0, 
              2,
              99312, 
              11806, 
              Constants.Produtos.LibertyResidencialPersonalizavel,
              Constants.Produtos.BrbMasterPremiado
            ];
            return listaDisponiveis.some(x => x === l.value)
          }
        );

        const newLinks = listProdutosLink.filter(v => v.label === 'CAPEMISA');
        setLinks(newLinks);
        setProdutos(produto);
      }
      
      if (usuario.descTipoProdutor === 'CNP') {
        // CNP exibe Residencial e AP
        const produto = listProdutos.filter(l => l.value === 11410 || l.value === 0 || l.value === 2 || l.value === 138104);
        setProdutos(produto);
        setShowLinks(false);
      }

      if (usuario.descTipoProdutor === 'AGENCIA_DIGITAL') {
        // AGENCIA DIGITAL exibe apenas o produto de Vida
        const produto = listProdutos.filter(l => l.value === 99312);
        setProdutos(produto);
        setShowLinks(false);
      }

      if (usuario.descTipoProdutor !== 'CNP') {
        // Diferente de CNP tira o AP da Liberty
        const produto = listProdutos.filter(l => l.value !== 138104);
        setProdutos(produto);
      }
    }
  }, [usuario]);

  function onChangeProdutos(produtoId) {
    switch (produtoId) {
      // CAP
      case 0:
        setVisibleModal(true);
        break;

      // Cotagro
      case 1:
        history.push(`/cotagro`);
        break;

      // Produtos do BRB Completo
      case 96053:
      case 11410:
      case 99312:
      case 98208:
        history.push(`/venda-assistida/${produtoId}`);
        break;
    
      default:
        dispatch(PropostaActions.saveProduto(produtoId));
        onChangeProduto(produtoId);
        break;
    }
  }
  return (
    <>
      <Container>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="grid-products">
              {produtos.map(item => (
                <RadioCustom
                  key={item.value}
                  label={item.label}
                  smallText={item.smallText}
                  icon={item.icon}
                  name="customRadio"
                  id={item.value}
                  onClick={() => onChangeProdutos(item.value)}
                />
              ))}
            </div>

            <Modal
              title="Selecione o Produto"
              centered
              width={300}
              visible={visibleModal}
              onOk={() => setVisibleModal(false)}
              onCancel={() => setVisibleModal(false)}
            >
              <Radio.Group>
                {listProdutosCap.map((item, index) => (
                  <Radio
                    style={radioStyleModal}
                    key={index}
                    onClick={() => onChangeProdutos(item.value)}
                    value={item.value}
                  >
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Modal>
          </Col>
        </Row>

        {showLinks && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <List
                dataSource={links}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor: '#FFF',
                            color: '#1890FF',
                          }}
                          size="small"
                          icon={<LinkOutlined />}
                        />
                      }
                      title={
                        <a href={item.value} rel="noreferrer" target="_blank">
                          {item.label}
                        </a>
                      }
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
