import React, { useEffect, useState } from 'react';
import { PlusOutlined, PrinterOutlined, ArrowLeftOutlined, SaveOutlined, CheckOutlined } from '@ant-design/icons';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import Button from "antd-button-color";
import {
  Row,
  Col,
  Radio,
  Table,
  Spin,
  BackTop,
  Result,
  Popconfirm,
  Alert,
  message,
  Icon,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  TitleSection,
  FieldsProducao,
  Container,
  DatePicker,
  FieldsEndereco,
  Input,
  Select,
  Disable,
} from '~/components';

import Pagamento from './pagamento';

import {
  ProducaoValidation,
  PropostaValidation,
  PagamentoValidation,
} from '~/yup';

import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PlanoActions from '~/store/ducks/plano';

import { Constants, Mask, Validation, Functions } from '~/utils';

type EmpresarialZurichProps = {
  onReset: Function,
  onPrevius: Function,
};

export default function EmpresarialZurich({
  onReset,
  onPrevius,
}: EmpresarialZurichProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    dreZurichEmp,
    dreZurichEmp: {
      planoSelected,
      bemEndereco,
      pagamento,
      isOutrosSeguros,
      propostaEletronicaPlanoId,
    },
    producao,
    loading: loadingProposta,
    propostaId,
    contratoId,
  } = useSelector(state => state.proposta);
  const { planos, loading } = useSelector(state => state.plano);
  const { cliente, endereco } = useSelector(state => state.cliente);
  const { regraComissaoCorretora, regraComissaoProdutor } = useSelector(
    state => state.producao
  );
  const {
    seguradoras,
    propostaEletronicaAtividades,
    nacionalidades,
  } = useSelector(({ dominio }) => dominio);

  // Component state
  const [planosAgrupado, setPlanosAgrupado] = useState([]);
  const [planoSelectedKey, setPlanoSelectedKey] = useState([]);
  const [apoliceOutrosSeg, setApoliceOutrosSeg] = useState();
  const [propRazaoSocial, setPropRazaoSocial] = useState();
  const [propCNPJ, setPropCNPJ] = useState();
  const [propPatrimonioLiq, setPropPatrimonioLiq] = useState();
  const [atividades, setAtividades] = useState([]);
  const [listNacionalidades, setListNacionalidades] = useState([]);
  const [erros, setErros] = useState([]);

  useEffect(() => {
    if (nacionalidades) {
      const n = nacionalidades.filter(a => a.nacionalidadeZurichId !== 0);

      setListNacionalidades(n);
    }
  }, []);

  useEffect(() => {
    if (propostaEletronicaAtividades) {
      const a = propostaEletronicaAtividades.filter(
        c =>
          c.seguradoraId == Constants.Seguradoras.Zurich &&
          c.propostaEletronicaAtividadeCodParceiro ==
            Constants.Produtos.DreZurichEmp
      );

      setAtividades(a);
    }
  }, []);

  useEffect(() => {
    getRegraComissaoCorretora();
    // if (bemEndereco && bemEndereco.tipoImovel)
    //   onChangeTipoImovel(bemEndereco.tipoImovel);
  }, []);

  useEffect(() => {
    if (dreZurichEmp && dreZurichEmp.bemEndereco) {
      // Preenche os objetos do state
      setApoliceOutrosSeg(dreZurichEmp.apoliceOutrosSeg);
      setPropRazaoSocial(dreZurichEmp.propRazaoSocial);
      setPropCNPJ(dreZurichEmp.propCNPJ);
      setPropPatrimonioLiq(Mask.maskMoney(dreZurichEmp.propPatrimonioLiquido));
      // Preenche os planos
      onChangeAtividade(dreZurichEmp.bemEndereco.bemAtividade);
    }
  }, []);

  useEffect(() => {
    const planosAgrupado1 = [];

    if (planos && planos.length > 0) {
      // Unifica retorno duplicados devido a coberturas
      planos.forEach(p => {
        const p2 = {};
        p2.propostaEletronicaPlanoId = p.propostaEletronicaPlanoId;
        p2.propostaEletronicaPlanoSigla = p.propostaEletronicaPlanoSigla;
        p2.propostaEletronicaPlanoTipo = p.propostaEletronicaPlanoTipo;
        p2.propostaEletronicaPlanoCodParceiro =
          p.propostaEletronicaPlanoCodParceiro;
        p2.mtrPropostaEletronicaPlanoPremioTotal = Mask.maskMoney(
          p.mtrPropostaEletronicaPlanoPremioTotal
        );

        if (
          planosAgrupado1.filter(
            pl =>
              pl.propostaEletronicaPlanoSigla ===
                p.propostaEletronicaPlanoSigla &&
              pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
          ).length === 0
        ) {
          planosAgrupado1.push(p2);
        }
      });

      // Agrupa por coberturas de acordo com a nomenclatura do antigo PPE
      planos.forEach(p => {
        p.propostaEletronicaPlanoCoberturas.forEach(pc => {
          planosAgrupado1.forEach(pl => {
            if (
              pl.propostaEletronicaPlanoSigla ===
                p.propostaEletronicaPlanoSigla &&
              pl.propostaEletronicaPlanoTipo ===
                p.propostaEletronicaPlanoTipo &&
              pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
            ) {
              pl.mtrIncendioQuedeDeRaioExplosao = Mask.maskMoney(
                pc.coberturaId === 202
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrIncendioQuedeDeRaioExplosao
              );
              pl.mtrPerdaDeAluguel = Mask.maskMoney(
                pc.coberturaId === 245 || pc.coberturaId === 571
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrPerdaDeAluguel
              );
              pl.mtrDanosEletricos = Mask.maskMoney(
                pc.coberturaId === 205
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrDanosEletricos
              );
              pl.mtrRouboDeBens = Mask.maskMoney(
                pc.coberturaId === 277
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRouboDeBens
              );
              pl.mtrRCOperacoes = Mask.maskMoney(
                pc.coberturaId === 222
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRCOperacoes
              );
              pl.mtrVendavalFumaca = Mask.maskMoney(
                pc.coberturaId === 420
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrVendavalFumaca
              );
              pl.mtrDespesasFixas = Mask.maskMoney(
                pc.coberturaId === 229
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrDespesasFixas
              );
            }
          });
        });
      });

      setPlanosAgrupado(planosAgrupado1);
    }
  }, [planos]);

  useEffect(() => {
    if (planoSelected) {
      // const index = planosAgrupado
      //   .map(e => e.propostaEletronicaPlanoId)
      //   .indexOf(planoSelected.propostaEletronicaPlanoId);
      setPlanoSelectedKey([planoSelected.propostaEletronicaPlanoId]);
    } else if (propostaEletronicaPlanoId) {
      const plano = planosAgrupado.find(
        e => e.propostaEletronicaPlanoId === propostaEletronicaPlanoId
      );
      // .map(e => e.propostaEletronicaPlanoId)
      // .indexOf(propostaEletronicaPlanoId);
      setPlanoSelectedKey([propostaEletronicaPlanoId]);
      onSelectedPlano(plano);
    }
  }, [planosAgrupado]);

  function getRegraComissaoCorretora() {
    const data = {
      produtoId: Constants.Produtos.DreZurichEmp,
      seguradoraId: Constants.Seguradoras.Zurich,
    };
    dispatch(ProducaoActions.getRegraComissaoCorretora(data));
  }

  function changeFieldRedux(field, value) {
    dispatch(PropostaActions.changeDreZurichEmp({ field, value }));
  }

  function onChangeEnderecoLocalRisco(value) {
    dispatch(
      PropostaActions.changeDreZurichEmp({ field: 'bemEndereco', value })
    );
  }

  function onChangeCpf(value) {
    setErros([]);
    if (!Validation.isCpfCnpjValid(value)) {
      setErros(['CNPJ do Proponente Inválido!']);
    } else {
      changeFieldRedux('propCNPJ', value);
    }
  }

  function onChangeTipoImovel(tipoImovel) {
    const param = {
      seguradoraId: Constants.Seguradoras.Zurich,
      produtoId: Constants.Produtos.DreZurichEmp,
      tipoImovel,
    };

    dispatch(PlanoActions.getPlanos({ param }));
  }

  function onChangeAtividade(atividadeId) {
    const atividadeSelected = propostaEletronicaAtividades.filter(
      p => p.propostaEletronicaAtividadeId === atividadeId
    )[0];
    const param = {
      seguradoraId: Constants.Seguradoras.Zurich,
      produtoId: Constants.Produtos.DreZurichEmp,
      tipoImovel: atividadeSelected.propostaEletronicaPlanoTipoId,
    };

    dispatch(PlanoActions.getPlanos({ param }));
  }

  function changeControlador(field, value, index) {
    const newArray = dreZurichEmp.controladores.map((controlador, i) => {
      if (i === index) {
        controlador[field] = value;
      }

      return controlador;
    });

    changeFieldRedux({ controladores: newArray });
  }

  function newControlador() {
    // Cria novo obj
    const newObj = {
      controladorNome: '',
      controladorCpfCnpj: '',
      controladorNacionalidade: '',
      controladorPublico: '',
    };
    // Add na lista
    dreZurichEmp.controladores.push(newObj);
    // Atualiza o estado
    changeFieldRedux({ controladores: dreZurichEmp.controladores });
  }

  function deleteControlador() {
    const ben = dreZurichEmp.controladores;
    ben.pop();
    changeFieldRedux({
      controladores: ben,
    });
  }

  function changePatrimonioLiquido(value) {
    value = Functions.getMoney(value);
    const valueMasked = Mask.maskMoney(value);
    setPropPatrimonioLiq(valueMasked);
    changeFieldRedux('propPatrimonioLiquido', value);
  }

  function showCoberturas(coberturaId) {
    let isShow = false;
    if (planos) {
      planos.forEach(p => {
        p.propostaEletronicaPlanoCoberturas.forEach(pc => {
          if (pc.coberturaId === coberturaId) {
            isShow = true;
          }
        });
      });
    }

    return isShow;
  }

  function onSelectedPlano(value) {
    dispatch(
      PropostaActions.changeDreZurichEmp({
        field: 'planoSelected',
        value,
      })
    );
  }

  async function onSalvar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }
    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();
      dispatch(PropostaActions.save({ param }));
    } else setErros(isValid);
  }

  async function validaProposta() {
    let response = await validaObjeto(
      ProducaoValidation.ProducaoValidationDre,
      producao
    );
    if (!response.ok) return response;

    response = await validaObjeto(
      PropostaValidation.DreZurichEmp,
      dreZurichEmp
    );
    if (!response.ok) return response;

    if (pagamento.formaPagamento === 1)
      response = await validaObjeto(PagamentoValidation.AVista, pagamento);
    else if (pagamento.formaPagamento === 2)
      response = await validaObjeto(
        PagamentoValidation.ParceladoCEntrada,
        pagamento
      );
    else
      response = await validaObjeto(
        PagamentoValidation.ParceladoSEntrada,
        pagamento
      );

    if (response && !response.ok) return response;

    return { ok: true };
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function getObjetoEnvio() {
    if (!planoSelected) return null;
    if (!endereco.clienteEnderecoId) cliente.clienteEnderecos.push(endereco);

    const bemCoberturas = [];
    if (planoSelected) {
      bemCoberturas.push({
        coberturaId: 202,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrIncendioQuedeDeRaioExplosao
        ),
      });
      bemCoberturas.push({
        coberturaId: 245,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrPerdaDeAluguel
        ),
      });
      bemCoberturas.push({
        coberturaId: 205,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrDanosEletricos
        ),
      });
      bemCoberturas.push({
        coberturaId: 277,
        mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRouboDeBens),
      });
      bemCoberturas.push({
        coberturaId: 222,
        mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRCOperacoes),
      });
      bemCoberturas.push({
        coberturaId: 420,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrVendavalFumaca
        ),
      });
      bemCoberturas.push({
        coberturaId: 229,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrDespesasFixas
        ),
      });
    }

    const bem = {
      bemCoberturas,
      bemEndereco: getBemEndereco(),
      mtrBemDREImportanciaSegurada: Functions.getMoney(
        planoSelected.mtrIncendioQuedeDeRaioExplosao
      ),
      objetoSeguradoId: bemEndereco.tipoImovel === 1 ? 10 : 20,
    };

    const premioTotal = Functions.getMoney(
      planoSelected.mtrPropostaEletronicaPlanoPremioTotal
    );

    const perIof = 7.38 / 100 + 1;
    const iof = parseFloat(premioTotal - premioTotal / perIof);
    const premioLiquido = parseFloat(premioTotal - iof);

    const propostaEletronicaJsonContrato = {
      bens: [bem],
      contratoTipoId: producao.contratoTipoId,
      clienteId: cliente.clienteId,
      contratoDREDataInicio: dreZurichEmp.contratoDREDataInicio,
      contratoDREDataFim: dreZurichEmp.contratoDREDataFim,
      seguradoraId: 5495,
      contratoFormaPagamentoId: getFormaPagamento(),
      mtrContratoDRE1Parcela:
        pagamento.formaPagamento === 1
          ? premioTotal
          : pagamento.mtrContratoDRE1Parcela,
      mtrContratoDREPremioTotal: premioTotal,
      mtrContratoDREIof: iof,
      mtrContratoDREFracionado: 0,
      mtrContratoDREComissao:
        premioLiquido *
        regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
      contratoDREQtdParcela:
        pagamento.formaPagamento === 1 // À vista
          ? 0
          : pagamento.contratoDREQtdParcela,
      mtrContratoDREDemaisParcelas:
        pagamento.formaPagamento === 1 // À vista
          ? null
          : pagamento.mtrContratoDRE1Parcela,
      contratoDREVencimento1Parcela: pagamento.formaPagamento === 1 
                                      ? moment().add(1, 'M').format()
                                      : moment().set("date", pagamento.contratoDREDiaVencimentoDemais).add(1, 'M').format(),
      contratoDREDiaVencimentoDemais:
        pagamento.formaPagamento === 1
          ? 0
          : pagamento.contratoDREDiaVencimentoDemais,
      debitoConta: {
        agencia: pagamento.agencia,
        agenciaId: pagamento.agencia?.agenciaId,
        produtoId: Constants.Produtos.DreZurichEmp,
        debitoContaNumeroConta: pagamento.debitoContaNumeroConta,
      },
      mtrContratoDREPremioLiquido: premioLiquido,
      mtrContratoDREComissaoPercent:
        regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
      contratoDREObservacao: 'PROPOSTA ELETRÔNICA NOVO PPE',
      ...producao,
      contratoDREDataCadastro: moment().format(),
      produtoId: Constants.Produtos.DreZurichEmp,
      tipoInclusaoId: 2,
      contratoStatusId: 1,
      contratoGrupoTipoId: 1,
      regraComissaoProdutor: regraComissaoProdutor.regraComissaoProdutor,
    };

    const propostaEletronicaJsonDemaisDados = {
      isProdutor: producao.isProdutor,
      isOutrosSeguros: dreZurichEmp.isOutrosSeguros,
      apoliceOutrosSeg: dreZurichEmp.apoliceOutrosSeg,
      seguradoraOutrosSeg: dreZurichEmp.seguradoraOutrosSeg,
      tipoImovel: bemEndereco.tipoImovel,
      propostaEletronicaPlanoSigla: planoSelected.propostaEletronicaPlanoSigla,
      formaPagamentoTipo: pagamento.formaPagamento,
      propostaEletronicaPlanoCodParceiro:
        planoSelected.propostaEletronicaPlanoCodParceiro,
      apoliceAnterior: producao.apoliceAnterior,
      seguradoraIdAnterior: producao.seguradoraIdAnterior,
      bemAtividade: bemEndereco.bemAtividade,
      proponente: getDadosProponente(),
    };

    const objetoEnvio = {
      clienteId: cliente.clienteId,
      cliente,
      produtoId: Constants.Produtos.DreZurichEmp,
      moduloId: Constants.Modulos.RISCO_DIVERSOS,
      propostaEletronicaPlanoId: planoSelected.propostaEletronicaPlanoId,
      propostaEletronicaJsonContrato: JSON.stringify(
        propostaEletronicaJsonContrato
      ),
      propostaEletronicaJsonDemaisDados: JSON.stringify(
        propostaEletronicaJsonDemaisDados
      ),
      propostaEletronicaDataCadastro: moment().format(),
      propostaEletronicaNossoNumero: pagamento.propostaEletronicaNossoNumero,
    };

    return objetoEnvio;
  }

  function getDadosProponente() {
    const prop = {
      propRazaoSocial: dreZurichEmp.propRazaoSocial,
      propCNPJ: dreZurichEmp.propCNPJ,
      propAtividade: dreZurichEmp.propAtividade,
      propPatrimonioLiquido: dreZurichEmp.propPatrimonioLiquido,
      propPEP: dreZurichEmp.propPEP,
      propClausulaBeneficiaria: dreZurichEmp.propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj:
        dreZurichEmp.propClausulaBeneficiariaCpfCnpj,
      propCobertura: dreZurichEmp.propCobertura,
      controladores: dreZurichEmp.controladores,
    };

    return prop;
  }

  function getBemEndereco() {
    if (!bemEndereco) return null;
    return {
      bemEnderecoCep: Functions.getOnlyNum(bemEndereco.clienteEnderecoCep),
      bemEnderecoBairro: bemEndereco.clienteEnderecoBairro,
      bemEnderecoCidade: bemEndereco.clienteEnderecoCidade,
      bemEnderecoEndereco: bemEndereco.clienteEndereco1,
      bemEnderecoUf: bemEndereco.clienteEnderecoUf,
      BemEnderecoNumero: bemEndereco.clienteEnderecoNumero,
      BemEnderecoComplemento: bemEndereco.clienteEnderecoComplemento,
    };
  }

  function getFormaPagamento() {
    if (pagamento.formaPagamento === 1 && !pagamento.isBoleto) return 1; // A VISTA\DEBITO
    if (pagamento.formaPagamento === 1 && pagamento.isBoleto) return 6; // A VISTA\CREDITO
    if (pagamento.formaPagamento === 2 && pagamento.isBoleto) return 8; // FRAC\CRED\CARNE
    if (pagamento.formaPagamento === 2 && !pagamento.isBoleto) return 15; // FCA + DÉBITO
    if (pagamento.formaPagamento === 3 && pagamento.isBoleto) return 8; //	FRAC\CRED\CARNE
    if (pagamento.formaPagamento === 3 && !pagamento.isBoleto) return 5; //	FRACION\DEBITO
  
    return 5;
  }

  async function onEfetivar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }
    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();

      dispatch(
        PropostaActions.efetivar({ param, callback: callbackErrorEfetivar })
      );
    } else setErros(isValid);
  }

  function callbackErrorEfetivar(values) {
    setErros(values);
  }

  function Botoes() {
    return (
      <div className="actions-tabs" style={{ paddingBottom: 10 }}>
        <Button size="sm" onClick={onPrevius} with="link" type="primary">
        <ArrowLeftOutlined/>  Voltar
        </Button>
        {!contratoId && (
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            onClick={onSalvar}
          >
            <SaveOutlined />  Salvar
          </Button>
        )}
        {propostaId > 0 && !contratoId && (
          <Popconfirm
            placement="topLeft"
            title="Deseja realmente efetivar esse contrato?"
            onConfirm={onEfetivar}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              size="sm"
              style={{ marginLeft: '.5rem' }}
              type="info"
            >
              <CheckOutlined />  Efetivar
            </Button>
          </Popconfirm>
        )}
        {propostaId > 0 && (
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            target="_blank"
            href="/impressao-dre-zurich-emp"
          >
            <PrinterOutlined /> Imprimir
          </Button>
        )}
      </div>
    );
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }

  function Erros() {
    return (
      <ul>
        {erros.map(e => (
          <li>{e}</li>
        ))}
      </ul>
    );
  }

  return (
    <>
      <BackTop />
      <Spin spinning={loadingProposta} size="large">
        <Botoes />
        <Container>
          {contratoId > 0 && (
            <Result
              status="success"
              title="Parabéns, produto contratado com sucesso!"
              subTitle={`O número do contrato no Multiseguros é: ${contratoId} e você já pode consultá-lo lá.`}
              extra={[
                <Button
                  type="primary"
                  href={`https://multiseguros.brbseguros.com.br/#/proposta-dre/${contratoId}/11802`}
                  target="_blank"
                  key="1"
                >
                  Ir ao Multiseguros
                </Button>,
              ]}
            />
          )}

          {erros.length > 0 && (
            <Alert
              message="Atenção, verifique os campos abaixo:"
              description={<Erros />}
              type="error"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}

          <Disable disable={!!contratoId}>
            <h4>Empresarial Zurich</h4>

            <FieldsProducao produto="empresarial-zurich" />

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Início da Vigência</label> <br />
                  <DatePicker
                    value={dreZurichEmp.contratoDREDataInicio}
                    placeholder="Selecione"
                    onChange={value => {
                      changeFieldRedux('contratoDREDataInicio', value);
                      changeFieldRedux(
                        'contratoDREDataFim',
                        moment(value).add(1, 'y')
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Fim da Vigência</label> <br />
                  <DatePicker
                    value={dreZurichEmp.contratoDREDataFim}
                    placeholder="Selecione"
                    onChange={value =>
                      changeFieldRedux('contratoDREDataFim', value)
                    }
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <div className="form-group">
                  <label>Há outros seguros cobrindo os mesmos bens?</label>{' '}
                  <br />
                  <Radio.Group
                    onChange={({ target: { value } }) =>
                      changeFieldRedux('isOutrosSeguros', value)
                    }
                    value={isOutrosSeguros}
                  >
                    <Radio value={1}>Sim</Radio>
                    <Radio value={0}>Não</Radio>
                  </Radio.Group>
                </div>
              </Col>

              {isOutrosSeguros === 1 && (
                <Row gutter={[16, 16]}>
                  <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                    <div className="form-group">
                      <label>Apólice Nº</label>
                      <Input
                        placeholder="Apólice Nº"
                        value={apoliceOutrosSeg}
                        onChange={value => setApoliceOutrosSeg(value)}
                        onBlur={value =>
                          changeFieldRedux('apoliceOutrosSeg', value)
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                    <div className="form-group">
                      <label>Seguradora</label>
                      <Select
                        defaultValue="Selecione"
                        itens={seguradoras}
                        itemValue="seguradoraId"
                        itemText="seguradoraDescricao"
                        itemTextSearch="seguradoraDescricao"
                        renderText={item =>
                          `${item.seguradoraId} - ${item.seguradoraDescricao}`
                        }
                        value={dreZurichEmp.seguradoraOutrosSeg}
                        onChange={value =>
                          changeFieldRedux('seguradoraOutrosSeg', value)
                        }
                        showSearch
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Row>

            <TitleSection title="Dados do Proponente" />
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Razão Social</label>
                  <Input
                    value={propRazaoSocial}
                    onChange={value => setPropRazaoSocial(value)}
                    onBlur={value => changeFieldRedux('propRazaoSocial', value)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>CNPJ</label>
                  <Input
                    value={propCNPJ}
                    onChange={value => setPropCNPJ(value)}
                    onBlur={value => onChangeCpf(value)}
                    mask="cpfCnpj"
                    maxLength={18}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <div className="form-group">
                  <label>Atividade</label>
                  <Select
                    showSearch
                    defaultValue="Selecione"
                    itens={atividades}
                    itemValue="propostaEletronicaAtividadeCodParceiroNicho"
                    itemText="propostaEletronicaAtividadeDescricao"
                    value={dreZurichEmp.propAtividade}
                    onChange={value => changeFieldRedux('propAtividade', value)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Patrimônio Líquido R$</label>
                  <Input
                    value={propPatrimonioLiq}
                    onChange={value => setPropPatrimonioLiq(value)}
                    onBlur={changePatrimonioLiquido}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Pessoa Exposta Politicamente?</label> <br />
                  <Radio.Group
                    onChange={({ target: { value } }) =>
                      changeFieldRedux('propPEP', value)
                    }
                    value={dreZurichEmp.propPEP}
                  >
                    <Radio value="S">Sim</Radio>
                    <Radio value="N">Não</Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                Nome (Controlador)
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                CPF / CNPJ
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                Nacionalidade
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                Controlador é agente público?
              </Col>
            </Row>
            {dreZurichEmp.controladores.map((controlador, index) => {
              return (
                <Row gutter={[16, 16]} key={index.toString()}>
                  <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                    <Input
                      placeholder="Nome (Controlador)"
                      value={controlador.controladorNome}
                      onChange={value =>
                        changeControlador('controladorNome', value, index)
                      }
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                    <Input
                      placeholder="CPF/CNPJ"
                      value={controlador.controladorCpfCnpj}
                      onChange={value =>
                        changeControlador('controladorCpfCnpj', value, index)
                      }
                      mask="cpfCnpj"
                      maxLength={18}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                    <div className="form-group">
                      <Select
                        showSearch
                        defaultValue="Selecione"
                        itens={listNacionalidades}
                        itemValue="nacionalidadeZurichId"
                        itemText="nacionalidadeDescricao"
                        value={controlador.controladorNacionalidade}
                        onChange={value =>
                          changeControlador(
                            'controladorNacionalidade',
                            value,
                            index
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                    <Radio.Group
                      onChange={({ target: { value } }) =>
                        changeControlador('controladorPublico', value, index)
                      }
                      value={controlador.controladorPublico}
                    >
                      <Radio value="S">Sim</Radio>
                      <Radio value="N">Não</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col>
                <Button
                  className="btn btn-xs-block"
                  type="primary"
                  onClick={newControlador}
                  style={{ marginRight: 20 }}
                >
                  <Icon type="plus" />
                  Incluir Controlador
                </Button>

                <Button
                  className="btn  btn-xs-block mt-2 mt-sm-0"
                  type="danger"
                  onClick={deleteControlador}
                  disabled={dreZurichEmp.controladores.length === 1}
                >
                  <Icon type="delete" />
                  Excluir Último Controlador
                </Button>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Cláusula Beneficiária</label>
                  <Input
                    value={dreZurichEmp.propClausulaBeneficiaria}
                    onChange={value =>
                      changeFieldRedux('propClausulaBeneficiaria', value)
                    }
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>CPF / CNPJ</label>
                  <Input
                    value={dreZurichEmp.propClausulaBeneficiariaCpfCnpj}
                    onChange={value =>
                      changeFieldRedux('propClausulaBeneficiariaCpfCnpj', value)
                    }
                    mask="cpfCnpj"
                    maxLength={18}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <div className="form-group">
                  <label>Cobertura Para:</label> <br />
                  <Radio.Group
                    onChange={({ target: { value } }) =>
                      changeFieldRedux('propCobertura', value)
                    }
                    value={dreZurichEmp.propCobertura}
                  >
                    <Radio value={1}>Exclusivamente Conteúdo</Radio>
                    <Radio value={2}>Exclusivamente Prédio</Radio>
                    <Radio value={3}>Prédio e Conteúdo</Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>

            <FieldsEndereco
              title="Local de Risco"
              onChangeEndereco={onChangeEnderecoLocalRisco}
              enderecoRedux={dreZurichEmp.bemEndereco}
              onChangeTipoImovel={onChangeTipoImovel}
              onChangeAtividade={onChangeAtividade}
              produtoId={Constants.Produtos.DreZurichEmp}
            />
          </Disable>

          <TitleSection title="Opções de planos" />
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Table
                rowKey="propostaEletronicaPlanoId"
                dataSource={planosAgrupado}
                pagination={false}
                loading={loading}
                size="small"
                scroll={{ x: 1000 }}
                rowSelection={{
                  type: 'radio',
                  onChange: (selectedRowKeys, selectedRows) => {
                    onSelectedPlano(selectedRows[0]);
                    setPlanoSelectedKey(selectedRowKeys);
                  },
                  selectedRowKeys: planoSelectedKey,
                  getCheckboxProps: () => ({
                    disabled: !!contratoId,
                  }),
                }}
                title={() => (
                  <span>
                    Os planos serão exibidos de acordo com a Atividade
                    Desenvolvida selecionada
                  </span>
                )}
              >
                {planosAgrupado.length > 0 && (
                  <Table.Column
                    title="Plano"
                    dataIndex="propostaEletronicaPlanoSigla"
                  />
                )}
                {showCoberturas(202) && (
                  <Table.Column
                    title="Incêndio/ Queda de Raio/ Explosão"
                    dataIndex="mtrIncendioQuedeDeRaioExplosao"
                  />
                )}
                {showCoberturas(245) && (
                  <Table.Column
                    title="Perda de Aluguel"
                    dataIndex="mtrPerdaDeAluguel"
                  />
                )}
                {showCoberturas(205) && (
                  <Table.Column
                    title="Danos Elétricos"
                    dataIndex="mtrDanosEletricos"
                  />
                )}
                {showCoberturas(277) && (
                  <Table.Column
                    title="Roubo de Bens"
                    dataIndex="mtrRouboDeBens"
                  />
                )}
                {showCoberturas(222) && (
                  <Table.Column
                    title="RC Operações"
                    dataIndex="mtrRCOperacoes"
                  />
                )}
                {showCoberturas(420) && (
                  <Table.Column
                    title="Vendaval até Fumaça"
                    dataIndex="mtrVendavalFumaca"
                  />
                )}
                {showCoberturas(229) && (
                  <Table.Column
                    title="Despesas Fixas"
                    dataIndex="mtrDespesasFixas"
                  />
                )}
                {planosAgrupado.length > 0 && (
                  <Table.Column
                    title="Prêmio"
                    dataIndex="mtrPropostaEletronicaPlanoPremioTotal"
                  />
                )}
              </Table>
            </Col>
          </Row>
          <span><i>Remuneração pela intermediação de 40% sobre o prêmio</i></span>
          <Disable disable={!!contratoId}>
            <Pagamento />
          </Disable>
        </Container>
        <Botoes />
      </Spin>
    </>
  );
}
