import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Radio, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'antd-button-color';
import { TitleSection, Select, Input, AutoComplete } from '~/components/';
import * as DneActions from '~/store/ducks/dne';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'

import { Container } from './style';
import { Constants } from '~/utils';

type EnderecoProps = {
  title: String,
  enderecoRedux?: Object,
  onChangeEndereco: Function,
  showTipoImovel?: Boolean,
  onChangeTipoImovel: Function,
  onChangeAtividade: Function,
  produtoId: Number,
  showMesmoCorrespondencia?: Boolean,
};

export default function Endereco({
  title,
  onChangeEndereco,
  enderecoRedux,
  showTipoImovel,
  onChangeTipoImovel,
  onChangeAtividade,
  produtoId,
  showMesmoCorrespondencia,
}: EnderecoProps) {
  // Redux state
  const { clienteEnderecos } = useSelector(
    ({ cliente }) => cliente.cliente || {}
  );
  const { endereco: enderecoInformado } = useSelector(({ cliente }) => cliente);
  const { ufs } = useSelector(({ dominio }) => dominio);
  const { loading, enderecos } = useSelector(({ dne }) => dne);
  const { propostaEletronicaAtividades } = useSelector(
    ({ dominio }) => dominio
  );

  const {listaNomesDominios: listaNomesDominiosResidencialLibertyPersonalizavel} = useSelector(state => state.libertyResidencialPersonalizavel)

  // Component state
  const [cep, setCep] = useState();
  const [endereco, setEndereco] = useState({});
  const [atividades, setAtividades] = useState([]);
  const [disabledBairro, setDisabledBairro] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (enderecoRedux) {
      setCep(enderecoRedux.clienteEnderecoCep);
    }
  }, []);

  useEffect(() => {
    setEndereco(enderecoRedux);

    if (!enderecoRedux.clienteEnderecoBairro) setDisabledBairro(false);
  }, [enderecoRedux]);

  useEffect(() => {
    if (propostaEletronicaAtividades) {
      const a = propostaEletronicaAtividades.filter(
        c =>
          c.seguradoraId === Constants.Seguradoras.Zurich &&
          c.propostaEletronicaAtividadeCodParceiro !=
            Constants.Produtos.DreZurichEmp
      );

      setAtividades(a);
    }
  }, []);

  function isLibertyResidencialPersonalizavel() {
    return produtoId === Constants.Produtos.LibertyResidencialPersonalizavel;
  } 

  function retornaListaTipoLogradouroLibertyPersonalizavel() {
    var retorno = [];

    var retorno = ((listaNomesDominiosResidencialLibertyPersonalizavel ?? [])
      .find(x => x.dominioParceiroNomeDescricao.includes('Tipo Logradouro')) ?? {dominioParceiros: []}).dominioParceiros;

    return retorno;
  }

  function isCap() {
    return (
      produtoId === Constants.Produtos.CapIcatuPU ||
      produtoId === Constants.Produtos.CapIcatu24 ||
      produtoId === Constants.Produtos.CapIcatu48 ||
      produtoId === Constants.Produtos.CapIcatu60 ||
      produtoId === Constants.Produtos.CapIcatu84
    );
  }

  function changeEndereco(id) {
    const e = id
      ? clienteEnderecos.find(i => i.clienteEnderecoId === id)
      : enderecoInformado;
    e.tipoImovel = enderecoRedux.tipoImovel;
    onChangeEndereco(e);
    setCep(e.clienteEnderecoCep);
  }

  function onClickNovoEndereco() {
    onChangeEndereco({ tipoImovel: enderecoRedux.tipoImovel });
    setCep();
  }

  function onChangeCep(value, a) {
    setCep(value);
    if (value && value.length >= 3 && value === a.key) {
      value = value.replace('-', '');
      dispatch(DneActions.getCepGeral({ param: value }));
    }
  }

  function onSelect(value) {
    const novoEndereco = JSON.parse(value);

    const newE = {
      tipoImovel: enderecoRedux.tipoImovel,
      clienteEndereco1: novoEndereco.endereco,
      clienteEnderecoCep: novoEndereco.cep,
      clienteEnderecoBairro: novoEndereco.bairro,
      clienteEnderecoCidade: novoEndereco.cidade,
      clienteEnderecoUf: novoEndereco.uf,
    };

    onChangeEndereco(newE);
  }

  function onChangeField(field, value) {
    setEndereco({ ...endereco, [field]: value });
  }

  function onChangeFieldRedux(field, value) {
    const newEndereco = { ...enderecoRedux, [field]: value };
    onChangeEndereco(newEndereco);
  }

  function changeTipoImovel(value) {
    onChangeFieldRedux('tipoImovel', value);
    if (onChangeTipoImovel) onChangeTipoImovel(value);
  }

  function changeAtividade(value) {
    onChangeFieldRedux('bemAtividade', value);
    if (onChangeAtividade) onChangeAtividade(value);
  }

  function getListEnderecos() {
    if (!clienteEnderecos) return [enderecoInformado];
    const listE = clienteEnderecos.find(
      e => e.clienteEnderecoCep === enderecoInformado.clienteEnderecoCep
    );

    if (listE || !enderecoInformado.clienteEnderecoCep) return clienteEnderecos;

    return clienteEnderecos.concat([enderecoInformado]);
  }

  function onPressMesmoCorrespondencia(value) {
    if (value) {
      const e = enderecoInformado;
      e.tipoImovel = enderecoRedux.tipoImovel;
      onChangeEndereco(e);
      setCep(e.clienteEnderecoCep);
    } else onClickNovoEndereco();
  }

  return (
    <>
      {title !== '' && <TitleSection title={title} />}

      <Container>
        {clienteEnderecos && !isCap() && (
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <div className="form-group">
                <label>
                  Selecione um endereço cadastrado ou cadastre um novo
                </label>

                <Select
                  placeholder="Selecione"
                  itens={getListEnderecos()}
                  renderText={item =>
                    `${item.clienteEndereco1}, ${item.clienteEnderecoNumero ||
                      ''} - ${item.clienteEnderecoBairro} - ${
                      item.clienteEnderecoCidade
                    } / ${item.clienteEnderecoUf}`
                  }
                  onChange={changeEndereco}
                  itemValue="clienteEnderecoId"
                  value={endereco.clienteEnderecoId}
                />
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }} style={{ paddingTop: 35 }}>
              <Button
                className="btn btn-outline-secondary"
                onClick={onClickNovoEndereco}
                type="success"
              >
                <PlusOutlined /> Novo Endereço
              </Button>
              {showMesmoCorrespondencia && (
                <Checkbox
                  style={{ marginLeft: 10 }}
                  onChange={({ target: { checked } }) =>
                    onPressMesmoCorrespondencia(checked)
                  }
                >
                  Mesmo de correspondência?
                </Checkbox>
              )}
            </Col>

            {/* <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <label> </label> <br />
              <div className="form-group">
                
              </div>
            </Col> */}
          </Row>
        )}

        {showTipoImovel && (
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <div className="form-group">
                <label style={{ marginRight: 10 }}>Tipo de Imóvel</label>
                <Radio.Group
                  onChange={({ target: { value } }) => changeTipoImovel(value)}
                  value={endereco.tipoImovel}
                >
                  {produtoId === Constants.Produtos.DreMitsui ||
                  produtoId === Constants.Produtos.DreZurichRes ? (
                    <Radio value={2}>Casa</Radio>
                  ) : (
                    ''
                  )}
                  {produtoId === Constants.Produtos.DreMitsui ||
                  produtoId === Constants.Produtos.DreZurichRes ? (
                    <Radio value={1}>Apartamento</Radio>
                  ) : (
                    ''
                  )}
                  {produtoId === Constants.Produtos.DreLibertyCeS ? (
                    <Radio value={3}>Comércio</Radio>
                  ) : (
                    ''
                  )}
                  {produtoId === Constants.Produtos.DreLibertyCeS ? (
                    <Radio value={4}>Prestação de Serviço</Radio>
                  ) : (
                    ''
                  )}
                </Radio.Group>
              </div>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <div className="form-group">
              <label>Cep</label>
              <AutoComplete
                placeholder="Cep"
                value={cep}
                onChange={onChangeCep}
                onSelect={onSelect}
                itens={enderecos}
                itemText="text"
              />
              <Spin
                style={{ marginLeft: -30, marginTop: 1 }}
                spinning={loading}
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 10 }}>
            <div className="form-group">
              <label>Endereço</label>
              <Input
                placeholder="Endereco"
                value={endereco.clienteEndereco1}
                onChange={value => onChangeField('clienteEndereco1', value)}
                onBlur={value => onChangeFieldRedux('clienteEndereco1', value)}
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <div className="form-group">
              <label>Cidade</label>
              <Input
                placeholder="Cidade"
                disabled
                value={endereco.clienteEnderecoCidade}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <div className="form-group">
              <label>Bairro</label>
              <Input
                placeholder="Bairro"
                disabled={disabledBairro}
                value={endereco.clienteEnderecoBairro}
                onChange={value =>
                  onChangeField('clienteEnderecoBairro', value)
                }
                onBlur={value =>
                  onChangeFieldRedux('clienteEnderecoBairro', value)
                }
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 3 }}>
            <div className="form-group">
              <label>Estado</label>
              <Select
                itens={ufs}
                itemText="text"
                itemValue="value"
                onChange={value =>
                  onChangeFieldRedux('clienteEnderecoUf', value)
                } //
                // onBlur={value => onChangeFieldRedux('clienteEnderecoUf', value)} //
                value={endereco.clienteEnderecoUf}
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 7 }}>
            <div className="form-group">
              <label>Complemento</label>
              <Input
                placeholder="Complemento"
                value={endereco.clienteEnderecoComplemento}
                onChange={value =>
                  onChangeField('clienteEnderecoComplemento', value)
                }
                onBlur={value =>
                  onChangeFieldRedux('clienteEnderecoComplemento', value)
                }
              />
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <div className="form-group">
              <label>Número</label>
              <Input
                placeholder="Número"
                value={endereco.clienteEnderecoNumero}
                onChange={value =>
                  onChangeField('clienteEnderecoNumero', value)
                }
                onBlur={value =>
                  onChangeFieldRedux('clienteEnderecoNumero', value)
                }
              />
            </div>
          </Col>
          {produtoId === Constants.Produtos.DreZurichEmp && (
            <Col xs={{ span: 24 }} sm={{ span: 11 }}>
              <div className="form-group">
                <label>Atividade Desenvolvida no Local</label>
                <Select
                  defaultValue="Selecione"
                  itens={atividades}
                  itemValue="propostaEletronicaAtividadeId"
                  itemText="propostaEletronicaAtividadeDescricao"
                  itemTextSearch="propostaEletronicaAtividadeDescricao"
                  value={endereco.bemAtividade}
                  onChange={changeAtividade}
                  showSearch
                />
              </div>
            </Col>
          )}
        </Row>
        {
          isLibertyResidencialPersonalizavel() && (
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Tipo de logradouro</label>
                  <Select
                    defaultValue="Selecione"
                    itens={retornaListaTipoLogradouroLibertyPersonalizavel()}
                    itemValue="dominioParceiroCodigo"
                    itemText="dominioParceiroDescricao"
                    value={endereco.tipoLogradouroLibertyResidencialPersonalizavel}
                    onChange={value =>
                      onChangeFieldRedux('tipoLogradouroLibertyResidencialPersonalizavel', value)
                    }
                    showSearch
                  />
                </div>
              </Col>
            </Row>
          )
        }
      </Container>
    </>
  );
}

Endereco.defaultProps = {
  enderecoRedux: {},
  showTipoImovel: false,
  showMesmoCorrespondencia: false,
};
