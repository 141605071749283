function maskCpfCnpj(v) {
  if (!v) return '';
  // Remove tudo o que não é dígito
  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    // CPF
    return v
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto entre o terceiro e o quarto dígitos
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto entre o terceiro e o quarto dígitos
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
  }
  // CNPJ
  return v
    .replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto entre o segundo e o terceiro dígitos
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Coloca ponto entre o quinto e o sexto dígitos
    .replace(/\.(\d{3})(\d)/, '.$1/$2') // Coloca uma barra entre o oitavo e o nono dígitos
    .replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
}

function maskContaBrb(v) {
  if (!v) return '';
  // Remove tudo o que não é dígito
  v = v.replace(/\D/g, '');

  v = v.length > 11 ? v.substring(0, 11) : v;

  return v
    .replace(/(\d{4})(\d)/, '$1.$2') // Coloca um ponto entre o quarto e o quinto dígito
    .replace(/(\d{6})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de 6 dígitos
}

function maskCep(v) {
  if (!v) return '';
  return v.replace(/(\d{5})(\d)/, '$1-$2');
}

function onlyNumbers(v) {
  if (!v) return '';
  // Remove tudo o que não é dígito
  return v.replace(/\D/g, '');
}

function peso(v) {
  if (!v) return '';
  v = v.replace(/\D/g, '');

  if (!v) return '';

  return (v = Number(v).toString());
}

function altura(v) {
  if (!v) return '';

  v = v.replace(/\D/g, '');

  if (!v) return '';

  v = Number(v).toString();

  v = v.length > 3 ? v.slice(0, -1) : v;

  return v.length < 3
    ? v.length < 2
      ? `0,0${v}`
      : `0,${v}`
    : v.substring(0, v.length - 2) + ',' + v.substring(v.length - 2);
}

function decimal(value) {
  if (!value || value === 0) return '';

  value = value
    .toString()
    .replace('.', '')
    .replace(',', '')
    .replace(/\D/g, '');

  if (!value || value === '0' || value === '00' || value === '000') return '';

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  );

  return result;
}

function maskMoney(value, sigla = 'R$') {
  if (!value) return '0,00';
  if (value.toString().indexOf('R$') !== -1) return value;

  value = value.toString();

  value = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRA',
  })
    .format(value)
    .replace('BRA', '')
    .trim();

  return sigla ? `${sigla} ${value}` : value;
}

function maskMoneyOn(value, get) {
  if (!value || value === 0) return '';

  if (get) {
    const options = { minimumFractionDigits: 2 };
    return new Intl.NumberFormat('pt-BR', options).format(parseFloat(value));
  }

  value = value
    .toString()
    .replace('.', '')
    .replace(',', '')
    .replace(/\D/g, '');

  if (!value || value === '0' || value === '00' || value === '000') return '';

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  );

  return result;
}

function maskPhone(v) {
  if (!v) return null;

  v = v.toString();

  let r = v.replace(/\D/g, '');
  r = r.replace(/^0/, '');

  if (r.length > 10) {
    return r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  }

  if (r.length === 10) {
    return r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
  }

  if (r.length > 7) {
    return r.replace(/^(\d\d)(\d{5})(\d{1,2}).*/, '($1) $2-$3');
  }

  if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else {
    r = r.replace(/^(\d*)/, '($1');
  }
  return r;
}

export default {
  maskCpfCnpj,
  maskCep,
  maskMoney,
  onlyNumbers,
  peso,
  altura,
  decimal,
  maskPhone,
  maskContaBrb,
  maskMoneyOn,
};
