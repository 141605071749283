/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { Row, Col, Radio, Checkbox } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { TitleSection, Select, Input } from '~/components';

import { Mask } from '~/utils';

import * as PropostaActions from '~/store/ducks/proposta';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const formasPagamento = [
  { text: 'À vista', value: 1 },
  { text: 'Parcelado com entrada', value: 2 },
  { text: 'Parcelado sem entrada', value: 3 },
];

const qtdParcelas = [
  { value: 1, parcela: 2 },
  { value: 2, parcela: 3 },
  { value: 3, parcela: 4 },
  { value: 4, parcela: 5 },
  { value: 5, parcela: 6 },
  { value: 6, parcela: 7 },
  { value: 7, parcela: 8 },
  { value: 8, parcela: 9 },
  { value: 9, parcela: 10 },
];

/* 
237 - Bradesco
341 – Itau
033 – Santander
001 – Banco do Brasil
084 – Uniprime
085 – Ailos
133 – Cresol
136 - Unicred
*/
const outrosBancos = [
  { value: '0237', text: 'Bradesco' },
  { value: '0341', text: 'Itau' },
  { value: '0033', text: 'Santander' },
  { value: '0001', text: 'Banco do Brasil' },
  { value: '0084', text: 'Uniprime' },
  { value: '0085', text: 'Ailos' },
  { value: '0133', text: 'Cresol' },
  { value: '0136', text: 'Unicred' },
];

export default function Pagamento() {
  // Component state
  const [label, setLabel] = useState('');
  const [propostaEletronicaNossoNumero, setNossoNumero] = useState();
  const [debitoContaNumeroConta, setNumeroConta] = useState();
  const [agenciasOutros, setAgenciasOutros] = useState([]);

  // Redux state
  const {
    planoSelected,
    pagamento,
    pagamento: {
      formaPagamento,
      isBoleto,
      isDebitoAutomatico,
      contratoDREQtdParcela,
      contratoDREDiaVencimentoDemais,
      agencia,
      isDebitoBrb,
      isDebitoOutrosBancos,
    },
  } = useSelector(({ proposta }) => proposta.dreMitsui);

  const {
    cliente: { cliente },
    proposta: { contasDebito },
    auth: { usuario },
  } = useSelector(state => state);

  const isCnp = usuario?.descTipoProdutor === 'CNP';

  const { agenciasBrb, agencias } = useSelector(({ dominio }) => dominio);

  const dispatch = useDispatch();

  useEffect(() => {
    if (planoSelected) {
      if (formaPagamento === 1) {
        setLabel(
          `À Vista ${planoSelected.mtrPropostaEletronicaPlanoPremioTotal}`
        );
        onChangePagamento(
          'mtrContratoDRE1Parcela',
          planoSelected.mtrPropostaEletronicaPlanoPremioTotal
        );
      } else if (formaPagamento === 2 || formaPagamento === 3) {
        const demaisParcelas =
          parseFloat(
            planoSelected.mtrPropostaEletronicaPlanoPremioTotal
              .replace('R$ ', '')
              .replace('.', '')
          ) /
          (contratoDREQtdParcela + 1);

        const num = formaPagamento === 2 ? 1 : 0;

        setLabel(
          `${num} + ${contratoDREQtdParcela +
            (num === 0 ? 1 : 0)} Parcela(s) SEM JUROS de ${Mask.maskMoney(
            demaisParcelas
          )}`
        );

        onChangePagamento('mtrContratoDRE1Parcela', demaisParcelas);
      }
    }
    setNossoNumero(pagamento.propostaEletronicaNossoNumero);
    setNumeroConta(pagamento.debitoContaNumeroConta);
  }, [planoSelected, formaPagamento, contratoDREQtdParcela]);

  useEffect(() => {
    if (isCnp) {
      if (!isDebitoBrb && !isDebitoOutrosBancos)
        onChangePagamento('isDebitoBrb', true);

      if (isDebitoOutrosBancos && agencia) {
        const agenciasO = agencias?.filter(a => a.bancoId === agencia?.bancoId);
        setAgenciasOutros(agenciasO || []);
      }
    }

    dispatch(PropostaActions.getContas({ param: cliente?.clienteCpfCnpj }));
  }, []);

  useEffect(() => {
    if (contasDebito && contasDebito.length === 1) {
      const conta = contasDebito[0];
      changeContaDebito(conta.id);
    }
  }, [contasDebito]);

  function onChangeFormaPagamento({ target: { value } }) {
    onChangePagamento('formaPagamento', value);
  }

  function getMelhorDiaPagamento() {
    const list = [];
    for (let i = 1; i <= 31; i++) {
      list.push({ text: i, value: i });
    }

    return list;
  }

  function onChangePagamento(field, value) {
    // Valor default
    let newValue = { ...pagamento, [field]: value };
    // Se alterou a forma de pagamento
    if (field === 'formaPagamento') {
      // Se for a vista limpa a parcelas e demais dias
      if (value === 1) {
        newValue = {
          ...pagamento,
          formaPagamento: value,
          contratoDREQtdParcela: 1,
          contratoDREDiaVencimentoDemais: null,
        };
        // Se for parcelado sem entrada limpa o nosso numero
      } else if (value === 3) {
        newValue = {
          ...pagamento,
          formaPagamento: value,
          propostaEletronicaNossoNumero: null,
          isBoleto: false,
          isDebitoAutomatico: true,
        };
      }
    }

    if (field === 'isDebitoBrb') {
      newValue = {
        ...pagamento,
        isBoleto: false,
        isDebitoAutomatico: false,
        isDebitoBrb: true,
        isDebitoOutrosBancos: false,
        agencia: null,
      };
    } else if (field === 'isDebitoOutrosBancos') {
      newValue = {
        ...pagamento,
        isBoleto: false,
        isDebitoAutomatico: false,
        isDebitoBrb: false,
        isDebitoOutrosBancos: true,
        agencia: null,
      };
    } else if (field === 'isBoleto') {
      newValue = {
        ...pagamento,
        isBoleto: value,
        isDebitoAutomatico: !value,
      };
    } else if (field === 'isDebitoAutomatico') {
      newValue = {
        ...pagamento,
        isDebitoAutomatico: value,
        isBoleto: !value,
      };
      if (value) setNossoNumero(null);
    }

    // Altera o pagamento no redux
    dispatch(
      PropostaActions.changeDreMitsui({
        field: 'pagamento',
        value: newValue,
      })
    );
  }

  function changeContaDebito(contaId) {
    const conta = contasDebito.find(c => c.id === contaId);
    if (conta) {
      const newValue = {
        ...pagamento,
        debitoContaNumeroConta: conta.contaNumero,
        agencia: agenciasBrb.find(a => a.agenciaId === conta.agenciaId),
      };
      setNumeroConta(conta.contaNumero);
      dispatch(
        PropostaActions.changeDreMitsui({
          field: 'pagamento',
          value: newValue,
        })
      );
    }
  }

  function getContaDebito() {
    const conta = contasDebito?.find(
      c =>
        c.agenciaId === agencia?.agenciaId &&
        c.contaNumero === debitoContaNumeroConta
    );
    if (conta) return conta.id;

    return null;
  }

  function onChangeBanco(value) {
    // onChangePagamento('bancoId', value);
    onChangePagamento('agencia', { bancoId: value });

    const agenciasO = agencias?.filter(a => a.bancoId === value);
    setAgenciasOutros(agenciasO || []);
  }

  return (
    <>
      <TitleSection title="Forma de pagamento" />

      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={6}>
          <div className="form-group">
            <Radio.Group
              onChange={onChangeFormaPagamento}
              value={formaPagamento}
            >
              {formasPagamento.map(f => (
                <Radio key={f.value} style={radioStyle} value={f.value}>
                  {f.text}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <label style={{ fontSize: 12 }}>{label}</label>
        </Col>
        <Col span={6}>
          <div className="form-group">
            {isCnp && (
              <>
                <Checkbox
                  checked={isDebitoBrb}
                  value={isDebitoBrb}
                  onChange={value =>
                    onChangePagamento('isDebitoBrb', value.target.checked)
                  }
                >
                  Débito BRB
                </Checkbox>
                <Checkbox
                  checked={isDebitoOutrosBancos}
                  value={isDebitoOutrosBancos}
                  onChange={value =>
                    onChangePagamento(
                      'isDebitoOutrosBancos',
                      value.target.checked
                    )
                  }
                >
                  Débito outros bancos
                </Checkbox>
              </>
            )}
            {/* COMENTADO A FORMA DE PAGAMENTO BOLETO POR ENQUANTO */}
            {/* {(formaPagamento === 1 || formaPagamento === 2) && !isCnp && (
              <Checkbox
                checked={isBoleto}
                value={isBoleto}
                onChange={value =>
                  onChangePagamento('isBoleto', value.target.checked)
                }
              >
                {formaPagamento === 1 ? 'Boleto' : ''}
                {formaPagamento === 2 ? 'Entrada Boleto' : ''}
              </Checkbox>
            )} */}
            {!isCnp && (
              <Checkbox
                checked={isDebitoAutomatico}
                value={isDebitoAutomatico}
                disabled={formaPagamento === 3}
                onChange={value =>
                  onChangePagamento('isDebitoAutomatico', value.target.checked)
                }
              >
                {formaPagamento === 1 ? 'Débito Automático' : ''}
                {formaPagamento === 2 ? 'Entrada Débito' : ''}
                {formaPagamento === 3 ? 'Débito' : ''}
              </Checkbox>
            )}
          </div>
        </Col>

        <Col span={6}>
          {formaPagamento !== 3 && !pagamento.isDebitoAutomatico && !isCnp && (
            <div className="form-group">
              <label>Nosso Número</label>
              <Input
                placeholder="Nosso número"
                value={propostaEletronicaNossoNumero}
                onChange={value => setNossoNumero(value)}
                onBlur={value =>
                  onChangePagamento('propostaEletronicaNossoNumero', value)
                }
              />
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        {formaPagamento !== 1 && (
          <>
            <Col span={6}>
              <div className="form-group">
                <label>Quantidade de Parcelas</label>
                <Select
                  itens={qtdParcelas}
                  itemText="parcela"
                  itemValue="value"
                  value={contratoDREQtdParcela}
                  onChange={value =>
                    onChangePagamento('contratoDREQtdParcela', value)
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div className="form-group">
                {pagamento.isBoleto && <label>Melhor dia de pagamento</label>}
                {!pagamento.isBoleto && (
                  <label>
                    <label>Melhor dia para débito</label>
                  </label>
                )}

                <Select
                  itens={getMelhorDiaPagamento()}
                  itemText="text"
                  itemValue="value"
                  value={contratoDREDiaVencimentoDemais}
                  onChange={value =>
                    onChangePagamento('contratoDREDiaVencimentoDemais', value)
                  }
                />
              </div>
            </Col>
          </>
        )}
      </Row>
      {(!isCnp || !isDebitoOutrosBancos) && (
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 12 }} sm={{ span: 8 }}>
            <div className="form-group">
              <label>Contas do Cliente</label>
              <Select
                defaultValue="Selecione"
                itens={contasDebito}
                itemValue="id"
                renderText={conta =>
                  `${conta.agenciaCodigo} - ${conta.contaNumero}`
                }
                value={getContaDebito()}
                onChange={value => changeContaDebito(value)}
              />
            </div>
          </Col>
        </Row>
      )}

      {isCnp && isDebitoOutrosBancos && (
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 12 }} sm={{ span: 8 }}>
            <div className="form-group">
              <label>Banco</label>
              <Select
                defaultValue="Selecione"
                itens={outrosBancos}
                itemValue="value"
                itemText="text"
                renderText={v => `${v.value} - ${v.text}`}
                value={agencia?.bancoId}
                onChange={value => onChangeBanco(value)}
                showSearch
              />
            </div>
          </Col>
        </Row>
      )}

      <Row gutter={[24, 24]}>
        {isCnp && isDebitoOutrosBancos && (
          <Col span={12}>
            <div className="form-group">
              <label>Agência</label>
              <Select
                itens={agenciasOutros}
                itemValue="agenciaId"
                itemText="agenciaDescricao"
                itemTextSearch="agenciaCodigo"
                renderText={item =>
                  `${item.agenciaCodigo} - ${item.agenciaDescricao}`
                }
                showSearch
                value={agencia?.agenciaId}
                onChange={value =>
                  onChangePagamento(
                    'agencia',
                    agencias.find(a => a.agenciaId === value)
                  )
                }
              />
            </div>
          </Col>
        )}
        {(!isCnp || !isDebitoOutrosBancos) && (
          <Col span={12}>
            <div className="form-group">
              <label>Agência</label>
              <Select
                itens={agenciasBrb}
                itemValue="agenciaId"
                itemText="agenciaDescricao"
                itemTextSearch="agenciaCodigo"
                renderText={item =>
                  `${item.agenciaCodigo} - ${item.agenciaDescricao}`
                }
                showSearch
                value={agencia?.agenciaId}
                onChange={value =>
                  onChangePagamento(
                    'agencia',
                    agenciasBrb.find(a => a.agenciaId === value)
                  )
                }
                disabled={cliente?.clienteCpfCnpj.length < 14}
              />
            </div>
          </Col>
        )}

        <Col span={12}>
          <div className="form-group">
            <label>Número Conta</label>
            <Input
              placeholder="Número Conta"
              value={debitoContaNumeroConta}
              onChange={value => setNumeroConta(value)}
              onBlur={value =>
                onChangePagamento('debitoContaNumeroConta', value)
              }
              disabled={
                cliente?.clienteCpfCnpj.length < 14 && !isDebitoOutrosBancos
              }
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
