/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Spin,
  BackTop,
  Result,
  Alert,
  message,
  Card,
  Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PlusCircleFilled, CloseSquareFilled } from '@ant-design/icons';
import Buttons from '../components/Buttons';
import {
  TitleSection,
  FieldsProducao,
  Container,
  FieldsEndereco,
  Disable,
  Input,
  Select,
  DatePicker,
} from '~/components';

import { ProducaoValidation, PropostaValidation } from '~/yup';

import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';

import { Constants, Mask, Functions } from '~/utils';
import constants from '~/utils/constants';

type CapitalizacaoIcatuProps = {
  onReset: Function,
  onPrevius: Function,
};

export default function CapitalizacaoIcatu({
  onReset,
  onPrevius,
}: CapitalizacaoIcatuProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    capIcatu,
    producao,
    loading: loadingProposta,
    propostaId,
    contratoId,
    numeroControle,
    urlArquivo,
    produtoId,
    propostas,
  } = useSelector(state => state.proposta);
  const { agenciasBrb, agencias } = useSelector(({ dominio }) => dominio);
  const { cliente, endereco } = useSelector(state => state.cliente);
  const { regraComissaoCorretora, regraComissaoProdutor } = useSelector(
    state => state.producao
  );
  const { usuario } = useSelector(({ auth }) => auth);

  // Component state
  const [erros, setErros] = useState([]);
  const [debitoContaNumeroConta, setNumeroConta] = useState();
  const [debitoContaAgenciaId, setDebitoContaAgenciaId] = useState();
  const [titulos, setTitulos] = useState([]);
  const [dataHoraProposta, setDataHoraProposta] = useState('');
  const [isDebitoBrb, setIsDebitoBrb] = useState(true);
  const [isDebitoOutrosBancos, setIsDebitoOutrosBancos] = useState(false);
  const [debitoContaBancoId, setDebitoContaBancoId] = useState();
  const [agenciasOutros, setAgenciasOutros] = useState([]);

  // Constants
  const isCNP = usuario?.descTipoProdutor === 'CNP';

  useEffect(() => {
    getRegraComissaoCorretora();

    if (capIcatu.contratoCapitalizacaoDataPrimeiroDebito == null)
      changeField('contratoCapitalizacaoDataPrimeiroDebito', moment().format());

    setTitulos([
      {
        sequencial: 1,
        nomePresenteado: cliente.clienteNome,
        pessoaFisicaJuridica: cliente.clienteCpfCnpj.length > 11 ? 'J' : 'F',
        cpfCnpj: cliente.clienteCpfCnpj,
        dataNascimento: cliente.clienteDataNascimento,
        quantidade: 1,
        mensalidade: null,
      },
    ]);

    // Se proposta já salva
    if (propostaId > 0) {
      setNumeroConta(capIcatu.demaisDados.debitoContaNumeroConta);
      setDebitoContaAgenciaId(capIcatu.demaisDados.debitoContaAgenciaId);
      setDebitoContaBancoId(capIcatu.demaisDados.debitoContaBancoId);

      if (capIcatu.demaisDados.titulosSelected) {
        if (capIcatu.demaisDados.titulosSelected.length > 0) {
          setTitulos(capIcatu.demaisDados.titulosSelected);
        }
      }
    }
    if (contratoId > 0) {
      const sDataHoraProposta = propostas.find(
        p => p.propostaEletronicaId == propostaId
      ).propostaEletronicaDataEfetivacao;

      const dateHoraProposta = new Date(sDataHoraProposta);

      setDataHoraProposta(
        ` Essa proposta foi efetivada aproximadamente na data e horário: ${dateHoraProposta
          .toLocaleString('pt-BR')
          .toString()}`
      );
    }
  }, []);

  useEffect(() => {
    sumValorPago(titulos);
  }, [titulos]);

  useEffect(() => {
    if (urlArquivo) {
      window.open(
        urlArquivo
      );
    }
  }, [urlArquivo]);

  function getRegraComissaoCorretora() {
    const data = {
      produtoId,
      seguradoraId: Constants.Seguradoras.Icatu,
    };
    dispatch(ProducaoActions.getRegraComissaoCorretora(data));
  }

  function getMelhorDiaPagamento() {
    const list = [];
    for (let i = 1; i <= 31; i++) {
      if (produtoId === constants.Produtos.CapIcatuPU) {
        list.push({ text: i, value: i });
      } else if ([1, 7, 12, 17, 21, 25].includes(i))
        list.push({ text: i, value: i });
    }

    return list;
  }

  function changeField(field, value) {
    dispatch(PropostaActions.changeCapIcatu({ field, value }));
  }

  function addErros(erro) {
    let newErro = [];
    newErro.push(erro);
    setErros(oldArray => [...oldArray, newErro]);
  }

  async function onEfetivar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }

    if (!isTitulosValidos(titulos)) {
      message.error('Títulos inválidos.');
      return;
    }

    const param = getObjetoEnvio();
    const isValid = await validaProposta(
      JSON.parse(param.propostaEletronicaJsonContrato)
    );

    if (isValid.ok) {
      dispatch(
        PropostaActions.efetivar({ param, callback: callbackErrorEfetivar })
      );
    } else setErros(isValid);
  }

  async function onSalvar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }

    if (!isTitulosValidos(titulos)) {
      message.error('Títulos inválidos.');
      return;
    }

    const param = getObjetoEnvio();
    const isValid = await validaProposta(
      JSON.parse(param.propostaEletronicaJsonContrato)
    );

    if (isValid.ok) {
      dispatch(PropostaActions.save({ param }));
    } else setErros(isValid);
  }

  async function validaProposta(jsonContrato) {
    let response = await validaObjeto(
      ProducaoValidation.ProducaoValidationDefault,
      producao
    );
    if (!response.ok) return response;

    response = await validaObjeto(PropostaValidation.CapIcatu, jsonContrato);
    if (!response.ok) return response;

    if (!response.ok) return response;

    return { ok: true };
  }

  function onPressAssinarProposta()
  {
    window.open(
      urlArquivo
    );
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function getObjetoEnvio() {
    if (!endereco.clienteEnderecoId) {
      // Novo endereço informado
      if (
        cliente.clienteEnderecos.find(
          end =>
            end.clienteEnderecoCep === endereco.clienteEnderecoCep &&
            end.clienteEnderecoBairro === endereco.clienteEnderecoBairro &&
            end.clienteEnderecoCidade === endereco.clienteEnderecoCidade &&
            end.clienteEnderecoNumero === endereco.clienteEnderecoNumero
        ) === undefined
      ) {
        // Novo endereço informado para o contrato, porém já incluído na base quando salvo antes de efetivar
        cliente.clienteEnderecos.push(endereco);
      }
    }

    const propostaEletronicaJsonContrato = {
      contratoCapitalizacaoId: cliente.clienteId,
      produtoId,
      seguradoraId: Constants.Seguradoras.Icatu,
      clienteId: cliente.clienteId,
      consultorId: producao.consultorId,
      angariadorId: producao.angariadorId,
      agenciaId: producao.agenciaId,
      equipeId: producao.equipeId,
      contratoCapitalizacaoNumeroControle: null,
      contratoCapitalizacaoDiaDebito: capIcatu.contratoCapitalizacaoDiaDebito,
      contratoCapitalizacaoDataAssinatura: moment().format(),
      contratoCapitalizacaoDataPrimeiroDebito:
        capIcatu.contratoCapitalizacaoDataPrimeiroDebito,
      contratoCapitalizacaoDataEnvioSeguradora: null,
      contratoCapitalizacaoGuid: null,
      contratoCapitalizacaoDataEntrada: moment().format(),
      ...producao,
      regraComissaoProdutor: regraComissaoProdutor.regraComissaoProdutor,
      titulosCapitalizacao: getTitulos(),
      debitoConta: {
        agenciaId: debitoContaAgenciaId,
        debitoContaNumeroConta,
        produtoId,
        bancoId: debitoContaBancoId,
      },
    };

    capIcatu.demaisDados.debitoContaNumeroConta = debitoContaNumeroConta;
    capIcatu.demaisDados.debitoContaAgenciaId = debitoContaAgenciaId;
    capIcatu.demaisDados.debitoContaBancoId = debitoContaBancoId;
    capIcatu.demaisDados.enderecoCorrepondencia = endereco;
    capIcatu.demaisDados.titulosSelected = titulos;
    capIcatu.demaisDados.isDebitoBrb = isDebitoBrb;
    capIcatu.demaisDados.isDebitoOutrosBancos = isDebitoOutrosBancos;

    const objetoEnvio = {
      clienteId: cliente.clienteId,
      cliente,
      produtoId,
      moduloId: Constants.Modulos.CAP,
      propostaEletronicaPlanoId: null,
      propostaEletronicaJsonContrato: JSON.stringify(
        propostaEletronicaJsonContrato
      ),
      propostaEletronicaJsonDemaisDados: JSON.stringify(capIcatu.demaisDados),
      propostaEletronicaDataCadastro: moment().format(),
      propostaEletronicaNossoNumero:
        propostaEletronicaJsonContrato.contratoCapitalizacaoNumeroControle,
    };

    return objetoEnvio;
  }

  function changeNumeroContaDebito(value) {
    if (value.replace(/\D/g, '') !== value) {
      value = value.replace(/\D/g, '');
      message.error('Favor informar somente os números da conta');
    }

    if (value.length > 7) {
      value = value.substring(0, 7);
      message.error(
        'Favor não repetir a agência na conta e informar a conta somente com 7 caracteres'
      );
    }
    setNumeroConta(value);
  }

  function getTitulos() {
    let ehSegmentacaoNaoSeAplica = capIcatu.demaisDados.segmentacaoClienteCap == "Não se aplica";
    let tit = [];
    if (titulos.length > 0) {
      titulos.forEach(t => {
        for (let i = 0; i < t.quantidade; i++) {
          tit.push({
            SituacaoTituloId: ehSegmentacaoNaoSeAplica ? 7 : 14, // 7 - PENDENTE DE PAGAMENTO | 14 -PENDENTE DE ASSINATURA
            MtrTituloCapitalizacaoValorMensalidade: t.mensalidade,
          });
        }
      });
      return tit;
    }
  }

  function addTitulo() {
    let newTitulo = {
      sequencial: 1,
      nomePresenteado: cliente.clienteNome,
      pessoaFisicaJuridica: cliente.clienteCpfCnpj.length > 11 ? 'J' : 'F',
      cpfCnpj: cliente.clienteCpfCnpj,
      dataNascimento: moment(cliente.clienteDataNascimento),
      quantidade: null,
      mensalidade: null,
    };

    if (titulos.length === 5) {
      message.warning('Limite de linhas de títulos atigindo');
      return;
    }

    setTitulos(oldArray => [...oldArray, newTitulo]);
  }

  function delTitulo(index) {
    const newTitulos = Array.from(titulos);

    newTitulos.splice(index, 1);

    setTitulos(newTitulos);
  }

  function changeTitField(position, field, value) {
    const newTitulos = titulos.map((standardItem, index) => {
      if (index === position) {
        return { ...standardItem, [field]: value };
      }
      return standardItem;
    });
    sumValorPago(newTitulos);
    setTitulos(newTitulos);
  }

  function isTitulosValidos(tit) {
    var temTitInvalido = false;
    tit.map((item, index) => {
      if (item.quantidade > 9) {
        addErros('Máximo de 9 títulos por linha.');
        temTitInvalido = true;
      }
      if (item.quantidade == undefined || item.quantidade == 0) {
        addErros('Existem títulos sem quantidade informada.');
        temTitInvalido = true;
      }

      if (item.mensalidade == undefined || item.mensalidade == 0) {
        addErros('Existem títulos sem valor(R$) informado.');
        temTitInvalido = true;
      }

      // SONHO 24
      if (produtoId == constants.Produtos.CapIcatu24) {
        if (item.mensalidade < 800 || item.mensalidade > 1500) {
          addErros(
            'Para o Sonho 24 a mensalidade deve estar entre R$ 800,00  e R$ 1500,00'
          );
          temTitInvalido = true;
        }
        if (item.mensalidade % 100 !== 0) {
          addErros(
            'Para o Sonho 24 so é permitido mensalidades que são múltiplos de R$ 100,00'
          );
          temTitInvalido = true;
        }
      } else if (produtoId == constants.Produtos.CapIcatu48) {
        // SONHO 48
        if (item.mensalidade < 200 || item.mensalidade > 500) {
          addErros(
            'Para o Sonho 48 a mensalidade deve estar entre R$ 200,00  e R$ 500,00'
          );
          temTitInvalido = true;
        }
        if (item.mensalidade % 10 !== 0) {
          addErros(
            'Para o Sonho 48 so é permitido mensalidades que são múltiplos de R$ 10,00'
          );
          temTitInvalido = true;
        }
      } else if (produtoId == constants.Produtos.CapIcatu60) {
        // SONHO 60
        if (item.mensalidade < 50 || item.mensalidade > 500) {
          addErros(
            'Para o Sonho 60 a mensalidade deve estar entre R$ 50,00  e R$ 500,00'
          );
          temTitInvalido = true;
        }

        if (item.mensalidade % 10 !== 0) {
          addErros(
            'Para o Sonho 60 so é permitido mensalidades que são múltiplos de R$ 10,00'
          );
          temTitInvalido = true;
        }
      } else if (produtoId == constants.Produtos.CapIcatuPU) {
        // SONHO 60 PU
        if (item.mensalidade < 200 || item.mensalidade > 3000) {
          addErros(
            'Para o Sonho 60 PU a mensalidade deve estar entre R$ 200,00  e R$ 3000,00'
          );
          temTitInvalido = true;
        }
        if (item.mensalidade % 100 !== 0) {
          addErros(
            'Para o Sonho 60 PU so é permitido mensalidades que são múltiplos de R$ 100,00'
          );
          temTitInvalido = true;
        }
      } else if (produtoId == constants.Produtos.CapIcatu84) {
        // SONHO 84
        if (item.mensalidade < 40 || item.mensalidade > 500) {
          addErros(
            'Para o Sonho 84 a mensalidade deve estar entre R$ 40,00  e R$ 500,00'
          );
          temTitInvalido = true;
        }
        if (item.mensalidade % 10 !== 0) {
          addErros(
            'Para o Sonho 84 so é permitido mensalidades que são múltiplos de R$ 10,00'
          );
          temTitInvalido = true;
        }
      }
    });

    return !temTitInvalido;
  }

  function sumValorPago(tit) {
    capIcatu.valorTotalPago = 0;
    tit.forEach(t => {
      if (t.quantidade > 0)
        capIcatu.valorTotalPago += parseFloat(t.mensalidade * t.quantidade);
    });
    capIcatu.valorTotalPago = Mask.maskMoney(capIcatu.valorTotalPago);
  }
  function isShowAlertHoraDebito() {
    const dateNow = new Date();

    if (dateNow.getHours() >= 16 && dateNow.getMinutes() >= 10) {
      return true;
    }
    return false;
  }

  function callbackErrorEfetivar(values) {
    setErros(values);
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }

  function returnProdutoDescricao() {
    if (produtoId === 161137) return 'PU';
    if (produtoId === 161139) return '24';
    if (produtoId === 161144) return '48';
    if (produtoId === 161142) return '60';
    if (produtoId === 161143) return '84';
  }

  function geraLinkImpresso() {
    dispatch(PropostaActions.getArquivoImpressaoById(propostaId));
  }
  function Erros() {
    return (
      <ul>
        {erros.map((e, index) => (
          <li key={index}>{e}</li>
        ))}
      </ul>
    );
  }

  function onChangeDebito(field, value) {
    if (field === 'isDebitoBrb') {
      setIsDebitoBrb(true);
      setIsDebitoOutrosBancos(false);
    } else if (field === 'isDebitoOutrosBancos') {
      setIsDebitoBrb(false);
      setIsDebitoOutrosBancos(true);
    }

    setDebitoContaAgenciaId(undefined);
  }

  function onChangeBanco(value) {
    const agenciasO = agencias?.filter(a => a.bancoId === value);
    setAgenciasOutros(agenciasO || []);
    setDebitoContaBancoId(value);
  }

  return (
    <>
      <BackTop />
      <Spin spinning={loadingProposta} size="large">
        <Buttons
          onPressVoltar={onPrevius}
          showButtonNovaCotacao={!contratoId}
          onPressNovaCotacao={onNovaCotacao}
          showButtonSalvar={!contratoId}
          onPressSalvar={onSalvar}
          showButtonEfetivar={propostaId > 0 && !contratoId}
          onPressEfetivar={onEfetivar}
          showButtonImprimir={false}
          showlinkBaixarArquivo={propostaId > 0 && contratoId > 0}
          linkBaixarArquivo={geraLinkImpresso}
          showLinkAssinarProposta={contratoId > 0 && urlArquivo}
          onPressAssinarProposta={onPressAssinarProposta}
        />
        <Container>
          {contratoId > 0 && (
            <>
              <Result
                status="success"
                title="Parabéns, produto contratado com sucesso!"
                subTitle={`O número do contrato no Multiseguros é: ${numeroControle} e você já pode consultá-lo lá.`}
                extra={[
                  <Button
                    type="primary"
                    href={`https://multiseguros${
                      Functions.isProducao() ? '' : 'plushmo'
                    }.brbseguros.com.br/#/proposta-capitalizacao/${contratoId}/${produtoId}`}
                    target="_blank"
                    key="1"
                  >
                    Ir ao Multiseguros
                  </Button>,
                ]}
              />
              {isShowAlertHoraDebito() && (
                <Row style={{ marginBottom: 12, marginTop: 18 }}>
                  <Alert
                    showIcon
                    description={`Propostas cadastradas após às 16h o 1º débito poderá ocorrer somente próximo dia últil. ${dataHoraProposta}`}
                    message="Atenção:"
                    type="warning"
                  />
                </Row>
              )}
            </>
          )}

          {erros.length > 0 && (
            <Alert
              message="Atenção, verifique os campos abaixo:"
              description={<Erros />}
              type="error"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}

          <Disable disable={!!contratoId}>
            <h4>BRB Cap - {returnProdutoDescricao()}</h4>
            <FieldsProducao produto="capitalizacao-icatu" showIsProdutor />
            <TitleSection title="SUBSCRITOR" />
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <div className="form-group actions-tabs">
                      {isCNP && (
                          <>
                              <Checkbox
                                  checked={isDebitoBrb}
                                  value={isDebitoBrb}
                                  onChange={value =>
                                    onChangeDebito('isDebitoBrb', value.target.checked)
                                  }
                              >
                                  Débito BRB
                              </Checkbox>
                              <Checkbox
                                  checked={isDebitoOutrosBancos}
                                  value={isDebitoOutrosBancos}
                                  onChange={value =>
                                    onChangeDebito('isDebitoOutrosBancos', value.target.checked)
                                  }
                              >
                                  Débito outros bancos
                              </Checkbox>
                          </>
                      )}
                  </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Nome</label>
                  <Input value={cliente.clienteNome} disabled />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <div className="form-group">
                  <label>CPF / CNPJ</label>
                  <Input value={cliente.clienteCpfCnpj} disabled />
                </div>
              </Col>
              {isDebitoOutrosBancos && isCNP && (
                <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label>Banco de débito</label>
                    <Select
                      itens={Constants.OutrosBancos}
                      itemValue="bancoId"
                      itemText="bancoDescricao"
                      value={debitoContaBancoId}
                      onChange={value => onChangeBanco(value)}
                    />
                  </div>
                </Col>
              )}
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                {isDebitoBrb && (
                  <div className="form-group">
                    <label>Agência de débito</label>
                    <Select
                      itens={agenciasBrb}
                      itemValue="agenciaId"
                      itemText="agenciaDescricao"
                      itemTextSearch="agenciaCodigo"
                      renderText={item =>
                        `${item.agenciaCodigo} - ${item.agenciaDescricao}`
                      }
                      showSearch
                      value={debitoContaAgenciaId}
                      onChange={value => setDebitoContaAgenciaId(value)}
                    />
                  </div>
                )}
                {isDebitoOutrosBancos && (
                  <div className="form-group">
                    <label>Agência de débito</label>
                    <Select
                      itens={agenciasOutros}
                      itemValue="agenciaId"
                      itemText="agenciaDescricao"
                      itemTextSearch="agenciaCodigo"
                      renderText={item =>
                        `${item.agenciaCodigo} - ${item.agenciaDescricao}`
                      }
                      showSearch
                      value={debitoContaAgenciaId}
                      onChange={value => setDebitoContaAgenciaId(value)}
                    />
                  </div>
                )}
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Número Conta</label>
                  <Input
                    placeholder="*Número da conta somente, sem agência"
                    value={debitoContaNumeroConta}
                    mask="onlyNumbers"
                    onChange={value => changeNumeroContaDebito(value)}
                  />
                </div>
              </Col>
            </Row>
            <Disable disable>
              <FieldsEndereco
                title=""
                // onChangeEndereco={onChangeEnderecoLocalRisco}
                enderecoRedux={endereco}
                // onChangeTipoImovel={onChangeTipoImovel}
                produtoId={produtoId}
              />
            </Disable>
          </Disable>

          <Card title="Título" bordered>
            <Row
              gutter={[16, 0]}
              style={{ marginTop: '1rem', backgroundColor: '#DCDCDC' }}
            >
              <Col xs={{ span: 24 }} sm={{ span: 2 }}>
                <label>Sequencial</label>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <label>Nome presenteado</label>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 2 }}>
                <label>F/J</label>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <label>CPF/CGC</label>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <label>Data de Nascimento</label>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 2 }}>
                <label>Quantidade</label>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <label>Mensalidade</label>
              </Col>
            </Row>
            <Disable disable={contratoId > 0}>
              {titulos.map((t, index) => (
                <Row key={index} gutter={[16, 0]} style={{ marginTop: '1rem' }}>
                  <Col xs={{ span: 24 }} sm={{ span: 2 }}>
                    <div className="form-group">
                      <Input
                        value={index + 1}
                        onChange={value =>
                          changeTitField(index, 'Sequencial', value)
                        }
                        maxLength={200}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                    <div className="form-group">
                      <Input
                        value={titulos[index].nomePresenteado}
                        onChange={value =>
                          changeTitField(index, 'nomePresenteado', value)
                        }
                        maxLength={150}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 2 }}>
                    <div className="form-group">
                      <Select
                        itens={[
                          { text: 'J', value: 'J' },
                          { text: 'F', value: 'F' },
                        ]}
                        itemValue="value"
                        itemText="text"
                        value={titulos[index].pessoaFisicaJuridica}
                        onChange={value =>
                          changeTitField(index, 'pessoaFisicaJuridica', value)
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                    <div className="form-group">
                      <Input
                        value={titulos[index].cpfCnpj}
                        onChange={value =>
                          changeTitField(index, 'cpfCnpj', value)
                        }
                        maxLength={14}
                        accept="onlyNumbers"
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                    <div className="form-group">
                      <DatePicker
                        value={titulos[index].dataNascimento}
                        placeholder="Selecione"
                        onChange={value =>
                          changeTitField(index, 'dataNascimento', value)
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 2 }}>
                    <div className="form-group">
                      <Input
                        value={titulos[index].quantidade}
                        onChange={value =>
                          changeTitField(index, 'quantidade', value)
                        }
                        accept="onlyNumbers"
                        maxLength={1}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                    <div className="form-group">
                      <Input
                        value={titulos[index].mensalidade}
                        onChange={value =>
                          changeTitField(index, 'mensalidade', value)
                        }
                        maxLength={6}
                        accept="onlyNumbers"
                        prefix="R$"
                        suffix=",00"
                        style={{
                          textAlign: 'right',
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 1 }}>
                    <Button
                      type="link"
                      size="small"
                      onClick={i => delTitulo(index)}
                    >
                      <CloseSquareFilled />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row>
                <Row gutter={[16, 16]} style={{ textAlign: 'left' }}>
                  <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                    <Button type="primary" size="small" onClick={addTitulo}>
                      <PlusCircleFilled /> Adicionar Título
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Disable>
          </Card>
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col xs={{ span: 24 }} sm={{ span: 4 }}>
              <div className="form-group">
                <label>Data do Pagamento</label> <br />
                <Disable disable>
                  <DatePicker
                    value={capIcatu.contratoCapitalizacaoDataPrimeiroDebito}
                    placeholder="Selecione"
                    onChange={value => {
                      changeField(
                        'contratoCapitalizacaoDataPrimeiroDebito',
                        value
                      );
                    }}
                  />
                </Disable>
              </div>
            </Col>
            {/* Diferente de PU */}
            {produtoId !== 161137 && (
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <Disable disable={contratoId > 0}>
                  <div className="form-group">
                    <label>Melhor dia para débito</label>
                    <Select
                      itens={getMelhorDiaPagamento()}
                      itemText="text"
                      itemValue="value"
                      value={capIcatu.contratoCapitalizacaoDiaDebito}
                      onChange={value =>
                        changeField('contratoCapitalizacaoDiaDebito', value)
                      }
                    />
                  </div>
                </Disable>
              </Col>
            )}
            <Col xs={{ span: 24 }} sm={{ span: 3 }}>
              <div className="form-group">
                <label>Valor Total Pago</label>
                <Input
                  value={capIcatu.valorTotalPago}
                  onChange={value => changeField('valorTotalPago', value)}
                  maxLength={18}
                  disabled
                />
              </div>
            </Col>
            {/* <Pagamento/> */}
          </Row>
        </Container>
        <Buttons
          onPressVoltar={onPrevius}
          showButtonNovaCotacao={!contratoId}
          onPressNovaCotacao={onNovaCotacao}
          showButtonSalvar={!contratoId}
          onPressSalvar={onSalvar}
          showButtonEfetivar={propostaId > 0 && !contratoId}
          onPressEfetivar={onEfetivar}
          showButtonImprimir={false}
          showlinkBaixarArquivo={propostaId > 0 && contratoId > 0}
          linkBaixarArquivo={geraLinkImpresso}
          showLinkAssinarProposta={contratoId > 0 && urlArquivo}
          onPressAssinarProposta={onPressAssinarProposta}
        />
      </Spin>
    </>
  );
}
