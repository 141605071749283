import React, { useState, useEffect } from 'react';
import { Select as SelectAntd } from 'antd';

type SelectProps = {
  defaultValue: String,
  value: String | Number,
  onChange: Function,
  itens: Array,
  itemValue: String,
  itemText: String,
  itemTextSearch?: String,
  renderText?: Function,
  // filterOption: Function | Boolean,
  // optionFilterProp: String,
  showSearch?: Boolean,
  placeholder?: String,
  disabled?: Boolean,
  loading?: Boolean,
  multiple?: Boolean,
  remove?: Function<Boolean>,
};

export default function Select({
  defaultValue,
  value,
  onChange,
  itens,
  itemText,
  itemValue,
  itemTextSearch,
  renderText,
  // filterOption,
  // optionFilterProp,
  showSearch,
  placeholder,
  disabled,
  loading,
  multiple,
  remove,
}: SelectProps) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (itens) {
      if (itens.length >= 100) {
        const arrayFiltrado = itens.slice(0, 100);
        if (value) {
          const i = arrayFiltrado.find(a => a[itemValue] === value);
          if (!i) {
            const it = itens.find(a => a[itemValue] === value);
            if (it) arrayFiltrado.push(it);
          }
        }
        setOptions(arrayFiltrado);
      } else {
        setOptions(itens);
      }
    }
  }, [value, itens]);

  function onSearch(text) {
    if (!itens) return;
    const newValues = itens.filter(i => {
      return (
        i[itemText]
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) !== -1 ||
        (itemTextSearch !== undefined &&
          i[itemTextSearch]
            ?.toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) !== -1)
      );
    });
    if (newValues.length >= 100) setOptions(newValues.slice(0, 100));
    else setOptions(newValues);
  }

  return (
    <SelectAntd
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      style={{ width: '100%' }}
      onChange={onChange}
      showSearch={showSearch}
      onSearch={onSearch}
      filterOption={() => showSearch}
      disabled={disabled}
      mode={multiple ? 'multiple' : ''}
      loading={loading}
    >
      {options.map(
        i =>
          !remove(i) && (
            <SelectAntd.Option
              key={itemValue ? i[itemValue] : i}
              value={itemValue ? i[itemValue] : i}
            >
              {renderText ? renderText(i) : i[itemText]}
            </SelectAntd.Option>
          )
      )}
    </SelectAntd>
  );
}

Select.defaultProps = {
  showSearch: false,
  disabled: false,
  itemTextSearch: undefined,
  renderText: null,
  placeholder: '',
  loading: false,
  multiple: false,
  remove: () => false,
};
