import { Tabs } from 'antd';
import React from 'react';

const { TabPane } = Tabs;

type SwitchProp = {
  valorTeste?: any,
  children?: any
}

export default function Switch(param: SwitchProp) {

  const { valorTeste, children } = param

  return children.find(child => {
    return child.props.valueSwitch === valorTeste
  })
};