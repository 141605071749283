import {
  BackTop, Button, Result, Spin
} from 'antd';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container, Disable, Error, Info
} from '~/components';
import * as ClienteActions from '~/store/ducks/cliente';
import * as LibertyResidencialPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PropostaActions from '~/store/ducks/proposta';
import { Constants } from '~/utils';
import Buttons from '../components/Buttons';
import CotacaoPrimeiroPasso from './cotacao-primeiro-passo/index.js';
import CotacaoSegundoPasso from './cotacao-segundo-passo/index.js';
import Efetivacao from './efetivacao/index.js';
import ClienteValidation from '../../../yup/cliente'
import PropostaValidation from '../../../yup/proposta'

type ResidencialPersonalizavelLibertyProps = {
  onReset: Function,
  onPrevius: Function,
  onNext: Functtion,
  passoAtual: Number
};

export default function ResidencialPersonalizavelLiberty({
  onReset,
  onPrevius,
  onNext,
  passoAtual
}: ResidencialPersonalizavelLibertyProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    producao,
    propostaId,
    contratoId,
  } = useSelector(state => state.proposta);
  const { cliente, endereco } = useSelector(state => state.cliente);

  const { libertyResidencialPersonalizavel, libertyResidencialPersonalizavel: {
    cotacao, efetivacao
  } } = useSelector(state => state);

  // Component state
  const [erros, setErros] = useState([]);

  useEffect(() => {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }, []);

  function retornaFormaPagamentoSelecionada() {
    var formasPagamentos = libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento;

    var formaSelecionada = (formasPagamentos ?? [])
      .find(x => x.formasPagamento.some(y => y.formaPagamentoEscolhaId === efetivacao?.escolhaFormaPagamentoId));

    return formaSelecionada;
  }

  async function onSalvar() {
    setErros([]);
    const isValid = await validarCotacaoPasso2();

    if (!isValid.ok) 
      return callbackApresentarErros(isValid);
    
    const param = getObjetoEnvioCotacao();
    dispatch(LibertyResidencialPersonalizavelActions.cotar({ param, callbackErro: erroCalcular }));
  }

  function erroCalcular(erros) {
    if(!!erros && !!erros.length)
    callbackApresentarErros(erros);
  }

  async function validaEfetivacao() {
    var retorno = [];

    if(!!(libertyResidencialPersonalizavel?.propostaDetalhes?.listaIndexResidenciasParaInspecao ?? []).length 
      && !(efetivacao.listaContatosInspecao || []).length)
      retorno.push('Deve-se informar no mínimo 1 contato para inspeção.')
    
    if(!(cotacao?.localRisco?.seguradoEhProprietarioDoImovel)) {
      var tamanho = (efetivacao.beneficiarios ?? []).length

      if(!tamanho || tamanho >  3) retorno.push('A quantidade de beneficiários deve estar entre 1 e 3')
    }

    var formaPagamentoEscolhida = retornaPagamentoEscolhido();

    if(primeiroPagamentoPossuiMaisDeUmaParcela(formaPagamentoEscolhida)  && diaPreferenciaPagamentoInvalido()) 
      retorno.push('Deve-se informar o dia de preferência do pagamento entre 1 e 31');

    var response = await validaObjeto(PropostaValidation.ContaDebitoLibertyPersonalizavel, efetivacao.contaDebito)
    if (!response.ok) retorno = retorno.concat(response);

    return !!retorno.length ? retorno : { ok: true };
  }

  function diaPreferenciaPagamentoInvalido() {
    return !efetivacao.diaPreferenciaPagamento ||
      efetivacao.diaPreferenciaPagamento < 1 || efetivacao.diaPreferenciaPagamento > 31
  }

  function primeiroPagamentoPossuiMaisDeUmaParcela(formaPagamento) {
    return formaPagamento.listaFormaEscolhida[0].quantidadeParcelas > 1
  }

  function retornaPagamentoEscolhido() {

    var listaPagamentos = libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento ?? [];
    if(!listaPagamentos.length || !efetivacao?.escolhaFormaPagamentoId) return {};

    var escolhido = listaPagamentos.find(x => x.formasPagamento.some(y => y.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId))    
    if(!escolhido) return {};

    escolhido.listaFormaEscolhida = escolhido.formasPagamento.filter(x => x.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId) 
    return escolhido;
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function getObjetoEnvioCotacao() {
    if(endereco.clienteEnderecoId) {
      var end = cliente.clienteEnderecos.find(x => x.clienteEnderecoId === endereco.clienteEnderecoId)
      var index = cliente.clienteEnderecos.indexOf(end)
      cliente.clienteEnderecos.splice(index, 1)
    }
    
    cliente.clienteEnderecos.unshift(endereco);

    var objToStringfy = {
      angariadorId: producao.angariadorId,
      agenciaProducaoId: producao.agenciaId,
      consultorId: producao.consultorId,
      equipeId: producao.equipeId,
      dataInicioVigencia: cotacao.dataInicioVigencia,
      dataFimVigencia: cotacao.dataFimVigencia,
      segurado: libertyResidencialPersonalizavel.segurado,
      residencias: [{
        tipoAssistenciaId: cotacao.localRisco.tipoAssistenciaId,
        tipoConstrucaoId: cotacao.localRisco.tipoConstrucaoId,
        tipoHabitacaoId: cotacao.localRisco.tipoHabitacaoId,
        tipoOcupacaoId: cotacao.localRisco.tipoOcupacaoId,
        tipoAtividadeProfissionalExercidaId: cotacao.localRisco.tipoAtividadeProfissionalExercidaId,
        seguradoEhProprietarioDoImovel: cotacao.localRisco.seguradoEhProprietarioDoImovel,
        imovelEhPatrimonioHistorico: cotacao.localRisco.imovelEhPatrimonioHistorico,
        imovelDesocupadoEmRetormaOuEmConstrucao: cotacao.localRisco.imovelDesocupadoEmRetormaOuEmConstrucao,
        imovelPossuiMadeiraParedesExternas: cotacao.localRisco.imovelPossuiMadeiraParedesExternas,
        porcentagemAgravo: cotacao.porcentagemAgravo,
        localDeRisco: {
          cep: cotacao.localRisco.endereco.clienteEnderecoCep,
          tipoRuaEndereco: cotacao.localRisco.endereco.tipoLogradouroLibertyResidencialPersonalizavel,
          complemento: cotacao.localRisco.endereco.clienteEnderecoComplemento,
          logradouro: cotacao.localRisco.endereco.clienteEndereco1,
          numeroEndereco: cotacao.localRisco.endereco.clienteEnderecoNumero,
          estado: cotacao.localRisco.endereco.clienteEnderecoUf,
          cidade: cotacao.localRisco.endereco.clienteEnderecoCidade,
          bairro: cotacao.localRisco.endereco.clienteEnderecoBairro,
        },
        coberturas: cotacao.coberturas
      }]
    }

    var obj = {
      clienteId: cliente.clienteId,
      produtoId: Constants.Produtos.LibertyResidencialPersonalizavel,
      propostaEletronicaId: propostaId ?? 0,
      moduloId: 2,
      cliente: cliente,
      propostaEletronicaJsonContrato: JSON.stringify(objToStringfy)
    }

    return obj;
  }

  function getObjEfetivacao(){
    var obj = {
      ...efetivacao,
      listaContatosInspecao: [
        ...(efetivacao.listaContatosInspecao ?? []).map((x, i) => {
          return {
            ...x,
            indexResidencia: 1,
            indexContatoResidencia: (i + 1),
            dddContato: (x?.numeroTelefone ?? '').substring(0, 2),
            numeroContato: (x?.numeroTelefone ?? '').substring(2)
          }
        })
      ],
      clienteContaId: null,
      contaDebito: {
          ...efetivacao.contaDebito,
          digitoVerificadorContaCorrente: (efetivacao?.contaDebito?.numeroContaCorrente ?? '').substring(((efetivacao?.contaDebito?.numeroContaCorrente ?? '').length - 1)),
          numeroContaCorrente: (efetivacao?.contaDebito?.numeroContaCorrente ?? '').substring(0, ((efetivacao?.contaDebito?.numeroContaCorrente ?? '').length - 1))
        }
    }

    return {
      propostaEletronicaId: propostaId,
      propostaEletronicaJsonContrato: JSON.stringify(obj)
    }
  }

  async function onEfetivar() {
    setErros([]);
    
    const isValid = await validaEfetivacao();

    if (!isValid.ok) 
      return callbackApresentarErros(isValid);
      
    const param = getObjEfetivacao();
    dispatch(LibertyResidencialPersonalizavelActions.efetivaProposta({param: param, callbackErro: callbackApresentarErros}))
  }

  async function _onNext() {
    setErros([]);
    // Valid data
    const response = await validate();
    
    // Check is valid
    if (response.ok) return onNext();

    callbackApresentarErros(response);
  }

  async function validate() {
    if(passoAtual === 1 && !contratoId) return await validarCotacaoPasso1()
    if(passoAtual === 2 && !contratoId) return validarPodeAvancarParaEfetivacao()
    
    return { ok: true }
  }

  async function validarCotacaoPasso1() {
    var retorno = [];

    var localRisco = cotacao.localRisco;
    var enderecoParaValidacao = cotacao.localRisco.endereco;

    var response;

    response = await validaObjeto(ClienteValidation.Endereco, enderecoParaValidacao);
    if (!response.ok) retorno = retorno.concat(response);

    response = await validaObjeto(ClienteValidation.EnderecoPersonalizavelLiberty, enderecoParaValidacao);
    if (!response.ok) retorno = retorno.concat(response);
    
    
    if(!enderecoParaValidacao.tipoLogradouroLibertyResidencialPersonalizavel || !enderecoParaValidacao.tipoLogradouroLibertyResidencialPersonalizavel.trim())
      retorno.push('Deve-se informar o tipo de logradouro.')
    
    response = await validaObjeto(PropostaValidation.LocalRiscoLibertyResidencialPersonalizavelCotacao, localRisco);
    if (!response.ok) retorno = retorno.concat(response);
    
    var cotacaoValidar = { ...cotacao, naoValidarDataAnteriorAAtual: propostaId > 0 }
    response = await validaObjeto(PropostaValidation.DatasVigenciaCotacaoLibertyPersonalizavel, cotacaoValidar);
    if (!response.ok) retorno = retorno.concat(response);

    return retorno.length === 0 ? { ok: true } : retorno;
  }

  async function validarCotacaoPasso2() {
    
    var retorno = [];

    var response;

    response = await validaObjeto(PropostaValidation.CotacaoLibertyResidencialPersonalizavelPasso2, cotacao);
    if (!response.ok) retorno = retorno.concat(response);

    var cotacaoValidar = { ...cotacao, naoValidarDataAnteriorAAtual: false }
    response = await validaObjeto(PropostaValidation.DatasVigenciaCotacaoLibertyPersonalizavel, cotacaoValidar);
    if (!response.ok) retorno = retorno.concat(response);

    return retorno.length === 0 ? { ok: true } : retorno;
  }

  function validarPodeAvancarParaEfetivacao(){
    var retorno = [];

    if(!efetivacao.escolhaFormaPagamentoId)
      retorno.push('Deve-se selecionar a forma de pagamento');

    return retorno.length === 0 ? { ok: true } : retorno;
  }

  function callbackApresentarErros(values) {
    setErros(values);
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }

  function limparMensagensInfo() {
    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({field: 'messagesInfo', value: []}))
  }

  function apresentarBotaoBaixarBoleto() {
    return contratoId > 0 && ehPropostaPagaEmBoleto();
  }

  function ehPropostaPagaEmBoleto() {
    var formasPagamentos = libertyResidencialPersonalizavel.propostaDetalhes.formasPagamento;

    var listaDoSelecionado = (formasPagamentos ?? [])
      .find(x => x.formasPagamento.some(y => y.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId));

    return listaDoSelecionado?.formaPagamentoTipoCodigo === 'FB'
      || (listaDoSelecionado?.formaPagamentoTipoCodigo === 'DC' && listaDoSelecionado?.formaPagamentoPlanoCodigo === '0171')
  }

  function baixarBoleto() {
    dispatch(LibertyResidencialPersonalizavelActions.impressaoBoletoResidencialLiberty(propostaId))
  }

  function exibirSectionDebitoEmConta() {
    return true;
    var formaSelecionada = retornaFormaPagamentoSelecionada();
    return formaSelecionada?.formaPagamentoTipoCodigo === 'DC'
  }

  function exibirBeneficiarios() { return !(cotacao?.localRisco?.seguradoEhProprietarioDoImovel) };

  // como no seguridade apenas uma residência é enviada, caso exista algum registro na lista de inspenção, 
  // significa que deve informar a lista de contatos para inspeção
  function exibirContatosParaInspecao() { return !!(libertyResidencialPersonalizavel?.propostaDetalhes?.listaIndexResidenciasParaInspecao ?? []).length };

  function exibirBodyEfetivar() {
    return !!exibirBeneficiarios() || !!exibirSectionDebitoEmConta() || !!exibirContatosParaInspecao();
  }

  return (
    <>
      <BackTop />
      <Spin spinning={!!(libertyResidencialPersonalizavel.loading ?? []).length} size="small">
        <Buttons
          onPressVoltar={onPrevius}
          showButtonNovaCotacao={!contratoId}
          onPressNovaCotacao={onNovaCotacao}
          showButtonSalvar={!contratoId && passoAtual === 2}
          onPressSalvar={onSalvar}
          showButtonNext={passoAtual === 1 || (passoAtual === 2 && propostaId > 0)}
          onPressButtonNext={_onNext}
          showButtonEfetivar={(propostaId > 0 && !contratoId) && passoAtual === 3}
          onPressEfetivar={onEfetivar}
          showButtonImprimir={propostaId > 0 && ([2, 3]).some(x => x === passoAtual)}
          linkImprimir="impressao-residencial-personalizavel-liberty"
          showlinkBaixarBoleto={apresentarBotaoBaixarBoleto()}
          onClickBaixarBoleto={baixarBoleto}
          textButtonSalvar='Calcular'
        />
        {
          (passoAtual !== 3 || exibirBodyEfetivar()) &&
          <>
            <Container>
              {contratoId > 0 && (
                <Result
                  status="success"
                  title="Parabéns, produto contratado com sucesso!"
                  subTitle={`O número do contrato no Multiseguros é: ${libertyResidencialPersonalizavel?.propostaDetalhes?.numeroPropostaLibery} e você já pode consultá-lo lá.`}
                  extra={[
                    <Button
                      className="btn btn-xs-block mb-2 mb-sm-0"
                      type="primary"
                      href={`https://multiseguros${
                        process.env.REACT_APP_BASE_URL.indexOf('hmo') > 0
                          ? 'plushmo'
                          : ''
                      }.brbseguros.com.br/#/proposta-dre/${contratoId}/11409`}
                      target="_blank"
                      key="1"
                    >
                      Ir ao Multiseguros
                    </Button>,
                  ]}
                />
              )}

              <Error erros={erros} />
              <Info messages={ libertyResidencialPersonalizavel.messagesInfo } onLimparMensagens={limparMensagensInfo} />

              <Disable disable={!!contratoId}>
                <h4>Residencial Liberty Personalizável</h4>
                {
                  passoAtual === 1 && 
                  <CotacaoPrimeiroPasso></CotacaoPrimeiroPasso>
                }
                {
                  passoAtual === 2 && 
                  <CotacaoSegundoPasso></CotacaoSegundoPasso>
                }
                {
                  passoAtual === 3 && 
                  <Efetivacao></Efetivacao>
                }
                
              </Disable>
            </Container>
            <Buttons
              onPressVoltar={onPrevius}
              showButtonNovaCotacao={!contratoId}
              onPressNovaCotacao={onNovaCotacao}
              showButtonSalvar={!contratoId && passoAtual === 2}
              onPressSalvar={onSalvar}
              showButtonNext={passoAtual === 1 || (passoAtual === 2 && propostaId > 0)}
              onPressButtonNext={_onNext}
              showButtonEfetivar={(propostaId > 0 && !contratoId) && passoAtual === 3}
              onPressEfetivar={onEfetivar}
              showButtonImprimir={propostaId > 0 && ([2, 3]).some(x => x === passoAtual)}
              linkImprimir="impressao-residencial-personalizavel-liberty"
              textButtonSalvar='Calcular'
            />
          </>
        }
        
      </Spin>
    </>
  );
}
