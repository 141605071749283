import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './route';

import Login from '~/pages/Login';
import PesquisarCotacoesPropostas from '~/pages/PesquisarCotacaoProposta';
import Dashboard from '~/pages/Dashboard';
import Profile from '~/pages/Profile';
import Proposta from '~/pages/Proposta';
import VendaAssistida from '~/pages/VendaAssistida';
import ImpressaoDre from '~/pages/Proposta/residencial-mitsui/impressao';
import ImpressaoVida from '~/pages/Proposta/vpp-american/impressao';
import ImpressaoDreLibertyCeS from '~/pages/Proposta/empresarial-liberty/impressao';
import ImpressaoDreZurichRes from '~/pages/Proposta/residencial-zurich/impressao';
import ImpressaoDreZurichEmp from '~/pages/Proposta/empresarial-zurich/impressao';
import ImpressaoDreMitsuiEmp from '~/pages/Proposta/empresarial-mitsui/impressao';
import ImpressaoVidaApLiberty from '~/pages/Proposta/acidentes-pessoais-liberty/impressao';
import ImpressaoResidencialPersonalizavelLiberty from '~/pages/Proposta/residencial-personalizavel-liberty/impressao';
import PesquisaGeral from '~/pages/PesquisaGeral';
import PesquisaCancelados from '~/pages/PesquisarPropostasCanceladas';
import AgendaPositiva from '~/pages/AgendaPositiva';
import AcumuloRisco from '~/pages/AcumuloRisco';
import Cotagro from '~/pages/Cotagro';
import AlteraConta from '~/pages/AlterarConta';
import AnaliseAlteracaoConta from '~/pages/AnaliseAlteracaoConta';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/venda-assistida/:produtoId" component={VendaAssistida} isPrivate />
      <Route path="/pesquisar-cotacoes-propostas" component={PesquisarCotacoesPropostas} isPrivate />
      <Route path="/pesquisa-geral" component={PesquisaGeral} isPrivate />
      <Route path="/altera-conta" component={AlteraConta} isPrivate />
      <Route path="/analise-alteracao-conta" component={AnaliseAlteracaoConta} isPrivate />
      <Route path="/pesquisa-proposta-canceladas" component={PesquisaCancelados} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/proposta/:type" component={Proposta} isPrivate />
      <Route path="/impressao-dre" component={ImpressaoDre} isPrivate blank />
      <Route path="/impressao-vida" component={ImpressaoVida} isPrivate blank />
      <Route path="/impressao-vida-ap" component={ImpressaoVidaApLiberty} isPrivate blank />
      <Route path="/impressao-residencial-personalizavel-liberty" component={ImpressaoResidencialPersonalizavelLiberty} isPrivate blank />
      <Route path="/agenda-positiva" component={AgendaPositiva} isPrivate />
      <Route path="/acumulo-risco" component={AcumuloRisco} isPrivate />
      <Route path="/cotagro" component={Cotagro} isPrivate />
      <Route
        path="/impressao-dre-ces"
        component={ImpressaoDreLibertyCeS}
        isPrivate
        blank
      />
      <Route
        path="/impressao-dre-zurich-res"
        component={ImpressaoDreZurichRes}
        isPrivate
        blank
      />
      <Route
        path="/impressao-dre-zurich-emp"
        component={ImpressaoDreZurichEmp}
        isPrivate
        blank
      />
      <Route
        path="/impressao-dre-mitsui-emp"
        component={ImpressaoDreMitsuiEmp}
        isPrivate
        blank
      />

      {/* redireciona para página inicial quando a rota for inexistente */}
      <Redirect from='*' to="/proposta/new" />
    </Switch>
  );
}
