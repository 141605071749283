import { Alert, Button, Col, Input, Radio, Result, Row, Spin } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Disable, Error, Select, TitleSection } from '~/components';
import useIsAgenciaDigital from '~/hooks/useIsAgenciaDigital';
import * as ClienteActions from '~/store/ducks/cliente';
import * as PlanoActions from '~/store/ducks/plano';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PropostaActions from '~/store/ducks/proposta';
import { Constants, Functions } from '~/utils';
import { PropostaValidation } from '~/yup';
import Buttons from '../../components/Buttons';
import FormatarProposta from '../utils';

const { TextArea } = Input;

type DpsAtividadesProp = {
  onPrevius: Function,
  onReset: Function,
};

export default function DpsAtividades({
  onPrevius,
  onReset,
}: DpsAtividadesProp) {
  const [erros, setErros] = useState([]);
  const [isencaoDps, setIsencaoDps] = useState(false);

  const [isAgenciaDigital] = useIsAgenciaDigital();

  const dispatch = useDispatch();

  const {
    respostasPerguntasDpsAtividades,
    pessoaRespostaPerguntasDpsAtividade,
  } = Constants.VidaPremiadoPlus;

  const {
    proposta: {
      vppAmerican: proposta,
      loading,
      propostaId,
      contratoId,
      perguntasProduto,
    },
    cliente: { cliente },
    plano: { planos },
  } = useSelector(state => state);

  const { planoSelected } = proposta;

  const { href } = window.location;

  useEffect(() => {
    dispatch(PropostaActions.searchPerguntasProduto({ produtoId: 99310 }));
    setPessoaRespostaPergunta();
  }, []);

  useEffect(() => {
    const { tipoPlano, tipoPagamento } = proposta;
    const idade = Functions.calculaIdade(cliente?.clienteDataNascimento);
    dispatch(
      PlanoActions.getPlanosVpp({
        param: { idade, tipoPlano, tipoPagamento },
      })
    );
  }, [proposta.tipoPlano, proposta.tipoPagamento]);

  useEffect(() => {
    if (proposta.planoSelected && planos.length > 0) {
      const planoSelectedA = planos.find(
        p =>
          p.planoGarantiaVidaId === proposta.planoSelected?.planoGarantiaVidaId
      );
      changeFields({ planoSelected: planoSelectedA });
    }
  }, [planos]);

  useEffect(() => {
    if (isAgenciaDigital && planoSelected.mtrGarantiaMorte <= 50000)
      setIsencaoDps(true);
  }, [planoSelected, isAgenciaDigital]);

  function changeFields(obj) {
    dispatch(PropostaActions.changeVppAmerican(obj));
  }

  function modelRespostasDadas(perguntaProdutoId) {
    proposta.respostasDps =
      proposta.respostasDps && !Array.isArray(proposta.respostasDps)
        ? proposta.respostasDps
        : { respostasObservacao: null, listaRespostas: [] };

    if (
      !proposta.respostasDps.listaRespostas.find(
        x => x.perguntaProdutoId === perguntaProdutoId
      )
    )
      proposta.respostasDps.listaRespostas.push({
        perguntaProdutoId,
        idRespostaPerguntaProduto: null,
        idPessoaRespostaPerguntaProduto: 1,
        respostaObservacao: null,
      });

    return proposta.respostasDps.listaRespostas.find(
      x => x.perguntaProdutoId === perguntaProdutoId
    );
  }

  async function saveProposal(efetivar = false) {
    setErros([]);

    const isValid = await validaRespostas();

    if (isValid.ok) {
      const propostaFormatada = FormatarProposta(efetivar);

      if (efetivar) {
        dispatch(
          PropostaActions.efetivar({
            param: propostaFormatada,
            callback: callbackErrorEfetivar,
          })
        );
        return;
      }

      dispatch(PropostaActions.save({ param: propostaFormatada }));
    } else {
      setErros(isValid);
    }
  }

  function setPessoaRespostaPergunta() {
    (perguntasProduto || []).forEach(x => {
      if (!mostrarCampoPessoaResposta(x.perguntaProdutoId))
        changeResposta(
          x.perguntaProdutoId,
          1,
          'idPessoaRespostaPerguntaProduto'
        );
    });
  }

  function callbackErrorEfetivar(values) {
    setErros(values);
  }

  async function validaRespostas() {
    if (isencaoDps) return { ok: true };

    const response = await validaObjeto(
      PropostaValidation.RespostasDpsAtividades,
      proposta.respostasDps
    );
    return !response.ok ? response : { ok: true };
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }

  function changeResposta(perguntaProdutoId, valor, propriedade) {
    const respostasDadas = { ...proposta.respostasDps };
    const pergunta = respostasDadas.listaRespostas.find(
      x => x.perguntaProdutoId === perguntaProdutoId
    );
    const index = respostasDadas.listaRespostas.indexOf(pergunta);

    pergunta[propriedade] = valor;

    // Caso atualize a resposta para não, define pessoa resposta como o titular
    pergunta.idPessoaRespostaPerguntaProduto =
      propriedade === 'idRespostaPerguntaProduto' && valor === 0
        ? 1
        : pergunta.idPessoaRespostaPerguntaProduto;

    respostasDadas.listaRespostas.splice(index, 1, pergunta);

    // Caso nenhuma resposta seja sim, define limpa a observação
    respostasDadas.respostasObservacao = respostasDadas.listaRespostas.some(
      x => x.idRespostaPerguntaProduto === 1
    )
      ? respostasDadas.respostasObservacao
      : null;

    changeFields({ respostasDps: respostasDadas });
  }

  function mostrarCampoPessoaResposta(idPergunta) {
    return (
      proposta.tipoPlano === 2 &&
      modelRespostasDadas(idPergunta).idRespostaPerguntaProduto === 1
    );
  }

  function mostrarCampoEsclarecimentos(perguntaProdutoId) {
    const resposta = proposta.respostasDps.listaRespostas.find(
      x => x.perguntaProdutoId === perguntaProdutoId
    );

    if (!resposta) return false;

    return resposta.idRespostaPerguntaProduto === 1;
  }

  // function mostrarCampoEsclarecimentos() {
  //   return proposta.respostasDps
  //     && proposta.respostasDps.listaRespostas.some(x => x.idRespostaPerguntaProduto === 1)
  // }

  return (
    <>
      <Buttons
        onPressVoltar={onPrevius}
        showButtonNovaCotacao={!contratoId}
        onPressNovaCotacao={onNovaCotacao}
        showButtonSalvar={!contratoId}
        onPressSalvar={() => saveProposal()}
        showButtonEfetivar={propostaId > 0 && !contratoId}
        onPressEfetivar={() => saveProposal(true)}
        showButtonImprimir={propostaId > 0}
        linkImprimir="impressao-vida"
      />
      <Spin spinning={loading} size="large">
        <Container>
          <Error erros={erros} />

          {contratoId > 0 && (
            <Result
              status="success"
              title="Parabéns, produto contratado com sucesso!"
              subTitle={`O número do contrato no Multiseguros é: ${contratoId} e você já pode consultá-lo lá.`}
              extra={[
                <Button
                  className="btn btn-xs-block mb-2 mb-sm-0"
                  type="primary"
                  href={`https://multiseguros${
                    href.indexOf('hmo') !== -1 ? `hmo` : ``
                  }.brbseguros.com.br/#/contrato-vida/${contratoId}/99310/${
                    cliente.clienteId
                  }/5819`}
                  target="_blank"
                  key="1"
                >
                  Ir ao Multiseguros
                </Button>,
              ]}
            />
          )}

          <div
            style={contratoId ? { pointerEvents: 'none', opacity: '0.8' } : {}}
          >
            <h4>Vida</h4>
            <TitleSection title="DPS / Atividades" />
          </div>

          {isencaoDps && (
            <Row style={{ marginBottom: 12, marginTop: 18 }}>
              <Alert
                showIcon
                description={`Para o plano ${planoSelected.planoGarantiaVidaDescricao} não há a necessidade de preenchimento da DPS.`}
                message="Atenção:"
                type="warning"
              />
            </Row>
          )}

          {!isencaoDps && (
            <>
              {(perguntasProduto || []).map((x, i) => (
                <>
                  <Disable disable={!!contratoId}>
                    <Row key={x.perguntaProdutoId}>
                      <div className="form-group">
                        <Col
                          xs={{ span: 24 }}
                          sm={{
                            span: mostrarCampoPessoaResposta(
                              x.perguntaProdutoId
                            )
                              ? 16
                              : 20,
                          }}
                        >
                          <label> {x.perguntaProdutoDescricao} </label>
                        </Col>
                        <Col
                          xs={{
                            span: mostrarCampoPessoaResposta(
                              x.perguntaProdutoId
                            )
                              ? 12
                              : 24,
                          }}
                          sm={{
                            span: mostrarCampoPessoaResposta(
                              x.perguntaProdutoId
                            )
                              ? 4
                              : 4,
                          }}
                          style={{
                            paddingRight: mostrarCampoPessoaResposta(
                              x.perguntaProdutoId
                            )
                              ? 8
                              : 0,
                          }}
                        >
                          <Radio.Group
                            buttonStyle="solid"
                            defaultValue={
                              modelRespostasDadas(x.perguntaProdutoId)
                                .idRespostaPerguntaProduto
                            }
                            options={respostasPerguntasDpsAtividades}
                            onChange={({ target: { value } }) =>
                              changeResposta(
                                x.perguntaProdutoId,
                                value,
                                'idRespostaPerguntaProduto'
                              )
                            }
                          />
                        </Col>
                        {mostrarCampoPessoaResposta(x.perguntaProdutoId) && (
                          <>
                            <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                              <Select
                                itens={pessoaRespostaPerguntasDpsAtividade}
                                itemValue="idPessoaRespostaPerguntaProduto"
                                itemText="descPessoaRespostaPerguntaProduto"
                                value={
                                  mostrarCampoPessoaResposta(
                                    x.perguntaProdutoId
                                  )
                                    ? modelRespostasDadas(x.perguntaProdutoId)
                                        .idPessoaRespostaPerguntaProduto
                                    : 1
                                }
                                onChange={value =>
                                  changeResposta(
                                    x.perguntaProdutoId,
                                    value,
                                    'idPessoaRespostaPerguntaProduto'
                                  )
                                }
                              />
                            </Col>
                          </>
                        )}
                      </div>
                    </Row>
                  </Disable>
                  {mostrarCampoEsclarecimentos(x.perguntaProdutoId) && (
                    <>
                      <div className="form-group">
                        <Disable disable={!!contratoId}>
                          <TextArea
                            placeholder="Esclarecimentos"
                            value={
                              modelRespostasDadas(x.perguntaProdutoId)
                                .respostaObservacao
                            }
                            onChange={value =>
                              changeResposta(
                                x.perguntaProdutoId,
                                value.target.value,
                                'respostaObservacao'
                              )
                            }
                            autoSize={{ minRows: 3, maxRows: 8 }}
                          />
                        </Disable>
                      </div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                    </>
                  )}
                </>
              ))}
            </>
          )}

          {/* {propostaId > 0 && (
            <>
              <TitleSection title="Enviar Proposta Digitalizada" />

              <Upload {...props} disabled={fileList.length > 0}>
                <Button icon={<UploadOutlined />}>Selecionar Arquivo</Button>
              </Upload>
            </>
          )} */}
        </Container>
      </Spin>

      <Buttons
        onPressVoltar={onPrevius}
        showButtonNovaCotacao={!contratoId}
        onPressNovaCotacao={onNovaCotacao}
        showButtonSalvar={!contratoId}
        onPressSalvar={() => saveProposal()}
        showButtonEfetivar={propostaId > 0 && !contratoId}
        onPressEfetivar={() => saveProposal(true)}
        showButtonImprimir={propostaId > 0}
        linkImprimir="impressao-vida"
      />
    </>
  );
}
