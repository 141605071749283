import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import Constants from '../../../../utils/constants';
import { store } from '~/store';
import { Functions } from '~/utils';

function formatarContrato(proposta, cliente, usuario, producao) {
  let dadosCondicionais = {
    contratoVidaDataContrato: formatISO(add(new Date(), { days: 1 })),
    contratoVidaDataFinal: formatISO(add(new Date(), { years: 1, days: 1 })),
    contratoVidaObservacao: 'PROPOSTA ELETRONICA',
    contratoVidaVencimento1Parcela: formatISO(new Date()),
    mtrContratoVida1Parcela: proposta.planoSelected.mtrPremioTotal,
  };

  if (proposta.tipoPlano === Constants.BrbMaster.tipoPlano.titularConjuge) {
    dadosCondicionais = {
      ...dadosCondicionais,
      contratoVidaConjugeCpf: proposta.conjugeCpf,
      contratoVidaConjugeNome: proposta.conjugeNome,
      contratoVidaConjugeDataNascimento: proposta.conjugeDataNascimento,
      contratoVidaConjugeAltura: proposta.conjugeAltura,
      contratoVidaConjugePeso: proposta.conjugePeso,
    };
  }

  const benficiarios = proposta.beneficiarios.map(b => {
    const result = {
      moduloId: 3, // VIDA
      beneficiarioId: 0,
      clienteId: cliente.clienteId,
      mtrParticipacao: b.percentual * 0.01,
      fatoBeneficiarioNivelDoisDataCadastro: formatISO(new Date()),
      grauRelacionamentoId: b.grauRelacionamento,
      beneficiario: {
        beneficiarioNome: b.nome,
        beneficiarioDataNascimento: b.dataNascimento,
      },
    };

    return result;
  });

  const premioTotal = proposta.planoSelected.mtrPremioTotal;
  const iof = (premioTotal * 0.38) / 100;
  const premioLiquido = premioTotal - iof;

  const contrato = {
    contratoVidaNumeroControle: proposta.numeroPropostaManual,
    clienteId: cliente.clienteId,
    contratoVidaStatusId: 1, // ativo
    seguradoraId: Constants.Seguradoras.Mongeral,
    produtoId: Constants.Produtos.BrbMasterPremiado,
    contratoVidaDataCadastro: formatISO(new Date()),
    contratoVidaDataCancelamento: null,
    contratoFormaPagamentoId:  proposta.tipoPagamento == 1 ? 5 : 1  , // fracionado/debito no mensal avisa débito no anual
    planoGarantiaVidaId: proposta.planoSelected.planoGarantiaVidaId,
    contratoVidaApolice: '',
    contratoVidaDataEmissaoApolice: formatISO(new Date()),
    correcaoCapitalVidaId: 2, // anual
    tipoPagamentoId: proposta.tipoPagamento,
    mtrContratoVidaPremio: premioTotal,
    mtrContratoVidaPremioLiquido: premioLiquido,
    mtrContratoVidaComissaoCorretagem: 0.22, // 22 %
    mtrContratoVidaIof: iof,
    agenciaId: producao.agenciaId,
    equipeId: producao.equipeId,
    angariadorId: producao.angariadorId,
    consultorId: producao.consultorId,
    contratoVidaTitularAltura: proposta.titularAltura,
    contratoVidaTitularPeso: proposta.titularPeso,
    contratoVidaDiaVencimentoDemaisParcelas: proposta.diaDebito,
    debitoConta: {
      produtoId: Constants.Produtos.BrbMasterPremiado,
      agenciaId: proposta.agenciaDebitoId,
      debitoContaNumeroConta: proposta.contaDebitoNumero,
    },
    ...dadosCondicionais,
    fatoBeneficiarioNivelDois: benficiarios,
  };

  return contrato;
}

function formatarCliente(cliente, endereco) {
  return {
    ...cliente,
    clienteTipoId: cliente.clienteTipoId || 1, // Pessoa Física
    clienteContatoFones: cliente.clienteContatoFones.map(cf => {
      cf.clienteContatoFoneTipo = null; // Limpa o objeto do tipo de telefone
      return cf;
    }),
    clienteEnderecos: endereco.clienteEnderecoId
      ? cliente.clienteEnderecos.map(e => {
          if (e.clienteEnderecoId === endereco.clienteEnderecoId) {
            e = { ...e, ...endereco };
          }

          return e;
        })
      : [...cliente.clienteEnderecos, endereco],
  };
}

function formataJsonDemaisDados(proposta, cliente) {
  const {
    tipoPropostaId,
    tipoConta,
    tipoPlano,
    respostasDps,
    planoSelected,
  } = proposta;
  const { mtrPremioSaf } = planoSelected;
  const {
    clienteFaixaRendaManual,
    clienteProfissaoBrbMaster,
    clienteProfissaoDescricaoBrbMaster,
    clienteNrFilhos,
    clienteProfissaoCategoria,
  } = cliente;
  const urlArquivo = '';

  return JSON.stringify({
    tipoPropostaId,
    tipoConta,
    tipoPlano,
    respostasDps,
    clienteFaixaRendaManual,
    clienteProfissaoBrbMaster,
    clienteProfissaoDescricaoBrbMaster,
    clienteNrFilhos,
    clienteProfissaoCategoria,
    urlArquivo,
    mtrPremioSaf,
    planoSelected,
  });
}

function formatarProposta(efetivar) {
  const {
    auth: { usuario },
    cliente: { cliente, endereco },
    proposta: { propostaId, brbMaster: proposta, producao },
  } = store.getState();

  const clienteFormatado = formatarCliente(cliente, endereco);

  const propostaFormatada = {
    clienteId: cliente.clienteId,
    produtoId: Constants.Produtos.BrbMasterPremiado,
    moduloId: Constants.Modulos.VIDA,
    usuarioId: usuario.matricula,
    planoGarantiaVidaId: proposta.planoSelected?.planoGarantiaVidaId,
    cliente: clienteFormatado,
    propostaEletronicaId: propostaId,
    propostaEletronicaEfetivada: efetivar,
    propostaEletronicaDataCadastro: formatISO(new Date()),
    propostaEletronicaDataEfetivacao: efetivar ? formatISO(new Date()) : null,
    propostaEletronicaJsonContrato: JSON.stringify(
      formatarContrato(proposta, clienteFormatado, usuario, producao)
    ),
    propostaEletronicaJsonDemaisDados: formataJsonDemaisDados(
      proposta,
      cliente
    ),
  };

  return propostaFormatada;
}

export default formatarProposta;
