import React, { useEffect } from 'react';

import { Row, Col, Skeleton, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Block, BlockTable } from './styles';
import imgVidaLogo from '~/assets/favicon.png';
import * as DominiosActions from '~/store/ducks/dominio';

import imgResidenciaPremiado from '~/assets/img/brb_residencia_premiado.jpg';
import imgLiberty from '~/assets/img/liberty.png';
import { Functions, Mask } from '~/utils';

type FieldProps = {
  span: Number,
  title: String,
  value: String,
};

export default function Impressao() {
    const {
        generos,
        estadosCivis,
        naturalidade,
        grausRelacionamento,
        consultores,
        angariadores,
        agenciasBrb,
        loading,
        profissoesLiberty,
        profissoes,
        cargos,
        faixaRenda,
      } = useSelector(({ dominio }) => dominio);
      const { equipesProdutor } = useSelector(({ producao }) => producao);
      const { cliente, endereco } = useSelector(state => state.cliente);
    
      const {
        contratoId,
        propostaId,
        apLiberty,
        apLiberty: { beneficiarios, planoSelected },
        producao,
        perguntasProduto,
        numeroControle
      } = useSelector(({ proposta }) => proposta);
    
      const dispatch = useDispatch();
    
      useEffect(() => {
        dispatch(DominiosActions.listAll(print));
      }, []);
    
      function print() {
        setTimeout(function(){ window.print(); }, 2000); // ADD ESSA LINHA POIS NÃO ESTAVA CARREGANDO AS IMAGENS NA IMPRESSÃO
      }
    
      function Field({ span, title, value }: FieldProps) {
        if (!value) return <></>;
        return (
          <Col span={span}>
            <b>{title}:</b> {value}
          </Col>
        );
      }
    
      function getDescricaoDominio(dominio, textValue, textDescription, value) {
        if (!value) return 'Não Informado';
        return dominio.find(d => d[textValue] == value)?.[textDescription];
      }
      function getMarcacaoDpsPergunta(perguntaProdutoId, idPessoaRespostaPerguntaProduto){
        var pergunta = ((apLiberty.respostasDps || {}).listaRespostas || [])
          .find(x => x.perguntaProdutoId === perguntaProdutoId)
    
        // idPessoaRespostaPerguntaProduto 3 significa ambos
        return pergunta && pergunta.idRespostaPerguntaProduto === 21 
          && (pergunta.idPessoaRespostaPerguntaProduto === idPessoaRespostaPerguntaProduto 
              || pergunta.idPessoaRespostaPerguntaProduto === 1) ? "Sim" : "Não"
      }

      function getProfissao() {
        const profissaoFiltered = profissoesLiberty.filter(
          p => p.codigo === cliente.clienteProfissaoId
        )[0];
        if (profissaoFiltered) return profissaoFiltered.clienteProfissaoDescricao;
    
        return '';
      }
      
      return (
        <>
          <Spin
            spinning={loading}
            style={{ textAlign: 'center' }}
            size="large"
            tip="Aguarde carregando"
          >
            {loading && <Skeleton active />}
          </Spin>
          {generos && angariadores && consultores && (
            <Container>
              {/* Dados Proposta */}
              <Block>
                <Row type="flex" style={{ alignItems: 'center' }}>
                  <Col span={2} style={{ paddingLeft: 4 }}>
                    <img
                      src={imgVidaLogo}
                      alt="BRB"
                      style={{ width: 44, height: 36 }}
                    />
                  </Col>
                  <Col span={8}>
                    <b style={{ fontSize: 16 }}>BRB VIDA ACIDENTES PESSOAIS</b>
                  </Col>
                  <Col span={6} style={{ alignItems: 'center' }}>
                    <b>{contratoId ? 'Proposta de Adesão' : 'Cotação de Seguro'}</b>
                    <br />
                    {contratoId
                      ? `Número da Proposta: ${numeroControle}`
                      : `Número da Cotação: ${propostaId}`}
                  </Col>
                  <Col
                    span={8}
                    style={{
                      textAlign: 'right',
                      paddingRight: 10,
                    }}
                  >
                    {/* <img src={imgAmericanLife} alt="Logo" width="70" height="74" /> */}
                  </Col>
                </Row>
              </Block>
              <Block>
                <Row type="flex">
                  <Field 
                    span={24} 
                    title="Estipulante" 
                    value="AEBRB - Associação dos Empregados do Banco de Brasília - Apólice de Vida em Grupo - Nº 1009300001038" />
                  <Field
                    span={10}
                    title="Seguradora"
                    value="LIBERTY COMPANHIA DE SEGUROS"
                  />
                  <Field span={8} title="Tipo de Seguro" value="Seguro Novo" />                 
                </Row>
              </Block>
              {/* Dados do Cliente */}
              <Block>
                <Row style={{ marginBottom: 8 }}>
                  <b>DADOS DO PROPONENTE TITULAR</b>
                </Row>
                <Row>
                  <Field span={10} title="Nome" value={cliente.clienteNome} />
                  <Field span={8} title="CPF" value={cliente.clienteCpfCnpj} />
                  <Field
                    span={6}
                    title="Data de Nascimento"
                    value={Functions.formatarData(cliente.clienteDataNascimento)}
                  />
                </Row>
                <Row>
                  <Field span={10} title="Endereço" value={endereco.clienteEndereco1} />
                  <Field span={8} title="Bairro" value={endereco.clienteEnderecoBairro} />
                  <Field
                    span={6}
                    title="CEP"
                    value={endereco.clienteEnderecoCep}
                  />
                </Row>
                <Row>
                  <Field span={10} title="Cidade" value={endereco.clienteEnderecoCidade} />
                  <Field span={8} title="UF" value={endereco.clienteEnderecoUf} />
                  <Field span={6} title="Telefone" value={cliente.clienteContatoFones[0]?.clienteContatoFoneNumero} />
                </Row>
                <Row>
                  <Field span={10} title="Profissão" value={getProfissao()} />
                  <Field span={14} title="Ramo de Atividade" value={cliente.clienteRamoAtividade} />
                </Row>
                <Row>
                  <Field span={14} title="Renda Mensal" value={Mask.maskMoney(cliente.clienteFaixaRendaManual)} />
                </Row>
                <Row>
                  <Field
                    span={10}
                    title="Sexo"
                    value={getDescricaoDominio(
                      generos,
                      'sexoId',
                      'sexoDescricao',
                      cliente.sexoId
                    )}
                  />
                  <Field
                    span={8}
                    title="Estado Civil"
                    value={getDescricaoDominio(
                      estadosCivis,
                      'estadoCivilId',
                      'estadoCivilDescricao',
                      cliente.estadoCivilId
                    )}
                  />
                  <Field
                    span={6}
                    title="Nacionalidade"
                    value={getDescricaoDominio(
                      naturalidade,
                      'naturalidadeId',
                      'naturalidadeDescricao',
                      cliente.naturalidadeId
                    )}
                  />
                </Row>
                <Row>
                  <Field
                    span={10}
                    title="Nº da Identidade"
                    value={cliente.clienteRg}
                  />
                  <Field
                    span={8}
                    title="Data de emissão"
                    value={Functions.formatarData(cliente.clienteDataExpedicaoRg)}
                  />
                  <Field
                    span={6}
                    title="Orgão Emissor"
                    value={cliente.clienteRgSsp}
                  />
                </Row>
                <Row>
                  <Field
                    title="E-mail"
                    value={
                      cliente.clienteContatoMails[0]?.clienteContatoMailDescricao
                    }
                  />
                </Row>
              </Block>              
              {/* Opção de Plano */}
              <Block>
                <Row style={{ marginBottom: 8 }}>
                  <b>OPÇÕES DE PLANO</b>
                </Row>
                <Row>
                  <Field
                    xs={{ span: 24 }}
                    sm={{ span: 10 }}
                    title="Tipo de Plano"
                    value='Titular'
                  />
                </Row>
                
              </Block>                    
              
              {planoSelected && (                
                <Block>
                  <Row style={{ marginBottom: 8 }}>
                    <b>PLANO, COBERTURAS E PRÊMIOS</b>
                  </Row>    
                    
              {(planoSelected.detalhesPlano || []).map((x) =>(
                 <Row>
                 <Field
                   span={10}
                   title={x.descricao}
                   value={x.valor}
                 />
                 </Row>
                ))}
                                </Block>
              )}
              <Block>
                <Row style={{ marginBottom: 8 }}>
                  <b>PAGAMENTO DO PRÊMIO</b>
                </Row>
                <Row>
                  <Field
                    xs={{ span: 24 }}
                    sm={{ span: 14 }}
                    title="Dia para débito em Conta Corrente"
                    value={apLiberty.diaDebito}
                  />
                </Row>
              </Block>
              <Block>
                <Row style={{ marginBottom: 8 }}>
                  <b>BENEFICIÁRIOS</b>
                </Row>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Cpf</th>
                      <th>Parentesco</th>                      
                      <th>Participação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {beneficiarios.map(b => (
                      <tr key={b.nome}>
                        <td>{b.nome}</td>
                        <td>{b.cpf}</td>
                        <td>
                          {getDescricaoDominio(
                            grausRelacionamento,
                            'grauRelacionamentoId',
                            'grauRelacionamentoDescricao',
                            b.grauRelacionamento
                          )}
                        </td>
                        <td>{b.percentual}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Block>
    
              {/* <p style={{ pageBreakBefore: "always" }}>&nbsp;</p> */}
    
              {/* DPS / Atividades */}
    
              {apLiberty.tipoPropostaId && apLiberty.tipoPropostaId == 1 
                && perguntasProduto && perguntasProduto.length && apLiberty.respostasDps && (
                  <Block>
                    <Row style={{ marginBottom: 8 }}>
                      <b>DECLARAÇÃO PESSOAL DE SAÚDE / ATIVIDADES</b>
                    </Row>
                    <Row style={{ padding: 10, marginBottom: 10 }}>
                      Responda as perguntas abaixo por extenso <b><u>SIM</u></b> ou <b><u>NÃO</u></b>. Qualquer resposta SIM deve ser complementada no espaço existente após o
                      questionário.
    
                    </Row>
                    <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: 2 }}>
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ padding: 10, border: 'solid', borderWidth: 0.5, margin: 0.3 }}>Titular</th>                                            
                        </tr>
                      </thead>
                      <tbody>
                        {perguntasProduto.map((p, i) => (
                          <>
                            {/* {i == 3 ? <p style={{ pageBreakBefore: "always" }}></p> : ""} */}
                            <tr key={p.perguntaProdutoId}>
                              <td style={{ padding: 10, border: 'solid', borderWidth: 0.5, textAlign: "justify" }}>
                                <b>{i + 1})</b> {p.perguntaProdutoDescricao}
                              </td>
                              <td style={{ padding: 10, border: 'solid', borderWidth: 0.5, margin: 0.3, textAlign: 'center' }}>
                                {/* Comentado parte da resposta a pedido da Dani da GEOPE */}
                                { <b>
                                  {getMarcacaoDpsPergunta(p.perguntaProdutoId, 1)}
                                </b>}
                              </td>                              
                            </tr>
                          </>
                        ))}                       
                      </tbody>
                    </table>
                    { apLiberty.tipoPropostaId && apLiberty.tipoPropostaId == 1 
                      && perguntasProduto && perguntasProduto.length && apLiberty.respostasDps
                      && (
                        <>
                          <Row style={{ padding: 10, marginBottom: -10 }}>
                            Utilize o espaço abaixo para complementar sua declaração de saúde, se necessário.
    
                          </Row>
    
                          <Row style={{ padding: 10, marginBottom: 10, fontSize: 16 }}>
                            ______________________________________________________________________________________________________________________________________________
                            ______________________________________________________________________________________________________________________________________________
                            ______________________________________________________________________________________________________________________________________________
                          </Row>
                        </>
                      )
                    }
                  </Block>
                )
              }
              {/* Termo e Assinatura */}
              {apLiberty.tipoPropostaId && apLiberty.tipoPropostaId == 1 && (
                <Block>
                  <b>DECLARAÇÃO E AUTORIZAÇÃO PARA DÉBITO EM CONTA CORRENTE</b>
                  <Row style={{ paddingBottom: 10, paddingTop: 10, textAlign: "justify", textJustify: "inter-word" }}>
                  Pelo presente, declaro que recebi, conheço e concordo com as Condições Gerais do presente seguro,
                  e que a Liberty Seguros disporá de 15 (quinze) dias para analisar esta Proposta, contado do seu
                  protocolo em qualquer dos seus escritórios. A inexistência de manifestação expressa pela 
                  Seguradora, dentro deste prazo implicará na aceitação automática desta proposta. Sendo aceita 
                  esta proposta, autorizo o BRB - Banco de Brasília S.A. a debitar o(s) prêmio(s) correspondente(s) 
                  ao seguro ora proposto no Banco <b>070</b>,
                    Agência{' '}
                    <b>
                      {getDescricaoDominio(
                        agenciasBrb,
                        'agenciaId',
                        'agenciaCodigo',
                        apLiberty.agenciaDebitoId
                      )}
                    </b>
                    , Conta Corrente nº <b>{apLiberty.contaDebitoNumero}</b>, na data acima assinalada, ciente de que, 
                    não existindo saldo disponível na data do débito, ele não será efetuado, ocasionando a interrupção das 
                    coberturas do seguro até a regularização do pagamento, nos termos das Condições Gerais da Apólice.
                  </Row>
                  <Row style={{ marginTop: 60 }}>
                    <Field
                      span={14}
                      title="Local e data"
                      value={`Brasília, ${Functions.getDateNow()}`}
                    />
                    <Col span={6} style={{ textAlign: 'center' }}>
                      ______________________________________________
                      <br />
                      <b>Assinatura do Proponente</b>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 8, textAlign: "justify", textJustify: "inter-word" }}>
                    O SEGURO BRB VIDA ACIDENTES PESSOAIS É GARANTIDO 100% (CEM POR CENTO)
                    PELA LIBERTY COMPANHIA DE SEGUROS. Avenida Angélica nº 2.626
                    Consolaçao, São Paulo/ SP CNPJ -67.865.360/0001-27, CÓDIGO SUSEP:
                    0581-9 PROCESSO SUSEP 10.003894/99-11, 15414.003320/2007-10,
                    15414.901246/2013-39  
                  </Row>
                  <Row style={{ textAlign: "justify", textJustify: "inter-word" }}>
                    SAC: 0800-755-5985 SAC Deficiência Auditiva
                    ou de Fala: 0800-770-9797 Ouvidoria: 0800-770-1102
                  </Row>
                  <Row style={{ textAlign: "justify", textJustify: "inter-word" }}>
                    CORRETOR: BRB
                    ADMINISTRADORA E CORRETORA DE SEGUROS S/A - CÓDIGO SUSEP
                    Nº 058907.1.006614-1
                  </Row>
                </Block>
              )}
    
              {/* <p style={{ pageBreakBefore: "always" }}>&nbsp;</p> */}
    
              {/* Dados Produção */}
              <Block>
                <Row style={{ marginBottom: 12 }}>
                  <b>DADOS DA PRODUÇÃO</b>
                </Row>
                <Row>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Mat. Consultor</th>
                        <th>Mat. Angariador</th>
                        <th>Agência Produtora</th>
                        <th>Equipe</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {getDescricaoDominio(
                            consultores,
                            'produtorId',
                            'produtorCodigo',
                            producao.consultorId
                          )}
                        </td>
                        <td>
                          {getDescricaoDominio(
                            angariadores,
                            'produtorId',
                            'produtorCodigo',
                            producao.angariadorId
                          )}
                        </td>
                        <td>
                          {`${getDescricaoDominio(
                            agenciasBrb,
                            'agenciaId',
                            'agenciaCodigo',
                            producao.agenciaId
                          )} - ${getDescricaoDominio(
                            agenciasBrb,
                            'agenciaId',
                            'agenciaDescricao',
                            producao.agenciaId
                          )}`}
                        </td>
                        <td>
                          {getDescricaoDominio(
                            equipesProdutor,
                            'equipeId',
                            'equipeDescricao',
                            producao.equipeId
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </Block>
            </Container>
          )}
        </>
      );
}