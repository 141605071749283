import { LineConfig } from '@ant-design/charts/es/line';
import { Functions } from 'utils';

function retornaConfig(dataGrafico) {
  var config: LineConfig = {
    data: dataGrafico,
    xField: 'mes',
    yField: 'valor',
    // Configuração do texto de cada ponto
    label: {
      formatter: (obj) => retornalabelGraficoLinha(obj)
    },
    // configuração dos pontos no gráfico
    point: {
      size: 3,
      shape: 'diamond',
      style: {
        fill: 'black',
        stroke: '#5B8FF9',
        lineWidth: 1,
      },
    },
    // Configuração do texto apresentado no tooltip do ponto
    tooltip: {
      formatter: (obj) => tooltipGraficoLinha(obj)
    },
    annotations: [
      {
        type: 'regionFilter',
        // [início eixo x, início eixo y] -> do 100 pra baixo(y) e do primeiro item(x) pega a cor informada na propriedade 'color'
        start: ['min', '100'],
        // [fim eixo x, fim eixo y] -> até o 0(y) e até o último item(x) pega a cor informada na propriedade 'color'
        end: ['max', '0'],
        color: '#F4664A',
      }
    ],
    
  };

  return config;
}

function retornalabelGraficoLinha(obj) {
  return Functions.formatarPorcentagem(obj.valor)
}

function tooltipGraficoLinha(x) {
  return {
    name: 'Realizado',
    value: Functions.formatarPorcentagem(x.valor)
  };
}

export default {
  retornaConfig
}