import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Functions } from '~/utils';
import history from '~/services/history';
import { Spin } from 'antd';
import * as AuthActions from '~/store/ducks/auth';

export default function VendaAssistida() {

    const dispatch = useDispatch();

    const { keySSOWiz, usuario } = useSelector(state => state.auth);
    const { produtoId } = useParams();

    useEffect(() => {

        const produtoGrupoId = obterProdutoGrupoId();
        const matricula = usuario.matricula;

        const url = Functions.isProducao() 
        ? `https://brbcompleto.com.br/integration#` 
        : `https://brbcgestorhmlstg.z13.web.core.windows.net/integration#`;

        dispatch(AuthActions.tokenWiz({
            param: { matricula, produtoId, produtoGrupoId },
            callback: response => {
                
                if (!response) history.push('/');

                window.open(url + response, '_blank');
                history.push('/');
            }
        }));
    }, [produtoId]);

    function obterProdutoGrupoId() {
        switch (produtoId) {
            case "96053":
                return 6; // ODONTO

            case "11410":
                return 10; // RESIDENCIAL

            case "99312":
                return 8; // VIDA

            case "98208":
                return 9; // Brb AP Proteção Pessoal
        }

    }

    return (
        <Spin spinning={true} size="large">
            <div style={{ height: 300 }}></div>
        </Spin>
    )
}