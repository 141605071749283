import { Functions } from '.';

const Seguradoras = {
  Mitsui: 6602,
  AmericanLife: 5819,
  Liberty: 5185,
  Zurich: 5495,
  Icatu: 4444,
  Mongeral: 2101,
};

const Produtos = {
  DreMitsui: 11407,
  VidaPremiadoPlus: 99310,
  DreLibertyCeS: 11804,
  DreZurichRes: 11402,
  DreZurichEmp: 11802,
  DreMitsuiEmp: 11806,
  CapIcatuPU: 161137,
  CapIcatu24: 161139,
  CapIcatu48: 161144,
  CapIcatu60: 161142,
  CapIcatu84: 161143,
  AcidentesPessoaisLiberty: 138104,
  LibertyResidencialPersonalizavel: 11409,
  BrbMasterPremiado: 139113,
};

const Meses = [
  { id: 1, descricao: 'Janeiro' },
  { id: 2, descricao: 'Fevereiro' },
  { id: 3, descricao: 'Março' },
  { id: 4, descricao: 'Abril' },
  { id: 5, descricao: 'Maio' },
  { id: 6, descricao: 'Junho' },
  { id: 7, descricao: 'Julho' },
  { id: 8, descricao: 'Agosto' },
  { id: 9, descricao: 'Setembro' },
  { id: 10, descricao: 'Outubro' },
  { id: 11, descricao: 'Novembro' },
  { id: 12, descricao: 'Dezembro' }
]

const Modulos = {
  AUTOMOVEL: 1,
  RISCO_DIVERSOS: 2,
  VIDA: 3,
  PRESTAMISTA: 4,
  SAUDE: 5,
  CAP: 6,
  CONSORCIO: 7,
  VIAGEM_E_TURISMO: 8,
  FATURA: 9,
  PREVIDÊNCIA: 10,
  FERIAS: 11,
};

const MetaAgenciaRamo = {
  SEM_RAMO: 0,
  RESIDENCIA: 10,
  AUTOMOVEL: 20,
  RISCOS_DIVERSOS: 30,
  VIDA: 40,
  CAPITALIZACAO: 50,
  PREVIDENCIA: 60,
  DEMAIS_RE: 70,
  RISCOS_PESSOAIS: 90,
  VIDA_PREMIADO: 91,
  CONSORCIO: 92,
  PRESTAMISTA: 93,
  SAUDE: 94,
  VIAGEM_E_TURISMO: 95,
}

const RespostaDefaultRadioOption = {
  respostaDefaultRadio: [
    { value: 0, label: 'Não' },
    { value: 1, label: 'Sim' },
  ],
}

const Dashboard = (
  function(){
    var obj = {
      tiposProdutos: [
        { id: 10, desc: 'Residência' },
        { id: 20, desc: 'Automóvel' },
        { id: 30, desc: 'Riscos Diversos' },
        { id: 40, desc: 'Vida' },
        { id: 50, desc: 'Captalização' },
        { id: 60, desc: 'Previdência' },
        { id: 70, desc: 'Demais RE' },
        { id: 90, desc: 'Riscos Pessoais' },
        { id: 91, desc: 'Vida Premiado' },
        { id: 92, desc: 'Consórcio' },
        { id: 93, desc: 'Prestamista' },
        { id: 94, desc: 'Saúde' },
        { id: 95, desc: 'Viagem e Turismo' },
      ],
      tiposProdutosConsultorAgencia: [10, 40, 92, 60],
      grupoPrevCapAuto: [60, 50, 20],
      grupoResidenciaVida: [10, 40],
      get encarteiramentos() {
        return [
          {
            id: 1,
            grupos: [
              {
                desc: 'Previdência / Cap / Auto',
                idsGrupo: [60, 50, 20]
              },
              {
                desc: 'Residência / Vida',
                idsGrupo: [10, 40]
              },
              {
                desc: 'Prestamista',
                idsGrupo: [93]
              },
              {
                desc: 'Outras Seguridades',
                get idsGrupo() {
                  
                  let encarteiramento = obj.encarteiramentos.find(x => x.id === 1);
                  let grupos = encarteiramento.grupos.filter(x => (x.desc || '') !== 'Outras Seguridades');
                  
                  let idsDemais = [];
    
                  (grupos || []).forEach(x => { idsDemais = idsDemais.concat(x.idsGrupo); });
    
                  return obj.tiposProdutos.filter(x => !idsDemais.some(y => y == x.id)).map(x => x.id);
                }                
              },
            ]
          },
          {
            id: 2,
            grupos: [
              {
                desc: 'Residência',
                idsGrupo: [10]
              },
              {
                desc: 'Vida',
                idsGrupo: [40]
              },
              {
                desc: 'Cap / Auto',
                idsGrupo: [20, 50]
              },
              {
                desc: 'Prestamista',
                idsGrupo: [93]
              },
              {
                desc: 'Outras Seguridades',
                get idsGrupo() {
                  
                  let encarteiramento = obj.encarteiramentos.find(x => x.id === 2);
                  let grupos = encarteiramento.grupos.filter(x => (x.desc || '') !== 'Outras Seguridades');
                  
                  let idsDemais = [];
    
                  (grupos || []).forEach(x => { idsDemais = idsDemais.concat(x.idsGrupo); });
    
                  return obj.tiposProdutos.filter(x => !idsDemais.some(y => y == x.id)).map(x => x.id);
                }                
              },
            ]
          }
        ]
      }
    }

    return obj;
  }
)()

const ListIsPessoaPoliticamenteExpostaMulti = [
  { value: 2, label: 'Não' },
  { value: 1, label: 'Sim' },
];

const ApLiberty = {
  listParentesco: [
    { grauRelacionamentoId: 6066, grauRelacionamentoDescricao: 'CÔNJUGE' },
    { grauRelacionamentoId: 6060, grauRelacionamentoDescricao: 'FILHOS' },
    { grauRelacionamentoId: 6061, grauRelacionamentoDescricao: 'PAIS' },
    { grauRelacionamentoId: 6062, grauRelacionamentoDescricao: 'TERCEIROS' },
    { grauRelacionamentoId: 6064, grauRelacionamentoDescricao: 'IRMÃOS' },
    { grauRelacionamentoId: 6065, grauRelacionamentoDescricao: 'AVÓS' },
  ],
  listTipoPessoaPoliticamenteExposta: [
    { codigo: 0, descricao: 'NÃO SE APLICA' },
    { codigo: 1, descricao: 'PAI' },
    { codigo: 2, descricao: 'MÃE' },
    { codigo: 3, descricao: 'FILHO' },
    { codigo: 4, descricao: 'CONJUGE' },
    { codigo: 5, descricao: 'FILHA' },
    { codigo: 6, descricao: 'IRMÃO' },
    { codigo: 7, descricao: 'CUNHADO' },
    { codigo: 8, descricao: 'HOUSE HOLD' },
    { codigo: 9, descricao: 'ADMINISTRADORÃE' },
    { codigo: 10, descricao: 'CONTROLADOR' },
    { codigo: 11, descricao: 'OUTROS' },
  ],
  listIsPessoaPoliticamenteExposta: [
    { value: 0, label: 'Não' },
    { value: 1, label: 'Sim' },
  ],
  listTipoPagamento: [
    { value: 1, label: 'Mensal' },
    { value: 2, label: 'Anual' },
  ],
  listTipoConta: [
    { value: 1, label: 'Titular' },
  ],
  listDiaDebito: ['1', '7', '10', '15', '20', '25'],
  respostasPerguntasDpsAtividades: [
    { value: 22, label: 'Não' },
    { value: 21, label: 'Sim' },
  ],
  planos: [
    {
      id: 32897, nome: 'Plano 1', info: "Informações adicionais sobre o plano...", detalhesPlano: [
        { descricao: "MAC", valor: "R$ 50.000,00" }
        , { descricao: "IPA", valor: "R$ 50.000,00" }
        , { descricao: "DMHO", valor: "R$ 3.000,00" }
        , { descricao: "ASSIST. F. TITULAR", valor: "R$ 6.000,00" }
        , { descricao: "PET", valor: "SIM" }
        , { descricao: "SORTEIO", valor: "R$ 10.000,00" }
        , { descricao: "COMISSAO", valor: "50%" }
        , { descricao: "CUSTO ANUAL", valor: "R$ 478,65" }
        , { descricao: "PARC. MÝXIMO", valor: "12" }]
    },
    {
      id: 32896, nome: 'Plano 2', info: "Informações adicionais sobre o plano...", detalhesPlano: [
        { descricao: "MAC", valor: "R$ 75.000,00" }
        , { descricao: "IPA", valor: "R$ 75.000,00" }
        , { descricao: "DMHO", valor: "R$ 3.000,00" }
        , { descricao: "ASSIST. F. TITULAR", valor: "R$ 6.000,00" }
        , { descricao: "PET", valor: "SIM" }
        , { descricao: "SORTEIO", valor: "R$ 10.000,00" }
        , { descricao: "COMISSAO", valor: "50%" }
        , { descricao: "CUSTO ANUAL", valor: "R$ 553,40" }
        , { descricao: "PARC. MÝXIMO", valor: "12" }]

    },
    {
      id: 32893, nome: 'Plano 3', info: "Informações adicionais sobre o plano...", detalhesPlano: [
        { descricao: "MAC", valor: "R$ 100.000,00" }
        , { descricao: "IPA", valor: "R$ 100.000,00" }
        , { descricao: "DMHO", valor: "R$ 3.000,00" }
        , { descricao: "ASSIST. F. TITULAR", valor: "R$ 6.000,00" }
        , { descricao: "PET", valor: "SIM" }
        , { descricao: "SORTEIO", valor: "R$ 10.000,00" }
        , { descricao: "COMISSAO", valor: "50%" }
        , { descricao: "CUSTO ANUAL", valor: "R$ 628,18" }
        , { descricao: "PARC. MÝXIMO", valor: "12" }]
    },
    {
      id: 32894, nome: 'Plano 4', info: "Informações adicionais sobre o plano...", detalhesPlano: [
        { descricao: "MAC", valor: "R$ 150.000,00" }
        , { descricao: "IPA", valor: "R$ 150.000,00" }
        , { descricao: "DMHO", valor: "R$ 3.000,00" }
        , { descricao: "ASSIST. F. TITULAR", valor: "R$ 6.000,00" }
        , { descricao: "PET", valor: "SIM" }
        , { descricao: "SORTEIO", valor: "R$ 10.000,00" }
        , { descricao: "COMISSAO", valor: "50%" }
        , { descricao: "CUSTO ANUAL", valor: "R$ 777,21" }
        , { descricao: "PARC. MÝXIMO", valor: "12" }]
    },
    {
      id: 32895, nome: 'Plano 5', info: "Informações adicionais sobre o plano...", detalhesPlano: [
        { descricao: "MAC", valor: "R$ 200.000,00" }
        , { descricao: "IPA", valor: "R$ 200.000,00" }
        , { descricao: "DMHO", valor: "R$ 3.000,00" }
        , { descricao: "ASSIST. F. TITULAR", valor: "R$ 6.000,00" }
        , { descricao: "PET", valor: "SIM" }
        , { descricao: "SORTEIO", valor: "R$ 10.000,00" }
        , { descricao: "COMISSAO", valor: "50%" }
        , { descricao: "CUSTO ANUAL", valor: "R$ 927,27" }
        , { descricao: "PARC. MÝXIMO", valor: "12" }]

    },
    {
      id: 32989, nome: 'Plano 6', info: "Plano exclusivo para agências CNP...", detalhesPlano: [
        { descricao: "MAC", valor: "R$ 15.000,00" }
      , { descricao: "IPA", valor: "R$ 0,00" }
      , { descricao: "ASSIST. F. TITULAR", valor: "R$ 0,00" }
      , { descricao: "ASSIST. DOMICILIAR", valor: "SIM" }
      , { descricao: "ASSIST. NUTRICIONISTA", valor: "NÃO" }
      , { descricao: "SORTEIO", valor: "R$ 10.000,00" }
      , { descricao: "COMISSAO", valor: "50%" }
      , { descricao: "CUSTO ANUAL", valor: "R$ 29,90" }
      , { descricao: "PARCELAMENTO", valor: "1x R$ 29,90" }]

    },
  ]

};

const VidaPremiadoPlus = {
  tipoProposta: {
    eletronica: 1,
    manual: 2,
  },
  tipoPlano: {
    titular: 1,
    titularConjuge: 2,
  },
  listTipoProposta: [
    { value: 1, label: 'Eletrônica' },
    { value: 2, label: 'Manual' },
  ],
  listTipoPlano: [
    { value: 1, label: 'Titular' },
    { value: 2, label: 'Titular + Cônjuge' },
  ],
  listTipoPagamento: [
    { value: 1, label: 'Mensal' },
    { value: 2, label: 'Anual' },
  ],
  listTipoConta: [
    { value: 1, label: 'Titular' },
    { value: 2, label: 'Terceiros' },
    { value: 3, label: 'Empresa' },
  ],
  listDiaDebito: ['1', '7', '10', '15', '20', '25'],
  colunasTabelaPlanos: [
    {
      title: 'Plano',
      dataIndex: 'planoGarantiaVidaDescricao',
    },
    {
      title: 'Morte',
      dataIndex: 'mtrGarantiaMorte',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Morte acidental',
      dataIndex: 'mtrGarantiaMorteAcidental',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Invalidez permanente por acidente',
      dataIndex: 'mtrGarantiaIPA',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Axílio funeral',
      dataIndex: 'mtrGarantiaAxilioDescessos',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Premio total',
      dataIndex: 'mtrPremioTotal',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
  ],
  respostasPerguntasDpsAtividades: [
    { value: 0, label: 'Não' },
    { value: 1, label: 'Sim' },
  ],
  pessoaRespostaPerguntasDpsAtividade: [
    {
      idPessoaRespostaPerguntaProduto: 1,
      descPessoaRespostaPerguntaProduto: 'Titular',
    },
    {
      idPessoaRespostaPerguntaProduto: 2,
      descPessoaRespostaPerguntaProduto: 'Cônjuge',
    },
    {
      idPessoaRespostaPerguntaProduto: 3,
      descPessoaRespostaPerguntaProduto: 'Ambos',
    },
  ],
};

const ResidencialPersonalizavelLiberty = {
  TipoOcupacaoImovel: {
    Habitual: '1',
    Veraneio: '2',
    HomeOffice: '3'
  },
  TipoHabitacao: {
    Casa: '1',
    Apartamento: '2',
    CasaEmCondominioFechado: '3',
    ChacaraSitioFazendo: '4'
  },
  Cobertura: {
    Principal: '11429',
    PerdaDeLucroEDespesasFixasPorIncendio: '11705',
    PagamentoDeCondominioPorPerdaDeRenda: '11701',
    RouboDeBensNoDepositoOuBicicletario: '11703',
    DanosCausadosAosBensDoCondominio: '11704',
    RouboEouSubtracaoDeBensComVestigiosVeraneio: '11431',
    RouboEouSubtracaoDeBensComVestigios: '11427',
    DanosEletricos: '11402',
    DespesasComMudanca: '11410',
    ResponsabilidadeCivilFamiliar: '11409',
    RecomposicaoDeRegistrosEDocumentos: '11408',
    EquipamentosEletronicos: '11430',
    ImpactoDeVeiculosTerrestres: '11421',
    PerdaOuPagamentoDeAluguel: '11404',
    QuebraDeVidrosEspelhosMarmoresGranitos: '11428',
    DanosPorRupturaDeTubulacao: '11716',
    VendavalFuracaoCicloneTornadoGranizo: '11426',
  },
  TipoConstrucao: {
    Madeira: '3',
    Alvenaria: '4'
  }
}

const BrbMaster = {
  tipoPlano: {
    titular: 1,
    titularConjuge: 2,
  },
  listTipoPlano: [{ value: 1, label: 'Titular' }],
  listTipoPagamento: [
    { value: 1, label: 'Mensal' },
  ],
  listTipoConta: [
    { value: 1, label: 'Selecionar Conta' },
    { value: 2, label: 'Preencher Conta Manualmente' },
  ],
  listDiaDebito: ['1', '7', '10', '15', '20', '25'],
  colunasTabelaPlanosMensal: [
    {
      title: 'Plano',
      dataIndex: 'planoGarantiaVidaDescricao',
    },
    {
      title: 'Morte Natural',
      dataIndex: 'mtrGarantiaMorte',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Serviços funerários',
      dataIndex: 'mtrGarantiaAxilioDescessos',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Prêmio mensal',
      dataIndex: 'mtrPremioTotal',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
  ],
  colunasTabelaPlanosAnual: [
    {
      title: 'Plano',
      dataIndex: 'planoGarantiaVidaDescricao',
    },
    {
      title: 'Morte Natural',
      dataIndex: 'mtrGarantiaMorte',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Serviços funerários',
      dataIndex: 'mtrGarantiaAxilioDescessos',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
    {
      title: 'Prêmio anual',
      dataIndex: 'mtrPremioTotal',
      align: 'center',
      render: text => Functions.formatarMoeda(text),
    },
  ],
  respostasPerguntasDpsAtividades: [
    { value: 0, label: 'Não' },
    { value: 1, label: 'Sim' },
  ],
  pessoaRespostaPerguntasDpsAtividade: [
    {
      idPessoaRespostaPerguntaProduto: 1,
      descPessoaRespostaPerguntaProduto: 'Titular',
    },
  ],
  categoriasProfissao: [
    { value: 'AUTONOMO', label: 'AUTONOMO' },
    { value: 'AUXILIAR NECROPSIA', label: 'AUXILIAR NECROPSIA' },
    { value: 'DATILOSCOPISTA', label: 'DATILOSCOPISTA' },
    { value: 'DELEGADO', label: 'DELEGADO' },
    { value: 'EMPREGADO', label: 'EMPREGADO' },
    { value: 'EMPREGADOR', label: 'EMPREGADOR' },
    { value: 'INSPETOR', label: 'INSPETOR' },
    { value: 'INVESTIGADOR', label: 'INVESTIGADOR' },
    { value: 'OFICIAL DE CARTORIO', label: 'OFICIAL DE CARTORIO' },
    { value: 'OUTROS', label: 'OUTROS' },
    { value: 'PERITO CRIMINAL', label: 'PERITO CRIMINAL' },
    { value: 'PERITO LEGISTA', label: 'PERITO LEGISTA' },
    { value: 'SERVIDOR PUBLICO', label: 'SERVIDOR PUBLICO' },
    { value: 'TECNICO NECROPSIA', label: 'TECNICO NECROPSIA' },
  ],
};
const OutrosBancos = [
  { bancoId: '0001', bancoDescricao: 'Banco do Brasil' },
  { bancoId: '0004', bancoDescricao: 'Banco do Nordeste do Brasil' },
  { bancoId: '0010', bancoDescricao: 'Credicoamo' },
  { bancoId: '0033', bancoDescricao: 'Santander' },
  { bancoId: '0041', bancoDescricao: 'Banco do Estado do Rio Grande do Sul' },
  { bancoId: '0047', bancoDescricao: 'Banese' },
  { bancoId: '0077', bancoDescricao: 'Banco Inter' },
  { bancoId: '0084', bancoDescricao: 'Uniprime' },
  { bancoId: '0085', bancoDescricao: 'Ailos' },
  { bancoId: '0098', bancoDescricao: 'Credialiança' },
  { bancoId: '0099', bancoDescricao: 'Uniprime' },
  { bancoId: '0104', bancoDescricao: 'Caixa Econômica Federal' },
  { bancoId: '0133', bancoDescricao: 'Cresol' },
  { bancoId: '0212', bancoDescricao: 'Banco Original' },
  { bancoId: '0237', bancoDescricao: 'Bradesco' },
  { bancoId: '0341', bancoDescricao: 'Itau' },
  { bancoId: '0422', bancoDescricao: 'Safra' },
  { bancoId: '0748', bancoDescricao: 'Sicredi' },
  { bancoId: '0756', bancoDescricao: 'Bancoob' },
];

export default {
  Seguradoras,
  Produtos,
  RespostaDefaultRadioOption,
  VidaPremiadoPlus,
  ApLiberty,
  Modulos,
  Dashboard,
  ResidencialPersonalizavelLiberty,
  Meses,
  OutrosBancos,
  ListIsPessoaPoliticamenteExpostaMulti,
  BrbMaster,
};
