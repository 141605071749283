import { Button, Col, Icon, Progress, Row } from 'antd';
import React from 'react';
import { Functions as util } from 'utils/index';
import { Container } from '~/components';

type CardProgressProps = {
  titulo?: String,
  valorTotal?: Number,
  valorRealizado?: Number,
  mostrarLegendaSuperior?: Boolean,
  descLegendaSuperiorInicio?: String,
  descLegendaSuperiorFim?: String,
  mostrarIconeProgresso?: Boolean,
  apresentarValoresEmReais?: Boolean,
  apresentarBotaoCard?: Boolean,
  configBotaoCard?: ConfigBotaoCard,
  style?: Object
};

type ConfigBotaoCard = {
  descricao: String,
  icon: String,
  onClickFunction: Function
}

function calculaPorcentagem(total, pedaco){
  let porcentagem = (pedaco / total) * 100;
  return porcentagem > 100 ? 100 : porcentagem;
}

export default function CardProgress({
  titulo,
  valorTotal,
  valorRealizado,
  mostrarLegendaSuperior,
  descLegendaSuperiorInicio,
  descLegendaSuperiorFim,
  mostrarIconeProgresso,
  apresentarValoresEmReais,
  apresentarBotaoCard,
  configBotaoCard,
  style
}: CardProgressProps) {
  return (
    <Container style={{ padding: 15, ...style }}>
      <Row>
        <strong style={{ color: '#538ac3' }}>{titulo}</strong>
      </Row>

      <Row justify="space-between">
        <Col span={!mostrarIconeProgresso || calculaPorcentagem(valorTotal || 0, valorRealizado || 0) === 100 ? 12 : 11}>
          <span style={{ color: mostrarLegendaSuperior ? '#1890ff' : '#1890ff00', fontSize: '.7rem' }} >
            <strong>{ descLegendaSuperiorInicio ? descLegendaSuperiorInicio : 'Realizados' }</strong>
          </span>
        </Col>
        <Col 
          span={ mostrarIconeProgresso ? (calculaPorcentagem(valorTotal || 0, valorRealizado || 0) !== 10 ? 10 : 11) : 12 } 
          style={{ textAlign: 'end', color: '#908e8e' }}
        >
          <span style={{ color: mostrarLegendaSuperior ? '#908e8e' : 'rgba(0, 0, 0, 0)', fontSize: '.7rem' }} >
            <strong>{ descLegendaSuperiorFim ? descLegendaSuperiorFim : 'Total' }</strong>
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={mostrarIconeProgresso ? (calculaPorcentagem(valorTotal || 0, valorRealizado || 0) === 100 ? 23 : 21) : 24}>
          <Progress percent={calculaPorcentagem(valorTotal || 0, valorRealizado || 0)} size="small" showInfo={false} />
        </Col>
        {
          mostrarIconeProgresso &&
          <Col span={ calculaPorcentagem(valorTotal || 0, valorRealizado || 0) === 100 ? 1 : 3 } style={{ paddingLeft: 10, textAlign: 'center' }}>
            {
              calculaPorcentagem(valorTotal || 0, valorRealizado || 0) === 100
                ? <Icon type='check-circle' style={{ color: '#52c41a' }} />
                :
                <span style={{fontSize: '.7rem'}}>
                  { util.formatarPorcentagem(calculaPorcentagem(valorTotal || 0, valorRealizado || 0)) }
                </span>
            }
          </Col>
        }
      </Row>
      <Row justify="space-between">
        <Col 
          xs={{ span: apresentarValoresEmReais ? 24 : (!mostrarIconeProgresso || calculaPorcentagem(valorTotal || 0, valorRealizado || 0) === 100 ? 12 : 11) }} 
          sm={{ span: !mostrarIconeProgresso || calculaPorcentagem(valorTotal || 0, valorRealizado || 0) === 100 ? 12 : 11 }}
        >
          <span style={{ color: '#1890ff', fontSize: '1.5rem' }} >
            <strong>{apresentarValoresEmReais ? util.formatarMoeda(valorRealizado || 0) : valorRealizado || 0}</strong>
          </span>
        </Col>
        <Col 
          span={mostrarIconeProgresso ? (calculaPorcentagem(valorTotal || 0, valorRealizado || 0) !== 100 ? 10 : 11) : 12} 
          style={{ textAlign: 'end', color: '#908e8e' }}
        >
          <span>
            { apresentarValoresEmReais ? util.formatarMoeda(valorTotal || 0) : valorTotal || 0 }
          </span>
        </Col>
      </Row>
      <div style={{textAlign: 'right'}}>
        { apresentarBotaoCard && (
          <Button 
            style={{ background: 'inherit', border: 'none', boxShadow: 'none' }}
            title={configBotaoCard.descricao} 
            type="default" shape="round" size="small" onClick={configBotaoCard.onClickFunction}
          >
            {
              configBotaoCard.icon &&
             <Icon type={ configBotaoCard.icon } />  
            }
            { configBotaoCard.descricao }
          </Button>
        )}
      </div>
    </Container>
  );
}
