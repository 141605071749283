import { Col, message, Row, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container, Disable, Input, Select,
  TitleSection
} from '~/components';
import * as LibertyResidencialPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';
import { Constants, Functions } from '~/utils';
import useEffectAfterMounted from '~/utils/useEffectAfterMounted';
import constants from '~/utils/constants';
import CardPagamento from '../card-pagamento/index.js';
import ModalInfoAssistencias from './../modal-info-tipo-assistencias/index';

type CotacaoSegundoPassoResidencialPersonalizavelLibertyProps = {
  onReset: Function,
  onPrevius: Function,
  onNext: Function
};

export default function CotacaoSegundoPassoResidencialPersonalizavelLiberty({
  onReset,
  onPrevius,
  onNext
}: CotacaoSegundoPassoResidencialPersonalizavelLibertyProps) {
  const dispatch = useDispatch();

  //#region listaLimitadoresValorCobertura
  const listaLimites = [
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.DanosEletricos, 
      valorPorcentagemLimite: 0.5, 
      valorMonetarioLimite: 100000, 
      valorMonetarioLimiteVeraneio: 50000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 50% da cobertura básica com valor máximo de R$ ${(ehVeraneio ? '50.000,00' : '100.000,00')}. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.DespesasComMudanca, 
      valorPorcentagemLimite: 0.1, 
      valorMonetarioLimite: 50000, 
      valorMonetarioLimiteVeraneio: 50000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 10% da cobertura básica com valor máximo de R$ 50.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.RecomposicaoDeRegistrosEDocumentos, 
      valorPorcentagemLimite: 0.1, 
      valorMonetarioLimite: 50000, 
      valorMonetarioLimiteVeraneio: 50000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 10% da cobertura básica com valor máximo de R$ 50.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.EquipamentosEletronicos, 
      valorPorcentagemLimite: 0.2, 
      valorMonetarioLimite: 50000, 
      valorMonetarioLimiteVeraneio: 50000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 20% da cobertura básica com valor máximo de R$ 50.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.ImpactoDeVeiculosTerrestres, 
      valorPorcentagemLimite: 1, 
      valorMonetarioLimite: 500000, 
      valorMonetarioLimiteVeraneio: 500000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 100% da cobertura básica com valor máximo de R$ 500.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.PerdaOuPagamentoDeAluguel, 
      valorPorcentagemLimite: 0.2, 
      valorMonetarioLimite: 100000, 
      valorMonetarioLimiteVeraneio: 100000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 20% da cobertura básica com valor máximo de R$ 100.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.QuebraDeVidrosEspelhosMarmoresGranitos, 
      valorPorcentagemLimite: 0.2, 
      valorMonetarioLimite: 100000, 
      valorMonetarioLimiteVeraneio: 100000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 20% da cobertura básica com valor máximo de R$ 100.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.ResponsabilidadeCivilFamiliar, 
      valorPorcentagemLimite: 1, 
      valorMonetarioLimite: 500000, 
      valorMonetarioLimiteVeraneio: 500000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 100% da cobertura básica com valor máximo de R$ 500.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.DanosPorRupturaDeTubulacao, 
      valorPorcentagemLimite: 0.2, 
      valorMonetarioLimite: 50000, 
      valorMonetarioLimiteVeraneio: 50000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 20% da cobertura básica com valor máximo de R$ 50.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.RouboEouSubtracaoDeBensComVestigios, 
      valorPorcentagemLimite: 0.2, 
      valorMonetarioLimite: 70000, 
      valorMonetarioLimiteVeraneio: 70000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 20% da cobertura básica com valor máximo de R$ 70.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.VendavalFuracaoCicloneTornadoGranizo, 
      valorPorcentagemLimite: 0.5, 
      valorMonetarioLimite: 200000, 
      valorMonetarioLimiteVeraneio: 100000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 50% da cobertura básica com valor máximo de R$ ${(ehVeraneio ? '100.000,00' : '200.000,00')}. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.PagamentoDeCondominioPorPerdaDeRenda, 
      valorPorcentagemLimite: 0.05, 
      valorMonetarioLimite: 10000, 
      valorMonetarioLimiteVeraneio: 10000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 5% da cobertura básica com valor máximo de R$ 10.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.RouboDeBensNoDepositoOuBicicletario, 
      valorPorcentagemLimite: 0.1, 
      valorMonetarioLimite: 5000, 
      valorMonetarioLimiteVeraneio: 5000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.RouboEouSubtracaoDeBensComVestigios,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 10% da cobertura de roubo com valor máximo de R$ 5.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.PerdaDeLucroEDespesasFixasPorIncendio, 
      valorPorcentagemLimite: 0.1, 
      valorMonetarioLimite: 30000, 
      valorMonetarioLimiteVeraneio: 30000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 10% da cobertura básica com valor máximo de R$ 30.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.RouboEouSubtracaoDeBensComVestigiosVeraneio, 
      valorPorcentagemLimite: 0.2, 
      valorMonetarioLimite: 10000, 
      valorMonetarioLimiteVeraneio: 10000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.Principal,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 20% da cobertura básica com valor máximo de R$ 10.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
    { 
      codigoCobertura: constants.ResidencialPersonalizavelLiberty.Cobertura.DanosCausadosAosBensDoCondominio, 
      valorPorcentagemLimite: 0.2, 
      valorMonetarioLimite: 10000, 
      valorMonetarioLimiteVeraneio: 10000, 
      codigoCoberturaLimitante: constants.ResidencialPersonalizavelLiberty.Cobertura.ResponsabilidadeCivilFamiliar,
      descricaoLimitadorCobertura: (ehVeraneio, valorLimite) => `Cobertura limitada a 20% da cobertura RCF com valor máximo de R$ 10.000,00. 
        Valor máximo atual de R$ ${Functions.formatarDecimal(valorLimite)}`
    },
  ];
  //#region end

  // Redux state
  const {
    contratoId,
    propostaId
  } = useSelector(state => state.proposta);

  const { libertyResidencialPersonalizavel, libertyResidencialPersonalizavel: {
    cotacao, listaNomesDominios, efetivacao
  } } = useSelector(state => state);

  const { usuario } = useSelector(({ auth }) => auth);

  const [coberturaPrincipal, setCoberturaPrincipal] = useState({
    codigoCobertura: '11429', codigoVerba: null, valorCobertura: null
  });

  // component state
  const [qtdParcelas, setQtdParcelas] = useState();
  const [listaFormasPagamento, setListaFormasPagamento] = useState([]);

  useEffect(() => {
    preencheCoberturaPrincipal();
    removerCoberturasNaoHabilitadasParaHabitacaoOuOcupacao();
    initFormaPagamento();
  }, []);

  useEffectAfterMounted(() => {
    changeQtdParcelas(qtdParcelas);
  }, [libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento]);

  const listaMatriculasBoletoBrb = ['00346', '81753', '87445', '91058']

  const listaCodigoPlanoPagamentoPermitidos = listaMatriculasBoletoBrb.some(x => x === usuario?.matricula) 
    ? ['0050','0100','0002', '0071', '0041', '0072', '0171', '0172'] 
    : ['0050','0002', '0071', '0041', '0072', '0171', '0172'];

  function initFormaPagamento() {
    if(!!efetivacao.escolhaFormaPagamentoId) {
      tratarPagamentoSelecionado();
    } else {
      setQtdParcelas(null);
    }
  }

  function preencheCoberturaPrincipal() {
    var listaCoberturas = (cotacao.coberturas ?? []);
    var coberturaNaLista = listaCoberturas.find(x => x.codigoCobertura === constants.ResidencialPersonalizavelLiberty.Cobertura.Principal);

    if(!(!!coberturaNaLista)) return;

    var obj = {
      ...coberturaNaLista,
      valorCobertura: !!coberturaNaLista.valorCobertura ? `R$ ${Functions.formatarDecimal(coberturaNaLista.valorCobertura)}` : null
    }

    setCoberturaPrincipal(obj);
    atualizaValoresLimitesCoberturas();
  }

  function changeFieldCotacao(field, value) {
    dispatch(LibertyResidencialPersonalizavelActions.changeCotacaoState({ field, value }));
  }

  function changeCoberturaPrincipal(field, value, isDinheiro = true){
    var valor = isDinheiro ? Functions.getMoney(value) : value;
    valor = !!valor ? valor : null;

    value = (value ?? '').replace("R$", "").trim();

    var obj = {
      ...coberturaPrincipal,
      [field]: isDinheiro && !!(value.trim()) ? `R$ ${value}` : value
    }

    setCoberturaPrincipal(obj);

    if(!isDinheiro) {
      var valorCobertura = Functions.getMoney(coberturaPrincipal.valorCobertura);
      valorCobertura = !!valorCobertura ? valorCobertura : null;

      obj = {
        ...obj,
        valorCobertura: valorCobertura
      }

      changeCoberturaCotacao({...obj, [field]: valor});
      return;
    }

    changeCoberturaCotacao({...obj, [field]: !isNaN(valor) ? valor : null});
  }

  function retornaListaCoberturasSemPrincipal() {
    var lista = retornaListaDominio('Cobertura')
    lista = lista.filter(x => x.dominioParceiroCodigo !== Constants.ResidencialPersonalizavelLiberty.Cobertura.Principal);

    // Quando não for Home Office remover a cobertura PERDA DE LUCRO E DESPESAS FIXAS POR INCENDIO
    if(cotacao.localRisco?.tipoOcupacaoId !== Constants.ResidencialPersonalizavelLiberty.TipoOcupacaoImovel.HomeOffice)
      lista = lista.filter(x => x.dominioParceiroCodigo !== Constants.ResidencialPersonalizavelLiberty.Cobertura.PerdaDeLucroEDespesasFixasPorIncendio);

    // Quando não for veraneio remover a cobertura ROUBO E/OU SUBTRACAO DE BENS COM VESTIGIOS - VERANEIO
    if(cotacao.localRisco?.tipoOcupacaoId !== Constants.ResidencialPersonalizavelLiberty.TipoOcupacaoImovel.Veraneio)
      lista = lista.filter(x => x.dominioParceiroCodigo !== Constants.ResidencialPersonalizavelLiberty.Cobertura.RouboEouSubtracaoDeBensComVestigiosVeraneio);
    else
      lista = lista.filter(x => x.dominioParceiroCodigo !== Constants.ResidencialPersonalizavelLiberty.Cobertura.RouboEouSubtracaoDeBensComVestigios);

    // Remove as coberturas que devem ser apresentadas apenas para apartamento
    if(cotacao.localRisco?.tipoHabitacaoId !== Constants.ResidencialPersonalizavelLiberty.TipoHabitacao.Apartamento) {
      var listaItensRemover = [
        Constants.ResidencialPersonalizavelLiberty.Cobertura.DanosCausadosAosBensDoCondominio, 
        Constants.ResidencialPersonalizavelLiberty.Cobertura.PagamentoDeCondominioPorPerdaDeRenda,
        Constants.ResidencialPersonalizavelLiberty.Cobertura.RouboDeBensNoDepositoOuBicicletario
      ];

      lista = lista.filter(x => !listaItensRemover.some(y => y === x.dominioParceiroCodigo));
    }
      

    return lista;
  }

  function removerCoberturasNaoHabilitadasParaHabitacaoOuOcupacao() {

    if(!cotacao.coberturas || !cotacao.coberturas.length) return;

    if(cotacao.localRisco?.tipoOcupacaoId !== Constants.ResidencialPersonalizavelLiberty.TipoOcupacaoImovel.HomeOffice) 
      removerCoberturaDaState(constants.ResidencialPersonalizavelLiberty.Cobertura.PerdaDeLucroEDespesasFixasPorIncendio)

    if(cotacao.localRisco?.tipoOcupacaoId !== Constants.ResidencialPersonalizavelLiberty.TipoOcupacaoImovel.Veraneio) 
      removerCoberturaDaState(constants.ResidencialPersonalizavelLiberty.Cobertura.RouboEouSubtracaoDeBensComVestigiosVeraneio)
    else
      removerCoberturaDaState(constants.ResidencialPersonalizavelLiberty.Cobertura.RouboEouSubtracaoDeBensComVestigios)

    if(cotacao.localRisco?.tipoHabitacaoId !== Constants.ResidencialPersonalizavelLiberty.TipoHabitacao.Apartamento) {
      var listaItensRemover = [
        Constants.ResidencialPersonalizavelLiberty.Cobertura.DanosCausadosAosBensDoCondominio, 
        Constants.ResidencialPersonalizavelLiberty.Cobertura.PagamentoDeCondominioPorPerdaDeRenda,
        Constants.ResidencialPersonalizavelLiberty.Cobertura.RouboDeBensNoDepositoOuBicicletario
      ];

      var listaCoberturas = [...cotacao.coberturas];

      listaItensRemover.forEach(x => {
        listaCoberturas = removerCoberturaDaState(x)
      });
    }
  }

  function tratarPagamentoSelecionado(){
    var listaFormasPagamento = libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento ?? [];
    var pagamentoEscolhido = listaFormasPagamento.find(x => x.formasPagamento.some(y => y.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId)) ?? { formasPagamento: []}
    var qtdParcelas = (pagamentoEscolhido.formasPagamento.find(x => x.formaPagamentoEscolhaId === efetivacao.escolhaFormaPagamentoId))?.quantidadeParcelas;
    setQtdParcelas(qtdParcelas);
    changeQtdParcelas(qtdParcelas, true);
  }

  function removerCoberturaDaState(codigoCobertura, listaCoberturas) {
    
    listaCoberturas = listaCoberturas ?? (cotacao.coberturas ?? [])

    var cobertura = (listaCoberturas ?? []).find(x => x.codigoCobertura === codigoCobertura);
    if(!cobertura) return [...(listaCoberturas ?? [])];
    var index = listaCoberturas.indexOf(cobertura);
    listaCoberturas.splice(index, 1);
    var obj = {
      ...cotacao,
      coberturas: [...listaCoberturas]
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'cotacao', value: obj }));
    return [...listaCoberturas];
  }

  function onBlurCobertura(codigoCobertura, valor) {
    changeValorCobertura(codigoCobertura, valor);

    var listaCoberturas = (cotacao.coberturas ?? []);

    listaCoberturas.forEach(x => {
      if(x.codigoCobertura === constants.ResidencialPersonalizavelLiberty.Cobertura.Principal) return;
      
      var coberturaLimite = listaLimites.find(y => y.codigoCoberturaLimitante === codigoCobertura && y.codigoCobertura === x.codigoCobertura);
      if(!coberturaLimite) return;

      changeValorCobertura(x.codigoCobertura, !!x.valorCobertura ? `${x.valorCobertura}` : null);
    });
  }

  function onBlurCoberturaPrincipal(value) {
    changeCoberturaPrincipal('valorCobertura', value);
    atualizaValoresLimitesCoberturas();
  }

  function atualizaValoresLimitesCoberturas() {
    var listaCoberturas = (cotacao.coberturas ?? []);

    listaCoberturas.forEach(x => {
      if(x.codigoCobertura === constants.ResidencialPersonalizavelLiberty.Cobertura.Principal) return;
      changeValorCobertura(x.codigoCobertura, !!x.valorCobertura ? `${x.valorCobertura}` : null);
    });
  }

  function RetornaDescricaoValorMaximo(codigoCobertura) {
    var cobertura = listaLimites.find(x => x.codigoCobertura === codigoCobertura);
    if(!cobertura) return '';

    var ehVeraneio = cotacao.localRisco.tipoOcupacaoId === constants.ResidencialPersonalizavelLiberty.TipoOcupacaoImovel.Veraneio;
    var valorLimite = retornaValorLimiteCobertura(codigoCobertura, cobertura);
    
    return cobertura.descricaoLimitadorCobertura(ehVeraneio, valorLimite);
  }

  function retornaValorCobertura(codigoCobertura) {
    var valorCobertura = retornaValorCoberturaNumeral(codigoCobertura);
    return !!valorCobertura ? `R$ ${Functions.formatarDecimal(valorCobertura)}` : null 
  }

  function retornaValorCoberturaNumeral(codigoCobertura) {
    var listaCoberturas = (cotacao.coberturas ?? []);
    var coberturaNaLista = (listaCoberturas.find(x => x.codigoCobertura === codigoCobertura) ?? {});
    return coberturaNaLista.valorCobertura ?? 0;
  }

  function retornaValorLimiteCobertura(codigoCobertura, cobertura = null) {
    cobertura = cobertura ?? listaLimites.find(x => x.codigoCobertura === codigoCobertura);
    if(!cobertura) return 0;

    var valorCoberturaLimitante = retornaValorCoberturaNumeral(cobertura.codigoCoberturaLimitante);
    var valorLimite  = valorCoberturaLimitante * cobertura.valorPorcentagemLimite;
    var coberturaValorLimiteMonetario = cotacao.localRisco.tipoOcupacaoId === constants.ResidencialPersonalizavelLiberty.TipoOcupacaoImovel.Veraneio
      ? cobertura.valorMonetarioLimiteVeraneio : cobertura.valorMonetarioLimite;
    return valorLimite > coberturaValorLimiteMonetario ? coberturaValorLimiteMonetario : valorLimite
  }

  function trataValorLimiteCobertura(codigoCobertura, valor) {
    if(codigoCobertura === constants.ResidencialPersonalizavelLiberty.Cobertura.Principal) return valor;
    if(isNaN(parseFloat(valor ??  ''))) return null;

    var valorLimiteCobertura = retornaValorLimiteCobertura(codigoCobertura);
    if(valorLimiteCobertura >= valor) return valor;

    message.warn(`Valor máximo para a cobertura é de R$ ${Functions.formatarDecimal(valorLimiteCobertura)}`);

    return valorLimiteCobertura;
  }

  function changeValorCobertura(codigoCobertura, valor) { 
    valor = (valor ?? '').replace("R$", "").trim();
    valor = Functions.getMoney(valor);

    valor = trataValorLimiteCobertura(codigoCobertura, valor);

    var cobertura = {
      codigoCobertura,
      valorCobertura: valor,
      codigoVerba: 'R04'
    }

    changeCoberturaCotacao(cobertura);
  }

  function changeTipoAssistencia(value) {
    var obj = {
      ...cotacao,
      localRisco: {
        ...cotacao.localRisco,
        tipoAssistenciaId: value
      }
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'cotacao', value: obj }));
  }

  function changeCoberturaCotacao(cobertura) {
    var listaCoberturas = (cotacao.coberturas ?? []);
    var coberturaNaLista = (listaCoberturas.find(x => x.codigoCobertura === cobertura.codigoCobertura) ?? {});
    
    var index = listaCoberturas.indexOf(coberturaNaLista);

    if(index !== -1) {
      coberturaNaLista.codigoVerba = cobertura.codigoVerba;
      coberturaNaLista.valorCobertura = cobertura.valorCobertura;
      listaCoberturas.splice(index, 1, coberturaNaLista);
    } else {
      listaCoberturas.push(cobertura);
    }

    var objCotacao = {
      ...cotacao,
      coberturas: [...listaCoberturas] 
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'cotacao', value: objCotacao} ));
      
  }

  function changeValorAgravo(valor) {
    
    valor = Functions.getMoney(valor) ? Functions.getMoney(valor) : null;
    var objCotacao = {
      ...cotacao,
      porcentagemAgravo: valor
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'cotacao', value: objCotacao} ));
  }

  function retornaValorCampoAgravo() {
    var valorAgravo = cotacao.porcentagemAgravo;
    return !!valorAgravo ? `${Functions.formatarDecimal(valorAgravo)}` : null 
  }

  function retornaValorDecimal(valor){
    valor = (valor ?? '').replace(',', '.');
    return valor;
  }

  function retornaListaDominio(nomeLista) {
    var retorno = [];

    var retorno = ((listaNomesDominios ?? [])
      .find(x => x.dominioParceiroNomeDescricao.includes(nomeLista)) ?? { dominioParceiros: [] }).dominioParceiros;

    return retorno;
  }

  function retornaListaTipoAssistencia() {
    return retornaListaDominio('Tipo Assistência')
  }

  function retornaListaTipoVerba() {
    var lista = retornaListaDominio('Verba Cobertura')
    return lista.filter(x => x.dominioParceiroCodigo !== 'R04')
  }

  function retornaQuantidadeParcelas() {

    var listaRetorno = []
    var listaParcelas = libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento ?? [];
    
    // vai funcionar apenas com débito em conta e boleto
    listaParcelas = listaParcelas.filter(x => retornaApresentarTipoPagamento(x.formaPagamentoTipoCodigo, x.formaPagamentoPlanoCodigo));

    if(!listaParcelas.length) return listaRetorno;

    listaParcelas.forEach(x => {
      x.formasPagamento.forEach( y => {
        if(!listaRetorno.some(z => z === y.quantidadeParcelas))
          listaRetorno.push(y.quantidadeParcelas);
      })
    })
    

    return listaRetorno.map(x => {
      return {
        value: x
      }
    });
  }

  function retornaApresentarTipoPagamento(formaPagamentoTipoCodigo, formaPagamentoPlanoCodigo) {
    // planoCodigo 0371 é primeira em pix, nossa plataforma não trabalha com essa opção
    return (['DC', 'FB']).some(y => y === formaPagamentoTipoCodigo 
      && listaCodigoPlanoPagamentoPermitidos.some(y => y === formaPagamentoPlanoCodigo))
  }

  function changeQtdParcelas(value, ignorarAlteracaoPagamentoSelecionado) {
    setQtdParcelas(value);

    var listaOpcoesPagamento = []
    var listaFormasPagamento = libertyResidencialPersonalizavel?.propostaDetalhes?.formasPagamento ?? [];

    listaFormasPagamento = listaFormasPagamento.filter(x => retornaApresentarTipoPagamento(x.formaPagamentoTipoCodigo, x.formaPagamentoPlanoCodigo));
    if(!listaFormasPagamento.length) return;
    
    listaFormasPagamento.forEach(x => {
      
      (x.formasPagamento ?? []).forEach(y => {
        // pagamento débito conta com a primeira em boleto
        y.ehPrimeiraBoleto = x.formaPagamentoTipoCodigo === 'DC' && x.formaPagamentoPlanoCodigo === '0171';
        y.formaPagamentoTipoCodigo = x.formaPagamentoTipoCodigo;
        y.formaPagamentoPlanoCodigo = x.formaPagamentoPlanoCodigo;
      })

      listaOpcoesPagamento = listaOpcoesPagamento.concat(x.formasPagamento ?? [])
    })

    listaOpcoesPagamento = listaOpcoesPagamento.filter(x => x.quantidadeParcelas === value)

    if(!ignorarAlteracaoPagamentoSelecionado) atualizarParcelaSelecionadaEfetivacao(0);

    setListaFormasPagamento(listaOpcoesPagamento);
  }

  function atualizarParcelaSelecionadaEfetivacao(escolhido){
    var obj = {
      ...efetivacao,
      escolhaFormaPagamentoId: escolhido
    }

    dispatch(LibertyResidencialPersonalizavelActions.changeFieldState({ field: 'efetivacao', value: obj}));
  }

  function onChangePagamento(pagamento) {
    if(!pagamento) return;

    var formaSelecionada = pagamento?.formaPagamentoEscolhaId === efetivacao?.escolhaFormaPagamentoId
      ? null 
      : pagamento.formaPagamentoEscolhaId;

    atualizarParcelaSelecionadaEfetivacao(formaSelecionada);
  }


  return (
    <>
      <Spin spinning={!!(libertyResidencialPersonalizavel.loading ?? []).length} size="small">

        <Container>

          <Disable disable={!!contratoId}>

            <TitleSection title="Tipo de Assistência" />
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Tipo de assistência</label>
                  <ModalInfoAssistencias></ModalInfoAssistencias>
                  <Select
                    defaultValue="Selecione"
                    itens={retornaListaTipoAssistencia()}
                    itemValue="dominioParceiroCodigo"
                    itemText="dominioParceiroDescricao"
                    value={cotacao.localRisco.tipoAssistenciaId}
                    onChange={value =>
                      changeTipoAssistencia(value)
                    }
                    showSearch
                  />
                </div>
              </Col>
            </Row>

            <TitleSection title="Cobertura Principal" />

            <Row gutter={[16, 16]}>
              <Col style={{'marginTop': '30px'}} xs={{ span: 24 }} sm={{ span: 12 }}>
                <div className="form-group">
                  <label>INCÊNDIO, QUEDA DE RAIO, EXPLOSÃO E QUEDA DE AERONAVES</label>
                </div>
              </Col>
              <Col style={{'marginTop': '30px'}} xs={{ span: 24 }} sm={{ span: 6 }}>
                <Input
                  placeholder="Valor da cobertura"
                  value={coberturaPrincipal.valorCobertura}
                  mask="money"
                  onChange={value => changeCoberturaPrincipal('valorCobertura', value)}
                  onBlur={value => onBlurCoberturaPrincipal(value)}
                />
              </Col>
              
              
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Tipo seguro</label>
                  <Select
                    defaultValue="Selecione"
                    itens={retornaListaTipoVerba()}
                    itemValue="dominioParceiroCodigo"
                    itemText="dominioParceiroDescricao"
                    value={coberturaPrincipal.codigoVerba}
                    onChange={value =>
                      changeCoberturaPrincipal('codigoVerba', value, false)
                    }
                    showSearch
                  />
                </div>
              </Col>
            </Row>

            <TitleSection title="Demais Coberturas" />

            {
              retornaListaCoberturasSemPrincipal().map(x => {
                return (
                  <Row gutter={[16, 16]} key={x.dominioParceiroCodigo}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <div className="form-group">
                        <label>{x.dominioParceiroDescricao}</label>
                        <Tooltip placement="right" title={ RetornaDescricaoValorMaximo(x.dominioParceiroCodigo) }>
                          <i style={ {"marginLeft": "6px"} } className='fad fa-info-circle'></i>
                        </Tooltip>
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                      <Input
                        placeholder="Valor da cobertura"
                        value={retornaValorCobertura(x.dominioParceiroCodigo)}
                        mask="money"
                        onChange={value => changeValorCobertura(x.dominioParceiroCodigo, value)}
                        onBlur={value => onBlurCobertura(x.dominioParceiroCodigo, value)}
                      />
                    </Col>
                  </Row>
                )

              })
            }

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className='form-group'>
                  <label>Porcentagem agravo (%)</label>
                  <Input
                    placeholder="Em porcentagem"
                    value={retornaValorCampoAgravo()}
                    onChange={value =>
                      changeValorAgravo(value)
                    }
                    mask="decimal"
                    maxLength={6}
                    suffix="%"
                  />
                </div>
              </Col>
            </Row>

            {
              !!propostaId &&
              <>
                <TitleSection title="Pagamento" />
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                    <div className="form-group">
                      <label className="w-100">Quantidade Parcelas</label>
                      <Select
                        defaultValue="Selecione"
                        itens={retornaQuantidadeParcelas()}
                        itemValue="value"
                        itemText="value"
                        value={qtdParcelas}
                        onChange={value => changeQtdParcelas(value)}
                        showSearch
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Container>
                    {qtdParcelas && qtdParcelas > 0 && (
                        <div className='box-plain'>
                            {listaFormasPagamento.map(p =>
                                <CardPagamento
                                    key={`${p.formaPagamentoEscolhaId}`}
                                    pagamento={p}
                                    onChangePagamento={onChangePagamento}
                                    selecionado={((p.formaPagamentoEscolhaId === efetivacao?.escolhaFormaPagamentoId))} />)}
                        </div>
                    )}
                  </Container>
                </Row>
              </>
            }
          </Disable>
        </Container>
      </Spin>
    </>
  );
}
