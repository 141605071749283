import { createActions, handleActions } from "redux-actions";

export const types = {
    GET_ACUMULO_RISCO_BY_CPF: 'GET_ACUMULO_RISCO_BY_CPF',
    SAVE_ACUMULO_RISCO: 'SAVE_ACUMULO_RISCO',
    FALHA: 'FALHA' 
}

const defaultState = {
    loading: false,
    retorno: [],
    clienteNome: '',
    acumuloRiscoValor: 0
}

export const {
    getAcumuloRiscoByCpf,
    saveAcumuloRisco,
    falha
} = createActions(
    types.GET_ACUMULO_RISCO_BY_CPF,
    types.SAVE_ACUMULO_RISCO,
    types.FALHA
    );

export default handleActions(
  {
    [getAcumuloRiscoByCpf]: (state) => {
        state.loading = state.loading  || [];
        state.loading.push('');
        
        return ({
          ...state
        })
      },

      [falha]: state => ({ ...state, loading: [], acumuloRiscoValor: 0, retorno: [], clienteNome: ''}),

      [saveAcumuloRisco]: (state, action) => ({
        ...state,
        loading: false,
        retorno: action.payload,
         clienteNome: action.payload.length > 0 ? ' Cliente: ' + action.payload[0].clienteNome : '',
        acumuloRiscoValor: action.payload.reduce((accumulator, objeto) => {
            return accumulator + objeto.mtrContratoPrestamistaValor
        }, 0)
        })
      },
      defaultState
)
