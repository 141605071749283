import moment from 'moment';
import api from '.';

export const getPlanosDreMitsui = (seguradoraId, produtoId, tipoImovel) =>
  api().get(
    `api/proposta-eletronica-plano/${seguradoraId}/${produtoId}/${tipoImovel}`
  );

export const getPlanosVpp = (idade, tipoPlano, tipoPagamento) =>
  api().get(
    `api/proposta-eletronica-plano/vida/${idade}/${tipoPlano}/${tipoPagamento}`
  );

export const getPlanosBrbMaster = (
  nome,
  cpf,
  dataNascimento,
  profissaoCbo,
  renda,
  sexoId,
  uf,
  periodicidade
) =>
  api().get(
    `api/proposta-eletronica-plano/brb-master/${nome}/${cpf}/${moment(
      dataNascimento
    ).format(
      'YYYY-MM-DD'
    )}/${profissaoCbo}/${renda}/${sexoId}/${uf}/${periodicidade}`
  );
