import React from 'react';
import { Functions } from '~/utils';
import './style.css';
import residencialLibertyUtil from '../util'


type CardPagamentoProp = {
    pagamento: {},
    onChangePagamento: Function,
    selecionado: Boolean,
}


function getDescricaoFormaPagamento(pagamento) {

    return residencialLibertyUtil
      .retornaDescricaoFormaPagamento(pagamento?.formaPagamentoTipoCodigo, pagamento?.formaPagamentoPlanoCodigo)
  }

export default function CardPagamento({ pagamento, onChangePagamento, selecionado }: CardPagamentoProp) {
    
    function onClick() {
        if (onChangePagamento)
            onChangePagamento(pagamento)
    }

    return (
        <div className='wraper-plain' onClick={onClick}>
            <input type='radio' id={pagamento.formaPagamentoEscolhaId} name='plains' value={pagamento.formaPagamentoEscolhaId} checked={selecionado} ></input>
            <div className='card-plain' style={{height: 350}}>
                <div className='header-plain'>
                    <h3>1 + {(pagamento.quantidadeParcelas ?? 1) - 1}</h3>
                </div>
                <div className='body-plain'>
                    {
                        !!(pagamento?.ehPrimeiraBoleto) &&
                        <>
                            <span style={{ marginBottom: 8, fontWeight: 'normal' }} className="badge badge-pill badge-info">Primeira parcela em boleto</span>
                        </> 
                    }
                    <ul className="list-desc">
                        <li>Tipo pagamento:<span>{ getDescricaoFormaPagamento(pagamento) }</span></li>
                        <li>Valor primeira parcela:<span>{Functions.formatarMoeda(pagamento?.valorPrimeiraParcela)}</span></li>
                        <li>Valor demais parcelas:<span>{Functions.formatarMoeda(pagamento?.valorDemaisParcelas)}</span></li>
                        {
                            !!(pagamento?.valorJurosAplicado || 0) && 
                            <li>Valor juros:<span>{Functions.formatarMoeda(pagamento?.valorJurosAplicado)}</span></li>
                        }
                        <li>Valor IOF:<span>{Functions.formatarMoeda(pagamento?.valorIof)}</span></li>
                        <li>Prêmio Total:<span className='coust'>{Functions.formatarMoeda(pagamento?.valorTotalDoPremio)}</span></li>
                    </ul>
                </div>
                <div>
                </div>
                <div className='footer-plain'>
                    <i className="fas fa-check" htmlFor={pagamento?.formaPagamentoEscolhaId}></i>
                </div>
            </div>
        </div>

    )
}