import * as cryptoJS from 'crypto-js';

let criptografar = function(valor){
  let key = cryptoJS.enc.Utf8.parse('$1$vVcZfyqW$gO3V');
  let iv = cryptoJS.enc.Utf8.parse('$1$vVcZfyqW$gO3V');

  var valorCriptografado = cryptoJS.AES.encrypt(cryptoJS.enc.Utf8.parse(valor), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7
  });

  return cryptoJS.enc.Base64.stringify(valorCriptografado.ciphertext)
}

export default criptografar