import React from 'react';
import { Row, Col } from 'antd';

import { TitleSection } from '~/components/';

import Telefones from './telefones';
import EmailsSites from './emails-sites';

import { Container } from './style';

type ProponenteProps = {
  selectTelefoneConfig: SelectTelefoneConfigProps
};

type SelectTelefoneConfigProps = {
  showOptionToSelectTelefones: Boolean,
  isMultiSelectTelefones: Boolean,
  textToolTipSelectTelefone: string,
  textHeaderSelectTelefone: string,
  onSelectTelefone: Function,
  selectedTelefonesList: Array<String>
}

export default function Contato({
  selectTelefoneConfig
}: ProponenteProps) {
  return (
    <>
      <TitleSection title="Contato" />
      <Container>
        <Row gutter={[16, 30]}>
          <Col lg={24} xl={12}>
            <Telefones 
              isMultiSelectTelefones={selectTelefoneConfig?.isMultiSelectTelefones} 
              showOptionToSelectTelefones={selectTelefoneConfig?.showOptionToSelectTelefones}
              textToolTipSelectTelefone={selectTelefoneConfig?.textToolTipSelectTelefone}
              textHeaderSelectTelefone={selectTelefoneConfig?.textHeaderSelectTelefone}
              onSelectTelefone={selectTelefoneConfig?.onSelectTelefone}
              selectedTelefonesList={selectTelefoneConfig?.selectedTelefonesList}
            />
          </Col>
          <Col lg={24} xl={12}>
            <EmailsSites />
          </Col>
        </Row>
      </Container>
    </>
  );
}
