import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Form, Radio, Row, Alert } from 'antd';
import Button from 'antd-button-color';
import moment from 'moment';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container, Disable, Error, FieldsContato, FieldsEndereco, FieldsProducao, FieldsProponente, Input, TitleSection
} from '~/components';
import useIsAgenciaDigital from '~/hooks/useIsAgenciaDigital';
import PerguntasResumoRisco from '~/pages/Proposta/acidentes-pessoais-liberty/resumo-risco/index';
import * as ClienteActions from '~/store/ducks/cliente';
import * as PropostaActions from '~/store/ducks/proposta';
import { Constants, Functions, Validation } from '~/utils';
import constants from '~/utils/constants';
import { ClienteValidation, ProducaoValidation, PropostaValidation } from '~/yup';




type DadosClienteProp = {
  onNext: Function,
  produtoId: Number,
};

export default function DadosCliente({ onNext, produtoId }: DadosClienteProp) {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('F');
  const [validateStatus, setValidateStatus] = useState('');
  const [erros, setErros] = useState([]);
  const { loading, cliente, endereco } = useSelector(state => state.cliente);
  const { 
    proposta,
    libertyResidencialPersonalizavel,
    proposta: {
      brbMaster: {
        telefonesEnvioToken
      },
      capIcatu
    }
  } = useSelector(state => state);

  const [isAgenciaDigital] = useIsAgenciaDigital();

  const dispatch = useDispatch();

  function onChangeCpf(value) {
    setErros([]);
    setCpfCnpj(value);
    setValidateStatus('');
    if (value && value.length >= 14) {
      if (!Validation.isCpfOrCnpjValid(value, tipoPessoa)) {
        setValidateStatus('error');
      } else {
        dispatch(ClienteActions.getByCpf({ param: value }));
      }
    }
  }

  useEffect(() => {
    if(cliente?.clienteCpfCnpj && (cliente?.clienteCpfCnpj ?? '').length > 11)
      setTipoPessoa('J');
  }, []);

  useEffect(() => {
    if (loading) {
      setValidateStatus('validating');
    } else {
      setValidateStatus('');
    }
  }, [loading]);

  function onChangeEnderecoCliente(end) {
    dispatch(ClienteActions.saveEndereco(end));
  }

  async function _onNext() {
    setErros([]);
    // Valid data
    const response = await validCliente();
    // Check is valid
    if (response.ok) onNext();
    else setErros(response);
  }

  async function validCliente() {

    var retorno = [];

    const clienteValidation =
      produtoId === Constants.Produtos.VidaPremiadoPlus
        ? ClienteValidation.ClienteAll
        : Functions.isCap(produtoId)
        ? ClienteValidation.ClienteCap
        : produtoId === Constants.Produtos.LibertyResidencialPersonalizavel 
        ? ClienteValidation.ClienteResidencialPersonalizavelLiberty 
        : produtoId === Constants.Produtos.BrbMasterPremiado
        ? ClienteValidation.ClienteBrbMaster
        : ClienteValidation.Cliente;

    let response = await validaObjeto(clienteValidation, cliente);
    if (!response) return ['Informe o cpf ou cnpj para começar.'];
    if (!response.ok) return response;

    if (produtoId === constants.Produtos.DreMitsui) {

      if (cliente?.clienteContatoMails.length === 0)
      return ['Informe pelo menos um e-mail'];
    }

    if (produtoId === Constants.Produtos.VidaPremiadoPlus) {
      proposta.vppAmerican.isAgenciaDigital = isAgenciaDigital;
      response = await validaObjeto(
        PropostaValidation.VppAmericanTitularPeso,
        proposta.vppAmerican
      );
      if (!response.ok) return response;

      if(cliente?.naturalidadeId === -2) return ['Por favor, informe a nacionalidade']
    }

    if (produtoId === Constants.Produtos.AcidentesPessoaisLiberty) {
      response = await validaObjeto(
        PropostaValidation.PessoaPoliticamenteExposta,
        proposta.apLiberty.PessoaPoliticamenteExposta
      );

      if (cliente?.estadoCivilId === -2) return ['Informe o estado civil!'];

      if (cliente?.clienteProfissaoId == null)
        return ['Selecione a profissão!'];

      if (cliente?.clienteContatoMails.length === 0)
        return ['Informe pelo menos um e-mail'];

      const respostasPreenchidas =
        proposta.apLiberty.respostasDps.listaRespostas.find(
          p => p.idRespostaPerguntaProduto == null
        ) === undefined;
      if (!respostasPreenchidas)
        return ['Responda todas as perguntas de risco!'];

      if (!response.ok) return response;
    }  

    if(produtoId === constants.Produtos.LibertyResidencialPersonalizavel) {
      response = await validaObjeto(ProducaoValidation.ProducaoValidationDefault, proposta.producao);

      if (cliente?.clienteContatoMails.length === 0)
      return ['Informe pelo menos um e-mail'];

      if((cliente?.clienteCpfCnpj ?? '').length > 11 && !libertyResidencialPersonalizavel?.segurado?.tipoAtividadeEmpresa)
        retorno.push('Deve-se informar o tipo de  atividade da empresa')

      if(!response.ok) retorno = retorno.concat(response);
    }

    if (produtoId === Constants.Produtos.BrbMasterPremiado) {
      if (cliente?.estadoCivilId == -2 || cliente?.estadoCivilId == 6)
        return ['Informe o estado civil!'];

      if (cliente?.naturalidadeId == -2) return ['Informe a nacionalidade!'];

      if (Functions.calculaIdade(cliente?.clienteDataNascimento) < 65) {
        return ['Para esse produto o cliente não pode ter idade inferior a 65 anos!'];
      }

      if (Functions.calculaIdade(cliente?.clienteDataNascimento) > 85) {
        return ['Para esse produto o cliente não pode ter idade superior a 85 anos!'];
      }

      if (!proposta?.contratoId && !(proposta?.brbMaster?.telefonesEnvioToken ?? []).length)
        return ['Deve-se informar o telefone do cliente que irá receber o token!'];
    }

    if (Functions.isCap(produtoId) && !capIcatu.demaisDados.segmentacaoClienteCap)
    {
      return ['Deve-se informar a Segmentação do cliente!'];
    }

    response = await validaObjeto(ClienteValidation.Endereco, endereco);
    if (!response.ok) retorno = retorno.concat(response);

    response = await validaObjeto(ClienteValidation.EnderecoPersonalizavelLiberty, endereco);
    if (!response.ok) retorno = retorno.concat(response);

    return retorno.length === 0 ? { ok: true } : retorno;
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function retornarConfiguracaoSelecionarTelefone() {
    switch(produtoId) {
      case Constants.Produtos.BrbMasterPremiado:
        return retornaConfiguracaoSelecionarTelefoneMaster()
    }
    return null;
  }

  function retornaConfiguracaoSelecionarTelefoneMaster() {
    var retorno = {
      showOptionToSelectTelefones: true,
      textToolTipSelectTelefone: 'Selecionar telefone para envio do token',
      textHeaderSelectTelefone: 'Envio do token',
      onSelectTelefone: selectTelefoneMaster,
      selectedTelefonesList: telefonesEnvioToken ?? []
    }

    return retorno;
  }

  function selectTelefoneMaster(listaEMails) {
    dispatch(PropostaActions.changeBrbMaster({ telefonesEnvioToken: listaEMails }));
  }

  return (
    <>
      <div className="actions-tabs" style={{ paddingBottom: 10 }}>
        <Button
          type="primary"
          size="default"
          style={{ marginLeft: '.5rem' }}
          onClick={_onNext}
        >
          Próximo <ArrowRightOutlined />
        </Button>
      </div>
      <Container>
        <Disable disable={proposta.contratoId > 0}>
          <Error erros={erros} />

          {!cliente && (
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                <div className="form-group">
                  <label>Tipo Pessoa</label> <br />
                  <Radio.Group
                    onChange={({ target: { value } }) => setTipoPessoa(value)}
                    value={tipoPessoa}
                  >
                    <Radio value="F">Física</Radio>
                    <Radio value="J">Jurídica</Radio>
                  </Radio.Group>
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <Form layout="vertical">
                  <Form.Item
                    hasFeedback
                    label="Para iniciar a inclusão de uma nova cotação informe o cpf/cnpj."
                    validateStatus={validateStatus}
                    help={
                      validateStatus === 'error'
                        ? 'Ops, o cpf/cnpj informado não é valido.'
                        : ''
                    }
                    required
                  >
                    <Input
                      placeholder="Cpf/Cnpj"
                      value={cpfCnpj}
                      onChange={onChangeCpf}
                      mask="cpfCnpj"
                      maxLength={18}
                      id="validating"
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}

          {cliente && (
            <>
              <FieldsProponente
                onChangeCliente={() => setCpfCnpj('')}
                produtoId={produtoId}
                ehPessoaFisica={tipoPessoa === 'F'}
              />
              <FieldsEndereco
                title="Correspondência"
                onChangeEndereco={onChangeEnderecoCliente}
                enderecoRedux={endereco}
              />
            </>
          )}

          {cliente &&
            produtoId === Constants.Produtos.AcidentesPessoaisLiberty && (
              <>
                <TitleSection title="Resumo de Risco" />
                <PerguntasResumoRisco ProdutoId={produtoId} />
              </>
            )}
          {cliente && (
            <>
              <FieldsContato selectTelefoneConfig={retornarConfiguracaoSelecionarTelefone()} />
            </>
          )}

          {
            produtoId === constants.Produtos.LibertyResidencialPersonalizavel && cliente &&(
              <FieldsProducao produto="residencial-personalizavel-liberty" />
            )
          }
        </Disable>
      </Container>
      {cliente && (
        <div className="actions-tabs">
          <Button
            type="primary"
            size="default"
            style={{ marginLeft: '.5rem' }}
            onClick={_onNext}
          >
            Próximo <ArrowRightOutlined />
          </Button>
        </div>
      )}
    </>
  );
}
