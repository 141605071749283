import { Icon, Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import history from '~/services/history';
import * as AuthAction from '~/store/ducks/auth';
import * as JogaJuntoActions from '~/store/ducks/joga-junto';
import * as LoginSeguradorasActions from '~/store/ducks/login-seguradoras';
import './index.css';




const { Header, Sider, Footer } = Layout;
const { SubMenu } = Menu;

type AuthLayoutProps = {
  children: Component,
};

export default function AuthLayout({ children }: AuthLayoutProps) {
  const dispatch = useDispatch();
  const { usuario } = useSelector(({ auth }) => auth);

  console.log(usuario);

  const rootSubmenuKeys = ['sub1', 'sub2'];
  const [collapsed, setCollapsed] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState([]);
  const location = useLocation();

  function toggle() {
    setCollapsed(!collapsed);
  }

  function rotaAtual() {
    return location.pathname;
  }

  function abrirIntegracao() {
    window.open(
      'https://integracao.brbseguros.com.br/frmAgendarContato.aspx'
    );
  }

  function EhHomologacao() {
    const { href } = window.location;

    const baseURL =
      href.indexOf('.brb.com.br') !== -1
        ? process.env.REACT_APP_BASE_URL_BRB
        : process.env.REACT_APP_BASE_URL;

    const listaUrlsHom = [
      'http://apiseguridadehmo.segurosbrb.com.br/',
      'http://localhost:2398/',
      'https://devz-sandbox-api.wizsolucoes.com.br/api/seguridade/'
    ];

    return listaUrlsHom.some(x => x === baseURL);
  }

  function onOpenChange(keySelected) {
    const latestOpenKey = keySelected.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keySelected);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  function logout() {
    dispatch(AuthAction.logout());
    history.push('/');
  }

  function donwloadPdfRegulamentoCampanha() {
    dispatch(JogaJuntoActions.getRegulamento())
  }

  function donwloadPlanilhaCampanha() {
    dispatch(JogaJuntoActions.getSituacaoAgencia())
  }

  function downloadFileLoginSeguradoras() {
    window.open(
      'https://c2s.brbseguros.com.br/'
    );
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={!collapsed}
      >
        <div className={collapsed ? 'logotipo' : 'logo'}
          onClick={toggle} />
        <Menu
          defaultSelectedKeys={[location.pathname]}
          // defaultOpenKeys={['sub1']}
          mode="inline"
          theme="dark"
          onOpenChange={onOpenChange}
          openKeys={openKeys}
        >

          {
            usuario && usuario.exibirDashboardPpe && <Menu.Item key="/dashboard" onClick={ev => history.push(ev.key)}>
              <Icon type="dashboard" theme="outlined"/>
              <span>Dashboard</span>
            </Menu.Item>
          }

          <Menu.Item key="/pesquisar-cotacoes-propostas" onClick={ev => history.push(ev.key)}>
            <Icon type="search" />
            <span>Pesquisar</span>
          </Menu.Item>

          <Menu.Item key="/pesquisa-geral" onClick={ev => history.push(ev.key)}>
            <Icon type="file-search" />
            <span>Pesquisa Geral</span>
          </Menu.Item>

          <Menu.Item key="/pesquisa-proposta-canceladas" onClick={ev => history.push(ev.key)}>
            <Icon type="security-scan" />
            <span>Pesquisa Propostas Canceladas</span>
          </Menu.Item>

          <SubMenu key="subUltimaRodada" title={
            <span>
              <Icon type="smile" />
              <span>Última Rodada</span>
            </span>
          }
          >
            <Menu.Item key="9" onClick={donwloadPdfRegulamentoCampanha}>Regulamento</Menu.Item>
            <Menu.Item key="10" onClick={donwloadPlanilhaCampanha}>Acompanhamento / Simulador</Menu.Item>
          </SubMenu>

          <Menu.Item key="/proposta/new" onClick={ev => history.push(ev.key)}>
            <Icon type="line-chart" />
            <span>Nova Cotação</span>
          </Menu.Item>

          <Menu.Item key="/agenda-positiva" onClick={ev => history.push(ev.key)}>
            <Icon type="calendar"/>
            <span>Agenda Positiva</span>
          </Menu.Item>

          <Menu.Item key="/login-seguradoras" onClick={downloadFileLoginSeguradoras}>
            <Icon type="file" />
            <span>Login Seguradoras </span>
          </Menu.Item>

          <Menu.Item key="/acumulo-risco" onClick={ev => history.push(ev.key)}>
            <Icon type="dollar"/>
            <span>Acúmulo de Risco</span>
          </Menu.Item>

          {
            (usuario.produtorId != null && usuario.produtorId != 0) && <Menu.Item key="/altera-conta" onClick={ev => history.push(ev.key)}>
              <Icon type="bank" theme="outlined"/>
              <span>Alterar Conta</span>
            </Menu.Item>
          }

          {
            usuario.fatoUsuarioPerfis.find(objeto => objeto.perfilId == 45 /*VIDA*/) && <Menu.Item key="/analise-alteracao-conta" onClick={ev => history.push(ev.key)}>
              <Icon type="bank" theme="outlined"/>
              <span>Analise Alteração de Conta</span>
            </Menu.Item>
          }


          <Menu.Item key="13" onClick={logout}>
            <Icon type="logout" />
            <span>Sair</span>
          </Menu.Item>
          
        </Menu>
      </Sider>
      <Layout>
        <Header className="header">
          <Icon
            className="trigger"
            type={collapsed ? 'pic-center' : 'pic-right'}
            onClick={toggle}
          />         
        </Header>
        {
          EhHomologacao() && (
            <div id="div-alerta-homologacao" className="navbar-text hidden-xs">
              <span className="badge badge-pill badge-danger spn-info-ambiente">Ambiente de Homologação</span>
            </div>
          )
        }

        {children}

        <Footer className="footerL">
          <span>
            Copyright <strong>BRB Seguros</strong> © {(new Date()).getFullYear()}
          </span>
          <span>
            ST SAUN Quadra 5 Lote C Torre “C” – Asa Norte |  Edifício Centro Empresarial CNC
          </span>
        </Footer>
      </Layout>
    </Layout>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
