import { call, all, put, takeLatest } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse } from '~/api/utils';
import * as DneApi from '~/api/dne.api';
import * as DneActions from '~/store/ducks/dne';

function* getCepGeral(action) {
  const { param } = action.payload;
  // Call api
  const response = getResponse(yield call(DneApi.cepGeral, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  yield put(DneActions.saveCepGeral(data));
}

function* fail(message) {
  yield put(DneActions.failedCep());
  toast.error(message);
}

export default all([takeLatest(DneActions.types.GET_CEP_GERAL, getCepGeral)]);
