import React from 'react';
import { Popconfirm } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import {
  PrinterOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  CheckOutlined,
  ArrowRightOutlined,
  FilePdfOutlined,
  EditOutlined
} from '@ant-design/icons';
import Button from 'antd-button-color';

type ButtonsProps = {
  onPressVoltar: Function,
  showButtonSalvar: Boolean,
  onPressSalvar: Function,
  showButtonEfetivar: Boolean,
  showButtonEnviarToken: Boolean,
  onPressEfetivar: Function,
  onPressEnviarToken: Function,
  showButtonImprimir: Boolean,
  linkImprimir: String,
  showButtonNovaCotacao: Boolean,
  onPressNovaCotacao: Function,
  showlinkBaixarArquivo: Boolean,
  linkBaixarArquivo: String,
  showButtonNext: Boolean,
  onPressButtonNext: Function,
  showlinkBaixarBoleto: Boolean,
  onClickBaixarBoleto: Function,
  textButtonSalvar: string,
  showButtonImprimirBrbMaster: Boolean,
  onPressImprimirBrbMaster: Function,
  showLinkAssinarProposta: Boolean,
  onPressAssinarProposta: Function
};

export default function Buttons({
  onPressVoltar,
  showButtonSalvar,
  onPressSalvar,
  showButtonEfetivar,
  showButtonEnviarToken,
  onPressEfetivar,
  onPressEnviarToken,
  showButtonImprimir,
  linkImprimir,
  showButtonNovaCotacao,
  onPressNovaCotacao,
  showlinkBaixarArquivo,
  linkBaixarArquivo,
  showButtonNext,
  onPressButtonNext,
  showlinkBaixarBoleto,
  onClickBaixarBoleto,
  textButtonSalvar,
  showButtonImprimirBrbMaster,
  onPressImprimirBrbMaster,
  showLinkAssinarProposta,
  onPressAssinarProposta
}: ButtonsProps) {
  return (
    <div className="actions-tabs" style={{ paddingBottom: 10 }}>
      <Button size="default" onClick={onPressVoltar} with="link" type="primary">
        <ArrowLeftOutlined /> Voltar
      </Button>
     
      {showButtonEnviarToken && (
        <Popconfirm
          placement="topLeft"
          title="Deseja enviar o token para o cliente?"
          onConfirm={onPressEnviarToken}
          okText="Sim"
          cancelText="Não"
        >
          <Button
            size="default"
            type="info"
            style={{ marginLeft: '.5rem' }}
          >
          <CheckOutlined />  Enviar Token
          </Button>
        </Popconfirm>
      )}
      {showButtonEfetivar && (
        <Popconfirm
          placement="topLeft"
          title="Deseja realmente efetivar esse contrato?"
          onConfirm={onPressEfetivar}
          okText="Sim"
          cancelText="Não"
        >
          <Button size="default" type="info" style={{ marginLeft: '.5rem' }}>
            <CheckOutlined /> Efetivar
          </Button>
        </Popconfirm>
      )}
      {showButtonSalvar && (
        <Button
          type="primary"
          size="default"
          style={{ marginLeft: '.5rem' }}
          onClick={onPressSalvar}
        >
          <SaveOutlined /> { textButtonSalvar ?? 'Salvar' }
        </Button>
      )}
      {
        showButtonNext && (
          <Button
            type="primary"
            size="default"
            style={{ marginLeft: '.5rem' }}
            onClick={onPressButtonNext}
          >
            Próximo <ArrowRightOutlined />
          </Button>
        )
      }
      {showlinkBaixarBoleto && (
        <Button
          ghost
          type="lightdark"
          size="default"
          style={{ marginLeft: '.5rem' }}
          onClick={onClickBaixarBoleto}
        >
          <FilePdfOutlined /> Baixar Boleto
        </Button>
      )}
      {showButtonImprimir && (
        <Button
          ghost
          type="lightdark"
          size="default"
          style={{ marginLeft: '.5rem' }}
          target="_blank"
          href={`/${linkImprimir}`}
        >
          <PrinterOutlined /> Imprimir
        </Button>
      )}
      {showlinkBaixarArquivo && (
        <Button
          ghost
          type="lightdark"
          size="default"
          style={{ marginLeft: '.5rem' }}
          onClick={linkBaixarArquivo}
        >
          <PrinterOutlined /> Baixar PDF
        </Button>
      )}
      {showLinkAssinarProposta && (
        <Button
          type="primary"
          size="default"
          style={{ marginLeft: '.5rem' }}
          onClick={onPressAssinarProposta}
        >
          <EditOutlined /> Assinar Proposta
        </Button>
      )}
      {showButtonImprimirBrbMaster && (
        <Button
          ghost
          type="lightdark"
          size="default"
          style={{ marginLeft: '.5rem' }}
          onClick={onPressImprimirBrbMaster}
        >
         <PrinterOutlined /> Imprimir
        </Button>
      )}
    </div>
  );
}
