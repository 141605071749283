import { createActions, handleActions } from "redux-actions";

export const types = {
    FAILED_AGENDA_POSTIVIA: 'FAILED_AGENDA_POSITIVA',
    INIT_AGENDA_POSITIVA: 'INIT_AGENDA_POSITIVA',
    ADD_AGENDA_POSITIVA: 'ADD_AGENDA_POSITIVA'
}

const defaultState = {
    loading: false
}

export const {
    failedAgendaPositiva,
    initAgendaPositiva,
    addAgendaPositiva,
} = createActions(
    types.FAILED_AGENDA_POSTIVIA,
    types.INIT_AGENDA_POSITIVA,
    types.ADD_AGENDA_POSITIVA
    );

export default handleActions(
  {
    [failedAgendaPositiva]: state => ({ ...state, loading: false }),
    [initAgendaPositiva]: () => defaultState,
    [addAgendaPositiva]: (state) => ({
        ...state,
        loading: false,
    })
  },
  defaultState
)