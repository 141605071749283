import { Col, Divider, Row, Modal, Button, Input, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Actions from '~/store/ducks/cliente';
import { postContaContrato } from '~/store/ducks/cliente';

type ContaProp = {
  visible?: Boolean,
  width?: String | Number,
  onOk?: Function,
  onCancel?: Function,
  data?: DataModal,
};

type DataModal = {
  contrato?: Object,
};

export default function ContaDetalhe(param: ContaProp) {
  const dispatch = useDispatch();
  const contrato = param?.data?.contrato || {};
  const { usuario } = useSelector(state => state.auth);
  const { loading } = useSelector(({ cliente }) => cliente);

  const [novaConta, setConta] = useState('');


  const handleChange = event => {
    setConta(event.target.value);
  };

  function handleSubmit(e) {
    if (e) e.preventDefault();

    let contratoAux = { ...contrato };

    contratoAux.contaAntiga = contrato.conta;
    contratoAux.contaNova = novaConta;
    contratoAux.conta = null;
    contratoAux.matriculaAngariador = usuario.matricula;

    const callback = (response) => {
      contrato.conta = contratoAux.contaNova;
      contratoAux = null;
      setConta('');


      if (response.ok) {
        if (param.onOk) param.onOk();
      }
      else{
        if (param.onCancel) param.onCancel();
      }
    };

    dispatch(postContaContrato({ param: contratoAux, callback }));
  }

  return (
    <Modal
      centered
      visible={param.visible}
      onOk={param.onOk}
      okButtonProps={{ hidden: true }}
      onCancel={param.onCancel}
      cancelText="Voltar"
      width={param.width}
      style={{ padding: '15px' }}
      title="Alteração de Conta"
    >
      <Spin
        spinning={loading}
        size="small"
        tip="Aguarde carregando..."
      >
        <Divider
          orientation="left"
          style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
        >
          Dados do Cliente
        </Divider>
        <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="ClienteNome">Cliente Nome</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="ClienteNome">{contrato.clienteNome}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="ClienteCpfCnpj">Cliente CPF/CNPJ</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="ClienteCpfCnpj">{contrato.clienteCpfCnpj}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
        >
          Dados do Contrato
        </Divider>
        <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="Contrato">Contrato</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="Contrato">{contrato.contrato}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="Apólice">Apólice</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="Apólice">{contrato?.apolice}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="Vigência">Vigência</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="Vigência">
                  {`DE ${moment(contrato?.dataInicio).format('DD/MM/YYYY')}
                    A ${moment(contrato?.dataFim).format('DD/MM/YYYY')}`}
                </span>
              </Col>
            </Row>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="Carteira">Carteira</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="Carteira">{contrato?.ramo}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="Produto">Produto</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="Produto">{contrato?.modalidade}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            <Row>
              <Col span={24}>
                <strong title="Seguradora">Seguradora</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="Seguradora">{contrato?.seguradora}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
        >
          Alterar Conta
        </Divider>
        <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
          <Col xs={24} sm={24} md={6}>
            <Row>
              <Col span={24}>
                <strong title="ContaAtual">Conta Atual</strong>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span title="ContaAtual">{contrato.conta}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Row>
              <Col span={24}>
                <strong title="NovaConta">Nova Conta</strong>
              </Col>
            </Row>
            <Row>
              <Input
                placeholder="Nova Conta"
                value={novaConta}
                name="NovaConta"
                onChange={handleChange}
                maxLength={18}
                id="NovaConta"
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Row>
              <Col span={24}>
                <Button
                  type="success"
                  style={{ marginTop: '20px' }}
                  onClick={handleSubmit}
                >
                  Altera Conta
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}
