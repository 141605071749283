import { call, all, put, takeLatest } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse, getResponseFile } from '~/api/utils';
import * as ClienteApi from '~/api/cliente.api';
import * as PropostaApi from '~/api/proposta.api';
import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';

import { store } from '~/store';

const CANAL_VENDA = {
  CNP: 13,
  AGENCIA_DIGITAL: 14,
};

function* getContas(action) {
  const { param } = action.payload;

  const response = getResponse(
    yield call(ClienteApi.getContas, param?.replace(/\D/g, ''))
  );

  const { ok, data, message } = response;

  if (!ok) fail(message);

  yield put(PropostaActions.saveContas(data || []));
}

function* save(action) {
  // Parâmetro
  const { param } = action.payload;
  // Id da proposta
  const { propostaId } = store.getState().proposta;
  // Verifica se existe Id
  const func = propostaId > 0 ? PropostaApi.update : PropostaApi.add;
  // Adiciona o id no objeto
  param.propostaEletronicaId = propostaId;
  // Chama a api
  const response = getResponse(yield call(func, param));

  // Recebe o retorno
  const { ok, data, message } = response;

  // Verifica se houve erro
  if (!ok) {
    yield call(fail, message);
    return;
  }
  // Exibe mensagem ao usuário
  toast.success(message);

  if (data) {
    // Atuaiza o cliente com o id retornado
    yield put(ClienteActions.updateClientAfterSave(data));
    // Salva o retorno
    yield put(PropostaActions.saveSuccess(data));
  } else {
    yield put(PropostaActions.updateSuccess());
  }
}

function* efetivar(action) {
  // Parâmetro
  const { param, callback } = action.payload;
  // Id da proposta
  const { propostaId } = store.getState().proposta;
  const { usuario } = store.getState().auth;

  // Adiciona o id no objeto
  param.propostaEletronicaId = propostaId;

  // Adiciona o canal de venda para todos os contratos efetivados
  const { propostaEletronicaJsonContrato } = param;
  const jsonContratoObj = JSON.parse(propostaEletronicaJsonContrato);
  jsonContratoObj.CanalVendaId = CANAL_VENDA[usuario.descTipoProdutor] || 10; // Canal de Venda do Seguridade
  param.propostaEletronicaJsonContrato = JSON.stringify(jsonContratoObj);

  // Chama a api para efetivar
  const response = getResponse(yield call(PropostaApi.efetivar, param));
  // Recebe o retorno
  const { ok, data, message, messages } = response;
  // Verifica se houve erro
  if (!ok) {
    if (callback) callback(messages);
    yield call(fail, message);
    return;
  }
  // Exibe mensagem ao usuário
  // toast.success(message);

  // Salva o retorno
  yield put(
    PropostaActions.efetivarSuccess({
      id: data.id,
      numeroControle: data.numeroControle,
      urlArquivo: data.urlArquivo,
    })
  );
}

function* efetivarComArquivo(action) {
  // Parâmetro
  const { param, callback } = action.payload;
  // Chama a api para efetivar
  const response = getResponse(
    yield call(PropostaApi.efetivarComArquivo, param)
  );
  // Recebe o retorno
  const { ok, data, message, messages } = response;
  // Verifica se houve erro
  if (!ok) {
    if (callback) callback(messages);
    yield call(fail, message);
    return;
  }
  // Exibe mensagem ao usuário
  // toast.success(message);

  // Salva o retorno
  yield put(
    PropostaActions.efetivarSuccessComArquivo({
      id: data.id,
      numeroControle: data.numeroControle,
      urlArquivo: data.urlArquivo,
    })
  );
}

function* search(action) {
  // Chama api para busca
  const response = getResponse(yield call(PropostaApi.search, action.payload));
  // Recebe o retorno
  const { ok, data, message } = response;
  // Verifica se houve erro
  if (!ok) {
    yield call(fail, message);
    return;
  }

  // Salva o retorno
  yield put(PropostaActions.searchSuccess(data));
}

function* searchPropostasCanceladas(action) {
  // Chama api para busca
  const response = getResponse(
    yield call(PropostaApi.searchPropostasCanceladas, action.payload)
  );
  // Recebe o retorno
  const { ok, data, message } = response;
  // Verifica se houve erro
  if (!ok) {
    yield call(fail, message);
    return;
  }

  // Salva o retorno
  yield put(PropostaActions.searchPropostasCanceladasSuccess(data));
}

function* searchById(action) {
  // Chama api para busca
  const response = getResponse(
    yield call(PropostaApi.searchById, action.payload)
  );
  // Recebe o retorno
  const { ok, data, message } = response;
  // Verifica se houve erro
  if (!ok) {
    yield call(fail, message);
    return;
  }

  // Salva o retorno
  yield put(PropostaActions.searchSuccess(data));
}

function* fail(message) {
  yield put(PropostaActions.failedProposta());
  toast.error(message);
}

function* searchPerguntasProduto(action) {
  // Chama api para busca
  const response = getResponse(
    yield call(PropostaApi.searchPerguntasProduto, action.payload)
  );
  // Recebe o retorno
  const { ok, data, message } = response;
  // Verifica se houve erro
  if (!ok) {
    yield call(fail, message);
    return;
  }

  // Salva o retorno
  yield put(PropostaActions.searchPerguntasProdutoSucess(data));
}

function* getArquivoImpressaoById(action) {
  // Chama api para busca
  const response = getResponseFile(
    yield call(PropostaApi.getArquivoImpressaoById, action.payload),
    `PropostaImpressao${action.payload}.pdf`
  );
  // Recebe o retorno
  const { ok, message } = response;
  // Verifica se houve erro
  if (!ok) {
    yield call(fail, message);
  }
}
export default all([
  takeLatest(PropostaActions.types.GET_CONTAS, getContas),
  takeLatest(PropostaActions.types.SAVE, save),
  takeLatest(PropostaActions.types.EFETIVAR, efetivar),
  takeLatest(PropostaActions.types.EFETIVAR_COM_ARQUIVO, efetivarComArquivo),
  takeLatest(PropostaActions.types.SEARCH, search),
  takeLatest(PropostaActions.types.SEARCH_BY_ID, searchById),
  takeLatest(
    PropostaActions.types.GET_ARQUIVO_IMPRESSAO_BY_ID,
    getArquivoImpressaoById
  ),
  takeLatest(
    PropostaActions.types.SEARCH_PERGUNTAS_PRODUTO,
    searchPerguntasProduto
  ),
  takeLatest(
    PropostaActions.types.SEARCH_PROPOSTAS_CANCELADAS,
    searchPropostasCanceladas
  ),
]);
