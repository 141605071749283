import api from '.';

export const getListaOrcadoAgenciaMes = param => api().get(`api/dashboard/orcado-agencia-mes`, param);
export const getListaRealizadoAgenciaMes = param => api().get(`api/dashboard/realizado-agencia-mes`, param);
export const getListaOrcadoOperadorMes = param => api().get(`api/dashboard/orcado-operador-mes`, param);
export const getListaRealizadoOperadorMes = param => api().get(`api/dashboard/realizado-operador-mes`, param);
export const getListaRealizadoOperadorPeriodo = param => api().get(`api/dashboard/realizado-operador-periodo`, param);
export const getListaOrcadoOperadorPeriodo = param => api().get(`api/dashboard/orcado-operador-periodo`, param);
export const getListaOrcadoAgenciaPeriodo = param => api().get(`api/dashboard/orcado-agencia-periodo`, param);
export const getListaRealizadoAgenciaPeriodo = param => api().get(`api/dashboard/realizado-agencia-periodo`, param);
