import api from '.';

export const generos = () => api().get('api/dominios/generos');
export const profissoes = () => api().get('api/dominios/profissoes');
export const estadosCivis = () => api().get('api/dominios/estados-civis');
export const naturalidade = () => api().get('api/dominios/naturalidade');
export const faixaRenda = () => api().get('api/dominios/faixa-renda');
export const enderecoTipo = () => api().get('api/dominios/endereco-tipo');
export const ufs = () => api().get('api/dominios/ufs');
export const cargos = () => api().get('api/dominios/cargos');
export const consultores = () => api().get('api/dominios/consultores');
export const angariadores = () => api().get('api/dominios/angariadores');
export const angariadoresCnps = agenciaId =>
  api().get(`api/dominios/angariadores-cnp?agenciaId=${agenciaId}`);
export const produtorByTipos = tipos =>
  api().get(`api/dominios/produtor-by-tipos?${tipos}`);
export const agenciasBrb = () => api().get('api/dominios/agencias?isBrb=true');
export const tiposContratos = () => api().get('api/dominios/tipos-contratos');
export const equipes = () => api().get('api/dominios/equipes');
export const agencias = () => api().get('api/dominios/agencias');
export const contatoFoneTipos = () =>
  api().get('api/dominios/contato-fone-tipo');
export const bancos = () => api().get('api/dominios/bancos');
export const grausRelacionamento = () =>
  api().get('api/dominios/graus-relacionamento');

export const allDominios = () => api().get('api/dominios/all');
