import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useIsAgenciaDigital() {
  const [isAgenciaDigital, setIsAgenciaDigital] = useState(false);

  const { usuario } = useSelector(({ auth }) => auth);

  useEffect(() => {
    setIsAgenciaDigital(usuario?.descTipoProdutor === 'AGENCIA_DIGITAL');
  }, [usuario]);

  return [isAgenciaDigital];
}
