import { createActions, handleActions, combineActions } from 'redux-actions';

import moment from 'moment';

export const types = {
  INIT_PROPOSTA: 'INIT_PROPOSTA',
  SAVE_PRODUCAO_PROPOSTA: 'SAVE_PRODUCAO_PROPOSTA',
  CHANGE_PRODUCAO: 'CHANGE_PRODUCAO',
  CHANGE_VPP_AMERICAN: 'CHANGE_VPP_AMERICAN',
  CHANGE_BRB_MASTER: 'CHANGE_BRB_MASTER',
  CHANGE_AP_LIBERTY: 'CHANGE_AP_LIBERTY',
  CHANGE_TIPO_PROPOSTA: 'CHANGE_TIPO_PROPOSTA',
  CHANGE_TIPO_PLANO: 'CHANGE_TIPO_PLANO',
  CHANGE_CONTAS_DEBITO: 'CHANGE_CONTAS_DEBITO',
  GET_CONTAS: 'GET_CONTAS',
  SAVE_CONTAS: 'SAVE_CONTAS',
  SAVE_DRE_MITSUI: 'SAVE_DRE_MITSUI',
  SAVE_AP_LIBERTY: 'SAVE_AP_LIBERTY',
  CHANGE_DRE_MITSUI: 'CHANGE_DRE_MITSUI',
  SAVE_DRE_LIBERTY_CE_S: 'SAVE_DRE_LIBERTY_CE_S',
  CHANGE_DRE_LIBERTY_CE_S: 'CHANGE_DRE_LIBERTY_CE_S',
  SAVE_DRE_ZURICH_RES: 'SAVE_DRE_ZURICH_RES',
  SAVE_DRE_ZURICH_EMP: 'SAVE_DRE_ZURICH_EMP',
  CHANGE_DRE_ZURICH_RES: 'CHANGE_DRE_ZURICH_RES',
  CHANGE_DRE_ZURICH_EMP: 'CHANGE_DRE_ZURICH_EMP',
  CHANGE_DRE_MITSUI_EMP: 'CHANGE_DRE_MITSUI_EMP',
  SAVE_DRE_MITSUI_EMP: 'SAVE_DRE_MITSUI_EMP',
  CHANGE_CAP_ICATU: 'CHANGE_CAP_ICATU',
  SAVE_CAP_ICATU: 'SAVE_CAP_ICATU',
  SAVE: 'SAVE',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  EFETIVAR: 'EFETIVAR',
  EFETIVAR_COM_ARQUIVO: 'EFETIVAR_COM_ARQUIVO',
  EFETIVAR_SUCCESS: 'EFETIVAR_SUCCESS',
  EFETIVAR_SUCCESS_COM_ARQUIVO: 'EFETIVAR_SUCCESS_COM_ARQUIVO',
  SEARCH: 'SEARCH',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_BY_ID: 'SEARCH_BY_ID',
  SAVE_PRODUTO: 'SAVE_PRODUTO',
  FAILED_PROPOSTA: 'FAILED_PROPOSTA',
  SEARCH_PERGUNTAS_PRODUTO: 'SEARCH_PERGUNTAS_PRODUTO',
  GET_ARQUIVO_IMPRESSAO_BY_ID: 'GET_ARQUIVO_IMPRESSAO_BY_ID',
  SEARCH_PERGUNTAS_PRODUTO_SUCESS: 'SEARCH_PERGUNTAS_PRODUTO_SUCESS',
  SEARCH_PROPOSTAS_CANCELADAS: 'SEARCH_PROPOSTAS_CANCELADAS',
  SEARCH_PROPOSTAS_CANCELADAS_SUCCESS: 'SEARCH_PROPOSTAS_CANCELADAS_SUCCESS'
};

export const {
  initProposta,
  saveProducaoProposta,
  changeProducao,
  changeVppAmerican,
  changeBrbMaster,
  changeApLiberty,
  changeTipoProposta,
  changeTipoPlano,
  changeContasDebito,
  getContas,
  saveContas,
  saveDreMitsui,
  saveApLiberty,
  changeDreMitsui,
  saveDreLibertyCeS,
  changeDreLibertyCeS,
  saveDreZurichRes,
  saveDreZurichEmp,
  changeDreZurichRes,
  changeDreZurichEmp,
  changeDreMitsuiEmp,
  saveDreMitsuiEmp,
  changeCapIcatu,
  saveCapIcatu,
  save,
  saveSuccess,
  updateSuccess,
  efetivar,
  efetivarComArquivo,
  efetivarSuccess,
  efetivarSuccessComArquivo,
  search,
  searchSuccess,
  searchById,
  saveProduto,
  failedProposta,
  searchPerguntasProduto,
  getArquivoImpressaoById,
  searchPerguntasProdutoSucess,
  searchPropostasCanceladas,
  searchPropostasCanceladasSuccess
} = createActions(
  types.INIT_PROPOSTA,
  types.SAVE_PRODUCAO_PROPOSTA,
  types.CHANGE_PRODUCAO,
  types.CHANGE_VPP_AMERICAN,
  types.CHANGE_BRB_MASTER,
  types.CHANGE_AP_LIBERTY,
  types.CHANGE_TIPO_PROPOSTA,
  types.CHANGE_TIPO_PLANO,
  types.CHANGE_CONTAS_DEBITO,
  types.GET_CONTAS,
  types.SAVE_CONTAS,
  types.SAVE_DRE_MITSUI,
  types.SAVE_AP_LIBERTY,
  types.CHANGE_DRE_MITSUI,
  types.SAVE_DRE_LIBERTY_CE_S,
  types.CHANGE_DRE_LIBERTY_CE_S,
  types.SAVE_DRE_ZURICH_RES,
  types.SAVE_DRE_ZURICH_EMP,
  types.CHANGE_DRE_ZURICH_RES,
  types.CHANGE_DRE_ZURICH_EMP,
  types.CHANGE_DRE_MITSUI_EMP,
  types.SAVE_DRE_MITSUI_EMP,
  types.CHANGE_CAP_ICATU,
  types.SAVE_CAP_ICATU,
  types.SAVE,
  types.SAVE_SUCCESS,
  types.UPDATE_SUCCESS,
  types.EFETIVAR,
  types.EFETIVAR_COM_ARQUIVO,
  types.EFETIVAR_SUCCESS,
  types.EFETIVAR_SUCCESS_COM_ARQUIVO,
  types.SEARCH,
  types.SEARCH_SUCCESS,
  types.SEARCH_BY_ID,
  types.SAVE_PRODUTO,
  types.FAILED_PROPOSTA,
  types.SEARCH_PERGUNTAS_PRODUTO,
  types.GET_ARQUIVO_IMPRESSAO_BY_ID,
  types.SEARCH_PERGUNTAS_PRODUTO_SUCESS,
  types.SEARCH_PROPOSTAS_CANCELADAS,
  types.SEARCH_PROPOSTAS_CANCELADAS_SUCCESS
);

const defaultState = {
  loading: false,
  producao: { contratoTipoId: 1, isProdutor: 2 },
  contasDebito: [],
  loadingContas: false,
  propostaId: 0,
  contratoId: 0,
  produtoId: 0,
  vppAmerican: {
    tipoPropostaId: 1,
    tipoPlano: 1,
    tipoPagamento: 1,
    tipoConta: 1,
    bancoId: '0070',
    beneficiarios: [{ nome: '', grauRelacionamento: '', percentual: '' }],
  },
  brbMaster: {
    tipoPropostaId: 1,
    tipoPagamento: 1,
    tipoPlano: 1,
    tipoConta: 1,
    bancoId: '0070',
    tokenSigned: false,
    telefonesEnvioToken: [],
    beneficiarios: [{ nome: '', dataNascimento: '', grauRelacionamento: '', percentual: '' }],
  },
  apLiberty: {
    tipoPropostaId: 1,
    tipoPagamento: 1,
    tipoConta: 1,
    contaDebitoId: undefined,
    agenciaDebitoId: undefined,
    contaDebitoNumero: '',
    PessoaPoliticamenteExposta: {isPessoaPoliticamenteExposta: 0},
    bancoId: '',
    beneficiarios: [
      { nome: '', cpf: '', grauRelacionamento: '', percentual: '' },
    ],
    planoSelected: {},
    cpfTerceiro: '',
    nomeTerceiro: '',
    respostasDps: [],
    pagamento: {
      isDebitoBrb: true,
      isDebitoOutrosBancos: false
    }
  },
  dreMitsui: {
    pagamento: {
      formaPagamento: 1,
      contratoDREQtdParcela: 1,
      isBoleto: false,
    },
    contratoDREDataInicio: moment.utc(Date.now()),
    contratoDREDataFim: moment(Date.now()).add(1, 'y'),
  },
  dreLibertyCeS: {
    pagamento: {
      formaPagamento: 1,
      contratoDREQtdParcela: 1,
    },
  },
  dreZurichRes: {
    pagamento: {
      formaPagamento: 1,
      contratoDREQtdParcela: 1,
    },
  },
  dreZurichEmp: {
    pagamento: {
      formaPagamento: 1,
      contratoDREQtdParcela: 1,
    },
    controladores: [
      {
        controladorNome: '',
        controladorCpfCnpj: '',
        controladorNacionalidade: '',
        controladorPublico: '',
      },
    ],
  },
  dreMitsuiEmp: {
    pagamento: {
      formaPagamento: 1,
      contratoDREQtdParcela: 1,
      isBoleto: false,
      isDebitoAutomatico: true
    },
  },
  capIcatu: { titulosCapitalizacao: [], demaisDados: {} },
  propostas: [],
  perguntasProduto: [],
};

export default handleActions(
  {
    [initProposta]: () => defaultState,
    [saveProducaoProposta]: (state, action) => ({
      ...state,
      producao: action.payload,
    }),
    [changeProducao]: (state, action) => ({
      ...state,
      producao: {
        ...state.producao,
        [action.payload.field]: action.payload.value,
      },
    }),
    [changeVppAmerican]: (state, action) => ({
      ...state,
      vppAmerican: {
        ...state.vppAmerican,
        ...action.payload,
      },
    }),
    [changeBrbMaster]: (state, action) => ({
      ...state,
      brbMaster: {
        ...state.brbMaster,
        ...action.payload,
      },
    }),
    [changeApLiberty]: (state, action) => ({
      ...state,
      apLiberty: {
        ...state.apLiberty,
        ...action.payload,
      },
    }),
    [getContas]: state => ({ ...state, loadingContas: true }),
    [saveContas]: (state, action) => ({
      ...state,
      loadingContas: false,
      contasDebito: action.payload,
      vppAmerican: { ...state.vppAmerican, loadingContas: false },
      brbMaster: { ...state.brbMaster, loadingContas: false },
      apLiberty: { ...state.apLiberty, loadingContas: false },
    }),
    [changeContasDebito]: (state, action) => ({
      ...state,
      contasDebito: action.payload,
    }),
    [saveDreMitsui]: (state, action) => ({
      ...state,
      dreMitsui: action.payload,
    }),
    [saveApLiberty]: (state, action) => ({
      ...state,
      apLiberty: action.payload,
    }),
    [changeDreMitsui]: (state, action) => ({
      ...state,
      dreMitsui: {
        ...state.dreMitsui,
        [action.payload.field]: action.payload.value,
      },
    }),
    [saveDreLibertyCeS]: (state, action) => ({
      ...state,
      dreLibertyCeS: action.payload,
    }),
    [changeDreLibertyCeS]: (state, action) => ({
      ...state,
      dreLibertyCeS: {
        ...state.dreLibertyCeS,
        [action.payload.field]: action.payload.value,
      },
    }),
    [saveDreZurichRes]: (state, action) => ({
      ...state,
      dreZurichRes: action.payload,
    }),
    [changeDreZurichRes]: (state, action) => ({
      ...state,
      dreZurichRes: {
        ...state.dreZurichRes,
        [action.payload.field]: action.payload.value,
      },
    }),
    [saveDreZurichEmp]: (state, action) => ({
      ...state,
      dreZurichEmp: action.payload,
    }),
    [changeDreZurichEmp]: (state, action) => ({
      ...state,
      dreZurichEmp: {
        ...state.dreZurichEmp,
        [action.payload.field]: action.payload.value,
      },
    }),
    [changeDreMitsuiEmp]: (state, action) => ({
      ...state,
      dreMitsuiEmp: {
        ...state.dreMitsuiEmp,
        [action.payload.field]: action.payload.value,
      },
    }),
    [saveDreMitsuiEmp]: (state, action) => ({
      ...state,
      dreMitsuiEmp: action.payload,
    }),
    [changeCapIcatu]: (state, action) => ({
      ...state,
      capIcatu: {
        ...state.capIcatu,
        [action.payload.field]: action.payload.value,
      },
    }),
    [saveCapIcatu]: (state, action) => ({
      ...state,
      capIcatu: action.payload,
    }),
    [combineActions(save, efetivar, efetivarComArquivo)]: state => ({
      ...state,
      loading: true,
    }),
    [saveSuccess]: (state, action) => ({
      ...state,
      loading: false,
      propostaId: action.payload.propostaEletronicaId,
      numeroControle: action.payload.propostaEletronicaNumeroControle,
    }),
    [updateSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [efetivarSuccess]: (state, action) => ({
      ...state,
      loading: false,
      contratoId: action.payload.id,
      numeroControle: action.payload.numeroControle,
      urlArquivo: action.payload.urlArquivo,
    }),
    [efetivarSuccessComArquivo]: (state, action) => ({
      ...state,
      loading: false,
      contratoId: action.payload.id,
      numeroControle: action.payload.numeroControle,
      urlArquivo: action.payload.urlArquivo,
    }),
    [efetivarSuccessComArquivo]: (state, action) => ({
      ...state,
      loading: false,
      contratoId: action.payload.id,
      numeroControle: action.payload.numeroControle,
      urlArquivo: action.payload.urlArquivo,
    }),
    [search]: state => ({
      ...state,
      loading: true,
    }),
    [searchSuccess]: (state, action) => ({
      ...state,
      loading: false,
      propostas: action.payload,
    }),
    [saveProduto]: (state, action) => ({
      ...state,
      produtoId: action.payload,
    }),
    [failedProposta]: (state, action) => ({
      ...state,
      loading: false,
      loadingContas: false,
      contratoId: action.payload,
    }),
    [searchPerguntasProduto]: state => ({
      ...state,
      loading: true,
    }),
    [searchPerguntasProdutoSucess]: (state, action) => ({
      ...state,
      perguntasProduto: action.payload,
      loading: false,
    }),
    [searchPropostasCanceladas]: state => ({
      ...state,
      loading: true
    }),
    [searchPropostasCanceladasSuccess]: (state, action) => ({
      ...state,
      propostaCanceladas: action.payload,
      loading: false
    })
  },
  defaultState
);
