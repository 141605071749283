import { call, takeLatest, put } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse } from '~/api/utils';
import * as AgendaPositivaApi from '~/api/agenda-positiva.api'
import * as AgendaPositivaActions from '~/store/ducks/agenda-positiva'

function* addAgendaPositiva(action) {

  // Call api
  const response = getResponse(yield call(AgendaPositivaApi.addAgendaPositiva, action.payload));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  toast.success(message);
}

function* fail(message) {
  yield put(AgendaPositivaActions.failedAgendaPositiva());
  toast.error(message);
}

export default takeLatest(AgendaPositivaActions.types.ADD_AGENDA_POSITIVA, addAgendaPositiva);
