import {
  CheckOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin, Table, Modal } from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, ContentHeader, Error } from '~/components';
import * as Actions from '~/store/ducks/cliente';
import * as DominiosActions from '~/store/ducks/dominio';
import './styles.css';

const { Column } = Table;
const { confirm } = Modal;

export default function AnaliseAlteracaoConta() {
  const dispatch = useDispatch();
  const dominios = useSelector(({ dominio }) => dominio);
  const { solicitacaoAlteracaoConta, loading } = useSelector(
    ({ cliente }) => cliente
  );
  const [erros, setErros] = useState([]);

  useEffect(() => {
    dispatch(Actions.getSolicitacaoAlteracaoConta(callback));
    dispatch(DominiosActions.listAll(callback));
  }, [dispatch]);

  function tratarChangeTable() {
    dispatch(Actions.getSolicitacaoAlteracaoConta(callback));
  }

  function showConfirm(record, acao) {
    confirm({
      title: 'Você tem certeza que deseja seguir com esta ação?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Spin
            spinning={loading}
            size="small"
            tip="Aguarde carregando..."
          ></Spin>
          <p>Solicitante: {record.nomeSolicitante}</p>
          <p>Módulo: {Modulo(record)}</p>
          <p>Produto: {Produto(record)}</p>
          <p>Número Contrato: {record.numeroContrato}</p>
          <p>Conta Antiga: {record.contaAntiga}</p>
          <p>Conta Nova: {record.contaNova}</p>
        </>
      ),
      onOk() {
        handleRowClick(record, acao);
      },
      onCancel() {
        console.log('Cancelado');
      },
    });
  }

  function handleRowClick(record, acao) {
    record.statusAlteracaoContaCorrenteId = acao;
    dispatch(
      Actions.postAprovaAlteracaoConta({
        param: record,
        callbackAprovaAlteracaoConta,
      })
    );
  }

  const callbackAprovaAlteracaoConta = response => {
    if (!response.ok) setErros(['Ocorreu um erro ao processar requisição.']);

    dispatch(Actions.getSolicitacaoAlteracaoConta(callback));
  };

  const callback = response => {
    if (!response.ok) setErros(['Ocorreu um erro ao processar requisição.']);
  };

  function Modulo(record) {
    if(!dominios || !dominios.modulos) return null;
    var modulo = dominios.modulos.find(m => m.moduloId == record.moduloId);
    return modulo.moduloDescricao;
  }

  function Produto(record) {
    if(!dominios || !dominios.produtos) return null;
    var produto = dominios.produtos.find(m => m.produtoId == record.produtoId);
    return produto.produtoDescricao;
  }

  return (
    <>
      <ContentHeader
        title="ANÁLISE ALTERAÇÃO CONTA"
        hideClass="btn-Hide"
        breadText="Soliciações"
        icon="search"
      />

      <Spin spinning={loading} size="small" tip="Aguarde carregando...">
        <Container style={{ margin: '0 16px' }}>
          <Error erros={erros} />

          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <h6>Análise Alteração de Conta</h6>
            </Col>
          </Row>

          <Row>
            <Col>
              <Divider
                orientation="center"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Listagem de Pedidos de Alteração de Conta
              </Divider>
              <Table
                rowKey={(r, i) => i.toString()}
                dataSource={solicitacaoAlteracaoConta}
                onChange={tratarChangeTable}
                loading={!!(loading || []).length}
                scroll={{ x: 1500 }}
              >
                <Column
                  align="center"
                  title="Solicitante"
                  width={150}
                  dataIndex="nomeSolicitante"
                />

                <Column
                  align="center"
                  title="Módulo"
                  render={(text, record) => Modulo(record)}
                  width={80}
                />

                <Column
                  align="center"
                  title="Produto"
                  render={(text, record) => Produto(record)}
                  width={80}
                />

                <Column
                  align="center"
                  title="Número Contrato"
                  dataIndex="numeroContrato"
                  width={100}
                />

                <Column
                  align="center"
                  title="Data Solicitação"
                  dataIndex="dataSolicitacao"
                  width={100}
                />

                <Column
                  align="center"
                  title="Conta Antiga"
                  dataIndex="contaAntiga"
                  width={80}
                />

                <Column
                  width={100}
                  align="center"
                  title="Conta Nova"
                  dataIndex="contaNova"
                />

                <Column
                  width={100}
                  align="center"
                  title="Ações"
                  render={(text, record) => (
                    <>
                      <Button
                        size="small"
                        type="link"
                        onClick={() => showConfirm(record, 2)}
                      >
                        <CheckOutlined />
                      </Button>
                      <Button
                        size="small"
                        type="link"
                        onClick={() => showConfirm(record, 3)}
                      >
                        <CloseOutlined />
                      </Button>
                    </>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Container>
      </Spin>
    </>
  );
}
