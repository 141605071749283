import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

export default (rootReducer, rootSaga) => {
  const middlewares = process.env.NODE_ENV === 'development' ? [logger] : [];
  const sagaMonitor =
    process.env.NODE_ENV === 'development'
      ? console.tron.createSagaMonitor()
      : null;

  /* Saga */
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  middlewares.push(sagaMiddleware);

  /* Composer for store */
  const composer =
    process.env.NODE_ENV === 'development'
      ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
      : compose(applyMiddleware(...middlewares));

  /* Create Store */
  const store = createStore(rootReducer, composer);

  /** Run Saga */
  sagaMiddleware.run(rootSaga);

  return store;
};
