import React from 'react';
import { Row, Col } from 'antd';
import { Title } from './style';

type TitleSectionProps = {
  title: String,
};

export default function TitleSection({ title }: TitleSectionProps) {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title>{title}</Title>
      </Col>
    </Row>
  );
}
