import React, { useState, useEffect } from 'react';
import { Row, Col, Radio, Spin, Tabs, Popconfirm, Result, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { TitleSection, Select, Input, Container, Disable } from '~/components';
import { Mask, Functions, Constants, Validation } from '~/utils';
import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';
import * as PropostaActions from '~/store/ducks/proposta';
import { PrinterOutlined, ArrowLeftOutlined, ArrowRightOutlined, RightOutlined, SaveOutlined, CreditCardOutlined, CheckOutlined } from '@ant-design/icons';
import Button from "antd-button-color";
import { ProducaoValidation, PropostaValidation } from '~/yup';
import { store } from '~/store';

const listParcelamentos = [
    { id: 1, descricao: "1 parcela de" },
    { id: 2, descricao: "2 parcelas de" },
    { id: 3, descricao: "3 parcelas de" },
    { id: 4, descricao: "4 parcelas de" },
    { id: 5, descricao: "5 parcelas de" },
    { id: 6, descricao: "6 parcelas de" },
    { id: 7, descricao: "7 parcelas de" },
    { id: 8, descricao: "8 parcelas de" },
    { id: 9, descricao: "9 parcelas de" },
    { id: 10, descricao: "10 parcelas de" },
    { id: 11, descricao: "11 parcelas de" }
];

type PagamentoProp = {
    onPrevius: Function,
    produtoId: Number,
};

export default function Pagamento({
    onPrevius,
    produtoId
}: PagamentoProp) {
    const {
        listTipoPagamento,
        listTipoConta,
        listDiaDebito
    } = Constants.ApLiberty;

    const {
        proposta: {
            apLiberty: proposta,
            contasDebito,
            loading,
            loadingContas,
            propostaId,
            contratoId,
            producao,
            parcelamento,
        },
        dominio: { bancos, agenciasBrb, grausRelacionamento, agencias },
        cliente: { cliente },
        plano: { planos, loading: loadingPlanos },
    } = useSelector(state => {
        const contasDebito = state.proposta.contasDebito;
        const apLiberty = state.proposta.apLiberty;
        apLiberty.contaDebitoId = apLiberty
            ? (
                contasDebito.find(
                    x =>
                        x.agenciaId === apLiberty.agenciaDebitoId &&
                        x.contaNumero === apLiberty.contaDebitoNumero
                ) || { id: null }
            ).id
            : 0;
        return state;
    });

    const {
        pagamento,
        pagamento: {
          isDebitoBrb,
          isDebitoOutrosBancos,
          agencia,
        },
      } = useSelector(({ proposta }) => proposta.apLiberty);

    const { usuario } = useSelector(({ auth }) => auth);
    const [agenciasOutros, setAgenciasOutros] = useState([]);

    const { TabPane } = Tabs;

    var valorParcela = proposta.planoSelected.detalhesPlano[7].valor;
    const isShowBtnEfetivar = (propostaId && !contratoId) ? true : false;

    const isCNP = usuario?.descTipoProdutor === 'CNP';

    const [erros, setErros] = useState([]);
    const [debitoContaNumeroConta, setNumeroConta] = useState();
    const [parcelamentos, setParcelamentos] = useState(listParcelamentos);
    const dispatch = useDispatch();

    const { href } = window.location;

    useEffect(() => {
        dispatch(PropostaActions.getContas({ param: cliente?.clienteCpfCnpj }));
        proposta.parcelamento = proposta.parcelamento > 0 ? proposta.parcelamento : 1;
        proposta.valorParcela = "R$ " + (parseFloat(valorParcela.replace("R$ ", "").replace(",", ".")) / parseInt(proposta.parcelamento)).toFixed(2).replace(".", ",")

    }, []);

    useEffect(() => {
        let parcelamentosFilter = isCNP ? listParcelamentos.filter(p => p.id === 1) : listParcelamentos;
        setParcelamentos(parcelamentosFilter);
        if (isCNP) changeParcelamento(1);
    }, [usuario]);

    function changeFields(obj) {
        dispatch(PropostaActions.changeApLiberty(obj));
    }

    function onChangeBanco(value) {
        const agenciasO = agencias?.filter(a => a.bancoId === value);
        setAgenciasOutros(agenciasO || []);

        onChangePagamento('agencia', { bancoId: value });
    }

    function changeContaDebito(contaId) {
        const conta = contasDebito.find(c => c.id === contaId);
        if (conta) {
            const newValue = {
                ...pagamento,
                debitoContaNumeroConta: conta.contaNumero,
                agencia: agencias.find(a => a.agenciaId === conta.agenciaId),
            };
            setNumeroConta(conta.contaNumero);
            dispatch(
                PropostaActions.changeApLiberty({
                    pagamento: newValue
                })
            );
        }
    }

    function callbackErrorEfetivar(values) {
        setErros(values);
    }

    function onChangePagamento(field, value) {
        // Valor default
        let newValue = { ...pagamento, [field]: value };

        if (field === 'isDebitoBrb') {
            newValue = {
            agencia: undefined,
            isDebitoBrb: true,
            isDebitoOutrosBancos: false,
            };
        } else if (field === 'isDebitoOutrosBancos') {
            newValue = {
            agencia: undefined,
            isDebitoBrb: false,
            isDebitoOutrosBancos: true,
            };
        }

        changeFields({
            pagamento: newValue,
        });
    }

    function getContaDebito() {
        const conta = contasDebito?.find(
        c =>
            c.agenciaId === agencia?.agenciaId &&
            c.contaNumero === debitoContaNumeroConta
        );
        if (conta) return conta.id;

        return null;
    }

    function changeOrigemConta(event) {
        const {
            target: { value },
        } = event;

        changeFields({
            tipoConta: value,
            contaDebitoId: undefined,
            agenciaDebitoId: undefined,
            contaDebitoNumero: undefined,
        });

        if (value !== 2) {
            changeFields({ cpfTerceiro: undefined });
            if (value === 1)
                dispatch(PropostaActions.getContas({ param: cliente.clienteCpfCnpj }));
            else dispatch(PropostaActions.changeContasDebito([]));
        } else if (value === 2) dispatch(PropostaActions.changeContasDebito([]));
    }

    async function validaRespostas() {
        let response = await validaObjeto(PropostaValidation.ApLibert, proposta);
        return !response.ok ? response : { ok: true };
    }

    async function saveProposal(efetivar = false) {
        setErros([]);

        const isValid = await validaRespostas();

        if (isValid.ok) {
            const propostaFormatada = FormatarProposta(efetivar);

            if (efetivar) {
                dispatch(
                    PropostaActions.efetivar({
                        param: propostaFormatada,
                        callback: callbackErrorEfetivar,
                    })
                );
                return;
            }

            dispatch(PropostaActions.save({ param: propostaFormatada }));
        } else {
            setErros(isValid);
        }
    }

    function formatarCliente(cliente, endereco) {
        return {
            ...cliente,
            clienteTipoId: cliente.clienteTipoId || 1, // Pessoa Física
            clienteContatoFones: cliente.clienteContatoFones.map(cf => {
                cf.clienteContatoFoneTipo = null; // Limpa o objeto do tipo de telefone
                return cf;
            }),
            clienteEnderecos: endereco.clienteEnderecoId
                ? cliente.clienteEnderecos.map(e => {
                    if (e.clienteEnderecoId === endereco.clienteEnderecoId) {
                        e = { ...e, ...endereco };
                    }

                    return e;
                })
                : [...cliente.clienteEnderecos, endereco],
        };
    }

    async function onEfetivar() {
        setErros([]);
        const isValid = await validaProposta();

        if (isValid.ok) {
            const param = FormatarProposta(true);

            dispatch(
                PropostaActions.efetivar({ param, callback: callbackErrorEfetivar })
            );
        } else setErros(isValid);
    }


    function callbackErrorEfetivar(values) {
        setErros(values);
    }

    function FormatarProposta(efetivar) {
        const {
            auth: { usuario },
            cliente: { cliente, endereco },
            proposta: { apLiberty: proposta, producao },
        } = store.getState();

        const clienteFormatado = formatarCliente(cliente, endereco);

        const propostaFormatada = {
            clienteId: cliente.clienteId,
            produtoId: Constants.Produtos.AcidentesPessoaisLiberty,
            moduloId: Constants.Modulos.VIDA,
            usuarioId: usuario.matricula,
            planoGarantiaVidaId: proposta.planoSelected?.id,
            cliente: clienteFormatado,
            propostaEletronicaEfetivada: efetivar,
            propostaEletronicaDataCadastro: formatISO(new Date()),
            propostaEletronicaDataEfetivacao: efetivar ? formatISO(new Date()) : null,
            propostaEletronicaJsonContrato: JSON.stringify(
                formatarContrato(proposta, clienteFormatado, usuario, producao)
            ),
            propostaEletronicaJsonDemaisDados: formataJsonDemaisDados(proposta, cliente, producao, pagamento),
        };

        return propostaFormatada;
    }

    function formataJsonDemaisDados(proposta, cliente, producao, pagamento) {
        const { tipoPropostaId, tipoConta, cpfTerceiro, nomeTerceiro, tipoPlano, respostasDps, beneficiarios, planoSelected, PessoaPoliticamenteExposta } = proposta;
        const { clienteFaixaRendaManual, clienteProfissaoLibertyCodigo } = cliente;
        const PlanoSelecionado = {
            id: planoSelected.id,
            valorMACeIPA: planoSelected.detalhesPlano[0].valor.replace("R$ ", "")
        };

        const { isProdutor } = producao;
        const { isDebitoBrb, isDebitoOutrosBancos } = pagamento;
        return JSON.stringify({ isProdutor, tipoPropostaId, tipoConta, cpfTerceiro, nomeTerceiro, tipoPlano, respostasDps, beneficiarios, PlanoSelecionado, PessoaPoliticamenteExposta, clienteFaixaRendaManual, clienteProfissaoLibertyCodigo, isDebitoBrb, isDebitoOutrosBancos });
    }

    function formatarContrato(proposta, cliente, usuario, producao) {
        let dadosCondicionais = {};

        var DebitoConta = {
            agencia: pagamento.agencia,
            agenciaId: pagamento.agencia?.agenciaId,
            produtoId: Constants.Produtos.AcidentesPessoaisLiberty,
            debitoContaNumeroConta: pagamento.debitoContaNumeroConta,
        };

        dadosCondicionais = {
            contratoVidaDataContrato: formatISO(add(new Date(), { days: 1 })),
            contratoVidaDataFinal: formatISO(add(new Date(), { years: 1, days: 1 })),
            contratoVidaObservacao: 'PROPOSTA ELETRONICA',
            contratoVidaVencimento1Parcela: formatISO(new Date()),
            mtrContratoVida1Parcela: proposta.valorParcela ? proposta.valorParcela.replace("R$ ", "").replace(",", ".") : (parseFloat(proposta.planoSelected.detalhesPlano[7].valor.replace("R$ ", "").replace(",", ".")) / parseInt(proposta.parcelamento)),
            MtrContratoVidaDemaisParcelas: proposta.parcelamento > 1 ? proposta.valorParcela ? proposta.valorParcela.replace("R$ ", "").replace(",", ".") : (parseFloat(proposta.planoSelected.detalhesPlano[7].valor.replace("R$ ", "").replace(",", ".")) / parseInt(proposta.parcelamento)) : null,
            clienteId: cliente.clienteId,
            seguradoraId: 5185,
            produtoId: 138104,
            contratoFormaPagamentoId: proposta.parcelamento === 1 ? 1 : 5,
            tipoPagamentoId: 2, //ANUAL fixo conforme alinhado com Hugo
            agenciaId: producao.agenciaId,
            equipeId: producao.equipeId,
            angariadorId: producao.angariadorId,
            consultorId: producao.consultorId,
            contratoVidaQuantidadeParcelas: proposta.parcelamento,
            contratoVidaDiaVencimentoDemaisParcelas: proposta.diaDebito,
            DebitoConta,
            contaDebitoId: proposta.contaDebitoId,
            contaDebitoNumero: proposta.contaDebitoNumero,
            agenciaDebitoId: proposta.agenciaDebitoId,
            bancoId: proposta.bancoId,
            MtrContratoVidaComissaoCorretagem: (parseInt(proposta.planoSelected.detalhesPlano[6].valor.replace("%", "").replace(",", ".")) / 100),
            mtrContratoVidaPremio: proposta.planoSelected.detalhesPlano[7].valor.replace("R$ ", "").replace(",", "."),
            mtrContratoVidaPremioLiquido: (parseFloat(proposta.planoSelected.detalhesPlano[7].valor.replace("R$ ", "").replace(",", ".")) / 1.0038)
        };

        dadosCondicionais = {
            ...dadosCondicionais,
        };
        return dadosCondicionais;
    }


    async function onSalvar() {
        setErros([]);
        const isValid = await validaProposta();
        saveProposal();

    }

    function validaObjeto(validation, obj) {
        return validation
            .validate(obj, { abortEarly: false })
            .then(() => ({ ok: true }))
            .catch(error => error.errors);
    }

    async function validaProposta() {

        return { ok: true };
    }

    function onChangeCpfTerceiro(value) {
        dispatch(
            PropostaActions.changeApLiberty({
                cpfTerceiro: value,
                contaDebitoId: undefined,
                agenciaDebitoId: undefined,
            })
        );

        dispatch(PropostaActions.changeContasDebito([]));

        if (value && value.length === 14) {
            if (!Validation.isCpfCnpjValid(value))
                dispatch(
                    PropostaActions.changeApLiberty({ cpfTerceiroIsValid: false })
                );
            else {
                dispatch(
                    PropostaActions.changeApLiberty({
                        cpfTerceiroIsValid: true,
                        loadingContas: true,
                    })
                );

                dispatch(PropostaActions.getContas({ param: value }));
            }
        }
    }

    function changeParcelamento(value) {
        valorParcela = (parseFloat(proposta.planoSelected.detalhesPlano[7].valor.replace("R$ ", "").replace(",", ".")) / value).toFixed(2);
        valorParcela = "R$ " + valorParcela.replace(".", ",");
        proposta.parcelamento = value > 0 ? value : 1;

        dispatch(
            PropostaActions.changeApLiberty({
                parcelamento: value,
                valorParcela: valorParcela
            })
        );
    }

    function onChangeNomeTerceiro(nomeTerceiro) {
        dispatch(
            PropostaActions.changeApLiberty({
                nomeTerceiro: nomeTerceiro,
            })
        );
    }

    function onChangeCpfTerceiro(value) {
        dispatch(
            PropostaActions.changeApLiberty({
                cpfTerceiro: value,
                contaDebitoId: undefined,
                agenciaDebitoId: undefined,
            })
        );

        dispatch(PropostaActions.changeContasDebito([]));

        if (value && value.length === 14) {
            if (!Validation.isCpfCnpjValid(value))
                dispatch(
                    PropostaActions.changeApLiberty({ cpfTerceiroIsValid: false })
                );
            else {
                dispatch(
                    PropostaActions.changeApLiberty({
                        cpfTerceiroIsValid: true,
                        loadingContas: true,
                    })
                );

                dispatch(PropostaActions.getContas({ param: value }));
            }
        }
    }

    function callback(key) {
        console.log(key);
    }

    return (
        <>
            <div className="actions-tabs" style={{ paddingBottom: 10 }}>
                <Button size="sm" onClick={onPrevius} with="link" type="primary">
                    <ArrowLeftOutlined />  Voltar
                </Button>
                {!contratoId && (

                    <Button
                        type="primary"
                        size="sm"
                        style={{ marginLeft: '.5rem' }}
                        onClick={onSalvar}
                    >
                        <SaveOutlined />  Salvar
                    </Button>
                )}
                {contratoId > 0 && (
                    <Button
                        type="primary"
                        size="sm"
                        style={{ marginLeft: '.5rem' }}
                        target="_blank"
                        href="/impressao-vida-ap"
                    >
                        <PrinterOutlined /> Imprimir
                    </Button>
                )}
                {isShowBtnEfetivar && (
                    <Popconfirm
                        placement="topLeft"
                        title="Deseja realmente efetivar esse contrato?"
                        onConfirm={onEfetivar}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            size="sm"
                            type="info"
                            style={{
                                marginLeft: '.5rem',
                            }}
                        >
                            <CheckOutlined />  Efetivar
                        </Button>
                    </Popconfirm>
                )}
            </div>


            <Spin spinning={loading} size="large">
                <Container>

                {contratoId > 0 && (
                    <Result
                        status="success"
                        title="Parabéns, produto contratado com sucesso!"
                        subTitle={`O número do contrato no Multiseguros é: ${contratoId} e você já pode consultá-lo lá.`}
                        extra={[
                            <Button
                                className="btn btn-xs-block mb-2 mb-sm-0"
                                type="primary"
                                href={`https://multiseguros${
                                    href.indexOf('hmo') !== -1 ? `hmo` : ``
                                }.brbseguros.com.br/#/contrato-vida/${contratoId}/138104/${
                                    cliente.clienteId
                                }/5185`}
                                target="_blank"
                                key="1"
                                >
                                Ir ao Multiseguros
                            </Button>,
                        ]}
                    />
                )}

                    <TitleSection title="Forma de Pagamento" />
                    <Row>
                        <Tabs defaultActiveKey="1" type='card' onChange={callback}>
                            <TabPane
                                tab={
                                    <span>
                                        <CreditCardOutlined />
                                Débito
                                </span>
                                }
                                key="1"
                            >
                                <Disable disable={!!contratoId}>
                                    <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Col xs={{ span: 12 }} sm={{ span: 4 }} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                            <Select
                                                defaultValue={parcelamentos[0].descricao}
                                                itens={parcelamentos}
                                                itemValue="id"
                                                renderText={parcelamentos =>
                                                    `${parcelamentos.descricao}`
                                                }
                                                value={proposta.parcelamento}
                                                onChange={value => changeParcelamento(value)}
                                                disabled={isCNP}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Col xs={{ span: 12 }} sm={{ span: 4 }} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                            <span style={{ color: '#1890FF', fontWeight: '700', textAlign: 'justify', fontSize: '40px' }}>{proposta?.valorParcela != null ? proposta.valorParcela : proposta.planoSelected.detalhesPlano[7].valor}</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Col xs={{ span: 12 }} sm={{ span: 4 }} style={{ paddingBottom: '0px', paddingTop: '0px' }} >
                                            <span style={{ color: '#777777', fontWeight: '500', textAlign: 'justify' }}>Total:{proposta.planoSelected.detalhesPlano[7].valor}</span>
                                        </Col>
                                    </Row>

                                    <hr style={{ background: "#1890FF" }}></hr>
                                    <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                            <Row>
                                                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                                    <div className="form-group">
                                                        <label className="w-100">Origem da conta</label>
                                                        <Radio.Group
                                                            buttonStyle="solid"
                                                            defaultValue={proposta.tipoConta}
                                                            options={listTipoConta}
                                                            onChange={changeOrigemConta}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                                    <div className="form-group">
                                                        {isCNP && (
                                                            <>
                                                                <Checkbox
                                                                    checked={isDebitoBrb}
                                                                    value={isDebitoBrb}
                                                                    onChange={value =>
                                                                        onChangePagamento('isDebitoBrb', value.target.checked)
                                                                    }
                                                                >
                                                                    Débito BRB
                                                                </Checkbox>
                                                                <Checkbox
                                                                    checked={isDebitoOutrosBancos}
                                                                    value={isDebitoOutrosBancos}
                                                                    onChange={value =>
                                                                        onChangePagamento(
                                                                        'isDebitoOutrosBancos',
                                                                        value.target.checked
                                                                        )
                                                                    }
                                                                >
                                                                    Débito outros bancos
                                                                </Checkbox>
                                                            </>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {proposta.tipoConta === 2 && (
                                                    <>
                                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                            <div className="form-group">
                                                                <label>CPF do terceiro</label>
                                                                <Input
                                                                    placeholder="Cpf/Cnpj"
                                                                    value={proposta.cpfTerceiro}
                                                                    onChange={onChangeCpfTerceiro}
                                                                    mask="cpfCnpj"
                                                                    maxLength={18}
                                                                />
                                                                <Spin
                                                                    style={{ marginLeft: -30, marginTop: 1 }}
                                                                    spinning={proposta.loadingContas}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                            <div className="form-group">
                                                                <label>Nome do terceiro</label>
                                                                <Input
                                                                    placeholder="Nome Completo"
                                                                    value={proposta.nomeTerceiro}
                                                                    onChange={onChangeNomeTerceiro}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </>

                                                )}
                                            </Row>
                                            <Row>
                                                {isDebitoBrb && proposta.tipoConta !== 3 && (
                                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ paddingTop: '15px' }}>
                                                        <div className="form-group">
                                                            <label>
                                                                Contas do Titular
                                                            </label>
                                                            <Select
                                                                defaultValue="Selecione"
                                                                itens={contasDebito}
                                                                itemValue="id"
                                                                renderText={conta =>
                                                                    `${conta.agenciaCodigo} - ${conta.contaNumero}`
                                                                }
                                                                value={getContaDebito()}
                                                                onChange={value => changeContaDebito(value)}
                                                                loading={loadingContas}
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                                {isDebitoBrb && (
                                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                        <div className="form-group">
                                                            <label className="w-100">Banco</label>
                                                            <Select
                                                                defaultValue="Selecione"
                                                                itens={bancos.filter(b => b.bancoId === '0070')}
                                                                itemValue="bancoId"
                                                                itemText="bancoDescricao"
                                                                value={agencia?.bancoId}
                                                                onChange={value => changeFields({ bancoId: value })}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                                {isDebitoOutrosBancos && (
                                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                        <div className="form-group">
                                                            <label className="w-100">Banco</label>
                                                            <Select
                                                                defaultValue="Selecione"
                                                                itens={Constants.OutrosBancos}
                                                                itemValue="bancoId"
                                                                itemText="bancoDescricao"
                                                                value={agencia?.bancoId}
                                                                onChange={value => onChangeBanco(value)}
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row>
                                                {isDebitoBrb && (
                                                    <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                        <div className="form-group">
                                                            <label>Agência</label>
                                                            <Select
                                                                defaultValue="Selecione"
                                                                itens={agenciasBrb}
                                                                itemValue="agenciaId"
                                                                value={agencia?.agenciaId}
                                                                itemText="agenciaCodigo"
                                                                itemTextSearch="agenciaDescricao"
                                                                renderText={ag =>
                                                                    `${ag.agenciaCodigo} - ${ag.agenciaDescricao}`
                                                                }
                                                                onChange={value => 
                                                                    onChangePagamento(
                                                                        'agencia',
                                                                        agenciasBrb.find(a => a.agenciaId === value)
                                                                    )}
                                                                disabled={proposta.tipoConta !== 3}
                                                                showSearch
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                                {isDebitoOutrosBancos && (
                                                    <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                        <div className="form-group">
                                                            <label>Agência</label>
                                                            <Select
                                                                defaultValue="Selecione"
                                                                itens={agenciasOutros}
                                                                itemValue="agenciaId"
                                                                value={agencia?.agenciaId}
                                                                itemText="agenciaCodigo"
                                                                itemTextSearch="agenciaDescricao"
                                                                renderText={ag =>
                                                                    `${ag.agenciaCodigo} - ${ag.agenciaDescricao}`
                                                                }
                                                                onChange={value => 
                                                                        onChangePagamento(
                                                                            'agencia',
                                                                            agencias.find(a => a.agenciaId === value)
                                                                        )}
                                                                showSearch
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                                {isDebitoBrb && (
                                                    <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                        <div className="form-group">
                                                            <label>Número Conta</label>
                                                            <Input
                                                                placeholder="Conta Corrente"
                                                                value={debitoContaNumeroConta}
                                                                onChange={value => setNumeroConta(value)}
                                                                onBlur={value =>
                                                                    onChangePagamento('debitoContaNumeroConta', value)
                                                                }
                                                                disabled={proposta.tipoConta !== 3}
                                                                accept="onlyNumbers"
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                                {isDebitoOutrosBancos && (
                                                    <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                        <div className="form-group">
                                                            <label>Número Conta</label>
                                                            <Input
                                                                placeholder="Conta Corrente"
                                                                value={debitoContaNumeroConta}
                                                                onChange={value => setNumeroConta(value)}
                                                                onBlur={value =>
                                                                    onChangePagamento('debitoContaNumeroConta', value)
                                                                }
                                                                accept="onlyNumbers"
                                                            />
                                                        </div>
                                                    </Col>
                                                )}
                                                <Col xs={{ span: 12 }} sm={{ span: 8 }} style={{ paddingRight: '5px', paddingTop: '15px' }}>
                                                    <div className="form-group">
                                                        <label className="w-100">Dia para débito das parcelas</label>
                                                        <Select
                                                            defaultValue="Selecione"
                                                            style={{ width: '100%' }}
                                                            itens={listDiaDebito}
                                                            value={proposta.diaDebito}
                                                            renderText={item => item}
                                                            onChange={value => changeFields({ diaDebito: value })}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                            <h6>AUTORIZAÇÃO PARA ENVIO DE MATERIAIS DE DIVULGAÇÃO ORIENTAÇÕES AO SEGURADO PARA ACESSAR A DOCUMENTAÇÃO (PDF DA PROPOSTA, APÓLICE E CONDIÇÕES GERAIS DO SEGURO) TELEFONE CENTRAL DE ATENDIMENTO</h6>
                                            <br></br>
                                            <h6>INFORMAÇÕES DO PRODUTO</h6>
                                            <p>Liberty Seguros S/A - CNPJ 61550141/0001-72 - Codigo SUSEP 518-5. Processo SUSEP nº 15414 901059/2016-06. 15414.901625/2017-52.
                                            O registro deste plano na SUSEP não implica, por parte da autarquia incentivo ou recomendação à sua comercialização, SAC 0800 726 1981.
                                            </p>
                                            <p><strong>Ouvidoria:</strong> acesse o site www.libertyseguros.com.br onde é possível postar seu recurso.</p>
                                            <p>Se preferir o recurso também poderá ser feito pelo e-mail ouvidoria@libertyseguros.com.br ou pelo telefone 0800-740-3994.</p>
                                            <p>Se houver informações do Sorteio incluir: *Titulo de pagamento único de modalidade incentivo emitido pela ICATU CAPTALIZAÇÂO S/A CNPJ/MF nº 74.267.170/0001-73, processo SUSEP nº 15414,900531/2019-54. Ouvidoria Icatu Seguros 0800 286 0047.</p>
                                            <p>Para mais informações consulte as condições gerais, o regulamento e as características essenciais em www.libertyseguros.com.br</p>
                                        </Col>
                                    </Row>
                                </Disable>
                            </TabPane>
                        </Tabs>
                    </Row>
                </Container>
            </Spin>
        </>

    )
}