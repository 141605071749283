import { createActions, handleActions } from 'redux-actions';

export const types = {
  LOGIN: 'LOGIN',
  CALLBACK: 'CALLBACK',
  NOTIFICATION: 'NOTIFICATION',
  TOKEN_WIZ: 'TOKEN_WIZ',
  SAVE_USER: 'SAVE_USER',
  LOGOUT: 'LOGOUT',
  FAILED_AUTH: 'FAILED_AUTH',
};

export const { login, callback, notification, tokenWiz, saveUser, logout, failedAuth } = createActions(
  types.LOGIN,
  types.CALLBACK,
  types.NOTIFICATION,
  types.TOKEN_WIZ,
  types.SAVE_USER,
  types.LOGOUT,
  types.FAILED_AUTH,
);

const defaultState = {
  user: null,
  token: null,
  tokenSSOWiz: null,
  keySSOWiz: null,
  loading: false,
};

export default handleActions(
  {
    [login]: state => ({ ...state, loading: true }),
    [callback]: state => ({ ...state, loading: true }),
    [notification]: state => ({ ...state, loading: true }),
    [tokenWiz]: state => ({ ...state, loading: true }),
    [saveUser]: (state, action) => action.payload,
    [logout]: () => defaultState,
    [failedAuth]: state => ({ ...state, loading: false }),
  },
  defaultState
);
