import React from 'react';
import { Alert, Button } from 'antd';

type InfosProps = {
  messages: String[],
  title: string,
  onLimparMensagens: Function
};

export default function Infos({ messages, title, onLimparMensagens }: InfosProps) {
  function limparMensagens(event) {
    if(onLimparMensagens) onLimparMensagens();
  }
  
  function Infos() {
    return (
      <ul>
        {messages.map((e,index) => (
          <li key={index}>{e}</li>
        ))}
      </ul>
    );
  }

  if (messages && messages.length > 0) {
    return (
      <>
        <div style={{"marginBottom": "10px"}} data-show="true" class="ant-alert ant-alert-info ant-alert-with-description ant-alert-no-icon" role="alert">
          <div class="ant-alert-content">
            <div class="ant-alert-message">
              { title }
            </div>
            <div class="ant-alert-description">
              { Infos() }
            </div>
          </div>
          <Button size="small" type="link" className={"ant-alert-close-icon"} onClick={limparMensagens}>
            X
          </Button>
        </div>
      </>
      // <Alert
      //   message={title}
      //   description={<Infos />}
      //   type="info"
      //   showIcon
      //   onClose={abc}
      //   closable={true}
      //   style={{ marginBottom: 12 }}
      // >
      //   <Button size="small" type="text">
      //       UNDO
      //     </Button>
      // </Alert>

      
    );
  }

  return null;
}
