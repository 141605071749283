import { Col, Icon, message, Radio, Row, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import Button from "antd-button-color";
import {
    Container,
    DatePicker,
    Error,
    FieldsProducao,
    Input,
    Select,
    TitleSection,
    Disable,
} from '~/components';
import * as PropostaActions from '~/store/ducks/proposta';
import { Constants, Functions, Validation } from '~/utils';
import { ProducaoValidation, PropostaValidation } from '~/yup';

type BeneficiarioProp = {
    onNext: Function,
    onPrevius: Function,
    produtoId: Number,
};

export default function Beneficiario({
    onNext,
    onPrevius,
    produtoId,
}: BeneficiarioProp) {

    const dispatch = useDispatch();


    const {
        proposta: {
            apLiberty: proposta,
            contasDebito,
            loading,
            propostaId,
            contratoId,
            producao,
        },
        dominio: { bancos, agenciasBrb, grausRelacionamento },
        cliente: { cliente },
        plano: { planos, loading: loadingPlanos },
    } = useSelector(state => {
        const apLiberty = state.proposta.apLiberty;
        return state;
    });

    const [erros, setErros] = useState([]);
    const [planoSelectedKey, setPlanoSelectedKey] = useState([]);

    function changeFields(obj) {
        dispatch(PropostaActions.changeApLiberty(obj));
    }


    function deleteBeneficiario() {
        const ben = proposta.beneficiarios;
        ben.pop();
        changeFields({
            beneficiarios: ben,
        });
    }

    function changeBeneficiario(field, value, index) {
        const newArray = proposta.beneficiarios.map((beneficiario, i) => {
            if (i === index) {
                beneficiario[field] = value;
            }

            return beneficiario;
        });

        changeFields({ beneficiarios: newArray });
    }

    function newBeneficiario() {
        changeFields({
            beneficiarios: [
                ...proposta.beneficiarios,
                ...[{ nome: '', cpf: '', grauRelacionamento: '', percentual: '' }],
            ],
        });
    }

    async function _onNext() {
        setErros([]);
        // Valid data
        const response = await validBeneficiario();
        // Check is valid
        if (response.ok) onNext();
        else setErros(response);
    }

    async function validBeneficiario() {
        let response = await validaObjeto(PropostaValidation.ApLibert, proposta);
        if (!response.ok) return response;
        return { ok: true };
    }

    function validaObjeto(validation, obj) {
        return validation
            .validate(obj, { abortEarly: false })
            .then(() => ({ ok: true }))
            .catch(error => error.errors);
    }


    return (

        <>
            <div className="actions-tabs" style={{ paddingBottom: 10 }}>
                <Button size="sm" onClick={onPrevius} with="link" type="primary">
                    <ArrowLeftOutlined />  Voltar
        </Button>
                <Button
                    type="primary"
                    size="sm"
                    style={{ marginLeft: '.5rem' }}
                    onClick={_onNext}
                >
                    Próximo <ArrowRightOutlined />
                </Button>
            </div>

            <Container>
                <TitleSection title="Benficiários" />

                <p>A soma das participações dos beneficiários deve ser 100%.</p>

                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 12 }} sm={{ span: 9 }}>
                        Nome(s) do(s) Beneficiário(s)
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                        Cpf
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                        Grau de parentesco
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                        Participação
                    </Col>
                </Row>

                {proposta.beneficiarios.map((beneficiario, index) => {
                    return (
                        <Disable disable={!!contratoId}>
                            <Row gutter={[16, 16]} key={index.toString()}>
                                <Col xs={{ span: 24 }} sm={{ span: 9 }}>
                                    <Input
                                        placeholder="Nome do Herdeiro(a)"
                                        value={beneficiario.nome}
                                        onChange={value =>
                                            changeBeneficiario('nome', value, index)
                                        }
                                    />
                                </Col>
                                <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                                    <Input
                                        placeholder="000.000.000-00"
                                        value={beneficiario.cpf}
                                        mask="cpfCnpj"
                                        maxLength={14}
                                        onChange={value =>
                                            changeBeneficiario('cpf', value, index)
                                        }
                                    />
                                </Col>

                                <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                                    <div className="form-group">
                                        <Select
                                            showSearch
                                            defaultValue="Selecione"
                                            itens={Constants.ApLiberty.listParentesco}
                                            itemValue="grauRelacionamentoId"
                                            itemText="grauRelacionamentoDescricao"
                                            value={beneficiario.grauRelacionamento}
                                            onChange={value =>
                                                changeBeneficiario('grauRelacionamento', value, index)
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                                    <Input
                                        placeholder="Em porcentagem"
                                        value={beneficiario.percentual}
                                        onChange={value =>
                                            changeBeneficiario('percentual', value, index)
                                        }
                                        onlyNumbers
                                        suffix="%"
                                        maxLength={5}
                                    />
                                </Col>
                            </Row>
                        </Disable>
                    );
                })}

                <Disable disable={!!contratoId}>
                    <Row>
                        <Col>
                            <Button
                                className="btn btn-xs-block"
                                type="primary"
                                onClick={newBeneficiario}
                                style={{ marginRight: 20 }}
                            >
                                <Icon type="plus" />
                            Incluir Beneficiário
                            </Button>

                            <Button
                                className="btn  btn-xs-block mt-2 mt-sm-0"
                                type="danger"
                                onClick={deleteBeneficiario}

                            >
                                <Icon type="delete" />
                            Excluir último Beneficiário
                            </Button>
                        </Col>
                    </Row>
                </Disable>
            </Container>
        </>
    )
}