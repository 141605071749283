import apisauce from 'apisauce';

import { store } from '~/store';

const href = window.location.href;

let baseURL = process.env.REACT_APP_BASE_URL;

if (href.indexOf(".brb.com.br") !== -1) {
  baseURL = process.env.REACT_APP_BASE_URL_BRB;
}

const api = () => {
  let headers = null;

  const { token } = store.getState().auth;

  if (token) headers = { Authorization: `Bearer ${token.accessToken}` };

  return apisauce.create({
    baseURL,
    headers,
    timeout: 300000,
  });
};

export default api;
