import { createActions, handleActions } from 'redux-actions';

export const types = {
  GET_REGULAMENTO: 'GET_REGULAMENTO',
  GET_SITUACAO_AGENCIA: 'GET_SITUACAO_AGENCIA',
  FAILED_JOGA_JUNTO: 'FAILED_JOGA_JUNTO',
};

export const {
  getRegulamento,
  getSituacaoAgencia,
  failedJogaJunto
} = createActions(
  types.GET_REGULAMENTO,
  types.GET_SITUACAO_AGENCIA,
  types.FAILED_JOGA_JUNTO,
);

const defaultState = {
  loading: false,
};

export default handleActions(
  {
    [failedJogaJunto]: state => ({ ...state, loading: false }),
  },
  defaultState
);
