import { createActions, handleActions } from 'redux-actions';

export const types = {
  GET_ORCADO_AGENCIA_MES: 'GET_ORCADO_AGENCIA_MES',
  GET_REALIZADO_AGENCIA_MES: 'GET_REALIZADO_AGENCIA_MES',
  SAVE_ORCADO_AGENCIA_MES: 'SAVE_ORCADO_AGENCIA_MES',
  SAVE_REALIZADO_AGENCIA_MES: 'SAVE_REALIZADO_AGENCIA_MES',
  SET_STATE_DEFAULT: 'SET_STATE_DEFAULT',
  GET_ORCADO_OPERADOR_MES: 'GET_ORCADO_OPERADOR_MES',
  GET_REALIZADO_OPERADOR_MES: 'GET_REALIZADO_OPERADOR_MES',
  SAVE_ORCADO_OPERADOR_MES: 'SAVE_ORCADO_OPERADOR_MES',
  SAVE_REALIZADO_OPERADOR_MES: 'SAVE_REALIZADO_OPERADOR_MES',
  GET_ORCADO_AGENCIA_PERIODO: 'GET_ORCADO_AGENCIA_PERIODO',
  GET_REALIZADO_AGENCIA_PERIODO: 'GET_REALIZADO_AGENCIA_PERIODO',
  SAVE_ORCADO_AGENCIA_PERIODO: 'SAVE_ORCADO_AGENCIA_PERIODO',
  SAVE_REALIZADO_AGENCIA_PERIODO: 'SAVE_REALIZADO_AGENCIA_PERIODO',
  GET_ORCADO_OPERADOR_PERIODO: 'GET_ORCADO_OPERADOR_PERIODO',
  GET_REALIZADO_OPERADOR_PERIODO: 'GET_REALIZADO_OPERADOR_PERIODO',
  SAVE_ORCADO_OPERADOR_PERIODO: 'SAVE_ORCADO_OPERADOR_PERIODO',
  SAVE_REALIZADO_OPERADOR_PERIODO: 'SAVE_REALIZADO_OPERADOR_PERIODO',
  FALHA: 'FALHA',
};

export const {
  getOrcadoAgenciaMes,
  getRealizadoAgenciaMes,
  saveOrcadoAgenciaMes,
  saveRealizadoAgenciaMes,
  setStateDefault,
  getOrcadoOperadorMes,
  getRealizadoOperadorMes,
  saveOrcadoOperadorMes,
  saveRealizadoOperadorMes,
  getOrcadoAgenciaPeriodo,
  getRealizadoAgenciaPeriodo,
  saveOrcadoAgenciaPeriodo,
  saveRealizadoAgenciaPeriodo,
  getOrcadoOperadorPeriodo,
  getRealizadoOperadorPeriodo,
  saveOrcadoOperadorPeriodo,
  saveRealizadoOperadorPeriodo,
  falha
} = createActions(
  types.GET_ORCADO_AGENCIA_MES,
  types.GET_REALIZADO_AGENCIA_MES,
  types.SAVE_ORCADO_AGENCIA_MES,
  types.SAVE_REALIZADO_AGENCIA_MES,
  types.SET_STATE_DEFAULT,
  types.GET_ORCADO_OPERADOR_MES,
  types.GET_REALIZADO_OPERADOR_MES,
  types.SAVE_ORCADO_OPERADOR_MES,
  types.SAVE_REALIZADO_OPERADOR_MES,
  types.GET_ORCADO_AGENCIA_PERIODO,
  types.GET_REALIZADO_AGENCIA_PERIODO,
  types.SAVE_ORCADO_AGENCIA_PERIODO,
  types.SAVE_REALIZADO_AGENCIA_PERIODO,
  types.GET_ORCADO_OPERADOR_PERIODO,
  types.GET_REALIZADO_OPERADOR_PERIODO,
  types.SAVE_ORCADO_OPERADOR_PERIODO,
  types.SAVE_REALIZADO_OPERADOR_PERIODO,
  types.FALHA,
);

const defaultState = {
  loading: [],
  orcadoAgenciaMes: [],
  realizadoAgenciaMes: []
};

export default handleActions(
  {
    [getOrcadoAgenciaMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getRealizadoAgenciaMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return  {
        ...state
      } 
    },
    [getOrcadoOperadorMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getRealizadoOperadorMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return  {
        ...state
      } 
    },
    [getOrcadoAgenciaPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getRealizadoAgenciaPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return  {
        ...state
      } 
    },
    [getOrcadoOperadorPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return {
        ...state
      }
    },
    [getRealizadoOperadorPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.push('');
      return  {
        ...state
      } 
    },
    [saveOrcadoAgenciaMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        orcadoAgenciaMes: [...action.payload]
      })
    },
    [saveRealizadoAgenciaMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        realizadoAgenciaMes: [...action.payload],
      })
    },
    [saveOrcadoOperadorMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        orcadoOperadorMes: [...action.payload]
      })
    },
    [saveRealizadoOperadorMes]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        realizadoOperadorMes: [...action.payload],
      })
    },
    [setStateDefault]: (state) => {
      return ({
        ...defaultState,
        loading: []
      })
    },
    [saveOrcadoOperadorPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        orcadoOperadorPeriodo: [...action.payload],
      })
    },
    [saveRealizadoOperadorPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        realizadoOperadorPeriodo: [...action.payload],
      })
    },
    [saveOrcadoAgenciaPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        orcadoAgenciaPeriodo: [...action.payload],
      })
    },
    [saveRealizadoAgenciaPeriodo]: (state, action) => {
      state.loading = state.loading  || [];
      state.loading.pop();
      return ({
        ...state,
        realizadoAgenciaPeriodo: [...action.payload],
      })
    },
    [falha]: state => ({ ...state, loading: [] }),
    
  },
  defaultState
);
