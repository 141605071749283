/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { Row, Col, Radio, Checkbox } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { TitleSection, Select, Input } from '~/components';

import { Mask, Functions } from '~/utils';

import * as PropostaActions from '~/store/ducks/proposta';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const formasPagamento = [
  { text: 'À vista', value: 1 },
  { text: 'Parcelado com entrada', value: 2 },
  { text: 'Parcelado sem entrada', value: 3 },
];

const qtdParcelas = [
  { value: 1, parcela: 2 },
  { value: 2, parcela: 3 },
  { value: 3, parcela: 4 },
  { value: 4, parcela: 5 },
  { value: 5, parcela: 6 },
  { value: 6, parcela: 7 },
  { value: 7, parcela: 8 },
  { value: 8, parcela: 9 },
  { value: 9, parcela: 10 },
];

export default function Pagamento() {
  // Component state
  const [label, setLabel] = useState('');
  const [propostaEletronicaNossoNumero, setNossoNumero] = useState();
  const [isBoleto, setIsBoleto] = useState();
  const [debitoContaNumeroConta, setNumeroConta] = useState();

  // Redux state
  const {
    planoSelected,
    pagamento,
    pagamento: {
      isDebitoAutomatico,
      formaPagamento,
      contratoDREQtdParcela,
      contratoDREDiaVencimentoDemais,
      agencia,
    },
  } = useSelector(({ proposta }) => proposta.dreMitsuiEmp);

  const { agenciasBrb } = useSelector(({ dominio }) => dominio);

  const {
    cliente: { cliente },
    proposta: { contasDebito },
  } = useSelector(state => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (planoSelected) {
      if (formaPagamento === 1) {
        const valorVista = Functions.getMoney(
          planoSelected.mtrPropostaEletronicaPlanoPremioTotal
        );

        setLabel(
          `À Vista ${planoSelected.mtrPropostaEletronicaPlanoPremioTotal}`
        );

        onChangePagamento('mtrContratoDRE1Parcela', valorVista);
      } else if (formaPagamento === 2 || formaPagamento === 3) {
        const demaisParcelas =
          Functions.getMoney(
            planoSelected.mtrPropostaEletronicaPlanoPremioTotal
          ) /
          (contratoDREQtdParcela + 1);

        const num = formaPagamento === 2 ? 1 : 0;

        setLabel(
          `${num} + ${contratoDREQtdParcela +
            (num === 0 ? 1 : 0)} Parcela(s) SEM JUROS de ${Mask.maskMoney(
            demaisParcelas
          )}`
        );

        onChangePagamento('mtrContratoDRE1Parcela', demaisParcelas);
      }
    }
    setNossoNumero(pagamento.propostaEletronicaNossoNumero);
    setNumeroConta(pagamento.debitoContaNumeroConta);
    setIsBoleto(pagamento.isBoleto);
  }, [planoSelected, formaPagamento, contratoDREQtdParcela]);

  useEffect(() => {
    dispatch(PropostaActions.getContas({ param: cliente?.clienteCpfCnpj }));
  }, []);

  function onChangeFormaPagamento({ target: { value } }) {
    onChangePagamento('formaPagamento', value);
  }

  function changeContaDebito(contaId) {
    const conta = contasDebito.find(c => c.id === contaId);
    if (conta) {
      const newValue = {
        ...pagamento,
        debitoContaNumeroConta: conta.contaNumero,
        agencia: agenciasBrb.find(a => a.agenciaId === conta.agenciaId),
      };
      setNumeroConta(conta.contaNumero);
      dispatch(
        PropostaActions.changeDreMitsuiEmp({
          field: 'pagamento',
          value: newValue,
        })
      );
    }
  }

  function getContaDebito() {
    const conta = contasDebito?.find(
      c =>
        c.agenciaId === agencia?.agenciaId &&
        c.contaNumero === debitoContaNumeroConta
    );
    if (conta) return conta.id;

    return null;
  }

  function getMelhorDiaPagamento() {
    const list = [];
    for (let i = 1; i <= 31; i++) {
      list.push({ text: i, value: i });
    }

    return list;
  }

  function onChangePagamento(field, value) {
    // Valor default
    let newValue = { ...pagamento, [field]: value };
    // Se alterou a forma de pagamento
    if (field === 'formaPagamento') {
      // Se for a vista limpa a parcelas e demais dias
      if (value === 1) {
        newValue = {
          ...pagamento,
          formaPagamento: value,
          contratoDREQtdParcela: 1,
          contratoDREDiaVencimentoDemais: null,
        };
        // Se for parcelado sem entrada limpa o nosso numero
      } else if (value === 3) {
        newValue = {
          ...pagamento,
          formaPagamento: value,
          propostaEletronicaNossoNumero: null,
          isBoleto: false,
          isDebitoAutomatico: true,
        };
      }
    }
    if (field === 'isBoleto') {
      newValue = {
        ...pagamento,
        isBoleto: value,
        isDebitoAutomatico: !value,
      };
    } else if (field === 'isDebitoAutomatico') {
      newValue = {
        ...pagamento,
        isDebitoAutomatico: value,
        isBoleto: !value,
      };
      if (value) setNossoNumero(null);
    }

    // Altera o pagamento no redux
    dispatch(
      PropostaActions.changeDreMitsuiEmp({
        field: 'pagamento',
        value: newValue,
      })
    );
  }

  return (
    <>
      <TitleSection title="Forma de pagamento" />

      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <div className="form-group">
            <Radio.Group
              onChange={onChangeFormaPagamento}
              value={formaPagamento}
            >
              {formasPagamento.map(f => (
                <Radio key={f.value} style={radioStyle} value={f.value}>
                  {f.text}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <label style={{ fontSize: 12 }}>{label}</label>
        </Col>
        <Col span={6}>
          <div className="form-group">
            <Checkbox
              checked={isDebitoAutomatico}
              value={isDebitoAutomatico}
              disabled={formaPagamento === 3}
              onChange={value =>
                onChangePagamento('isDebitoAutomatico', value.target.checked)
              }
            >
              {formaPagamento === 1 ? 'Débito Automático' : ''}
              {formaPagamento === 2 ? 'Entrada Débito' : ''}
              {formaPagamento === 3 ? 'Débito' : ''}
            </Checkbox>
            {(formaPagamento === 1 || formaPagamento === 2) && (
            <Checkbox
              checked={pagamento.isBoleto}
              onChange={value =>
                onChangePagamento('isBoleto', value.target.checked)
              }
            >
              {formaPagamento === 1 ? 'Boleto' : ''}
              {formaPagamento === 2 ? 'Entrada Boleto' : ''}
            </Checkbox>
            )}
          </div>
        </Col>
        {formaPagamento !== 3 && !pagamento.isDebitoAutomatico && (
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            <div className="form-group">
              <label>Nosso Número</label>
              <Input
                placeholder="Nosso número"
                value={propostaEletronicaNossoNumero}
                onChange={value => setNossoNumero(value)}
                onBlur={value =>
                  onChangePagamento('propostaEletronicaNossoNumero', value)
                }
              />
            </div>
          </Col>
        )}
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        {formaPagamento !== 1 && (
          <>
            <Col span={6}>
              <div className="form-group">
                <label>Quantidade de Parcelas</label>
                <Select
                  itens={qtdParcelas}
                  itemText="parcela"
                  itemValue="value"
                  value={contratoDREQtdParcela}
                  onChange={value =>
                    onChangePagamento('contratoDREQtdParcela', value)
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div className="form-group">
                {pagamento.isBoleto && <label>Melhor dia de pagamento</label>}
                {!pagamento.isBoleto && (
                  <label>
                    <label>Melhor dia para débito</label>
                  </label>
                )}
                <Select
                  itens={getMelhorDiaPagamento()}
                  itemText="text"
                  itemValue="value"
                  value={contratoDREDiaVencimentoDemais}
                  onChange={value =>
                    onChangePagamento('contratoDREDiaVencimentoDemais', value)
                  }
                />
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={{ span: 12 }} sm={{ span: 8 }}>
          <div className="form-group">
            <label>Contas do Cliente</label>
            <Select
              defaultValue="Selecione"
              itens={contasDebito}
              itemValue="id"
              renderText={conta =>
                `${conta.agenciaCodigo} - ${conta.contaNumero}`
              }
              value={getContaDebito()}
              onChange={value => changeContaDebito(value)}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <div className="form-group">
            <label>Agência</label>
            <Select
              itens={agenciasBrb}
              itemValue="agenciaId"
              itemText="agenciaDescricao"
              itemTextSearch="agenciaCodigo"
              renderText={item =>
                `${item.agenciaCodigo} - ${item.agenciaDescricao}`
              }
              showSearch
              value={agencia?.agenciaId}
              onChange={value =>
                onChangePagamento(
                  'agencia',
                  agenciasBrb.find(a => a.agenciaId === value)
                )
              }
              disabled={cliente?.clienteCpfCnpj.length < 14}
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <div className="form-group">
            <label>Número Conta</label>
            <Input
              placeholder="Número Conta"
              value={debitoContaNumeroConta}
              onChange={value => setNumeroConta(value)}
              onBlur={value =>
                onChangePagamento('debitoContaNumeroConta', value)
              }
              disabled={cliente?.clienteCpfCnpj.length < 14}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
