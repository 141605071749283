import { call, all, put, takeEvery } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse } from '~/api/utils';
import * as PlanosApi from '~/api/plano.api';
import * as PlanoActions from '~/store/ducks/plano';

function* getPlanosDreMitsui(action) {
  const { param, callback } = action.payload;
  const { seguradoraId, produtoId, tipoImovel } = param;
  const response = getResponse(
    yield call(
      PlanosApi.getPlanosDreMitsui,
      seguradoraId,
      produtoId,
      tipoImovel
    )
  );
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  yield put(PlanoActions.savePlanos(data));

  if (callback) callback();
}

function* getPlanosVpp(action) {
  const {
    param: { idade, tipoPlano, tipoPagamento },
  } = action.payload;

  // Call api
  const response = getResponse(
    yield call(PlanosApi.getPlanosVpp, idade, tipoPlano, tipoPagamento)
  );
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  if (data && data.length > 0) {
    const dataOrder = data.sort((a, b) => {
      return a.mtrGarantiaMorte > b.mtrGarantiaMorte ? 1 : -1;
    });

    yield put(PlanoActions.savePlanos(dataOrder));
  } else yield put(PlanoActions.savePlanos(data));
}

function* getPlanosBrbMaster(action) {
  const {
    param: {
      nome,
      cpf,
      dataNascimento,
      profissaoCbo,
      renda,
      sexoId,
      uf,
      periodicidade,
    },
  } = action.payload;

  // Call api
  const response = getResponse(
    yield call(
      PlanosApi.getPlanosBrbMaster,
      nome,
      cpf,
      dataNascimento,
      profissaoCbo,
      renda,
      sexoId,
      uf,
      periodicidade
    )
  );
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  yield put(PlanoActions.savePlanos(data));
}

function* fail(message) {
  yield put(PlanoActions.failedPlanos());
  toast.error(message);
}

export default all([
  takeEvery(PlanoActions.types.GET_PLANOS, getPlanosDreMitsui),
  takeEvery(PlanoActions.types.GET_PLANOS_VPP, getPlanosVpp),
  takeEvery(PlanoActions.types.GET_PLANOS_BRB_MASTER, getPlanosBrbMaster),
]);
