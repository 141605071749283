import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';

import { store } from '~/store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  blank,
  ...rest
}) {
  const { token, usuario } = store.getState().auth;
  
  if (!token && isPrivate) {
    return <Redirect to="/" />;
  }
  
  if (token && rest.path === '/dashboard' && !usuario.exibirDashboardPpe) {
    return <Redirect to="/proposta/new" />;
  }

  if (token && !isPrivate) {
    return <Redirect to="/proposta/new" />;
  }

  const Layout = token ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props =>
        blank ? (
          <Component {...props} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  blank: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  blank: false,
};
