import React from 'react';
import Button from "antd-button-color";
import ModalInfo from 'components/modal/index.js';

import '../card-plano/style.css';

type CardPlanoProp = {
    plano: {},
    onChangePlano: Function,
    isSelected: Boolean,
    isCNP: Boolean,
}

export default function CardPlano({ plano, onChangePlano, isSelected, isCNP }: CardPlanoProp) {
    function onClick() {
        if (onChangePlano)
            onChangePlano(plano)
    }


    return (
        <div className='wraper-plain'>
            <input type='radio' id={plano.nome} name='plains' value={plano.nome} checked={isSelected} ></input>
            <div className='card-plain'>
                <div className='header-plain'>
                    <h3>{plano.nome}</h3>
                    <ModalInfo title="Mais informações" info={plano.info}></ModalInfo>
                </div>
                <div className='body-plain'>
                    <ul className="list-desc">
                        {!isCNP && (
                            <>
                                <li>{plano.detalhesPlano[0].descricao}:<span>{plano.detalhesPlano[0].valor}</span></li>
                                <li>{plano.detalhesPlano[1].descricao}:<span>{plano.detalhesPlano[1].valor}</span></li>
                                <li>{plano.detalhesPlano[2].descricao}:<span>{plano.detalhesPlano[2].valor}</span></li>
                                <li>{plano.detalhesPlano[3].descricao}:<span>{plano.detalhesPlano[3].valor}</span></li>
                                <li>{plano.detalhesPlano[4].descricao}:<span>{plano.detalhesPlano[4].valor}</span></li>
                                <li>{plano.detalhesPlano[5].descricao}:<span>{plano.detalhesPlano[5].valor}<small>(Bruto)</small></span></li>
                                <li>{plano.detalhesPlano[6].descricao}:<span>{plano.detalhesPlano[6].valor}</span></li>
                                <li>{plano.detalhesPlano[7].descricao}:<span className='coust'>{plano.detalhesPlano[7].valor}</span></li>
                                <li>{plano.detalhesPlano[8].descricao}:<span>{plano.detalhesPlano[8].valor}</span></li>
                            </>
                        )}

                        {isCNP && (
                            <>
                                <li>{plano.detalhesPlano[0].descricao}:<span>{plano.detalhesPlano[0].valor}</span></li>
                                <li>{plano.detalhesPlano[3].descricao}:<span>{plano.detalhesPlano[3].valor}</span></li>
                                <li>{plano.detalhesPlano[5].descricao}:<span>{plano.detalhesPlano[5].valor}<small>(Bruto)</small></span></li>
                                <li>{plano.detalhesPlano[6].descricao}:<span>{plano.detalhesPlano[6].valor}</span></li>
                                <li>{plano.detalhesPlano[7].descricao}:<span className='coust'>{plano.detalhesPlano[7].valor}</span></li>
                            </>
                        )}
                    </ul>
                </div>
                <div className='footer-plain' onClick={onClick}>
                    <label for={plano.nome} className='btn-plain'>Quero esse</label>
                    <i className="fas fa-check" for={plano.nome}></i>
                </div>
            </div>
        </div>

    )
}