import { Col, Divider, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';

const { Column } = Table;

type PrestamistaTabProp = {
  data?: Object
}

function ordenarPorPropriedade(lista, posicaoOrdenar = null) {
  return lista.sort((a, b) => a[posicaoOrdenar] - b[posicaoOrdenar]);
}

export default function PrestamistaDetalhe(param: PrestamistaTabProp) {

  const contrato = param?.data || {};

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        Dados do Contrato
      </Divider>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Contrato">Contrato</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Contrato">{ contrato?.contratoNumeroControle }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Produto">Produto</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Produto">{ contrato?.produtoDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Seguradora">Seguradora</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Seguradora">{ contrato?.seguradoraDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 4 }}>
          <Row>
            <Col span={24}>
              <strong title="Situação">Situação</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Situação">
                { contrato?.contratoStatusDescricao }                
              </span>
              
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Prêmio">Prêmio</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Prêmio">{ util.Functions.formatarMoeda(contrato?.valorPremio) }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Capital segurado">Capital segurado</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Capital segurado">{ util.Functions.formatarMoeda(contrato?.valorPrincipalSegurado) }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Vigência">Vigência</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Vigência">
                { 
                  `DE ${moment(contrato?.contratoPrestamistaDataInicio).format('DD/MM/YYYY')}
                  A ${ moment(contrato?.contratoPrestamistaDataFim).format('DD/MM/YYYY') }` 

                }                
              </span>
              
            </Col>
          </Row>
        </Col>
        
      </Row>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Motivo Cancelamento">Motivo Cancelamento</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Motivo Cancelamento">{ contrato?.contratoCanceladoMotivoDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <strong title="Valor Estorno">Valor Estorno</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Valor Estorno">{ util.Functions.formatarMoeda(contrato?.mtrContratoPrestamistaEstorno) }</span>
            </Col>
          </Row>
        </Col>
        
      </Row>
      
    </>
  );
};