import React, { useState, useEffect } from 'react';
import { Row, Col, Input as InputAnt, Button, Radio, Alert, Checkbox, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as ClienteActions from '~/store/ducks/cliente';
import * as LibertyResidencialPersonalizavelActions from '~/store/ducks/liberty-residencial-personalizavel';
import * as PropostaActions from '~/store/ducks/proposta';
import { TitleSection, Input, Select, DatePicker } from '~/components';
import { Functions, Mask, Constants } from '~/utils';
import { Container } from './style';
import { Title } from '../title-section/style';
import useIsAgenciaDigital from '~/hooks/useIsAgenciaDigital';

type ProponenteProps = {
  onChangeCliente: Function,
  produtoId: Boolean,
  ehPessoaFisica: Boolean
};

const orgaos = [
  { text: 'DETRAN', value: 'DETRAN' },
  { text: 'OAB', value: 'OAB' },
];

export default function Proponente({
  onChangeCliente,
  produtoId,
  ehPessoaFisica
}: ProponenteProps) {
  const productIsVPP = produtoId === Constants.Produtos.VidaPremiadoPlus;
  const productIsAP = produtoId === Constants.Produtos.AcidentesPessoaisLiberty;
  const productIsBrbMaster = produtoId === Constants.Produtos.BrbMasterPremiado;
  const productIsDreMitsui = produtoId === Constants.Produtos.DreMitsui;
  const productIsCAP = Functions.isCap(produtoId);
  const produtoIsLibertyResidencialPersonalizavel = produtoId === Constants.Produtos.LibertyResidencialPersonalizavel

  const { listTipoPlano } = Constants.VidaPremiadoPlus;

  const [isAgenciaDigital] = useIsAgenciaDigital();

  const { cliente: clienteRedux } = useSelector(state => state.cliente);
  const { vppAmerican } = useSelector(state => state.proposta);
  const { segurado } = useSelector(state => state.libertyResidencialPersonalizavel)
  const { listaNomesDominios, listaNomesDominios: listaNomesDominiosResidencialLibertyPersonalizavel } = useSelector(state => state.libertyResidencialPersonalizavel)

  const {
    generos,
    profissoes,
    profissoesLiberty,
    atividadesPJIcatu,
    profissoesIcatu,
    // faixaRenda,
    profissoesMongeral,
    ufs,
    cargos,
  } = useSelector(({ dominio }) => dominio);

  let { estadosCivis, naturalidade } = useSelector(({ dominio }) => dominio);
  if (productIsBrbMaster) {
    estadosCivis = estadosCivis.filter(
      e => e.estadoCivilId !== -2 && e.estadoCivilId !== 6
    );
    naturalidade = naturalidade.filter(e => e.naturalidadeId !== -2);
  }
  const [cliente, setCliente] = useState({});
  const [rendaMensal, setRendaMensal] = useState();
  const [profissaoIcatuId, setProfissaoIcatuId] = useState();
  const [atividadesPJIcatuId, setAtividadesPJIcatuId] = useState();
  const [segmentacaoClienteCap, setSegmentacaoClienteCap] = useState();
  const [representanteNome, setRepresentanteNome] = useState();
  const [representanteCPF, setRepresentanteCPF] = useState();
  const [seguradoLibertyPersonalizavel, setSeguradoLibertyPersonalizavel] = useState({});
  const [ehPpe, setEhPpe] = useState(false);
  // const [listaFaixaRenda, setListaFaixaRenda] = useState([]);
  const { apLiberty, capIcatu } = useSelector(state => state.proposta);
  // const [listaFaixaRenda, setListaFaixaRenda] = useState([]);
  const [nrFilhos, setNrFilhos] = useState();
  const [nomeEmpresaTrabalho, setNomeEmpresaTrabalho] = useState();
  const { PessoaPoliticamenteExposta } = useState();
  const estadosCivisLiberty = [
    { estadoCivilId: 1, estadoCivilDescricao: 'SOLTEIRO(A)' },
    { estadoCivilId: 2, estadoCivilDescricao: 'CASADO(A)' },
    { estadoCivilId: 3, estadoCivilDescricao: 'VIUVO(A)' },
    { estadoCivilId: 4, estadoCivilDescricao: 'DIVORCIADO(A)' },
    { estadoCivilId: 5, estadoCivilDescricao: 'OUTROS' },
  ];
  const listSegmentacaoClienteCap = [
    { segmentacaoClienteCap: 'Millenium Capital' },
    { segmentacaoClienteCap: 'Ruralista Alta Renda' },
    { segmentacaoClienteCap: 'Sócio PJ' },
    { segmentacaoClienteCap: 'Não se aplica' },
  ]

  const [listOrgaoEmissor, setListOrgaoEmissor] = useState();
  const [messageImc, setMessageImc] = useState('');
  const [messageImcConjuge, setMessageImcConjuge] = useState('');
  const [showMessageImc, setShowMessageImc] = useState(false);
  const [showMessageImcConjuge, setShowMessageImcConjuge] = useState(false);
  const [ehPessoaEstrangeira, setEhPessoaEstrangeira] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setRendaMensal(
      Mask.maskMoneyOn(clienteRedux?.mtrClienteFaixaRendaValor, true)
    );

    if(produtoIsLibertyResidencialPersonalizavel) {
      setEhPessoaEstrangeira(!!segurado.ehEstrangeiro)
      setSeguradoLibertyPersonalizavel({...segurado});
    }

    carregarProfissaoLiberty();

    // Se for CAP
    if (productIsCAP) {
      // set profissao no drop caso tenha no estado
      if (
        profissaoIcatuId == null &&
        capIcatu.demaisDados.dominiosParceiroSelecteds != null
      ) {
        // Busca a profissão do parceiro selecionado
        var _profissaoIcatuId = capIcatu.demaisDados.dominiosParceiroSelecteds.find(
          c => c.dominioParceiroNomeId == 2 /* Profissão ICATU */
        );

        if (_profissaoIcatuId != null) {
          setProfissaoIcatuId(_profissaoIcatuId.dominioParceiroId);
        }
      }
      // set profissao no drop caso tenha no estado
      if (
        atividadesPJIcatuId == null &&
        capIcatu.demaisDados.dominiosParceiroSelecteds != null
      ) {
        // Busca a profissão do parceiro selecionado
        var _atividadesPJIcatuId = capIcatu.demaisDados.dominiosParceiroSelecteds.find(
          c => c.dominioParceiroNomeId == 1 /* Atividade ICATU */
        );

        if (_atividadesPJIcatuId != null) {
          setAtividadesPJIcatuId(_atividadesPJIcatuId.dominioParceiroId);
        }
      }

      setRepresentanteNome(capIcatu.demaisDados.representanteNome);
      setRepresentanteCPF(capIcatu.demaisDados.representanteCPF);

      if (capIcatu.demaisDados.segmentacaoClienteCap && !segmentacaoClienteCap) {
        setSegmentacaoClienteCap(capIcatu.demaisDados.segmentacaoClienteCap);
      }
    }
    if (ufs) {
      const newUfs = ufs.map(u => {
        return {
          text: `SSP - ${u.text}`,
          value: `SSP - ${u.text}`,
        };
      });
      setListOrgaoEmissor(newUfs.concat(orgaos));

      // Se for VPP confere se o SSP presente está de acordo com a lista, pois no back espera desta forma
      if (productIsVPP) {
        const existeUf = newUfs.find(
          u => u.value === clienteRedux.clienteRgSsp
        );
        if (existeUf == null) {
          console.log('UF não padrão, limpa UF', clienteRedux.clienteRgSsp);
          changeFieldRedux('clienteRgSsp', null);
        }
      }
    }
    if (productIsAP) {
      console.log("profissao", clienteRedux.clienteProfissaoId)
      if (clienteRedux.clienteProfissaoId > 0) {
        const descricaoClienteProfissao = profissoes.find(p => p.clienteProfissaoId === clienteRedux.clienteProfissaoId)?.clienteProfissaoDescricao;
        if (descricaoClienteProfissao) {
          const clienteProfissaoId = SelectedProfissaoLiberty(descricaoClienteProfissao);
          dispatch(
            ClienteActions.changeCliente({
              field: 'clienteProfissaoId',
              value: clienteProfissaoId,
            })
          );
        }
      }
    }
    if (productIsBrbMaster) {
      if (clienteRedux.estadoCivilId == -2 || clienteRedux.estadoCivilId == 6) {
        changeFieldRedux('estadoCivilId', null)
      }
      if (clienteRedux.clienteProfissaoId == 0) {
        changeFieldRedux('clienteProfissaoId', null)
      }
    }
  }, []);

  useEffect(() => {
    setCliente(clienteRedux);
  }, [clienteRedux]);

  useEffect(() => {
    calculaIMCTitular();
  }, [vppAmerican.titularAltura, vppAmerican.titularPeso]);

  useEffect(() => {
    calculaIMCConjuge();
  }, [vppAmerican.conjugeAltura, vppAmerican.conjugePeso]);

  function deveCarregarProfissaoLiberty() {
    return (productIsAP || produtoIsLibertyResidencialPersonalizavel) && (clienteRedux?.clienteProfissaoId ?? 0) > 0;
  }

  function carregarProfissaoLiberty() {

    if(!deveCarregarProfissaoLiberty())
      return;

    const descricaoClienteProfissao = profissoes
      .find(p => p.clienteProfissaoId === clienteRedux.clienteProfissaoId)?.clienteProfissaoDescricao;
    
    if(!descricaoClienteProfissao) return;

    const clienteProfissaoId = SelectedProfissaoLiberty(descricaoClienteProfissao);

    dispatch(ClienteActions.changeCliente({ field: 'clienteProfissaoId', value: clienteProfissaoId}));
    changeFieldRedux('clienteProfissaoLibertyCodigo', clienteProfissaoId);
  }

  function isCapPJ() {
    return productIsCAP && clienteRedux.clienteCpfCnpj.length >= 14;
  }

  function SelectedProfissaoLiberty(descricao) {
    for (let i = 0; i < profissoesLiberty.length; i++) {
      if (
        (profissoesLiberty[i]?.descricao ?? '').toUpperCase() === (descricao ?? '').toUpperCase()
      )
        return profissoesLiberty[i].codigo;
    }
  }

  function changeFieldSeguradoPersonalizavel(field, value){
    setSeguradoLibertyPersonalizavel({...seguradoLibertyPersonalizavel, [field]: value});
    dispatch(LibertyResidencialPersonalizavelActions.saveSeguradoRedux({
      ...seguradoLibertyPersonalizavel, [field]: value
    }))
  }

  function changeFieldRedux(field, value) {
    dispatch(ClienteActions.changeCliente({ field, value }));
  }

  function changeField(field, value) {
    setCliente({ ...cliente, [field]: value });
  }

  function _onChangeCliente() {
    dispatch(ClienteActions.saveCliente());
    
    if(produtoId === Constants.Produtos.LibertyResidencialPersonalizavel)
      zerarObjSeguradoResidencialPersonalizavelLiberty();
    
    if (onChangeCliente) onChangeCliente();
  }

  function _onChangeProfissao(value) {
    // Verifica se a profissão selecionada foi "Aposentado" 
    if (value == "9999-04") {
      message.error('Atenção! Caso o cliente atualmente seja APOSENTADO, favor colocar a última profissão de atuação do cliente.');
      
      changeFieldRedux('clienteProfissaoBrbMaster', undefined);
      changeFieldRedux('clienteProfissaoDescricaoBrbMaster', undefined);
      return;
    }

    changeFieldRedux('clienteProfissaoBrbMaster', value);
    const profissaoChosen = profissoesMongeral.find(p => p.auxiliar == value);
    changeFieldRedux(
      'clienteProfissaoDescricaoBrbMaster',
      profissaoChosen.descricao
    );
  }

  function _onBlurRendaMensal(value) {
    const rendaMensalValor = Functions.getMoney(value);
    const faixaRendaId = Functions.getFaixaRendaId(rendaMensalValor);
    setRendaMensal(value);
    changeFieldRedux('clienteFaixaRendaId', faixaRendaId);
    changeFieldRedux('clienteFaixaRendaManual', rendaMensalValor);
    changeFieldRedux('mtrClienteFaixaRendaValor', rendaMensalValor);
  }

  function _onChangeRendaMensal(value) {
    setRendaMensal(value);
  }

  function _onChangeNrFilhos(value) {
    setNrFilhos(value);
    changeField('clienteNrFilhos', value);
    changeFieldRedux('clienteNrFilhos', value);
  }

  function changeIsPessoaPoliticamenteExposta(field, value) {
    changeFieldRedux('clientePpeId', value === 1 ? 1 : 2);
    const PessoaPoliticamenteExposta =
      apLiberty.PessoaPoliticamenteExposta || {};
    dispatch(
      PropostaActions.changeApLiberty({
        PessoaPoliticamenteExposta: {
          ...PessoaPoliticamenteExposta,
          isPessoaPoliticamenteExposta: value,
        },
      })
    );
  }

  function changeDemaisDadosCap(field, value) {
    if (field === 'profissoesIcatu') {
      setProfissaoIcatuId(value);
      capIcatu.demaisDados.dominiosParceiroSelecteds = changeDominiosParceiroSelecteds(
        profissoesIcatu.find(c => c.dominioParceiroId === value)
      );
    }

    if (field === 'atividadesPJIcatu') {
      setAtividadesPJIcatuId(value);
      capIcatu.demaisDados.dominiosParceiroSelecteds = changeDominiosParceiroSelecteds(
        atividadesPJIcatu.find(c => c.dominioParceiroId === value)
      );
    }
    if (field === 'representanteNome') {
      setRepresentanteNome(value);
      capIcatu.demaisDados.representanteNome = value;
    }
    if (field === 'representanteCPF') {
      setRepresentanteCPF(value);
      capIcatu.demaisDados.representanteCPF = value;
    }
    if (field === 'segmentacaoClienteCap') {
      setSegmentacaoClienteCap(value);
      capIcatu.demaisDados.segmentacaoClienteCap = value;
    }
    value = capIcatu.demaisDados;
    field = 'demaisDados';
    dispatch(PropostaActions.changeCapIcatu({ field, value }));
  }

  function changeDominiosParceiroSelecteds(dominioParceiro) {
    var { dominiosParceiroSelecteds } = capIcatu.demaisDados;

    // Se estiver em branco adiciona
    if (dominiosParceiroSelecteds == null || dominiosParceiroSelecteds === []) {
      dominiosParceiroSelecteds = [];
      dominiosParceiroSelecteds.push(dominioParceiro);
    } else {
      // Remove dominio de mesmo tipo se já existir
      dominiosParceiroSelecteds = dominiosParceiroSelecteds.filter(
        c => c.dominioParceiroNomeId !== dominioParceiro.dominioParceiroNomeId
      );
      // Adiciona novo dominio
      if (dominiosParceiroSelecteds == null) dominiosParceiroSelecteds = [];

      dominiosParceiroSelecteds.push(dominioParceiro);
    }

    return dominiosParceiroSelecteds;
  }

  function changePessoaPoliticamenteExposta(field, value) {
    const PessoaPoliticamenteExposta =
      apLiberty.PessoaPoliticamenteExposta || {};
    dispatch(
      PropostaActions.changeApLiberty({
        PessoaPoliticamenteExposta: {
          ...PessoaPoliticamenteExposta,
          tipoPessoaPoliticamenteExposta: value,
        },
      })
    );
  }

  function changePropostaVppAmerican(obj) {
    dispatch(PropostaActions.changeVppAmerican(obj));
  }

  function calculaIMCTitular() {
    const imc = calculaIMC(vppAmerican.titularAltura, vppAmerican.titularPeso);
    if (!imc) return;

    const objImc = verificaIMC(imc);

    setShowMessageImc(objImc.alert);
    setMessageImc(
      `IMC: ${imc.toFixed(2).replace('.', ',')} - ${objImc.message}`
    );
  }

  function calculaIMCConjuge() {
    const imc = calculaIMC(vppAmerican.conjugeAltura, vppAmerican.conjugePeso);
    if (!imc) return;

    const objImc = verificaIMC(imc);

    setShowMessageImcConjuge(objImc.alert);
    setMessageImcConjuge(
      `IMC: ${imc.toFixed(2).replace('.', ',')} - ${objImc.message}`
    );
  }

  function calculaIMC(altura, peso) {
    altura = Functions.getOnlyNum(altura) / 100;
    peso = Functions.getOnlyNum(peso);

    if (altura && peso) {
      const imc = peso / (altura * altura);
      return imc;
    }

    return 0;
  }

  function verificaIMC(imc) {
    if (imc < 18.5) return { message: 'Abaixo do peso ideal', alert: false };
    if (imc >= 18.5 && imc <= 24.99)
      return { message: 'Peso ideal', alert: false };
    if (imc >= 25 && imc <= 29.9)
      return { message: 'Acima do Peso (sobrepeso)', alert: false };
    if (imc >= 30 && imc <= 34.9)
      return { message: 'Obesidade Grau I', alert: false };
    if (imc >= 35 && imc <= 39.9)
      return { message: 'Obesidade Grau II (severa)', alert: true };
    if (imc >= 40) return { message: 'Obesidade Grau III', alert: true };
    return '';
  }

  function retornaListaPaisesEmissaoPassaporteLibertyPersonalizavel() {
    var retorno = [];

    var retorno = ((listaNomesDominiosResidencialLibertyPersonalizavel ?? [])
      .find(x => x.dominioParceiroNomeDescricao.includes('Países Emissão Passaporte')) ?? {dominioParceiros: []}).dominioParceiros;

    return retorno;
  }

  function changeTipoPlano(value) {
    if (value === 2) {
      changePropostaVppAmerican({ tipoPlano: value, planoSelected: undefined });
      return;
    }

    changePropostaVppAmerican({
      tipoPlano: value,
      conjugeCpf: undefined,
      conjugeNome: undefined,
      conjugeDataNascimento: undefined,
      conjugeAltura: undefined,
      conjugePeso: undefined,
      planoSelected: undefined,
    });
  }

  function changeEhPessoaEstrangeira(value){
    
    var obj = {
      ...seguradoLibertyPersonalizavel,
      numeroPassaporte: null,
      passaporteOrigem: null,
      ehEstrangeiro: value,
      relacaoPessoaPoliticamenteExposta: null
    }

    dispatch(LibertyResidencialPersonalizavelActions.saveSeguradoRedux(obj));
    setSeguradoLibertyPersonalizavel(obj);
    setEhPessoaEstrangeira(value);
  }

  function zerarObjSeguradoResidencialPersonalizavelLiberty(){
    dispatch(LibertyResidencialPersonalizavelActions.saveSeguradoRedux({
      ...seguradoLibertyPersonalizavel,
      numeroPassaporte: null,
      passaporteOrigem: null,
      ehEstrangeiro: false,
      tipoAtividadeEmpresa: null,
      relacaoPessoaPoliticamenteExposta: null
    }))
  }

  function changeTemRelacionamentoPpeEstrangeiro(value){
    setEhPpe(value);
    
    var obj = {
      ...seguradoLibertyPersonalizavel,
      ehPessoaPoliticamenteExpostaEstrangeira: value,
      relacaoPessoaPoliticamenteExposta: null
    }

    setSeguradoLibertyPersonalizavel(obj)
    dispatch(LibertyResidencialPersonalizavelActions.saveSeguradoRedux(obj))
  }

  function retornaListaAtividadesEmpresaLibertyPersonalizavel() {
    var retorno = [];

    var retorno = ((listaNomesDominios ?? [])
      .find(x => x.dominioParceiroNomeDescricao.includes('Tipo Atividade Empresa')) ?? {dominioParceiros: []}).dominioParceiros;

    return retorno;
  }

  return (
    <>
      <TitleSection title="Opção de Plano" />

      <Container>
        {productIsVPP && (
          <Row gutter={[16, 0]} style={{ marginBottom: 10 }}>
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label className="w-100">Plano</label>
                  <Radio.Group
                    buttonStyle="solid"
                    defaultValue={vppAmerican.tipoPlano}
                    options={listTipoPlano}
                    onChange={({ target: { value } }) => changeTipoPlano(value)}
                  />
                </div>
              </Col>
            </Row>
            {!isAgenciaDigital && (
              <Row gutter={[16, 16]}>
                <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                  <div className="form-group">
                    <label>Altura do titular</label>
                    <Input
                      placeholder="Altura"
                      addonAfter="m"
                      value={vppAmerican.titularAltura}
                      mask="altura"
                      suffix="M"
                      onChange={value =>
                        changePropostaVppAmerican({ titularAltura: value })
                      }
                    />
                  </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                  <div className="form-group">
                    <label>Peso do titular</label>
                    <Input
                      placeholder="Peso"
                      addonAfter="kg"
                      mask="peso"
                      suffix="Kg"
                      value={vppAmerican.titularPeso}
                      onChange={value =>
                        changePropostaVppAmerican({ titularPeso: value })
                      }
                    />
                  </div>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 8 }}>
                  <div style={{ paddingTop: 33 }}>{messageImc}</div>
                </Col>
              </Row>
            )}

            {vppAmerican.tipoPlano === 2 && (
              <>
                {!isAgenciaDigital && (
                  <>
                    <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
                      <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                        <div className="form-group">
                          <label>Altura do cônjuge</label>
                          <Input
                            placeholder="Altura"
                            addonAfter="m"
                            mask="altura"
                            suffix="M"
                            value={vppAmerican.conjugeAltura}
                            onChange={value =>
                              changePropostaVppAmerican({
                                conjugeAltura: value,
                              })
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                        <div className="form-group">
                          <label>Peso do cônjuge</label>
                          <Input
                            placeholder="Peso"
                            addonAfter="kg"
                            mask="peso"
                            suffix="Kg"
                            value={vppAmerican.conjugePeso}
                            onChange={value =>
                              changePropostaVppAmerican({ conjugePeso: value })
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={{ span: 6 }} sm={{ span: 8 }}>
                        <div style={{ paddingTop: 33 }}>
                          {messageImcConjuge}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <div className="form-group">
                      <label>CPF do cônjuge</label>
                      <Input
                        placeholder="CPF"
                        value={vppAmerican.conjugeCpf}
                        mask="cpfCnpj"
                        onChange={value =>
                          changePropostaVppAmerican({ conjugeCpf: value })
                        }
                        maxLength={14}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <div className="form-group">
                      <label>Nome do cônjuge</label>
                      <Input
                        placeholder="Nome"
                        value={vppAmerican.conjugeNome}
                        onChange={value =>
                          changePropostaVppAmerican({ conjugeNome: value })
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                    <div className="form-group">
                      <label className="w-100">
                        Data de nascimento do cônjuge
                      </label>
                      <DatePicker
                        value={vppAmerican.conjugeDataNascimento}
                        placeholder="Selecione"
                        onChange={value =>
                          changePropostaVppAmerican({
                            conjugeDataNascimento: value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {(showMessageImc || showMessageImcConjuge) && (
              <Row style={{ marginBottom: 12, marginTop: 18 }}>
                <Alert
                  showIcon
                  description="O IMC está fora do grau geralmente aceito pela seguradora. Recomendamos que procure seu consultor para avaliação da melhor proposta a ser apresentada. Caso queira prosseguir com a proposta, basta continuar o preenchimento do formulário."
                  message="Atenção:"
                  type="warning"
                />
              </Row>
            )}
          </Row>
        )}
      </Container>

      <TitleSection title="Proponente" />

      <Container>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} sm={{ span: 10 }}>
            <div className="form-group">
              <label>Cpf</label>
              <InputAnt.Group compact>
                <Input
                  placeholder="Cpf"
                  value={cliente.clienteCpfCnpj}
                  disabled
                  style={{ width: '70%' }}
                />

                <Button
                  type="ghost"
                  style={{ width: '30%' }}
                  onClick={_onChangeCliente}
                >
                  Alterar cliente
                </Button>
              </InputAnt.Group>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 11 }}>
            <div className="form-group">
              <label>Nome</label>
              <Input
                placeholder="Nome"
                value={cliente.clienteNome}
                onChange={value => changeField('clienteNome', value)}
                onBlur={value => changeFieldRedux('clienteNome', value)}
              />
            </div>
          </Col>
          {
            (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) &&
            <>
              <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                <div className="form-group">
                  <label className="w-100">Data Nascimento</label>
                  <DatePicker
                    value={cliente.clienteDataNascimento}
                    onChange={value =>
                      changeFieldRedux('clienteDataNascimento', value)
                    }
                  />
                </div>
              </Col>
            </>
          }
          
        </Row>
        <Row gutter={[16, 16]}>
          {!isCapPJ() && (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) && (
            <Col xs={{ span: 24 }} sm={{ span: 4 }}>
              <div className="form-group">
                <label className="w-100">Sexo</label>
                <Select
                  defaultValue="Selecione"
                  itens={generos}
                  itemValue="sexoId"
                  itemText="sexoDescricao"
                  value={cliente.sexoId}
                  onChange={value => changeFieldRedux('sexoId', value)}
                  showSearch
                  remove={
                    productIsVPP
                      ? v => v.sexoDescricao == 'JURIDICA'
                      : () => false
                  }
                />
              </div>
            </Col>
          )}
          {productIsAP && !isCapPJ() && (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label className="w-100">Estado Civil</label>
                <Select
                  defaultValue="Selecione"
                  itens={estadosCivisLiberty}
                  itemValue="estadoCivilId"
                  itemText="estadoCivilDescricao"
                  value={cliente.estadoCivilId}
                  onChange={value => changeFieldRedux('estadoCivilId', value)}
                  showSearch
                />
              </div>
            </Col>
          )}
          {!productIsAP && !isCapPJ() && (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label className="w-100">Estado Civil</label>
                <Select
                  defaultValue="Selecione"
                  itens={estadosCivis}
                  itemValue="estadoCivilId"
                  itemText="estadoCivilDescricao"
                  value={cliente.estadoCivilId}
                  onChange={value => changeFieldRedux('estadoCivilId', value)}
                  showSearch
                  remove={
                    productIsVPP
                      ? v => v.estadoCivilId === -2 || v.estadoCivilId === 6
                      : () => false
                  }
                />
              </div>
            </Col>
          )}
          {!isCapPJ() && (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) && (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Número de identidade</label>
                  <Input
                    placeholder="Número de identidade"
                    value={cliente.clienteRg}
                    onChange={value => changeField('clienteRg', value)}
                    onBlur={value => changeFieldRedux('clienteRg', value)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 5 }}>
                <div className="form-group">
                  <label>Órgão emissor</label>
                  <Select
                    defaultValue="Selecione"
                    itens={listOrgaoEmissor}
                    itemValue="value"
                    itemText="text"
                    // renderText={item => `SSP - ${item.text}`}
                    value={cliente.clienteRgSsp}
                    onChange={value => changeFieldRedux('clienteRgSsp', value)}
                    showSearch
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                <div className="form-group">
                  <label className="w-100">Data de emissão</label>
                  <DatePicker
                    value={cliente.clienteDataExpedicaoRg}
                    placeholder="Selecione"
                    onChange={value =>
                      changeFieldRedux('clienteDataExpedicaoRg', value)
                    }
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <div className="form-group">
                  <label>Nacionalidade</label>
                  <Select
                    defaultValue="Selecione"
                    itens={naturalidade}
                    itemValue="naturalidadeId"
                    itemText="naturalidadeDescricao"
                    value={cliente.naturalidadeId}
                    onChange={value =>
                      changeFieldRedux('naturalidadeId', value)
                    }
                    showSearch
                    remove={
                      productIsVPP ? v => v.naturalidadeId === -2 : () => false
                    }
                  />
                </div>
              </Col>
            </>
          )}
          {!productIsAP && !productIsCAP && !produtoIsLibertyResidencialPersonalizavel && !productIsBrbMaster && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Profissão</label>
                <Select
                  defaultValue="Selecione"
                  itens={profissoes}
                  itemValue="clienteProfissaoId"
                  itemText="clienteProfissaoDescricao"
                  value={cliente.clienteProfissaoId}
                  onChange={value =>
                    changeFieldRedux('clienteProfissaoId', value)
                  }
                  showSearch
                  remove={
                    productIsVPP
                      ? v =>
                          v.clienteProfissaoId === 0 ||
                          v.clienteProfissaoId === 99 ||
                          v.clienteProfissaoId === 78 ||
                          v.clienteProfissaoId === 89 ||
                          v.clienteProfissaoId === 924
                      : () => false
                  }
                />
              </div>
            </Col>
          )}

          {(productIsAP || produtoIsLibertyResidencialPersonalizavel) && (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Profissão</label>
                <Select
                  defaultValue="Selecione"
                  itens={profissoesLiberty}
                  itemValue="codigo"
                  itemText="descricao"
                  value={cliente.clienteProfissaoLibertyCodigo}
                  onChange={value =>
                    {
                      changeFieldRedux('clienteProfissaoId', value);
                      changeFieldRedux('clienteProfissaoLibertyCodigo', value);
                    }
                  }
                  showSearch
                />
              </div>
            </Col>
          )}
          {productIsCAP && isCapPJ() && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Atividade </label>
                <Select
                  defaultValue="Selecione"
                  itens={atividadesPJIcatu}
                  itemValue="dominioParceiroId"
                  itemText="dominioParceiroDescricao"
                  value={atividadesPJIcatuId}
                  onChange={value =>
                    changeDemaisDadosCap('atividadesPJIcatu', value)
                  }
                  showSearch
                />
              </div>
            </Col>
          )}

          {productIsCAP && (
            <Col xs={{ span: 24 }} sm={{ span: 4 }}>
              <div className="form-group">
                <label>Segmentação Cliente</label>
                <Select
                  defaultValue="Selecione"
                  itens={listSegmentacaoClienteCap}
                  itemValue="segmentacaoClienteCap"
                  itemText="segmentacaoClienteCap"
                  value={segmentacaoClienteCap}
                  onChange={value =>
                    changeDemaisDadosCap('segmentacaoClienteCap', value)
                  }
                />
              </div>
            </Col>
          )}

          {productIsBrbMaster && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Profissão</label>
                <Select
                  defaultValue={"Selecione"}
                  itens={profissoesMongeral}
                  itemValue="auxiliar"
                  itemText="descricao"
                  value={cliente.clienteProfissaoBrbMaster}
                  onChange={_onChangeProfissao}
                  showSearch
                />
              </div>
            </Col>
          )}
          {isCapPJ() && (
              <Col span={24}>
                  <Title>Representante Legal</Title>
              </Col>
          )}

          {(productIsAP || productIsCAP || productIsBrbMaster) && (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>É pessoa politicamente exposta</label> <br />
                  <Radio.Group
                    buttonStyle="solid"
                    defaultValue={
                      apLiberty.PessoaPoliticamenteExposta
                        ?.isPessoaPoliticamenteExposta == null
                        ? cliente.clientePpeId == 1
                          ? 1
                          : 0
                        : apLiberty.PessoaPoliticamenteExposta
                            ?.isPessoaPoliticamenteExposta
                    }
                    options={
                      Constants.ApLiberty.listIsPessoaPoliticamenteExposta
                    }
                    onChange={({ target: { value } }) =>
                      changeIsPessoaPoliticamenteExposta(
                        'isPessoaPoliticamenteExposta',
                        value
                      )
                    }
                  />
                </div>
              </Col>
            </>
          )}
 
          {productIsAP && (
            <Col xs={{ span: 24 }} sm={{ span: 5 }}>
              <div className="form-group">
                <label>Pessoa Politicamente Exposta</label>
                <Select
                  defaultValue="Selecione"
                  itens={Constants.ApLiberty.listTipoPessoaPoliticamenteExposta}
                  itemValue="codigo"
                  itemText="descricao"
                  value={
                    apLiberty.PessoaPoliticamenteExposta
                      ?.tipoPessoaPoliticamenteExposta
                  }
                  onChange={value =>
                    changePessoaPoliticamenteExposta(
                      'tipoPessoaPoliticamenteExposta',
                      value
                    )
                  }
                  showSearch
                  required={apLiberty.isPessoaPoliticamenteExposta}
                />
              </div>
            </Col>
          )}

          {!productIsAP && !productIsCAP && !productIsBrbMaster && (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) && (
            <Col xs={{ span: 24 }} sm={{ span: 11 }}>
              <div className="form-group">
                <label>Cargo</label>
                <Select
                  placeholder="Cargo"
                  itens={cargos}
                  itemValue="clienteCargoId"
                  itemText="clienteCargoDescricao"
                  value={cliente.clienteCargoId}
                  onChange={value => changeFieldRedux('clienteCargoId', value)}
                  showSearch
                />
              </div>
            </Col>
          )}
          {/* {!productIsVPP && (
            <Col xs={{ span: 24 }} sm={{ span: 3 }}>
              <div className="form-group">
                <label>
                  Renda mensal{isCapPJ() ? ' (Representante)' : ''}:
                </label>
                <Select
                  defaultValue="Selecione"
                  itens={listaFaixaRenda}
                  itemValue="clienteFaixaRendaId"
                  itemText="clienteFaixaRendaInicio"
                  renderText={i =>
                    i.clienteFaixaRendaId != 18
                      ? `${i.clienteFaixaRendaInicio} - ${i.clienteFaixaRendaFim}`
                      : `NÃO INFORMADA`
                  }
                  value={cliente.clienteFaixaRendaId}
                  onChange={value =>
                    changeFieldRedux('clienteFaixaRendaId', value)
                  }
                  showSearch
                />
              </div>
            </Col>
          )}
        */}
          {productIsCAP && (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>
                    Profissão {isCapPJ() ? ' do Representante Legal' : ''}
                  </label>
                  <Select
                    defaultValue="Selecione"
                    itens={profissoesIcatu}
                    itemValue="dominioParceiroId"
                    itemText="dominioParceiroDescricao"
                    value={profissaoIcatuId}
                    onChange={value =>
                      changeDemaisDadosCap('profissoesIcatu', value)
                    }
                    showSearch
                  />
                </div>
              </Col>
              {isCapPJ() && (
                <>
                  <Col xs={{ span: 16 }} sm={{ span: 4 }}>
                    <div className="form-group">
                      <label>CPF do Representante Legal</label>
                      <Input
                        placeholder="CPF do Representante Legal"
                        value={representanteCPF}
                        onChange={value =>
                          changeDemaisDadosCap('representanteCPF', value)
                        }
                        onBlur={value =>
                          changeDemaisDadosCap('representanteCPF', value)
                        }
                        accept="onlyNumbers"
                        maxLength={11}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 16 }} sm={{ span: 4 }}>
                    <div className="form-group">
                      <label>Nome do Representante Legal</label>
                      <Input
                        placeholder="Nome do Representante Legal"
                        value={representanteNome}
                        onChange={value =>
                          changeDemaisDadosCap('representanteNome', value)
                        }
                        onBlur={value =>
                          changeDemaisDadosCap('representanteNome', value)
                        }
                      />
                    </div>
                  </Col>
                </>
              )}
            </>
          )}
          {!productIsAP && !productIsDreMitsui && !productIsCAP && !produtoIsLibertyResidencialPersonalizavel && !productIsBrbMaster && (
            // {/* {!productIsAP && !productIsDreMitsui && ( */}
            <Col xs={{ span: 24 }} sm={{ span: 10 }}>
              <div className="form-group">
                <label>Ramo de Atividade</label>
                <Input
                  placeholder="Ramo de Atividade"
                  value={cliente.clienteRamoAtividade}
                  onChange={value => changeField('clienteRamoAtividade', value)}
                  onBlur={value =>
                    changeFieldRedux('clienteRamoAtividade', value)
                  }
                />
              </div>
            </Col>
          )}

          {/* SE FOR VIDA PREMIADO PLUS, HABILITAR CAMPO PARA PREENCHIMENTO MANUAL */}
          {/* {productIsVPP && ( */}
          {
            (!produtoIsLibertyResidencialPersonalizavel || ehPessoaFisica) &&
            <>
            <Col xs={{ span: 24 }} sm={{ span: 3 }}>
              <div className="form-group">
                <label>Renda mensal: </label>
                <Input
                  placeholder="Renda mensal"
                  value={rendaMensal}
                  mask="money"
                  onChange={_onChangeRendaMensal}
                  onBlur={_onBlurRendaMensal}
                />
              </div>
            </Col>
            </>
          }
          
          { !ehPessoaFisica && (
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <div className="form-group">
                <label>Tipo de Atividade da empresa</label>
                <Select
                  defaultValue="Selecione"
                  itens={retornaListaAtividadesEmpresaLibertyPersonalizavel()}
                  itemValue="dominioParceiroCodigo"
                  itemText="dominioParceiroDescricao"
                  value={seguradoLibertyPersonalizavel.tipoAtividadeEmpresa}
                  onChange={value =>
                    changeFieldSeguradoPersonalizavel('tipoAtividadeEmpresa', value)
                  }
                  showSearch
                />
              </div>
            </Col>
          )
          
          }
          {/* )} */}

          {productIsBrbMaster && (
              <>
                  <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                      <div className="form-group">
                          <label>Número de Filhos </label>
                          <Input
                              placeholder="Número de filhos"
                              value={cliente.clienteNrFilhos}
                              onChange={_onChangeNrFilhos}
                              accept="onlyNumbers"
                          />
                      </div>
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                      <div className="form-group">
                          <label>Categoria da profissão </label>
                          <Select
                              defaultValue="Selecione"
                              itens={Constants.BrbMaster.categoriasProfissao}
                              itemValue="value"
                              itemText="label"
                              value={cliente.clienteProfissaoCategoria}
                              onChange={value =>
                                  changeFieldRedux('clienteProfissaoCategoria', value)
                              }
                              showSearch
                          />
                      </div>
                  </Col>
              </>
          )}
        </Row>
        {/* {produtoIsLibertyResidencialPersonalizavel && ehPessoaFisica && (
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 3 }}>
            <div className="form-group">
              <label>É estrangeiro?</label> <br />
              <Radio.Group
                onChange={({ target: { value } }) => changeEhPessoaEstrangeira(value)}
                value={ehPessoaEstrangeira}
              >
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </div>
          </Col>
          {
            ehPessoaEstrangeira && (
              <>
                <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label>Número Passaporte</label>
                    <Input
                      placeholder="Número do passaporte"
                      value={seguradoLibertyPersonalizavel.numeroPassaporte}
                      onChange={value => changeFieldSeguradoPersonalizavel('numeroPassaporte', value)}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <div className="form-group">
                  <label>País origem Passaporte</label>
                  <Select
                    defaultValue="Selecione"
                    itens={retornaListaPaisesEmissaoPassaporteLibertyPersonalizavel()}
                    itemValue="dominioParceiroDescricao"
                    itemText="dominioParceiroDescricao"
                    value={seguradoLibertyPersonalizavel.passaporteOrigem}
                    onChange={value =>
                      changeFieldSeguradoPersonalizavel('passaporteOrigem', value)
                    }
                    showSearch
                  />
                </div>
              </Col>               
              </>
            )
          }
        </Row>
        )
        } */}
      </Container>
    </>
  );
}
