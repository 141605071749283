import { Col, Divider, Row, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import * as util from '~/utils';

const { Column } = Table;

type FeriasTabProp = {
  data?: Object
}

function ordenarPorPropriedade(lista, posicaoOrdenar = null) {
  return lista.sort((a, b) => a[posicaoOrdenar] - b[posicaoOrdenar]);
}

function exibirListaPagamentos(contrato) {
  return contrato?.pagamentos && !!contrato.pagamentos.length;
}

function exibirListaTitulos(contrato) {
  return contrato?.titulos && !!contrato.titulos.length;
}

export default function FeriasDetalhe(param: FeriasTabProp) {

  const contrato = param?.data || {};

  return (
    <>
      <Divider
        orientation="left"
        style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
      >
        Dados do Contrato
      </Divider>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Contrato">Contrato</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Contrato">{ contrato?.contrato }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Produto">Produto</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Produto">{ contrato?.produtoDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Seguradora">Seguradora</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Seguradora">{ contrato?.seguradoraDescricao }</span>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Situação">Situação</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Situação">{ contrato?.contratoStatusDescricao }</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{padding: '.9rem'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 6 }}>
          <Row>
            <Col span={24}>
              <strong title="Quantidade de títulos">Quantidade de títulos</strong>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span title="Quantidade de títulos">{ contrato?.titulos?.length || 0 }</span>
            </Col>
          </Row>
        </Col>
        {
          !!contrato.quantidadeDiarias &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
              <Row>
                <Col span={24}>
                  <strong title="Quantidade de diárias">Quantidade de diárias</strong>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span title="Quantidade de diárias">{contrato?.quantidadeDiarias}</span>
                </Col>
              </Row>
            </Col>
          </>
        }
      </Row>
      <Row gutter={[8, 8]} style={{ padding: '.9rem' }}>
        {
          exibirListaPagamentos(contrato) &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Parcelas
              </Divider>
              <Table
                rowKey="pagamentoId"
                dataSource={ordenarPorPropriedade(contrato?.pagamentos || [], 'pagamentoParcelaNumero')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="Nº Parcela"
                  align="center"
                  dataIndex="pagamentoParcelaNumero"
                  width={6}
                />
                <Column
                  title="Tipo parcela"
                  align="center"
                  dataIndex="tipoParcelaDescricao"
                  width={10}
                />
                <Column
                  title="Data vencimento"
                  width={10}
                  align="center"
                  dataIndex="pagamentoDataVencimento"
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : ''
                  )}
                />
                <Column
                  title="Data pagamento"
                  width={10}
                  align="center"
                  dataIndex="pagamentoDataPagamento"
                  render={(text, record) => (
                    text
                      ? <span>{moment(text).format('DD/MM/YYYY')}</span>
                      : 'NÃO ESTÁ PAGA'
                  )}
                />
                <Column
                  title="Valor Pago"
                  width={12}
                  align="center"
                  dataIndex="pagamentoValor"
                  render={(text, record) => (
                    text
                      ? <span>{util.Functions.formatarMoeda(text)}</span>
                      : 'NÃO ESTÁ PAGA'
                  )}
                />
                <Column
                  title="Situação"
                  align="center"
                  width={8}
                  dataIndex="pagamentoStatusDescricao"
                />

              </Table>
            </Col>
          </>
        }
        {
          exibirListaTitulos(contrato) &&
          <>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Divider
                orientation="left"
                style={{ color: '#333', fontWeight: 'normal', marginTop: 40 }}
              >
                Títulos
              </Divider>
              <Table
                rowKey="tituloId"
                dataSource={ordenarPorPropriedade(contrato?.titulos || [], 'tituloId')}
                scroll={{ x: 800 }}
              >
                <Column
                  title="N° Título"
                  align="center"
                  dataIndex="tituloNumero"
                  width={15}
                />

              </Table>
            </Col>
          </>
        }
      
      </Row>
      
    </>
  );
};