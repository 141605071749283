import React from 'react';
import moment from 'moment';
import { DatePicker as DatePickerAntd } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
// import { Container } from './styles';

type DatePickerProps = {
  value: Date | Date[],
  placeholder: String,
  onChange: Function,
  range?: Boolean,
};

const { RangePicker } = DatePickerAntd;

export default function DatePicker({
  value,
  placeholder,
  onChange,
  range,
}: DatePickerProps) {
  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (!value) setData();
    else setData(moment.utc(value));
  }, [value]);

  if (range)
    return (
      <RangePicker
        format="DD/MM/YYYY"
        locale={locale}
        value={value ? [moment.utc(value[0]), moment.utc(value[1])] : ''}
        placeholder={placeholder}
        onChange={date => onChange(date)}
      />
    );

  return (
    <DatePickerAntd
      format="DD/MM/YYYY"
      locale={locale}
      value={data}
      placeholder={placeholder}
      onChange={date => onChange(date)}
    />
  );
}

DatePicker.defaultProps = {
  range: false,
};
