import { message as toast } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '~/api/pesquisa-geral.api';
import * as ClienteApi from '~/api/cliente.api';
import { getResponse } from '~/api/utils';
import * as PesquisaGeralActions from '~/store/ducks/pesquisa-geral';
import { Functions } from '~/utils';


function* get(action) {;

  const response = getResponse(
    yield call(Api.get, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.saveResultadoBusca(Functions.trimObjectProperties(data) || {
    clienteCpfCnpj: action.payload,
    clienteContatoFones: [],
    clienteContatoMails: [],
    clienteEnderecos: [],
    clienteTipoId: (action.payload || '').length > 11 ? 2 : 1,
  }));
}

function* getCliente(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesCliente, { cpfCnpj: action.payload })
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.saveCliente(data || []));
}


function* getPrevidenciaDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesPrevidencia, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getAutomovelDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesAutomovel, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getDreDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesDre, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getPrestamistaDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesPrestamista, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getHabitacionalDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesHabitacional, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getConsorcioDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesConsorcio, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getSaudeDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesSaude, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getFeriasDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesFerias, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getVidaDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesVida, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* getCapitalizacaoDetalhes(action) {;

  const response = getResponse(
    yield call(Api.getDetalhesCapitalizacao, action.payload)
  );

  const { ok, data, message } = response;

  if (!ok) return yield call(fail, message);

  yield put(PesquisaGeralActions.savePropostaDetalhes(Functions.trimObjectProperties(data) || { }));
}

function* fail(message) {
  yield put(PesquisaGeralActions.falha());
  toast.error(message);
}

export default all([
  takeLatest(PesquisaGeralActions.types.GET, get),
  takeLatest(PesquisaGeralActions.types.GET_PREVIDENCIA_DETALHES, getPrevidenciaDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_AUTOMOVEL_DETALHES, getAutomovelDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_DRE_DETALHES, getDreDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_PRESTAMISTA_DETALHES, getPrestamistaDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_HABITACIONAL_DETALHES, getHabitacionalDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_CONSORCIO_DETALHES, getConsorcioDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_SAUDE_DETALHES, getSaudeDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_FERIAS_DETALHES, getFeriasDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_VIDA_DETALHES, getVidaDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_CAPITALIZACAO_DETALHES, getCapitalizacaoDetalhes),
  takeLatest(PesquisaGeralActions.types.GET_CLIENTE, getCliente)
]);
