import React, { useEffect, useState } from 'react';
import {
  PlusOutlined,
  PrinterOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import Button from 'antd-button-color';
import {
  Row,
  Col,
  Radio,
  Table,
  Spin,
  BackTop,
  Result,
  Popconfirm,
  Alert,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  TitleSection,
  FieldsProducao,
  Container,
  DatePicker,
  FieldsEndereco,
  Input,
  Select,
  Disable,
} from '~/components';

import Pagamento from './pagamento';

import {
  ProducaoValidation,
  PropostaValidation,
  PagamentoValidation,
} from '~/yup';

import * as PropostaActions from '~/store/ducks/proposta';
import * as ClienteActions from '~/store/ducks/cliente';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PlanoActions from '~/store/ducks/plano';

import { Constants, Mask, Validation, Functions } from '~/utils';

type EmpresarialMitsuiProps = {
  onReset: Function,
  onPrevius: Function,
};

export default function EmpresarialMitsui({
  onReset,
  onPrevius,
}: EmpresarialMitsuiProps) {
  const dispatch = useDispatch();

  // Redux state
  const {
    dreMitsuiEmp,
    dreMitsuiEmp: {
      planoSelected,
      bemEndereco,
      pagamento,
      isOutrosSeguros,
      propostaEletronicaPlanoId,
    },
    producao,
    loading: loadingProposta,
    propostaId,
    contratoId,
  } = useSelector(state => state.proposta);
  const { planos, loading } = useSelector(state => state.plano);
  const { cliente, endereco } = useSelector(state => state.cliente);
  const { regraComissaoCorretora, regraComissaoProdutor } = useSelector(
    state => state.producao
  );
  const { seguradoras, propostaEletronicaAtividades } = useSelector(
    ({ dominio }) => dominio
  );

  // Component state
  const [planosAgrupado, setPlanosAgrupado] = useState([]);
  const [planoSelectedKey, setPlanoSelectedKey] = useState([]);
  const [apoliceOutrosSeg, setApoliceOutrosSeg] = useState();
  const [propRazaoSocial, setPropRazaoSocial] = useState();
  const [propCNPJ, setPropCNPJ] = useState();
  const [erros, setErros] = useState([]);

  useEffect(() => {
    const param = {
      seguradoraId: Constants.Seguradoras.Mitsui,
      produtoId: Constants.Produtos.DreMitsuiEmp,
      tipoImovel: 3,
    };

    dispatch(PlanoActions.getPlanos({ param }));
  }, []);

  useEffect(() => {
    getRegraComissaoCorretora();
  }, []);

  useEffect(() => {
    if (dreMitsuiEmp && dreMitsuiEmp.bemEndereco) {
      // Preenche os objetos do state
      setApoliceOutrosSeg(dreMitsuiEmp.apoliceOutrosSeg);
      setPropRazaoSocial(dreMitsuiEmp.propRazaoSocial);
      setPropCNPJ(dreMitsuiEmp.propCNPJ);
    }
  }, []);

  useEffect(() => {
    const planosAgrupado1 = [];

    if (planos && planos.length > 0) {
      // Unifica retorno duplicados devido a coberturas
      planos.forEach(p => {
        const p2 = {};
        p2.propostaEletronicaPlanoId = p.propostaEletronicaPlanoId;
        p2.propostaEletronicaPlanoSigla = p.propostaEletronicaPlanoSigla;
        p2.propostaEletronicaPlanoTipo = p.propostaEletronicaPlanoTipo;
        p2.propostaEletronicaPlanoCodParceiro =
          p.propostaEletronicaPlanoCodParceiro;
        p2.mtrPropostaEletronicaPlanoPremioTotal = Mask.maskMoney(
          p.mtrPropostaEletronicaPlanoPremioTotal
        );

        if (
          planosAgrupado1.filter(
            pl =>
              pl.propostaEletronicaPlanoSigla ===
                p.propostaEletronicaPlanoSigla &&
              pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
          ).length === 0
        ) {
          planosAgrupado1.push(p2);
        }
      });

      // Agrupa por coberturas de acordo com a nomenclatura do antigo PPE
      planos.forEach(p => {
        p.propostaEletronicaPlanoCoberturas.forEach(pc => {
          planosAgrupado1.forEach(pl => {
            if (
              pl.propostaEletronicaPlanoSigla ===
                p.propostaEletronicaPlanoSigla &&
              pl.propostaEletronicaPlanoTipo ===
                p.propostaEletronicaPlanoTipo &&
              pl.propostaEletronicaPlanoId === p.propostaEletronicaPlanoId
            ) {
              pl.mtrIncendioQuedeDeRaioExplosao = Mask.maskMoney(
                pc.coberturaId === 202
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrIncendioQuedeDeRaioExplosao
              );
              pl.mtrDanosEletricos = Mask.maskMoney(
                pc.coberturaId === 205
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrDanosEletricos
              );
              pl.mtrRCOperacoes = Mask.maskMoney(
                pc.coberturaId === 222
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRCOperacoes
              );
              pl.mtrRouboDeBens = Mask.maskMoney(
                pc.coberturaId === 277
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRouboDeBens
              );
              pl.mtrPerdaDeAluguel = Mask.maskMoney(
                pc.coberturaId === 245 || pc.coberturaId === 571
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrPerdaDeAluguel
              );
              pl.mtrQuebraDeVidros = Mask.maskMoney(
                pc.coberturaId === 223
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrQuebraDeVidros
              );
              pl.mtrVendavalFumaca = Mask.maskMoney(
                pc.coberturaId === 420
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrVendavalFumaca
              );
              pl.mtrRecDoctos = Mask.maskMoney(
                pc.coberturaId === 231
                  ? pc.mtrPropostaEletronicaPlanoImpSegurada
                  : pl.mtrRecDoctos
              );
            }
          });
        });
      });

      setPlanosAgrupado(planosAgrupado1);
    }
  }, [planos]);

  useEffect(() => {
    if (planoSelected) {
      // const index = planosAgrupado
      //   .map(e => e.propostaEletronicaPlanoId)
      //   .indexOf(planoSelected.propostaEletronicaPlanoId);
      setPlanoSelectedKey([planoSelected.propostaEletronicaPlanoId]);
    } else if (propostaEletronicaPlanoId) {
      const plano = planosAgrupado.find(
        e => e.propostaEletronicaPlanoId === propostaEletronicaPlanoId
      );
      // .map(e => e.propostaEletronicaPlanoId)
      // .indexOf(propostaEletronicaPlanoId);
      setPlanoSelectedKey([propostaEletronicaPlanoId]);
      onSelectedPlano(plano);
    }
  }, [planosAgrupado]);

  function getRegraComissaoCorretora() {
    const data = {
      produtoId: Constants.Produtos.DreMitsuiEmp,
      seguradoraId: Constants.Seguradoras.Mitsui,
    };
    dispatch(ProducaoActions.getRegraComissaoCorretora(data));
  }

  function changeFieldRedux(field, value) {
    dispatch(PropostaActions.changeDreMitsuiEmp({ field, value }));
  }

  function onChangeEnderecoLocalRisco(value) {
    dispatch(
      PropostaActions.changeDreMitsuiEmp({ field: 'bemEndereco', value })
    );
  }

  function onChangeCpf(value) {
    setErros([]);
    if (!Validation.isCpfCnpjValid(value)) {
      setErros(['CNPJ do Proponente Inválido!']);
    } else {
      changeFieldRedux('propCNPJ', value);
    }
  }

  function onChangeTipoImovel(tipoImovel) {
    const param = {
      seguradoraId: Constants.Seguradoras.Mitsui,
      produtoId: Constants.Produtos.DreMitsuiEmp,
      tipoImovel,
    };

    dispatch(PlanoActions.getPlanos({ param }));
  }

  function onChangeAtividade(atividadeId) {
    const atividadeSelected = propostaEletronicaAtividades.filter(
      p => p.propostaEletronicaAtividadeId === atividadeId
    )[0];
    const param = {
      seguradoraId: Constants.Seguradoras.Mitsui,
      produtoId: Constants.Produtos.DreMitsuiEmp,
      tipoImovel: atividadeSelected.propostaEletronicaPlanoTipoId,
    };

    dispatch(PlanoActions.getPlanos({ param }));
  }

  function showCoberturas(coberturaId) {
    let isShow = false;
    if (planos) {
      planos.forEach(p => {
        p.propostaEletronicaPlanoCoberturas.forEach(pc => {
          if (pc.coberturaId === coberturaId) {
            isShow = true;
          }
        });
      });
    }

    return isShow;
  }

  function onSelectedPlano(value) {
    dispatch(
      PropostaActions.changeDreMitsuiEmp({
        field: 'planoSelected',
        value,
      })
    );
  }

  async function onSalvar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }
    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();
      dispatch(PropostaActions.save({ param }));
    } else setErros(isValid);
  }

  async function validaProposta() {
    let response = await validaObjeto(
      ProducaoValidation.ProducaoValidationDre,
      producao
    );
    if (!response.ok) return response;

    response = await validaObjeto(
      PropostaValidation.DreMitsuiEmp,
      dreMitsuiEmp
    );
    if (!response.ok) return response;

    if (!pagamento.agencia || !pagamento.debitoContaNumeroConta) {
      message.error('Por favor, informe a Agência e o Número da Conta');
      return { ok: false };
    }
    // Se boleto não estiver marcado e informar o Nosso Número
    if (!pagamento.isBoleto && pagamento.propostaEletronicaNossoNumero) {
      message.error(
        'Foi informado o Nosso Número porém nenhuma opção de Boleto está marcada, favor verificar.'
      );
      return { ok: false };
    }

    // Se não escolher nenhuma forma de pagamento boleto ou débito automático
    // if (!pagamento.isBoleto && !pagamento.isDebitoAutomatico) {
    //   message.error(
    //     'Nenhuma forma de pagamento foi selecionada. Gentileza escolher uma das opções de Boleto ou Débito Automático.'
    //   );
    //   return { ok: false };
    // }

    if (pagamento.formaPagamento === 1)
      response = await validaObjeto(PagamentoValidation.AVista, pagamento);
    else if (pagamento.formaPagamento === 2)
      response = await validaObjeto(
        PagamentoValidation.ParceladoCEntrada,
        pagamento
      );
    else
      response = await validaObjeto(
        PagamentoValidation.ParceladoSEntrada,
        pagamento
      );

    if (response && !response.ok) return response;

    return { ok: true };
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function getObjetoEnvio() {
    if (!planoSelected) return null;
    if (!endereco.clienteEnderecoId) cliente.clienteEnderecos.push(endereco);

    const bemCoberturas = [];
    if (planoSelected) {
      bemCoberturas.push({
        coberturaId: 202,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrIncendioQuedeDeRaioExplosao
        ),
      });
      bemCoberturas.push({
        coberturaId: 205,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrDanosEletricos
        ),
      });
      bemCoberturas.push({
        coberturaId: 222,
        mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRCOperacoes),
      });
      bemCoberturas.push({
        coberturaId: 277,
        mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRouboDeBens),
      });
      bemCoberturas.push({
        coberturaId: 245,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrPerdaDeAluguel
        ),
      });
      bemCoberturas.push({
        coberturaId: 223,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrQuebraDeVidros
        ),
      });
      bemCoberturas.push({
        coberturaId: 420,
        mtrBemCoberturaValor: Functions.getMoney(
          planoSelected.mtrVendavalFumaca
        ),
      });
      bemCoberturas.push({
        coberturaId: 231,
        mtrBemCoberturaValor: Functions.getMoney(planoSelected.mtrRecDoctos),
      });
    }

    const bem = {
      bemCoberturas,
      bemEndereco: getBemEndereco(),
      mtrBemDREImportanciaSegurada: Functions.getMoney(
        planoSelected.mtrIncendioQuedeDeRaioExplosao
      ),
      objetoSeguradoId: bemEndereco.tipoImovel === 1 ? 10 : 20,
    };

    const premioTotal = Functions.getMoney(
      planoSelected.mtrPropostaEletronicaPlanoPremioTotal
    );

    const perIof = 7.38 / 100 + 1;
    const iof = parseFloat(premioTotal - premioTotal / perIof);
    const premioLiquido = parseFloat(premioTotal - iof);

    const propostaEletronicaJsonContrato = {
      bens: [bem],
      contratoTipoId: producao.contratoTipoId,
      clienteId: cliente.clienteId,
      contratoDREDataInicio: dreMitsuiEmp.contratoDREDataInicio,
      contratoDREDataFim: dreMitsuiEmp.contratoDREDataFim,
      seguradoraId: Constants.Seguradoras.Mitsui,
      contratoFormaPagamentoId: getFormaPagamento(),
      mtrContratoDRE1Parcela:
        pagamento.formaPagamento === 1
          ? premioTotal
          : pagamento.mtrContratoDRE1Parcela,
      mtrContratoDREPremioTotal: premioTotal,
      mtrContratoDREIof: iof,
      mtrContratoDREFracionado: 0,
      mtrContratoDREComissao:
        premioLiquido *
        regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
      contratoDREQtdParcela:
        pagamento.formaPagamento === 1 // À vista
          ? 0
          : pagamento.contratoDREQtdParcela,
      mtrContratoDREDemaisParcelas:
        pagamento.formaPagamento === 1 // À vista
          ? null
          : pagamento.mtrContratoDRE1Parcela,
      contratoDREVencimento1Parcela: moment()
        .add(1, 'M')
        .format(),
      contratoDREDiaVencimentoDemais:
        pagamento.formaPagamento === 1
          ? 0
          : pagamento.contratoDREDiaVencimentoDemais,
      debitoConta: {
        agencia: pagamento.agencia,
        agenciaId: pagamento.agencia?.agenciaId,
        produtoId: Constants.Produtos.DreMitsuiEmp,
        debitoContaNumeroConta: pagamento.debitoContaNumeroConta,
      },
      mtrContratoDREPremioLiquido: premioLiquido,
      mtrContratoDREComissaoPercent:
        regraComissaoCorretora.mtrRegraComissaoCorretoraPercentualCorretagem,
      contratoDREObservacao: 'PROPOSTA ELETRÔNICA NOVO PPE',
      ...producao,
      contratoDREDataCadastro: moment().format(),
      produtoId: Constants.Produtos.DreMitsuiEmp,
      tipoInclusaoId: 2,
      contratoStatusId: 1,
      contratoGrupoTipoId: 1,
      regraComissaoProdutor: regraComissaoProdutor.regraComissaoProdutor,
    };

    const propostaEletronicaJsonDemaisDados = {
      isProdutor: producao.isProdutor,
      isOutrosSeguros: dreMitsuiEmp.isOutrosSeguros,
      apoliceOutrosSeg: dreMitsuiEmp.apoliceOutrosSeg,
      seguradoraOutrosSeg: dreMitsuiEmp.seguradoraOutrosSeg,
      tipoImovel: bemEndereco.tipoImovel,
      propostaEletronicaPlanoSigla: planoSelected.propostaEletronicaPlanoSigla,
      formaPagamentoTipo: pagamento.formaPagamento,
      formaPagamentoTipoCodParceiro: getFormaPagamentoTipoCodParceiro(),
      propostaEletronicaPlanoCodParceiro:
        planoSelected.propostaEletronicaPlanoCodParceiro,
      apoliceAnterior: producao.apoliceAnterior,
      seguradoraIdAnterior: producao.seguradoraIdAnterior,
      enderecoCorrepondencia: endereco,
      proponente: getDadosProponente(),
      propClausulaBeneficiaria: dreMitsuiEmp.propClausulaBeneficiaria,
      propClausulaBeneficiariaCpfCnpj:
        dreMitsuiEmp.propClausulaBeneficiariaCpfCnpj,
    };

    const objetoEnvio = {
      clienteId: cliente.clienteId,
      cliente,
      produtoId: Constants.Produtos.DreMitsuiEmp,
      moduloId: Constants.Modulos.RISCO_DIVERSOS,
      propostaEletronicaPlanoId: planoSelected.propostaEletronicaPlanoId,
      propostaEletronicaJsonContrato: JSON.stringify(
        propostaEletronicaJsonContrato
      ),
      propostaEletronicaJsonDemaisDados: JSON.stringify(
        propostaEletronicaJsonDemaisDados
      ),
      propostaEletronicaDataCadastro: moment().format(),
      propostaEletronicaNossoNumero: pagamento.propostaEletronicaNossoNumero,
    };

    return objetoEnvio;
  }

  function getDadosProponente() {
    const prop = {
      propRazaoSocial: dreMitsuiEmp.propRazaoSocial,
      propCNPJ: dreMitsuiEmp.propCNPJ,
      propAtividade: dreMitsuiEmp.propAtividade,
    };

    return prop;
  }

  function getFormaPagamentoTipoCodParceiro() {
    const isDebito = pagamento.isDebitoAutomatico;

    if (pagamento.formaPagamento === 1) return isDebito ? 39299 : 39290;
    if (pagamento.formaPagamento === 2) {
      if (pagamento.contratoDREQtdParcela === 1) return isDebito ? 39300 : 39291;
      if (pagamento.contratoDREQtdParcela === 2) return isDebito ? 39301 : 39292;
      if (pagamento.contratoDREQtdParcela === 3) return isDebito ? 39302 : 39293;
      if (pagamento.contratoDREQtdParcela === 4) return isDebito ? 39304 : 39294;
      if (pagamento.contratoDREQtdParcela === 5) return isDebito ? 39332 : 39337;
      if (pagamento.contratoDREQtdParcela === 6) return isDebito ? 39333 : 39338;
      if (pagamento.contratoDREQtdParcela === 7) return isDebito ? 39334 : 39339;
      if (pagamento.contratoDREQtdParcela === 8) return isDebito ? 39335 : 39340;
      if (pagamento.contratoDREQtdParcela === 9) return isDebito ? 39336 : 39341;
    }
    if (pagamento.formaPagamento === 3) {
      if (pagamento.contratoDREQtdParcela === 1) return 39296;
      if (pagamento.contratoDREQtdParcela === 2) return 39297;
      if (pagamento.contratoDREQtdParcela === 3) return 39298;
      if (pagamento.contratoDREQtdParcela === 4) return 39303;
      if (pagamento.contratoDREQtdParcela === 5) return 39327;
      if (pagamento.contratoDREQtdParcela === 6) return 39328;
      if (pagamento.contratoDREQtdParcela === 7) return 39329;
      if (pagamento.contratoDREQtdParcela === 8) return 39330;
      if (pagamento.contratoDREQtdParcela === 9) return 39331;
    }

    return null;
  }

  function getBemEndereco() {
    if (!bemEndereco) return null;
    return {
      bemEnderecoCep: Functions.getOnlyNum(bemEndereco.clienteEnderecoCep),
      bemEnderecoBairro: bemEndereco.clienteEnderecoBairro,
      bemEnderecoCidade: bemEndereco.clienteEnderecoCidade,
      bemEnderecoEndereco: bemEndereco.clienteEndereco1,
      bemEnderecoUf: bemEndereco.clienteEnderecoUf,
      BemEnderecoNumero: bemEndereco.clienteEnderecoNumero,
      BemEnderecoComplemento: bemEndereco.clienteEnderecoComplemento,
    };
  }

  function getFormaPagamento() {
    if (pagamento.isDebitoAutomatico) {
      if (pagamento.formaPagamento === 1) return 1; // A VISTA\DEBITO
      if (pagamento.formaPagamento === 2) return 21; // A VISTA\ DÉBITO + FRACION DÉBITO
      return 5; //	FRACION\DEBITO
    }
  
    if (pagamento.formaPagamento === 1 && pagamento.isBoleto) return 6; // A VISTA\CREDITO
    if (pagamento.formaPagamento === 2 && pagamento.isBoleto) return 8; // FRAC\CRED\CARNE
    if (pagamento.formaPagamento === 3 && pagamento.isBoleto) return 5; //	FRACION\DEBITO
  
    return 5; //	FRACION\DEBITO
  }

  async function onEfetivar() {
    setErros([]);
    if (!regraComissaoProdutor) {
      message.error(
        'Não foi encontrado regra para geração da comissão do Produtor.'
      );
      return;
    }
    const isValid = await validaProposta();

    if (isValid.ok) {
      const param = getObjetoEnvio();

      dispatch(
        PropostaActions.efetivar({ param, callback: callbackErrorEfetivar })
      );
    } else setErros(isValid);
  }

  function callbackErrorEfetivar(values) {
    setErros(values);
  }

  function Botoes() {
    return (
      <div className="actions-tabs" style={{ paddingBottom: 10 }}>
        <Button size="am" onClick={onPrevius} with="link" type="primary">
          <ArrowLeftOutlined /> Voltar
        </Button>

        {!contratoId && (
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            onClick={onSalvar}
          >
            <SaveOutlined /> Salvar
          </Button>
        )}
        {propostaId > 0 && !contratoId && (
          <Popconfirm
            placement="topLeft"
            title="Deseja realmente efetivar esse contrato?"
            onConfirm={onEfetivar}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              size="sm"
              style={{
                marginLeft: '.5rem',
              }}
              type="info"
            >
              <CheckOutlined /> Efetivar
            </Button>
          </Popconfirm>
        )}
        {propostaId > 0 && (
          <Button
            type="primary"
            size="sm"
            style={{ marginLeft: '.5rem' }}
            target="_blank"
            href="/impressao-dre-mitsui-emp"
          >
            <PrinterOutlined /> Imprimir
          </Button>
        )}
      </div>
    );
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }

  function Erros() {
    return (
      <ul>
        {erros.map(e => (
          <li>{e}</li>
        ))}
      </ul>
    );
  }

  return (
    <>
      <BackTop />
      <Spin spinning={loadingProposta} size="large">
        <Botoes />
        <Container>
          {contratoId > 0 && (
            <Result
              status="success"
              title="Parabéns, produto contratado com sucesso!"
              subTitle={`O número do contrato no Multiseguros é: ${contratoId} e você já pode consultá-lo lá.`}
              extra={[
                <Button
                  type="primary"
                  href={`https://multiseguros.brbseguros.com.br/#/proposta-dre/${contratoId}/11806`}
                  target="_blank"
                  key="1"
                >
                  Ir ao Multiseguros
                </Button>,
              ]}
            />
          )}

          {erros.length > 0 && (
            <Alert
              message="Atenção, verifique os campos abaixo:"
              description={<Erros />}
              type="error"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}

          <Disable disable={!!contratoId}>
            <h4>Empresarial Mitsui</h4>

            <FieldsProducao produto="empresarial-mitsui" />

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Início da Vigência</label> <br />
                  <DatePicker
                    value={dreMitsuiEmp.contratoDREDataInicio}
                    placeholder="Selecione"
                    onChange={value => {
                      changeFieldRedux('contratoDREDataInicio', value);
                      changeFieldRedux(
                        'contratoDREDataFim',
                        moment(value).add(1, 'y')
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Fim da Vigência</label> <br />
                  <DatePicker
                    value={dreMitsuiEmp.contratoDREDataFim}
                    placeholder="Selecione"
                    onChange={value =>
                      changeFieldRedux('contratoDREDataFim', value)
                    }
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <div className="form-group">
                  <label>Há outros seguros cobrindo os mesmos bens?</label>{' '}
                  <br />
                  <Radio.Group
                    onChange={({ target: { value } }) =>
                      changeFieldRedux('isOutrosSeguros', value)
                    }
                    value={isOutrosSeguros}
                  >
                    <Radio value={1}>Sim</Radio>
                    <Radio value={0}>Não</Radio>
                  </Radio.Group>
                </div>
              </Col>

              {isOutrosSeguros === 1 && (
                <Row gutter={[16, 16]}>
                  <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                    <div className="form-group">
                      <label>Apólice Nº</label>
                      <Input
                        placeholder="Apólice Nº"
                        value={apoliceOutrosSeg}
                        onChange={value => setApoliceOutrosSeg(value)}
                        onBlur={value =>
                          changeFieldRedux('apoliceOutrosSeg', value)
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                    <div className="form-group">
                      <label>Seguradora</label>
                      <Select
                        defaultValue="Selecione"
                        itens={seguradoras}
                        itemValue="seguradoraId"
                        itemText="seguradoraDescricao"
                        itemTextSearch="seguradoraDescricao"
                        renderText={item =>
                          `${item.seguradoraId} - ${item.seguradoraDescricao}`
                        }
                        value={dreMitsuiEmp.seguradoraOutrosSeg}
                        onChange={value =>
                          changeFieldRedux('seguradoraOutrosSeg', value)
                        }
                        showSearch
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Row>

            <TitleSection title="Dados do Proponente" />
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <div className="form-group">
                  <label>Razão Social</label>
                  <Input
                    value={propRazaoSocial}
                    onChange={value => setPropRazaoSocial(value)}
                    onBlur={value => changeFieldRedux('propRazaoSocial', value)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>CNPJ</label>
                  <Input
                    value={propCNPJ}
                    onChange={value => setPropCNPJ(value)}
                    onBlur={value => onChangeCpf(value)}
                    mask="cpfCnpj"
                    maxLength={18}
                  />
                </div>
              </Col>
            </Row>

            <FieldsEndereco
              title="Local de Risco"
              onChangeEndereco={onChangeEnderecoLocalRisco}
              enderecoRedux={dreMitsuiEmp.bemEndereco}
              onChangeTipoImovel={onChangeTipoImovel}
              onChangeAtividade={onChangeAtividade}
              produtoId={Constants.Produtos.DreMitsuiEmp}
            />

            <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>Cláusula Beneficiária</label>
                  <Input
                    value={dreMitsuiEmp.propClausulaBeneficiaria}
                    onChange={value =>
                      changeFieldRedux('propClausulaBeneficiaria', value)
                    }
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                <div className="form-group">
                  <label>CPF / CNPJ</label>
                  <Input
                    value={dreMitsuiEmp.propClausulaBeneficiariaCpfCnpj}
                    onChange={value =>
                      changeFieldRedux('propClausulaBeneficiariaCpfCnpj', value)
                    }
                    mask="cpfCnpj"
                    maxLength={18}
                  />
                </div>
              </Col>
            </Row>
          </Disable>

          <TitleSection title="Opções de planos" />
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Table
                rowKey="propostaEletronicaPlanoId"
                dataSource={planosAgrupado}
                pagination={false}
                loading={loading}
                size="small"
                scroll={{ x: 1000 }}
                rowSelection={{
                  type: 'radio',
                  onChange: (selectedRowKeys, selectedRows) => {
                    onSelectedPlano(selectedRows[0]);
                    setPlanoSelectedKey(selectedRowKeys);
                  },
                  selectedRowKeys: planoSelectedKey,
                  getCheckboxProps: () => ({
                    disabled: !!contratoId,
                  }),
                }}
              >
                {planosAgrupado.length > 0 && (
                  <Table.Column
                    title="Plano"
                    dataIndex="propostaEletronicaPlanoSigla"
                  />
                )}
                {showCoberturas(202) && (
                  <Table.Column
                    title="Incêndio/ Queda de Raio/ Explosão"
                    dataIndex="mtrIncendioQuedeDeRaioExplosao"
                  />
                )}
                {showCoberturas(205) && (
                  <Table.Column
                    title="Danos Elétricos"
                    dataIndex="mtrDanosEletricos"
                  />
                )}
                {showCoberturas(222) && (
                  <Table.Column
                    title="RC Operações"
                    dataIndex="mtrRCOperacoes"
                  />
                )}
                {showCoberturas(277) && (
                  <Table.Column
                    title="Roubo de Bens"
                    dataIndex="mtrRouboDeBens"
                  />
                )}
                {showCoberturas(245) && (
                  <Table.Column
                    title="Perda de Aluguel"
                    dataIndex="mtrPerdaDeAluguel"
                  />
                )}
                {showCoberturas(223) && (
                  <Table.Column
                    title="Quebra de Vidros"
                    dataIndex="mtrQuebraDeVidros"
                  />
                )}
                {showCoberturas(420) && (
                  <Table.Column
                    title="Vendaval até Fumaça"
                    dataIndex="mtrVendavalFumaca"
                  />
                )}
                {showCoberturas(231) && (
                  <Table.Column
                    title="Rec de Doctos"
                    dataIndex="mtrRecDoctos"
                  />
                )}
                {planosAgrupado.length > 0 && (
                  <Table.Column
                    title="Prêmio"
                    dataIndex="mtrPropostaEletronicaPlanoPremioTotal"
                  />
                )}
              </Table>
            </Col>
          </Row>
          <span>
            <i>Remuneração pela intermediação de 40% sobre o prêmio</i>
          </span>
          <Disable disable={!!contratoId}>
            <Pagamento />
          </Disable>
        </Container>
        <Botoes />
      </Spin>
    </>
  );
}
