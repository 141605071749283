import React, { useState } from 'react';
import { Modal, Button } from 'antd';

type ModalInfoProp = {
    title: String,
    info: String
}

export default function ModalInfo({ title, info }: ModalInfoProp) {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <i className='fad fa-info-circle' onClick={() => setVisible(true)} ></i>
            <Modal
                title={title}
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={1000}
            >
                <p>{info}</p>
            </Modal>
        </>
    );
};