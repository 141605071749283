/* Persistor */
import { persistStore } from 'redux-persist';
/* Saga */
import configureStore from './configureStore';
import sagas from './sagas';

/* Ducks */
import reducers from './ducks';

/* Export Store */
export const store = configureStore(reducers, sagas);

/* Export Persistor */
export const persistor = persistStore(store);
