import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import moment from 'moment';

import ptBR from 'antd/es/locale/pt_BR';

import '~/config/reactotronConfig';

import { store, persistor } from './store';

import Routes from '~/routes';
import history from '~/services/history';

import GlobalStyle from '~/styles/global';

moment.defineLocale('pt-br', {
  weekdaysMin: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
  monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ConfigProvider locale={ptBR}>
            <Routes />
          </ConfigProvider>
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
