import api from '.';

export const search = data => api().get('api/propostas', data);
export const searchPropostasCanceladas = data => api().get('api/propostas/canceladas', data);
export const add = data => api().post('api/propostas', data);
export const update = data => api().put('api/propostas', data);
export const efetivar = data => api().post('api/propostas/efetivar', data);
export const efetivarComArquivo = data => api().post('api/propostas/efetivar-com-arquivo', data, { headers: { 'ContentType': 'x-www-url-formencoded' } });
export const searchById = data => api().get(`api/propostas/${data}`);
export const searchPerguntasProduto = data =>
  api().get('api/propostas/perguntas-produto', data);
export const getArquivoImpressaoById = data =>
  api().get(`api/propostas/get-arquivo-impressao-by-id/${data}`, null, {
    responseType: 'blob',
  });
