import { Input as InputAntd } from 'antd';
import React from 'react';
import { Mask } from '~/utils';

type InputProps = {
  placeholder: String,
  value: String,
  name: String,
  disabled: Boolean,
  onChange: Function,
  mask:
    | 'cpfCnpj'
    | 'cep'
    | 'money'
    | 'peso'
    | 'altura'
    | 'maskContaBrb'
    | 'money'
    | 'decimal'
    | 'phone',
  maxLength: Number,
  id: String,
  style: React.CSSProperties,
  accept: 'onlyNumbers' | '',
  onBlur: Function,
  prefix: String | Function,
  suffix: String | Function,
  autoComplete: String,
};

type OnChangeParamProps = {
  target: {
    value: String,
    name: String,
  },
};

export default function Input({
  placeholder,
  value,
  name,
  disabled,
  onChange,
  mask,
  maxLength,
  id,
  style,
  accept,
  onBlur,
  prefix,
  suffix,
  autoComplete,
}: InputProps) {
  function _onChange({ target: { value: v, name: n } }: OnChangeParamProps) {
    if (!onChange) return;

    if (mask === 'cpfCnpj') {
      onChange(Mask.maskCpfCnpj(v), n);
      return;
    }

    if (mask === 'cep') {
      onChange(Mask.maskCep(v), n);
      return;
    }

    if (accept === 'onlyNumbers') {
      onChange(Mask.onlyNumbers(v), n);
      return;
    }

    if (mask === 'peso') {
      onChange(Mask.peso(v), n);
      return;
    }

    if (mask === 'altura') {
      onChange(Mask.altura(v), n);
      return;
    }

    if(mask === 'decimal') {
      onChange(Mask.decimal(v), n)
      return;
    }

    if (mask === 'maskContaBrb') {
      onChange(Mask.maskContaBrb(v), n);
      return;
    }

    if (mask === 'money') {
      onChange(Mask.maskMoneyOn(v), n);
      return;
    }

    if (mask === 'phone') {
      onChange(Mask.maskPhone(v), n);
      return;
    }

    onChange(v, n);
  }

  function _onBlur({ target: { value: v, name: n } }: OnChangeParamProps) {
    if (onBlur) onBlur(v?.trim(), n);
  }

  return (
    <InputAntd
      placeholder={placeholder}
      onChange={_onChange}
      value={value}
      disabled={disabled}
      name={name}
      maxLength={maxLength}
      id={id}
      autoComplete={autoComplete || 'on'}
      style={style}
      onBlur={_onBlur}
      prefix={prefix}
      suffix={suffix}
    />
  );
}
