import { Col, Modal, Row, Tabs, Tooltip } from 'antd';
import React, { useState } from 'react';

const { TabPane } = Tabs;

type ModalInfoAssistenciasProp = {
    title: String,
    info: String
}

const assistencias = [
    {
        nome: 'Assistência Básica',
        servicosEmergenciais: ['Vidraceiro', 'Chaveiro', 'Hidráulica', 'Elétrica'],
        servicosVinculadosASinistro: ['Limpeza da Residência', 'Vigilância', 'Mudança de Móveis', 'Guarda Provisória de Móveis', 'Cobertura Provisória de Telhados'],
        outrosServicos: ['Help Desk']
    },
    {
        nome: 'Assistência Intermediária',
        servicosEmergenciais: ['Vidraceiro', 'Chaveiro', 'Hidráulica', 'Elétrica'],
        servicosVinculadosASinistro: [
            'Limpeza da Residência', 'Vigilância', 'Mudança de Móveis', 'Guarda Provisória de Móveis','Cobertura Provisória de Telhados', 
        ],
        outrosServicos: ['Hospedagem Alternativa', 'Regresso Antecipado', 'Recuperação de Veículo', 'Guarda de Animais Domésticos', 'Help Desk'],
        demaisAssistencias: [
            { nome:'Linha branca (Cozinha)', assistencias: [
                'Fogão a gás', 'Cooktop a gás', 'Forno Elétrico', 'Forno a gás', 'Micro-ondas', 'Refrigerador (Geladeira)',
                'Freezers', 'Lava-louças', 'Geladeira side by side'
            ]}, 
            { nome:'Linha branca (Lavanderia)', assistencias: [
                'Máquina de lavar roupas', 'Máquina lava e seca', 'Máquina de secar roupas', 'Centrífuga de roupas',
                'Tanquinho', 'Reversão de fogão', 'Conserto de ar condicionado (Split ou Mini Split)'

            ]}, 
            { nome:'Assistência Geral', assistencias: [
                'Limpeza de Calhas (até 20 metros de comprimento) - casa', 'Caçamba (em caso de reforma, uma caçamba, até 3 dias corridos)',
                'Limpeza de ar condicionado - até 2 equipamentos (Split ou Mini Split)', 'Instalação de ventilador de teto (até 2 unidades)'
            ]}, 
            { nome: 'Elétrica', assistencias: [
                'Instalação de chuveiro elétrico (não blindado)', 'Instalação de torneira elétrica (não blindada)', 
                'Troca de lâmpadas, tomadas e interruptores (até 10 unidades)',
                'Revisão de instalação elétrica',
            ] }, 
            { nome: 'Hidráulica', assistencias: [
                'Desentupimento de tubulação de esgoto', `Limpeza de caixa d'água (até 2 caixas d’água de até 3.000 litros, cada)`,
                'Limpeza de ralos e sifões (até 4 ralos e/ou sifões)', 'Verificação de possíveis vazamentos (caça vazamentos)'
            ] } 
        ],
        linhaBranca: true
    },
    {
        nome: 'Assistência Superior',
        servicosEmergenciais: ['Vidraceiro', 'Chaveiro', 'Hidráulica', 'Elétrica'],
        servicosVinculadosASinistro: [
            'Limpeza da Residência', 'Vigilância', 'Mudança de Móveis', 'Guarda Provisória de Móveis', 'Cobertura Provisória de Telhados',
            'Hospedagem Alternativa', 'Regresso Antecipado', 'Recuperação de Veículo', 'Guarda de animais domésticos'
        ],
        outrosServicos: ['Help Desk'],
        demaisAssistencias: [
            { nome:'Linha Branca (Cozinha)', assistencias: [
                'Fogão a gás', 'Cooktop a gás', 'Forno Elétrico', 'Forno a gás', 'Micro-ondas', 'Refrigerador (Geladeira)',
                'Freezers', 'Lava-louças', 'Geladeira side by side'
                ]
            }, 
            { nome:'Linha Branca (Lavanderia)', assistencias: [
                'Máquina de lavar roupas', 'Máquina lava e seca', 'Máquina de secar roupas', 'Centrífuga de roupas',
                'Tanquinho', 'Reversão de fogão', 'Conserto de ar condicionado (Split ou Mini Split)'

                ]
            }, 
            { nome:'Linha Marrom', assistencias: [
                'Televisão (convencional, Led, LCD e Plasma)', 'Aparelho de Som', 'DVD', 'Blu-ray Player',
                'Home-Theater', 'Vídeo game (não portátil)', 'Conserto de aquecedor a gás '

                ]
            },
            { nome: 'Elétrica', assistencias: [
                'Instalação de chuveiro elétrico (não blindado)', 'Instalação de torneira elétrica (não blindada)', 
                'Troca de lâmpadas, tomadas e interruptores (até 10 unidades)', 'Revisão de instalação elétrica',
                ] 
            }, 
            { nome: 'Hidráulica', assistencias: [
                'Desentupimento de tubulação de esgoto', `Limpeza de caixa d'água (até 2 caixas d’água de até 3.000 litros, cada)`,
                'Limpeza de ralos e sifões (até 4 ralos e/ou sifões)', 'Verificação de possíveis vazamentos (caça vazamentos)'
                ] 
            } ,
            { nome: 'Assistência PET', assistencias: [
                'Consulta Veterinária Emergencial', 'Cremação Funerária Pet', 'Assistência Funeral Pet',
                'Aplicação de vacinas em domicílio (visita)'
                ] 
            },
            { nome:'Assistência Geral', assistencias: [
                'Limpeza de Calhas (até 20 metros de comprimento) - casa', 'Caçamba (em caso de reforma, uma caçamba, até 3 dias corridos)',
                'Limpeza de ar condicionado - até 2 equipamentos (Split ou Mini Split)', 'Instalação de ventilador de teto (até 2 unidades)',
                'Instalação de tela de segurança, até 20m2 (casa ou apartamento)', 'Dedetização'
                ]
            }, 
            { nome:'Assistência Acessibilidade', assistencias: [
                'Instalação de assento dentro do box', 'Instalação de barra de apoio', 'Troca da válvula de descarga',
                'Troca de fechaduras de portas internas ou externas', 'Instalação de interruptores',
                'Instalação de luz de emergência nos principais cômodos. Limite: até 05 unidades',
                'Instalação ou troca de maçanetas de alavanca', 'Instalação ou troca de pia (lavatório)',
                'Instalação ou troca de porta', 'Instalação ou troca de torneiras com alavanca', 'Adaptação (troca) de vaso sanitário',
                `Rampa: Construção de uma rampa de acesso à residência (altura 3 ou 4 degraus) próximos à porta de entrada,
                garagem ou quintal`

                ]
            }            
        ],
    }
]

export default function ModalInfoAssistencias({ title, info }: ModalInfoAssistenciasProp) {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <Tooltip placement="right" title="Clique para obter informações">
                <i style={ {marginLeft: "6px"} } className='fad fa-info-circle' onClick={() => setVisible(true)} ></i>
            </Tooltip>
            <Modal
                title='Tipo de Assistências'
                centered
                visible={visible}
                closable={false}
                keyboard={false}
                onOk={() => setVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={1000}
            >
                <Tabs defaultActiveKey="1">
                    {
                        assistencias.map((x, i) => (
                            
                                <TabPane tab={x.nome} key={(i + 1)}>
                                    <Row gutter={[16, 16]} key={x.nome}>
                                        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                            {
                                                x.servicosEmergenciais && !!x.servicosEmergenciais.length &&
                                                <>
                                                    <h6>Serviços Emergenciais</h6>
                                                    { 
                                                        x.servicosEmergenciais.map(y => (
                                                            <ul key={y}>{y}</ul>
                                                        )) 
                                                    }
                                                </>
                                            }
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                            {
                                                x.servicosVinculadosASinistro && !!x.servicosVinculadosASinistro.length &&
                                                <>
                                                    <h6>Serviços vinculados a sinistro</h6>
                                                    { 
                                                        x.servicosVinculadosASinistro.map(y => (
                                                            <ul key={y}>{y}</ul>
                                                        )) 
                                                    }
                                                </>
                                            }
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                            {
                                                x.outrosServicos && !!x.outrosServicos.length &&
                                                <>
                                                    <h6>Outros Serviços</h6>
                                                    { 
                                                        x.outrosServicos.map(y => (
                                                            <ul key={y}>{y}</ul>
                                                        )) 
                                                    }
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    {
                                        ((x.demaisAssistencias && !!x.demaisAssistencias.length)) &&
                                        <>
                                            <Row><h6 style={{padding: '8px'}}>Demais assistências</h6></Row>
                                            <Row gutter={[16, 16]} type="flex">
                                                <br/>
                                                { 
                                                    x.demaisAssistencias.map(y => (
                                                        !!y.assistencias && !!y.assistencias.length && 
                                                        
                                                        <Col key={y.nome} xs={{ span: 24 }} sm={{ span: 8 }}>
                                                            <span>{y.nome}</span>

                                                            <ul style={{ marginTop: '10px' }}>
                                                                {y.assistencias.map(z => 
                                                                    <li key={z}><em>{z}</em></li>
                                                                )}
                                                            </ul>
                                                        </Col>

                                                    )) 
                                                }
                                            </Row>
                                        </>
                                    }
                                </TabPane>
                        ))
                    }
                </Tabs>

                

                <br />
                <br />
                <Row>
                    <p><em>*Consultar particularidades das assistências nas condições gerais do produto.</em></p>
                </Row>
            </Modal>
        </>
    );
};