import { call, takeLatest, put } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse } from '~/api/utils';
import * as AcumuloRiscoApi from '~/api/acumulo-risco.api'
import * as AcumuloRiscoActions from '~/store/ducks/acumulo-risco'

function* getAcumuloRiscoByCpf(action) {

  // Call api
  const response = getResponse(yield call(AcumuloRiscoApi.getAcumuloRiscoByCpf, action.payload));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

    // Salva o retorno
    yield put(AcumuloRiscoActions.saveAcumuloRisco(data));
}

function* fail(message) {
  yield put(AcumuloRiscoActions.falha());
  toast.error(message);
}

export default takeLatest(AcumuloRiscoActions.types.GET_ACUMULO_RISCO_BY_CPF, getAcumuloRiscoByCpf);
