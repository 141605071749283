import React, { useEffect } from 'react';

import { Row, Col, Skeleton, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Block, BlockTable } from './styles';

import * as DominiosActions from '~/store/ducks/dominio';

import imgResidenciaPremiado from '~/assets/img/brb_residencia_premiado.jpg';
import imgLiberty from '~/assets/img/liberty.png';
import { Functions } from '~/utils';

type FieldProps = {
  span: Number,
  title: String,
  value: String,
};

export default function Impressao() {
  const {
    generos,
    estadosCivis,
    naturalidade,
    consultores,
    angariadores,
    agencias,
    loading,
  } = useSelector(({ dominio }) => dominio);
  const { cliente } = useSelector(state => state.cliente);
  const {
    contratoId,
    propostaId,
    dreLibertyCeS,
    dreLibertyCeS: { bemEndereco, planoSelected, pagamento },
    producao,
  } = useSelector(({ proposta }) => proposta);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DominiosActions.listAll(print));
  }, []);

  function print() {
    setTimeout(function () {
      window.focus();
      window.print();
    }, 2000); // wait for images to load inside iframe
  }

  function Field({ span, title, value }: FieldProps) {
    if (!value) return <></>;
    return (
      <Col span={span}>
        <b>{title}:</b> {value}
      </Col>
    );
  }

  function getDescricaoDominio(dominio, textValue, textDescription, value) {
    if (!value) return 'Não Informado';
    return dominio.find(d => d[textValue] === value)?.[textDescription];
  }

  function getFormaPagamento() {
    const parcela = Functions.formatarMoeda(pagamento.mtrContratoDRE1Parcela);
    if (pagamento.formaPagamento === 1) return `À Vista R$ ${parcela}`;

    if (pagamento.formaPagamento === 2)
      return ` 1 + ${pagamento.contratoDREQtdParcela} Parcela(s) SEM JUROS de R$ ${parcela}`;

    if (pagamento.formaPagamento === 3)
      return ` 0 + ${pagamento.contratoDREQtdParcela +
        1} Parcela(s) SEM JUROS de R$ ${parcela}`;

    return '';
  }

  return (
    <>
      <Spin
        spinning={loading}
        style={{ textAlign: 'center' }}
        size="large"
        tip="Aguarde carregando"
      >
        {loading && <Skeleton active />}
      </Spin>
      {generos && angariadores && consultores && (
        <Container>
          {/* Dados Proposta */}
          <Block>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col span={10} style={{ paddingLeft: 4 }}>
                <img src={imgResidenciaPremiado} alt="BRB" />
              </Col>
              <Col span={6} style={{ alignItems: 'center' }}>
                <b>{contratoId ? 'Proposta de Seguro' : 'Cotação de Seguro'}</b>
                <br />
                {contratoId
                  ? `Número da Proposta: ${contratoId}`
                  : `Número da Cotação: ${propostaId}`}
              </Col>
              <Col
                span={8}
                style={{
                  textAlign: 'right',
                  paddingRight: 10,
                }}
              >
                <div ng-show="vm.ProdutoId == vm.produtoLibertyDRE">
                  <img src={imgLiberty} alt="Logo" width="70" />
                </div>
              </Col>
            </Row>
          </Block>
          {/* Tipo Seguro */}
          <Block>
            <Row>
              <b>Tipo de Seguro: </b> Seguro Novo
            </Row>
            <Row>
              <b>Vigência do Seguro: </b> A partir das 24h de
              {Functions.formatarData(dreLibertyCeS.contratoDREDataInicio)}
              até as 24h de{' '}
              {Functions.formatarData(dreLibertyCeS.contratoDREDataFim)}
            </Row>
          </Block>
          {/* Dados do Cliente */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PROPONENTE</b>
            </Row>
            <Row>
              <Field span={10} title="Nome" value={cliente.clienteNome} />
              <Field span={8} title="CPF" value={cliente.clienteCpfCnpj} />
              <Field
                span={6}
                title="Data de Nascimento"
                value={Functions.formatarData(cliente.clienteDataNascimento)}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Sexo"
                value={getDescricaoDominio(
                  generos,
                  'sexoId',
                  'sexoDescricao',
                  cliente.sexoId
                )}
              />
              <Field
                span={8}
                title="Estado Civil"
                value={getDescricaoDominio(
                  estadosCivis,
                  'estadoCivilId',
                  'estadoCivilDescricao',
                  cliente.estadoCivilId
                )}
              />
              <Field
                span={6}
                title="Nacionalidade"
                value={getDescricaoDominio(
                  naturalidade,
                  'naturalidadeId',
                  'naturalidadeDescricao',
                  cliente.naturalidadeId
                )}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Nº da Identidade"
                value={cliente.clienteRg}
              />
              <Field
                span={8}
                title="Data de emissão"
                value={Functions.formatarData(cliente.clienteDataExpedicaoRg)}
              />
              <Field
                span={6}
                title="Orgão Emissor"
                value={cliente.clienteRgSsp}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Telefone Residencial"
                value={getDescricaoDominio(
                  cliente.clienteContatoFones,
                  'clienteContatoFoneTipoId',
                  'ClienteContatoFoneNumero',
                  1
                )}
              />
              <Field
                span={8}
                title="Telefone Comercial"
                value={getDescricaoDominio(
                  cliente.clienteContatoFones,
                  'clienteContatoFoneTipoId',
                  'ClienteContatoFoneNumero',
                  2
                )}
              />
              <Field
                span={6}
                title="Telefone Celular"
                value={getDescricaoDominio(
                  cliente.clienteContatoFones,
                  'clienteContatoFoneTipoId',
                  'ClienteContatoFoneNumero',
                  4
                )}
              />
            </Row>
            <Row>
              <Field
                title="E-mail"
                value={
                  cliente.clienteContatoMails[0]?.clienteContatoMailDescricao
                }
              />
            </Row>
          </Block>
          {/* Local de Risco */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>LOCAL DE RISCO</b>
            </Row>
            <Row>
              <Field
                span={10}
                title="Tipo de Imóvel"
                value={bemEndereco.tipoImovel === 1 ? 'APARTAMENTO' : 'CASA'}
              />
              <Field
                span={8}
                title="CEP"
                value={bemEndereco.clienteEnderecoCep}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Endereço de Risco"
                value={bemEndereco.clienteEndereco1}
              />
              <Field
                span={8}
                title="Número"
                value={bemEndereco.clienteEnderecoNumero}
              />
              <Field
                span={6}
                title="Complemento"
                value={bemEndereco.clienteEnderecoComplemento}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Bairro"
                value={bemEndereco.clienteEnderecoBairro}
              />
              <Field
                span={8}
                title="Cidade"
                value={bemEndereco.clienteEnderecoCidade}
              />
              <Field
                span={6}
                title="UF"
                value={bemEndereco.clienteEnderecoUf}
              />
            </Row>
            <Row>
              <Field
                span={18}
                title="Cláusula beneficiária a fator de"
                value={cliente.clienteNome}
              />
              <Field span={6} title="CPF/CNPJ" value={cliente.clienteCpfCnpj} />
            </Row>
          </Block>
          {/* Plano Selecionado */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PLANO</b>
            </Row>
            <Row type="flex" align="middle">
              <BlockTable minHeight bold>
                Plano
              </BlockTable>
              <BlockTable minHeight bold>
                Incêndio/ Queda de Raio/ Explosão
              </BlockTable>
              <BlockTable minHeight bold>
                Perda de Aluguel
              </BlockTable>
              <BlockTable minHeight bold>
                Danos Elétricos
              </BlockTable>
              <BlockTable minHeight bold>
                Roubo de Bens
              </BlockTable>
              <BlockTable minHeight bold>
                Despesas Fixas
              </BlockTable>
              <BlockTable minHeight bold>
                Vendaval Granizo e Impacto de Veículos
              </BlockTable>
              <BlockTable minHeight bold>
                RC Estab. Comerciais
              </BlockTable>
              <BlockTable minHeight bold>
                Prêmio
              </BlockTable>
            </Row>
            <Row type="flex" align="middle">
              <BlockTable>
                {planoSelected.propostaEletronicaPlanoSigla}
              </BlockTable>
              <BlockTable>
                {planoSelected.mtrIncendioQuedeDeRaioExplosao}
              </BlockTable>
              <BlockTable>{planoSelected.mtrPerdaDeAluguel}</BlockTable>
              <BlockTable>{planoSelected.mtrDanosEletricos}</BlockTable>
              <BlockTable>{planoSelected.mtrRouboDeBens}</BlockTable>
              <BlockTable>{planoSelected.mtrDespesasFixas}</BlockTable>
              <BlockTable>{planoSelected.mtrVendavalGranizo}</BlockTable>
              <BlockTable>{planoSelected.mtrRcEstabComerciais}</BlockTable>
              <BlockTable>
                {planoSelected.mtrPropostaEletronicaPlanoPremioTotal}
              </BlockTable>
            </Row>
            <Row>
              <span><i>Remuneração pela intermediação de 50% sobre o prêmio</i></span>
            </Row>
          </Block>
          {/* Forma de Pagamento */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>FORMA DE PAGAMENTO</b>
            </Row>
            <Row>
              <Col span={6}>{getFormaPagamento()}</Col>
              <Field
                title="Nosso Número"
                value={pagamento.propostaEletronicaNossoNumero}
              />
            </Row>
          </Block>
          {/* Termo e Assinatura */}
          <Block>
            <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
              Concordo com a forma de pagamento especificada autorizando o
              débito, na referida conta-corrente, do valor relativo à(s)
              parcela(s) do prêmio devido pela contratação deste seguro. Estou
              ciente de que a inadimplência de quaisquer das parcelas do prêmio
              no(s) seu(s) vencimento(s), decorrente de insuficiência de saldo,
              implicará na suspensão imediata das coberturas contratadas,
              observados os termos da Cláusula de Pagamento de Prêmio das
              Condições Gerais do Seguro. Declaro que as informações constantes
              nesta proposta forma por mim prestadas e, em sendo apurada
              qualquer divergência naquelas que serviram de base para a taxação
              do risco, por omissão ou incorreção desses dados, estou ciente que
              poderei perder o direito a uma eventual indenização, nos termos
              previstos no Código Civil Brasileiro e na legislação pertinente,
              devendo ainda efetuar o pagamento do prêmio vencido. Declaro ainda
              que li e concordo com as Condições Gerais do Seguro em questão,
              que me foram apresentadas previamente à assinatura da presente
              proposta.
            </Row>
            <Row>
              <Field
                span={14}
                title="Local e data"
                value={`Brasília, ${Functions.getDateNow()}`}
              />
              <Col span={6} style={{ textAlign: 'center' }}>
                ________________________________________________
                <br />
                <b>Assinatura do Proponente</b>
              </Col>
            </Row>
          </Block>
          {/* Dados Débito Conta */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS PARA DÉBITO EM CONTA CORRENTE</b>
            </Row>
            <Row>
              <Field span={6} title="Banco" value={pagamento.agencia.bancoId} />
              <Field
                span={6}
                title="Agência"
                value={pagamento.agencia.agenciaCodigo}
              />
              <Field
                span={6}
                title="Conta Corrente Nº"
                value={pagamento.debitoContaNumeroConta}
              />
              {pagamento.contratoDREDiaVencimentoDemais && (
                <Field
                  span={6}
                  title="Dia Preferencial para Pagamento"
                  value={pagamento.contratoDREDiaVencimentoDemais}
                />
              )}
            </Row>
          </Block>
          {/* Dados Produção */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DA PRODUÇÃO</b>
            </Row>
            <Row>
              <Field
                span={6}
                title="Mat. Consultor"
                value={getDescricaoDominio(
                  consultores,
                  'produtorId',
                  'produtorCodigo',
                  producao.consultorId
                )}
              />
              <Field
                span={6}
                title="Mat. Angariador"
                value={getDescricaoDominio(
                  angariadores,
                  'produtorId',
                  'produtorCodigo',
                  producao.angariadorId
                )}
              />
              <Field
                span={6}
                title="Agência Produtora"
                value={getDescricaoDominio(
                  agencias,
                  'agenciaId',
                  'agenciaCodigo',
                  producao.agenciaId
                )}
              />
              <Field span={6} title="Equipe" value={producao.equipeId} />
            </Row>
          </Block>
        </Container>
      )}
    </>
  );
}
