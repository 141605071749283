import React, { useEffect } from 'react';

import { Row, Col, Spin, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Block, BlockTable } from './styles';

import * as DominiosActions from '~/store/ducks/dominio';

import imgResidenciaPremiado from '~/assets/img/brb_residencia_premiado.jpg';
import imgMitsui from '~/assets/img/mitsui.jpg';
import { Functions, Mask } from '~/utils';

type FieldProps = {
  span: Number,
  title: String,
  value: String,
};

export default function Impressao() {
  const {
    generos,
    estadosCivis,
    naturalidade,
    consultores,
    angariadores,
    agencias,
    loading,
  } = useSelector(({ dominio }) => dominio);
  const { cliente } = useSelector(state => state.cliente);
  const {
    contratoId,
    propostaId,
    dreMitsui,
    dreMitsui: { bemEndereco, planoSelected, pagamento },
    producao,
  } = useSelector(({ proposta }) => proposta);

  const { usuario } = useSelector(({ auth }) => auth);

  const isCnp = usuario?.descTipoProdutor === 'CNP';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DominiosActions.listAll(print));
  }, []);

  function print() {
    setTimeout(function() {
      window.focus();
      window.print();
    }, 2000); // wait for images to load inside iframe
  }

  function Field({ span, title, value }: FieldProps) {
    if (!value) return <></>;
    return (
      <Col span={span}>
        <b>{title}:</b> {value}
      </Col>
    );
  }

  function getDescricaoDominio(dominio, textValue, textDescription, value) {
    if (!value) return 'Não Informado';
    return dominio.find(d => d[textValue] === value)?.[textDescription];
  }

  function getFormaPagamento() {
    const parcela = Functions.formatarMoeda(pagamento.mtrContratoDRE1Parcela);
    if (pagamento.formaPagamento === 1) return `À Vista R$ ${parcela}`;

    if (pagamento.formaPagamento === 2)
      return ` 1 + ${pagamento.contratoDREQtdParcela} Parcela(s) SEM JUROS de R$ ${parcela}`;

    if (pagamento.formaPagamento === 3)
      return ` 0 + ${pagamento.contratoDREQtdParcela +
        1} Parcela(s) SEM JUROS de R$ ${parcela}`;

    return '';
  }

  function getDescricaoFormaPagamento() {
    if (isCnp) {
      const msg = pagamento.isDebitoBrb ? 'Débito BRB' : 'Débito Outros Bancos';

      if (pagamento.formaPagamento === 1) return `À Vista ${msg}`;
      if (pagamento.formaPagamento === 2) return `1ª Débito + ${msg}`;
      if (pagamento.formaPagamento === 3) return `Parcelado ${msg}`;
    }

    if (pagamento.formaPagamento === 1)
      return pagamento.isBoleto ? 'À vista Boleto' : 'À vista Débito';

    if (pagamento.formaPagamento === 2)
      return pagamento.isBoleto
        ? '1ª Boleto / Carnê + Débito'
        : '1ª Débito + Demais Débito';

    if (pagamento.formaPagamento === 3)
      return pagamento.isBoleto ? 'Parcelado Boleto' : 'Parcelado Débito ';
  }

  function getPremioLiquido() {
    const premioTotal = Functions.getMoney(
      dreMitsui.planoSelected.mtrPropostaEletronicaPlanoPremioTotal
    );
    const perIof = 7.38 / 100 + 1;
    const iof = parseFloat(premioTotal - premioTotal / perIof);
    const premioLiquido = parseFloat(premioTotal - iof);

    return Mask.maskMoney(premioLiquido);
  }

  function getPremioIOF() {
    const premioTotal = Functions.getMoney(
      dreMitsui.planoSelected.mtrPropostaEletronicaPlanoPremioTotal
    );
    const perIof = 7.38 / 100 + 1;
    const iof = parseFloat(premioTotal - premioTotal / perIof);

    return Mask.maskMoney(iof);
  }

  return (
    <>
      <Spin
        spinning={loading}
        style={{ textAlign: 'center' }}
        size="large"
        tip="Aguarde carregando"
      >
        {loading && <Skeleton active />}
      </Spin>
      {generos && angariadores && consultores && (
        <Container>
          {/* Dados Proposta */}
          <Block>
            <Row type="flex" style={{ alignItems: 'center' }}>
              <Col span={10} style={{ paddingLeft: 4 }}>
                <img src={imgResidenciaPremiado} alt="BRB" />
              </Col>
              <Col span={6} style={{ alignItems: 'center' }}>
                <b>{contratoId ? 'Proposta de Seguro' : 'Cotação de Seguro'}</b>
                <br />
                {contratoId
                  ? `Número da Proposta: ${contratoId}`
                  : `Número da Cotação: ${propostaId}`}
              </Col>
              <Col
                span={8}
                style={{
                  textAlign: 'right',
                  paddingRight: 10,
                }}
              >
                <img src={imgMitsui} alt="Logo" width="70" />
              </Col>
            </Row>
          </Block>
          {/* Tipo Seguro */}
          <Block>
            <Row>
              <b>Tipo de Seguro: </b> Seguro Novo
            </Row>
            <Row>
              <b>Vigência do Seguro: </b> A partir das 24h de
              {Functions.formatarData(dreMitsui.contratoDREDataInicio)}
              até as 24h de{' '}
              {Functions.formatarData(dreMitsui.contratoDREDataFim)}
            </Row>
          </Block>
          {/* Dados do Cliente */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PROPONENTE</b>
            </Row>
            <Row>
              <Field span={10} title="Nome" value={cliente.clienteNome} />
              <Field span={8} title="CPF" value={cliente.clienteCpfCnpj} />
              <Field
                span={6}
                title="Data de Nascimento"
                value={Functions.formatarData(cliente.clienteDataNascimento)}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Sexo"
                value={getDescricaoDominio(
                  generos,
                  'sexoId',
                  'sexoDescricao',
                  cliente.sexoId
                )}
              />
              <Field
                span={8}
                title="Estado Civil"
                value={getDescricaoDominio(
                  estadosCivis,
                  'estadoCivilId',
                  'estadoCivilDescricao',
                  cliente.estadoCivilId
                )}
              />
              <Field
                span={6}
                title="Nacionalidade"
                value={getDescricaoDominio(
                  naturalidade,
                  'naturalidadeId',
                  'naturalidadeDescricao',
                  cliente.naturalidadeId
                )}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Nº da Identidade"
                value={cliente.clienteRg}
              />
              <Field
                span={8}
                title="Data de emissão"
                value={Functions.formatarData(cliente.clienteDataExpedicaoRg)}
              />
              <Field
                span={6}
                title="Orgão Emissor"
                value={cliente.clienteRgSsp}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Telefone Residencial"
                value={getDescricaoDominio(
                  cliente.clienteContatoFones,
                  'clienteContatoFoneTipoId',
                  'ClienteContatoFoneNumero',
                  1
                )}
              />
              <Field
                span={8}
                title="Telefone Comercial"
                value={getDescricaoDominio(
                  cliente.clienteContatoFones,
                  'clienteContatoFoneTipoId',
                  'ClienteContatoFoneNumero',
                  2
                )}
              />
              <Field
                span={6}
                title="Telefone Celular"
                value={getDescricaoDominio(
                  cliente.clienteContatoFones,
                  'clienteContatoFoneTipoId',
                  'ClienteContatoFoneNumero',
                  4
                )}
              />
            </Row>
            <Row>
              <Field
                title="E-mail"
                value={
                  cliente.clienteContatoMails[0]?.clienteContatoMailDescricao
                }
              />
            </Row>
          </Block>
          {/* Local de Risco */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>LOCAL DE RISCO</b>
            </Row>
            <Row>
              <Field
                span={10}
                title="Tipo de Imóvel"
                value={bemEndereco.tipoImovel === 1 ? 'APARTAMENTO' : 'CASA'}
              />
              <Field
                span={8}
                title="CEP"
                value={bemEndereco.clienteEnderecoCep}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Endereço de Risco"
                value={bemEndereco.clienteEndereco1}
              />
              <Field
                span={8}
                title="Número"
                value={bemEndereco.clienteEnderecoNumero}
              />
              <Field
                span={6}
                title="Complemento"
                value={bemEndereco.clienteEnderecoComplemento}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Bairro"
                value={bemEndereco.clienteEnderecoBairro}
              />
              <Field
                span={8}
                title="Cidade"
                value={bemEndereco.clienteEnderecoCidade}
              />
              <Field
                span={6}
                title="UF"
                value={bemEndereco.clienteEnderecoUf}
              />
            </Row>
            <Row>
              <Field
                span={10}
                title="Cláusula beneficiária a favor de"
                value={dreMitsui.propClausulaBeneficiaria}
              />
              <Field
                span={8}
                title="CPF/CNPJ"
                value={dreMitsui.propClausulaBeneficiariaCpfCnpj}
              />
            </Row>
          </Block>
          {/* Plano Selecionado */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DO PLANO</b>
            </Row>
            <Row type="flex" align="middle">
              <BlockTable minHeight bold>
                Plano
              </BlockTable>
              <BlockTable minHeight bold>
                Incêndio/ Queda de Raio/ Explosão
              </BlockTable>
              <BlockTable minHeight bold>
                Perda de Aluguel
              </BlockTable>
              <BlockTable minHeight bold>
                Danos Elétricos
              </BlockTable>
              <BlockTable minHeight bold>
                Roubo de Bens
              </BlockTable>
              <BlockTable minHeight bold>
                RC Familiar
              </BlockTable>
              <BlockTable minHeight bold>
                Quebra de Vidros
              </BlockTable>
              <BlockTable minHeight bold>
                Prêmio Líquido
              </BlockTable>
              <BlockTable minHeight bold>
                IOF
              </BlockTable>
              <BlockTable minHeight bold>
                Prêmio Total
              </BlockTable>
            </Row>
            <Row type="flex" align="middle">
              <BlockTable>
                {planoSelected.propostaEletronicaPlanoSigla}
              </BlockTable>
              <BlockTable>
                {planoSelected.mtrIncendioQuedeDeRaioExplosao}
              </BlockTable>
              <BlockTable>{planoSelected.mtrPerdaDeAluguel}</BlockTable>
              <BlockTable>{planoSelected.mtrDanosEletricos}</BlockTable>
              <BlockTable>{planoSelected.mtrRouboDeBens}</BlockTable>
              <BlockTable>{planoSelected.mtrRCFamiliar}</BlockTable>
              <BlockTable>{planoSelected.mtrQuebraDeVidros}</BlockTable>
              <BlockTable>{getPremioLiquido()}</BlockTable>
              <BlockTable>{getPremioIOF()}</BlockTable>
              <BlockTable>
                {planoSelected.mtrPropostaEletronicaPlanoPremioTotal}
              </BlockTable>
            </Row>
            <Row>
              <span>
                <i>Remuneração pela intermediação de 50% sobre o prêmio</i>
              </span>
            </Row>
          </Block>
          {/* Forma de Pagamento */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>FORMA DE PAGAMENTO</b>
            </Row>
            <Row>
              <Col span={6}>{getDescricaoFormaPagamento()}</Col>
            </Row>
            <Row>
              <Col span={6}>{getFormaPagamento()}</Col>
              <Field
                title="Nosso Número"
                value={pagamento.propostaEletronicaNossoNumero}
              />
            </Row>
          </Block>
          {/* Termo e Assinatura */}
          <Block>
            <Row style={{ paddingBottom: 2, paddingTop: 10 }}>
            Concordo com a forma de pagamento especificada autorizando o débito, 
            na referida conta-corrente, do valor relativo à(s) parcela(s) do prêmio 
            devido pela contratação deste seguro. Estou ciente de que a inadimplência 
            de quaisquer das parcelas do prêmio no(s) seu(s) vencimento(s), decorrente 
            de insuficiência de saldo, implicará na suspensão imediata das coberturas 
            contratadas, observados os termos da Cláusula de Pagamento de Prêmio das 
            Condições Gerais do Seguro. Declaro que as informações constantes nesta 
            proposta forma por mim prestadas e, em sendo apurada qualquer divergência 
            naquelas que serviram de base para a taxação do risco, por omissão ou incorreção 
            desses dados, estou ciente que poderei perder o direito a uma eventual indenização, 
            nos termos previstos no Código Civil Brasileiro e na legislação pertinente, 
            devendo ainda efetuar o pagamento do prêmio vencido. Declaro ainda que li e 
            concordo com as Condições Gerais do Seguro em questão, que me foram apresentadas 
            previamente à assinatura da presente proposta.
            </Row>
            <b>RENOVAÇÃO AUTOMÁTICA</b>
            <Row style={{ paddingTop: 1 }}>
            <b> •	Do Cancelamento da renovação automática: </b>
            A qualquer momento, e até 30 dias antes do fim da vigência 
            desta 1ª apólice, o cliente poderá solicitar o cancelamento 
            da renovação automática, pelos canais de atendimento da BRB 
            Seguros ou do BRB.
            </Row>
            <Row>
            <b> •	Da Forma de pagamento: </b>
            O cliente está ciente de que, na renovação automática, a forma 
            de pagamento será alterada para débito em conta corrente (informada 
            no momento da contratação), em 10x, sem juros.
            </Row>
            <Row style={{ paddingBottom: 10}}>
            <b> •	Da Renovação automática: </b>
             Com a renovação automática, a nova apólice será emitida em 
             até 15 dias antes do término de vigência da apólice atual, 
             e enviada para o e-mail cadastrado pelo cliente. O cliente 
             está ciente de que o pagamento da 1ª parcela configurará o 
             aceite da nova apólice de seguro.
            </Row>
            <Row>
              <Field
                span={14}
                title="Local e data"
                value={`Brasília, ${Functions.getDateNow()}`}
              />
              <Col span={6} style={{ textAlign: 'center' }}>
                ________________________________________________
                <br />
                <b>Assinatura do Proponente</b>
              </Col>
            </Row>
          </Block>
          {/* Dados Débito Conta */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS PARA DÉBITO EM CONTA CORRENTE</b>
            </Row>
            <Row>
              <Field
                span={6}
                title="Banco"
                value={
                  pagamento.agencia != null
                    ? `${pagamento.agencia.bancoId} - ${pagamento.agencia.banco?.bancoDescricao}`
                    : ''
                }
              />
              <Field
                span={6}
                title="Agência"
                value={
                  pagamento.agencia != null
                    ? pagamento.agencia.agenciaCodigo
                    : ''
                }
              />
              <Field
                span={6}
                title="Conta Corrente Nº"
                value={pagamento.debitoContaNumeroConta}
              />

              {pagamento.contratoDREDiaVencimentoDemais && (
                <Field
                  span={6}
                  title="Dia Preferencial para Pagamento"
                  value={pagamento.contratoDREDiaVencimentoDemais}
                />
              )}
            </Row>
          </Block>
          {/* Dados Produção */}
          <Block>
            <Row style={{ marginBottom: 12 }}>
              <b>DADOS DA PRODUÇÃO</b>
            </Row>
            <Row>
              <Field
                span={6}
                title="Mat. Consultor"
                value={getDescricaoDominio(
                  consultores,
                  'produtorId',
                  'produtorCodigo',
                  producao.consultorId
                )}
              />
              <Field
                span={6}
                title="Mat. Angariador"
                value={getDescricaoDominio(
                  angariadores,
                  'produtorId',
                  'produtorCodigo',
                  producao.angariadorId
                )}
              />
              <Field
                span={6}
                title="Agência Produtora"
                value={getDescricaoDominio(
                  agencias,
                  'agenciaId',
                  'agenciaCodigo',
                  producao.agenciaId
                )}
              />
              <Field span={6} title="Equipe" value={producao.equipeId} />
            </Row>
          </Block>
        </Container>
      )}
    </>
  );
}
