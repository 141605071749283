import { message as toast } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as BrbMasterApi from '~/api/brb-master.api';
import { getResponse } from '~/api/utils';
import * as BrbMasterActions from '~/store/ducks/brb-master';
import * as PropostaActions from '~/store/ducks/proposta';

function* sendToken(action) {
  
  // Call api
  const response = getResponse(yield call(BrbMasterApi.sendToken, action.payload));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }
}

function* signToken(action) {
  
  // Call api
  const response = getResponse(yield call(BrbMasterApi.signToken, action.payload));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  yield put(PropostaActions.changeBrbMaster({ tokenSigned: true }));
}

function* downloadPdf(action) {
  const { param, callback } = action.payload;

  // Call api
  const response = getResponse(yield call(BrbMasterApi.downloadPdf, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    callback(data);
    return;
  }

  if (callback) callback(data);
}

function* fail(message) {
  yield put(BrbMasterActions.failedBrbMaster());
  toast.error(message);
}

export default all([
  takeLatest(BrbMasterActions.types.SEND_TOKEN, sendToken),
  takeLatest(BrbMasterActions.types.SIGN_TOKEN, signToken),
  takeLatest(BrbMasterActions.types.DOWNLOAD_PDF, downloadPdf),
]);
