import { call, all, put, takeLatest } from 'redux-saga/effects';
import { message as toast } from 'antd';
import { getResponse } from '~/api/utils';
import * as UserApi from '~/api/auth.api';
import * as AuthActions from '~/store/ducks/auth';
import history from '~/services/history';
import { duration } from 'moment';

function* login(action) {
  const { param } = action.payload;

  // Call api login
  const response = getResponse(yield call(UserApi.login, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  yield put(AuthActions.saveUser(data));

  toast.success(message);
  history.push('/proposta/new');
}

function* notification(action) {
  const { param } = action.payload;

  // Call api notification
  const response = getResponse(yield call(UserApi.notification, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  if(data.deveExibirAlerta) 
    toast.error(data.mensagemAlerta, 20);
    
  history.push('/proposta/new');
}

function* tokenWiz(action) {
  const { param, callback } = action.payload;

  // Call api
  const response = getResponse(yield call(UserApi.generateTokenWiz, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(fail, message);
    return;
  }

  if (callback) callback(data);
}

function* callback(action) {
  const { param } = action.payload;

  // Call api login
  const response = getResponse(yield call(UserApi.callback, param));
  const { ok, data, message } = response;

  if (!ok) {
    yield call(failCallback, message);
    return;
  }

  yield put(AuthActions.saveUser(data));

  toast.success(message);

  history.push('/proposta/new');
}

function* fail(message) {
  yield put(AuthActions.failedAuth());
  toast.error(message);
}

function* failCallback(message) {
  yield put(AuthActions.failedAuth());
  toast.error(message);
  history.push('/');
}

export default all([
  takeLatest(AuthActions.types.LOGIN, login),
  takeLatest(AuthActions.types.CALLBACK, callback),
  takeLatest(AuthActions.types.NOTIFICATION, notification),
  takeLatest(AuthActions.types.TOKEN_WIZ, tokenWiz),
]);
