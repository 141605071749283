import { Col, Icon, message, Radio, Row, Spin, Table, Modal, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import 'antd-button-color/dist/css/style.css'; // or 'antd-button-color/dist/css/style.less'
import Button from 'antd-button-color';
import {
  Container,
  DatePicker,
  Error,
  FieldsProducao,
  Input,
  Select,
  TitleSection,
  Disable,
  Input as InputCmp,
} from '~/components';
import Buttons from '../components/Buttons';
import * as ClienteActions from '~/store/ducks/cliente';
import * as PlanoActions from '~/store/ducks/plano';
import * as ProducaoActions from '~/store/ducks/producao';
import * as PropostaActions from '~/store/ducks/proposta';
import * as BrbMasterActions from '~/store/ducks/brb-master';
import { Constants, Functions, Validation } from '~/utils';
import { ProducaoValidation, PropostaValidation } from '~/yup';
import FormatarProposta from './utils';
import moment from 'moment';

type BrbMasterPremiadoProps = {
  onReset: Function,
  onPrevius: Function,
  onNext: Function,
};

export default function BrbMasterPremiado({
  onReset,
  onPrevius,
  onNext,
}: BrbMasterPremiadoProps) {
  const {
    listTipoPlano,
    listTipoPagamento,
    listTipoConta,
    listDiaDebito,
    colunasTabelaPlanosMensal: columnsMensal,
    colunasTabelaPlanosAnual: columnsAnual,
  } = Constants.BrbMaster;

  const {
    proposta: {
      brbMaster: proposta,
      brbMaster: { telefonesEnvioToken },
      contasDebito,
      loading,
      propostaId,
      contratoId,
      producao,
    },
    dominio: { bancos, agenciasBrb, grausRelacionamento },
    cliente: { cliente, endereco },
    plano: { planos, loading: loadingPlanos },
  } = useSelector(state => {
    const { contasDebito } = state.proposta;
    const { brbMaster } = state.proposta;

    brbMaster.contaDebitoId = brbMaster
      ? (
          contasDebito.find(
            x =>
              x.agenciaId === brbMaster.agenciaDebitoId &&
              x.contaNumero === brbMaster.contaDebitoNumero
          ) || { id: null }
        ).id
      : 0;

    return state;
  });

  const [erros, setErros] = useState([]);
  const [planoSelectedKey, setPlanoSelectedKey] = useState([]);
  const [disableBeneficiario, setDisableBeneficiario] = useState(false);
  const [textoDadosCalculo, setTextoDadosCalculo] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalDiaDebito, setVisibleModalDiaDebito] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PropostaActions.getContas({ param: cliente?.clienteCpfCnpj }));
    if (proposta.beneficiarios.length === 1) {
      const beneficiario = proposta.beneficiarios[0];
      if (beneficiario.nome.indexOf('HERDEIRO') !== -1)
        setDisableBeneficiario(true);
    }
  }, []);

  useEffect(() => {
    if (proposta.planoSelected && planos.length > 0) {
      const planoSelected = planos.find(
        p =>
          p.planoGarantiaVidaId === proposta.planoSelected?.planoGarantiaVidaId
      );
      changeFields({ planoSelected });
      setPlanoSelectedKey([proposta.planoSelected?.planoGarantiaVidaId]);
    }
  }, [planos]);

  useEffect(() => {
    reloadPlanosByTipoPagamento(proposta.tipoPagamento);
  }, [proposta.tipoPagamento]);

  function reloadPlanosByTipoPagamento(tipoPagamento){

    const enderecoCliente = cliente.clienteEnderecos.length > 0 ? cliente.clienteEnderecos[0] : endereco;

    const nome = cliente?.clienteNome;
    const cpf = cliente?.clienteCpfCnpj;
    const dataNascimento = cliente?.clienteDataNascimento;
    const profissaoCbo = cliente.clienteProfissaoBrbMaster;
    const renda =
      cliente?.clienteFaixaRendaManual == undefined
        ? cliente?.mtrClienteFaixaRendaValor
        : cliente?.clienteFaixaRendaManual;
    const sexoId = cliente?.sexoId;
    const uf = enderecoCliente.clienteEnderecoUf;
    const periodicidade = tipoPagamento == 1 ? 'M' : 'A' ;

    setTextoDadosCalculo(
      `Nome: ${nome}, CPF: ${cpf}, Data Nascimento: ${moment(
        dataNascimento
      ).format(
        'DD/MM/YYYY'
      )}, Profissão Cód.: ${profissaoCbo}, Renda: ${renda}, Sexo: ${sexoId == 1 ? 'M' : 'F'}, UF: ${uf}, Periodicidade: ${periodicidade}`
    );
    dispatch(
      PlanoActions.getPlanosBrbMaster({
        param: {
          nome,
          cpf,
          dataNascimento,
          profissaoCbo,
          renda,
          sexoId,
          uf,
          periodicidade,
        },
      })
    );
  }

  function changeFields(obj) {
    dispatch(PropostaActions.changeBrbMaster(obj)); 
  }

  function changeTipoProposta(value) {
    if (value === 2) {
      changeFields({ tipoPropostaId: value });
      return;
    }
    changeFields({
      tipoPropostaId: value,
      dataPagamentoPropostaManual: undefined,
      numeroPropostaManual: undefined,
    });
  }

  function changeTipoPlano(value) {
    if (value === 2) {
      changeFields({ tipoPlano: value });
      return;
    }

    changeFields({
      tipoPlano: value,
      conjugeCpf: undefined,
      conjugeNome: undefined,
      conjugeDataNascimento: undefined,
      conjugeAltura: undefined,
      conjugePeso: undefined,
    });
  }

  function changeContaDebito(contaId) {
    const conta = contasDebito.find(x => x.id === contaId);

    changeFields({
      contaDebitoId: conta.id,
      agenciaDebitoId: conta.agenciaId,
      contaDebitoNumero: conta.contaNumero,
    });
  }

  function onChangeCpfTerceiro(value) {
    dispatch(
      PropostaActions.changeBrbMaster({
        cpfTerceiro: value,
        contaDebitoId: undefined,
        agenciaDebitoId: undefined,
      })
    );

    dispatch(PropostaActions.changeContasDebito([]));

    if (value && value.length === 14) {
      if (!Validation.isCpfCnpjValid(value))
        dispatch(
          PropostaActions.changeBrbMaster({ cpfTerceiroIsValid: false })
        );
      else {
        dispatch(
          PropostaActions.changeBrbMaster({
            cpfTerceiroIsValid: true,
            loadingContas: true,
          })
        );

        dispatch(PropostaActions.getContas({ param: value }));
      }
    }
  }

  function changeOrigemConta(event) {
    const {
      target: { value },
    } = event;

    changeFields({
      tipoConta: value,
      contaDebitoId: undefined,
      agenciaDebitoId: undefined,
      contaDebitoNumero: undefined,
    });

    if (value !== 2) {
      changeFields({ cpfTerceiro: undefined });
      if (value === 1)
        dispatch(PropostaActions.getContas({ param: cliente.clienteCpfCnpj }));
      else dispatch(PropostaActions.changeContasDebito([]));
    } else if (value === 2) dispatch(PropostaActions.changeContasDebito([]));
  }

  function changeBeneficiario(field, value, index) {
    const newArray = proposta.beneficiarios.map((beneficiario, i) => {
      if (i === index) {
        beneficiario[field] = value;
      }

      return beneficiario;
    });

    changeFields({ beneficiarios: newArray });
  }

  function onChangeGrauParentesco(value, index) {
    const grauRelacionamento = grausRelacionamento.find(
      g => g.grauRelacionamentoId === value
    );
    if (
      grauRelacionamento.grauRelacionamentoDescricao.indexOf('HERDEIRO') !== -1
    ) {
      changeBeneficiario('nome', 'HERDEIROS LEGAIS', index);
      changeBeneficiario('percentual', '100', index);
      changeBeneficiario('dataNascimento', '', index);
      changeBeneficiario('grauRelacionamento', 12, index);
      setDisableBeneficiario(true);
    } else {
      setDisableBeneficiario(false);
      changeBeneficiario('grauRelacionamento', value, index);
    }
  }

  function newBeneficiario() {
    changeFields({
      beneficiarios: [
        ...proposta.beneficiarios,
        ...[
          {
            nome: '',
            dataNascimento: '',
            grauRelacionamento: '',
            percentual: '',
          },
        ],
      ],
    });
  }

  function signToken() {
    const objEnvio = {
      user: {
        username: cliente.clienteCpfCnpj,
        document: cliente.clienteCpfCnpj,
        email: cliente.clienteContatoMails[0].clienteContatoMailDescricao,
        cellphone: cliente.clienteContatoFones.find(
          f => f.clienteContatoFoneTipoId == 4
        ).clienteContatoFoneNumero,
        nameProponente: cliente.clienteNome,
      },
      token: {
        tokenCode: proposta.tokenCliente,
        sendType: 'SMS',
      },
    };

    dispatch(BrbMasterActions.signToken(objEnvio));

    setVisibleModal(false);
  }

  function sendToken() {
    if (!telefonesEnvioToken || telefonesEnvioToken?.length == 0) {
      message.error('Favor selecionar o telefone que irá receber o token na tela anterior!');
      return;
    }

    const objEnvio = {
      user: {
        username: cliente.clienteCpfCnpj,
        email: cliente.clienteContatoMails[0].clienteContatoMailDescricao,
        cellphone: telefonesEnvioToken[0],
        name: cliente.clienteNome,
      },
    };

    dispatch(BrbMasterActions.sendToken(objEnvio));

    setVisibleModal(true);
  }

  function downloadPdf() {
    setLoadingPdf(true);

    dispatch(BrbMasterActions.downloadPdf({
      param: proposta.numeroControle,
      callback: response => {

        if (response == undefined) {
          setLoadingPdf(false);
          return;
        }

        // 1. Convert base64 to Blob
        const blob = b64toBlob(response.fileContents, response.contentType);

        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${response.fileDownloadName}.pdf`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);

        setLoadingPdf(false);
      }
    }));
  }

  function b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  async function saveProposal(efetivar = false) {
    setErros([]);

    const isValid = await validaProposta();

    if (isValid.ok) {
      if (planoSelectedKey.length === 0) {
        message.error('Selecionar um plano é obrigatório.');
        return;
      }

      const propostaFormatada = FormatarProposta(efetivar);

      if (efetivar) {
        dispatch(
          PropostaActions.efetivar({
            param: propostaFormatada,
            callback: callbackErrorEfetivar,
          })
        );
        return;
      }

      dispatch(PropostaActions.save({ param: propostaFormatada }));
    } else {
      setErros(isValid);
    }
  }

  async function validaProposta() {
    let response = await validaObjeto(PropostaValidation.BrbMaster, proposta);
    if (!response.ok) return response;

    response = await validaObjeto(
      ProducaoValidation.ProducaoValidationDefault,
      producao
    );

    if (!response.ok) return response;

    return { ok: true };
  }

  function validaObjeto(validation, obj) {
    return validation
      .validate(obj, { abortEarly: false })
      .then(() => ({ ok: true }))
      .catch(error => error.errors);
  }

  function onNovaCotacao() {
    dispatch(ClienteActions.initCliente());
    dispatch(ProducaoActions.initProducao());
    dispatch(PropostaActions.initProposta());
    onReset();
  }

  function callbackErrorEfetivar(values) {
    setErros(values);
  }

  function deleteBeneficiario() {
    const ben = proposta.beneficiarios;
    ben.pop();
    changeFields({
      beneficiarios: ben,
    });
  }

  async function _onNext() {
    setErros([]);
    // valid data
    const response = await validaProposta();
    // Check is valid
    if (response.ok) onNext();
    else setErros(response);
  }

  function onChangeDiaDebito(value) {
    changeFields({ diaDebito: value });

    // verifica se a data escolhida para o proximo debito
    // vai dar antes de 20 dias
    const diaFuturo = parseInt(moment().add(20, 'days').format('DD'));
    
    if (parseInt(value) < diaFuturo) 
      setVisibleModalDiaDebito(true);
  }

  return (
    <>
      <Spin spinning={loading || loadingPdf} size="large">
        <Buttons
          onPressVoltar={onPrevius}
          showButtonNovaCotacao={!contratoId}
          onPressNovaCotacao={onNovaCotacao}
          showButtonSalvar={!contratoId}
          onPressSalvar={() => saveProposal()}
          showButtonEfetivar={
            propostaId > 0 && !contratoId && proposta.tokenSigned
          }
          showButtonEnviarToken={
            propostaId > 0 && !contratoId && !proposta.tokenSigned
          }
          onPressEnviarToken={sendToken}
          onPressEfetivar={() => saveProposal(true)}
          showButtonImprimir={false}
          showButtonImprimirBrbMaster={contratoId > 0}
          onPressImprimirBrbMaster={downloadPdf}
        />
        <Container>
          <Error erros={erros} />
          
          {contratoId > 0 && (
            <Result
              status="success"
              title="Parabéns, produto contratado com sucesso!"
              subTitle={`O número do contrato no Multiseguros é: ${contratoId} e você já pode consultá-lo lá.`}
              extra={[
                <Button
                  className="btn btn-xs-block mb-2 mb-sm-0"
                  type="primary"
                  href={`https://multiseguros.brbseguros.com.br/#/contrato-vida/${contratoId}/${Constants.Produtos.BrbMasterPremiado}/${cliente.clienteId}/5819`}
                  target="_blank"
                  key="1"
                >
                  Ir ao Multiseguros
                </Button>,
              ]}
            />
          )}

          <Disable disable={!!contratoId}>
            <h4>BRB Vida Sênior</h4>

            <FieldsProducao produto="brb-master" />

            <TitleSection title="Contrato" />

            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label className="w-100">Opçoes de Plano</label>
                  <Radio.Group
                    buttonStyle="solid"
                    defaultValue={proposta.tipoPlano}
                    options={listTipoPlano}
                    onChange={({ target: { value } }) => changeTipoPlano(value)}
                  />
                </div>
              </Col>
            </Row>
            {proposta.tipoPlano === 2 && (
              <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
                <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label>CPF do cônjuge</label>
                    <Input
                      placeholder="CPF"
                      value={proposta.conjugeCpf}
                      mask="cpfCnpj"
                      onChange={value => changeFields({ conjugeCpf: value })}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                  <div className="form-group">
                    <label>Nome do cônjuge</label>
                    <Input
                      placeholder="Nome"
                      value={proposta.conjugeNome}
                      onChange={value => changeFields({ conjugeNome: value })}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label>Data de nascimento</label>
                    <DatePicker
                      value={proposta.conjugeDataNascimento}
                      placeholder="Selecione"
                      onChange={value =>
                        changeFields({ conjugeDataNascimento: value })
                      }
                    />
                  </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label>Altura do cônjuge</label>
                    <Input
                      placeholder="Altura"
                      addonAfter="m"
                      mask="altura"
                      suffix="M"
                      value={proposta.conjugeAltura}
                      onChange={value => changeFields({ conjugeAltura: value })}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                  <div className="form-group">
                    <label>Peso do cônjuge</label>
                    <Input
                      placeholder="Peso"
                      addonAfter="kg"
                      mask="peso"
                      suffix="Kg"
                      value={proposta.conjugePeso}
                      onChange={value => changeFields({ conjugePeso: value })}
                    />
                  </div>
                </Col>
              </Row>
            )}

            <TitleSection title="Pagamentos" />

            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label className="w-100">Pagamento do Prêmio</label>
                  <Radio.Group
                    buttonStyle="solid"
                    defaultValue={proposta.tipoPagamento}
                    options={listTipoPagamento}
                    onChange={({ target: { value } }) => {
                      reloadPlanosByTipoPagamento(value);
                      changeFields({ tipoPagamento: value })}
                    }
                  />
                </div>
              </Col>

              <Col xs={{ span: 12 }} sm={{ span: 7 }}>
                <div className="form-group">
                  <label className="w-100">Origem da conta</label>
                  <Radio.Group
                    buttonStyle="solid"
                    defaultValue={proposta.tipoConta}
                    options={listTipoConta}
                    onChange={changeOrigemConta}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              {proposta.tipoConta !== 2 && (
                <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                  <div className="form-group">
                    <label>
                      Contas do{' '}
                      {proposta.tipoConta === 1 ? 'Titular' : 'Terceiro'}
                    </label>
                    <Select
                      defaultValue="Selecione"
                      itens={contasDebito}
                      itemValue="id"
                      renderText={conta =>
                        `${conta.agenciaCodigo} - ${conta.contaNumero}`
                      }
                      value={proposta.contaDebitoId}
                      onChange={value => changeContaDebito(value)}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label className="w-100">Banco</label>
                  <Select
                    defaultValue="Selecione"
                    itens={bancos.filter(b => b.bancoId === '0070')}
                    itemValue="bancoId"
                    itemText="bancoDescricao"
                    value={proposta.bancoId}
                    onChange={value => changeFields({ bancoId: value })}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label>Agência</label>
                  <Select
                    defaultValue="Selecione"
                    itens={agenciasBrb}
                    itemValue="agenciaId"
                    value={proposta.agenciaDebitoId}
                    itemText="agenciaCodigo"
                    itemTextSearch="agenciaDescricao"
                    renderText={ag =>
                      `${ag.agenciaCodigo} - ${ag.agenciaDescricao}`
                    }
                    onChange={value => changeFields({ agenciaDebitoId: value })}
                    disabled={proposta.tipoConta !== 2}
                    showSearch
                  />
                </div>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                <div className="form-group">
                  <label>Conta</label>
                  <Input
                    placeholder="Conta Corrente"
                    value={proposta.contaDebitoNumero}
                    onChange={value =>
                      changeFields({ contaDebitoNumero: value })
                    }
                    disabled={proposta.tipoConta !== 2}
                    accept="onlyNumbers"
                  />
                </div>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                <div className="form-group">
                  <label className="w-100">Dia para débito das parcelas</label>
                  <Select
                    defaultValue="Selecione"
                    style={{ width: '100%' }}
                    itens={listDiaDebito}
                    value={proposta.diaDebito}
                    renderText={item => item}
                    onChange={value => onChangeDiaDebito(value)}
                  />
                </div>
              </Col>
            </Row>
          </Disable>
          <TitleSection title="Planos" />
          {planos?.length === 1 && (
            <>
              {planos[0].msgErros?.length > 0 && (
                <>
                  {planos[0].msgErros.map(erro => {
                    return (
                      <span style={{ color: '#ca4440' }}>
                        <i> - {erro}</i> <br/>
                      </span>
                    );
                  })}
                </>
              )}
            </>
          )}
          <>
            <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
              <Col span={24}>
                <Table
                  rowKey="planoGarantiaVidaId"
                  dataSource={planos}
                  columns={
                    proposta.tipoPagamento == 1 ? columnsMensal : columnsAnual
                  }
                  pagination={false}
                  loading={loadingPlanos}
                  size="small"
                  rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys, selectedRows) => {
                      changeFields({ planoSelected: selectedRows[0] });
                      setPlanoSelectedKey(selectedRowKeys);
                    },
                    selectedRowKeys: planoSelectedKey,
                    getCheckboxProps: () => ({
                      disabled: !!contratoId,
                    }),
                  }}
                  title={() => (
                    <span>
                     Os planos serão exibidos de acordo com os dados: {textoDadosCalculo} 
                    </span>
                  )}
                  scroll={{ x: 1000 }}
                />
              </Col>
            </Row>
            <span style={{ color: '#ca4440' }}>
              <i>
                *Cobertura de Morte qualquer causa (Morte Natural + Morte
                Acidental)
              </i>
            </span>
            <span>
              <br />
              <i>
                Remuneração pela intermediação de 22% sobre o prêmio líquido
              </i>
            </span>
          </>
          
          <Disable disable={!!contratoId}>

            <TitleSection title="Altura e Peso" />

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                <div className="form-group">
                  <label>Altura do titular</label>
                  <InputCmp
                    placeholder="Altura"
                    addonAfter="m"
                    value={proposta.titularAltura}
                    mask="altura"
                    suffix="M"
                    onChange={value => changeFields({ titularAltura: value })}
                  />
                </div>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 4 }}>
                <div className="form-group">
                  <label>Peso do titular</label>
                  <InputCmp
                    placeholder="Peso"
                    addonAfter="kg"
                    mask="peso"
                    suffix="Kg"
                    value={proposta.titularPeso}
                    onChange={value => changeFields({ titularPeso: value })}
                  />
                </div>
              </Col>
            </Row>
          </Disable>

          <Disable disable={!!contratoId}>
            <TitleSection title="Beneficiários" />

            <p>A soma das participações dos beneficiários deve ser 100%.</p>

            <Row gutter={[16, 16]}>
              <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                Grau de parentesco
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 9 }}>
                Nome(s) do(s) Beneficiário(s)
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                Data(s) de Nascimento do Beneficiário(s)
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                Participação
              </Col>
            </Row>
            {proposta.beneficiarios.map((beneficiario, index) => {
              return (
                <Row gutter={[16, 16]} key={index.toString()}>
                  <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                    <div className="form-group">
                      <Select
                        showSearch
                        defaultValue="Selecione"
                        itens={grausRelacionamento}
                        itemValue="grauRelacionamentoId"
                        itemText="grauRelacionamentoDescricao"
                        value={beneficiario.grauRelacionamento}
                        onChange={value => onChangeGrauParentesco(value, index)}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 9 }}>
                    <Input
                      placeholder="Nome dos Beneficiários"
                      value={beneficiario.nome}
                      onChange={value =>
                        changeBeneficiario('nome', value, index)
                      }
                      disabled={disableBeneficiario}
                    />
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                    <Disable disable={disableBeneficiario}>
                      <DatePicker
                        value={beneficiario.dataNascimento}
                        onChange={value =>
                          changeBeneficiario('dataNascimento', value, index)
                        }
                      />
                    </Disable>
                  </Col> 
                  <Col xs={{ span: 12 }} sm={{ span: 5 }}>
                    <Input
                      placeholder="Em porcentagem"
                      value={beneficiario.percentual}
                      onChange={value =>
                        changeBeneficiario('percentual', value, index)
                      }
                      onlyNumbers
                      suffix="%"
                      maxLength={3}
                      disabled={disableBeneficiario}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col>
                <Button
                  className="btn btn-xs-block"
                  type="primary"
                  onClick={newBeneficiario}
                  style={{ marginRight: 20 }}
                >
                  <Icon type="plus" />
                  Incluir Beneficiário
                </Button>

                <Button
                  className="btn  btn-xs-block mt-2 mt-sm-0"
                  type="danger"
                  onClick={deleteBeneficiario}
                  disabled={proposta.beneficiarios.length === 1}
                >
                  <Icon type="delete" />
                  Excluir último Beneficiário
                </Button>
              </Col>
            </Row>
          </Disable>

          <Modal
            title="Token Cliente"
            centered
            width={300}
            visible={visibleModal}
            onOk={signToken}
            onCancel={() => setVisibleModal(false)}
            okText="Validar"
          >
            <div className="form-group">
              <label>Preencha o token enviado para o celular do cliente:</label>
              <InputCmp
                value={proposta.tokenCliente}
                onChange={value => changeFields({ tokenCliente: value })}
              />
            </div>
          </Modal>

          <Modal
            warning
            title="Atenção!"
            centered
            width={400}
            visible={visibleModalDiaDebito}
            onOk={() => setVisibleModalDiaDebito(false)}
            onCancel={() => setVisibleModalDiaDebito(false)}
            okText="Ok"
            
          >
            <div>
              A data escolhida para os demais débitos, tem período inferior a 20 dias da data de contratação. 
              Favor informar para o cliente que o pagamento da 2ª parcela ocorrerá em período inferior a 20 dias 
              da data de contratação.
            </div>
          </Modal>
        </Container>

        <Buttons
          onPressVoltar={onPrevius}
          showButtonNovaCotacao={!contratoId}
          onPressNovaCotacao={onNovaCotacao}
          showButtonSalvar={!contratoId}
          onPressSalvar={() => saveProposal()}
          showButtonEfetivar={
            propostaId > 0 && !contratoId && proposta.tokenSigned
          }
          showButtonEnviarToken={
            propostaId > 0 && !contratoId && !proposta.tokenSigned
          }
          onPressEnviarToken={sendToken}
          onPressEfetivar={() => saveProposal(true)}
          showButtonImprimir={false}
          showButtonImprimirBrbMaster={contratoId > 0}
          onPressImprimirBrbMaster={downloadPdf}
        />
      </Spin>
    </>
  );
}
